import React from 'react';
import styled from 'styled-components/macro';
import { media, colors } from 'styles';
import BackButton from 'modules/shared/components/BackButton';
import SearchCTA from 'modules/search/components/SearchCTA';
import { useBookExercisePageAbTest } from '../hooks/useBookExercisePageAbTest';

export default function BookHeader({ disableBackButton }) {
  const { shouldChangeLayout } = useBookExercisePageAbTest();
  return (
    <>
      <Header>
        {media.isDesktop() && !disableBackButton && <BackButton />}
        {shouldChangeLayout ? (
          <GreenStripe></GreenStripe>
        ) : (
          <TitleContainer>
            <SubtitleContainer>
              <h1>Livros Resolvidos</h1>
              <h3>
                Mate suas <strong>listas de exercícios</strong>
                {media.isMobile() && <br />} com nossas{' '}
                <strong>resoluções passo a passo!</strong>
              </h3>
              {media.isDesktop() && (
                <SearchButtonContainer>
                  <SearchCTA color="#149f8c" />
                </SearchButtonContainer>
              )}
            </SubtitleContainer>
          </TitleContainer>
        )}
      </Header>
      {media.isMobile() && !shouldChangeLayout && (
        <SearchButtonContainer>
          <SearchCTA color="#149f8c" />
        </SearchButtonContainer>
      )}
    </>
  );
}

const Header = styled.header`
  background: #14b9a6;
  position: relative;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0 60px 0;
  height: 280px;

  @media ${media.mobile} {
    position: relative;
    height: 170px;
    flex-direction: column;
    padding-top: 120px;
  }
`;

const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.bodyBackground};
  font-style: italic;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-weight: bold;

  h1 {
    text-transform: uppercase;
    font-size: 3.4em;
    text-align: center;
  }

  h3 {
    font-size: 1.15em;
    text-align: center;
  }

  @media ${media.mobile} {
    width: 85%;

    h1 {
      font-size: 1.3em;
      margin-bottom: 5px;
    }

    h3 {
      font-size: 1em;
    }
  }
`;

const SearchButtonContainer = styled.div`
  margin: 20px 0;

  @media ${media.mobile} {
    padding-inline: 20px;
    margin: 0;
  }
`;

const GreenStripe = styled.div`
  width: 100%;
  height: 400px;
  background: #14b9a6;
  position: absolute;
  top: -35px;
  z-index: -1;
`;
