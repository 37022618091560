import React, { useEffect, useCallback } from 'react';
import { addMonths, format } from 'date-fns';

import { styled, colors, media } from 'styles';
import { useTracker, useDate, useCurrency, useUTM } from 'modules/shared/hooks';
import CoinImage from 'images/campaigns/change-plan/coin.png';

export default function ChangePlanConfirmationDialog({
  subscription,
  newPlanPricing,
}) {
  const { getHumanReadableDateFromTimestamp } = useDate();
  const { formatCurrency } = useCurrency();
  const { getUTMsByLocation } = useUTM();
  const { trackEvent } = useTracker();

  const getAmplitudeEventProperties = useCallback(() => {
    return {
      Onde: media.isDesktop() ? 'Desktop' : 'Mobile',
      'Preço Plano Escolhido': formatCurrency(newPlanPricing.price),
      ...getUTMsByLocation(window.location),
    };
  }, [formatCurrency, getUTMsByLocation, newPlanPricing]);

  useEffect(() => {
    trackEvent(
      'Troca de Plano: Dialog Sucesso Visualizou',
      getAmplitudeEventProperties()
    );
  }, [getAmplitudeEventProperties, trackEvent]);

  const handleFinishPress = useCallback(() => {
    window.location.reload(false);
  }, []);

  const getNextRenovationDate = useCallback(() => {
    const currentRenovationDate = new Date(subscription.expiresIn * 1000);
    const newRenovationDate = addMonths(
      currentRenovationDate,
      newPlanPricing.plan.months
    );

    return format(newRenovationDate, 'dd/MM/yyyy');
  }, [subscription, newPlanPricing]);

  return (
    <Container className="change-plan-succeed">
      <Content>
        <Image src={CoinImage} />
        <Title>Plano alterado com sucesso!</Title>
        <InfosContainer>
          Agora você possui um plano {newPlanPricing.plan.description} do
          Responde Aí, com{' '}
          <b>{newPlanPricing.plan.months} meses de acesso liberado!</b> A partir
          do dia{' '}
          <b>{getHumanReadableDateFromTimestamp(subscription.expiresIn)}</b> seu
          novo plano será cobrado e sua renovação está agendada para dia{' '}
          {getNextRenovationDate()}.
        </InfosContainer>
        <ButtonsContainer>
          <Button className="full" onClick={handleFinishPress}>
            OK, entendi!
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 60px;
  border-top: 10px solid ${colors.purple};
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const Image = styled.img`
  position: relative;
  left: -15px;
  width: 130px;
  margin-top: 15px;

  @media ${media.mobile} {
    left: -10px;
    width: 100px;
  }
`;

const Title = styled.h1`
  margin-top: 25px;
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InfosContainer = styled.div`
  width: 100%;
  padding: 25px 20px;
  line-height: 1.4;
  font-size: 0.9em;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 50px;
  margin-top: 25px;
  color: ${colors.mainOrange};
  border: 2px solid ${colors.mainOrange};
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  &.full {
    color: #fff;
    background-color: ${colors.mainOrange};
  }

  @media ${media.mobile} {
    font-size: 0.8em;
  }
`;
