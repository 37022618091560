import React, { useCallback, useEffect } from 'react';
import { styled, colors, media } from 'styles';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import useSearchTracker from '../hooks/useSearchTracker';

export default function SearchFeedbackContainer({
  afterSendFeedbackCallback = () => {},
  source = 'Busca',
  text,
}) {
  const {
    trackViewFeedbackFromSearch,
    trackSendFeedbackFromSearch,
  } = useSearchTracker();

  const sendFeedback = useCallback(
    feedback => {
      trackSendFeedbackFromSearch(feedback, source);
      afterSendFeedbackCallback();
    },
    [trackSendFeedbackFromSearch, afterSendFeedbackCallback, source]
  );

  useEffect(() => {
    trackViewFeedbackFromSearch(source);
  }, [trackViewFeedbackFromSearch, source]);

  return (
    <Container onClick={e => e.stopPropagation()}>
      <Title>{text}</Title>
      <Thumbs>
        {[
          <Thumb
            key="thumbs-up"
            onClick={() => sendFeedback('up')}
            color="#149f8c"
          >
            <Icon className="thumb-icon" icon={faThumbsUp} color={'white'} />
            Sim
          </Thumb>,
          <Thumb
            key="thumbs-down"
            onClick={() => sendFeedback('down')}
            color="#E74B3B"
          >
            <Icon className="thumb-icon" icon={faThumbsDown} color={'white'} />
            Não
          </Thumb>,
        ].sort(() => (Math.random() > 0.5 ? 1 : -1))}
      </Thumbs>
    </Container>
  );
}

const Container = styled.div`
  width: 480px;
  height: 110px;
  max-width: 95%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
  border-top: 10px solid ${colors.mainOrange};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 130px;
  padding-top: 20px;

  @media ${media.mobile} {
    width: 94%;
    margin: 0 auto;
  }
`;

const Thumbs = styled.h2`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 180px;
  justify-self: center;
`;

const Thumb = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  background-color: ${({ color }) => color};
  margin: 10px 0;
  font-size: 1em;
  padding: 5px 7px;
  border-radius: 5px;
  justify-content: space-evenly;
  width: 80px;
  cursor: pointer;

  color: #fff;

  .thumb-icon {
    color: #fff;
  }
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 1.2em;
`;
