import React, { useCallback, useState } from 'react';
import { Form as UnformForm } from '@unform/web';
import { ClipLoader } from 'modules/shared/components/generic/loaders';

import { styled, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import FormInput from 'modules/shared/components/inputs/FormInput';
import { useUserContext } from 'providers/UserProvider';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useToast } from 'modules/shared/hooks';

export default function EditProfile() {
  const [loading, setLoading] = useState(false);
  const { errorToast } = useToast();

  const { user, setUser } = useUserContext();
  const { unsetDialog } = useDialogContext();

  const updateUser = useRAPostRequest('users/update_basic_infos');

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        const noName = !data.name || data.name === '';
        if (noName) {
          setLoading(false);
          return errorToast('Nome não pode ficar em branco');
        }

        const response = await updateUser(data);
        setUser({ ...user, ...response.data.user });

        location.reload();
      } catch (_) {
        setLoading(false);
        alert(
          'Ocorreu um erro inesperado. Por favor tente novamente mais tarde.'
        );
      }
    },
    [updateUser, setUser, user, errorToast]
  );

  return (
    <Container>
      <Content>
        <Form
          onSubmit={handleSubmit}
          action=""
          initialData={{ name: user.name, phone: user.phone }}
        >
          <FieldSet>
            <label htmlFor="name">Nome</label>
            <FormInput type="text" name="name" id="name" />
          </FieldSet>

          <FieldSet>
            <label htmlFor="phone">Telefone</label>
            <FormInput
              type="text"
              name="phone"
              id="phone"
              mask="(99) 99999-9999"
            />
          </FieldSet>

          {!loading ? (
            <ButtonsContainer>
              <button onClick={unsetDialog} className="cancel" type="button">
                Cancelar
              </button>
              <button className="submit" type="submit">
                Salvar
              </button>
            </ButtonsContainer>
          ) : (
            <ClipLoader color="#f7ac3b" />
          )}
        </Form>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  padding: 30px 15px 20px;
  border-top: 10px solid ${colors.mainOrange};
`;

const Form = styled(UnformForm)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  max-width: 100%;
`;

const FieldSet = styled.fieldset`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 80%;

  label {
    margin: 0 0 2px 5px;
    font-size: 0.8em;
    text-align: left;
  }

  > div {
    width: 100%;

    > input {
      border-radius: 5px;
      font-size: 1em;
      border: 1px solid ${colors.lightGray};
      padding: 5px;
      width: 100%;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;

  button {
    font-size: 1.1em;
    font-weight: bold;
    padding: 10px 0;
    border: 1px solid ${colors.lightGray};
    border-radius: 10px;
    width: 100%;
    max-width: 580px;

    &:first-child {
      margin-bottom: 10px;
    }

    &.submit {
      background-color: ${colors.mainOrange};
      border-color: ${colors.mainOrange};
      color: white;
    }

    &.cancel:hover {
      background-color: ${colors.mainOrange};
      border-color: ${colors.mainOrange};
      color: white;
    }

    &.submit:hover {
      background-color: white;
      border-color: ${colors.mainOrange};
      color: ${colors.mainOrange};
    }
  }
`;
