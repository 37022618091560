import React from 'react';

import { styled, media } from 'styles';

export default function ThemeItem({
  color,
  imagePath,
  name,
  onClick,
  isSelected,
}) {
  return (
    <Container className={isSelected ? 'selected' : ''} onClick={onClick}>
      <ImageContainer color={color}>
        <Image src={imagePath} />
      </ImageContainer>
      <Name>{name}</Name>
    </Container>
  );
}

const ImageContainer = styled.div`
  background-color: ${props => props.color};
  width: 60px;
  height: 60px;
  margin-top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;

  @media ${media.mobile} {
    width: 50px;
    height: 50px;
  }
`;

const Name = styled.p`
  font-weight: bold;
  padding: 0 10px;
  font-size: 0.8em;
  text-align: center;
  margin: 10px auto 0 auto;
  @media ${media.mobile} {
    font-size: 0.7em;
  }
`;

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  &.selected ${ImageContainer} {
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
  }
  &.selected ${Name} {
    color: #454;
  }
`;

const Image = styled.img`
  width: 35px;
  @media ${media.mobile} {
    width: 30px;
  }
`;
