/* eslint-disable custom-rules/set-dialog */

// Example of input
// [
//   {
//     condition: conditionMethod,
//     render: renderMethod,
//   }
// ]

import { useEffect } from 'react';
import { useDialogContext } from 'providers/DialogProvider';

export default function useRenderDialog(dialogs) {
  const { setDialog } = useDialogContext();

  useEffect(() => {
    const dialogToRender = dialogs.find(dialog => dialog.condition());
    if (!dialogToRender) return;

    const timer = setTimeout(() => {
      setDialog(dialogToRender.render());
    }, dialogToRender.timeToOpen || 0);

    return () => clearTimeout(timer);
  }, [dialogs, setDialog]);
}
