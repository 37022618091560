import React, { useState, useCallback } from 'react';
import { styled, colors } from 'styles';
import { TextField, InputAdornment } from '@material-ui/core';
import { useRAPostRequest } from 'modules/shared/hooks/requests';

export default function BookEditionSuggestion({ book }) {
  const [openSuggestion, setOpenSuggestion] = useState(false);
  const [suggestion, setSuggestion] = useState('');
  const [sending, setSending] = useState(false);

  const saveBookSuggestionReq = useRAPostRequest('create_book_suggestion');

  const showError = useCallback(() => {
    alert(
      'Tivemos um problema para salvar sua sugestão. Tente novamente mais tarde!'
    );
  }, []);

  const getBookAuthor = useCallback(book => {
    return book.author || book.name.split('-')[0];
  }, []);

  const onSubmitSuggestion = useCallback(async () => {
    if (!suggestion) {
      alert('Por favor, digite uma sugestão!');
      return;
    }

    if (sending) return;

    setSending(true);

    const finalSuggestion = `${getBookAuthor(book)} - ${
      book.volume
    } - ${suggestion}`;

    try {
      await saveBookSuggestionReq({
        bookIndication: finalSuggestion,
      });
      alert('Sugestão recebida com sucesso!');
      setOpenSuggestion(false);
      setSuggestion('');
    } catch (e) {
      showError();
    }

    setSending(false);
  }, [
    saveBookSuggestionReq,
    getBookAuthor,
    suggestion,
    book,
    sending,
    showError,
  ]);

  const onChangeSuggestion = useCallback(
    event => {
      setSuggestion(event.target.value);
    },
    [setSuggestion]
  );

  return (
    <EditionSuggestion>
      {openSuggestion ? (
        <TextField
          label="Dê sua sugestão"
          placeholder="Ex: 7ª Edição"
          variant="outlined"
          value={suggestion}
          onChange={onChangeSuggestion}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SendButton onClick={onSubmitSuggestion}>
                  {sending ? 'Enviando...' : 'Enviar'}
                </SendButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <EditionSuggestionCTA onClick={() => setOpenSuggestion(true)}>
          Preciso de outra edição
        </EditionSuggestionCTA>
      )}
    </EditionSuggestion>
  );
}

const EditionSuggestion = styled.div`
  label.Mui-focused {
    color: ${colors.darkGray};
  }

  .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: ${colors.darkGray};
  }
`;

const EditionSuggestionCTA = styled.div`
  border-bottom: 1px solid;
  padding-bottom: 0.5px;
  font-size: 0.85em;
  color: #14b9a6;
  font-weight: bold;
  cursor: pointer;
`;

const SendButton = styled.div`
  background-color: ${colors.mediumGray};
  color: #fff;
  padding: 5px 10px;
  border-radius: 7px;
  cursor: pointer;
`;
