import React, { useCallback, useMemo, useEffect } from 'react';

import { styled, colors, media } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker } from 'modules/shared/hooks';
import CancelConfirmation from 'modules/profile/dialogs/cancellation/CancelConfirmation';
import FreezeSubscriptionChooseDaysDialog from './FreezeSubscriptionChooseDays';
import { useFreezeSubscriptionCampaign } from 'modules/campaigns/freeze-subscription/hooks';

export default function FreezeSubscriptionCTA({
  subscription,
  selectedReason,
  selectedDetailedReason,
  otherReason,
}) {
  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const { getPriceAfterFreeze } = useFreezeSubscriptionCampaign();

  useEffect(() => {
    trackEvent('Congelamento Assinatura: CTA Visualizou', {
      Beneficio: subscription.hasDiscount
        ? 'Manter desconto'
        : 'Ganhar desconto',
    });
  }, [subscription, trackEvent]);

  const handleFreezeSubscriptionPress = useCallback(() => {
    trackEvent('Congelamento Assinatura: CTA Clicou Proximo');
    setDialog(
      <FreezeSubscriptionChooseDaysDialog subscription={subscription} />
    );
  }, [setDialog, subscription, trackEvent]);

  const handleCancellationPress = useCallback(() => {
    trackEvent('Congelamento Assinatura: CTA Clicou Cancelar');
    setDialog(
      <CancelConfirmation
        subscription={subscription}
        selectedReason={selectedReason}
        selectedDetailedReason={selectedDetailedReason}
        otherReason={otherReason}
      />
    );
  }, [
    setDialog,
    trackEvent,
    subscription,
    selectedReason,
    selectedDetailedReason,
    otherReason,
  ]);

  const formattedPrice = useMemo(() => {
    const planMonths = subscription.plan.months;
    const price = subscription.order.planPricing.price;
    if (planMonths === 1) {
      return `R$${price.toFixed(2)}/mes`;
    }

    const pricePerMonth = price / planMonths;
    return `${planMonths}xR$${pricePerMonth.toFixed(2)}`;
  }, [subscription]);

  const planDescription = useMemo(() => {
    return subscription.plan.description.toLowerCase();
  }, [subscription]);

  const renderSubscriptionWithDiscountCTA = () => (
    <>
      <Title>
        Deseja pausar a sua assinatura pelo período das férias e manter seu
        desconto?
      </Title>

      <Paragraph>
        Assim, você mantém o seu desconto e continua pagando {formattedPrice}{' '}
        depois das férias!
        <br />
        <br />
        Você pode pausar seu plano por até 60 dias, não precisando pagar nesse
        período! Ao fim desse período, seu plano {planDescription} vai renovar
        automaticamente!
        <br />
        <br />
        Atenção: você não terá acesso ao conteúdo do Responde aí enquanto sua
        assinatura estiver pausada. Mas você pode retomar a sua assinatura
        quando quiser!
      </Paragraph>
    </>
  );

  const renderSubscriptionWithoutDiscountCTA = () => {
    return (
      <>
        <Title>
          Deseja pausar a sua assinatura pelo período de férias e ainda ganhar
          15% de desconto?
        </Title>
        <Paragraph>
          Você pode pausar seu plano por até 60 dias, não precisando pagar nesse
          período! Ao fim desse período, seu plano {planDescription} vai renovar
          automaticamente e você passa a pagar apenas{' '}
          <strong>{getPriceAfterFreeze(subscription)}.</strong>
          <br />
          <br />
          <strong>Atenção:&nbsp;</strong>
          você não terá acesso ao conteúdo do Responde Aí enquanto sua
          assinatura estiver pausada. Mas você pode retomar a sua assinatura
          quando quiser!
        </Paragraph>
      </>
    );
  };

  return (
    <Container>
      <Content>
        <CancelStrip>Cancelar Assinatura</CancelStrip>
        {subscription.hasDiscount
          ? renderSubscriptionWithDiscountCTA()
          : renderSubscriptionWithoutDiscountCTA()}
        <ButtonsContainer>
          <button onClick={handleCancellationPress} type="button">
            Perder desconto para sempre
          </button>

          <button onClick={handleFreezeSubscriptionPress} className="positive">
            Próximo
          </button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-top: 10px solid ${colors.warningRed};
  text-align: left;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const CancelStrip = styled.div`
  display: inline-block;
  padding: 3px 10px;
  position: relative;
  left: -48px;
  margin-bottom: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${colors.warningRed};
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  @media ${media.mobile} {
    left: -24px;
  }
`;

const Title = styled.h1`
  margin-bottom: 15px;
  font-size: 1.6em;
  font-weight: bold;
  color: ${colors.warningRed};
`;

const Paragraph = styled.p`
  font-size: 1em;
  margin: 20px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 500px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 60px;

  button {
    width: 45%;
    font-size: 0.95em;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 20px 20px 0;
    height: 50px;
    border: 2px solid #989898;
    color: #989898;
    text-transform: uppercase;
    font-weight: bold;

    &.positive {
      border-color: ${colors.darkGreen};
      color: ${colors.darkGreen};
    }
  }

  @media ${media.mobile} {
    flex-direction: column-reverse;

    button {
      width: 95%;
    }
  }
`;
