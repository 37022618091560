import React, { useCallback, useMemo } from 'react';

import { NoAccessDialogFormerSubscriber } from 'modules/campaigns/recover-subscriber/former-subscriber-recover/dialogs';
import {
  useRAQueryImmutable,
  useRAPostRequest,
} from 'modules/shared/hooks/requests';
import { useUTM } from 'modules/shared/hooks';

function useFormerSubscriberRecoverCampaign() {
  const { data: formerSubscriberRecoverData } = useRAQueryImmutable(
    '/campaigns/former_subscriber_recover'
  );
  const { getUTMsByLocation } = useUTM();

  const recoverPaymentReq = useRAPostRequest('/campaigns/recover_subscriber');

  const isOnFormerSubscriberRecoverCampaign = useCallback(() => {
    return (
      !!formerSubscriberRecoverData &&
      !formerSubscriberRecoverData?.isEligibleFromTrial
    );
  }, [formerSubscriberRecoverData]);

  const isOnFormerTrialRecoverCampaign = useCallback(() => {
    return !!formerSubscriberRecoverData?.isEligibleFromTrial;
  }, [formerSubscriberRecoverData]);

  const pricings = useMemo(() => {
    formerSubscriberRecoverData?.pricings.forEach(pricing => {
      const pricingToShow = pricing?.monthlyPricing
        .toFixed(2)
        .toString()
        .replace('.', ',');
      pricing.monthlyPricingToShow = pricingToShow;
      pricing.priceToShow = formerSubscriberRecoverData?.price?.replace(
        '.',
        ','
      );
    });

    return formerSubscriberRecoverData?.pricings;
  }, [formerSubscriberRecoverData]);

  const card = useMemo(() => {
    return formerSubscriberRecoverData?.card;
  }, [formerSubscriberRecoverData]);

  const paymentMethod = useMemo(() => {
    return formerSubscriberRecoverData?.paymentMethod;
  }, [formerSubscriberRecoverData]);

  const openFormerSubscriberRecoverOnOpenPage = useCallback(() => {
    return !!formerSubscriberRecoverData && !isOnFormerTrialRecoverCampaign();
  }, [formerSubscriberRecoverData, isOnFormerTrialRecoverCampaign]);

  const openTrialSubscriberRecoverOnOpenPage = useCallback(() => {
    return !!formerSubscriberRecoverData && isOnFormerTrialRecoverCampaign();
  }, [formerSubscriberRecoverData, isOnFormerTrialRecoverCampaign]);

  const recoverPayment = useCallback(
    async (pricing, paymentMethod, cardId, creditCard, type) => {
      try {
        const result = await recoverPaymentReq({
          type: type,
          cardId,
          creditCard,
          planPricingId: pricing.id,
          paymentMethod,
        });
        return { ...result.data, success: true };
      } catch (e) {
        return { success: false };
      }
    },
    [recoverPaymentReq]
  );

  const hasFormerSubscriberRecoverQueryParams = useCallback(location => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);

    return !!searchParams.get('recuperacao_ex_assinante');
  }, []);

  const openFormerSubscriberRecoverDialogAutomaticallyByUrl = useCallback(
    location => {
      const isEligibleToFormerSubscriberRecover = isOnFormerSubscriberRecoverCampaign();
      return (
        hasFormerSubscriberRecoverQueryParams(location) &&
        isEligibleToFormerSubscriberRecover
      );
    },
    [isOnFormerSubscriberRecoverCampaign, hasFormerSubscriberRecoverQueryParams]
  );

  const getFormerSubscriberRecoverDialogByUrl = useCallback(location => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);

    const utmSourceQueryParam =
      searchParams.get('utm_source') || searchParams.get('amp;utm_source');
    return <NoAccessDialogFormerSubscriber source={utmSourceQueryParam} />;
  }, []);

  const defaultAmplitudeEventAttributes = useMemo(() => {
    return getUTMsByLocation(window.location);
  }, [getUTMsByLocation]);

  return {
    card,
    pricings,
    paymentMethod,
    openFormerSubscriberRecoverOnOpenPage,
    openTrialSubscriberRecoverOnOpenPage,
    recoverPayment,
    isOnFormerSubscriberRecoverCampaign,
    openFormerSubscriberRecoverDialogAutomaticallyByUrl,
    getFormerSubscriberRecoverDialogByUrl,
    hasFormerSubscriberRecoverQueryParams,
    isOnFormerTrialRecoverCampaign,
    defaultAmplitudeEventAttributes,
  };
}

export { useFormerSubscriberRecoverCampaign };
