import Axios from 'axios';

export async function execAPICall(data, index) {
  // const url = `${process.env.REACT_APP_ELASTIC_SEARCH_STAGING_URL}/${index}/_search`;
  const url = `${process.env.REACT_APP_ELASTIC_SEARCH_PRODUCTION_URL}/${index}/_search`;

  const headers = {
    // Authorization: `ApiKey ${process.env.REACT_APP_ELASTIC_SEARCH_STAGING_API_KEY}`,
    Authorization: `ApiKey ${process.env.REACT_APP_ELASTIC_SEARCH_API_KEY}`,
  };

  headers['Content-encoding'] = 'gzip, deflate';

  const response = await Axios.post(url, data, { headers });

  return response.data;
}
