import { useCallback, useMemo } from 'react';

export default function useLiveLectures(liveLectures = [], themes) {
  const listOfThemesWithLiveLectures = useMemo(() => {
    const liveLecturesThemesIds = liveLectures.map(
      liveLecture => liveLecture.themeId
    );
    return themes.filter(theme => liveLecturesThemesIds.includes(theme.id));
  }, [liveLectures, themes]);

  const getLecturesByTheme = useCallback(
    themeId => {
      const lectures = liveLectures.filter(
        lecture => lecture.themeId === themeId
      );
      return lectures;
    },
    [liveLectures]
  );

  return {
    listOfThemesWithLiveLectures,
    getLecturesByTheme,
  };
}
