import React, { useState, useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';

import { styled, colors } from 'styles';
import stars from 'modules/feedback/consts/syllabus-feedback-stars';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker } from 'modules/shared/hooks';
import { useSyllabusFeedback } from 'modules/feedback/hooks';
import scaredDexterImg from 'images/syllabus-feedback/macaco-assustado.png';
import SuccessSylabusFeedbackDialog from './SuccessSylabusFeedbackDialog';

export default function FinishSyllabusFeedbackDialog({
  initialSelectedStar,
  setOpenSyllabus,
  contentName,
  guidableId,
}) {
  const [showDexter, setShowDexter] = useState(false);
  const [selectedStar, setSelectedStar] = useState(initialSelectedStar);
  const [feedbackComments, setFeedbackComments] = useState('');
  const [openToggle, setOpenToggle] = useState(true);
  const { setDialog, unsetDialog } = useDialogContext();
  const {
    getSyllabusFeedbackEventAttributes,
    sendSyllabusFeedback,
    saveSyllabusFeedbackFlag,
  } = useSyllabusFeedback();
  const { trackEvent } = useTracker();

  useEffect(() => {
    setTimeout(() => setShowDexter(true), 300);
  }, [setShowDexter]);

  const isFillStar = useCallback(
    starToCheck => {
      return parseInt(starToCheck.id) <= parseInt(selectedStar.id);
    },
    [selectedStar]
  );

  const onStarClick = useCallback(
    star => {
      trackEvent(
        'Feedback: Ementa - Clicou Estrela',
        getSyllabusFeedbackEventAttributes(star, contentName)
      );

      if (star.id === 5) {
        sendSyllabusFeedback(star, contentName);
        saveSyllabusFeedbackFlag(guidableId);
        setOpenToggle(false);
        setOpenSyllabus(false);
        setDialog(<SuccessSylabusFeedbackDialog />);
        return;
      }

      setOpenToggle(false);
      setTimeout(() => {
        setSelectedStar(star);
        setOpenToggle(true);
      }, 650);
    },
    [
      setSelectedStar,
      trackEvent,
      getSyllabusFeedbackEventAttributes,
      sendSyllabusFeedback,
      saveSyllabusFeedbackFlag,
      setOpenSyllabus,
      contentName,
      guidableId,
      setDialog,
    ]
  );

  const onCloseDialog = useCallback(() => {
    setOpenToggle(false);
    setTimeout(() => {
      setOpenSyllabus(true);
      unsetDialog();
    }, 600);
  }, [unsetDialog, setOpenToggle, setOpenSyllabus]);

  const sendFeedback = useCallback(() => {
    if (!feedbackComments) {
      alert(
        'Por favor, preencha com mais informações sobre o que podemos melhorar :)'
      );
      return;
    }

    sendSyllabusFeedback(selectedStar, contentName, feedbackComments);
    saveSyllabusFeedbackFlag(guidableId);
    setOpenToggle(false);
    setOpenSyllabus(false);
    setDialog(<SuccessSylabusFeedbackDialog />);
  }, [
    contentName,
    feedbackComments,
    selectedStar,
    guidableId,
    sendSyllabusFeedback,
    saveSyllabusFeedbackFlag,
    setOpenToggle,
    setOpenSyllabus,
    setDialog,
  ]);

  return (
    <Container>
      <ToggleableComponent
        width="100%"
        height="600px"
        animationTime="0.6s"
        open={openToggle}
      >
        <Dexter src={scaredDexterImg} className={showDexter ? 'show' : ''} />
        <Content>
          {selectedStar.id <= 3 && (
            <>
              <Message>Poxa, temos que melhorar!</Message>
              <Message>Pode nos dizer no que estamos errando?</Message>
            </>
          )}
          {selectedStar.id === 4 && (
            <Message>Opa! O que poderia estar melhor?</Message>
          )}
          <StarsContainer>
            {stars.map(star => (
              <StarIcon
                icon={isFillStar(star) ? faStarSolid : faStarRegular}
                key={star.id}
                className={isFillStar(star) ? 'filled' : ''}
                onClick={() => onStarClick(star)}
              />
            ))}
          </StarsContainer>
          <StarText>{selectedStar.description}</StarText>
          <TextArea
            placeholder="O que podemos melhorar?"
            value={feedbackComments}
            onChange={e => setFeedbackComments(e.target.value)}
          />
          <SendButton onClick={sendFeedback}>Enviar</SendButton>
        </Content>
        <CloseButton onClick={onCloseDialog}>Cancelar</CloseButton>
      </ToggleableComponent>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  max-width: 95%;
  margin: auto;
  padding: 20px 25px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 10px ${colors.lightGray};
`;

const CloseButton = styled.div`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
  cursor: pointer;
  margin-top: 20px;
`;

const Message = styled.div`
  font-weight: bold;
  font-size: 0.8em;
  line-height: 1.1;
`;

const StarsContainer = styled.div`
  display: flex;
  margin-top: 15px;
`;

const StarIcon = styled(FontAwesomeIcon)`
  font-size: 1.8em;
  cursor: pointer;
  color: ${colors.mediumGray};
  margin: 0 8px;

  &.filled {
    color: #ffda1a;
  }
`;

const StarText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.85em;
  color: #dcbc18;
  font-weight: bold;
  margin-top: 10px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 10px;
  resize: none;
  background-color: #eee;
  border: none;
  margin-top: 25px;
  border-radius: 10px;
  font-size: 0.9em;

  &::placeholder {
    font-style: italic;
  }
`;

const SendButton = styled.div`
  width: 110px;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  background-color: #f7ac3c;
  margin-top: 15px;
  font-weight: bold;
  font-size: 0.8em;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
`;

const Dexter = styled.img`
  position: relative;
  width: 100px;
  bottom: -100px;
  opacity: 0;
  transition: bottom 0.5s ease-in-out, opacity 1s ease-in-out;

  &.show {
    bottom: -10px;
    opacity: 1;
  }
`;
