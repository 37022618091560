import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { media, styled } from 'styles';

import { MathJaxWithLatexProvider } from 'providers';
import { useContentQuery } from 'modules/shared/hooks/requests';
import { QuestionContent, QuestionTopBar } from '../components/shared';
import {
  GenerateAiResponse,
  Statement,
} from '../components/ListWithStatementQuestionSolvingByAiPage';
import useRenderListWithStatementQuestionPageDialog from '../hooks/useRenderListWithStatementQuestionPageDialog';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ShowAiResponse } from '../components/ListWithStatementQuestionSolvingByAiPage/ShowAiResponse';
import { useQuestions, useTrackEvents } from '../hooks';
import { PageSkeleton } from '../components/ListWithStatementQuestion';

export function ListWithStatementQuestionSolvingByAiPage() {
  const { listId, questionId } = useParams();
  const history = useHistory();
  const { trackViewQuestion } = useTrackEvents();
  const { isQuestionBlocked } = useQuestions();

  const { data: list, mutate: refetchList } = useContentQuery(
    `/mvp/lists-with-statements/${listId}`
  );

  const { data: question, error } = useContentQuery(
    `/mvp/lists-with-statements/questions/ai/${questionId}`
  );

  useEffect(() => {
    if (!question) return;

    trackViewQuestion(
      { ...question, exerciseType: 'MvpExercise' },
      question.mvpListWithStatement
    );
  }, [question, trackViewQuestion]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useRenderListWithStatementQuestionPageDialog({
    isQuestionBlocked: isQuestionBlocked(list, questionId),
    exerciseType: 'MvpExercise',
  });

  if (error) {
    history.push(`/lista-de-enunciado/${listId}/questao/${questionId}`);
    return;
  }

  if (!question) return <PageSkeleton />;

  return (
    <MathJaxWithLatexProvider>
      <Container>
        <QuestionTopBar listId={listId} style={{ marginTop: '20px' }} />

        <QuestionContent>
          <Statement question={question} />
          {!question.exercise && (
            <GenerateAiResponse question={question} refetchList={refetchList} />
          )}
          {question.exercise && (
            <ShowAiResponse
              question={question}
              refetchList={refetchList}
              list={list}
            />
          )}
        </QuestionContent>
      </Container>
    </MathJaxWithLatexProvider>
  );
}

const Container = styled.div`
  width: 1080px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media ${media.mobile} {
    margin-top: 80px;
  }
`;
