import { useTracker } from 'modules/shared/hooks';
import { useCallback } from 'react';
import { useUTM } from 'modules/shared/hooks';

const useLearningPathTracker = () => {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const getDefaultUtmProperties = useCallback(() => {
    const utms = getUTMsByLocation(window.location);

    return {
      ...utms,
    };
  }, [getUTMsByLocation]);

  const trackSubjectDialogView = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Visualizou Tela de Assuntos', {
        ...props,
        ...getDefaultUtmProperties(),
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );
  const trackSubjectClick = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Selecionou Assunto', {
        ...props,
        ...getDefaultUtmProperties(),
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );

  const trackExamDateDialogView = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Visualizou Tela de Data de Prova', {
        ...props,
        ...getDefaultUtmProperties(),
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );
  const trackExamDateClick = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Informou Data de Prova', {
        ...props,
        ...getDefaultUtmProperties(),
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );

  const trackDurationDialogView = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Visualizou Tela de Duração da Trilha', {
        ...props,
        ...getDefaultUtmProperties(),
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );
  const trackDurationClick = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Selecionou Duração da Trilha', {
        ...props,
        ...getDefaultUtmProperties(),
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );

  const trackLearningPathCreation = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Criou Trilha', {
        ...props,
        ...getDefaultUtmProperties(),
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );

  const trackErrorOnLearningPathCriation = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Erro ao tentar criar a trilha', {
        ...props,
        ...getDefaultUtmProperties(),
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );

  const trackLearningPathView = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Visualizou trilha de Estudos', {
        ...props,
        ...getDefaultUtmProperties(),
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );
  const trackTopicClick = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Clicou em Conteúdo', {
        ...props,
        ...getDefaultUtmProperties(),
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );

  const trackBlockedDayClick = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Clicou Conteúdo Bloqueado', {
        ...props,
        ...getDefaultUtmProperties(),
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );

  const trackCloseForm = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Fechou Dialog', {
        ...props,
        ...getDefaultUtmProperties,
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );

  const trackClickBlockedContent = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Clicou Cadeado', {
        ...props,
        ...getDefaultUtmProperties(),
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );

  const trackClickContent = useCallback(
    props => {
      trackEvent('Trilha de Estudos: Clicou conteúdo', {
        ...props,
        ...getDefaultUtmProperties(),
      });
    },
    [getDefaultUtmProperties, trackEvent]
  );

  return {
    trackBlockedDayClick,
    trackDurationClick,
    trackDurationDialogView,
    trackExamDateClick,
    trackExamDateDialogView,
    trackLearningPathView,
    trackSubjectClick,
    trackSubjectDialogView,
    trackTopicClick,
    trackLearningPathCreation,
    trackErrorOnLearningPathCriation,
    trackCloseForm,
    trackClickBlockedContent,
    trackClickContent,
  };
};

export default useLearningPathTracker;
