import { useCookies } from 'modules/shared/hooks';
import { useCallback } from 'react';

export default function useMonitorSearchUsage() {
  const [cookies, setCookie] = useCookies(['view_search_counter']);

  const increaseCookiesCounter = useCallback(
    cookieName => {
      const counter = cookies[cookieName];

      let counterToSet = 1;
      if (counter) {
        counterToSet = parseInt(counter) + 1;
      }

      setCookie(cookieName, counterToSet);
    },
    [cookies, setCookie]
  );

  const increaseViewCounter = useCallback(() => {
    increaseCookiesCounter('view_search_counter');
  }, [increaseCookiesCounter]);

  const getSearchViewCounter = useCallback(() => {
    const counter = cookies['view_search_counter'];
    return counter ? parseInt(counter) : 0;
  }, [cookies]);

  return {
    increaseViewCounter,
    getSearchViewCounter,
  };
}
