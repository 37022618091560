import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useWorkspaceGuide } from 'modules/workspace/hooks/shared';
import useWorkspace from './useWorkspace';

export default function useWorkspaceMainLinks() {
  const { guideId, subjectId, topicId, chapterId } = useParams();
  const { isGuideWorkspace } = useWorkspaceGuide();
  const {
    isExtraTimePageWorkspace,
    isFixationExerciseWorkspace,
  } = useWorkspace();

  const getExerciseLink = useCallback(
    exercise => {
      if (isFixationExerciseWorkspace()) {
        if (isGuideWorkspace())
          return `/aprender/${guideId}/topico/${subjectId}/${topicId}/exercicio/${exercise.id}`;
        return `/aprender/topico/${subjectId}/${topicId}/exercicio/${exercise.id}`;
      }

      if (isExtraTimePageWorkspace()) {
        if (isGuideWorkspace()) {
          return `/aprender/${guideId}/extras/${subjectId}/${chapterId}/exercicio-lista/${exercise.id}`;
        }
        return `/aprender/extras/${subjectId}/${chapterId}/exercicio-lista/${exercise.id}`;
      }

      if (isGuideWorkspace())
        return `/aprender/${guideId}/topico/${subjectId}/${topicId}/exercicio-lista/${exercise.id}`;

      return `/aprender/topico/${subjectId}/${topicId}/exercicio-lista/${exercise.id}`;
    },
    [
      guideId,
      subjectId,
      chapterId,
      topicId,
      isGuideWorkspace,
      isFixationExerciseWorkspace,
      isExtraTimePageWorkspace,
    ]
  );

  return { getExerciseLink };
}
