import { React, useEffect } from 'react';
import { useDialogContext } from 'providers/DialogProvider';

import { useBookTrialCampaign } from '../hooks';
import { BookCreditCardTrialCTADialog } from '../components';
import ThemesPage from 'modules/main/pages/ThemesPage';
import { useUserContext } from 'providers/UserProvider';

export default function CreditCardTrialPage() {
  const { setDialog } = useDialogContext();
  const { showBookCreditCardTrialCTA } = useBookTrialCampaign();
  const { user } = useUserContext();

  useEffect(() => {
    if (showBookCreditCardTrialCTA()) {
      // In this page, it is only one possible dialog => BookCreditCardTrialCTADialog
      // eslint-disable-next-line custom-rules/set-dialog
      setDialog(<BookCreditCardTrialCTADialog source="lp" />);
    }
  }, [showBookCreditCardTrialCTA, setDialog, user]);

  return <ThemesPage />;
}
