import React from 'react';

import { styled } from 'styles';
import FeelingElement from './FeelingElement';

export default function ContentFeedbackFeeling({
  updateFeedback,
  feelings,
  feelingsOptions,
}) {
  function AddOrRemoveFeeling(feeling) {
    const index = feelings.indexOf(feeling);

    if (index !== -1)
      return updateFeedback(feelings.filter((val, i) => i !== index));
    updateFeedback([...feelings, feeling]);
  }

  return (
    <>
      <div className="header">O que podemos melhorar?</div>
      <FeelingContainer>
        {feelingsOptions.map(feeling => (
          <FeelingElement
            key={feeling.id}
            AddOrRemoveFeeling={AddOrRemoveFeeling}
            feelings={feelings}
            text={feeling.text}
            icon={feeling.iconSrc}
          />
        ))}
      </FeelingContainer>
    </>
  );
}

const FeelingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: #bcbcbc;
  justify-content: space-evenly;

  img {
    max-width: 30px;
    max-height: 30px;
  }
`;
