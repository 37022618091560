import React from 'react';

import { styled, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';

function GenericDialog({ title, message }) {
  const { unsetDialog } = useDialogContext();

  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        {message && <Message>{message}</Message>}
        <ButtonContainer>
          <Button onClick={unsetDialog}>Ok</Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
}

export default GenericDialog;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
`;

const Content = styled.div`
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate(0, 0) scale(1);
  border-radius: 4px;
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  min-width: 240px;
  max-width: 80%;
  max-height: 80%;
  position: relative;
  overflow: auto;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  padding: 10px 24px;
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: 0.005em;
`;

const Message = styled.p`
  font-size: 1em;
  padding: 0px 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 12px;
`;

const Button = styled.button`
  color: ${colors.mainOrange};
  margin: 8px;
  box-sizing: border-box;
  min-width: 88px;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;

  &:hover {
    background-color: rgba(158, 158, 158, 0.2);
  }
`;
