import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useDialogContext } from 'providers/DialogProvider';
import { styled, media } from 'styles';
import { landscape } from '../../images';
import { MdClose } from 'react-icons/md';
import CourseInfo from './CourseInfo';
import DisciplineInfo from './DisciplineInfo';
import FileInfo from './FileInfo';
import UploadSession from './UploadSession';
import { useRAPostFileRequest } from 'modules/shared/hooks/requests';
import Confirmation from './Confirmation';
import SuccessSession from './SuccessSession';
import { useEscClick, useTracker } from 'modules/shared/hooks';
import ExitConfirmation from './ExitConfirmation';

export default function UploadFileDialog({ theme }) {
  const { unsetDialog } = useDialogContext();
  const [courseInfo, setCourseInfo] = useState(null);
  const [materialType, setMaterialType] = useState(null);
  const [discipline, setDiscipline] = useState(null);
  const [testInfo, setTestInfo] = useState(null);
  const [file, setFile] = useState(null);
  const [step, setStep] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updatedUniversity, setUpdatedUniversity] = useState(null);
  const [sendAgain, setSendAgain] = useState(false);
  const [isThemesMenuOpen, setIsThemesMenuOpen] = useState(false);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(theme);

  const { trackEvent } = useTracker();
  const ref = useRef();

  useEscClick(ref, () => {
    handleClose();
  });

  const isMobile = useMemo(() => {
    return media.isMobile();
  }, []);

  const sendMaterial = useRAPostFileRequest('/materials');

  const steps = useMemo(() => {
    return {
      COURSE: 1,
      DISCIPLINE: 2,
      FILE_INFO: 3,
      UPLOAD: 4,
      CONFIRMATION: 5,
      SUCCESS: 6,
    };
  }, []);

  useEffect(() => {
    const header = document.getElementsByClassName('header');
    if (header[0]) header[0].style.display = 'none';

    setStep(steps.COURSE);

    return () => {
      if (header[0]) header[0].style.display = 'block';
    };
  }, [steps.COURSE]);

  const handleResetInfo = useCallback(() => {
    setCourseInfo(null);
    setDiscipline(null);
    setTestInfo(null);
    setFile(null);
    setStep(steps.COURSE);
    setSendAgain(true);
  }, [
    setCourseInfo,
    setDiscipline,
    setTestInfo,
    setFile,
    setStep,
    setSendAgain,
    steps.COURSE,
  ]);

  const submitForm = useCallback(async () => {
    setLoading(true);
    const dataToSend = {
      materialType,
      disciplineId: discipline?.id,
      disciplineName: discipline?.name,
      testInfo,
    };

    try {
      await sendMaterial(file, dataToSend);

      setStep(steps.SUCCESS);
    } catch (e) {
      return e;
    } finally {
      setLoading(false);
    }
  }, [sendMaterial, materialType, discipline, testInfo, file, setStep, steps]);

  const handleClose = useCallback(() => {
    if (isThemesMenuOpen) {
      setIsThemesMenuOpen(false);
      return;
    }
    if (step != steps.SUCCESS) {
      setShowExitConfirmation(true);
      return;
    }
    trackEvent('Listas: Fechou Dialog');
    unsetDialog();
  }, [
    isThemesMenuOpen,
    setIsThemesMenuOpen,
    setShowExitConfirmation,
    unsetDialog,
    step,
    steps,
    trackEvent,
  ]);

  return (
    <Container isMobile={isMobile} ref={ref}>
      {showExitConfirmation ? (
        <ExitConfirmation
          handleContinue={() => setShowExitConfirmation(false)}
        />
      ) : (
        <>
          <img src={landscape} className="background" alt="paisagem" />
          <MdClose className="close-icon" onClick={handleClose} />
          {step === steps.COURSE && (
            <CourseInfo
              onSubmitInfo={() => setStep(steps.DISCIPLINE)}
              setCourseInfo={setCourseInfo}
              courseInfo={courseInfo}
              setMaterialType={setMaterialType}
              materialType={materialType}
              updatedUniversity={updatedUniversity}
              setUpdatedUniversity={setUpdatedUniversity}
              sendAgain={sendAgain}
            />
          )}
          {step === steps.DISCIPLINE && (
            <DisciplineInfo
              selectedTheme={selectedTheme}
              setDiscipline={setDiscipline}
              onFormBack={() => setStep(steps.COURSE)}
              onSubmitInfo={() => setStep(steps.FILE_INFO)}
              materialType={materialType}
              discipline={discipline}
              isThemesMenuOpen={isThemesMenuOpen}
              setIsThemesMenuOpen={setIsThemesMenuOpen}
              setSelectedTheme={setSelectedTheme}
            />
          )}
          {step === steps.FILE_INFO && (
            <FileInfo
              materialType={materialType}
              testInfo={testInfo}
              setTestInfo={setTestInfo}
              onSubmitInfo={() => setStep(steps.UPLOAD)}
              onFormBack={() => setStep(steps.DISCIPLINE)}
              discipline={discipline}
              selectedTheme={selectedTheme}
            />
          )}
          {step === steps.UPLOAD && (
            <UploadSession
              materialType={materialType}
              file={file}
              setFile={setFile}
              onSubmitInfo={() => setStep(steps.CONFIRMATION)}
              onFormBack={() => setStep(steps.FILE_INFO)}
              discipline={discipline}
              selectedTheme={selectedTheme}
              testInfo={testInfo}
            />
          )}
          {step === steps.CONFIRMATION && (
            <Confirmation
              materialType={materialType}
              discipline={discipline}
              testInfo={testInfo}
              file={file}
              onSubmitInfo={submitForm}
              onFormBack={() => setStep(steps.UPLOAD)}
              loading={loading}
            />
          )}
          {step === steps.SUCCESS && (
            <SuccessSession
              onSubmitInfo={unsetDialog}
              onFormBack={handleResetInfo}
              discipline={discipline}
              selectedTheme={selectedTheme}
              materialType={materialType}
              testInfo={testInfo}
            />
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '' : '100%')};
  position: relative;
  padding-top: 50px;

  background-color: #fff;
  justify-content: center;

  .background {
    position: fixed;
    left: -100px;
    bottom: 0;
    opacity: 0.8;
    z-index: 0;
  }

  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.7rem;
    color: #888;
    cursor: pointer;
    z-index: 3;

    &:hover {
      transform: scale(1.3);
      color: #555;
      transition: color 180ms ease-in-out;
      transition: transform 180ms ease-in-out;
    }
  }
  @media ${media.mobile} {
    height: '';
    padding-bottom: 50px;
  }
`;
