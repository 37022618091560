import { useCookies } from 'modules/shared/hooks';
import { useCallback } from 'react';

export function useSupportMaterialBanner() {
  const [cookies, setCookie] = useCookies([
    'viewSupportBanner',
    'notShowSupportBanner',
  ]);

  const isEligibleToBanner = useCallback(() => {
    const hasNotShowSupportBannerCookie = !!cookies.notShowSupportBanner;
    if (hasNotShowSupportBannerCookie) return false;

    const viewCount = cookies.viewSupportBanner;

    return !viewCount || parseInt(viewCount) < 6;
  }, [cookies.notShowSupportBanner, cookies.viewSupportBanner]);

  const setCookieShouldNotShowBanner = useCallback(() => {
    setCookie('notShowSupportBanner', true);
  }, [setCookie]);

  const incrementViewBanner = useCallback(() => {
    const viewCookie = cookies.viewSupportBanner;

    if (!viewCookie) return setCookie('viewSupportBanner', 1);

    setCookie('viewSupportBanner', parseInt(viewCookie) + 1);
  }, [cookies.viewSupportBanner, setCookie]);

  const bannerWillAppearInThePage = useCallback(() => {
    const hasNotShowSupportBannerCookie = !!cookies.notShowSupportBanner;
    if (hasNotShowSupportBannerCookie) return false;

    const viewCount = cookies.viewSupportBanner;

    return !viewCount || parseInt(viewCount) < 5;
  }, [cookies.notShowSupportBanner, cookies.viewSupportBanner]);

  return {
    isEligibleToBanner,
    incrementViewBanner,
    setCookieShouldNotShowBanner,
    bannerWillAppearInThePage,
  };
}
