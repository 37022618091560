import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled, media, colors } from 'styles';
import { MenuItem, TextField } from '@material-ui/core';
import {
  useContentQueryImmutable,
  useContentRestRequest,
} from 'modules/shared/hooks/requests';
import { Error, Loading } from 'modules/shared/components/generic';
import { useToast } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import { useProfessorTracker } from 'modules/professors/hooks/useProfessorTracker';
import { themesIdsToShow } from 'modules/campaigns/mvp-books/data';
import { ProfessorSelector } from '../shared/components';

export default function CreateUserProfessorDialog({
  defaultThemeId = null,
  defaultDisciplineId = null,
  defaultDisciplineType = null,
  defaultSubject = null,
  availableDisciplines = [],
  color = null,
  showRejectCta = false,
  source = '',
}) {
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [selectedDiscipline, setSelectedDiscipline] = useState(null);
  const [selectedProfessor, setSelectedProfessor] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    trackUserProfessorCreated,
    trackOpenUserProfessorCreateDialog,
    trackCloseUserProfessorCreateDialog,
    trackRejectInformProfessor,
  } = useProfessorTracker();

  const { errorToast } = useToast();
  const { sendPostRequest } = useContentRestRequest();
  const { unsetDialog } = useDialogContext();

  const { data: themes, themesError, themesLoading } = useContentQueryImmutable(
    '/mvp/themes-with-disciplines-and-professors'
  );

  useEffect(() => {
    trackOpenUserProfessorCreateDialog(source);
  }, [source, trackOpenUserProfessorCreateDialog]);

  const shouldShowThemeAndDisciplineInput = useCallback(() => {
    return !defaultDisciplineId;
  }, [defaultDisciplineId]);

  const loadDefaultParams = useCallback(() => {
    if (defaultThemeId && !selectedTheme && themes) {
      setSelectedTheme(themes.find(theme => theme.id === defaultThemeId));
    }

    if (defaultDisciplineId && !selectedDiscipline && selectedTheme) {
      if (defaultDisciplineType === 'MvpDiscipline') {
        setSelectedDiscipline(
          selectedTheme.mvpDisciplines.find(
            discipline => discipline.id === defaultDisciplineId
          )
        );
        return;
      }

      setSelectedDiscipline(
        selectedTheme.disciplines.find(
          discipline => discipline.id === defaultDisciplineId
        )
      );
    }
  }, [
    defaultDisciplineId,
    defaultDisciplineType,
    defaultThemeId,
    selectedDiscipline,
    selectedTheme,
    themes,
  ]);

  useEffect(() => {
    loadDefaultParams();
  }, [loadDefaultParams]);

  const getDiciplinesToShow = useCallback(() => {
    if (!selectedTheme) return [];
    if (availableDisciplines?.length > 0) return availableDisciplines;

    if (selectedTheme.disciplines.length > 0) return selectedTheme.disciplines;
    if (selectedTheme.mvpDisciplines.length > 0)
      return selectedTheme.mvpDisciplines;
    return [];
  }, [availableDisciplines, selectedTheme]);

  const onSendUserProfessor = useCallback(async () => {
    if (!selectedTheme || !selectedDiscipline || !selectedProfessor) {
      errorToast('Preencha todos os campos corretamente');
      return;
    }
    try {
      setLoading(true);
      const { data } = await sendPostRequest(`/mvp/user-professor`, {
        discipline: selectedDiscipline,
        professorData: selectedProfessor,
      });
      trackUserProfessorCreated(
        selectedTheme,
        selectedDiscipline,
        selectedProfessor
      );

      if (defaultSubject) {
        window.location.href = `/materias/professor/${data.professorId}/assunto/${defaultSubject.id}`;
        return;
      }

      window.location.href = `/materias/professor/${data.professorId}`;
    } catch (e) {
      console.error(e);
      alert(
        'Erro ao tentar criar a turma, contate o suporte ou tente novamente mais tarde'
      );
      unsetDialog();
    }
  }, [
    selectedTheme,
    selectedDiscipline,
    selectedProfessor,
    errorToast,
    sendPostRequest,
    trackUserProfessorCreated,
    defaultSubject,
    unsetDialog,
  ]);

  const getTitleCta = useCallback(() => {
    if (defaultDisciplineId)
      return (
        <>
          Informe seu professor para acessar{' '}
          {defaultSubject ? 'o Assunto' : 'a Disciplina'}{' '}
          <DisciplineHighlight color={color}>
            {defaultSubject ? defaultSubject.name : selectedDiscipline?.name}
          </DisciplineHighlight>
        </>
      );

    return 'Qual disciplina você quer adicionar ao seu semestre?';
  }, [color, defaultDisciplineId, selectedDiscipline, defaultSubject]);

  const getSubmitButtonText = useCallback(() => {
    if (defaultDisciplineId) {
      if (loading) return 'Enviando...';
      return 'Informar Professor';
    }

    if (loading) return 'Adicionando...';
    return 'Adicionar Disciplina';
  }, [defaultDisciplineId, loading]);

  // TODO filtrar melhor pelo back
  const themesEligible = useMemo(() => {
    if (!themes) return [];

    const filteredThemes = themes
      .filter(theme => themesIdsToShow.includes(theme.id))
      .filter(
        theme =>
          theme.disciplines?.length > 0 || theme.mvpDisciplines?.length > 0
      )
      .filter(
        theme =>
          theme.disciplines.some(discipline => discipline.guide) ||
          theme.mvpDisciplines.some(
            mvpDiscipline => mvpDiscipline.guideRelation
          )
      );

    filteredThemes.forEach(theme => {
      theme.disciplines = theme.disciplines.filter(
        discipline => !!discipline?.guide?.id
      );
      theme.mvpDisciplines = theme.mvpDisciplines.filter(
        mvpDiscipline => !!mvpDiscipline?.guideRelation?.guide?.id
      );
    });

    return filteredThemes;
  }, [themes]);

  const redirectToNonInformedProfessorPage = useCallback(() => {
    trackRejectInformProfessor(selectedTheme, selectedDiscipline, source);

    if (defaultSubject) {
      window.location.href = `/materias/completas/${defaultThemeId}/assunto/${defaultSubject.id}`;
      return;
    }
    window.location.href = `/materias/${selectedDiscipline.id}/guia/${selectedDiscipline.guide.id}`;
  }, [
    defaultSubject,
    defaultThemeId,
    selectedDiscipline,
    selectedTheme,
    source,
    trackRejectInformProfessor,
  ]);

  if (themesError) return <Error />;
  if (themesLoading || !themes) return <Loading />;

  return (
    <Container
      onClick={() => {
        trackCloseUserProfessorCreateDialog(
          selectedTheme,
          selectedDiscipline,
          source
        );
        unsetDialog();
      }}
    >
      <Content onClick={e => e.stopPropagation()}>
        <Title>{getTitleCta()}</Title>
        <FormContainer>
          {shouldShowThemeAndDisciplineInput() && (
            <>
              <CustomTextField
                label="Selecione sua matéria"
                value={selectedTheme ? selectedTheme : ''}
                onChange={e => {
                  setSelectedTheme(e.target.value);
                }}
                className="select"
                select
                required
              >
                {themesEligible.map(option => (
                  <MenuItem key={option.id} value={option}>
                    {option.name}
                  </MenuItem>
                ))}
              </CustomTextField>
              <CustomTextField
                label="Selecione sua disciplina"
                value={selectedDiscipline || ''}
                onChange={e => {
                  setSelectedDiscipline(e.target.value);
                }}
                className="select"
                select
                required
                disabled={!selectedTheme}
              >
                {getDiciplinesToShow().map(option => (
                  <MenuItem key={option.id} value={option}>
                    {option.name}
                  </MenuItem>
                ))}
              </CustomTextField>
            </>
          )}

          <ProfessorSelector
            professors={selectedDiscipline?.professors ?? []}
            selectedProfessor={selectedProfessor}
            setSelectedProfessor={setSelectedProfessor}
            disabled={!selectedDiscipline}
          />
          <SubmitButton onClick={onSendUserProfessor}>
            {getSubmitButtonText()}
          </SubmitButton>
          {showRejectCta && (
            <CloseCta onClick={redirectToNonInformedProfessorPage}>
              {`Não quero acessar as listas resolvidas do meu professor agora`}
            </CloseCta>
          )}
        </FormContainer>
      </Content>

      <CloseButton
        onClick={() => {
          trackCloseUserProfessorCreateDialog(
            selectedTheme,
            selectedDiscipline,
            source
          );
          unsetDialog();
        }}
      >
        X
      </CloseButton>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 680px;
  max-width: 95%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
  border-top: 10px solid ${colors.mainOrange};
  padding: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 1.2em;
  color: #3e3e3e;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  padding: 0 20px;

  @media ${media.mobile} {
    padding: 0;
  }
`;

const CustomTextField = styled(TextField)`
  margin: 5px 0 !important;
  width: 320px;
  max-width: 100%;
  font-weight: bold;

  &.select {
    .MuiInput-formControl {
      text-align: left;
    }
  }
`;

const SubmitButton = styled.div`
  padding: 10px 20px;
  background-color: orange;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 50px;
  width: 100%;
  text-align: center;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer;
`;

const DisciplineHighlight = styled.span`
  font-weight: bold;
  color: ${props => props.color};
  text-decoration: underline;
  text-underline-offset: 3px;
`;

const CloseCta = styled.div`
  color: #999999;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
  user-select: none;
`;
