import { useCallback, useMemo } from 'react';

import { useTracker } from 'modules/shared/hooks';
import { useWorkspaceListExerciseContext } from 'modules/workspace/providers/WorkspaceListExerciseProvider';
import { useWorkspaceGuide, useWorkspaceVideo } from '../shared';
import { useUserContext } from 'providers/UserProvider';
import useWorkspaceListExercisePermission from './useWorkspaceListExercisePermission';
import { useForumContext } from 'providers/ForumProvider';
import useForumPermission from 'modules/forum/hooks/useForumPermission';

export default function useWorkspaceListExerciseTracker() {
  const {
    currentListCategory,
    currentList,
    currentTheme,
    guide,
    currentContent,
  } = useWorkspaceListExerciseContext();
  const { getCurrentContentVideoIds, getVideosToRender } = useWorkspaceVideo();

  const {
    trackEvent,
    trackEventToRaEvents,
    trackMktCloudActivationEvents,
  } = useTracker();
  const { getGuideTypeToRaEvents } = useWorkspaceGuide();
  const { user } = useUserContext();
  const { currentContentAccess } = useWorkspaceListExercisePermission();
  const { forumContent } = useForumContext();
  const { shouldShowForumForUserInContent } = useForumPermission();

  const getDisciplineMktCloudName = useCallback(() => {
    if (!guide) return null;
    if (guide.guidable !== 'Disciplines') return null;

    return `${guide.guidableName}`;
  }, [guide]);

  const eventProperties = useMemo(() => {
    const { search, href } = window.location;
    const searchParams = new URLSearchParams(search);

    return {
      'Id da lista': currentList?.id,
      'Id da categoria': currentListCategory?.id,
      'Categoria Lista': currentListCategory?.name,
      Lista: currentList?.name,
      Matéria: currentTheme.name,
      Modo: 'Simulator',
      'Tipo Conteúdo': 'Exercício Lista',
      'Tipo Guia': guide.guidable,
      Fórum: shouldShowForumForUserInContent(forumContent)
        ? 'Ligado'
        : 'Desligado',
      utm_source: searchParams.get('utm_source'),
      url: href,
    };
  }, [
    currentListCategory,
    currentList,
    currentTheme,
    guide,
    shouldShowForumForUserInContent,
    forumContent,
  ]);

  const trackShareContent = useCallback(
    shareMedia => {
      const properties = {
        ...eventProperties,
        Mídia: shareMedia,
      };
      trackEvent('Compartilhou Conteúdo', properties);
    },
    [eventProperties, trackEvent]
  );

  const trackErrorReportClick = useCallback(() => {
    trackEvent('Reportou erro', { ...eventProperties, Local: 'Megafone' });
  }, [trackEvent, eventProperties]);

  const trackFormatChange = useCallback(
    newFormat => {
      const properties = eventProperties;
      properties['Vídeo/Texto'] = newFormat === 'text' ? 'Texto' : 'Vídeo';
      trackEvent('Videoaula: Alternou Vídeo/Texto', eventProperties);
    },
    [trackEvent, eventProperties]
  );

  const trackFirstVideoPlay = useCallback(
    provider => {
      const properties = {
        ...eventProperties,
        Provedor: provider,
      };
      trackEvent('Videoaula: Deu play', properties);
    },
    [eventProperties, trackEvent]
  );

  const getRaEventsInfo = useCallback(
    listExerciseId => {
      return {
        themeName: currentTheme.name,
        themeId: currentTheme.id,
        categoryName: currentListCategory?.name,
        categoryId: currentListCategory?.id,
        listName: currentList?.name,
        listId: currentList?.id,
        guideType: getGuideTypeToRaEvents(guide),
        guideId: guide.id,
        contentId: parseInt(listExerciseId),
        blockedContent: !currentContentAccess,
      };
    },
    [
      currentTheme,
      currentListCategory,
      currentList,
      getGuideTypeToRaEvents,
      guide,
      currentContentAccess,
    ]
  );

  const mktCloudTrackingProperties = useMemo(() => {
    return {
      subjectName: null,
      subjectId: null,
      themeName: currentTheme?.name,
      themeId: currentTheme?.id,
      blockedContent: user?.hasAccess,
      contentType: 'Exercício Lista',
      platform: 'Web',
      discpline: getDisciplineMktCloudName(),
    };
  }, [
    currentTheme?.id,
    currentTheme?.name,
    getDisciplineMktCloudName,
    user?.hasAccess,
  ]);

  const trackViewContent = useCallback(
    (listExerciseId = null) => {
      const props = eventProperties;

      if (listExerciseId) props['Id do exercício'] = listExerciseId;

      trackEventToRaEvents(
        '/view-workspace-list',
        getRaEventsInfo(listExerciseId)
      );

      trackMktCloudActivationEvents(
        'ON_VIEW_CONTENT_WORKSPACE',
        mktCloudTrackingProperties
      );

      trackEvent('Visualizou Conteúdo', eventProperties);
    },
    [
      eventProperties,
      trackEvent,
      trackEventToRaEvents,
      getRaEventsInfo,
      trackMktCloudActivationEvents,
      mktCloudTrackingProperties,
    ]
  );

  const trackContentFeedbackNote = useCallback(
    (note, contentFeedbackId) => {
      const videos = getVideosToRender(
        currentContent?.videos,
        currentContentAccess
      );
      const eventProps = {
        ...eventProperties,
        'Link do Conteúdo': location.pathname,
        'Mídia ID': getCurrentContentVideoIds(videos),
        'Link do Feedback': `https://respondeai.com.br/admin/content_feedbacks/${contentFeedbackId}`,
        Nota: note,
      };
      trackEvent('Feedback de Conteúdo: Nova nota', eventProps);
    },
    [
      currentContent,
      currentContentAccess,
      trackEvent,
      eventProperties,
      getCurrentContentVideoIds,
      getVideosToRender,
    ]
  );

  const trackContentFeedbackFeeling = useCallback(
    (note, feelings, contentFeedbackId) => {
      const eventProps = {
        ...eventProperties,
        'Link do Conteúdo': location.pathname,
        'Link do Feedback': `https://respondeai.com.br/admin/content_feedbacks/${contentFeedbackId}`,
        Nota: note,
        Sentimentos: feelings.join(', '),
      };
      trackEvent('Feedback de Conteúdo: Novo sentimento', eventProps);
    },
    [trackEvent, eventProperties]
  );

  const trackContentFeedbackComment = useCallback(
    (note, feelings, comment, contentFeedbackId) => {
      const eventProps = {
        ...eventProperties,
        'Link do Conteúdo': location.pathname,
        'Link do Feedback': `https://respondeai.com.br/admin/content_feedbacks/${contentFeedbackId}`,
        Nota: note,
        Sentimentos: feelings.join(', '),
        Comentário: comment,
      };
      trackEvent('Feedback de conteúdo: Novo comentário', eventProps);
    },
    [trackEvent, eventProperties]
  );

  return {
    trackShareContent,
    trackErrorReportClick,
    trackViewContent,
    trackFirstVideoPlay,
    trackFormatChange,
    trackContentFeedbackNote,
    trackContentFeedbackFeeling,
    trackContentFeedbackComment,
  };
}
