import React, { createContext, useContext } from 'react';
import { useUserContext } from 'providers/UserProvider';

export const UniversityContext = createContext(null);

const UniversityProvider = props => {
  const { user } = useUserContext();

  const universityData = {
    university: user?.university,
    campus: user?.university_campus,
    isFromPrivateUniversityCampaign: user?.isFromPrivateUniversityCampaign,
    universityPronoumn: user?.university?.gender === 'M' ? 'do' : 'da',
  };

  return <UniversityContext.Provider value={universityData} {...props} />;
};

export const useUniversityContext = () => useContext(UniversityContext);

export default UniversityProvider;

export const isStar = university_id => {
  const stars_universities_ids = [
    '338',
    '664',
    '508',
    '658',
    '507',
    '238',
    '639',
    '241',
    '325',
    '148',
    '666',
    '130',
    '233',
    '548',
    '26',
    '1803',
    '172',
    '657',
    '371',
    '231',
  ];
  return stars_universities_ids.includes(`${university_id}`);
};

export const isTop50 = university_id => {
  const top50_universities_ids = [
    '338',
    '664',
    '508',
    '658',
    '507',
    '238',
    '639',
    '241',
    '325',
    '148',
    '666',
    '130',
    '233',
    '548',
    '26',
    '1803',
    '172',
    '657',
    '371',
    '231',
    '5',
    '44',
    '50',
    '15',
    '147',
    '240',
    '323',
    '332',
    '335',
    '372',
    '510',
    '569',
    '564',
    '635',
    '782',
    '521',
    '232',
    '234',
    '665',
    '237',
    '106',
    '339',
    '505',
    '324',
    '509',
    '659',
    '538',
    '571',
    '235',
  ];
  return top50_universities_ids.includes(`${university_id}`);
};

export const getAcronymByUniversityName = name => {
  if (!name) return null;

  const parts = name.split(/[\(\)]/).filter(x => x.length > 0);
  const acronym = parts
    .reduce((a, b) => (a.length > b.length ? b : a))
    .replace(/ $/, '');

  return acronym;
};
