import { useCallback } from 'react';
import { bookEditionWithEndChapterExercise, MapBookToAuthor } from '../data';

const useBookFormatter = () => {
  const getBookEditionType = useCallback(bookEditionId => {
    return bookEditionWithEndChapterExercise.includes(bookEditionId)
      ? 'Capitulo'
      : 'Seção';
  }, []);

  const isEditionChapter = useCallback(edition => {
    return bookEditionWithEndChapterExercise.includes(edition.id);
  }, []);

  const getBookAuthorById = useCallback(bookId => {
    return MapBookToAuthor[bookId].author;
  }, []);

  const getFormattedBookNameById = useCallback(bookId => {
    return MapBookToAuthor[bookId].bookName;
  }, []);

  const getChapterSectionByBookEdition = useCallback(bookEdition => {
    let ChapterSections = [];

    if (bookEditionWithEndChapterExercise.includes(bookEdition.id)) {
      bookEdition.chapters.forEach(chapter => {
        ChapterSections.push(chapter.name);
      });
      return ChapterSections;
    }

    bookEdition.chapters.forEach(chapter => {
      chapter.sections.forEach(section => {
        ChapterSections.push(`${chapter.name}.${section.name}`);
      });
    });

    return ChapterSections;
  }, []);

  return {
    getBookEditionType,
    getBookAuthorById,
    getFormattedBookNameById,
    getChapterSectionByBookEdition,
    isEditionChapter,
  };
};

export default useBookFormatter;
