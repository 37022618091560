import React, { useEffect } from 'react';
import { landscape } from '../../../images/index';
import { Container, MainContent } from './styles';
import { useTracker } from 'modules/shared/hooks';
import SearchButton from 'modules/shared/components/SearchButton';
import BackButton from 'modules/shared/components/BackButton';

export default function TopBackground({ theme }) {
  const { name, color } = theme;
  const { trackEvent } = useTracker();

  useEffect(() => {
    trackEvent('Visualizou Matéria', { Plataforma: 'Web', Matéria: name });
  }, [name, trackEvent]);

  return (
    <Container color={color}>
      <BackButton to="/materias" />
      <MainContent>
        <div>
          <h1> {name} </h1>
          <h2>{`Aproveite o conteúdo de ${name} completo do Responde Aí!`}</h2>
        </div>

        <SearchButton theme={theme} where={theme.name} />
      </MainContent>
      <img src={landscape} alt="paisagem" />
    </Container>
  );
}
