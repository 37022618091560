import React from 'react';

import { styled, colors, media } from 'styles';

import { UserMenuLink } from 'modules/shared/components/header';
import { useUserContext } from 'providers/UserProvider';

export default function UserMenu({ isOpen, toggleUserMenu }) {
  const { onLogout } = useUserContext();

  const RESPONDEAI_URL = process.env.REACT_APP_RESPONDEAI_URL;

  return (
    <Container isOpen={isOpen}>
      <CardContainer>
        <List>
          {media.isMobile() && (
            <>
              <UserMenuLink to="/materias" onClick={toggleUserMenu}>
                Matérias
              </UserMenuLink>
              <UserMenuLink to="/auloes" onClick={toggleUserMenu}>
                Aulões
              </UserMenuLink>
              <UserMenuLink to="/livros-resolvidos" onClick={toggleUserMenu}>
                Livros Resolvidos
              </UserMenuLink>
              <UserMenuLink to={'/resumoes-gratis'} onClick={toggleUserMenu}>
                Resumões Grátis
              </UserMenuLink>
              <UserMenuLink
                href={`${process.env.REACT_APP_RA_WEB_SSR_URL}/planos`}
                onClick={toggleUserMenu}
              >
                Planos
              </UserMenuLink>
              <UserMenuLink
                href={`${RESPONDEAI_URL}/faq`}
                onClick={toggleUserMenu}
              >
                FAQ
              </UserMenuLink>
            </>
          )}
          <UserMenuLink to={'/minha-conta'} onClick={toggleUserMenu}>
            Minha Conta
          </UserMenuLink>
          <UserMenuLink href="#" onClick={() => window.UserVoice.show()}>
            Fale Conosco
          </UserMenuLink>
          <UserMenuLink onClick={onLogout}>Sair</UserMenuLink>
        </List>
      </CardContainer>
    </Container>
  );
}

const Container = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 70px;
  right: 0;
  z-index: 10;

  @media ${media.mobile} {
    position: relative;
    top: 0;
  }
`;

const CardContainer = styled.div`
  border-radius: 10px;
  padding: 8px;
  margin: 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: #fff;

  @media ${media.mobile} {
    box-shadow: none;
  }
`;

const List = styled.div`
  background-color: ${colors.bodyBackground};
  border-radius: 10px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
