import { useCallback } from 'react';

import { useFormValidate } from 'modules/shared/hooks';

function useCreditCard() {
  const {
    validCreditCardHolderName,
    validCreditCardNumber,
    validCreditCardValidateDate,
    validCreditCardCvv,
  } = useFormValidate();

  const getCreditCardErrors = useCallback(
    creditCard => {
      const number = validCreditCardNumber(creditCard.number)
        ? ''
        : 'Número de cartão inválido.';
      const holderName = validCreditCardHolderName(creditCard.holderName)
        ? ''
        : 'Nome inválido.';
      const expiry = validCreditCardValidateDate(creditCard.expiry)
        ? ''
        : 'Data inválida.';
      const cvv = validCreditCardCvv(creditCard.cvv)
        ? ''
        : 'Código de segurança inválido.';

      return { number, holderName, expiry, cvv };
    },
    [
      validCreditCardNumber,
      validCreditCardHolderName,
      validCreditCardValidateDate,
      validCreditCardCvv,
    ]
  );

  return { getCreditCardErrors };
}

export { useCreditCard };
