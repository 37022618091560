import { useCallback, useMemo } from 'react';

export default function useUniversitySelectSearch() {
  const keywords = useMemo(() => {
    return [
      { name: 'unesa', universityId: 544, keyword: 'estacio' },
      { name: 'unifael', universityId: 1370, keyword: 'unifael' },
      { name: 'unifael', universityId: 1370, keyword: 'fael' },
      { name: 'uniderp', universityId: 220, keyword: 'uniderp' },
      { name: 'unip', universityId: 42, keyword: 'unip' },
      { name: 'UAM', universityId: 746, keyword: 'anhembi' },
      { name: 'UAM', universityId: 746, keyword: 'morumbi' },
      { name: 'unicid', universityId: 1919, keyword: 'unicid' },
      { name: 'unicid', universityId: 1919, keyword: 'cidade de sao paulo' },
      { name: 'unifran', universityId: 754, keyword: 'unifran' },
      { name: 'unifran', universityId: 754, keyword: 'unifram' },
      { name: 'unopar', universityId: 365, keyword: 'unopar' },
      { name: 'USJT', universityId: 763, keyword: 'usjt' },
      { name: 'USJT', universityId: 763, keyword: 'sao judas' },
      { name: 'FAM', universityId: 1031, keyword: 'fam' },
      { name: 'FAM', universityId: 1031, keyword: 'americas' },
      { name: 'multivix', universityId: 967, keyword: 'mtvx' },
      { name: 'unicesumar', universityId: 348, keyword: 'cesumar' },
      { name: 'unicesumar', universityId: 348, keyword: 'unicesumar' },
      { name: 'uniasselvi', universityId: 646, keyword: 'uniasselvi' },
      { name: 'unifacs', universityId: 74, keyword: 'unifacs' },
      { name: 'uninter', universityId: 860, keyword: 'uninter' },
      { name: 'unicsul', universityId: 753, keyword: 'cruzeiro do sul' },
      { name: 'unicsul', universityId: 753, keyword: 'unicsul' },
      { name: 'pitagoras', universityId: 1538, keyword: 'pitagoras' },
      { name: 'uninassau', universityId: 413, keyword: 'uninassau' },
      { name: 'uninassau', universityId: 413, keyword: 'nassau' },
      { name: 'anhanguera', universityId: 764, keyword: 'anhanguera' },
      { name: 'unicamp', universityId: 658, keyword: 'unicamp' },
    ];
  }, []);

  const getInputTreated = useCallback(input => {
    const inputWithoutAccents = input
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return inputWithoutAccents.toLowerCase();
  }, []);

  const getUniversitiesThatIncludesInputInTheName = useCallback(
    (universities, input) => {
      const universitiesFiltered = universities.filter(u => {
        const universityNameWithoutAccents = u.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();

        return universityNameWithoutAccents.includes(input);
      });

      return universitiesFiltered;
    },
    []
  );

  const getUniveritiesThatStartsWithInput = useCallback(
    (universities, input) => {
      const universitiesFiltered = universities.filter(u => {
        const universityNameTreated = u.name
          .trim()
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

        return universityNameTreated.startsWith(input);
      });

      return universitiesFiltered;
    },
    []
  );

  const prioritizeByInitalName = useCallback(
    (universities, input) => {
      let universitiesPrioritized = [];
      let initialUniversities = getUniveritiesThatStartsWithInput(
        universities,
        input
      );

      for (let i = 0; i < initialUniversities.length; i++) {
        const currentUniversity = initialUniversities[i];
        const firstNameTreated = currentUniversity.name
          .trim()
          .toLowerCase()
          .split(' ')[0]
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

        if (firstNameTreated === input)
          universitiesPrioritized.unshift(currentUniversity);
      }

      return [
        ...universitiesPrioritized,
        ...initialUniversities,
        ...universities,
      ];
    },
    [getUniveritiesThatStartsWithInput]
  );

  const treatKeywordCases = useCallback(
    (allUniversities, universitiesToShow, input) => {
      if (input.length === '') return [];

      const possibleKeywords = keywords.filter(keywordObject =>
        keywordObject['keyword'].startsWith(input)
      );

      const isThereAnyPossibleKeyword = possibleKeywords.length > 0;

      if (isThereAnyPossibleKeyword) {
        possibleKeywords.forEach(keywordObject => {
          const universityToAdd = allUniversities.find(
            university => university.id === keywordObject.universityId
          );

          if (universityToAdd) universitiesToShow.unshift(universityToAdd);
        });
      }

      return universitiesToShow;
    },
    [keywords]
  );

  const treatNonUniversitiesOptions = useCallback(
    (allUniversities, universitiesPrioritized) => {
      for (let i = 0; i < universitiesPrioritized.length; i++) {
        const currentUniversity = universitiesPrioritized[i];

        if (currentUniversity?.name.toLowerCase() === 'não faço faculdade') {
          universitiesPrioritized.splice(i, 1);
        } else if (
          currentUniversity?.name.toLowerCase().includes('outra universidade')
        ) {
          universitiesPrioritized.splice(i, 1);
        }
      }

      const otherUniversityOption = allUniversities.find(u =>
        u.name.toLowerCase().includes('outra universidade')
      );

      if (otherUniversityOption)
        universitiesPrioritized.splice(3, 0, otherUniversityOption);

      return universitiesPrioritized;
    },
    []
  );

  const getUniversitiesArrayWithoutRepetitions = useCallback(universities => {
    const auxHash = {};

    const universitiesArrayWithoutRepetition = universities.filter(
      university => {
        if (auxHash[university?.id]) return false;

        auxHash[university?.id] = true;
        return true;
      }
    );

    return universitiesArrayWithoutRepetition;
  }, []);

  const getFirst100UniversitiesInSearch = useCallback(
    (allUniversities, userInput) => {
      if (allUniversities.length === 0) return [];

      const inputTreated = getInputTreated(userInput);

      const universitiesFirstFiltered = getUniversitiesThatIncludesInputInTheName(
        allUniversities,
        inputTreated
      );

      const universitiesPrioritized = prioritizeByInitalName(
        universitiesFirstFiltered,
        inputTreated
      );

      const universitiesWithKeywords = treatKeywordCases(
        allUniversities,
        universitiesPrioritized,
        inputTreated
      );

      const universitiesWithFixedOption = treatNonUniversitiesOptions(
        allUniversities,
        universitiesWithKeywords
      );

      const finalUniversities = getUniversitiesArrayWithoutRepetitions(
        universitiesWithFixedOption
      );

      return finalUniversities.slice(0, 100);
    },
    [
      getInputTreated,
      getUniversitiesThatIncludesInputInTheName,
      prioritizeByInitalName,
      treatKeywordCases,
      treatNonUniversitiesOptions,
      getUniversitiesArrayWithoutRepetitions,
    ]
  );

  return {
    getFirst100UniversitiesInSearch,
  };
}
