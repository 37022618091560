import React, { useEffect, useCallback, useMemo, useState } from 'react';

import { useTracker, useTestAB, useCookies } from 'modules/shared/hooks';
import { useBannerMakerFunctions } from 'modules/banner/shared/banners/BannerMaker/sharedFunctions';
import { emitter } from '@marvelapp/react-ab-test';
import {
  Container,
  Button,
  Strip,
  TextContainer,
  TextSubtitle,
  TextTitle,
  Content,
} from './styles';

export default function DesktopBannerMaker({ className = '', source }) {
  const { trackEvent } = useTracker();
  const {
    bannerData: desktopBannerData,
    getBannerLayoutData,
    getTitleToShow,
    getSubTitleToShow,
  } = useBannerMakerFunctions();
  const [layoutToShow, setLayoutToShow] = useState(null);
  const { getVariantFromTestAB } = useTestAB();

  emitter.defineVariants('bannerTest', ['A', 'B']);

  const amplitudeEventAttributes = useMemo(() => {
    let attributes = {
      local: source,
    };
    if (desktopBannerData?.hasTestAb && !desktopBannerData?.testWinner) {
      attributes = {
        ...attributes,
        variant: getVariantFromTestAB('bannerTest'),
      };
    }

    return attributes;
  }, [
    source,
    desktopBannerData?.hasTestAb,
    desktopBannerData?.testWinner,
    getVariantFromTestAB,
  ]);

  useEffect(() => {
    if (!desktopBannerData) return;
    trackEvent(
      `${desktopBannerData.name}: Visualizou Banner`,
      amplitudeEventAttributes
    );
  }, [desktopBannerData, trackEvent, amplitudeEventAttributes, source]);

  useEffect(() => {
    getBannerLayoutData({ bannerType: 'desktop' });

    if (desktopBannerData?.hasTestAb && desktopBannerData?.testWinner === 'A') {
      setLayoutToShow(desktopBannerData?.layoutA);
      return;
    }

    if (desktopBannerData?.hasTestAb && desktopBannerData?.testWinner === 'B') {
      setLayoutToShow(desktopBannerData?.layoutB);
      return;
    }

    let layoutToShow = desktopBannerData?.layoutA;
    if (
      desktopBannerData?.hasTestAb &&
      getVariantFromTestAB('bannerTest') === 'B'
    )
      layoutToShow = desktopBannerData?.layoutB;

    setLayoutToShow(layoutToShow);
  }, [getBannerLayoutData, desktopBannerData, getVariantFromTestAB]);

  const onBannerClick = useCallback(() => {
    if (!desktopBannerData) return;
    trackEvent(
      `${desktopBannerData.name}: Clicou Banner`,
      amplitudeEventAttributes
    );

    let redirectLink = layoutToShow.container.redirectLink;

    if (redirectLink.includes('ganhar-trial-generico')) {
      let urlObject = new URL(redirectLink);
      urlObject.searchParams.set('redirect_to', window.location.href);

      redirectLink = urlObject.toString();
    }

    window.location.href = redirectLink;
  }, [desktopBannerData, trackEvent, amplitudeEventAttributes, layoutToShow]);

  if (!layoutToShow) return null;

  return (
    <>
      <Container
        className={className}
        {...layoutToShow.container}
        onClick={onBannerClick}
        data-cy="desktop-banner-maker"
      >
        <Content>
          {layoutToShow.strip && (
            <Strip {...layoutToShow.strip}>{layoutToShow.strip.text}</Strip>
          )}
          <TextContainer>
            {layoutToShow.title && (
              <TextTitle {...layoutToShow.title}>
                {getTitleToShow(layoutToShow.title)}
              </TextTitle>
            )}
            {layoutToShow.subtitle && (
              <TextSubtitle {...layoutToShow.subtitle}>
                {getSubTitleToShow(layoutToShow.subtitle)}
              </TextSubtitle>
            )}
          </TextContainer>
          {layoutToShow.button && (
            <Button {...layoutToShow.button}>{layoutToShow.button.text}</Button>
          )}
        </Content>
      </Container>
    </>
  );
}
