import { useCallback, useMemo } from 'react';

const useBookPermission = () => {
  const shouldBooksFirstChaptersBeFree = useMemo(() => {
    return true;
  }, []);

  const isBookAccessible = useCallback(edition => {
    const bookId = edition?.book?.id;
    if (!bookId) return false;

    const listOfFreeBooks = [];

    return !!listOfFreeBooks.find(item => item === bookId);
  }, []);

  return {
    shouldBooksFirstChaptersBeFree,
    isBookAccessible,
  };
};

export { useBookPermission };
