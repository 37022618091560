import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { styled } from 'styles';

function Checkbox({
  label,
  setChecked,
  checked,
  linkLabel = '',
  linkUrl = '',
  altText = '',
  size = 17,
}) {
  return (
    <>
      <Container
        onClick={() => {
          setChecked(!checked);
        }}
        data-cy="checkbox-container"
      >
        <IconTextCheckBox size={size} selected={checked} icon={faCheckSquare} />
        <ContainerTextCheckBox>
          <span>
            {label}
            {linkLabel && (
              <Link href={linkUrl} target="_blank">
                {linkLabel}
              </Link>
            )}
          </span>
          <AltText>{altText}</AltText>
        </ContainerTextCheckBox>
      </Container>
    </>
  );
}

export default Checkbox;

const Link = styled.a`
  text-decoration: underline;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  /* align-items: center; */
  letter-spacing: 0.2px;
  margin: 10px auto;
  color: #444444;
  span {
    font-size: 0.9em;
  }
`;

const ContainerTextCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const IconTextCheckBox = styled(FontAwesomeIcon)`
  height: ${({ selected, size }) => (selected ? `${size}px` : `${size - 2}px`)};
  width: ${({ selected, size }) =>
    selected ? `${size}px !important;` : `${size - 2}px !important;`};
  margin: 0px 8px 3px 0;
  border: ${({ selected }) => (selected ? null : '2px solid #737373')};
  color: ${({ selected }) => (selected ? '#f6ac3a' : 'white')};
  border-radius: 2px;
`;

const AltText = styled.p`
  font-size: 0.8em;
  color: #666;
  margin: 8px 0px;
  text-align: start;
  line-height: 15px;
`;
