export const testPeriods = [
  { label: '2024.2' },
  { label: '2024.1' },
  { label: '2023.2' },
  { label: '2023.1' },
  { label: '2022.2' },
  { label: '2022.1' },
  { label: '2021.2' },
  { label: '2021.1' },
  { label: '2020.2' },
  { label: '2020.1' },
  { label: '2019.2' },
  { label: '2019.1' },
  { label: '2018.2' },
  { label: '2018.1' },
  { label: '2017.2' },
  { label: '2017.1' },
  { label: '2016.2' },
  { label: '2016.1' },
  { label: '2015.2' },
  { label: '2015.1' },
  { label: '2014.2' },
  { label: '2014.1' },
  { label: '2013.2' },
  { label: '2013.1' },
  { label: '2012.2' },
  { label: '2012.1' },
  { label: '2011.2' },
  { label: '2011.1' },
  { label: '2010.2' },
  { label: '2010.1' },
  { label: '2009.2' },
  { label: '2009.1' },
  { label: '2008.2' },
  { label: '2008.1' },
];
