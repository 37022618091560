import styled from 'styled-components';
import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { FaTrash } from 'react-icons/fa6';
import useBookFormatter from '../hooks/useBookFormatter';
import { media } from 'styles';

const ListCreationRow = ({
  selectedSection,
  currentExercises,
  onChapterSectionChange,
  removeSection,
  onExerciseChange,
  selectedBookEdition,
}) => {
  const { getChapterSectionByBookEdition } = useBookFormatter();
  const { getBookEditionType } = useBookFormatter();

  return (
    <Line>
      <Section>
        <Text>{`${getBookEditionType(selectedBookEdition.id)}`}</Text>
        <CustomSelectField
          value={selectedSection}
          onChange={e => {
            onChapterSectionChange(e.target.value);
          }}
          variant="outlined"
          className="select"
          size="small"
          select
          required
        >
          {getChapterSectionByBookEdition(selectedBookEdition).map(
            (section, i) => (
              <MenuItem key={i} value={section}>
                {section}
              </MenuItem>
            )
          )}
        </CustomSelectField>
      </Section>
      <Exercise>
        <Text>exercícios</Text>
        <CustomTextField
          placeholder="Digite seus exercícios (Ex: 1, 3, 5-13)"
          value={currentExercises}
          variant="outlined"
          size="small"
          multiline
          InputLabelProps={{ shrink: currentExercises !== '' }}
          onChange={e => {
            if (e.target.value.slice(-1) === '\n') return;
            onExerciseChange(e.target.value);
          }}
          required
        />
      </Exercise>
      <TrashIcon onClick={removeSection} />
      <Divider />
    </Line>
  );
};

const Divider = styled.div`
  display: none;
  width: 100%;
  margin-top: 5px;
  height: 1px;
  background-color: #dfdfdf;
  @media ${media.mobile} {
    display: block;
  }
`;

const TrashIcon = styled(FaTrash)`
  font-size: 22px;
  cursor: pointer;
  @media ${media.mobile} {
    display: none;
  }
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 95%;
  margin-bottom: 10px;
  @media ${media.mobile} {
    flex-wrap: wrap;
    justify-content: start;
  }
`;

const Section = styled.div`
  font-size: 17px;
  display: flex;
  align-items: center;
  @media ${media.mobile} {
    width: 100%;
    margin-bottom: 8px;
  }
`;

const CustomTextField = styled(TextField)`
  text-overflow: ellipsis;
  margin-right: 25px !important;
  width: 100% !important;
  & .MuiInputBase-root {
    border-radius: 19px;
    background-color: #eeeeee;
  }
`;

const CustomSelectField = styled(TextField)`
  margin-right: 25px !important;
  width: 70px;
  & .MuiInputBase-root {
    border-radius: 7px;
    background-color: #eeeeee;
  }
  @media ${media.mobile} {
    width: 100% !important;
  }
`;

const Exercise = styled.div`
  font-size: 17px;
  display: flex;
  align-items: center;
  width: 100%;
`;

const Text = styled.div`
  margin-right: 5px;
  @media ${media.mobile} {
    text-align: left;
    width: 38% !important;
  }
`;

export default ListCreationRow;
