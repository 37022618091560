import React from 'react';

import { styled, media } from 'styles';
import { MathjaxContent } from 'modules/shared/components/generic';
import { useWorkspaceExercise } from 'modules/workspace/hooks/shared';

export default function ExerciseStatement({ exercise }) {
  const { getExerciseColor } = useWorkspaceExercise();

  return (
    <Container data-cy="statement-exercise">
      <Title color={getExerciseColor()}>Enunciado</Title>
      <Subtitle>{exercise.metadata}</Subtitle>
      <MathjaxContent html={exercise.lightBody} />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-top: 50px;
  padding: 0 30px;

  @media ${media.mobile} {
    padding: 0 10px;
  }
`;

const Title = styled.h1`
  font-size: 1.7em;
  color: ${({ color }) => color};
  font-family: 'Droid Serif', serif;
`;

const Subtitle = styled.h2`
  color: #ddd;
  font-size: 0.85em;
`;
