import { useCallback } from 'react';
import _ from 'lodash';

export default function useBooks() {
  const getQuestionByBookExerciseId = useCallback((edition, bookExerciseId) => {
    if (!edition) return null;
    const { chapters } = edition;

    const sections = _.flatten(chapters.map(c => c.sections));
    const questions = _.flatten(sections.map(s => s.questions));

    return questions.find(q => q.exercise.id === bookExerciseId);
  }, []);

  const getCurrentChapter = useCallback((edition, sectionId) => {
    if (!edition) return null;

    const selectedChapter = edition.chapters.find(c =>
      c.sections.find(s => s.id === sectionId)
    );

    return selectedChapter;
  }, []);

  const getCurrentSection = useCallback(
    (edition, sectionId) => {
      const selectedChapter = getCurrentChapter(edition, sectionId);
      const selectedSection = selectedChapter?.sections.find(
        s => s.id === sectionId
      );

      return selectedSection;
    },
    [getCurrentChapter]
  );

  const getChapterDotSectionName = useCallback(
    (edition, sectionId) => {
      const selectedChapter = getCurrentChapter(edition, sectionId);
      const selectedSection = getCurrentSection(edition, sectionId);

      return `${selectedChapter.name}.${selectedSection.name}`;
    },
    [getCurrentChapter, getCurrentSection]
  );

  return {
    getChapterDotSectionName,
    getQuestionByBookExerciseId,
    getCurrentChapter,
    getCurrentSection,
  };
}
