/* eslint-disable custom-rules/set-dialog */
import React, { useEffect, useRef } from 'react';

import { NoAccessDialog } from 'modules/shared/dialogs';
import { NoAccessPaymentRecoverDialog } from 'modules/campaigns/recover-subscriber/payment-failed-recover/dialogs';
import {
  NoAccessDialogFormerSubscriber,
  useFormerSubscriberRecoverCampaign,
} from 'modules/campaigns/recover-subscriber';
import { useDialogContext } from 'providers/DialogProvider';
import { usePaymentFailedRecoverCampaign } from 'modules/campaigns/recover-subscriber/payment-failed-recover/hooks/PaymentFailedRecoverCampaign';
import NoAccessDialogFormerTrial from 'modules/campaigns/recover-subscriber/former-subscriber-recover/dialogs/NoAccessDialogFormerTrial/NoAccessDialogFormerTrial';

export default function useNoAccessDialog(timeout, condition) {
  const { hasPaymentFailedToRecover } = usePaymentFailedRecoverCampaign();
  const {
    isOnFormerSubscriberRecoverCampaign,
    isOnFormerTrialRecoverCampaign,
  } = useFormerSubscriberRecoverCampaign();
  const timeoutRef = useRef(null);
  const { setDialog } = useDialogContext();

  useEffect(() => {
    if (condition) {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        if (hasPaymentFailedToRecover()) {
          setDialog(
            <NoAccessPaymentRecoverDialog source="no_access_dialog_react" />
          );
          return;
        }

        if (isOnFormerSubscriberRecoverCampaign()) {
          setDialog(
            <NoAccessDialogFormerSubscriber source="no_access_dialog_react" />
          );
          return;
        }
        if (isOnFormerTrialRecoverCampaign()) {
          setDialog(
            <NoAccessDialogFormerTrial source="no_access_dialog_react" />
          );
          return;
        }

        setDialog(<NoAccessDialog />);
      }, timeout);

      return () => clearTimeout(timeoutRef.current);
    }
  }, [
    timeout,
    setDialog,
    timeoutRef,
    condition,
    hasPaymentFailedToRecover,
    isOnFormerSubscriberRecoverCampaign,
    isOnFormerTrialRecoverCampaign,
  ]);
}
