import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import { SummaryContent } from 'modules/summaries/components';

import { colors, styled } from 'styles';

export default function SummaryToggle(props) {
  const { i, chapter, color, isToggled, onClick } = props;

  const topics = useMemo(() => chapter.topics.filter(t => !!t.summary), [
    chapter,
  ]);

  return (
    <Container onClick={onClick}>
      <Toggle color={color}>
        <h2>{`${i + 1} ${chapter.name}`}</h2>
        {<Icon icon={isToggled ? faAngleUp : faAngleDown} />}
      </Toggle>
      {isToggled && (
        <ContentContainer>
          <ContentWrapper>
            {topics.map(t => (
              <SummaryContent
                key={t.id}
                summary={t.summary}
                title={t.name}
                color={color}
              />
            ))}
          </ContentWrapper>
        </ContentContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  box-shadow: 0 3px 5px 0px rgba(148, 148, 148, 0.5);
  width: 100%;
  background-color: ${colors.white};
  margin-bottom: 25px;
`;

const Toggle = styled.button`
  color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 60px;
  font-size: 1.1em;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 20px;
  height: 100%;
  color: ${colors.darkGray};
  font-size: 1.7em;
  font-weight: bold;
`;

const ContentContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  min-height: 500px;
  background: ${colors.white};
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  font-family: 'Droid Serif', serif;
  position: relative;
  top: 0;
  left: 0;
  user-select: none;
  font-size: 1.1em;
  line-height: 1.5;
  padding: 0 30px 60px 30px;
`;
