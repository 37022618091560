import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

import { styled, media } from 'styles';
import { useLectureContext } from 'modules/lectures/providers/LectureProvider';

const PreviousButton = ({ className }) => {
  const location = useLocation();
  const { lectureData } = useLectureContext();

  const previousUrl = useMemo(() => {
    const previousUrl = location.state?.previousUrl;
    if (previousUrl) return previousUrl;

    const themeId = lectureData?.subject?.theme?.id;
    const subjectId = lectureData?.subject?.id;
    return `/materias/completas/${themeId}/assunto/${subjectId}`;
  }, [location, lectureData]);

  return (
    <Button className={className} to={previousUrl}>
      <IconContainer className="back-icon">
        <Icon icon={faLongArrowAltLeft} />
      </IconContainer>
      {!media.isMobile() && 'VOLTAR'}
    </Button>
  );
};

const Button = styled(Link)`
  color: #fff;
  font-size: 1.2em;

  @media ${media.desktop} {
    cursor: pointer;

    &:hover {
      .back-icon {
        background-color: rgba(0, 0, 0, 0.15);
      }
    }
  }
`;

const IconContainer = styled.span`
  display: inline-block;
  margin-right: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out;
  border-radius: 5px;
  padding: 10px 15px;
  text-align: center;

  @media ${media.mobile} {
    padding: 5px 10px;
  }
`;

const Icon = styled(FontAwesomeIcon)``;

export default PreviousButton;
