import React, { useCallback, useEffect } from 'react';
import sadDexter from 'images/dexter/feedbacks/dexter-frawn.png';

import { styled, colors, media } from 'styles';
import { useTrackEvents } from 'modules/mvp/hooks';
import { useDialogContext } from 'providers/DialogProvider';

export default function ZeroPercentageDialog({ list }) {
  const {
    trackViewZeroPercentDialog,
    trackGoBackToSolveList,
    trackClickSimilarQuestions,
  } = useTrackEvents();
  const { unsetDialog } = useDialogContext();

  useEffect(() => {
    trackViewZeroPercentDialog(list);
  }, [list, trackViewZeroPercentDialog]);

  const onOpenLink = useCallback(() => {
    trackGoBackToSolveList(list);
    window.location.href = `${process.env.REACT_APP_REACT_URL}/resolver-lista`;
  }, [list, trackGoBackToSolveList]);

  const onCloseDialog = useCallback(() => {
    trackClickSimilarQuestions(list);
    unsetDialog();
  }, [list, trackClickSimilarQuestions, unsetDialog]);

  return (
    <Container onClick={unsetDialog}>
      <Content onClick={e => e.stopPropagation()}>
        <Image src={sadDexter} />
        <Title>
          Obrigado! Infelizmente, ainda não conseguimos resolver a sua lista!
        </Title>
        <ButtonContainer>
          <Button onClick={onOpenLink}>Voltar</Button>
          <Button onClick={onCloseDialog}>Buscar questões parecidas</Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: 40%;
  max-width: 600px;
  padding: 20px 15px 20px;
  border-top: 22px solid #ff5555;
  border-radius: 11px;
  background-color: #fff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);

  @media ${media.mobile} {
    width: 96%;
  }
`;

const Image = styled.img`
  width: 75px;
  height: 75px;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.9rem;
  color: #555;

  @media ${media.mobile} {
    font-size: 1.5rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-around;
`;

const Button = styled.button`
  min-width: 30%;
  padding: 10px;
  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: white;
  margin-top: 1.5rem;
  border-radius: 15px;
  font-size: 1.1em;
  font-weight: bold;

  &.fill {
    width: 90%;
  }

  &:hover {
    opacity: 0.8;
  }

  @media ${media.mobile} {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;

    &:first-child {
      margin-right: 10px;
    }
  }
`;
