import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  startTransition,
} from 'react';
import { useParams } from 'react-router-dom';
import { SummaryHeader, SummaryToggle } from 'modules/summaries/components';
import { Loading, Error } from 'modules/shared/components/generic';
import { useUserContext } from 'providers/UserProvider';
import { useLoginDialog } from 'modules/shared/hooks/dialog';

import { styled } from 'styles';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import useSummaryTracker from '../hooks/useSummaryTracker';
import MathJaxProvider from 'providers/MathJaxProvider';

export default function SummaryPage() {
  const { subjectId } = useParams();
  const { user } = useUserContext();
  const [selectedChapterId, setSelectedChapterId] = useState(null);

  const { data: subject, loading, error } = useContentQueryImmutable(
    `/subjects/${subjectId}/with-summaries`
  );

  const { trackViewSummary, trackContentToGTM } = useSummaryTracker(subject);

  useLoginDialog({
    timeout: 0,
    condition: !user && selectedChapterId !== null,
    origin: 'summary',
  });

  const handleTogglePress = useCallback(
    chapter => {
      const isAlreadyOpen = selectedChapterId === chapter.id;
      startTransition(() => {
        setSelectedChapterId(isAlreadyOpen ? null : chapter.id);
      });

      trackViewSummary(chapter);
    },
    [setSelectedChapterId, selectedChapterId, trackViewSummary]
  );

  const chapters = useMemo(() => {
    if (!subject) return [];

    return subject.chapters.filter(
      c => c.topics.length > 0 && !!c.topics.find(t => !!t.summary)
    );
  }, [subject]);

  useEffect(() => {
    trackContentToGTM();
  }, [trackContentToGTM]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  if (!subject) return null;

  return (
    <MathJaxProvider>
      <SummaryHeader color={subject.theme.color} subjectName={subject.name} />
      <SummariesContainer>
        {chapters.map((c, i) => (
          <SummaryToggle
            color={subject.theme.color}
            key={c.id}
            chapter={c}
            i={i}
            isToggled={c.id === selectedChapterId}
            onClick={() => handleTogglePress(c, i)}
          />
        ))}
      </SummariesContainer>
    </MathJaxProvider>
  );
}

const SummariesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 760px;
  max-width: 95%;
  margin: 40px auto;
`;
