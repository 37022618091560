import React, { useCallback, useEffect, useState } from 'react';
import { media, styled } from 'styles';
import DisciplinesHeader from './DisciplinesHeader.js';
import ExamsContainer from './ExamsContainer.js';
import { useArrangeData } from '../hooks/useArrangeData';
import ContentTitle from './ContentTitle.js';
import ExerciseListLink from './ExerciseListLink.js';
import ContainerWithOnlyDisciplines from './ContainerWithOnlyDisciplines';
import { useDialogContext } from 'providers/DialogProvider.js';
import { useTracker } from 'modules/shared/hooks/Tracker.js';

export default function DisciplineContent({
  disciplines,
  selectedTheme,
  history,
}) {
  const {
    getDisciplinesFromSelectedTheme,
    getAllExamsFromDiscipline,
  } = useArrangeData();
  const disciplinesFromSelectedTheme = getDisciplinesFromSelectedTheme(
    disciplines,
    selectedTheme
  );
  const [selectedDiscipline, setSelectedDiscipline] = useState(
    disciplinesFromSelectedTheme[0]
  );
  const { unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();

  const hasExamsList = useCallback(() => {
    const listCategoryOfExams = getAllExamsFromDiscipline(selectedDiscipline);
    return listCategoryOfExams.length > 0;
  }, [selectedDiscipline, getAllExamsFromDiscipline]);

  const hasAnyList = useCallback(() => {
    return selectedDiscipline?.listCategories?.length > 0;
  }, [selectedDiscipline]);

  const onTheoryTitleClick = useCallback(() => {
    trackEvent('Dialog Conteúdo Personalizado: Clicou Teoria', {
      Disciplina: selectedDiscipline.name,
      Matéria: selectedTheme.name,
    });
    history.push(
      `/materias/${selectedDiscipline.id}/guia/${selectedDiscipline.guide.id}?internal_source=custom-dialog`
    );
    unsetDialog();
  }, [selectedDiscipline, history, unsetDialog, trackEvent, selectedTheme]);

  useEffect(() => {
    const disciplinesFromSelectedTheme = getDisciplinesFromSelectedTheme(
      disciplines,
      selectedTheme
    );
    setSelectedDiscipline(disciplinesFromSelectedTheme[0]);
  }, [disciplines, selectedTheme, getDisciplinesFromSelectedTheme]);

  return hasAnyList() ? (
    <>
      <DisciplinesHeader
        disciplines={disciplinesFromSelectedTheme}
        selectedDiscipline={selectedDiscipline}
        setSelectedDiscipline={setSelectedDiscipline}
      />
      <ContentContainer
        color={selectedDiscipline?.theme.color}
        className={!hasAnyList() && `top-border`}
      >
        {hasExamsList() && (
          <ExamsContainer
            disciplines={getDisciplinesFromSelectedTheme}
            selectedDiscipline={selectedDiscipline}
          />
        )}
        <ExerciseListLink selectedDiscipline={selectedDiscipline} />
        <ContentTitle
          title={`Teoria completa de ${selectedDiscipline.name}`}
          color={selectedDiscipline?.theme.color}
          onClick={onTheoryTitleClick}
        />
      </ContentContainer>
    </>
  ) : (
    <ContentContainer
      color={selectedDiscipline?.theme.color}
      className={!hasAnyList() && `top-border`}
    >
      <ContainerWithOnlyDisciplines
        selectedDiscipline={selectedDiscipline}
        disciplines={disciplinesFromSelectedTheme}
        history={history}
      />
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  width: 100%;
  border-radius: 0px 0px 6px 6px;
  margin: auto;
  border: 2px solid #d8d8d8;
  padding: 30px 40px 50px 40px;

  &.top-border {
    border-top: 8px solid ${({ color }) => color};
    padding-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  @media ${media.mobile} {
    padding: 10px 15px;
    border: 0;
  }
`;
