import { useCallback, useMemo, useState } from 'react';

export default function useLecturesSliders(themesList) {
  const [themes] = useState(themesList);
  const [selectedTheme, setSelectedTheme] = useState(themes[0]);

  const selectedThemeIndex = useMemo(() => {
    return selectedTheme?.id
      ? themes.findIndex(t => selectedTheme?.id === t.id)
      : 0;
  }, [selectedTheme, themes]);

  const updateSlickSlideOnNextArrowClick = useCallback(themesRef => {
    themesRef?.current?.slickNext();
  }, []);

  const updateSlickSlideOnPrevArrowClick = useCallback(themesRef => {
    themesRef?.current?.slickPrev();
  }, []);

  const updateSlickSlideOnThemeClick = useCallback(
    (clickedIndex, themesRef) => {
      if (clickedIndex === selectedThemeIndex) return;
      if (selectedThemeIndex === themes.length - 1 && clickedIndex === 0) {
        themesRef?.current?.slickNext();
        return;
      }
      if (clickedIndex === selectedThemeIndex + 1) {
        themesRef?.current?.slickNext();
        return;
      } else {
        themesRef?.current?.slickPrev();
      }
    },
    [selectedThemeIndex, themes]
  );

  const synchronizeLecturesSlider = useCallback(
    (newIndex, lecturesRef) => {
      if (selectedThemeIndex === themes.length - 1 && newIndex === 0) {
        lecturesRef?.current?.slickNext();
      }
      if (newIndex === selectedThemeIndex + 1) {
        lecturesRef?.current?.slickNext();
      } else {
        lecturesRef?.current?.slickPrev();
      }
      setSelectedTheme(themes[newIndex]);
    },
    [setSelectedTheme, themes, selectedThemeIndex]
  );

  return {
    updateSlickSlideOnNextArrowClick,
    updateSlickSlideOnPrevArrowClick,
    updateSlickSlideOnThemeClick,
    selectedThemeIndex,
    selectedTheme,
    synchronizeLecturesSlider,
  };
}
