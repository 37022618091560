import { useCallback, useMemo } from 'react';

import { useTracker, useUTM } from 'modules/shared/hooks';
import { useLectureContext } from 'modules/lectures/providers/LectureProvider';
import { media } from 'styles';
import { useUserContext } from 'providers/UserProvider';

export default function useLectureTracker() {
  const { lectureData: lecture } = useLectureContext();
  const { getUTMsByLocation } = useUTM();
  const {
    trackEvent,
    trackEventToRaEvents,
    trackMktCloudActivationEvents,
  } = useTracker();
  const { user } = useUserContext();

  const mktCloudTrackingInfo = useMemo(() => {
    const { subject } = lecture;
    return {
      subjectName: subject?.name,
      subjectId: subject?.id,
      themeName: subject?.theme.name,
      themeId: subject?.theme.id,
      blockedContent: user?.hasAccess ? false : true,
      platform: 'Web',
    };
  }, [lecture, user]);

  const lectureProperties = useMemo(() => {
    const { duration, subject } = lecture;
    return {
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Aulão: subject.name,
      Duração: duration,
      Matéria: subject.theme.name,
      ...getUTMsByLocation(window.location),
    };
  }, [lecture, getUTMsByLocation]);

  const trackErrorReport = useCallback(() => {
    trackEvent('Aulão: Reportou erro', {
      ...lectureProperties,
      Local: 'Megafone',
    });
  }, [lectureProperties, trackEvent]);

  const trackViewLecture = useCallback(() => {
    trackEvent('Aulão: Visualizou página', lectureProperties);
  }, [trackEvent, lectureProperties]);

  const trackPlayLecture = useCallback(() => {
    trackEvent('Aulão: Deu play', lectureProperties);
    trackMktCloudActivationEvents('ON_VIEW_LECTURE', mktCloudTrackingInfo);
  }, [
    trackEvent,
    lectureProperties,
    trackMktCloudActivationEvents,
    mktCloudTrackingInfo,
  ]);

  const trackPayWallLecture = useCallback(() => {
    trackEvent('Aulão: Terminou acesso grátis', lectureProperties);
  }, [trackEvent, lectureProperties]);

  const trackFinishLecture = useCallback(() => {
    trackEvent('Aulão: Terminou módulo', lectureProperties);
  }, [trackEvent, lectureProperties]);

  // TODO
  const trackChangeLectureSpeed = useCallback(() => {
    trackEvent('Aulão: Mudou velocidade', lectureProperties);
    // TODO, mandar a velocidade
  }, [trackEvent, lectureProperties]);

  const trackPraticeMoreClick = useCallback(() => {
    trackEvent('Aulão: Clicou Praticar Mais', lectureProperties);
  }, [trackEvent, lectureProperties]);

  // TODO
  const trackBulletModuleClick = useCallback(() => {
    trackEvent('Aulão: Clicou bolinha', lectureProperties);
    // TODO, mandar o modulo e posiçao modulo
  }, [trackEvent, lectureProperties]);

  // TODO
  const trackTopicClick = useCallback(() => {
    trackEvent('Aulão: Clicou Tópico', lectureProperties);
    // TODO, mandar o modulo e posiçao modulo
  }, [trackEvent, lectureProperties]);

  const getRaEventsInfo = useCallback(
    moduleIndex => {
      const { subject, lectureModules } = lecture;
      const lectureModule = lectureModules[moduleIndex];

      return {
        themeName: subject?.theme.name,
        themeId: subject?.theme.id,
        subjectName: subject?.name,
        subjectId: subject?.id,
        lectureId: lecture.id,
        moduleName: lectureModule.name,
        moduleId: lectureModule.id,
        contentId: lectureModule.video.id,
        blockedContent: user?.hasAccess ? false : true,
      };
    },
    [lecture, user]
  );

  const trackPlayModuleToRaEvents = useCallback(
    moduleIndex => {
      trackEventToRaEvents(
        '/view-lecture-module',
        getRaEventsInfo(moduleIndex)
      );
    },
    [trackEventToRaEvents, getRaEventsInfo]
  );

  return {
    trackErrorReport,
    trackViewLecture,
    trackPlayLecture,
    trackPayWallLecture,
    trackFinishLecture,
    trackChangeLectureSpeed,
    trackPraticeMoreClick,
    trackBulletModuleClick,
    trackTopicClick,
    trackPlayModuleToRaEvents,
  };
}
