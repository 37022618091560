import React from 'react';
import { styled } from 'styles';
import { useLectureContext } from '../../providers/LectureProvider';
import {
  VideoSpeedControl,
  VideoPlayPauseControl,
  VideoVolumeControl,
  FullScreenControl,
  VideoProgressBarControl,
} from '../index';
import { media } from 'styles';

const PlayerControl = ({
  className = '',
  enterFullScreen,
  fullScreen,
  exitFullScreen,
  seekTo,
}) => {
  const { restartCurrentHoverModule } = useLectureContext();
  return (
    <ControlBar onMouseEnter={restartCurrentHoverModule} className={className}>
      {!media.isMobile() && <VideoPlayPauseControl />}

      <VideoProgressBarControl seekTo={seekTo} />

      {!media.isMobile() && <VideoVolumeControl />}

      {!media.isMobile() && (
        <FullScreenControl
          enterFullScreen={enterFullScreen}
          fullScreen={fullScreen}
          exitFullScreen={exitFullScreen}
        />
      )}

      {!media.isMobile() && <VideoSpeedControl />}
    </ControlBar>
  );
};

const ControlBar = styled.div`
  user-select: none;
  transition: (opacity 300ms ease-in-out);
  z-index: 3;
  padding: 20px 10px;
  height: 85px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  .icon {
    font-size: 20px !important;
    margin: 0 10px;
    cursor: pointer;
  }
`;

export default PlayerControl;
