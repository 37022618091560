import styled from 'styled-components/macro';

export const ThemesContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  max-width: 1280px;
  margin: ${({ showingBigSearch }) =>
    showingBigSearch ? '0 auto 0 auto' : '50px auto 0 auto'};
  padding-bottom: 50px;
  margin-top: 30px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 30px;
  width: 320px;
`;

export const RightContainer = styled.div`
  flex: 1;
  position: relative;
  margin-right: 30px;
  border-radius: 10px;
`;

export const SearchThemesContainer = styled.div`
  margin: 0 auto 0 auto;
  flex: 1;
  display: flex;
  align-items: flex-start;
  max-width: 1280px;
`;

export const SearchLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 30px;
  width: 320px;
`;

export const SearchRightContainer = styled.div`
  flex: 1;
  position: relative;
  margin-right: 30px;
  border-radius: 10px;
`;
