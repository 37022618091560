import { useCallback } from 'react';
import TagManager from 'react-gtm-module';
import { isStar, isTop50 } from 'providers/UniversityProvider';

function useGTM() {
  const addTagManagerUserData = useCallback(user => {
    const userNames = user?.name?.split(' ');
    TagManager.dataLayer({
      gtmId: process.env.REACT_APP_TAG_MANAGER_ID,
      dataLayer: {
        userId: user?.id,
        subscriber: user?.subscriber,
        userFirstName: userNames && userNames[0],
        userLastName: userNames && userNames[userNames.length - 1],
        iesAcronym: user?.university?.acronym,
        iesId: user?.university?.id,
        isStar: isStar(user?.university?.id),
        isTop50: isTop50(user?.university?.id),
        email: user?.email,
        phone: user?.phone || null,
      },
    });
  }, []);

  const removeTagManagerUserData = useCallback(() => {
    TagManager.dataLayer({
      gtmId: process.env.REACT_APP_TAG_MANAGER_ID,
    });
  }, []);

  const addVariableToGTMDataLayer = useCallback((propertiesObject = {}) => {
    const isObjectEmpty = Object.keys(propertiesObject).length === 0;
    if (isObjectEmpty) return;

    window.dataLayer.push(propertiesObject);
  }, []);

  return {
    addTagManagerUserData,
    addVariableToGTMDataLayer,
    removeTagManagerUserData,
  };
}

export { useGTM };
