import React, { useCallback } from 'react';

import { styled, colors } from 'styles';
import { ToggleableComponent } from 'modules/shared/components/generic';
import raWhiteIcon from 'images/workspace/ra-white-icon.png';

export default function ExerciseShowSolutionAndAnswerToggle({
  onClick,
  showSolutionAndAnswer,
  answerComponent,
}) {
  const scrollToAnswer = useCallback(() => {
    answerComponent.current?.scrollIntoView({ behavior: 'smooth' });
  }, [answerComponent]);

  return (
    <Container data-cy="exercise-show-answer-button-container">
      <Title>Responde Aí</Title>
      <Button onClick={onClick} />
      <ToggleableComponent
        open={showSolutionAndAnswer}
        height="30px"
        animationTime="0.5s"
      >
        <SkipToAnswer onClick={scrollToAnswer}>
          Pular para Gabarito
        </SkipToAnswer>
      </ToggleableComponent>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
`;

const Title = styled.h1`
  color: ${colors.exercise};
  font-weight: bold;
  font-size: 1.15em;
`;

const Button = styled.div`
  width: 70px;
  height: 70px;
  margin-top: 8px;
  background-image: url(${raWhiteIcon});
  background-clip: padding-box;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: ${colors.mainOrange};
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  border-radius: 50%;
  border: 2px solid #fff;
  cursor: pointer;
`;

const SkipToAnswer = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.85em;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
