import React, { useMemo } from 'react';
import { styled, media } from 'styles';
import { MathjaxContent } from 'modules/shared/components/generic';
import { useWorkspaceExercise } from 'modules/workspace/hooks/shared';

export default function ExerciseSolution({ exercise, useRef, contentAccess }) {
  const { getExerciseColor } = useWorkspaceExercise();

  const concatenatedSolution = useMemo(() => {
    let filteredSolutions = exercise.lightSolution?.filter(
      solution => solution?.length > 0
    );

    const solutionStepsWithTitle = filteredSolutions?.map(
      (solutionStep, index) =>
        `<div class="title">Passo ${index + 1}</div> ${solutionStep}`
    );

    if (!solutionStepsWithTitle) return '';

    return solutionStepsWithTitle.reduce((acc, step) => acc + step, '');
  }, [exercise]);

  return (
    <Container
      ref={useRef}
      color={getExerciseColor()}
      data-cy="exercise-solution"
    >
      <MathjaxContent
        html={concatenatedSolution}
        replaceToFakeContent={!contentAccess}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 0 30px;

  @media ${media.mobile} {
    padding: 0 10px;
  }

  .title {
    font-size: 1.6em;
    color: ${({ color }) => color};
    font-family: 'Droid Serif', serif;
    padding-bottom: 30px;
  }
`;
