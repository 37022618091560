import React, { useEffect, useCallback, useMemo, useState } from 'react';

import { useTracker, useTestAB } from 'modules/shared/hooks';
import { useBannerMakerFunctions } from 'modules/banner/shared/banners/BannerMaker/sharedFunctions';
import { emitter } from '@marvelapp/react-ab-test';
import {
  Container,
  Button,
  TextContainer,
  TextSubtitle,
  TextTitle,
  Content,
} from './styles';

export default function MobileBannerMaker() {
  const { trackEvent } = useTracker();
  const {
    bannerData: mobileBannerData,
    getBannerLayoutData,
    getTitleToShow,
    getSubTitleToShow,
  } = useBannerMakerFunctions();
  const [layoutToShow, setLayoutToShow] = useState(null);
  const { getVariantFromTestAB } = useTestAB();

  emitter.defineVariants('bannerTest', ['A', 'B']);

  const amplitudeEventAttributes = useMemo(() => {
    let attributes = {
      local: 'mobile',
    };
    if (mobileBannerData?.hasTestAb && !mobileBannerData?.testWinner) {
      attributes = {
        ...attributes,
        variant: getVariantFromTestAB('bannerTest'),
      };
    }

    return attributes;
  }, [mobileBannerData, getVariantFromTestAB]);

  useEffect(() => {
    if (!mobileBannerData) return;

    trackEvent(
      `${mobileBannerData.name}: Visualizou Banner`,
      amplitudeEventAttributes
    );
  }, [mobileBannerData, trackEvent, amplitudeEventAttributes]);

  useEffect(() => {
    getBannerLayoutData({ bannerType: 'mobile' });

    if (mobileBannerData?.hasTestAb && mobileBannerData?.testWinner === 'A') {
      setLayoutToShow(mobileBannerData?.layoutA);
      return;
    }

    if (mobileBannerData?.hasTestAb && mobileBannerData?.testWinner === 'B') {
      setLayoutToShow(mobileBannerData?.layoutB);
      return;
    }

    let layoutToShow = mobileBannerData?.layoutA;
    if (
      mobileBannerData?.hasTestAb &&
      getVariantFromTestAB('bannerTest') === 'B'
    )
      layoutToShow = mobileBannerData?.layoutB;

    setLayoutToShow(layoutToShow);
  }, [getBannerLayoutData, mobileBannerData, getVariantFromTestAB]);

  const onBannerClick = useCallback(() => {
    if (!mobileBannerData) return;

    trackEvent(
      `${mobileBannerData.name}: Clicou Banner`,
      amplitudeEventAttributes
    );

    window.location.href = layoutToShow.container.redirectLink;
  }, [mobileBannerData, amplitudeEventAttributes, trackEvent, layoutToShow]);

  if (!layoutToShow) return null;

  return (
    <Container {...layoutToShow.container} onClick={onBannerClick}>
      <Content {...layoutToShow.container}>
        <TextContainer>
          {layoutToShow.title && (
            <TextTitle {...layoutToShow.title}>
              {getTitleToShow(layoutToShow.title)}
            </TextTitle>
          )}
          {layoutToShow.subtitle && (
            <TextSubtitle {...layoutToShow.subtitle}>
              {getSubTitleToShow(layoutToShow.subtitle)}
            </TextSubtitle>
          )}
        </TextContainer>
        {layoutToShow.button && (
          <Button {...layoutToShow.button}>{layoutToShow.button.text}</Button>
        )}
      </Content>
    </Container>
  );
}
