import React, { useState, useEffect, useCallback } from 'react';

import { styled, colors, media } from 'styles';
import { useChangePlanCampaign } from 'modules/campaigns/change-plan/hooks';
import ChangePlanExitDialog from './ChangePlanExitDialog';
import ChangePlanConfirmationDialog from './ChangePlanConfirmationDialog';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker, useCurrency, useUTM } from 'modules/shared/hooks';

export default function ChangePlanOptionsDialog({ subscription }) {
  const [checkedPlanPricing, setCheckedPlanPricing] = useState(null);
  const {
    getAvailablePlanPricingsToChange,
    getInstallmentPriceFromPlanPricing,
  } = useChangePlanCampaign();
  const { setDialog } = useDialogContext();
  const { formatCurrency } = useCurrency();
  const { getUTMsByLocation } = useUTM();
  const { trackEvent } = useTracker();

  const getAmplitudeEventProperties = useCallback(() => {
    return {
      'Preco Plano Atual': formatCurrency(subscription.order.planPricing.price),
      Onde: media.isDesktop() ? 'Desktop' : 'Mobile',
      ...getUTMsByLocation(window.location),
    };
  }, [formatCurrency, subscription, getUTMsByLocation]);

  useEffect(() => {
    // set initial plan pricing selected
    const planPricingsAvailable = getAvailablePlanPricingsToChange();
    const length = planPricingsAvailable.length;
    setCheckedPlanPricing(planPricingsAvailable[length - 1]);

    // track amplitude event
    trackEvent(
      'Troca de Plano: Dialog Planos Visualizou',
      getAmplitudeEventProperties()
    );
  }, [
    getAmplitudeEventProperties,
    getAvailablePlanPricingsToChange,
    trackEvent,
  ]);

  const handleNextStepPress = useCallback(() => {
    trackEvent('Troca de Plano: Dialog Planos Clicou Avançar', {
      ...getAmplitudeEventProperties(),
      'Preço Plano Escolhido': formatCurrency(checkedPlanPricing.price),
    });
    setDialog(
      <ChangePlanConfirmationDialog
        subscription={subscription}
        newPlanPricing={checkedPlanPricing}
      />
    );
  }, [
    trackEvent,
    getAmplitudeEventProperties,
    formatCurrency,
    setDialog,
    subscription,
    checkedPlanPricing,
  ]);

  const handleExitPress = useCallback(() => {
    trackEvent('Troca de Plano: Saiu do Fluxo', {
      ...getAmplitudeEventProperties(),
      Origem: 'Dialog Planos',
    });
    setDialog(<ChangePlanExitDialog subscription={subscription} />);
  }, [trackEvent, getAmplitudeEventProperties, setDialog, subscription]);

  const availablePlanPricings = getAvailablePlanPricingsToChange();
  return (
    <Container>
      <Content>
        <Title>
          Seu plano atual: {subscription.plan.description} (R$
          {formatCurrency(subscription.order.planPricing.price)}/mês)
        </Title>

        <ChoosePlan>
          {availablePlanPricings.map(pricing => (
            <PlanOption
              onClick={() => setCheckedPlanPricing(pricing)}
              className={
                checkedPlanPricing &&
                pricing.id === checkedPlanPricing.id &&
                'selected'
              }
              key={pricing.id}
            >
              <PlanDot
                className={
                  checkedPlanPricing &&
                  pricing.id === checkedPlanPricing.id &&
                  'selected'
                }
              />
              <PlanName>{pricing.plan.description}</PlanName>
              <PlanDescription>
                {pricing.plan.months}x de{' '}
                <b>
                  R${' '}
                  {formatCurrency(
                    getInstallmentPriceFromPlanPricing(pricing, pricing.plan)
                  )}
                </b>
              </PlanDescription>
            </PlanOption>
          ))}
        </ChoosePlan>

        {checkedPlanPricing && (
          <Warning>
            Atenção: O plano {checkedPlanPricing.plan.description.toLowerCase()}{' '}
            te dá {checkedPlanPricing.plan.months}
            &nbsp;meses de acesso ao Responde Aí, e o pagamento é feito em{' '}
            {checkedPlanPricing.plan.months} parcelas. &nbsp;A renovação será
            automática após esse período. Não é possível cancelar o pagamento
            das parcelas, porém &nbsp;você pode desativar a renovação a qualquer
            momento.
          </Warning>
        )}

        <ButtonsContainer>
          <Button onClick={handleExitPress}>Sair</Button>
          <Button className="full" onClick={handleNextStepPress}>
            Continuar
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 80px;
  border-top: 10px solid ${colors.purple};
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const Title = styled.div`
  font-size: 1.4em;
  font-weight: bold;
  padding: 5px 0;
  margin-top: 25px;
  background-color: ${colors.purple};
  color: #fff;
  width: 100%;
  text-align: center;
`;

const Warning = styled.div`
  margin: 20px 0;
  font-size: 0.8em;
  line-height: 1.4;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Button = styled.div`
  width: 45%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  color: ${colors.mainOrange};
  border: 2px solid ${colors.mainOrange};
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  &.full {
    color: #fff;
    background-color: ${colors.mainOrange};
  }
`;

export const ChoosePlan = styled.div`
  display: flex;
  width: 400px;
  max-width: 100%;
  margin: auto;

  b {
    margin-bottom: 10px;
  }
`;

export const PlanOption = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
  cursor: pointer;
  margin: 20px 0;

  &.selected {
    background-color: rgba(249, 172, 62, 0.1);
  }
`;

export const PlanDot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50px;
  border: 2px solid #666;
  background: #fff;

  &.selected {
    background: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
  }
`;

export const PlanName = styled.span`
  margin: 5px 0;
  font-size: 1.1em;
  font-weight: bold;
`;

export const PlanDescription = styled.span`
  line-height: 1.3;
  font-size: 0.9em;

  @media ${media.mobile} {
    font-size: 0.75em;
  }
`;
