import React, { useCallback, useEffect, useRef, useState } from 'react';
import { media } from 'styles';
import { SuggestionsContainer, SuggestionItem } from './styles';
import { getSearchSuggestions } from 'apis/search/Suggestions';
import { useTracker } from 'modules/shared/hooks';
import { removeMathsFromHtml } from './helper';
import SearchEvents from './SearchEvents';

function SearchFieldSuggestions({
  searchTerm,
  setTerm,
  setSearchTerm,
  showSuggestions,
  setShowSuggestions,
  currentIndexSuggestion,
  setSuggestions: setSuggestionsState,
}) {
  const { trackEvent } = useTracker();
  const [suggestions, setSuggestions] = useState([]);

  const timer = useRef(null);

  useEffect(() => {
    if (!searchTerm || !searchTerm.trim()) return;

    const trimmed = searchTerm.trim().replace(/ +(?= )/g, '');
    const start = new Date();

    const req = getSearchSuggestions(trimmed, true, start);

    timer.current = start;

    req.then(data => {
      const lc = term => term.toLowerCase().trim();

      if (data.time < timer.current) return;

      const sugg = data.suggestions;

      if (sugg.length === 1 && lc(sugg[0].term) === lc(searchTerm)) {
        setSuggestions([]);

        setTerm(searchTerm);

        return;
      }

      if (sugg.length === 0) {
        setTerm(searchTerm);
      }

      setSuggestions(sugg);

      setSuggestionsState(sugg.map(item => item.term));

      if (sugg.length > 0) {
        const time = new Date() - start;

        new SearchEvents({
          amplitudeTracker: trackEvent,
        }).gotSuggestions(trimmed, time);
      }
    });
  }, [searchTerm, setTerm, trackEvent, setSuggestionsState]);

  const getSuggestionItemHTML = useCallback(
    (suggestionItem, showTheme = true) => {
      const item = suggestionItem.term;
      const theme = suggestionItem.theme;

      const escapeRegExp = string =>
        string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

      const highlighted = item.replace(
        new RegExp(`(${escapeRegExp(searchTerm)})(.*)`, 'gi'),
        `$1<b>$2</b>`
      );

      let html = `
      <span class="highlight">
        ${highlighted}
      </span>`;

      if (showTheme)
        html += `<span class="theme">
        (${theme})
      </span>`;

      return html;
    },
    [searchTerm]
  );

  const onClickSuggestion = useCallback(
    (it, i) => {
      const cleaned = removeMathsFromHtml(it.term.trim())[0];

      setTerm(cleaned);
      setSearchTerm(cleaned);

      setSuggestions([]);
      setShowSuggestions(false);

      new SearchEvents({
        amplitudeTracker: trackEvent,
      }).clickInSuggestion(cleaned, i);
    },
    [setSearchTerm, setShowSuggestions, setTerm, trackEvent]
  );

  if (suggestions.length === 0 || !showSuggestions) return null;

  return (
    <SuggestionsContainer className="suggestions" isMobile={media.isMobile()}>
      {suggestions.map((it, i) => (
        <SuggestionItem
          isMobile={media.isMobile()}
          selected={currentIndexSuggestion === i}
          className="suggestion"
          key={`${suggestions.toString()}-${i}`}
          onClick={() => {
            onClickSuggestion(it, i);
          }}
        >
          <a
            dangerouslySetInnerHTML={{
              __html: getSuggestionItemHTML(it, true),
            }}
          />
        </SuggestionItem>
      ))}
    </SuggestionsContainer>
  );
}

export default SearchFieldSuggestions;
