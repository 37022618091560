import { useUserContext } from 'providers/UserProvider';
import React, { useEffect } from 'react';
import { styled } from 'styles';
import dexterImage from 'modules/banner/images/book-banner/university-personalized-content/dexter.png';
import { usePersonalizedContentSharedFunctions } from '../sharedFunctions.js';

export default function UniversityPersonalizedContentBanner() {
  const { user } = useUserContext();
  const {
    viewBannerEvent,
    onClickBanner,
  } = usePersonalizedContentSharedFunctions();

  useEffect(() => {
    viewBannerEvent();
  }, [viewBannerEvent]);

  return (
    <ContainerBanner onClick={onClickBanner}>
      <Content>
        <DexterImage src={dexterImage} alt="dexter" />
        <Description>
          <h2>
            Conteúdos feitos para você <br />
            {` mandar bem ${user?.university?.gender === 'F' ? 'na' : 'no'} ${
              user?.university?.acronym
            }`}
          </h2>
        </Description>
        <CTAButton>clique e confira</CTAButton>
      </Content>
    </ContainerBanner>
  );
}

const ContainerBanner = styled.div`
  width: 100%;
  height: 120px;
  background: linear-gradient(180deg, #7000ff 0%, #92227a 59.37%, #ff9d2d 100%),
    #bb8ab9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1100px;
  width: 90%;
`;

const DexterImage = styled.img`
  height: 115px;
`;

const Description = styled.div`
  margin-left: -15px;

  h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: #ffffff;
  }
`;

const CTAButton = styled.button`
  width: 220px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 30px #ffffff;
  border-radius: 10px;
  outline: 0;
  border: 0;
  text-transform: uppercase;
  color: #410094;
  font-weight: bold;
  font-size: 17px;
`;
