import React, { useState } from 'react';
import { styled } from 'styles';
import { useUserContext } from 'providers/UserProvider';
import ReactPlayer from 'react-player/youtube';

import { videoMask } from '../images';
import Overlay from './Overlay';

export default function VideoContainer(props) {
  const { user } = useUserContext();
  const { lecture } = props;
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);

  if (!lecture) return null;

  return (
    <VideoContainerStyled>
      {!videoIsPlaying && (
        <Overlay lecture={lecture} setVideoIsPlaying={setVideoIsPlaying} />
      )}
      {!user ? (
        <img alt="" src={videoMask} className="mask" />
      ) : (
        <ReactPlayer
          url={`https://www.youtube.com/watch?v=${lecture.link}`}
          width="100%"
          height="100%"
          playing={videoIsPlaying}
          controls={true}
        />
      )}
    </VideoContainerStyled>
  );
}

const VideoContainerStyled = styled.div`
  width: 67%;
  height: 440px;
  position: relative;

  .mask {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1165px) {
    width: 100%;
  }
`;
