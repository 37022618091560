import { useMemo } from 'react';

import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';

export default function useSummary() {
  const { currentChapter } = useWorkspaceContentContext();

  const currentSummaries = useMemo(() => {
    const { topics } = currentChapter;
    return topics.map(topic => topic.summary).filter(summary => !!summary);
  }, [currentChapter]);

  return { currentSummaries };
}
