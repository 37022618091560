import { useCallback } from 'react';
import { pricings } from '../data/pricings';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useFormValidate } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';

export function usePurchase() {
  const sendPostRequest = useRAPostRequest(`/payments/process_purchase`);
  const { user } = useUserContext();

  const {
    validCpf,
    validPhone,
    validCreditCardHolderName,
    validCreditCardNumber,
    validCreditCardValidateDate,
    validCreditCardCvv,
  } = useFormValidate();

  const getCouponCode = useCallback(pricingId => {
    const pricing = pricings.find(pricing => pricing.id === pricingId);

    return pricing?.promotionalCode || null;
  }, []);

  const getSerializedPurchaseData = useCallback(
    (pricingId, userData, cardData, installments) => {
      if (!user) return;
      if (!pricingId) return;

      const data = {
        purchaseData: {
          userData: {
            name: user.name,
            cpf: userData.cpf,
            phone: userData.phone,
          },
          billingAddress: {
            postalCode: '20091-901',
            district: 'Centro',
            street: 'Rua Visconde de Inhauma',
            number: '134',
            state: 'RJ',
            city: 'Rio de Janeiro',
            cityCode: '3304557',
            complement: '20 Andar Sala 2001',
          },
          creditCard: {
            number: cardData.number,
            holderName: cardData.holderName,
            expiry: cardData.expiry,
            cvv: cardData.cvv,
          },
          planPricingId: pricingId,
          paymentMethod: 'credit_card',
          installments: installments,
          couponCode: getCouponCode(pricingId),
        },
      };

      return data;
    },
    [user, getCouponCode]
  );

  const getCreditCardErrors = useCallback(
    creditCard => {
      const number = validCreditCardNumber(creditCard.number)
        ? ''
        : 'Número de cartão inválido.';
      const holderName = validCreditCardHolderName(creditCard.holderName)
        ? ''
        : 'Nome inválido.';
      const expiry = validCreditCardValidateDate(creditCard.expiry)
        ? ''
        : 'Data inválida.';
      const cvv = validCreditCardCvv(creditCard.cvv)
        ? ''
        : 'Código de segurança inválido.';

      return { number, holderName, expiry, cvv };
    },
    [
      validCreditCardCvv,
      validCreditCardHolderName,
      validCreditCardNumber,
      validCreditCardValidateDate,
    ]
  );

  const getUserDataErrors = useCallback(
    userData => {
      const phone = validPhone(userData.phone)
        ? ''
        : 'Número incorreto. Formato: (xx) xxxxx-xxxx';
      const cpf = validCpf(userData.cpf)
        ? ''
        : 'Por favor, preencha um CPF válido.';

      return { phone, name, cpf };
    },
    [validPhone, validCpf]
  );

  const sendPayment = useCallback(
    async (pricingId, userData, cardData, installments) => {
      const purchaseData = getSerializedPurchaseData(
        pricingId,
        userData,
        cardData,
        installments
      );
      const { data, error } = await sendPostRequest(purchaseData);

      return { data, error };
    },
    [getSerializedPurchaseData, sendPostRequest]
  );

  return {
    getSerializedPurchaseData,
    getCreditCardErrors,
    getUserDataErrors,
    sendPayment,
  };
}
