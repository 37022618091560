import { useCallback, useMemo } from 'react';

import { useUserContext } from 'providers/UserProvider';
import { useRAQuery } from 'modules/shared/hooks/requests';
import useActivationCounter from 'modules/shared/hooks/useActivationCounter';
export default function useUserUniversityConfirmationDialog() {
  const { user } = useUserContext();
  const { data } = useRAQuery('/profile/user_university_confirmation');
  const { getActivationUniversityConfirmation } = useActivationCounter();

  const isOnActivationLimit = useMemo(
    () => getActivationUniversityConfirmation() >= 3,
    [getActivationUniversityConfirmation]
  );

  const showUserUniversityConfirmationDialog = useCallback(() => {
    const shouldShowDialog = data?.isEligible;
    return (
      user &&
      shouldShowDialog &&
      (user?.university?.name === 'Não faço faculdade' ||
        user?.university?.name.includes('Outra universidade')) &&
      isOnActivationLimit
    );
  }, [user, data, isOnActivationLimit]);

  return { showUserUniversityConfirmationDialog };
}
