import React, { useCallback, useMemo } from 'react';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';

import { styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import HeartBreakCancelDialog from '../dialogs/cancellation/HeartBreakCancelDialog';
import { useTracker, useDate } from 'modules/shared/hooks';

export default function CancelSubscriptionButton({ user, subscription }) {
  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const { getHumanReadableDate } = useDate();

  const isWaitingCancellationConfirmation = useCallback(() => {
    return !!Cookies.get('cancel_subscription_processing');
  }, []);

  const handleCancelSubscriptionPress = useCallback(() => {
    if (isWaitingCancellationConfirmation()) {
      alert(
        'Seu cancelamento já está sendo processado, isso pode levar alguns minutos. Recarregue a página para atualizar o status.'
      );
      return;
    }

    trackEvent('Minha Conta: Clicou Cancelar Assinatura');
    setDialog(<HeartBreakCancelDialog subscription={subscription} />);
  }, [trackEvent, subscription, setDialog, isWaitingCancellationConfirmation]);

  const cancellationLimitDate = useMemo(() => {
    const date = new Date(subscription.expiresIn * 1000);
    date.setDate(date.getDate() - 3);
    return date;
  }, [subscription]);

  const buttonText = useMemo(() => {
    if (user && user.scheduledOrder) {
      if (user.scheduledOrder.plan.months === 1) return 'Cancelar a Assinatura';
      else return 'Desativar a Renovação';
    }

    if (subscription.plan.months === 1) return 'Cancelar a Assinatura';
    else return 'Desativar a Renovação';
  }, [subscription, user]);

  const cancellationWarning = useMemo(() => {
    if (isWaitingCancellationConfirmation())
      return 'Você já solicitou o cancelamento. Ele será confirmado em até 5 minutos.';

    return `*Você só pode cancelar sua renovação até o dia ${getHumanReadableDate(
      cancellationLimitDate
    )}`;
  }, [
    cancellationLimitDate,
    isWaitingCancellationConfirmation,
    getHumanReadableDate,
  ]);

  return (
    <Container
      className={isWaitingCancellationConfirmation() ? 'disabled' : ''}
      data-cy="cancel-subscription-button"
    >
      <button onClick={handleCancelSubscriptionPress}>
        <Icon icon={faBan} />
        {buttonText}
      </button>
      <p>{cancellationWarning}</p>
    </Container>
  );
}

const Container = styled.div`
  button {
    background-color: #eee;
    font-size: 1em;
    padding: 5px 15px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    svg {
      margin-right: 5px;
    }
  }

  p {
    margin-top: 5px;
    font-size: 0.8em;
    font-weight: bold;
  }

  &.disabled {
    opacity: 0.5;
  }
`;
