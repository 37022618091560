import { useCallback } from 'react';

export default function useSearchFeedback() {
  const shouldShowSearchFeedbackDialog = useCallback(() => {
    const utmParams = new URLSearchParams(window.location.search);
    const isFromSearch = utmParams.get('internal_source');
    return isFromSearch === 'search_image';
  }, []);

  return { shouldShowSearchFeedbackDialog };
}
