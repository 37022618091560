import React from 'react';
import { ClipLoader } from 'modules/shared/components/generic/loaders';
import { styled, colors } from 'styles';
import { MdCheckCircleOutline } from 'react-icons/md';

export default function DownloadableSendButton({
  loading,
  handleSubmit,
  updated,
}) {
  return (
    <>
      {!loading ? (
        <ButtonsContainer>
          {updated ? (
            <Button className="updated">
              Atualizado <MdCheckCircleOutline className="icon" />
            </Button>
          ) : (
            <Button onClick={handleSubmit} className="submit">
              Enviar
            </Button>
          )}
        </ButtonsContainer>
      ) : (
        <ClipLoader color="#f7ac3b" />
      )}
    </>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  font-size: 1.1em;
  font-weight: bold;
  user-select: none;
`;

const Button = styled.div`
  padding: 10px 0;
  border: 1px solid ${colors.lightGray};
  border-radius: 10px;
  width: 100%;
  max-width: 580px;
  &.submit {
    background-color: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
    color: white;
    &:hover {
      background-color: white;
      border-color: ${colors.mainOrange};
      color: ${colors.mainOrange};
    }
  }
  &.updated {
    border: 2px solid #7bd77d;
    color: #7bd77d;
    .icon {
      font-size: 1.5em;
      font-weight: bold;
      margin-bottom: -5px;
      margin-left: 10px;
    }
  }
`;
