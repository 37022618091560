import { useCallback } from 'react';
import { useUniversityContext } from 'providers/UniversityProvider';
import { useCookies } from 'modules/shared/hooks';

export default function useWorkspaceContentFormat() {
  const { isFromPrivateUniversityCampaign } = useUniversityContext();
  const [cookies, setCookie] = useCookies(['video-or-text']);

  const getInitialDefaultFormat = useCallback(() => {
    if (isFromPrivateUniversityCampaign) return 'video';
    return 'text';
  }, [isFromPrivateUniversityCampaign]);

  const hasVideoFormat = useCallback(content => {
    if (!content || !content.videos) return false;
    return content.videos.length > 0;
  }, []);

  const hasTextFormat = useCallback(content => {
    return content && content.lightBody;
  }, []);

  const hasManyContentFormats = useCallback(
    content => {
      return hasVideoFormat(content) && hasTextFormat(content);
    },
    [hasVideoFormat, hasTextFormat]
  );

  const getContentFormat = useCallback(
    content => {
      if (!content) return;

      if (!hasVideoFormat(content)) return 'text';
      if (!hasTextFormat(content)) return 'video';

      const contentFormat =
        cookies['video-or-text'] || getInitialDefaultFormat();
      return contentFormat;
    },
    [getInitialDefaultFormat, hasVideoFormat, hasTextFormat, cookies]
  );

  const setFormatInCookies = useCallback(
    format => {
      const now = new Date();
      const oneYearInTheFutureDate = new Date(
        now.getFullYear() + 1,
        now.getMonth(),
        now.getDate()
      );
      setCookie('video-or-text', format, { expires: oneYearInTheFutureDate });
    },
    [setCookie]
  );

  return {
    getContentFormat,
    hasVideoFormat,
    hasTextFormat,
    hasManyContentFormats,
    setFormatInCookies,
  };
}
