import React from 'react';
import { useLectureContext } from '../../providers/LectureProvider';
import { styled } from 'styles';

import { PlayArrow, Pause } from '@material-ui/icons';

const VideoPlayPauseControl = () => {
  const { playing, setPlaying } = useLectureContext();

  return (
    <Rounded
      onClick={() => {
        setPlaying(!playing);
      }}
    >
      {playing ? <PauseIcon /> : <PlayIcon />}
    </Rounded>
  );
};

const Rounded = styled.div`
  width: 45px;
  border-radius: 50%;
  color: white;
  height: 45px;
  cursor: pointer;
  background-color: #f9ac3e;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 7px;
`;

const PlayIcon = styled(PlayArrow)`
  font-size: 30px !important;
`;

const PauseIcon = styled(Pause)`
  font-size: 30px !important;
`;

export default VideoPlayPauseControl;
