import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { styled, media } from 'styles';
import { FiCheck } from 'react-icons/fi';
import {
  MdOutlineUploadFile,
  MdPictureAsPdf,
  MdOutlineWarning,
} from 'react-icons/md';
import { useDropzone } from 'react-dropzone';
import { DoubleButtonContainer, UploadArea, StepsContainer } from './styles';
import { useTracker } from 'modules/shared/hooks';

export default function UploadSession({
  materialType,
  file,
  setFile,
  onSubmitInfo,
  onFormBack,
  discipline,
  selectedTheme,
  testInfo,
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDetail, setErrorDetail] = useState('');
  const { trackEvent } = useTracker();

  const canSubmit = useMemo(() => !!file && !errorMessage, [
    file,
    errorMessage,
  ]);

  const validateFile = useCallback(
    acceptedFile => {
      if (acceptedFile?.size > 1048576) {
        setErrorMessage('O arquivo ultrapassa o limite permitido.');
        setErrorDetail('O tamanho máximo permitido é 1.0MB');
        trackEvent('Listas: Página de Upload - Erro ao upar Arquivo', {
          'Tipo de Erro': 'Tamanho do arquivo',
          'Tipo de Material': materialType,
          Matéria: selectedTheme?.name,
          Disciplina: discipline?.name,
          Categoria: testInfo?.category,
          Ano: testInfo?.year,
          Período: testInfo?.period,
        });
        setFile(null);
        return false;
      } else {
        if (errorMessage) setErrorMessage('');
        setFile(acceptedFile);
        return true;
      }
    },
    [
      setFile,
      setErrorMessage,
      errorMessage,
      setErrorDetail,
      trackEvent,
      selectedTheme,
      discipline,
      materialType,
      testInfo,
    ]
  );

  useEffect(() => {
    trackEvent('Listas: Visualizou Página de Upload');
  }, [trackEvent]);

  useEffect(() => {
    validateFile(file);
  }, [validateFile, file]);

  const handleNext = useCallback(() => {
    if (!canSubmit) return;
    onSubmitInfo();
  }, [canSubmit, onSubmitInfo]);

  const handleBack = useCallback(() => onFormBack(), [onFormBack]);

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 1) {
        setErrorMessage('Você só pode fazer o upload de um único arquivo.');
        trackEvent('Listas: Página de Upload - Erro ao upar Arquivo', {
          'Tipo de Erro': 'Arquivos múltiplos',
          'Tipo de Material': materialType,
          Matéria: selectedTheme?.name,
          Disciplina: discipline?.name,
          Categoria: testInfo?.category,
          Ano: testInfo?.year,
          Período: testInfo?.period,
        });
        if (errorDetail) setErrorDetail('');
        setFile(null);
        return;
      } else if (acceptedFiles.length === 1 && validateFile(acceptedFiles[0])) {
        trackEvent('Listas: Página de Upload - Carregou Arquivo', {
          'Tipo de Material': materialType,
          Matéria: selectedTheme?.name,
          Disciplina: discipline?.name,
          Categoria: testInfo?.category,
          Ano: testInfo?.year,
          Período: testInfo?.period,
        });
      } else if (acceptedFiles.length === 0) {
        setErrorMessage('Aceitamos apenas arquivo do formato pdf.');
        trackEvent('Listas: Página de Upload - Erro ao upar Arquivo', {
          'Tipo de Erro': 'Formato incompatível',
          'Tipo de Material': materialType,
          Matéria: selectedTheme?.name,
          Disciplina: discipline?.name,
          Categoria: testInfo?.category,
          Ano: testInfo?.year,
          Período: testInfo?.period,
        });
        setFile(null);
      }
    },
    [
      validateFile,
      errorDetail,
      setErrorMessage,
      setFile,
      selectedTheme,
      discipline,
      materialType,
      testInfo,
      trackEvent,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    onDrop,
  });

  return (
    <Container>
      <Title>
        <h1> Envie a sua {materialType.toLowerCase()} </h1>
      </Title>
      <StepsContainer>
        <span className={''}></span>
        <span className={''}></span>
        <span className={''}></span>
        <span className={'fill'}></span>
        <span className={''}></span>
      </StepsContainer>
      <h2>
        Para que sua {materialType.toLowerCase()} chegue até nossos experts e
        seja analisada, ela precisa estar dentro dos seguintes critérios:
      </h2>
      <CriteriaContainer>
        <Criteria>
          <FiCheck></FiCheck>
          <span>Precisa estar dentro da disciplina escolhida</span>
        </Criteria>
        <Criteria>
          <FiCheck></FiCheck>
          <span>Pode ter no máximo 10 questões</span>
        </Criteria>
        <Criteria>
          <FiCheck></FiCheck>
          <span>As questões precisam estar completas e legíveis</span>
        </Criteria>
      </CriteriaContainer>
      <UploadArea {...getRootProps()} error={!!errorMessage}>
        <input {...getInputProps()} />
        {errorMessage && (
          <>
            <ErrorContainer>
              <MdOutlineWarning />
              <span>Erro: {errorMessage}</span>
            </ErrorContainer>
            <ErrorDetail>{!!errorDetail && errorDetail}</ErrorDetail>
          </>
        )}

        {file ? (
          <FileContainer error={!!errorMessage}>
            <div>
              <MdPictureAsPdf />
              <p>{file.name}</p>
            </div>
            <span>Alterar</span>
          </FileContainer>
        ) : isDragActive ? (
          <p>Agora é só soltar</p>
        ) : (
          <>
            <MdOutlineUploadFile></MdOutlineUploadFile>
            <p>
              Arraste e solte o arquivo aqui ou <br></br>clique para escolher o
              documento
            </p>
            <span>Formato aceito: pdf</span>
            <span>Tamanho máximo: 1.0MB</span>
          </>
        )}
      </UploadArea>
      <DoubleButtonContainer>
        <Button backButton onClick={handleBack}>
          VOLTAR
        </Button>
        <Button canSubmit={canSubmit} onClick={handleNext}>
          PRÓXIMO
        </Button>
      </DoubleButtonContainer>
    </Container>
  );
}

const ErrorDetail = styled.div`
  color: #ff6f4e;
  font-size: 13px;
  text-decoration: underline;
  font-weight: bold;
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  svg,
  span {
    color: #ff6f4e;
  }

  svg {
    margin: 0px 5px;
    font-size: 20px;
  }

  span {
    font-size: 15px;
  }
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  text-decoration: underline;
  padding: 10px;
  color: ${({ error }) => error && '#ff6f4e'};
  div {
    display: flex;
    align-items: center;
    p {
      padding: 0;
    }

    svg {
      font-size: 20px;
      margin: 0 8px;
      color: ${({ error }) => error && '#ff6f4e'};
    }
  }

  span {
    font-size: 13px;
  }

  @media ${media.mobile} {
    flex-direction: column;
    span {
      margin: 15px;
    }
  }
`;

const Criteria = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  padding: 5px;

  span {
    padding-left: 5px;
  }
`;

const CriteriaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 25px;

  @media ${media.mobile} {
    padding: 12px;
    margin: 15px auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-weight: 300;
  }

  position: sticky;
  z-index: 3;
`;

export const Button = styled.button`
  width: 48%;
  border: 1px solid
    ${({ backButton, canSubmit }) =>
      canSubmit || backButton ? '#f6ac3a' : '#B8B8B8'};
  border-radius: 10px;

  color: ${({ backButton }) => (backButton ? '#f6ac3a' : '#fff')};
  background-color: ${({ backButton, canSubmit }) =>
    backButton ? '#fff' : canSubmit ? '#f6ac3a' : '#B8B8B8'};
  margin: 45px 0px;
  padding: 15px;

  font-weight: bold;
  font-size: 1.1em;

  cursor: pointer;

  position: sticky;
  z-index: 2;

  @media ${media.mobile} {
    margin: 10px;
    width: 100%;
  }
`;

const Title = styled.div`
  color: #888;
  font-weight: bold;
  text-align: center;

  h1 {
    font-size: 2.2em;

    @media ${media.mobile} {
      font-size: 2em;
    }
  }

  h3 {
    font-size: 1.1em;
    margin-top: 10px;
    line-height: 20px;

    @media ${media.mobile} {
      font-size: 1em;
    }
  }
`;
