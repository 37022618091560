import React from 'react';

import { styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { LoginButtonsContainer } from 'modules/shared/components/login';

export default function LectureLoginDialog() {
  const { unsetDialog } = useDialogContext();

  return (
    <Container onClick={unsetDialog}>
      <Title>Para assistir ao aulão, faça login:</Title>
      <LoginButtonsContainer where="dialog" origin="lecture-dialog" />
      <Message>Relaxa, não vamos publicar nada no seu nome</Message>
      <Close onClick={unsetDialog}>X</Close>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.98);
`;

const Title = styled.h1`
  color: #666;
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 15px;
`;

const Message = styled.div`
  font-size: 0.9em;
  margin-top: 10px;
`;

const Close = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
`;
