import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { styled, colors } from 'styles';
import { useTracker } from 'modules/shared/hooks';

export default function AutoRenewBar() {
  const history = useHistory();
  const { trackEvent } = useTracker();

  useEffect(() => {
    trackEvent('Barra Notificacao Renovacao: Visualizou');
  }, [trackEvent]);

  const onBarClick = useCallback(() => {
    trackEvent('Barra Notificacao Renovacao: Clicou');
    history.push('minha-conta');
  }, [trackEvent, history]);

  return (
    <Container onClick={onBarClick}>
      Sua assinatura está próxima de renovar. Garanta que suas informações de
      pagamento estão atualizadas ;D
    </Container>
  );
}

const Container = styled.div`
  background: ${colors.mainOrange};
  width: 100%;
  height: 1.6rem;
  padding: 0 2px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
