import { useCallback } from 'react';

const useFormValidation = () => {
  const isUserInputedListInvalid = useCallback(inputList => {
    const invalids = inputList.some(row => !row.chapter || !row.exercises);

    return invalids;
  }, []);

  return { isUserInputedListInvalid };
};

export default useFormValidation;
