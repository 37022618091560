import React, { useCallback } from 'react';
import { styled } from 'styles';
import { MdUploadFile } from 'react-icons/md';
import { useDialogContext } from 'providers/DialogProvider';
import UploadFileDialog from 'modules/main/dialogs/upload-file/UploadFileDialog';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { useTracker } from 'modules/shared/hooks';

export default function UploadMaterialButton({ theme }) {
  const { setDialog } = useDialogContext();
  const { data: themes } = useContentQueryImmutable(`/themes/with-subjects`);
  const { trackEvent } = useTracker();
  const selectedTheme = themes?.find(t => t.id === parseInt(theme.id));

  const openDialog = useCallback(() => {
    trackEvent('Listas: Clicou para Enviar Prova/Lista', {
      Origem: 'Página Intermediária',
    });
    setDialog(<UploadFileDialog theme={selectedTheme} />);
  }, [setDialog, trackEvent, selectedTheme]);

  return (
    <Container onClick={openDialog}>
      <Icon size={35} />
      <Text>ENVIAR PROVAS/LISTAS PARA RESOLVER</Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid #36aaad;
  border-radius: 8px;
  padding: 13px;

  margin-bottom: 15px;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.45);
`;

const Icon = styled(MdUploadFile)`
  color: #36aaad;
`;

const Text = styled.div`
  font-size: 0.8em;
  width: 75%;
  align-self: center;
  font-weight: bold;
`;
