import React, { useMemo } from 'react';

import { ContentHeader } from 'modules/workspace/components/content/shared';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import {
  useWorkspace,
  useWorkspaceErrorReport,
  useWorkspaceTracker,
} from 'modules/workspace/hooks/main';

export default function MainContentHeader() {
  const {
    contentTitle,
    contentSubtitle,
    currentChapter,
    currentTopic,
  } = useWorkspaceContentContext();
  const { getErrorReportData } = useWorkspaceErrorReport();
  const {
    getChapterIndex,
    getCurrentContentData,
    isExtraTimePageWorkspace,
    isSummaryWorkspace,
  } = useWorkspace();
  const { trackShareContent, trackErrorReportClick } = useWorkspaceTracker();

  const currentChapterIndex = useMemo(() => {
    if (!currentChapter) return;

    return getChapterIndex(currentChapter);
  }, [currentChapter, getChapterIndex]);

  const label = useMemo(() => {
    return `${currentChapterIndex + 1}. ${currentChapter.name}`;
  }, [currentChapterIndex, currentChapter]);

  const title = useMemo(() => {
    if (isExtraTimePageWorkspace()) return 'EXERCICIOS EXTRAS';
    if (isSummaryWorkspace()) return 'RESUMINHO';

    return currentTopic?.name;
  }, [isExtraTimePageWorkspace, isSummaryWorkspace, currentTopic]);

  const whatsappMessage = useMemo(() => {
    const currentUrl = window.location;

    const encodedMessage = encodeURIComponent(
      `Opa, encontrei o que a gente precisava sobre ${contentSubtitle ||
        contentTitle}. Se liga: ${currentUrl}?utm_source=share`
    );
    return encodedMessage;
  }, [contentSubtitle, contentTitle]);

  const iconsData = useMemo(() => {
    return {
      whatsapp: {
        message: whatsappMessage,
        trackMethod: trackShareContent,
      },
      errorReport: {
        data: getErrorReportData(),
        trackMethod: trackErrorReportClick,
      },
    };
  }, [
    whatsappMessage,
    trackShareContent,
    getErrorReportData,
    trackErrorReportClick,
  ]);

  return (
    <ContentHeader
      color={getCurrentContentData('color')}
      title={title}
      label={label}
      iconsData={iconsData}
    />
  );
}
