import React, { useCallback, useEffect, useState } from 'react';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import styled from 'styled-components';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useTracker } from 'modules/shared/hooks';
import { Button } from './styles';

function StudyInfoForm({ setError, setIsOnUniversity }) {
  const [academicEducation, setAcademicEducation] = useState(null);
  const [saving, setSaving] = useState(false);
  const options = [
    'Estou no Ensino Fundamental',
    'Estou no Ensino Médio',
    'Estou no Ensino Superior',
    'Terminei o Ensino Superior',
    'Não tenho matrícula em uma intituição de ensino',
    'Outro',
  ];
  const { trackEvent } = useTracker();

  const sendAcademicEducation = useRAPostRequest(
    'profile/update_academic_education'
  );

  useEffect(() => {
    trackEvent('Visualizou Dialog de Confirmação de Formação Acadêmica');
  }, [trackEvent]);

  const handleSelect = useCallback(
    option => {
      setAcademicEducation(option);
    },
    [setAcademicEducation]
  );

  const canSendForm = useCallback(() => {
    if (!academicEducation) {
      setError('Você precisa selecionar sua formação acadêmica');
    }

    setTimeout(() => {
      setError('');
    }, 3000);

    return academicEducation;
  }, [academicEducation, setError]);

  const handleSendAcademicEducation = useCallback(async () => {
    if (!canSendForm()) return;

    const eventData = {
      'Formação Acadêmica': academicEducation,
    };

    if (academicEducation === 'Estou no Ensino Superior') {
      setIsOnUniversity(true);
      trackEvent('Informou Formação Acadêmica', eventData);
      return;
    }

    setSaving(true);
    const params = {
      academic_education: academicEducation,
      from: 'user_university_confirmation',
    };

    try {
      await sendAcademicEducation(params);

      trackEvent('Confirmou Formação Acadêmica', eventData);

      location.reload();
    } catch (e) {
      console.log(e);
    } finally {
      setSaving(false);
    }
  }, [
    canSendForm,
    setSaving,
    academicEducation,
    sendAcademicEducation,
    trackEvent,
    setIsOnUniversity,
  ]);

  return (
    <Container>
      <Title>Selecione sua formação acadêmica atual:</Title>
      <CheckboxesContainer>
        {options.map(option => {
          return (
            <CheckboxContainer
              key={option}
              onClick={() => handleSelect(option)}
            >
              {academicEducation === option ? (
                <CheckedIcon />
              ) : (
                <RadioButtonUncheckedIcon />
              )}
              <span>{option}</span>
            </CheckboxContainer>
          );
        })}
      </CheckboxesContainer>
      <Button onClick={handleSendAcademicEducation} saving={saving}>
        {saving ? 'ENVIANDO...' : 'ENVIAR'}
      </Button>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  margin: 20px 0px;
  font-weight: bold;
  color: #303030;
  text-align: start;
  font-size: 18px;
`;

const CheckboxesContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 6px;

  span {
    margin-left: 5px;
    text-align: start;
  }
`;

const CheckedIcon = styled(RadioButtonCheckedIcon)`
  color: #f6ac3a;
`;

export default StudyInfoForm;
