import React, { useEffect, useCallback, useMemo } from 'react';

import { styled, media, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import { useCookies, useTracker, useUTM } from 'modules/shared/hooks';
import BookCreditCardTrialInputInfoDialog from './BookCreditCardTrialInputInfoDialog';
import {
  PhoneNumberRequestDialog,
  usePhoneNumberDialog,
} from 'modules/campaigns/phone-number-request';

export default function BookCreditCardTrialCTADialog({ source }) {
  const { setDialog, unsetDialog } = useDialogContext();
  const {
    bookCreditCardTrialFreeAccessEnd,
    getBookCreditCardTrialAvailablePricings,
    getMonthlyPriceValue,
    bookCreditCardTrialFirstCharge,
    trialDays,
    testEventParams,
    getInitialPlanPricing,
    bookCreditCardTrialFreeAccessBegin,
  } = useBookTrialCampaign();
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();
  const [cookies, setCookie] = useCookies([]);
  const { shouldShowPhoneNumberDialog } = usePhoneNumberDialog();

  const pricing = useMemo(() => {
    const planPricings = getBookCreditCardTrialAvailablePricings();
    return getInitialPlanPricing(planPricings);
  }, [getBookCreditCardTrialAvailablePricings, getInitialPlanPricing]);

  useEffect(() => {
    trackEvent('Trial com Cartão: CTA Visualizou', {
      ...getUTMsByLocation(window.location),
      ...testEventParams(),
      Origem: source,
    });
  }, [getUTMsByLocation, trackEvent, source, testEventParams]);

  const handleNextPress = useCallback(() => {
    setDialog(
      <BookCreditCardTrialInputInfoDialog
        planPricing={pricing}
        source={source}
      />
    );
  }, [setDialog, pricing, source]);

  const handleRefusePress = useCallback(() => {
    trackEvent('Trial com Cartão: CTA Recusou', {
      ...getUTMsByLocation(window.location),
      ...testEventParams(),
    });

    setCookie('open_credit_card_trial', 'true', {
      expires: new Date(new Date().getTime() + 2 * 60 * 1000), // two minutes from now
    });

    if (shouldShowPhoneNumberDialog) {
      return setDialog(<PhoneNumberRequestDialog source="Trial Com Cartão" />);
    }

    unsetDialog();
  }, [
    trackEvent,
    getUTMsByLocation,
    testEventParams,
    setCookie,
    shouldShowPhoneNumberDialog,
    unsetDialog,
    setDialog,
  ]);

  const handleGoToPlansPress = useCallback(() => {
    window.open(
      `${process.env.REACT_APP_RESPONDEAI_URL}/marketing/cupom/trial_com_cartao_test_ab`,
      '_blank'
    );
  }, []);

  const refuseButton = useCallback(() => {
    if (cookies['open_credit_card_trial'])
      return (
        <Button className="light" onClick={handleGoToPlansPress}>
          Ver outros planos
        </Button>
      );

    return (
      <Button className="light" onClick={handleRefusePress}>
        Recusar
      </Button>
    );
  }, [handleRefusePress, handleGoToPlansPress, cookies]);

  return (
    <Container data-cy="credit-card-dialog" onClick={handleRefusePress}>
      <Content onClick={e => e.stopPropagation()}>
        <Close onClick={handleRefusePress}>X</Close>
        <Title>Teste gratuito de {trialDays} dias</Title>

        <Row2 className="with-grid">
          <FreeAccessDate>
            De {bookCreditCardTrialFreeAccessBegin()} <br />à{' '}
            {bookCreditCardTrialFreeAccessEnd()}
          </FreeAccessDate>
          <FreeMessage>Acesso 100% gratuito ao Responde Aí</FreeMessage>
        </Row2>
        <Line />
        <Row2 className="with-grid" style={{ marginBottom: '30px' }}>
          <PlanBeginDate>
            Depois de {bookCreditCardTrialFirstCharge()}
          </PlanBeginDate>
          <SelectedPlan>
            Plano {pricing.plan.description} até
            <br />
            {`${pricing.plan.months}x de R$ ${getMonthlyPriceValue(pricing)}`}
          </SelectedPlan>
        </Row2>

        <PurchaseInfoBold>
          Você não paga nada se cancelar até{' '}
          {bookCreditCardTrialFreeAccessEnd()}
        </PurchaseInfoBold>

        <ButtonsContainer>
          <Button className="highlight" onClick={handleNextPress}>
            Ganhar {trialDays} dias de Responde Aí
          </Button>

          {refuseButton()}
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  padding: 30px 15px 20px;
  background-color: #fff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
`;

const Close = styled.div`
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
`;

const Title = styled.h1`
  font-size: 2em;
  font-weight: bold;
  color: #000;
  margin-bottom: 25px;

  @media ${media.mobile} {
    font-size: 1.7em;
  }
`;

// const Row = styled.div`
//   width: 450px;
//   max-width: 95%;
//   display: flex;
//   justify-content: center;
// `;

const Row2 = styled.div`
  width: 450px;
  max-width: 95%;

  &.with-grid {
    display: grid;
    grid-column-gap: 25px;
    grid-template-columns: 1fr 1fr;
  }
`;

// const ChoosePlan = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr;
//   width: 400px;
//   max-width: 100%;

//   b {
//     margin-bottom: 10px;
//   }
// `;

// const PlanOption = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   padding: 30px 0;
//   cursor: pointer;

//   &.selected {
//     background-color: rgba(249, 172, 62, 0.1);
//   }
// `;

// const PlanDot = styled.div`
//   width: 15px;
//   height: 15px;
//   border-radius: 50px;
//   border: 2px solid #666;
//   background: #fff;

//   &.selected {
//     background: ${colors.mainOrange};
//     border-color: ${colors.mainOrange};
//   }
// `;

// const PlanName = styled.span`
//   margin: 5px 0;
//   font-size: 1.1em;
//   font-weight: bold;
// `;

// const PlanDescription = styled.span`
//   line-height: 1.3;
//   font-size: 0.9em;

//   @media ${media.mobile} {
//     font-size: 0.75em;
//   }
// `;

const PurchaseInfoBold = styled.div`
  width: 480px;
  max-width: 95%;
  margin-top: 20px;
  font-size: 0.9em;
  font-weight: 900;
`;

const ButtonsContainer = styled.div`
  width: 400px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  border: 2px solid #000;
  font-weight: bold;
  font-size: 1.5em;
  margin: 5px 0;
  padding: 10px 0;
  border-radius: 5px;

  &.highlight {
    background-color: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
    color: #fff;
  }

  &.light {
    color: #a2a2a2;
    border-color: #a2a2a2;
  }

  @media ${media.mobile} {
    font-size: 1.4em;
  }
`;

const FreeAccessDate = styled.span`
  text-align: right;
`;

const FreeMessage = styled.span`
  font-weight: bold;
  text-align: left;
`;

const PlanBeginDate = styled.span`
  text-align: right;
  margin-bottom: 20px;
`;

const Line = styled.div`
  width: 410px;
  max-width: 90%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 10px 0;
`;

const SelectedPlan = styled.span`
  font-weight: bold;
  text-align: left;
`;
