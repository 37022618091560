import React, { useCallback, useEffect, useState } from 'react';
import { media, styled } from 'styles';
import { RiArrowRightSLine, RiArrowLeftSLine } from 'react-icons/ri';
import { useBookEditionContext } from '../providers/BookEditionProvider';
import { useBookExerciseTracker } from '../hooks';

export default function BookNavigationButtons() {
  const {
    edition,
    currentExerciseId,
    onChangeExerciseSelected,
  } = useBookEditionContext();
  const [exercisesIdsOrdered, setExercisesIdsOrdered] = useState(null);
  const { trackNavigationClick } = useBookExerciseTracker();

  const getAllExercisesIdsOrdered = useCallback(() => {
    const allExercisesOrdered = [];
    edition.chapters.forEach(c => {
      c.sections.forEach(s => {
        s.questions.forEach(q => {
          allExercisesOrdered.push(q.exercise.id);
        });
      });
    });
    return allExercisesOrdered;
  }, [edition]);

  const goToPreviousExercise = useCallback(() => {
    const position = exercisesIdsOrdered.indexOf(currentExerciseId);
    if (position > 0) {
      const exerciseId = exercisesIdsOrdered[position - 1];
      onChangeExerciseSelected(exerciseId);
    }
    trackNavigationClick('Anterior');
  }, [
    exercisesIdsOrdered,
    onChangeExerciseSelected,
    currentExerciseId,
    trackNavigationClick,
  ]);

  const goToNextExercise = useCallback(() => {
    const position = exercisesIdsOrdered.indexOf(currentExerciseId);
    if (position < exercisesIdsOrdered.length - 1) {
      const exerciseId = exercisesIdsOrdered[position + 1];
      onChangeExerciseSelected(exerciseId);
    }
    trackNavigationClick('Próximo');
  }, [
    exercisesIdsOrdered,
    onChangeExerciseSelected,
    currentExerciseId,
    trackNavigationClick,
  ]);

  useEffect(() => {
    setExercisesIdsOrdered(getAllExercisesIdsOrdered());
  }, [getAllExercisesIdsOrdered]);

  return (
    <Container>
      <Button onClick={() => goToPreviousExercise()}>
        <RiArrowLeftSLine className="icon" />
        Exercício Anterior
      </Button>
      <Button onClick={() => goToNextExercise()}>
        Próximo Exercício
        <RiArrowRightSLine className="icon" />
      </Button>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media ${media.mobile} {
    margin-bottom: 15px;
    height: 47px;
  }
`;

const Button = styled.button`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border-top: 2px solid #dddddd;
  border-bottom: 2px solid #dddddd;

  font-size: 18px;
  line-height: 22px;
  color: #a2a2a2;

  &:last-child {
    border-left: 3px solid #dddddd;
  }

  .icon {
    font-size: 1.6rem;
    color: #a2a2a2;
  }
`;
