import { useUserContext } from 'providers/UserProvider';
import { useCallback } from 'react';

import { useProfessorContext } from '../providers/ProfessorProvider';

export function useProfessor() {
  const { user } = useUserContext();
  const useProfessorData = useProfessorContext();

  const showUserProfessors = useCallback(() => {
    if (!user) return false;

    const eligibleCampus = [350, 352, 284, 320];
    const eligibleUniversity = [
      49,
      338,
      206,
      372,
      570,
      782,
      49,
      637,
      232,
      234,
      238,
      665,
      195,
      325,
      237,
      148,
      666,
      106,
      130,
      548,
      324,
      371,
      231,
      235,
      547,
      15,
      147,
      236,
      667,
      1722,
      301,
      505,
      225,
    ];

    if (eligibleUniversity.includes(parseInt(user.university?.id))) return true;
    if (eligibleCampus.includes(parseInt(user.universityCampus?.id)))
      return true;
  }, [user]);

  const getUserProfessorFromDiscipline = useCallback(
    discipline => {
      if (!useProfessorData) return null;

      const { userProfessors } = useProfessorData;
      if (!userProfessors) return null;
      if (!discipline) return null;

      return userProfessors.find(
        userProfessor =>
          userProfessor.professor?.discipline?.id === discipline.id
      );
    },
    [useProfessorData]
  );

  const getDisciplinesFromSubject = useCallback(
    subjectId => {
      const disciplines = useProfessorData?.disciplines || [];

      return disciplines.filter(discipline => {
        const guide =
          discipline.modelName === 'MvpDiscipline'
            ? discipline.guideRelation?.guide
            : discipline.guide;

        const subjectIds = guide?.guidesSubjects?.map(gs => gs.subjectId) || [];
        return subjectIds.includes(subjectId);
      });
    },
    [useProfessorData]
  );

  const isSubjectEligibleToUserProfessor = useCallback(
    subjectId => {
      if (!showUserProfessors()) return false;

      const disciplinesWithSubject = getDisciplinesFromSubject(subjectId);
      return disciplinesWithSubject.length > 0;
    },
    [getDisciplinesFromSubject, showUserProfessors]
  );

  const getUserProfessorFromSubject = useCallback(
    subjectId => {
      const disciplinesWithSubject = getDisciplinesFromSubject(subjectId);
      const userProfessors = disciplinesWithSubject.map(
        getUserProfessorFromDiscipline
      );
      return userProfessors.find(p => p);
    },
    [getDisciplinesFromSubject, getUserProfessorFromDiscipline]
  );

  return {
    showUserProfessors,
    getUserProfessorFromDiscipline,
    isSubjectEligibleToUserProfessor,
    getUserProfessorFromSubject,
    getDisciplinesFromSubject,
  };
}
