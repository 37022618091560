import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt,
  faTrashAlt,
  faBook,
  faUser,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';

import { styled, media } from 'styles';
import BackButton from '../BackButton';
import landscape from '../../images/landscape.png';
import { Header, Img } from './styles';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';
import { useStudyPlan } from 'modules/guides/hooks';
import BuildStudyPlanDialog from 'modules/main/dialogs/BuildStudyPlanDialog';
import { DateMask } from 'modules/shared/components/inputs';
import { useHistory } from 'react-router-dom';

export default function StudyPlanHeader({ color, studyPlan }) {
  const { user } = useUserContext();
  const { setDialog } = useDialogContext();
  const [onStudyPlanNameFocus, setOnStudyPlanNameFocus] = useState(false);
  const [hasChangeStudyPlan, setHasChangeStudyPlan] = useState(false);
  const [studyPlanState, setStudyPlanState] = useState(null);
  const timer = useRef(null);
  const { destroyStudyPlan, updateStudyPlanBasicInfos } = useStudyPlan();
  const history = useHistory();

  useEffect(() => {
    setStudyPlanState({ ...studyPlan });
  }, [studyPlan]);

  useEffect(() => {
    if (!hasChangeStudyPlan) return;

    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      updateStudyPlanBasicInfos(studyPlanState);
    }, 1000);
  }, [studyPlanState, hasChangeStudyPlan, updateStudyPlanBasicInfos]);

  const onUpdateStudyPlan = useCallback(
    (key, value) => {
      if (!hasChangeStudyPlan) setHasChangeStudyPlan(true);
      setStudyPlanState({ ...studyPlanState, [key]: value });
    },
    [studyPlanState, hasChangeStudyPlan, setHasChangeStudyPlan]
  );

  const studyPlanLabel = useMemo(() => {
    if (onStudyPlanNameFocus) return ' ';

    return studyPlanState?.name ? ' ' : 'COMO VOU ME CHAMAR?';
  }, [studyPlanState, onStudyPlanNameFocus]);

  const studyPlanMessage = useMemo(() => {
    if (studyPlan.name) return 'PLANO DE ESTUDO';
    if (!user) return 'PLANO DE ESTUDO';

    const userName = user.name && user.name.split(' ')[0];
    return `${userName}, sou seu Plano de Estudo`;
  }, [user, studyPlan]);

  const onDestroyStudyPlan = useCallback(async () => {
    const deleteConfirmation = window.confirm(
      'Tem certeza que deseja deletar este Plano de Estudo? Essa ação é irreversível.'
    );
    if (deleteConfirmation) {
      const success = await destroyStudyPlan(studyPlan);
      if (success) {
        alert('Plano de estudos excluído com sucesso!');
        history.push('/materias');
      } else {
        alert(
          'Ocorreu algum problema ao apagar seu Plano de Estudo.Tente mais tarde novamente'
        );
      }
    }
  }, [studyPlan, destroyStudyPlan, history]);

  return (
    <Header backgroundColor={color} paddingBottom="120px">
      <BackButton to="/materias" />
      <StudyPlanInfo>
        {user && <ProfileImg src={user.pictureUrl} />}
        <Message>{studyPlanMessage}</Message>
        <StudyPlanNameInput
          variant="standard"
          value={studyPlanState?.name || ''}
          label={studyPlanLabel}
          onChange={e => onUpdateStudyPlan('name', e.target.value)}
          onFocus={() => setOnStudyPlanNameFocus(true)}
          onBlur={() => setOnStudyPlanNameFocus(false)}
        />
        <StudyPlanControlls>
          <Button
            onClick={() => {
              setDialog(
                <BuildStudyPlanDialog
                  studyPlanToEdit={studyPlan}
                  theme={studyPlan.theme}
                />
              );
            }}
          >
            <ButtonIcon icon={faPencilAlt} />
            Editar
          </Button>
          <Button onClick={onDestroyStudyPlan}>
            <ButtonIcon icon={faTrashAlt} />
            Excluir
          </Button>
        </StudyPlanControlls>
        {media.isDesktop() && (
          <CustomInfos>
            <InfoInput
              value={studyPlanState?.discipline || ''}
              variant="standard"
              placeholder={
                studyPlanState?.discipline ? ' ' : 'Nome da Disciplina'
              }
              onChange={e => onUpdateStudyPlan('discipline', e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InfoIcon icon={faBook} />
                  </InputAdornment>
                ),
              }}
            />
            <InfoInput
              value={studyPlanState?.professor || ''}
              variant="standard"
              placeholder={
                studyPlanState?.professor ? ' ' : 'Nome do Professor'
              }
              onChange={e => onUpdateStudyPlan('professor', e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InfoIcon icon={faUser} />
                  </InputAdornment>
                ),
              }}
            />
            <InfoInput
              value={studyPlanState?.examDate || ''}
              variant="standard"
              placeholder={studyPlanState?.examDate ? ' ' : 'Data da prova'}
              onChange={e => onUpdateStudyPlan('examDate', e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InfoIcon icon={faCalendar} />
                  </InputAdornment>
                ),
                inputComponent: DateMask,
              }}
            />
          </CustomInfos>
        )}
      </StudyPlanInfo>

      <Img src={landscape} />
    </Header>
  );
}

const StudyPlanInfo = styled.div`
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

const ProfileImg = styled.img`
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #fff;
`;

const Message = styled.h2`
  margin: 5px 0;
  font-size: 1.1em;
  padding-top: 6px;
`;

const StudyPlanNameInput = styled(TextField)`
  position: relative;
  top: -20px;
  width: 300px;
  max-width: 95%;
  height: 40px;
  color: #fff;

  .MuiInputLabel-formControl {
    color: #fff !important;
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.4em;
    transition: none;
  }

  .MuiInputBase-root {
    color: #fff;

    &::before {
      border-color: #fff !important;
      border-width: 1px !important;
    }

    &::after {
      border-color: #fff !important;
      border-width: 1px !important;
    }
  }

  input {
    text-align: center;
    font-size: 1.4em;
    font-weight: bold;
  }
`;

const StudyPlanControlls = styled.div`
  display: flex;
  margin-top: 12px;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 8px;
  font-size: 0.9em;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);

  &:hover {
    color: rgb(255, 255, 255);
  }
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  font-size: 0.7em;
  margin-right: 4px;
`;

const CustomInfos = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const InfoInput = styled(TextField)`
  .MuiInputLabel-formControl {
    color: #fff !important;
  }

  .MuiInputBase-root {
    color: #fff;

    &::before {
      border: none !important;
    }

    &::after {
      border: none !important;
    }
  }

  input {
    text-align: right;
    font-weight: bold;
    font-size: 0.9em;
    font-style: italic;
    padding: 0;
    margin: 3px 0;
    cursor: pointer;

    ::placeholder {
      opacity: 1;
    }
  }
`;

const InfoIcon = styled(FontAwesomeIcon)`
  font-size: 0.75em;
`;
