import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ForumInput from './ForumInput';
import PastableContainer from './PastableContainer';
import { TextField } from '@material-ui/core';
import { useEscClick, useToast } from 'modules/shared/hooks';
import { styled, colors, media } from 'styles';
import { useUserContext } from 'providers/UserProvider';
import { detectBadwords } from 'modules/shared/utils';
import useForumRedirectToErrorReport from 'modules/forum/hooks/useForumRedirectToErrorReport/useForumRedirectToErrorReport';
import ErrorReportRedirect from './ErrorReportRedirect';
import useForumTracker from 'modules/forum/hooks/useForumTracker';

export default function PostManager({
  isEditing,
  hasTitle,
  postContent,
  onBack,
  onSubmit,
  hasBackButton = true,
  buttonTextData,
}) {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [file, setFile] = useState(null);
  const [sending, setSending] = useState(false);
  const [dropError, setDropError] = useState('');
  const [
    shouldConfirmRedirectToErrorReport,
    setShouldConfirmRedirectToErroReport,
  ] = useState(false);
  const { trackErrorReportRedirect } = useForumTracker();

  const { user } = useUserContext();
  const { errorToast } = useToast();
  const { shouldConfirmRedirect } = useForumRedirectToErrorReport();

  const ref = useRef();
  useEscClick(ref, () => onBack());

  useEffect(() => {
    setText(postContent?.body);
    if (hasTitle) setTitle(postContent?.title);
    if (postContent?.fileUrl) setFile(postContent?.fileUrl);
  }, [postContent, hasTitle, setTitle]);

  const buttonText = useMemo(() => {
    return sending ? buttonTextData.onSending : buttonTextData.default;
  }, [buttonTextData, sending]);

  const badwordsDetected = useMemo(() => {
    return (hasTitle && detectBadwords(title)) || detectBadwords(text);
  }, [text, title, hasTitle]);

  const canSubmit = useCallback(() => {
    let error = '';

    if (title.length < 6 && hasTitle)
      error = 'Você precisa criar um título maior para a pergunta.';
    if (badwordsDetected)
      error =
        'Revise sua mensagem e garanta que não exista nenhuma palavra ofensiva.';
    if (error) errorToast(error);

    return error === '';
  }, [title, hasTitle, errorToast, badwordsDetected]);

  useEffect(() => {
    setTimeout(() => {
      setDropError('');
    }, 2000);
  }, [dropError]);

  const sendRequest = useCallback(async () => {
    setSending(true);

    let params = { text };
    if (hasTitle) params = { ...params, title };
    await onSubmit(params, file);
    setText('');
    setTitle('');

    setSending(false);
    setShouldConfirmRedirectToErroReport(false);
  }, [
    setSending,
    text,
    hasTitle,
    title,
    onSubmit,
    file,
    setText,
    setTitle,
    setShouldConfirmRedirectToErroReport,
  ]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (sending) return;
      if (!canSubmit()) return;
      if (shouldConfirmRedirect(text, title)) {
        trackErrorReportRedirect(shouldConfirmRedirect(text, title));
        return setShouldConfirmRedirectToErroReport(true);
      }

      await sendRequest();
    },
    [
      sending,
      canSubmit,
      sendRequest,
      text,
      title,
      shouldConfirmRedirect,
      trackErrorReportRedirect,
    ]
  );

  const onReportOrClose = useCallback(() => {
    setText('');
    setTitle('');
    setShouldConfirmRedirectToErroReport(false);
  }, [setText, setTitle, setShouldConfirmRedirectToErroReport]);

  return (
    <>
      <ErrorReportRedirect
        onReportOrClose={onReportOrClose}
        sendPost={sendRequest}
        title={title}
        text={text}
        shouldConfirmRedirectToErrorReport={shouldConfirmRedirectToErrorReport}
      />
      <>
        {hasBackButton && <Back onClick={onBack}>Voltar</Back>}

        <PastableContainer setFile={setFile} setDropError={setDropError}>
          <Container ref={ref}>
            <ContainerImg>
              <img src={user.pictureUrl}></img>
            </ContainerImg>
            <ContainerForm onSubmit={handleSubmit}>
              {hasTitle && (
                <TextField
                  placeholder={'Título'}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  variant="outlined"
                  size="small"
                  style={{
                    marginBottom: 10,
                    color: colors.darkGray,
                  }}
                  InputProps={{
                    style: {
                      color: colors.darkGray,
                      fontFamily: 'Lato, sans-serif',
                      borderColor: '#e0e0e0',
                      borderRadius: '5px',
                      letterSpacing: 'normal',
                    },
                  }}
                />
              )}
              <>
                <ForumInput
                  id="new-answer-input"
                  setText={setText}
                  text={text}
                  placeholder={
                    hasTitle ? 'Digite sua pergunta' : 'Digite sua resposta'
                  }
                  file={file}
                  setFile={setFile}
                  error={dropError}
                  onShiftEnter={onSubmit}
                />
              </>

              {
                <ButtonContainer>
                  {isEditing && (
                    <DiscardButton onClick={onBack} disabled={sending}>
                      Descartar alterações
                    </DiscardButton>
                  )}
                  <Button type="submit" disabled={sending}>
                    {buttonText}
                  </Button>
                </ButtonContainer>
              }
            </ContainerForm>
          </Container>
        </PastableContainer>
      </>
    </>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
`;

const ContainerImg = styled.div`
  margin-right: 10px;
  img {
    width: auto;
    height: 35px;
    border-radius: 50%;
  }
`;
const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const Button = styled.button`
  background-color: ${colors.mainOrange};
  color: #505050;
  align-self: flex-end;
  bottom: 0px;
  right: 0px;
  border-radius: 28px;
  width: 319px;
  height: 55px;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: bold;

  /* disabled style */
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  @media ${media.mobile} {
    max-width: 90%;
    align-self: center;
    margin-top: 15px;
  }
`;

const Back = styled.div`
  margin-bottom: 20px;
  font-size: 1.1em;
  text-decoration: underline;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  margin-top: 10px;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: bold;

  @media ${media.mobile} {
    flex-direction: column;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const DiscardButton = styled.div`
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 25px;

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;
