import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

function useCovid19Functions() {
  const history = useHistory();

  const onClickBanner = useCallback(() => {
    history.push('/minha-conta');
  }, [history]);

  return {
    onClickBanner,
  };
}

export { useCovid19Functions };
