import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { styled, colors } from 'styles';
import { useDate } from 'modules/shared/hooks';
import { useChangePlanOnCancelCampaign } from 'modules/campaigns/change-plan-on-cancel/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker } from 'modules/shared/hooks';
import {
  Container,
  Content,
  ButtonsContainer,
  Button,
} from 'modules/campaigns/change-plan-on-cancel/components';
import CancelConfirmation from 'modules/profile/dialogs/cancellation/CancelConfirmation';
import ChangePlanOnCancelConfirmationDialog from './ChangePlanOnCancelConfirmationDialog';
import MoneyIcon from 'images/cancellation/money.png';

export default function ChangePlanOnCancelCTADialog({
  subscription,
  selectedReason,
  selectedDetailedReason,
  otherReason,
  isOnCancellationFlow = true,
}) {
  const { getHumanReadableDateFromTimestamp } = useDate();
  const { unsetDialog, setDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const {
    getAvailablePlanPricings,
    getMonthlyPlanPricingPrice,
    getAmplitudeEventProperties,
  } = useChangePlanOnCancelCampaign();
  const [selectedPlanPricing, setSelectedPlanPricing] = useState(null);

  useEffect(() => {
    trackEvent(
      'Troca de Plano no Cancel: CTA Visualizou',
      getAmplitudeEventProperties(subscription)
    );
  }, [trackEvent, getAmplitudeEventProperties, subscription]);

  const titleText = useMemo(() => {
    if (subscription.plan.months === 1)
      return 'Troque seu plano e economize ;)';

    return 'Troque seu plano e mantenha o seu acesso ;)';
  }, [subscription]);

  const ctaMessageText = useMemo(() => {
    if (subscription.plan.months === 1)
      return 'Troque seu plano atual por um plano mais barato de maior duração.';

    if (subscription.plan.months === 6)
      return 'Troque seu plano atual pelo plano que for melhor para você.';

    return 'Troque seu plano para um de menor duração, poupando o limite do seu cartão e te dando uma maior liberdade para cancelar quando precisar';
  }, [subscription]);

  const planDescriptionText = useMemo(() => {
    const price = subscription.order.planPricing.price;
    const installments = subscription.order.installments || 1;
    const monthlyPrice = (price / installments).toFixed(2);

    return `${subscription.plan.description} ${installments}x R$${monthlyPrice}`;
  }, [subscription]);

  const renderOptionPlanInfo = useCallback(
    plan => {
      if (plan.months === 1)
        return <OptionPlanInfo>Renova a cada mês</OptionPlanInfo>;

      return (
        <>
          <OptionPlanInfo>Renova a cada {plan.months} meses</OptionPlanInfo>
          {isOnCancellationFlow && (
            <OptionPlanInfo>
              Pagamento das parcelas não pode ser interrompido
            </OptionPlanInfo>
          )}
        </>
      );
    },
    [isOnCancellationFlow]
  );

  const onOptionClick = useCallback(planPricing => {
    setSelectedPlanPricing(planPricing);
  }, []);

  const onChangePlanClick = useCallback(() => {
    if (!selectedPlanPricing) {
      alert('Por favor, escolha um plano.');
      return;
    }

    setDialog(
      <ChangePlanOnCancelConfirmationDialog
        subscription={subscription}
        selectedReason={selectedReason}
        selectedDetailedReason={selectedDetailedReason}
        otherReason={otherReason}
        newPlanPricing={selectedPlanPricing}
      />
    );
  }, [
    setDialog,
    selectedPlanPricing,
    subscription,
    selectedReason,
    selectedDetailedReason,
    otherReason,
  ]);

  const onContinueCancelClick = useCallback(() => {
    trackEvent(
      'Troca de Plano no Cancel: CTA Clicou Cancelar',
      getAmplitudeEventProperties(subscription)
    );
    setDialog(
      <CancelConfirmation
        subscription={subscription}
        selectedReason={selectedReason}
        selectedDetailedReason={selectedDetailedReason}
        otherReason={otherReason}
      />
    );
  }, [
    setDialog,
    trackEvent,
    getAmplitudeEventProperties,
    subscription,
    selectedReason,
    selectedDetailedReason,
    otherReason,
  ]);

  return (
    <Container>
      <Content>
        <Icon src={MoneyIcon} />
        <Title>{titleText}</Title>
        <CTAMessage>{ctaMessageText}</CTAMessage>
        <CurrentPlanDescription>
          Seu plano: {planDescriptionText}
        </CurrentPlanDescription>
        <CurrentPlanInfo>
          Termina em:{' '}
          {getHumanReadableDateFromTimestamp(subscription.expiresIn)}
        </CurrentPlanInfo>
        {subscription.order.installments > 1 && isOnCancellationFlow && (
          <CurrentPlanInfo>
            Pagamento de parcelas não pode ser interrompido
          </CurrentPlanInfo>
        )}

        <ChoosePlanCTA>Escolha seu novo plano</ChoosePlanCTA>

        {getAvailablePlanPricings().map(planPricing => (
          <OptionContainer
            key={planPricing.id}
            onClick={() => onOptionClick(planPricing)}
          >
            <OptionDot selected={selectedPlanPricing?.id == planPricing.id} />
            <OptionDescription>
              <OptionPlanDescription>
                {planPricing.plan.description}: {planPricing.plan.months}x R$
                {getMonthlyPlanPricingPrice(planPricing)}
              </OptionPlanDescription>
              {renderOptionPlanInfo(planPricing.plan)}
            </OptionDescription>
          </OptionContainer>
        ))}

        <ButtonsContainer>
          <Button onClick={() => unsetDialog()}>Voltar</Button>
          <Button onClick={onChangePlanClick}>Trocar de plano</Button>
        </ButtonsContainer>
        {isOnCancellationFlow && (
          <ContinueOnCancelButton onClick={onContinueCancelClick}>
            Desativar a renovação definitivamente
          </ContinueOnCancelButton>
        )}
      </Content>
    </Container>
  );
}

const Icon = styled.img`
  display: block;
  margin: 20px auto 5px auto;
  width: 65px;
`;

const Title = styled.h1`
  font-weight: bold;
  color: ${colors.warningRed};
  font-size: 1.4em;
  text-align: center;
`;

const CTAMessage = styled.div`
  margin: 15px 0;
  font-size: 0.9em;
`;

const CurrentPlanDescription = styled.div`
  font-weight: bold;
  font-size: 1.1em;
`;

const CurrentPlanInfo = styled.div`
  margin-top: 5px;
  font-weight: bold;
  font-size: 0.8em;
`;

const ChoosePlanCTA = styled.div`
  width: fit-content;
  margin: 20px 0 15px;
  font-size: 1.2em;
  font-weight: bold;
  background-color: ${colors.mainOrange};
  color: #fff;
  padding: 5px;
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin-bottom: 15px;
`;

const OptionDot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid ${colors.mediumGray};
  margin-right: 10px;

  ${({ selected }) => {
    if (selected)
      return `background: ${colors.mainOrange}; border-color: ${colors.mainOrange};`;
  }}
`;

const OptionDescription = styled.div`
  position: relative;
  top: -4px;
`;

const OptionPlanDescription = styled.div`
  font-weight: bold;
`;

const OptionPlanInfo = styled.div`
  font-size: 0.8em;
  margin: 1px 0;
`;

const ContinueOnCancelButton = styled.div`
  width: 100%;
  text-align: right;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 0.75em;
  margin-top: 25px;
  cursor: pointer;
`;
