import { useMathJaxContext } from 'providers/MathJaxProvider';

export const useMathJax = () => {
  const MathJax = useMathJaxContext();

  function updateMathContent(onFinish = () => {}) {
    if (!MathJax || typeof MathJax.typesetPromise !== 'function') return;

    MathJax &&
      MathJax.typesetPromise()
        .then(onFinish)
        .catch(e => {
          if (!isTypeError(e)) console.log(e);
        });
  }

  function isTypeError(e) {
    return e instanceof TypeError;
  }

  return [MathJax, updateMathContent];
};
