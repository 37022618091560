import React from 'react';
import { styled } from 'styles';

export default function MaintenanceBar() {
  return (
    <Container>
      <span>
        <strong>Aviso:</strong> sexta, dia 01/07/22, o site poderá sofrer
        instabilidades entre as 23:00 e as 4:00, por conta de uma manutenção.
      </span>
    </Container>
  );
}

const Container = styled.div`
  background: #ead308;
  width: 100%;
  height: 1.5rem;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
