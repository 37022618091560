import React, { useCallback, useEffect, useRef, useState } from 'react';
import { UploadArea } from '../components/UploadStatementList/UploadArea';
import { colors, styled, media } from 'styles';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useToast, useTracker, useWebView } from 'modules/shared/hooks';
import { useHistory } from 'react-router-dom';
import gitDesktop from './gifDesktop.gif';
import gifMobile from './gifMobile.gif';
import { oldRaLogo, raLogoWhite } from 'images/main';
import { useUploadStatementListPageTracker } from '../hooks/useUploadStatementListPageTracker';

export function UploadStatementListPage() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { sendPostFileRequest } = useContentRestRequest();
  const { errorToast } = useToast();
  const history = useHistory();
  const {
    trackViewPage,
    trackUploadList,
  } = useUploadStatementListPageTracker();
  const { amplitudeStartSessionReply } = useTracker();

  const {
    isInstagramWebView,
    openInBrowser,
    isWebView,
    isIOSDevice,
  } = useWebView();

  useEffect(() => {
    if (isInstagramWebView()) {
      openInBrowser(window.location.href);
    }
  }, [isInstagramWebView, openInBrowser]);

  const inputRef = useRef();

  useEffect(() => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);

    const cameFromRejectedList = !!searchParams.get('list_rejected');

    amplitudeStartSessionReply(0.2);
    trackViewPage(cameFromRejectedList, isWebView(), navigator.userAgent);
  }, [isWebView, trackViewPage, amplitudeStartSessionReply]);

  const sendList = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await sendPostFileRequest(
        '/mvp/list-with-statements-incomplete',
        file,
        {
          shouldSendListEmail: isIOSDevice() && isInstagramWebView(),
          queryString: window.location.search,
        }
      );

      if (response.data.listId) {
        trackUploadList(file?.type);

        const currentQuery = new URLSearchParams(location.search).toString();
        const newUrl = `/lista-de-enunciado/${response.data.listId}${
          currentQuery ? `?${currentQuery}` : ''
        }`;

        history.push(newUrl);
      }
    } catch (e) {
      errorToast(
        'Erro durante o envio da lista, por favor tente novamente mais tarde'
      );
    } finally {
      setLoading(false);
    }
  }, [
    errorToast,
    file,
    history,
    isIOSDevice,
    isInstagramWebView,
    loading,
    sendPostFileRequest,
    trackUploadList,
  ]);

  const handleInputChange = useCallback(event => {
    const selectedFile = event.target.files[0]; // Obtém o arquivo selecionado
    if (selectedFile) {
      setFile(selectedFile);
    }
  }, []);

  const handleSendButtonClick = useCallback(
    e => {
      if (isInstagramWebView() && !isIOSDevice()) {
        openInBrowser(window.location.href);
        errorToast('Navegador não suportado, tente abrir em outro navegador !');
        e.preventDefault();
        return;
      }
    },
    [errorToast, isIOSDevice, isInstagramWebView, openInBrowser]
  );

  const renderMobilePage = useCallback(() => {
    return (
      <Container>
        <Header>
          <LogoImg src={raLogoWhite} />
        </Header>
        <UploadSection>
          <Title>Sua Lista de exercícios</Title>
          <SmallTitle>resolvida em 45 segundos</SmallTitle>
          <GifContainer>
            <InstructionGif src={gifMobile} />
          </GifContainer>

          {file?.name}

          <input
            onClick={e => handleSendButtonClick(e)}
            ref={inputRef}
            type="file"
            onChange={e => {
              handleInputChange(e);
            }}
            accept="image/jpeg,image/png,image/jpg,application/pdf"
          ></input>

          <UploadButton
            onClick={() => {
              if (!file && inputRef?.current) {
                inputRef.current.click();
                return;
              }

              sendList();
            }}
          >
            {loading ? 'Enviando...' : 'Enviar Lista'}
          </UploadButton>
          <Bullets>
            <Bullet>Envie o PDF ou print da sua lista</Bullet>
            <Bullet>Receba sua lista resolvida passo a passo</Bullet>
            <Bullet>Questões revisadas pelo Responde Aí</Bullet>
          </Bullets>
        </UploadSection>
      </Container>
    );
  }, [file, handleInputChange, handleSendButtonClick, loading, sendList]);

  if (media.isMobile()) return renderMobilePage();

  return (
    <Container>
      <UploadSection>
        <LogoImg src={oldRaLogo} />
        <Title>
          Sua Lista de exercícios <br /> resolvida em 45 segundos
        </Title>
        <SubTitle>
          Envie o <b>PDF ou print</b> da sua lista e <br />
          <span>receba instantaneamente</span> cada <br />
          exercício resolvido passo a passo
        </SubTitle>
        <UploadInputContainer hasFile={!!file}>
          {file ? (
            <>
              <p>{file.name}</p>
              <DesktopSendListButton onClick={sendList}>
                {loading ? 'Enviando...' : 'Enviar Lista'}
              </DesktopSendListButton>
            </>
          ) : (
            <UploadArea setFile={setFile} />
          )}
        </UploadInputContainer>
      </UploadSection>
      <ExplanationSection>
        <GifContainer>
          <InstructionGif src={gitDesktop} />
        </GifContainer>
        <Bullets>
          <Bullet>Envie o PDF ou print da sua lista</Bullet>
          <Bullet>Receba sua lista resolvida passo a passo</Bullet>
          <Bullet>Questões revisadas pelo Responde Aí</Bullet>
        </Bullets>
      </ExplanationSection>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media ${media.mobile} {
    padding: 0;
  }
`;

const LogoImg = styled.img`
  width: 200px;
  margin-bottom: 20px;

  @media ${media.mobile} {
    width: 120px;
    margin-bottom: 0;
    color: #fff;
  }
`;

const Header = styled.div`
  height: 7vh;
  max-height: 70px;
  width: 100%;
  background-color: ${colors.mainOrange};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadSection = styled.div`
  margin-top: 40px;
  padding: 15px;
  min-width: 122px;
  input {
    display: none;
  }

  @media ${media.mobile} {
    margin: 0;
    text-align: center;
    padding: 0 15px 15px 15px;
    display: flex;
    flex-direction: column;
    min-height: 93vh;
    box-sizing: border-box;

    justify-content: center;
  }
`;

const Title = styled.div`
  font-size: 2em;
  text-transform: uppercase;
  color: black;
  font-weight: bold;

  @media ${media.mobile} {
    font-size: 1.5em;
    margin-top: 7px;
    text-shadow: 2px 2px 4px rgba(174, 172, 172, 0.45);
    color: ${colors.mainOrange};
  }
`;

const SmallTitle = styled.div`
  font-size: 2.6em;
  margin-top: 10px;
  text-transform: uppercase;
  color: ${colors.mainOrange};
  font-weight: bold;
  @media ${media.mobile} {
    font-size: 1.5em;
    text-shadow: 2px 2px 4px rgba(174, 172, 172, 0.45);
    margin-bottom: 25px;
    margin-top: 5px;
  }
`;

const SubTitle = styled.div`
  font-size: 1.5em;
  margin: 25px 0 40px 0;
  color: #343434;
  line-height: 1.1em;

  span {
    background-color: #f7ac3d;
  }
`;

const UploadInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #eea232;
  border-radius: 10px;
  background-color: #fcf3e4;
  height: 70px;
  cursor: ${props => (props.hasFile ? 'initial' : 'pointer')};
  padding: ${props => (props.hasFile ? '0 25px' : '0')};

  p {
    color: black;
  }
`;

const DesktopSendListButton = styled.div`
  border: 2px solid #eea232;
  background-color: #fbcf90;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1.2em;
  color: black;
  cursor: pointer;
`;

const UploadButton = styled.div`
  @media ${media.mobile} {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 2em;
    padding: 12px;
    border-radius: 12px;
    background-color: ${colors.mainOrange};
    cursor: pointer;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #eba33b;
    }
    margin-top: 5px;
  }
`;

const ExplanationSection = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;
const GifContainer = styled.div`
  padding: 20px;
  border: 1px solid ${colors.mainOrange};
  border-radius: 20px;
  margin-bottom: 45px;
  max-width: 520px;
  @media ${media.mobile} {
    max-width: 70%;
    height: 50vh;
    max-height: 450px;
    margin: 0 auto 0 auto;
    margin-bottom: 30px;
    border: none;
    box-shadow: 2px 1px 10px 3px rgba(0, 0, 0, 0.25);
    padding: 10px;
  }
`;

const InstructionGif = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Bullets = styled.div`
  display: flex;
  flex-direction: column;

  @media ${media.mobile} {
    margin-top: 20px;
  }
`;
const Bullet = styled.div`
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  font-size: 1.4em;
  display: flex;
  align-items: center;
  color: #343434;

  @media ${media.mobile} {
    font-size: 0.9em;
    margin-bottom: 7px;
    padding-left: 22px;
  }

  &::before {
    content: '✔'; /* Ícone de checkmark */
    position: absolute;
    left: 0;
    top: 2px;
    color: white; /* Cor do ícone */
    font-size: 18px;
    height: 21px;
    width: 21px;
    text-align: center;
    border-radius: 50%;
    background-color: ${colors.mainOrange};
    @media ${media.mobile} {
      font-size: 16px;
      height: 18px;
      width: 18px;
      background-color: white;
      color: gray;
      top: -1px;
    }
  }
`;
