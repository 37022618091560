import React, { useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useWorkspaceExercise } from 'modules/workspace/hooks/shared';
import {
  useWorkspace,
  useWorkspaceMainLinks,
} from 'modules/workspace/hooks/main';
import { ExerciseContent } from 'modules/workspace/components/content/shared';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';

export default function MainExerciseContent({
  exercises,
  contentAccess,
  exerciseContentTrackMethods,
  setCurrentContent,
  setCurrentContentFormat,
}) {
  const { isExtraTimePageWorkspace } = useWorkspace();
  const { filterListExercisesFromUniversity } = useWorkspaceExercise();
  const { listExerciseId } = useParams();
  const { getExerciseLink } = useWorkspaceMainLinks();
  const { filterExtraExercises } = useWorkspaceContentContext();

  const isListExercisePage = useMemo(() => {
    return !!listExerciseId;
  }, [listExerciseId]);

  const listExercisesFromUniversity = useMemo(() => {
    return isListExercisePage && filterListExercisesFromUniversity(exercises);
  }, [exercises, isListExercisePage, filterListExercisesFromUniversity]);

  const filteredExercises = useMemo(() => {
    if (!isListExercisePage) return exercises;

    if (isExtraTimePageWorkspace()) {
      const listExercisesNotFromUniversity = filterExtraExercises(
        exercises,
        listExercisesFromUniversity
      );
      return listExercisesNotFromUniversity;
    }

    if (listExercisesFromUniversity.length > 0)
      return listExercisesFromUniversity;

    return exercises;
  }, [
    exercises,
    isListExercisePage,
    listExercisesFromUniversity,
    isExtraTimePageWorkspace,
    filterExtraExercises,
  ]);

  const exercisesLimit = useMemo(() => {
    if (!listExerciseId) return null;
    if (isExtraTimePageWorkspace()) return null;
    if (listExercisesFromUniversity.length > 0) return null;

    return 5;
  }, [listExerciseId, listExercisesFromUniversity, isExtraTimePageWorkspace]);

  const getExerciseName = useCallback((exercise, index) => {
    return index + 1;
  }, []);

  return (
    <ExerciseContent
      exercises={filteredExercises}
      exercisesLimit={exercisesLimit}
      contentAccess={contentAccess}
      exerciseContentTrackMethods={exerciseContentTrackMethods}
      setCurrentContent={setCurrentContent}
      setCurrentContentFormat={setCurrentContentFormat}
      getExerciseLink={getExerciseLink}
      getExerciseName={getExerciseName}
    />
  );
}
