import React, { useCallback } from 'react';

import { styled, media, colors } from 'styles';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';

export default function BookCreditCardTrialSuccessDialog({ planPricing }) {
  const {
    getMonthlyPriceValue,
    maxCancelationDate,
    bookCreditCardTrialFirstCharge,
    trialDays,
  } = useBookTrialCampaign();

  const notifyIframeForSuccess = useCallback(() => {
    window.top.postMessage('trial-success', '*');
  }, []);

  const handleBeginStudyButton = useCallback(() => {
    notifyIframeForSuccess();
    window.location.reload();
  }, [notifyIframeForSuccess]);

  return (
    <Container>
      <Content>
        <Title>
          Uhuu! Você acaba de ganhar <br /> {trialDays} dias de Responde Aí
          Grátis
        </Title>

        <Info className="small">
          À partir do dia {bookCreditCardTrialFirstCharge()} inicia sua
          assinatura {planPricing.plan.description} no valor de{' '}
          {planPricing.plan.months}x de R$ {getMonthlyPriceValue(planPricing)}.{' '}
          <b>Para não ser cobrado, cancele até o dia {maxCancelationDate()}</b>.
          Você pode fazer o cancelamento pela página de{' '}
          <a
            href={`${process.env.REACT_APP_REACT_URL}/minha-conta`}
            style={{ textDecoration: 'underline' }}
          >
            "Minha Conta"
          </a>
          . Todas essas informações estão sendo enviadas para o seu email.
        </Info>

        <Info>Aproveite e sucesso nos estudos!</Info>

        <ButtonsContainer>
          <Button className="highlight" onClick={handleBeginStudyButton}>
            Acessar o Responde Aí
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  padding: 30px 15px 20px;
  background-color: #fff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
`;

const Title = styled.h1`
  font-size: 2em;
  font-weight: bold;
  color: #000;
  margin-bottom: 25px;

  @media ${media.mobile} {
    font-size: 1.7em;
  }
`;

const Info = styled.div`
  width: 460px;
  max-width: 95%;
  margin: 10px;
  font-size: 0.9em;
  font-weight: bold;

  &.small {
    font-size: 0.8em;
  }
`;

const ButtonsContainer = styled.div`
  width: 400px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  border: 2px solid #000;
  font-weight: bold;
  font-size: 1.5em;
  margin: 5px 0;
  padding: 10px 0;
  border-radius: 5px;

  &.highlight {
    background-color: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
    color: #fff;
  }

  @media ${media.mobile} {
    font-size: 1.4em;
  }
`;
