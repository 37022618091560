import React, { useCallback, useEffect } from 'react';
import { styled } from 'styles';
import UploadImageArea from '../components/UploadImageArea';
import { useSearchContext } from '../providers/SearchProvider';
import ImagePreview from '../components/ImagePreview';
import { BeatLoader } from 'modules/shared/components/generic/loaders';
import ResultsContainer from '../components/ResultsContainer';
import useSearchTracker from '../hooks/useSearchTracker';

export default function UploadScreenOnDesktop() {
  const { imgUploadedSrc, results, loading } = useSearchContext();
  const { trackViewUploadPage } = useSearchTracker();

  const shouldShowImagePreview = useCallback(() => {
    return !results && imgUploadedSrc;
  }, [results, imgUploadedSrc]);

  useEffect(() => {
    trackViewUploadPage();
  }, [trackViewUploadPage]);

  return (
    <Container>
      <UploadImageArea />
      {shouldShowImagePreview() && <ImagePreview />}
      {loading ? (
        <BeatLoader size={12} color="#149f8c" />
      ) : (
        <ResultsContainer />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding-top: 35px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
