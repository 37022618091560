import { useUserContext } from 'providers/UserProvider';
import { useCallback } from 'react';
import { CONTENT_ITEMS } from '../contentItems';

export default function useDownloadContentCampaign() {
  const { user } = useUserContext();
  const shouldRenderDownloadContentPhoneRequestDialog = useCallback(() => {
    if (!user) return false;
    const searchParams = new URLSearchParams(window.location.search);
    const hasParam = searchParams.get('contentDialog');

    return hasParam === 'true';
  }, [user]);

  const getUtmTerm = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('utm_term');
  }, []);

  const getContentByTerm = useCallback(term => {
    return CONTENT_ITEMS.filter(item => item.name === term);
  }, []);

  return {
    shouldRenderDownloadContentPhoneRequestDialog,
    getContentByTerm,
    getUtmTerm,
  };
}
