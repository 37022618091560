import { styled } from 'styles';

const IconContainer = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  width: 30px;
  min-width: 30px;
  height: 23px;
  transition: background-color 0.3s ease-in-out;

  &.open {
    background-color: #393939;
  }
`;

export default IconContainer;
