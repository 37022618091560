import React, { useEffect, useMemo, useCallback } from 'react';

import { styled } from 'styles';
import { usePaymentFailedRecoverCampaign } from 'modules/campaigns/recover-subscriber/payment-failed-recover/hooks/PaymentFailedRecoverCampaign';
import { useTracker } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import {
  Container,
  Content,
  Title,
  ButtonsContainer,
  OptionButton,
} from 'modules/campaigns/recover-subscriber/styles';
import { RetryWithNewCardDialog } from '.';

export default function RetryErrorDialog({ source, pricing }) {
  const { trackEvent } = useTracker();
  const { setDialog, unsetDialog } = useDialogContext();
  const {
    getCard,
    defaultAmplitudeEventAttributes,
  } = usePaymentFailedRecoverCampaign();

  useEffect(() => {
    trackEvent('MVP Falha de Pagamento 2.0: Visualizou Erro', {
      utm_source: source,
      opcao: 'mesmo cartao',
      plan: pricing.description,
      ...defaultAmplitudeEventAttributes,
    });
  }, [source, trackEvent, pricing, defaultAmplitudeEventAttributes]);

  const card = useMemo(() => {
    return getCard();
  }, [getCard]);

  const onNewCardClick = useCallback(() => {
    setDialog(<RetryWithNewCardDialog source={source} pricing={pricing} />);
  }, [setDialog, source, pricing]);

  return (
    <Container onClick={unsetDialog}>
      <Content onClick={e => e.stopPropagation()}>
        <Title>Erro ao efetuar cobrança</Title>
        <PaymentInfo>
          Não conseguimos realizar a cobrança de{' '}
          <Strong>
            R$ {pricing.price.toFixed(2)}{' '}
            {pricing.months > 1 &&
              `(${pricing.months}x de R$${pricing.monthlyPricing.toFixed(2)})`}
          </Strong>{' '}
          em seu cartão de crédito{' '}
          <Strong>
            {card.brand} de final X-{card.lastFourDigits}.
          </Strong>
        </PaymentInfo>
        <PaymentInfo>
          Com isso, seu acesso aos conteúdos continua bloqueado. Deseja tentar
          efetuar o pagamento por um novo cartão de crédito?
        </PaymentInfo>
        <ButtonsContainer>
          <OptionButton onClick={() => window.UserVoice.show()}>
            Falar com o atendimento
          </OptionButton>
          <OptionButton onClick={onNewCardClick}>
            Tentar com novo cartão
          </OptionButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const PaymentInfo = styled.div`
  font-size: 1.2em;
  margin: 15px 0;
`;

const Strong = styled.span`
  font-weight: bold;
`;
