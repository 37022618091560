import { useCallback } from 'react';

import { useProfessorContext } from 'modules/professors/providers/ProfessorProvider';
import { useTracker } from 'modules/shared/hooks';

export function useHomeProfessorTracker() {
  const { userProfessors } = useProfessorContext() || {};
  const { trackEvent, amplitudeStartSessionReply } = useTracker();

  const trackAddUserProfessorClickButton = useCallback(() => {
    trackEvent('Home com Professor: Clicou Adicionar Guia do Professor', {
      'Numero de Disciplinas do Semestre': userProfessors?.length || 0,
    });
  }, [trackEvent, userProfessors]);

  const trackUserProfessorClick = useCallback(
    userProfessor => {
      trackEvent('Home com Professor: Clicou Guia do Professor', {
        Matéria: userProfessor?.professor?.discipline?.theme?.name,
        Disciplina: userProfessor?.professor?.discipline?.name,
        Professor: userProfessor?.professor?.name,
        'ID Professor': userProfessor?.professor?.id,
        'Numero de Disciplinas do Semestre': userProfessors?.length || 0,
      });
    },
    [trackEvent, userProfessors]
  );

  const trackClickDiscipline = useCallback(
    (discipline, theme) => {
      trackEvent('Home com Professor: Clicou Disciplina Personalizada', {
        Matéria: theme?.name,
        Disciplina: discipline?.name,
        'Numero de Disciplinas do Semestre': userProfessors?.length || 0,
      });
    },
    [trackEvent, userProfessors]
  );

  const trackClickSubject = useCallback(
    (subject, theme) => {
      trackEvent('Home com Professor: Clicou Assunto Default', {
        Matéria: theme?.name,
        Assunto: subject?.name,
        'Numero de Disciplinas do Semestre': userProfessors?.length || 0,
      });
    },
    [trackEvent, userProfessors]
  );

  const trackClickOnDeleteUserProfessor = useCallback(
    userProfessor => {
      trackEvent('Home com Professor: Clicou Deletar Guia do Professor', {
        Matéria: userProfessor?.professor?.discipline?.theme?.name,
        Disciplina: userProfessor?.professor?.discipline?.name,
        Professor: userProfessor?.professor?.name,
        'ID Professor': userProfessor?.professor?.id,
        'Numero de Disciplinas do Semestre': userProfessors?.length || 0,
      });
    },
    [trackEvent, userProfessors]
  );

  const trackDeleteUserProfessorSuccess = useCallback(
    userProfessor => {
      trackEvent('Home com Professor: Deletou Guia do Professor', {
        Matéria: userProfessor?.professor?.discipline?.theme?.name,
        Disciplina: userProfessor?.professor?.discipline?.name,
        Professor: userProfessor?.professor?.name,
        'ID Professor': userProfessor?.professor?.id,
      });
    },
    [trackEvent]
  );

  const trackHomeAccess = useCallback(() => {
    trackEvent('Home com Professor: Visualizou Home');
  }, [trackEvent]);

  const recordScreen = useCallback(() => {
    amplitudeStartSessionReply(0.1); // record 10% of sessions
  }, [amplitudeStartSessionReply]);

  return {
    trackAddUserProfessorClickButton,
    trackUserProfessorClick,
    trackClickDiscipline,
    trackClickOnDeleteUserProfessor,
    trackDeleteUserProfessorSuccess,
    trackHomeAccess,
    recordScreen,
    trackClickSubject,
  };
}
