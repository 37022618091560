import React, { useRef } from 'react';
import Slick from 'react-slick';

import ThemeItem from './ThemeItem.js';

import { styled, media } from 'styles';

export default function ThemesSlider({
  themes,
  selectedTheme,
  setSelectedTheme,
}) {
  const ref = useRef(null);

  function onThemeChange(i) {
    ref.curent && ref.current.slickGoTo(i);
    setSelectedTheme(themes[i]);
  }

  const config = {
    slidesToShow: themes.length > 3 ? 4 : themes.length,
    slidesToScroll: 1,
    speed: 400,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider ref={ref} {...config}>
      {themes.map((t, i) => (
        <ThemeItem
          onClick={() => onThemeChange(i)}
          key={t.id}
          color={t.color}
          imagePath={t.imagePath}
          name={t.name}
          selected={selectedTheme.id === t.id}
        />
      ))}
    </Slider>
  );
}

const Slider = styled(Slick)`
  width: 70%;
  margin: 50px auto;
  max-width: 960px;

  button.slick-prev,
  button.slick-next {
    &:before {
      color: #555;
      font-size: 26px;
    }
  }

  @media ${media.mobile} {
    width: 89%;
    margin: 20px auto;

    button.slick-prev,
    button.slick-next {
      &:before {
        font-size: 20px;
      }
    }
  }
`;
