import React, { useCallback } from 'react';
import SubscriptionButton from './SubscriptionButton';
import { useDate } from 'modules/shared/hooks';
import { styled } from 'styles';
import { useHistory } from 'react-router-dom';

export default function KeepSubscriptionButton({ subscription }) {
  const { getHumanReadableDateFromTimestamp } = useDate();
  let history = useHistory();

  const handleClick = useCallback(() => {
    const renewDate = getHumanReadableDateFromTimestamp(subscription.expiresIn);
    const message = `Show de bola. Sua assinatura continuará ativa e está prevista para renovar em ${renewDate}`;

    alert(message);

    history.push('/materias');
  }, [getHumanReadableDateFromTimestamp, subscription, history]);

  return (
    <Container onClick={handleClick}>
      Quero continuar com o meu plano e resgatar o bônus depois
    </Container>
  );
}

const Container = styled(SubscriptionButton)`
  background-color: #60b160;
`;
