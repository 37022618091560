import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { styled } from 'styles';
import { defaultSubjectImage, soonContent } from 'images/main';

export default function SubjectBlock({ subject, color, themeId }) {
  let { imagePath, name } = subject;

  const shouldGoToWorkspace = useMemo(() => {
    return !subject.hasLecture && !subject.hasBookSubject;
  }, [subject]);

  const urlLink = useMemo(() => {
    if (shouldGoToWorkspace)
      return `/aprender/topico/${subject.id}/${subject.firstTopic.id}/teoria/${subject.firstTheoryId}`;

    return `/materias/completas/${themeId}/assunto/${subject.id}`;
  }, [shouldGoToWorkspace, subject, themeId]);

  if (!imagePath) imagePath = defaultSubjectImage;
  if (name === 'Vazio') imagePath = soonContent;

  if (shouldGoToWorkspace) {
    return (
      <CustomLinkProvisory color={color} to={urlLink}>
        <img src={imagePath} alt="imagem-materia"></img>
        <p>{name}</p>
      </CustomLinkProvisory>
    );
  }

  return (
    <CustomLink color={color} to={urlLink}>
      <img src={imagePath} alt="imagem-materia"></img>
      <p>{name}</p>
    </CustomLink>
  );
}

const CustomLink = styled(Link)`
  width: 160px;
  height: 160px;
  margin: 25px 2px 0 2px;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 2px 1px -1px rgb(0 0 0 / 12%);
  border-radius: 8px;
  z-index: 10;
  cursor: pointer;

  img {
    max-width: 100%;
    background-color: ${props => props.color};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  p {
    text-transform: uppercase;
    margin-left: 4px;
    font-size: 0.9rem;
  }
`;

const CustomLinkProvisory = styled(Link)`
  width: 160px;
  height: 160px;
  margin: 25px 2px 0 2px;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 2px 1px -1px rgb(0 0 0 / 12%);
  border-radius: 8px;
  z-index: 10;
  cursor: pointer;

  img {
    max-width: 100%;
    background-color: ${props => props.color};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  p {
    text-transform: uppercase;
    margin-left: 4px;
    font-size: 0.9rem;
  }
`;
