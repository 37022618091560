import React, { useCallback, useEffect, useState } from 'react';
import { media, styled } from 'styles';
import { PlanChoiceStep } from './PlanChoiceStep';
import { pricings } from './data/pricings';
import { CreditCardFormStep } from './CreditCardFormStep';
import { SuccessStep } from './SuccessStep';
import { useCheckoutDialogTracker } from './hooks/useCheckoutDialogTracker';

const annualPricingId = pricings.find(pricing => pricing.plan.id === 3)?.id;

export function CheckoutDialog({ origin, eventProps = {} }) {
  const [pricingId, setPricingId] = useState(annualPricingId);
  const [step, setStep] = useState(1);
  const { trackViewDialogNoAccess } = useCheckoutDialogTracker(eventProps);

  const getStep = useCallback(() => {
    if (step === 1)
      return (
        <PlanChoiceStep
          setStep={setStep}
          setPricingId={setPricingId}
          pricingId={pricingId}
          eventProps={eventProps}
        />
      );
    if (step === 2)
      return (
        <CreditCardFormStep
          pricingId={pricingId}
          setStep={setStep}
          eventProps={eventProps}
        />
      );
    if (step === 3) return <SuccessStep eventProps={eventProps} />;
  }, [step, pricingId, eventProps]);

  useEffect(() => {
    trackViewDialogNoAccess(origin);
  }, [origin, trackViewDialogNoAccess]);

  return <Container onClick={e => e.stopPropagation()}>{getStep()}</Container>;
}

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 600px;
  height: fit-content;
  max-width: 100%;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: rgb(238, 238, 238) 0px 0px 10px 0px;
  border-top: 10px solid #fbc531;
  z-index: 10;
  color: #222;

  @media ${media.mobile} {
    border-top: 0;
    height: initial;
  }
`;
