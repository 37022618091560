import React, { useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';

import { media, styled } from 'styles';
import { FaFaceGrinStars } from 'react-icons/fa6';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useTrackEvents } from 'modules/mvp/hooks';

export function CheckSearchResult({ question, onCheckResult }) {
  const { sendPostRequest } = useContentRestRequest();
  const {
    trackViewCheckSearchResult,
    trackCheckResultSearch,
  } = useTrackEvents();

  useEffect(() => {
    if (!question) return;

    trackViewCheckSearchResult(question, question.mvpListWithStatement);
  }, [question, trackViewCheckSearchResult]);

  const onButtonClick = useCallback(
    async found => {
      try {
        if (found)
          await await sendPostRequest(
            '/mvp/lists-with-statements/user-selections',
            {
              mvpListWithStatementQuestionId: question.id,
              exerciseType: question.exerciseType,
              exerciseId: question.exerciseId,
            }
          );
        else
          await sendPostRequest('/mvp/lists-with-statements/user-selections', {
            mvpListWithStatementQuestionId: question.id,
            exerciseType: null,
            exerciseId: null,
          });

        onCheckResult(found);
      } catch (e) {
        console.error(e);
        alert(
          'Erro ao selecionar questão. Por favor, entre em contato com o relacionamento'
        );
      } finally {
        trackCheckResultSearch(question, found, question.mvpListWithStatement);
      }
    },
    [question, onCheckResult]
  );

  return (
    <Container>
      <Title>A questão que encontramos é a mesma da sua lista?</Title>
      <ButtonsContainer>
        <Button color="#EE6D6D" onClick={() => onButtonClick(false)}>
          Não é a minha questão <FontAwesomeIcon icon={faThumbsDown} />
        </Button>
        <Button color="#82C577" onClick={() => onButtonClick(true)}>
          É a minha questão <FaFaceGrinStars />
        </Button>
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 1.6em;
  font-weight: bold;
  text-align: center;
  color: #5d5d5d;

  @media ${media.mobile} {
    font-size: 1.3em;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: auto;

  @media ${media.mobile} {
    flex-direction: column;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  padding: 10px 0;
  margin: 20px;
  background-color: ${({ color }) => color};
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
  border-radius: 40px;
  opacity: 0.8;
  cursor: pointer;

  svg {
    margin-left: 10px;
  }

  &:hover {
    opacity: 1;
  }

  @media ${media.mobile} {
    opacity: 1;
  }
`;
