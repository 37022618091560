import React, { useCallback } from 'react';
import { styled, media } from 'styles';
import TagAsFreeContent from 'modules/shared/components/TagAsFreeContent';
import { useUserContext } from 'providers/UserProvider';

export default function CardsContainer({
  children,
  color,
  title,
  icon,
  width,
  shouldHaveFreeTag = false,
  source,
  styleTitle = {},
  styleIcon = {},
}) {
  const { user } = useUserContext();

  const getCustomFreeTagOfCardContainer = useCallback(source => {
    if (source === 'booksCards')
      return <CustomFreeTagBookContent text="1º CAPÍTULO GRÁTIS" />;
    if (source === 'subjectsCardsFromNotFreeDiscipline')
      return <CustomFreeTagSubjectContent text="Introdução Grátis" />;
    else return <CustomFreeTagContent text="GRÁTIS" />;
  }, []);

  return (
    <Container width={width}>
      <CardsHeader color={color}>
        <IconContainer>
          <Icon src={icon} style={styleIcon} />
        </IconContainer>

        <Title style={styleTitle}>{title}</Title>
        {!user?.subscriber &&
          shouldHaveFreeTag &&
          getCustomFreeTagOfCardContainer(source)}
      </CardsHeader>
      {children}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '265px'};
  max-width: 95%;
  margin: 0 15px;
  padding-top: 70px;
  padding-bottom: 20px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 2px 1px -1px rgb(0 0 0 / 12%);
  border-radius: 7px;

  @media ${media.mobile} {
    width: 100%;
    max-width: 100%;
  }
`;

const CardsHeader = styled.div`
  color: ${({ color }) => color};
`;

const Title = styled.h1`
  text-align: center;
  text-transform: uppercase;
  font-size: 1.05em;
  font-weight: bold;
  margin: 0 20px 40px 20px;
`;

const IconContainer = styled.div`
  background: #fff;
  border-radius: 50%;
  width: 85px;
  height: 85px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -32px;
  text-align: center;
`;

const Icon = styled.img`
  margin-top: 8px;
`;

const CustomFreeTagBookContent = styled(TagAsFreeContent)`
  background-color: rgba(0, 0, 0, 0.8);
  width: 180px;
  height: 25px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  margin-top: -27px;
  font-size: 14px;

  .star-icon {
    font-size: 18px;
    color: #f7ac3b;
  }
`;

const CustomFreeTagSubjectContent = styled(TagAsFreeContent)`
  width: 180px;
  height: 25px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  margin-top: -27px;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.6);

  .star-icon {
    font-size: 18px;
    color: #b3b3b3;
  }
`;

const CustomFreeTagContent = styled(TagAsFreeContent)`
  background-color: rgba(0, 0, 0, 0.8);
  width: 90px;
  height: 25px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 24px;
  margin-top: -27px;

  .star-icon {
    font-size: 20px;
    color: #f7ac3b;
  }
`;
