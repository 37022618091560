import React from 'react';
import { styled, media } from 'styles';
import RecaptchaInfo from './RecaptchaInfo';

export default function Footer({ shouldShowFooter = true }) {
  const RESPONDEAI_URL = process.env.REACT_APP_RESPONDEAI_URL;

  if (!shouldShowFooter) return null;

  return (
    <>
      <RecaptchaInfo />
      <Container>
        <ExternalLink href={`${RESPONDEAI_URL}/privacidade`}>
          Políticas de Privacidade
        </ExternalLink>
        <ExternalLink href={`${RESPONDEAI_URL}/termos-de-uso`}>
          Termos de Uso
        </ExternalLink>
        <ExternalLink href={`${RESPONDEAI_URL}/planos`}>Planos</ExternalLink>
        <ExternalLink href="http://www.procon.rj.gov.br/">
          Procon RJ
        </ExternalLink>
      </Container>
    </>
  );
}

const Container = styled.footer`
  background-color: #f3f3f3;
  border-top: 1px solid rgba(50, 50, 50, 0.15);
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media ${media.mobile} {
    margin-bottom: 55px;
  }
`;

const ExternalLink = styled.a`
  text-transform: uppercase;
  font-size: 0.75em;
  cursor: pointer;
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  border-radius: 2px;
  border: 0;
  padding: 0 6px;
  margin: 6px 8px;
  background: transparent;
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    background-color: rgba(158, 158, 158, 0.2);
  }
`;
