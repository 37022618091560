import React, { useRef, useState } from 'react';
import { useLectureContext } from '../../providers/LectureProvider';
import { styled } from 'styles';
import {
  IoMdVolumeHigh,
  //IoMdVolumeLow,
  IoMdVolumeOff,
  //IoMdVolumeMute,
} from 'react-icons/io';

const VideoVolumeControl = () => {
  const volumeBarRef = useRef();
  const [showVolumeBar, setShowVolumeBar] = useState(false);

  const { onVolumeBarClick, volume, muted, toggleMuted } = useLectureContext();

  return (
    <VolumeController
      onMouseOver={() => setShowVolumeBar(true)}
      onMouseOut={() => setShowVolumeBar(false)}
    >
      <VolumeClickable onClick={e => onVolumeBarClick(e, volumeBarRef)}>
        <VolumeFullBar ref={volumeBarRef} showVolumeBar={showVolumeBar}>
          <VolumeCurrentBar heigth={volume} muted={muted} />
        </VolumeFullBar>
      </VolumeClickable>

      <IconContainer onClick={toggleMuted}>
        {muted ? (
          <IoMdVolumeOff className="icon" />
        ) : (
          <IoMdVolumeHigh className="icon" />
        )}
      </IconContainer>
    </VolumeController>
  );
};

const IconContainer = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VolumeController = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
  &:hover {
    .icon {
      color: #666;
    }
  }
`;

const VolumeFullBar = styled.div`
  height: 70px;
  width: 8px;
  background-color: #ddd;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  margin: auto;
  display: ${({ showVolumeBar }) => (showVolumeBar ? 'flex' : 'none')};
`;

const VolumeCurrentBar = styled.div`
  width: 8px;
  background-color: #f9ac3e;
  border-radius: 5px;
  height: ${({ heigth, muted }) => (muted ? '0%' : `${heigth * 100}%`)};
`;

const VolumeClickable = styled.div`
  width: 100%;
  padding-top: 15px;
`;

export default VideoVolumeControl;
