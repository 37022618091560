import { useProfessorTracker } from 'modules/guides/hooks';
import React, { useCallback, useMemo } from 'react';
import { colors, styled } from 'styles';
import Slider from 'react-slick';
import { GoPlay } from 'react-icons/go';
import { useHistory } from 'react-router-dom';
import { useSupportMaterialBanner } from 'modules/guides/hooks/useSupportMaterialBanner';

const SLIDER_SETTINGS = {
  speed: 500,
  slidesToShow: 1, // Será sobrescrito pelo `variableWidth`
  slidesToScroll: 1,
  infinite: false, // Desativa o loop
  variableWidth: true, // Permite largura variável dos slides
};

export default function LectureSection({ professor, lectures }) {
  const { trackClickLecture } = useProfessorTracker();
  const history = useHistory();
  const { setCookieShouldNotShowBanner } = useSupportMaterialBanner();

  const themeColor = useMemo(() => {
    if (!professor?.teachable?.theme?.color) return colors.mainOrange;
    return professor?.teachable?.theme?.color;
  }, [professor]);

  const handleLectureClick = useCallback(
    lecture => {
      setCookieShouldNotShowBanner();

      trackClickLecture({
        professorName: professor.name,
        disciplineName: professor.teachable.name,
        lectureSubject: lecture.subjectName,
        lists: [...professor.lists, ...professor.statementLists],
      });

      history.push(`/lecture/aulao/${lecture.id}`, {
        previousUrl: history.location.pathname,
      });
    },
    [professor, history, trackClickLecture, setCookieShouldNotShowBanner]
  );

  return (
    <Container>
      <Title>Aulões Salva-Vidas</Title>
      <SubTitle>
        Aqui você assiste as Resoluções de toda matéria que vai cair na sua
        prova.
      </SubTitle>
      <Slider {...SLIDER_SETTINGS}>
        {lectures.map(lecture => (
          <LectureContainer
            onClick={() => handleLectureClick(lecture)}
            key={lecture.id}
            imgSrc={lecture.imagePath}
            bgColor={themeColor}
          >
            <LectureTitle>{lecture.subjectName}</LectureTitle>
            <PlyerIcon className="playerIco" />
          </LectureContainer>
        ))}
      </Slider>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 20px auto 20px auto;
  color: #515151;

  .slick-arrow {
    ::before {
      color: black !important;
      font-size: 25px !important;
    }
  }
`;

const Title = styled.p`
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const SubTitle = styled.p`
  font-size: 17px;
`;

const LectureContainer = styled.div`
  width: 120px !important;
  height: 180px;
  margin: 5px;
  margin-top: 10px;
  background-color: ${({ bgColor }) => bgColor};
  background-image: ${({ imgSrc }) => `url(${imgSrc})`};
  background-position: center 20px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px;
  cursor: pointer;

  .playerIco {
    transition: all 0.1s ease;
  }

  &:hover .playerIco {
    font-size: 70px;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
  }
`;

const PlyerIcon = styled(GoPlay)`
  position: absolute;
  font-size: 60px;
  color: white;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
`;

const LectureTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: calc(50% + 35px);
  left: 5px;
`;
