import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useOutsideClick } from 'modules/shared/hooks';
import { Row } from '../shared/styles';
import { menu, menuClick, del, report, edit } from 'images/forum';
import { colors, media } from 'styles';

function MenuButton({ onClickEdit, onClickDelete, onClickReport }) {
  const [opened, setOpened] = useState(false);
  const ref = useRef(null);

  useOutsideClick(ref, () => setOpened(false));

  return (
    <div
      onClick={() => setOpened(!opened)}
      style={{
        cursor: 'pointer',
      }}
      ref={ref}
    >
      <img src={opened ? menuClick : menu} alt="menu" />
      {opened && (
        <OpenedMenu
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          onClickReport={onClickReport}
        />
      )}
    </div>
  );
}

export default MenuButton;

function OpenedMenu({ onClickEdit, onClickDelete, onClickReport }) {
  return (
    <MenuContainer
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {onClickEdit && (
        <Row onClick={onClickEdit}>
          <MenuItemImage src={edit} alt="editar" />
          <Text>Editar</Text>
        </Row>
      )}
      {onClickDelete && (
        <Row onClick={onClickDelete}>
          <MenuItemImage src={del} alt="deletar" />
          <Text>Deletar</Text>
        </Row>
      )}
      {onClickReport && (
        <Row onClick={onClickReport}>
          <MenuItemImage src={report} alt="denunciar" />
          <Text>Denunciar</Text>
        </Row>
      )}
    </MenuContainer>
  );
}

const MenuContainer = styled.div`
  position: absolute;

  @media ${media.mobile} {
    right: 30px;
  }

  z-index: 100;

  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);

  border-top: 10px solid ${colors.mainOrange};
  border-radius: 8px;

  background-color: white;

  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const MenuItemImage = styled.img`
  margin: 8px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  color: #a2a2a2;
`;

const Text = styled.p`
  text-decoration: underline;
  color: #a2a2a2;
`;
