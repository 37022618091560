import { highlightObject, prepareQuery } from './utils';

export default class ElasticSearchExercises {
  static getSearchQuery(term, themeId) {
    const weight = term.length <= 35 ? 1 : 1.4;
    const keys = [{ name: 'subject.chapter.topic.exercise.lightBody', weight }];

    const source = [
      'id',
      'color',
      'name',
      'theory.id',
      'subject.id',
      'subject.name',
      'subject.chapter.id',
      'subject.chapter.name',
      'subject.chapter.topic.id',
      'subject.chapter.topic.name',
      'subject.chapter.topic.exercise.id',
      'subject.chapter.topic.exercise.topicId',
      'subject.chapter.topic.exercise.lightSolution',
      'subject.chapter.topic.exercise.lightSolutionMaths',
      'subject.chapter.topic.exercise.lightBodyMaths',
      'subject.chapter.topic.exercise.videos',
    ];
    const query = prepareQuery(term, keys, themeId);

    const json = {
      query,
      _source: source,
      size: 10,
      ...highlightObject(keys),
    };

    return json;
  }

  static adjustResults(data) {
    const hits = data.hits.hits
      .filter(it => it._index === 'ra_exercises')
      .filter(it => it.highlight);

    data = hits.map(it => {
      it._source.score = it._score;
      it._source.max_score = data.hits.max_score;

      const highlight =
        it.highlight['subject.chapter.topic.exercise.lightBody'];

      let lb = highlight[0];

      const mj = it._source.subject.chapter.topic.exercise.lightBodyMaths;

      for (let i = 0; i < mj.length; i++) {
        lb = lb.replace(`_m${i + 1}_`, mj[i]);
        lb = lb.replace(`_m${i + 1}`, mj[i]);
      }

      it._source.subject.chapter.topic.exercise.lightBody = lb;

      return it._source;
    });

    return data;
  }
}
