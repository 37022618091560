import React from 'react';
import {
  UserProvider,
  UniversityProvider,
  DialogProvider,
  BannerProvider,
  RollbarProvider,
} from './';

export default function RootProviders({ children }) {
  return (
    <RollbarProvider>
      <UserProvider>
        <DialogProvider>
          <BannerProvider>
            <UniversityProvider>{children}</UniversityProvider>
          </BannerProvider>
        </DialogProvider>
      </UserProvider>
    </RollbarProvider>
  );
}
