import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { styled, media } from 'styles';
import {
  useRAPostRequest,
  useRAQuery,
  useRAQueryImmutable,
} from 'modules/shared/hooks/requests';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import {
  SelectContainer,
  selectCustomComponents,
  selectCustomComponentsWithoutDropdown,
  selectCustomStyles,
  StepsContainer,
  UniversitySelectorContainer,
} from './styles';

import { useUserContext } from 'providers/UserProvider';
import UniversitySelects from 'modules/profile/components/profile-complete/components/UniversitySelects';
import { periodsList, yearsList } from './forms-options';
import { useTracker } from 'modules/shared/hooks';
import { Loading } from 'modules/shared/components/generic';
import { BeatLoader } from 'modules/shared/components/generic/loaders';

export default function CourseInfo({
  onSubmitInfo,
  setCourseInfo,
  courseInfo,
  setMaterialType,
  materialType,
  updatedUniversity,
  setUpdatedUniversity,
  sendAgain,
}) {
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [campi, setCampi] = useState([]);
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState(courseInfo?.course || '');
  const [period, setPeriod] = useState(courseInfo?.period || '');
  const [year, setYear] = useState(courseInfo?.year || '');
  const [fileTypeSelected, setFileTypeSelected] = useState(materialType || '');
  const [saving, setSaving] = useState(false);
  const [shouldShowJustFileType, setShouldShowJustFileType] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { trackEvent } = useTracker();
  const { user } = useUserContext();
  const [inputValue, setInputValue] = useState('');

  const { data: courseList, loading: loadingCourses } = useRAQueryImmutable(
    `/courses`
  );
  const {
    data: userUniversityAdmission,
    loading: loadingUniversityAdmission,
  } = useRAQuery('/users/university_admission');
  const sendUserInfo = useRAPostRequest(
    'users/update_user_info_on_upload_material'
  );

  const loading = useMemo(() => loadingCourses || loadingUniversityAdmission, [
    loadingCourses,
    loadingUniversityAdmission,
  ]);

  useEffect(() => {
    if (!userUniversityAdmission) return;

    if (userUniversityAdmission?.periodAdmission && !courseInfo?.period)
      setPeriod({ label: userUniversityAdmission?.periodAdmission });
    if (userUniversityAdmission?.yearAdmission && !courseInfo?.year)
      setYear({ label: userUniversityAdmission?.yearAdmission });

    if (
      (user.course &&
        userUniversityAdmission?.periodAdmission &&
        userUniversityAdmission?.yearAdmission &&
        (!courseInfo?.period || !courseInfo?.year)) ||
      sendAgain
    ) {
      setShouldShowJustFileType(true);
      if (!loading) trackEvent('Listas: Visualizou Dialog Tipo de Material');
    } else if (!loading)
      trackEvent('Visualizou Dialog de Qualificação', {
        Categoria: media.platformName(),
        Local: 'Envio de Material',
      });
  }, [
    userUniversityAdmission,
    courseInfo,
    loading,
    sendAgain,
    trackEvent,
    user,
  ]);

  useEffect(() => {
    if (!courseList) return;

    const enrichedCourses = courseList.map(course => {
      return {
        id: course?.id,
        label: course?.name,
        name: course?.name,
        value: course.id,
      };
    });

    setCourses(enrichedCourses);
  }, [courseList]);

  useEffect(() => {
    if (user?.course && !courseInfo?.course) {
      const selectedCourse = courses.find(c => c.id === user?.course?.id);
      setCourse(selectedCourse);
    }
  }, [user, courses, courseInfo]);

  const fileTypeOptions = ['Prova', 'Lista'];

  const canSubmit = useMemo(() => {
    if (shouldShowJustFileType && fileTypeSelected) return true;

    const shouldCompleteCampus = campi.length > 0 && !selectedCampus;

    return (
      selectedUniversity &&
      !shouldCompleteCampus &&
      fileTypeSelected &&
      course &&
      period &&
      year &&
      fileTypeSelected
    );
  }, [
    selectedUniversity,
    campi.length,
    selectedCampus,
    course,
    period,
    year,
    fileTypeSelected,
    shouldShowJustFileType,
  ]);

  const validateForm = useCallback(() => {
    let error = '';
    if (shouldShowJustFileType && fileTypeSelected) return true;

    if (!selectedUniversity) {
      error = 'Você precisa selecionar uma universidade';
    }

    if (campi.length > 0 && !selectedCampus) {
      error = 'Você precisa selecionar um campus';
    }

    if (error) {
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
    return (
      !error && fileTypeSelected && course && period && year && fileTypeSelected
    );
  }, [
    course,
    period,
    year,
    fileTypeSelected,
    setErrorMessage,
    selectedUniversity,
    selectedCampus,
    campi.length,
    shouldShowJustFileType,
  ]);

  const handleSubmit = useCallback(async () => {
    if (!validateForm()) return;
    if (shouldShowJustFileType) {
      setMaterialType(fileTypeSelected);
      onSubmitInfo();
      trackEvent('Listas: Informou Tipo de Material', {
        'Tipo de Material': fileTypeSelected,
      });

      return;
    }
    setSaving(true);

    const params = {
      university_id: selectedUniversity?.value,
      university_campus_id: selectedCampus?.value,
      course_id: course?.id,
      year: parseInt(year?.label),
      period: period?.label,
    };

    try {
      await sendUserInfo(params);

      setMaterialType(fileTypeSelected);
      setCourseInfo({ course, period, year });
      setUpdatedUniversity(selectedUniversity);
      onSubmitInfo();

      trackEvent('Informou Dados de Qualificação', {
        Local: 'Envio de Material',
        'Tipo de Material': fileTypeSelected,
        Curso: course?.name,
        'Período de Ingresso': period?.label,
        'Ano de Ingresso': year?.label,
      });
    } catch (e) {
      setErrorMessage('Erro ao enviar as informações. Tente novamente');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    } finally {
      setSaving(false);
    }
  }, [
    course,
    fileTypeSelected,
    onSubmitInfo,
    period,
    selectedCampus?.value,
    selectedUniversity,
    sendUserInfo,
    setCourseInfo,
    setMaterialType,
    setUpdatedUniversity,
    shouldShowJustFileType,
    trackEvent,
    validateForm,
    year,
    setErrorMessage,
  ]);

  return (
    <>
      <Title>
        <h1> Ajude o Responde Aí a ter mais materiais da sua faculdade </h1>
        <h3>
          Ao enviar provas anteriores e listas que seu professor passou para
          estudar,<br></br> <strong>após análise, podemos resolver</strong> e
          ajudar você e muitos outros alunos do seu curso
        </h3>
      </Title>
      <StepsContainer>
        <span className={'fill'}></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </StepsContainer>
      <FormContainer>
        {loading ? (
          <Loading></Loading>
        ) : (
          <>
            {errorMessage && (
              <ErrorContainer>
                <span>{errorMessage}</span>
              </ErrorContainer>
            )}
            {!shouldShowJustFileType && (
              <>
                <UniversitySelectorContainer>
                  <UniversitySelects
                    onChangeSelectedUniversity={setSelectedUniversity}
                    onChangeSelectedCampus={setSelectedCampus}
                    onChangeCampi={setCampi}
                    noUniversitySelected={false}
                    updatedUniversity={updatedUniversity}
                  />
                </UniversitySelectorContainer>
                <SelectContainer
                  options={courses}
                  onInputChange={e => setInputValue(e)}
                  onChange={e => setCourse(e)}
                  placeholder={'Digite o nome do seu curso'}
                  components={selectCustomComponentsWithoutDropdown}
                  styles={selectCustomStyles}
                  value={course}
                  menuIsOpen={inputValue.length > 2}
                  isDisabled={courses.length === 0}
                  firstOne
                />
                <SelectContainer
                  options={yearsList}
                  onChange={e => setYear(e)}
                  placeholder={'Ano de Ingresso'}
                  components={selectCustomComponents}
                  styles={selectCustomStyles}
                  value={year}
                  dropDown
                />
                <SelectContainer
                  options={periodsList}
                  onChange={e => setPeriod(e)}
                  placeholder={'Período de Ingresso'}
                  components={selectCustomComponents}
                  styles={selectCustomStyles}
                  value={period}
                  dropDown
                />
              </>
            )}
            <CheckboxesContainer>
              <span>Qual é o tipo de material que quer enviar?</span>
              {fileTypeOptions.map(type => {
                return (
                  <CheckboxContainer
                    key={type}
                    onClick={() => setFileTypeSelected(type)}
                  >
                    {fileTypeSelected === type ? (
                      <CheckedIcon />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )}
                    <span>{type}</span>
                  </CheckboxContainer>
                );
              })}
            </CheckboxesContainer>
            <Button
              onClick={handleSubmit}
              canSubmit={canSubmit}
              saving={saving}
            >
              {saving ? <BeatLoader size={10} color="#fff" /> : 'ENVIAR'}
            </Button>
          </>
        )}
      </FormContainer>
    </>
  );
}

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: start;
  width: 90%;
  padding: 15px 0;

  > span {
    margin-bottom: 10px;
    font-weight: 600;
  }
`;

export const Button = styled.button`
  width: 90%;
  border: 1px solid ${({ canSubmit }) => (canSubmit ? '#f6ac3a' : '#B8B8B8')};
  border-radius: 10px;

  color: white;
  background-color: ${({ canSubmit }) => (canSubmit ? '#f6ac3a' : '#B8B8B8')};
  margin: 20px 0px;
  padding: 15px;

  font-weight: bold;
  font-size: 1.1em;

  cursor: ${({ saving }) => (saving ? 'not-allowed' : 'pointer')};
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 6px;

  span {
    margin-left: 5px;
    text-align: start;
  }
`;
const CheckedIcon = styled(RadioButtonCheckedIcon)`
  color: #f6ac3a;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-top: 10px solid #f6ac3a;
  border-radius: 10px;
  padding: 35px 10px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  color: #7a7a7a;
  background-color: white;
  align-self: center;
  position: sticky;
  z-index: 1;

  width: 80%;
  max-width: 600px;

  @media ${media.mobile} {
    padding: 30px;
    width: 95%;
  }
`;

const Title = styled.div`
  color: #888;
  font-weight: 100;
  text-align: center;

  h1 {
    font-size: 2.5em;

    @media ${media.mobile} {
      font-size: 2em;
    }
  }

  h3 {
    font-size: 1.1em;
    margin-top: 10px;
    line-height: 20px;

    @media ${media.mobile} {
      font-size: 1em;
      padding: 0px 10px;
    }
  }
`;

export const ErrorContainer = styled.div`
  align-self: flex-end;
  z-index: 10;
  background: red;
  color: white;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  margin: -20px;
`;
