import React, { useCallback } from 'react';
import { TbArrowsDiagonal, TbArrowsDiagonalMinimize2 } from 'react-icons/tb';
import { styled } from 'styles';

const FullScreenControl = ({ enterFullScreen, fullScreen, exitFullScreen }) => {
  const toggleFullScreen = useCallback(() => {
    fullScreen ? exitFullScreen() : enterFullScreen();
  }, [fullScreen, exitFullScreen, enterFullScreen]);

  return (
    <FullScreenController onClick={toggleFullScreen}>
      {fullScreen ? (
        <TbArrowsDiagonalMinimize2 className="icon" />
      ) : (
        <TbArrowsDiagonal className="icon" />
      )}
    </FullScreenController>
  );
};

const FullScreenController = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  cursor: pointer;
  &:hover {
    .icon {
      color: #666;
    }
  }
`;

export default FullScreenControl;
