import React, { useEffect, useMemo } from 'react';
import { styled, media } from 'styles';
import { pricings } from './data/pricings';
import pagarMeImage from './images/pagarme.svg';
import { Form } from './Form';
import { Title } from './PlanChoiceStep';
import { useCheckoutDialogTracker } from './hooks/useCheckoutDialogTracker';

export function CreditCardFormStep({ pricingId, setStep, eventProps }) {
  const { trackViewCheckoutForm } = useCheckoutDialogTracker(eventProps);

  const selectedPricing = useMemo(() => {
    return pricings.find(p => p.id === pricingId);
  }, [pricingId]);

  useEffect(() => {
    trackViewCheckoutForm();
  }, [trackViewCheckoutForm]);

  return (
    <>
      <Title>Finalize sua Assinatura do Responde Aí</Title>
      <SubTitle>E tenha acesso completo a todas as listas resolvidas</SubTitle>
      <InfoContainer>
        <PlanContainer>
          <Info>
            <span className="name"> Plano {selectedPricing?.plan.name}</span>
            <span className="back" onClick={() => setStep(1)}>
              Alterar Plano
            </span>
          </Info>
          <Price> R$ {selectedPricing?.monthlyPrice.toFixed(2)}/mês</Price>
          <p>Acesso ilimitado ao RespondeAí por 1 mês</p>
        </PlanContainer>
        <img src={pagarMeImage} alt="Pagarme" />
      </InfoContainer>
      <Form
        selectedPricing={selectedPricing}
        setStep={setStep}
        eventProps={eventProps}
      />
    </>
  );
}

const SubTitle = styled.p`
  font-size: 1.1rem;
  margin-top: -25px;

  @media ${media.mobile} {
    font-size: 0.85rem;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  margin-top: 15px;

  img {
    width: 160px;
    height: 90px;
  }
`;

const PlanContainer = styled.div`
  height: 90px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${media.mobile} {
    p {
      font-size: 0.8rem;
    }
  }
`;

const Info = styled.div`
  .name {
    text-transform: uppercase;
    font-size: 0.9rem;
    margin-right: 10px;
  }

  .back {
    color: #f7ac3d;
    font-size: 0.8rem;
    cursor: pointer;
    text-decoration: underline;
  }

  @media ${media.mobile} {
    .name {
      font-size: 0.7rem;
    }

    .back {
      font-size: 0.65rem;
    }
  }
`;

const Price = styled.div`
  font-size: 1.7rem;
  font-weight: bold;
  margin: 10px 0;

  @media ${media.mobile} {
    font-size: 1rem;
  }
`;
