import React from 'react';
import { Route } from 'react-router-dom';

import { SummariesPage, SummaryPage } from './pages';

export default [
  <Route
    exact
    path="/resumoes-gratis"
    component={SummariesPage}
    key="resumoes-gratis"
  />,
  <Route
    exact
    path="/resumoes-gratis/:subjectId"
    component={SummaryPage}
    key="resumoes-gratis/id"
  />,
];
