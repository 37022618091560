import useSwrQueryGenerator from './useSwrQueryGenerator';

const baseUrl = `${process.env.REACT_APP_RA_CONTENT_URL}/api/v2`;

export default function useContentQueryImmutable(
  url,
  params = {},
  swrConfig = {},
  recaptcha = null
) {
  const queryParams = new URLSearchParams(params).toString();
  const completeUrl = `${baseUrl}${url}${queryParams && `?${queryParams}`}`;
  return useSwrQueryGenerator(
    completeUrl,
    {
      ...swrConfig,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    },
    recaptcha
  );
}
