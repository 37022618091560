import React from 'react';
import styled from 'styled-components';
import { lectureIc } from '../images';

export default function TitleWithImage() {
  return (
    <Container>
      <ImageContainer>
        <LectureIcImg src={lectureIc} />
        <TitleContainer>
          <Title>AULÕES</Title>
          <Subtitle>SALVA-VIDAS</Subtitle>
        </TitleContainer>
      </ImageContainer>
      <Text>
        Saia do ZERO para a APROVAÇÃO em TEMPO RECORDE com aulões daqueles de
        salvar a vida!
      </Text>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  color: #ff573a;
`;
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LectureIcImg = styled.img`
  margin-right: 8px;
  margin-top: 5px;
  width: 100px;
  height: 67px;
`;

const TitleContainer = styled.div`
  font-weight: 700;
  text-shadow: 2px 1px 0px #8d3c26;
  transform: skewX(-5deg);
`;

const Title = styled.p`
  transform: scale(1, 1.12);
  font-size: 1.9em;
`;

const Subtitle = styled.p`
  transform: scale(1, 1.1);
  font-size: 1.2em;
`;

const Text = styled.span`
  margin: 23px auto 30px auto;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
`;
