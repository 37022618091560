import React, { useCallback, useEffect } from 'react';
import { styled, colors } from 'styles';
import { MdOutlineUploadFile } from 'react-icons/md';
import UploadAreaListWithStatement from './UploadAreaListWithStatement';
import { useListWithStatementTracker } from 'modules/mvp/hooks/useListWithStatementTracker';
import { useUploadDialogContext } from '../providers/UploadDialogProvider';
import { useUserContext } from 'providers/UserProvider';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useToast } from 'modules/shared/hooks';

export function UploadListWithStatementStep() {
  const {
    trackViewSendListScren,
    trackClickSendListWithStatement,
    trackSendError,
  } = useListWithStatementTracker();
  const { user } = useUserContext();
  const { sendPostFileRequest } = useContentRestRequest();
  const { errorToast } = useToast();

  const {
    file,
    setFile,
    selectedTheme,
    professor,
    exam,
    setLoading,
    setStep,
    selectedDiscipline,
    setListLink,
    setFoundPercentage,
  } = useUploadDialogContext();

  const sendListWithStatementRequest = useCallback(async () => {
    if (!file) return errorToast('Selecione um arquivo');
    setLoading(true);
    setStep('loading');
    trackClickSendListWithStatement({
      fileName: file.name,
      fileType: file.type,
      professor,
      themeName: selectedTheme.name,
      exam,
    });

    try {
      const response = await sendPostFileRequest(
        '/mvp/list-with-statements',
        file,
        {
          professorData: professor,
          userName: user.name,
          isSubscriber: user.subscriber,
          exam,
          disciplineData: selectedDiscipline,
        }
      );

      if (response.error) {
        trackSendError('Erro ao enviar lista');
        errorToast(
          response.error?.message ||
            'Alguma coisa deu errado, tente novamente mais tarde'
        );
        setStep(3);
        setLoading(false);
        return;
      }

      setListLink(
        `${process.env.REACT_APP_REACT_URL}/lista-de-enunciado/${response.data.listId}`
      );
      setFoundPercentage(response.data.foundPercentage);
      setStep(4);
    } catch (e) {
      trackSendError('Erro ao enviar lista');
      errorToast('Alguma coisa deu errado, tente novamente mais tarde');
      setStep(3);
      setLoading(false);
      // TODO: jogar para alguma tela especifica de erro que instrua melhor o usuário?
    }
  }, [
    file,
    errorToast,
    setLoading,
    setStep,
    trackClickSendListWithStatement,
    professor,
    selectedTheme,
    exam,
    setListLink,
    sendPostFileRequest,
    user,
    selectedDiscipline,
    setFoundPercentage,
    trackSendError,
  ]);

  useEffect(() => {
    trackViewSendListScren({
      themeName: selectedTheme.name,
      professor: professor.name,
      exam: exam,
    });
  }, [exam, professor, selectedTheme.name, trackViewSendListScren]);

  return (
    <Container>
      <Title>Agora é a hora de enviar sua lista!</Title>
      {file ? (
        <FileContainer>
          <MdOutlineUploadFile color={colors.darkGray} />
          <p>{file.name}</p>
        </FileContainer>
      ) : (
        <UploadAreaListWithStatement
          exam={exam}
          professor={professor}
          selectedTheme={selectedTheme}
          setFile={setFile}
        />
      )}
      <SendListButton onClick={sendListWithStatementRequest}>
        Enviar Lista
      </SendListButton>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.7rem;
  user-select: none;
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  user-select: none;
  svg {
    font-size: 25px;
  }

  p {
    margin-left: 10px;
  }
`;

const SendListButton = styled.button`
  width: 90%;
  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: white;
  margin-top: 1.5rem;
  padding: 10px 0px;
  border-radius: 15px;
  font-size: 1.1em;
  font-weight: bold;
  border: 1px solid rgb(204, 204, 204);

  &:hover {
    background-color: white;
    border-color: ${colors.mainOrange};
    color: ${colors.mainOrange};
  }
`;
