import React, { useCallback, useEffect, useMemo } from 'react';
import { styled } from 'styles';
import { emitter } from '@marvelapp/react-ab-test';

import { useDialogContext } from 'providers/DialogProvider';
import { NoAccessDialogFormerSubscriber } from 'modules/campaigns/recover-subscriber/former-subscriber-recover/dialogs';
import { useTracker } from 'modules/shared/hooks';
import { useTestAB } from 'modules/shared/hooks';

export default function FormerSubscriberRecoverBanner() {
  emitter.defineVariants('bannerTest', ['A', 'B']);

  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const { getVariantFromTestAB } = useTestAB();

  const bannerVersion = useMemo(() => {
    return getVariantFromTestAB('bannerTest');
  }, [getVariantFromTestAB]);

  useEffect(() => {
    trackEvent('Recuperacao Ex Assinantes: Visualizou Banner', {
      where: 'desktop-main-banner',
      'Teste Version': bannerVersion,
    });
  }, [trackEvent, bannerVersion]);

  const onBannerClick = useCallback(() => {
    setDialog(<NoAccessDialogFormerSubscriber source="desktop-main-banner" />);
  }, [setDialog]);

  const renderVersionA = useCallback(
    () => (
      <ContainerBanner onClick={onBannerClick}>
        <Content>
          <CTA>
            <Title>Opa, parece que sua assinatura acabou</Title>
            <Button>Recupere com uma condição imperdível</Button>
          </CTA>
        </Content>
      </ContainerBanner>
    ),
    [onBannerClick]
  );

  if (bannerVersion == 'A') return renderVersionA();
  else
    return (
      <ContainerBannerB onClick={onBannerClick}>
        <ContentBannerB>
          <BannerBTitle>
            Opa, parece que sua assinatura acabou &#128565;
          </BannerBTitle>
          <BannerBButton>Recupere com uma condição imperdível</BannerBButton>
        </ContentBannerB>
        <RightTriangle />
        <BottomBase />
        <LeftTriangle />
      </ContainerBannerB>
    );
}

// Version A
const ContainerBanner = styled.div`
  width: 100%;
  height: 120px;
  background-color: #e4613c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1100px;
  width: 90%;
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  color: #fff;
  font-weight: bold;
  font-size: 1.8em;
`;

const Button = styled.button`
  margin-top: 5px;
  font-size: 1.2em;
  background-color: #fff;
  width: 400px;
  padding: 10px 0;
  color: #e4613c;
  border-radius: 20px;
`;

// Version B
const ContainerBannerB = styled.div`
  position: relative;
  width: 1000px;
  max-width: 75%;
  height: 110px;
  margin: auto;
  cursor: pointer;
`;

const ContentBannerB = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 90px;
  background-color: #e4613c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 10px auto 0 auto;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const RightTriangle = styled.div`
  position: absolute;
  top: 0px;
  right: -145px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 150px 0 0 150px;
  border-color: transparent transparent transparent #c95432;
`;

const BottomBase = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 30px;
  width: 100%;
  background-color: #c95432;
`;

const LeftTriangle = styled.div`
  position: absolute;
  bottom: 0;
  left: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 0 0 30px;
  border-color: transparent transparent transparent #f9f9f9;
`;

const BannerBTitle = styled.div`
  font-size: 1.6em;
  font-weight: bold;
  color: #fff;
`;

const BannerBButton = styled.div`
  color: #000;
  padding: 10px 15px;
  font-size: 1.2em;
  font-weight: bold;
  background-color: #faff00;
  margin-left: 20px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
