import React, { useCallback, useEffect, useState } from 'react';
import { styled, media, colors } from 'styles';
import {
  faApple,
  faFacebookF,
  faGoogle,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  getAppleAuthUrl,
  getFbAuthUrl,
  getGoogleAuthUrl,
} from 'modules/auth/AuthUrl';
import { useTracker } from 'modules/shared/hooks';
import { useCookies } from 'modules/shared/hooks/useCookies';

export default function LoginButtonsContainer({
  where = 'dialog',
  origin = '',
  config,
}) {
  const [buttons, setButtons] = useState([]);
  const [vertical, setVertical] = useState(false);
  const isHeader = where === 'header';
  const { trackEvent } = useTracker();
  const [, setCookie] = useCookies(['point_of_login', 'test7_apple_login']);

  useEffect(() => {
    if (!isHeader) {
      const userAgent = window?.navigator?.userAgent;
      let device = '';
      if (userAgent) {
        if (userAgent.match(/(tablet|ipad)|(android(?!.*mobile))/i)) {
          device = 'Tablet';
        } else if (userAgent.match(/Mobile/)) {
          device = 'Mobile';
        } else {
          device = 'Desktop';
        }
      }

      trackEvent('Viu Dialog de Login', {
        Plataforma: media.platformName(),
        'Tipo de Plataforma': device,
        'Ponto de Login/Cadastro': origin,
      });
    }
  }, [isHeader, origin, trackEvent]);

  const onClick = useCallback(() => {
    setCookie('point_of_login', origin, {
      domain: process.env.REACT_APP_RESPONDEAI_DOMAIN,
      path: '/',
    });
  }, [setCookie, origin]);

  const dealWithHeaderMobile = useCallback(() => {
    setVertical(false);

    setButtons([
      <GoogleButton key="google" config={config} onClick={onClick} onlyIcon />,
      <FacebookButton key="fb" config={config} onClick={onClick} onlyIcon />,
      <AppleButton key="apple" config={config} onClick={onClick} onlyIcon />,
    ]);
  }, [config, onClick]);

  const dealWithHeaderDesktop = useCallback(() => {
    setVertical(false);
    setButtons([
      <GoogleButton
        key="google"
        config={config}
        onClick={onClick}
        onlyIcon={false}
      />,
      <FacebookButton
        key="fb"
        config={config}
        onClick={onClick}
        onlyIcon={false}
      />,
      <AppleButton
        key="apple"
        config={config}
        onClick={onClick}
        onlyIcon={false}
      />,
    ]);
  }, [config, onClick]);

  const dealWithDialogMobile = useCallback(() => {
    setVertical(!isHeader);

    const onlyIcon = isHeader;

    let buttons = [];

    buttons.push(
      <AppleButton
        key="apple"
        config={config}
        onClick={onClick}
        onlyIcon={onlyIcon}
      />,
      <FacebookButton
        key="fb"
        config={config}
        onClick={onClick}
        onlyIcon={onlyIcon}
      />,
      <GoogleButton
        key="google"
        config={config}
        onClick={onClick}
        onlyIcon={onlyIcon}
      />
    );

    setButtons(buttons);
  }, [config, isHeader, onClick]);

  const dealWithDialogDesktop = useCallback(() => {
    setVertical(false);

    setButtons([
      <GoogleButton
        key="google"
        config={config}
        onClick={onClick}
        onlyIcon={false}
      />,
      <FacebookButton key="fb" config={config} onClick={onClick} onlyIcon />,
      <AppleButton key="apple" config={config} onClick={onClick} onlyIcon />,
    ]);
  }, [config, onClick]);

  useEffect(() => {
    if (media.isMobile()) {
      isHeader ? dealWithHeaderMobile() : dealWithDialogMobile();
      return;
    }

    isHeader ? dealWithHeaderDesktop() : dealWithDialogDesktop();
  }, [
    dealWithDialogDesktop,
    dealWithDialogMobile,
    dealWithHeaderDesktop,
    dealWithHeaderMobile,
    isHeader,
  ]);

  return <Container vertical={!isHeader && vertical}>{buttons}</Container>;
}

function GoogleButton({ onlyIcon, config, onClick }) {
  return (
    <A
      href={getGoogleAuthUrl(config)}
      className="google"
      onlyIcon={onlyIcon}
      onClick={onClick}
    >
      <SNIcon icon={faGoogle} />
      {onlyIcon || <span>Acesse com Google</span>}
    </A>
  );
}

function FacebookButton({ onlyIcon, config, onClick }) {
  return (
    <A
      href={getFbAuthUrl(config)}
      className="facebook"
      onlyIcon={onlyIcon}
      onClick={onClick}
    >
      <SNIcon icon={faFacebookF} />
      {onlyIcon || <span>{'Acesse com Facebook'}</span>}
    </A>
  );
}

function AppleButton({ onlyIcon, config, onClick }) {
  return (
    <A
      href={getAppleAuthUrl(config)}
      className="apple"
      onlyIcon={onlyIcon}
      onClick={onClick}
    >
      <SNIcon icon={faApple} />
      {onlyIcon || <span>{'Acesse com Apple'}</span>}
    </A>
  );
}

function SNIcon({ icon }) {
  return (
    <Icon
      icon={icon}
      style={{
        width: '18px',
        height: '18px',
        marginRight: '10px',
        marginLeft: '10px',
      }}
    />
  );
}

const A = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 3px 2px;
  padding: 0 5px;
  text-align: center;

  width: ${({ onlyIcon }) => (onlyIcon ? '40px' : '250px')};

  height: 40px;

  border-radius: 50px;
  color: #fff;
  text-decoration: none;
  line-height: 40px;
  position: relative;

  &.google {
    background-color: ${colors.googleRed};
  }

  &.facebook {
    background-color: ${colors.facebookBlue};
  }

  &.apple {
    background-color: ${colors.appleBlack};
  }
`;

const Container = styled.div`
  display: flex;

  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  justify-content: center;
  align-items: center;

  @media ${media.mobile} {
    a {
      height: 40px;
      justify-content: center;

      &:first-child {
        margin-right: 5px;
      }
    }
  }
`;
