import { Skeleton } from '@mui/material';
import React from 'react';

import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';
import { styled } from 'styles';

export function Statement({ question }) {
  if (!question)
    return (
      <Container>
        <CustomSkeleton variant="text" width={250} height={40} />
        <CustomSkeleton variant="text" height={200} />
      </Container>
    );

  return (
    <Container>
      <Title>Questão {question.label} da lista</Title>
      <Content text={question.statement} isLatex={true} />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const Title = styled.h1`
  width: 100%;
  color: #6a0d91;
  font-weight: bold;
  font-size: 1.6em;
  text-decoration: underline;
  text-align: left;
`;

const Content = styled(MathjaxContentWithLatex)`
  color: #5d5d5d;
  margin-top: 15px;
`;

const CustomSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    transform: none;
    margin-bottom: 15px;
  }
`;
