import React, { useCallback } from 'react';
import { styled } from 'styles';
import { useParams } from 'react-router-dom';

export default function Chat() {
  const { lectureId } = useParams();

  const matchId = useCallback(lectureId => {
    const hash = {
      39: '92cpc-4e8',
      40: 'vYYVhtoU8',
    };
    return hash[lectureId];
  }, []);

  const chatId = matchId(parseInt(lectureId));

  if (chatId === undefined) return null;

  return (
    <Container>
      <iframe
        title="chat"
        src={`https://deadsimplechat.com/${chatId}`}
        width="320px"
        height="440px"
      ></iframe>
    </Container>
  );
}

const Container = styled.div`
  width: 320px;
  margin: 0 auto;

  @media (max-width: 1165px) {
    margin-top: 20px;
  }
`;
