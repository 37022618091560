import { useMemo } from 'react';

import { useUserContext } from 'providers/UserProvider';

export default function useWorkspaceListExercisePermission() {
  const { user } = useUserContext();

  const currentContentAccess = useMemo(() => {
    return user?.hasAccess;
  }, [user]);
  return { currentContentAccess };
}
