import React, { useCallback, useRef, useState, useEffect } from 'react';
import { styled } from 'styles';
import ReactPlayer from 'react-player';
import PlayerControl from '../Player/PlayerControl';
import { useLectureContext } from '../../providers/LectureProvider';
import useLectureTracker from 'modules/lectures/hooks/useLectureTracker';
import {
  SubscriberOverlay,
  PreventClickOverlay,
  PreventClickPauseOverlay,
  FinishedModuleOverlay,
  LectureLoginDialog,
} from '../index';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { media } from 'styles';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';
import { useLectureKeyboardPlayer } from 'modules/lectures/hooks';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import { BookCreditCardTrialCTADialog } from 'modules/campaigns/book-trial/components';

const LecturePlayer = () => {
  const [mouseMoving, setMouseMoving] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [
    alreadyShowBookCreditCardTrialCTA,
    setAlreadyShowBookCreditCardTrialCTA,
  ] = useState(false);
  const { showBookCreditCardTrialCTA, trialDays } = useBookTrialCampaign();
  const { user } = useUserContext();
  const { setDialog } = useDialogContext();

  const {
    trackPlayLecture,
    trackViewLecture,
    trackPlayModuleToRaEvents,
  } = useLectureTracker();

  const {
    getVideoUrlByIndex,
    getPlayingByIndex,
    videoSpeed,
    volume,
    muted,
    playing,
    setPlaying,
    setProgressPercentage,
    setProgressSeconds,
    lecturesList,
    currentVideoIndex,
    progressPercentage,
    setBlocked,
    neverPlayedLecture,
    setNeverPlayedLecture,
    setModulesAlreadyPlayed,
    modulesAlreadyPlayed,
  } = useLectureContext();

  const iframeRefs = useRef([]);

  useEffect(() => {
    trackViewLecture();
  }, [trackViewLecture]);

  useEffect(() => {
    if (user || progressPercentage < 0.2 || neverPlayedLecture) return;

    setPlaying(false);
    // eslint-disable-next-line custom-rules/set-dialog
    setDialog(<LectureLoginDialog />);
  }, [user, progressPercentage, setDialog, setPlaying, neverPlayedLecture]);

  const seekTo = useCallback(
    (amount, index, seekType = 'fraction') => {
      if (amount < iframeRefs.current[currentVideoIndex].getDuration()) {
        setVideoEnded(false);
      }
      iframeRefs.current[index].seekTo(amount, seekType);
      setPlaying(true);
    },
    [setPlaying, iframeRefs, currentVideoIndex]
  );

  const onMouseMove = useCallback(() => {
    setMouseMoving(true);
    setTimeout(() => setMouseMoving(false), 700);
  }, []);

  const isContentAvailable = useCallback(() => {
    return user?.hasAccess || progressPercentage < 0.2 || neverPlayedLecture;
  }, [progressPercentage, user, neverPlayedLecture]);

  useEffect(() => {
    if (
      showBookCreditCardTrialCTA() &&
      !isContentAvailable() &&
      !alreadyShowBookCreditCardTrialCTA
    ) {
      // TODO: revisar
      // foi necessário fazer isso por conta da dinâmica de renderização do bloqueio do conteúdo. não sabemos se o trial vai continuar em aulão
      // eslint-disable-next-line custom-rules/set-dialog
      setDialog(<BookCreditCardTrialCTADialog source="Aulão" />);
      setAlreadyShowBookCreditCardTrialCTA(true);
      setPlaying(false);
    }
  }, [
    isContentAvailable,
    showBookCreditCardTrialCTA,
    setAlreadyShowBookCreditCardTrialCTA,
    alreadyShowBookCreditCardTrialCTA,
    setDialog,
    setPlaying,
  ]);

  const getOverlayToRender = useCallback(() => {
    if (!isContentAvailable()) {
      setTimeout(() => {
        setPlaying(false);
        setBlocked(true);
      }, 700);
      return (
        <SubscriberOverlay
          isElegibleToCreditCardTrial={showBookCreditCardTrialCTA()}
          trialDays={trialDays}
        />
      );
    }
    if (videoEnded) {
      return <FinishedModuleOverlay seekTo={seekTo} />;
    }
    if (!playing) return <PreventClickPauseOverlay />;
    return <PreventClickOverlay />;
  }, [
    playing,
    videoEnded,
    seekTo,
    setBlocked,
    isContentAvailable,
    showBookCreditCardTrialCTA,
    trialDays,
    setPlaying,
  ]);

  const onPlayVideo = useCallback(
    moduleIndex => {
      const shouldTrackModule = !modulesAlreadyPlayed.includes(moduleIndex);
      if (shouldTrackModule) {
        setModulesAlreadyPlayed([...modulesAlreadyPlayed, moduleIndex]);
        trackPlayModuleToRaEvents(moduleIndex);
      }

      if (neverPlayedLecture) {
        setNeverPlayedLecture(false);
        trackPlayLecture();
        iframeRefs.current[moduleIndex].seekTo(1, 'seconds');
      }
      setPlaying(true);
    },
    [
      modulesAlreadyPlayed,
      neverPlayedLecture,
      setModulesAlreadyPlayed,
      setNeverPlayedLecture,
      setPlaying,
      trackPlayModuleToRaEvents,
      trackPlayLecture,
    ]
  );

  useLectureKeyboardPlayer({ seekTo });
  const handle = useFullScreenHandle();

  const { enter, exit, active } = handle;

  return (
    <LectureOverlay onMouseMove={onMouseMove}>
      <FullScreen handle={handle}>
        <FullScreenContainer className={active ? 'fullScreen' : ''}>
          {lecturesList.map((el, i) => (
            <IframeContainer
              className={i === currentVideoIndex ? 'show' : 'hide'}
              key={i}
            >
              <ReactPlayer
                key={i}
                id="playerId"
                ref={ref => (iframeRefs.current[i] = ref)}
                url={getVideoUrlByIndex(i)}
                width="100%"
                height="100%"
                playing={getPlayingByIndex(i)}
                playbackRate={videoSpeed}
                controls={false}
                volume={volume}
                muted={muted}
                onEnded={() => setVideoEnded(true)}
                onPlay={() => onPlayVideo(i)}
                onPause={() => {
                  setPlaying(false);
                }}
                onProgress={progress => {
                  if (i === currentVideoIndex) {
                    setProgressPercentage(progress?.played);
                    setProgressSeconds(progress?.playedSeconds);
                  }
                }}
              />
            </IframeContainer>
          ))}

          <CustomPlayerControl
            enterFullScreen={enter}
            fullScreen={active}
            exitFullScreen={exit}
            mouseMoving={mouseMoving}
            seekTo={seekTo}
            className={media.isMobile() ? 'mobile' : 'desktop'}
          />

          {getOverlayToRender()}
        </FullScreenContainer>
      </FullScreen>
    </LectureOverlay>
  );
};

const LectureOverlay = styled.div``;

const IframeContainer = styled.div`
  height: 100%;
  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
`;

const CustomPlayerControl = styled(PlayerControl)`
  position: absolute;
  transition: opacity 300ms ease-in-out;
  bottom: 0;
  opacity: ${({ mouseMoving }) => (mouseMoving ? '1' : '0')};
  &:hover {
    opacity: 1;
  }
  &.mobile {
    opacity: 1;
    position: inherit;
  }
`;

const FullScreenContainer = styled.div`
  aspect-ratio: 16/9;
  z-index: 1;
  position: relative;
  &.fullScreen {
    width: 100%;
    height: 100%;
  }
`;

export default LecturePlayer;
