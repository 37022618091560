import React, { useCallback, useEffect, useMemo } from 'react';
import { styled, media } from 'styles';
import { IoCalendarOutline } from 'react-icons/io5';
import { MdUploadFile } from 'react-icons/md';
import ListToggle from './ListToggle';
import { useProfessorTracker } from 'modules/guides/hooks';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { useDialogContext } from 'providers/DialogProvider';
import { FaArrowDownLong } from 'react-icons/fa6';
import { useTooltipContext } from 'providers/TooltipProvider';
import { usePeriod } from 'modules/guides/hooks/usePeriod';

export default function ListSection({
  professor,
  handleScrollToSupportSection,
  mutateProfessorQuery,
}) {
  const { trackClickSendList, trackClickStudySupport } = useProfessorTracker();
  const { setDialog } = useDialogContext();
  const { stopPreview } = useTooltipContext();
  const {
    getListsAndPeriodsGrouped,
    shouldShowPreviousPeriods,
    getPeriodsFromListsOrdered,
    getPeriodName,
    selectedPeriod,
    setSelectedPeriod,
    onPeriodClick,
  } = usePeriod();

  const allLists = useMemo(() => {
    return [...professor.lists, ...professor.statementLists];
  }, [professor]);

  const listsGroupByExamFromSelectedPeriod = useMemo(() => {
    if (!selectedPeriod) return {};

    const groupsOfListsAndPeriods = getListsAndPeriodsGrouped(allLists);
    const listsGroupBySelectedPeriod =
      groupsOfListsAndPeriods.find(g => g.period.id === selectedPeriod.id)
        ?.lists || null;

    if (!listsGroupBySelectedPeriod) return {};

    const groupedListByExam = listsGroupBySelectedPeriod.reduce((acc, list) => {
      if (!acc[list.exam]) {
        acc[list.exam] = [];
      }
      acc[list.exam].push(list);
      return acc;
    }, {});
    return groupedListByExam;
  }, [allLists, getListsAndPeriodsGrouped, selectedPeriod]);

  const handleSentListClick = useCallback(() => {
    trackClickSendList({
      professorName: professor.name,
      disciplineName: professor.teachable.name,
      lists: [...professor.lists, ...professor.statementLists],
      numberOfLists: [...professor.lists, professor.statementLists].length,
      source: 'Botão fora',
    });

    setDialog(
      <UploadBookListDialog
        defaultThemeId={professor.teachable.theme?.id}
        defaultTeachable={{ id: professor.teachable.id, type: 'Discipline' }}
        defaultProfessor={{
          id: professor.id,
          name: professor.name,
        }}
        utms={{
          utmSource: 'site_upload',
          utmMedium: ' botao_enviar_lista_fora_toggle',
          utmContent: 'pag_prof_elegivel',
        }}
      />
    );
  }, [professor, setDialog, trackClickSendList]);

  const isThereAnyFavoriteList = useCallback(lists => {
    if (!lists) return false;

    return lists.some(list => list.userPreferences[0]?.isFavorite);
  }, []);

  useEffect(() => {
    if (!professor) return;

    setSelectedPeriod(professor.currentPeriod);
  }, [professor, setSelectedPeriod]);

  return (
    <Container>
      <Title>
        LISTAS DE EXERCÍCIOS <br />
        RESOLVIDOS PASSO A PASSO
      </Title>
      <StudySupportLink
        onClick={() => {
          trackClickStudySupport({
            professorName: professor.name,
            disciplineName: professor.teachable.name,
            lists: [...professor.lists, ...professor.statementLists],
          });
          handleScrollToSupportSection();
        }}
      >
        <span>
          Ver <b>Livros</b>, <b>Aulões</b> e Explicações da <b>Matéria</b>
        </span>
        <div className="icon">
          <FaArrowDownLong size="18px" />
        </div>
      </StudySupportLink>

      <ListsContainer onScroll={stopPreview}>
        <CurrentPeriodSection>
          <CalenderIcon />
          <Period>{`Período ${getPeriodName(professor.currentPeriod)}`}</Period>
          <SendListButton onClick={() => handleSentListClick()}>
            <UploadIcon />
            Enviar Lista
          </SendListButton>
        </CurrentPeriodSection>

        <ListToggle
          examName={'Primeira Prova'}
          professor={professor}
          lists={listsGroupByExamFromSelectedPeriod['Primeira prova'] || []}
          examGrouped="Primeira prova"
          opened={isThereAnyFavoriteList(
            listsGroupByExamFromSelectedPeriod['Primeira prova']
          )}
          mutateProfessorQuery={mutateProfessorQuery}
        />
        <ListToggle
          examName={'Segunda Prova'}
          professor={professor}
          lists={listsGroupByExamFromSelectedPeriod['Segunda prova'] || []}
          examGrouped="Segunda prova"
          opened={isThereAnyFavoriteList(
            listsGroupByExamFromSelectedPeriod['Segunda prova']
          )}
          mutateProfessorQuery={mutateProfessorQuery}
        />
        <ListToggle
          examName={'Terceira Prova'}
          professor={professor}
          lists={listsGroupByExamFromSelectedPeriod['Terceira prova'] || []}
          examGrouped="Terceira prova"
          opened={isThereAnyFavoriteList(
            listsGroupByExamFromSelectedPeriod['Terceira prova']
          )}
          mutateProfessorQuery={mutateProfessorQuery}
        />
        <ListToggle
          examName={'Outras Provas'}
          professor={professor}
          lists={listsGroupByExamFromSelectedPeriod['Outras provas'] || []}
          examGrouped="Outras provas"
          opened={isThereAnyFavoriteList(
            listsGroupByExamFromSelectedPeriod['Outras prova']
          )}
          mutateProfessorQuery={mutateProfessorQuery}
        />

        {shouldShowPreviousPeriods(allLists, professor) && (
          <>
            <PreviousPeriodSection>
              <CalenderIcon />
              <Period>Períodos:</Period>
            </PreviousPeriodSection>
            <Periods>
              {getPeriodsFromListsOrdered(
                allLists,
                professor?.currentPeriod
              ).map(period => {
                return (
                  <PeriodSelector
                    key={period.id}
                    className={
                      selectedPeriod?.id === period?.id ? 'selected' : ''
                    }
                    onClick={() =>
                      onPeriodClick({
                        period,
                        professor,
                        numberLists: allLists?.length,
                      })
                    }
                  >
                    {period.name}
                  </PeriodSelector>
                );
              })}
            </Periods>
          </>
        )}
      </ListsContainer>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  padding: 22px;
  width: 1400px;
  max-width: 95%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  margin-top: -110px;
  margin-bottom: 140px;

  @media ${media.mobile} {
    margin-top: -80px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 30px;
  color: #70bfc7;
  text-align: center;
  @media ${media.mobile} {
    font-size: 20px;
    margin-top: 40px;
  }
`;

const StudySupportLink = styled.div`
  position: absolute;
  color: #fff;
  right: 30px;
  top: 20px;
  text-decoration: underline;
  cursor: pointer;
  background-color: #f7ac3d;
  text-decoration: underline;
  width: 223px;
  height: 60px;
  border-radius: 20px;
  padding: 11px 12px 11px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 10px;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media ${media.mobile} {
    right: 15px;
    top: 14px;
    font-size: 13px;
    width: 193px;
    height: 39px;
    border-radius: 15px;
  }
`;

const ListsContainer = styled.div`
  max-width: 650px;
  padding: 0 50px;
  margin: 0 auto;
  max-height: 500px;
  overflow: overlay; /* Exibe a barra de rolagem sobre o conteúdo */

  @media ${media.mobile} {
    padding: 0 5px;
  }

  /* Estilo para WebKit browsers */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }

  /* Estilo para Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
`;

const CurrentPeriodSection = styled.div`
  display: flex;
  align-items: center;
  margin: 35px 0 5px 0;
  @media ${media.mobile} {
    margin-top: 20px;
  }
`;

const CalenderIcon = styled(IoCalendarOutline)`
  font-size: 17px;
  color: #515151;
  margin-right: 7px;
  @media ${media.mobile} {
    margin-right: 3px;
  }
`;

const Period = styled.p`
  font-size: 21px;
  font-weight: bold;
  color: #515151;

  @media ${media.mobile} {
    font-size: 18px;
  }
`;

const SendListButton = styled.div`
  background-color: #70bfc7;
  padding: 10px 40px;
  border-radius: 10px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media ${media.mobile} {
    padding: 10px 20px;
  }
`;

const UploadIcon = styled(MdUploadFile)`
  font-size: 17px;
  font-weight: lighter;
  color: white;
`;

const PreviousPeriodSection = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0 5px 0;

  @media ${media.mobile} {
    margin-bottom: 10px;
  }
`;

const Periods = styled.div`
  display: flex;
  width: 100%;
  @media ${media.mobile} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const PeriodSelector = styled.div`
  font-size: 18px;
  color: #515151;
  padding: 7px 30px;
  border: 1px solid #70bfc7;
  margin-right: 15px;
  border-radius: 8px;
  cursor: pointer;
  &.selected {
    background-color: #70bfc7;
    color: white;
    text-decoration: underline;
  }

  @media ${media.mobile} {
    padding: 5px 15px;
    font-size: 17px;
    margin-right: 8px;
  }
`;
