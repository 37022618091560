import { subDays } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import Cookies from 'js-cookie';

import { styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import HeartBreakCancelDialog from 'modules/profile/dialogs/cancellation/HeartBreakCancelDialog';
import { useDate } from 'modules/shared/hooks';

export default function CancelBookTrialButton({ subscription }) {
  const { setDialog } = useDialogContext();
  const { getHumanReadableDate } = useDate();

  const isWaitingCancellationConfirmation = useCallback(() => {
    return !!Cookies.get('cancel_subscription_processing');
  }, []);

  const handleCancelSubscriptionPress = useCallback(() => {
    if (isWaitingCancellationConfirmation()) {
      alert(
        'Seu cancelamento já está sendo processado, isso pode levar alguns minutos. Recarregue a página para atualizar o status.'
      );
      return;
    }

    setDialog(<HeartBreakCancelDialog subscription={subscription} />);
  }, [setDialog, subscription, isWaitingCancellationConfirmation]);

  const handleCallSupportPress = useCallback(() => {
    window.UserVoice.show();
  }, []);

  const cancellationLimitDate = useMemo(() => {
    const date = new Date(subscription.expiresIn * 1000);
    return subDays(date, 1);
  }, [subscription]);

  return (
    <Container>
      <button onClick={handleCancelSubscriptionPress}>
        Cancelar seu plano
      </button>

      <button onClick={handleCallSupportPress}>Falar com o atendimento</button>
      <p>
        *Para não ser cobrado, cancele até o dia{' '}
        {getHumanReadableDate(cancellationLimitDate)}
      </p>
    </Container>
  );
}

const Container = styled.div`
  button {
    background-color: #eee;
    font-size: 1em;
    padding: 5px 15px;
    margin-bottom: 7px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    svg {
      margin-right: 5px;
    }
  }

  p {
    font-size: 0.8em;
    font-weight: bold;
  }
`;
