import React, { useCallback, useEffect } from 'react';
import { styled } from 'styles';

import { useTracker } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';
import { media } from 'styles';

export default function PerpetualPlansBannerMobile() {
  const { trackEvent } = useTracker();
  const { user } = useUserContext();

  useEffect(() => {
    trackEvent('Banner perpétuo de planos: Visualizou banner', {
      origem: 'home',
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
    });
  }, [trackEvent, user]);

  const onBannerClick = useCallback(() => {
    trackEvent('Banner perpétuo de planos: Clicou banner', {
      origem: 'home',
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
    });
    window.location.href = `${process.env.REACT_APP_RA_WEB_SSR_URL}/planos?utm_source=banner_plans`;
  }, [trackEvent]);

  const getBannerTitleByUser = useCallback(() => {
    if (user?.university?.acronym === '' || !user?.university) {
      return `Tá com prova essa semana?`;
    }
    return `Tá com prova essa semana ${
      user?.university?.gender === 'F' ? 'na' : 'no'
    } ${user?.university?.acronym}?`;
  }, [user]);

  return (
    <ContainerBanner onClick={onBannerClick}>
      <ContentBanner>
        <BannerBTitle>{getBannerTitleByUser()} &#129300;</BannerBTitle>
        <BannerBButton>A ajuda que você precisa tá aqui</BannerBButton>
        <RightTriangle />
      </ContentBanner>
      <BottomBase />
      <LeftTriangle />
    </ContainerBanner>
  );
}

const ContainerBanner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  cursor: pointer;
`;

const ContentBanner = styled.div`
  position: relative;
  z-index: 2;
  height: 45px;
  width: 96%;
  background-color: #ac0091;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 4px 2px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const RightTriangle = styled.div`
  position: absolute;
  top: 0px;
  right: -147px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 150px 0 0 150px;
  border-color: transparent transparent transparent #80086c;
`;

const BottomBase = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 30px;
  width: 96%;
  background-color: #80086c;
`;

const LeftTriangle = styled.div`
  position: absolute;
  bottom: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 0 0 30px;
  border-color: transparent transparent transparent #f9f9f9;
`;

const BannerBTitle = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  color: #fff;
`;

const BannerBButton = styled.div`
  color: #000;
  padding: 5px 5px;
  font-size: 0.7em;
  font-weight: bold;
  background-color: #faff00;
  margin-left: 20px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
