import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { isMediumScreen } from 'modules/workspace/helpers';
import { CloseOverlay, HamburgerTrigger, MenuSpace, SideMenuContent } from '.';

export default function SideMenu({ children }) {
  const [open, setOpen] = useState(!isMediumScreen());
  const location = useLocation();

  useEffect(() => {
    if (isMediumScreen()) setOpen(false);
  }, [location.pathname]);

  const toggleMenu = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  const showCloseOverlay = useCallback(() => {
    return open && isMediumScreen();
  }, [open]);

  return (
    <>
      <HamburgerTrigger onClick={toggleMenu} open={open} />
      <SideMenuContent open={open}>{children}</SideMenuContent>
      {showCloseOverlay() && <CloseOverlay onClick={toggleMenu} />}
      <MenuSpace open={open} />
    </>
  );
}
