import React, { useEffect } from 'react';

import { ContainerBanner } from './styles';
import { useChangePlanBanner } from '../../hooks';
import { pigImage } from 'modules/banner/images/main-banner/change-plan';

export default function ChangePlan() {
  const { viewBannerEvent, onClickBanner, bannerData } = useChangePlanBanner();

  useEffect(() => {
    viewBannerEvent();
  }, [viewBannerEvent]);

  return (
    <ContainerBanner onClick={onClickBanner}>
      <div className="banner-content">
        <div className="message">
          <span>TROQUE DE PLANO E</span>
          <span className="highlight">{`Economize Até R$${bannerData?.economy}/mês!`}</span>
          <button>Descubra</button>
        </div>
        <div className="pig-container">
          <div className="layer-border-3">
            <div className="layer-border-2">
              <div className="layer-border-1">
                <div className="layer">
                  <img src={pigImage} alt="porquinho" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerBanner>
  );
}
