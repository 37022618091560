import React, { useCallback, useEffect, useMemo } from 'react';
import { styled, colors, media } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { LoginButtonsContainer } from 'modules/shared/components/login';
import { MdClose } from 'react-icons/md';
import { useTracker } from 'modules/shared/hooks';

export function LoginWarningDialog() {
  const { unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();

  const eventProperties = useMemo(() => {
    return {
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Versão: '2.0',
    };
  }, []);

  const closeDialog = useCallback(() => {
    trackEvent('MVP Lista: Clicou Fechar Dialog de Login', eventProperties);
    unsetDialog();
  }, [unsetDialog, trackEvent, eventProperties]);

  useEffect(() => {
    trackEvent('MVP Lista: Visualizou Dialog de Login', eventProperties);
  }, [trackEvent, eventProperties]);

  return (
    <Container onClick={closeDialog}>
      <CloseIcon className="close-icon" />
      <Content color={colors.mainOrange} onClick={e => e.stopPropagation()}>
        <Title>Para enviar sua lista, é só fazer o login!</Title>
        <LoginButtonsContainer where="mvp-login-dialog" />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  padding: 20px 15px 20px;
  background-color: #fff;
  border-top: 10px solid ${({ color }) => color};
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
`;

const CloseIcon = styled(MdClose)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  color: #999;
  cursor: pointer;

  &:hover {
    scale: 1.1;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.7rem;
  user-select: none;
  margin-bottom: 20px;
`;
