import React from 'react';
import SearchInput from '../components/SearchInput';
import { media, styled } from 'styles';
import { BeatLoader } from 'modules/shared/components/generic/loaders';
import { useSearchContext } from '../providers/SearchProvider';
import ResultsContainer from '../components/ResultsContainer';

export default function TextSearchScreen() {
  const { loading } = useSearchContext();

  return (
    <Container>
      <SearchInput />
      {loading ? (
        <BeatLoader size={12} color="#149f8c" />
      ) : (
        <ResultsContainer />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${media.mobile} {
    padding-top: 60px;
  }
`;
