import React from 'react';
import { styled, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { raLogo } from 'images/main';
import { LoginButtonsContainer } from '../components/login';

function LoginDialog({ origin }) {
  const { unsetDialog } = useDialogContext();

  return (
    <Container onClick={unsetDialog}>
      <Content onClick={e => e.stopPropagation()}>
        <Logo src={raLogo} />
        <Paragraph>Esse conteúdo é exclusivo para cadastrados.</Paragraph>
        <Paragraph>
          <Bold>Cadastre-se grátis</Bold> no maior portal de exatas do Brasil!
        </Paragraph>
        <LoginButtonsContainer where="dialog" origin={origin} />

        <Disclaimer>Relaxa, não vamos publicar nada no seu nome.</Disclaimer>
      </Content>
    </Container>
  );
}

export default LoginDialog;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.98);
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Logo = styled.img`
  width: auto;
  height: 45px;
  margin-bottom: 40px;
`;

const Paragraph = styled.p`
  font-size: 1.3em;
  margin-bottom: 30px;
  font-weight: 100;

  &:last-child {
    margin-bottom: 40px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Disclaimer = styled.p`
  color: ${colors.mediumGray};
  padding: 5px;
`;
