import React from 'react';
import styled from 'styled-components';
import { PlayCircleOutline } from '@material-ui/icons';

export default function LiveLectures({ lectures, color }) {
  return (
    <Container color={color}>
      {lectures.map((lecture, index) => (
        <LectureLink href={lecture.url} key={index}>
          <LectureTitleContainer>
            <LectureTitle>Aulão de {lecture.name}</LectureTitle>
            <LectureDate>{lecture.dateWithoutTime}</LectureDate>
          </LectureTitleContainer>
          <PlayIcon />
        </LectureLink>
      ))}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  z-index: 99;
  background-color: ${({ color }) => color};
  padding-bottom: 1px;
`;

const LectureLink = styled.a`
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
  height: 45px;
  margin: 0 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px 0 15px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    svg {
      transform: scale(1.1);
    }
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const LectureTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
  color: #fff;
`;

const PlayIcon = styled(PlayCircleOutline)`
  color: #fff;
  font-weight: lighter;
  font-size: 2.1em !important;
  transition: transform 0.2s ease-in-out !important;
`;

const LectureTitle = styled.span`
  font-size: 1.05em;
`;

const LectureDate = styled.span`
  margin-top: 2px;
  font-size: 0.78em;
`;
