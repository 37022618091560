import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { media } from 'styles';
import ThemesPageDesktop from './ThemesPageDesktop';
import ThemesPageMobile from './ThemesPageMobile';
import { Loading, Error } from 'modules/shared/components/generic';
import { useUniversityContext } from 'providers/UniversityProvider';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import useRenderThemesPageDialog from '../hooks/useRenderThemesPageDialog';
import ProfessorProvider from 'modules/professors/providers/ProfessorProvider';
import { useProfessor } from 'modules/professors/hooks/useProfessor';
import { useHomeProfessorTracker } from '../hooks/useHomeProfessorTracker';

export default function ThemesPage() {
  const { data: themes, loading, error } = useContentQueryImmutable(
    `/themes/with-subjects`
  );
  const {
    data: disciplines,
    loading: disciplineLoading,
    error: disciplineError,
  } = useContentQueryImmutable(`/disciplines`);

  const {
    data: mvpLists,
    loading: mvpListsLoading,
    error: mvpListsError,
  } = useContentQueryImmutable(`/mvp/lists`, {}, { revalidateOnMount: true });

  const {
    data: listsWithStatement,
    loading: listsWithStatementLoading,
    error: listsWithStatementError,
  } = useContentQueryImmutable(
    `/mvp/lists-with-statements`,
    {},
    { revalidateOnMount: true }
  );

  const { data: studyPlans } = useContentQueryImmutable(
    `/study-plans`,
    {},
    { revalidateOnMount: true }
  );

  const {
    data: studyTracks,
    loading: studyTracksLoading,
    error: studyTracksError,
  } = useContentQueryImmutable(`/study-tracks`);

  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();
  const [selectedTheme, setSelectedTheme] = useState(null);
  const { isFromPrivateUniversityCampaign } = useUniversityContext();
  useRenderThemesPageDialog();
  const { showUserProfessors } = useProfessor();

  const { trackHomeAccess } = useHomeProfessorTracker();

  useEffect(() => {
    trackEvent('Visualizou Home', {
      ...getUTMsByLocation(window.location),
      Plataforma: 'Web',
      Versão: showUserProfessors() ? 'Disciplina do Semestre' : 'Default',
    });

    if (showUserProfessors()) {
      trackHomeAccess();
    }
  }, [trackEvent, getUTMsByLocation, showUserProfessors, trackHomeAccess]);

  const shouldMarkThemeAsPrivate = useCallback(
    theme => {
      if (!studyTracks || studyTracks.length === 0) return false;

      theme.studyTracks = studyTracks.filter(
        studyTrack => studyTrack.themeId === theme.id
      );
      const themeHasAtLeastOnePrivateSubject = theme.studyTracks.length > 0;

      return (
        isFromPrivateUniversityCampaign && themeHasAtLeastOnePrivateSubject
      );
    },
    [isFromPrivateUniversityCampaign, studyTracks]
  );

  const generalThemes = useMemo(() => {
    if (!themes) return [];

    return themes.filter(t => !shouldMarkThemeAsPrivate(t));
  }, [themes, shouldMarkThemeAsPrivate]);

  const privateThemes = useMemo(() => {
    if (!themes) return [];

    return themes.filter(t => shouldMarkThemeAsPrivate(t));
  }, [themes, shouldMarkThemeAsPrivate]);

  if (
    error ||
    disciplineError ||
    studyTracksError ||
    mvpListsError ||
    listsWithStatementError
  )
    return <Error />;
  if (
    loading ||
    disciplineLoading ||
    studyTracksLoading ||
    mvpListsLoading ||
    listsWithStatementLoading
  )
    return <Loading />;

  return (
    <ProfessorProvider>
      {media.isMobile() ? (
        <ThemesPageMobile
          privateThemes={privateThemes}
          generalThemes={generalThemes}
          disciplines={disciplines}
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
          studyPlans={studyPlans}
          mvpLists={mvpLists}
          listsWithStatement={listsWithStatement}
        />
      ) : (
        <ThemesPageDesktop
          privateThemes={privateThemes}
          generalThemes={generalThemes}
          disciplines={disciplines}
          studyPlans={studyPlans}
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
          mvpLists={mvpLists}
          listsWithStatement={listsWithStatement}
        />
      )}
    </ProfessorProvider>
  );
}
