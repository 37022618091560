import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { lockIcon } from 'images/shareContainer';
import { useDialogContext } from 'providers/DialogProvider';
import AdvanceStepDialog from '../dialogs/AdvanceStepDialog';
import { Checkbox } from 'modules/shared/components/inputs';
import { useLearningPathTracker } from '../hooks';

const LearningPathDayCard = ({
  day,
  topics,
  locked,
  type,
  remaingTimeForNextDay,
  showTimer,
  theme,
  subject,
  duratrion,
}) => {
  const { setDialog } = useDialogContext();
  const { unsetDialog } = useDialogContext();
  const [checked, setChecked] = useState();
  const {
    trackClickBlockedContent,
    trackClickContent,
  } = useLearningPathTracker();

  const [seconds, setSeconds] = useState(remaingTimeForNextDay);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [seconds]);

  const unLock = useCallback(() => {
    unsetDialog();
  }, [unsetDialog]);

  const handleClickBlockedContent = useCallback(() => {
    trackClickBlockedContent({
      Matéria: theme,
      Assunto: subject,
      'Dia bloqueado': day,
      Duração: duratrion,
    });
    setDialog(<AdvanceStepDialog onUnLock={unLock} />);
  }, [
    day,
    duratrion,
    setDialog,
    subject,
    theme,
    trackClickBlockedContent,
    unLock,
  ]);

  const formatSecondsToTime = useCallback(() => {
    return `${Math.floor(seconds / 3600)}h${Math.floor(
      (seconds % 3600) / 60
    )}m${Math.floor((seconds % 36000) % 60)}s`;
  }, [seconds]);

  const handleLinkClick = useCallback(
    event => {
      if (event.target.tagName === 'A') {
        trackClickContent();
      }
    },
    [trackClickContent]
  );

  return (
    <Container className={type}>
      <CheckBoxContainer>
        <Checkbox size={22} checked={checked} setChecked={setChecked} />
      </CheckBoxContainer>
      {type !== 'single-card' && <Title>{`DIA ${day}`}</Title>}
      {locked && (
        <BlockContent
          onClick={e => {
            e.stopPropagation();
            handleClickBlockedContent();
          }}
        >
          <img src={lockIcon} className="lock-image" />
          {showTimer && (
            <TimerContainer>
              <TimerTitle>Seu conteúdo liberado em</TimerTitle>
              <Timer>{formatSecondsToTime()}</Timer>
            </TimerContainer>
          )}
        </BlockContent>
      )}

      {topics.map((topic, i) => (
        <Step key={i} className={type}>
          <StepTitle>{`Passo ${i + 1}:`}</StepTitle>
          <Topic onClick={handleLinkClick}>
            <div dangerouslySetInnerHTML={{ __html: topic.topic }} />
          </Topic>
          {topic.subtopics.map((sub, i) => (
            <Subtopic onClick={handleLinkClick} key={i}>
              <div dangerouslySetInnerHTML={{ __html: sub.text }} />
            </Subtopic>
          ))}
        </Step>
      ))}
    </Container>
  );
};

const CheckBoxContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  user-select: none;
`;

const TimerContainer = styled.div``;

const Timer = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 14.85px;
  text-align: center;
  font-weight: bold;
  color: #f7ac3b;
`;

const TimerTitle = styled.p`
  font-size: 17px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 400;
  line-height: 14.85px;
  text-align: center;
  font-weight: bold;
  color: #888888;
`;

const Container = styled.div`
  user-select: none;
  width: 250px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 16.41px 0px #00000040;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  position: relative;
  &.big-card {
    padding: 30px;
    width: 500px;
    min-width: 30%;
  }
  &.single-card {
    display: grid;
    width: 900px;
    max-width: 95%;
    grid-template-columns: repeat(
      auto-fit,
      minmax(200px, 1fr)
    ); /* Número de colunas ajustável */
    grid-gap: 10px; /* Espaçamento entre os itens do grid */
  }
`;

const BlockContent = styled.div`
  width: 99%;
  height: 99%;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Cor de fundo com transparência */
  backdrop-filter: blur(3px); /* Efeito de desfoque */
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
`;

const Title = styled.p`
  color: #f7ac3b;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  z-index: 999;
  user-select: none;
  margin-bottom: 20px;
`;

const Step = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  &.single-card {
    max-width: 200px;
  }
  a {
    color: blue;
    text-decoration: underline;
  }
`;

const StepTitle = styled.p`
  color: #888888;
  font-size: 18.36px;
  font-weight: 700;
  line-height: 22.03px;
  margin-bottom: 9px;
  user-select: none;
`;

const Topic = styled.p`
  font-size: 14.68px;
  font-weight: 400;
  line-height: 17.62px;
  margin-bottom: 8px;
  user-select: none;
  a {
    color: #568fe3;
    transition: color 0.3s;
  }
`;

const Subtopic = styled.p`
  font-weight: 400;
  line-height: 17.62px;
  margin-bottom: 8px;
  color: #f3b239;
  user-select: none;
  a {
    color: #c59c52;
    font-weight: bold;
    transition: color 0.3s;
  }
`;

export default LearningPathDayCard;
