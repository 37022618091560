import React, { useState, useEffect, useCallback } from 'react';
import { TextField } from '@material-ui/core';

import { styled } from 'styles';
import CpfMask from 'modules/shared/components/inputs/CpfMask';
import PhoneMask from 'modules/shared/components/inputs/PhoneMask';
import { useUserContext } from 'providers/UserProvider';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { useCheckoutLight } from 'modules/campaigns/checkout-light/hooks';
import { ButtonsContainer, Button } from '.';

export default function UserDataForm({
  afterCompleteUserData,
  backFromUserDataForm,
  initialUserData,
  plan,
}) {
  const { user } = useUserContext();
  const { getUserDataErrors } = useCheckoutLight();
  const [userData, setUserData] = useState(INITIAL_USER_DATA);
  const [userDataError, setUserDataError] = useState(INITIAL_USER_DATA);
  const [alreadyReceiveFormError, setAlreadyReceiveFormError] = useState(false);
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  useEffect(() => {
    trackEvent('Checkout Light: Form Dados Pessoais Visualizou', {
      ...getUTMsByLocation(window.location),
      Plano: plan.description,
      Preco: plan.pricing.price,
    });
  }, [trackEvent, getUTMsByLocation, plan]);

  useEffect(() => {
    if (!alreadyReceiveFormError) return;

    const errors = getUserDataErrors(userData);
    setUserDataError(errors);
  }, [userData, alreadyReceiveFormError, getUserDataErrors]);

  useEffect(() => {
    if (!user) return;

    setUserData(oldUserData => {
      return {
        ...oldUserData,
        name: user.name,
        phone: user.phone || '',
      };
    });
  }, [user]);

  useEffect(() => {
    if (!initialUserData) return;
    setUserData(initialUserData);
  }, [initialUserData]);

  const onInputChange = useCallback(
    (key, value) => {
      setUserData({ ...userData, [key]: value });
    },
    [setUserData, userData]
  );

  const trackFormError = useCallback(
    errors => {
      const keys = Object.keys(errors);
      const eventProperties = { ...getUTMsByLocation(window.location) };
      for (const key of keys) {
        if (!errors[key]) continue;

        eventProperties[key] = errors[key];
      }
      trackEvent('Checkout Light: Form Dados Pessoais Erro', eventProperties);
    },
    [getUTMsByLocation, trackEvent]
  );

  const handleContinuePress = useCallback(() => {
    const errors = getUserDataErrors(userData);
    if (errors.name || errors.cpf || errors.phone) {
      setUserDataError(errors);
      trackFormError(errors);
      setAlreadyReceiveFormError(true);
      return;
    }

    afterCompleteUserData(userData);
  }, [
    getUserDataErrors,
    userData,
    setUserDataError,
    trackFormError,
    afterCompleteUserData,
  ]);

  const handleBackPress = useCallback(() => {
    backFromUserDataForm();
  }, [backFromUserDataForm]);

  return (
    <Container>
      <Title>Informe seus dados</Title>
      <CustomTextField
        label="Nome Completo"
        value={userData.name}
        onChange={e => onInputChange('name', e.target.value)}
        helperText={userDataError.name}
        error={!!userDataError.name}
      />
      <CustomTextField
        label="CPF"
        InputProps={{
          inputComponent: CpfMask,
        }}
        value={userData.cpf}
        onChange={e => onInputChange('cpf', e.target.value)}
        helperText={userDataError.cpf}
        error={!!userDataError.cpf}
      />
      <CustomTextField
        label="Telefone"
        InputProps={{
          inputComponent: PhoneMask,
        }}
        value={userData.phone}
        onChange={e => onInputChange('phone', e.target.value)}
        helperText={userDataError.phone}
        error={!!userDataError.phone}
      />

      <ButtonsContainer>
        <Button className="highlight" onClick={handleContinuePress}>
          Continuar
        </Button>
        <Button className="light" onClick={handleBackPress}>
          Voltar
        </Button>
      </ButtonsContainer>
    </Container>
  );
}

const INITIAL_USER_DATA = {
  name: '',
  cpf: '',
  phone: '',
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 1.2em;
  font-weight: bold;
  color: #000;
  margin: 10px;
`;

const CustomTextField = styled(TextField)`
  margin: 5px 0 !important;
  width: 100%;
  font-weight: bold;
`;
