import React, { useCallback } from 'react';
import { FaBook } from 'react-icons/fa';
import { useDialogContext } from 'providers/DialogProvider';
import { media, styled } from 'styles';
import BuildStudyPlanDialog from 'modules/main/dialogs/BuildStudyPlanDialog';
import { useTracker } from 'modules/shared/hooks';

export default function BuildStudyPlan({ theme, privateThemeColor }) {
  const { trackEvent } = useTracker();
  const { setDialog } = useDialogContext();

  const onClickBuildStudyPlan = useCallback(() => {
    trackEvent('Plano de Estudos: Clicou criar', { Plataforma: 'Web' });
    setDialog(<BuildStudyPlanDialog theme={theme} />);
  }, [trackEvent, setDialog, theme]);

  return (
    <Container
      onClick={onClickBuildStudyPlan}
      theme={theme}
      privateThemeColor={privateThemeColor}
    >
      <div className="icon">
        <span>+</span>
        <FaBook className="rotate" />
      </div>
      <h2>
        Montar Guia <br /> para sua prova
      </h2>
    </Container>
  );
}

const Container = styled.div`
  width: 184px;
  min-width: 120px;
  height: 118px;
  margin: 10px 8px 20px 8px;
  background-color: '#fff';
  /* opacity: 0.15; */
  border-radius: 10px;
  border: ${({ theme, privateThemeColor }) =>
    privateThemeColor ? `2px solid #e2502e` : `2px solid ${theme.color}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
  color: '#888888';
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);

  :hover {
    background-color: ${({ theme, privateThemeColor }) =>
      privateThemeColor ? '#e2502e32' : `${theme.color}32`};
  }

  .icon {
    font-size: 2rem;
    color: ${({ theme, privateThemeColor }) =>
      privateThemeColor ? '#e2502e' : `${theme.color}`};
    /* opacity: unset; */

    .rotate {
      /* transform: rotate(20deg); */
    }

    span {
      font-size: 1.5rem;
      margin-right: 3px;
    }
  }

  h2 {
    margin-top: 22px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8em;
    /* opacity: unset; */
  }

  @media ${media.mobile} {
    height: 170px;
    max-width: 120px;
    justify-content: center;
  }
`;
