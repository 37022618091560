import { useUserContext } from 'providers/UserProvider';
import { useCallback, useMemo } from 'react';

export function useShouldShowUploadFileDialog() {
  const { user } = useUserContext();

  const availableForUniversity = useMemo(() => {
    return (
      user?.university?.id === 338 || //utfpr
      user?.university?.id === 238 || //ufv
      user?.university?.id === 231 || //ufjf
      user?.university?.id === 325 || //ufcg
      user?.university?.id === 665 || //ufscar
      user?.university?.id === 233 || //UFMG
      user?.university?.id === 371 || //UFPE
      user?.university?.id === 639 || //UFSC
      user?.university?.id === 507 || //UFF
      user?.university?.id === 657 || //USP
      user?.university?.id === 130 || //UnB
      user?.university?.id === 508 || //UFRJ
      user?.university?.id === 148 || //UFES
      user?.university?.id === 301 || //UFPA
      user?.university?.id === 324 || //UFPB
      user?.university?.id === 237 || //UFU
      user?.university?.id === 658 || //UNICAMP
      user?.university?.id === 49 || //UFBA
      user?.university?.id === 501 //UERJ
    );
  }, [user]);

  const isElegibleUniversitiesTheme = useCallback(
    theme => {
      const data = {
        338: ['Cálculo', 'Física'],
        238: ['Cálculo', 'Física'],
        231: ['Cálculo', 'Física'],
        325: ['Cálculo', 'Física'],
        665: ['Cálculo', 'Física'],
        233: ['Cálculo', 'Física'], //UFMG
        371: ['Cálculo', 'Física', 'Química'], //UFPE
        639: ['Cálculo', 'Física'],
        507: ['Cálculo', 'Física'],
        657: ['Cálculo', 'Física'],
        130: ['Cálculo', 'Física'],
        508: ['Cálculo', 'Física'],
        148: ['Cálculo', 'Física'],
        301: ['Cálculo', 'Física'],
        324: ['Cálculo', 'Física'],
        237: ['Cálculo', 'Física'],
        658: ['Cálculo', 'Física'],
        49: ['Cálculo', 'Física'],
        501: ['Cálculo', 'Física'],
      };

      const universityId = user?.university?.id;

      if (universityId && data[universityId]?.includes(theme)) return true;

      return false;
    },
    [user]
  );

  const isElegibleToUploadFile = useCallback(
    // eslint-disable-next-line no-unused-vars
    theme => {
      return false;

      /*
      // feature temporariamente desabilitada (não é mais o foco do conteudo)

      const isElegible = isElegibleUniversitiesTheme(theme);

      return user && user.hasAccess && isElegible;
      */
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, isElegibleUniversitiesTheme]
  );

  const isUserElegibleToUploadFile = useCallback(() => {
    return false;
    /*
    // feature temporariamente desabilitada (não é mais o foco do conteudo)

    return user && user.hasAccess && availableForUniversity;
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, availableForUniversity]);

  return {
    isElegibleToUploadFile,
    isUserElegibleToUploadFile,
    isElegibleUniversitiesTheme,
  };
}
