import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { styled } from 'styles';

export default function WorkspaceNavigationButtons({ previous, next }) {
  const hasNextContent = useCallback(() => {
    return !!next?.link;
  }, [next]);

  return (
    <Container data-cy="workspace-navigation-buttons">
      {previous && (
        <PreviousButton to={previous.link || '#'} color={previous.color}>
          <FontAwesomeIcon icon={faAngleLeft} className="left-arrow" />
          Anterior
        </PreviousButton>
      )}

      <NextButton
        color={next?.color}
        to={next?.link || '#'}
        data-cy="workspace-next-button"
      >
        {next?.text}
        {hasNextContent() && (
          <FontAwesomeIcon icon={faAngleRight} className="right-arrow" />
        )}
      </NextButton>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  border-radius: 0 0 5px 5px;
  border-top: 1px solid #ddd;
  line-height: 50px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
`;

const PreviousButton = styled(Link)`
  flex: 1;
  text-align: center;
  color: ${({ color }) => color};

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: ${({ color }) => color};
    color: #fff;
  }

  .left-arrow {
    margin-right: 10px;
    font-size: 1.7rem;
  }
`;

const NextButton = styled(Link)`
  flex: 4;
  text-align: center;
  color: ${({ color }) => color};
  border-left: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: ${({ color }) => color};
    color: #fff;
  }

  .right-arrow {
    margin-left: 10px;
    font-size: 1.7rem;
  }
`;
