import React from 'react';
import { styled } from 'styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';

export default function RedirectTab({ to, textCTA }) {
  const { bookExercise } = useBookEditionContext();

  if (!bookExercise) return null;

  return (
    <BoxExerciseRedirect>
      <ReferencesHeadline>Essa questão é do tópico de:</ReferencesHeadline>
      <References>
        {bookExercise.topic.subject.name} &gt; {bookExercise.topic.name}
      </References>
      <br />
      <CustomLink to={to} target="_blank">
        <Icon icon={faExternalLinkAlt} /> {textCTA}
      </CustomLink>
    </BoxExerciseRedirect>
  );
}

const BoxExerciseRedirect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0;
`;

const ReferencesHeadline = styled.span`
  color: #aaa;
`;

const References = styled.span`
  color: #14b9a6;
  font-weight: bold;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #14b9a6;
  font-size: 0.95em;
  color: #fff;
`;
