import React, { useCallback, useEffect, useState } from 'react';
import { styled, colors, media } from 'styles';
import { raLogoWhiteOrange } from 'images/shared';
import dexterLoveImg from 'images/dexter/feedbacks/dexter-love.png';
import dexterBigSmile from 'images/dexter/feedbacks/dexter-big-smile.png';
import { CampaignLectureLogo } from 'images/campaigns/campaign-lecture';
import ReactPlayer from 'react-player';
import { PlanCard, RaFeatures } from '../components';
import { ClipLoader } from 'modules/shared/components/generic/loaders';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';
import { CampaignLectureLoginDialog } from '../components/index';
import { useTracker } from 'modules/shared/hooks';

import { useParams } from 'react-router-dom';
import { Loading, Error } from 'modules/shared/components/generic';
import {
  useRAQueryImmutable,
  useRAPostRequest,
} from 'modules/shared/hooks/requests';
import dayjs from 'dayjs';

const ProgressThresholdList = {
  firstThreshold: 25,
  secondThreshold: 50,
  thirdThreshold: 75,
};

const CampaignLecturePage = () => {
  const { lectureId } = useParams();

  const [playing, setPlaying] = useState(false);
  const [loadingTrial, setLoadingTrial] = useState(false);
  const [trialSuccess, setTrialSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [userProgressState, setUserProgressState] = useState(false);

  const { trackEvent } = useTracker();
  const { user } = useUserContext();
  const { setDialog } = useDialogContext();

  const { data, loading, error } = useRAQueryImmutable(
    `/campaigns/campaign_lecture/${lectureId}`
  );

  const giveFreePass = useRAPostRequest(
    `/campaigns/campaign_lecture/trial/${lectureId}`
  );

  useEffect(() => {
    if (data?.lecture?.title) {
      trackEvent('Aulão de Campanha: Visualizou Página de Aulão', {
        Nome: data.lecture.title,
        Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      });
    }
  }, [trackEvent, data]);

  useEffect(() => {
    if (progress.played < 0.25) return;
    if (!data?.lecture?.title) return;
    if (userProgressState === ProgressThresholdList.thirdThreshold) return;
    if (progress.played > 0.25 && !userProgressState) {
      setUserProgressState(ProgressThresholdList.firstThreshold);
      trackEvent('Aulão de Campanha: Assistiu Aulão', {
        Nome: data.lecture.title,
        Retenção: `${ProgressThresholdList.firstThreshold}%`,
        Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      });
    }
    if (!userProgressState) return;
    if (
      progress.played > 0.5 &&
      progress.played < 0.75 &&
      userProgressState === ProgressThresholdList.firstThreshold
    ) {
      setUserProgressState(ProgressThresholdList.secondThreshold);
      trackEvent('Aulão de Campanha: Assistiu Aulão', {
        Nome: data.lecture.title,
        Retenção: `${ProgressThresholdList.secondThreshold}%`,
        Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      });
    }
    if (
      progress.played > 0.75 &&
      userProgressState === ProgressThresholdList.secondThreshold
    ) {
      setUserProgressState(ProgressThresholdList.thirdThreshold);
      trackEvent('Aulão de Campanha: Assistiu Aulão', {
        Nome: data.lecture.title,
        Retenção: `${ProgressThresholdList.thirdThreshold}%`,
        Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      });
    }
  }, [progress, trackEvent, data, userProgressState]);

  const handleTrialButtonClick = useCallback(async () => {
    try {
      setLoadingTrial(true);
      await giveFreePass();
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingTrial(false);
      setTrialSuccess(true);
    }
  }, [giveFreePass]);

  const onVideoOverlayClick = useCallback(() => {
    if (!user) {
      setDialog(<CampaignLectureLoginDialog origin="Aulão de Campanha" />);
      return;
    }
    if (playing === false && data?.lecture?.title) {
      trackEvent('Aulão de Campanha: Deu Play', {
        Nome: data.lecture.name,
        Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      });
    }
    setPlaying(!playing);
  }, [setDialog, user, playing, trackEvent, data]);

  if (error) return <Error />;
  if (loading) return <Loading />;

  const { title, videoId } = data.lecture;
  return (
    <Container>
      <CampaginLectureHeader>
        <a href={`/materias`}>
          <HeaderLogo src={raLogoWhiteOrange} />
        </a>
        <HeaderLogo src={CampaignLectureLogo} />
      </CampaginLectureHeader>

      <MainSection>
        <LectureTitle>{`${title}`}</LectureTitle>
        <Video>
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=${videoId}`}
            width="98%"
            height="97%"
            allowFullScreen={true}
            frameBorder="0"
            controls={false}
            playing={playing}
            onProgress={progress => {
              setProgress(progress);
            }}
            config={{
              youtube: {
                playerVars: {
                  color: 'white',
                  modestbranding: 1,
                  rel: 0,
                  enablejsapi: 1,
                },
              },
            }}
          />
          <PlayerOverlay onClick={onVideoOverlayClick} />
        </Video>
      </MainSection>
      {data.hasTrial && !user?.hasAccess ? (
        <TrialSection>
          <TrialBox>
            <TrialTitle>
              {trialSuccess
                ? 'Seu acesso foi liberado !'
                : `Aproveite e ganhe o seu acesso liberado até o dia ${dayjs(
                    data.lecture.endTrialDate
                  ).format('DD/MM')}`}
            </TrialTitle>
            <DexterImage src={trialSuccess ? dexterBigSmile : dexterLoveImg} />
            {!trialSuccess && (
              <TrialButton onClick={handleTrialButtonClick}>
                {loadingTrial ? (
                  <ClipLoader size={22} color="#fff" />
                ) : (
                  <span>{'Clique para liberar o Respondai Grátis !'}</span>
                )}
              </TrialButton>
            )}
            {trialSuccess && (
              <ContentButton href="/materias">
                <span>{'Conheça os nossos conteúdos'}</span>
              </ContentButton>
            )}
          </TrialBox>
        </TrialSection>
      ) : (
        <PlansSection>
          <Title>ASSINE NOSSOS PLANOS:</Title>
          <PlanCard planName={'annual'} />
          <PlanCard planName={'semiannual'} />
          <PlanCard planName={'monthly'} />
        </PlansSection>
      )}

      <Title>Aprenda em MINUTOS o que levaria HORAS com:</Title>
      <RaFeatures />
    </Container>
  );
};

const Container = styled.div`
  background-color: #ebebeb;
`;

const PlayerOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const CampaginLectureHeader = styled.div`
  height: 70px;
  width: 100%;
  background-color: #121212;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10% 0 10%;
`;

const HeaderLogo = styled.img`
  width: 175px;
  cursor: pointer;
  @media ${media.mobile} {
    width: 125px;
  }
`;

const MainSection = styled.div`
  width: 100%;
  padding: 0px 7px 40px 7px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
  background-color: #121212;
`;

const LectureTitle = styled.div`
  font-size: 25px;
  margin-bottom: 25px;
  margin-top: 35px;
  width: 100%;
  text-align: center;
  color: white;
  font-weight: bold;
  z-index: 3;
  @media ${media.mobile} {
    font-size: 20px;
  }
`;

const Video = styled.div`
  width: 980px;
  max-width: 90%;
  aspect-ratio: 16/9;
  z-index: 3;
  background-color: #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media ${media.mobile} {
    width: 100%;
    border-radius: 0px;
  }
`;

const PlansSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const TrialSection = styled.div`
  margin-top: 150px;
  margin-bottom: 70px;
`;

const TrialBox = styled.div`
  width: 740px;
  max-width: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  background-color: white;
  align-items: center;
  border-radius: 25px;
  border: 4px solid ${colors.exercise};
  position: relative;
  flex-wrap: wrap;
  @media ${media.mobile} {
    max-width: 80%;
  }
`;

const TrialTitle = styled.h1`
  font-size: 1.4em;
  width: 100%;
  color: #333;
  text-align: center;
  font-weight: bolder;
  margin: 30px 0 50px 0;
  line-height: 22px;
  @media ${media.mobile} {
    max-width: 80%;
    font-size: 1.1em;
  }
`;

const DexterImage = styled.img`
  width: 95px;
  position: absolute;
  right: -25px;
  top: -53px;
`;

const TrialButton = styled.div`
  color: white;
  font-size: 22px;
  padding: 20px 15px;
  font-weight: bold;
  background-color: ${colors.mainOrange};
  border-radius: 35px;
  margin-bottom: 45px;
  text-align: center;
  min-width: 40%;
  cursor: pointer;
  @media ${media.mobile} {
    max-width: 80%;
    font-size: 18px;
  }
`;

const ContentButton = styled.a`
  color: white;
  font-size: 22px;
  padding: 20px 15px;
  font-weight: bold;
  background-color: ${colors.mainOrange};
  border-radius: 35px;
  margin-bottom: 45px;
  text-align: center;
  min-width: 40%;
  cursor: pointer;
  @media ${media.mobile} {
    max-width: 80%;
    font-size: 18px;
  }
`;

const Title = styled.h1`
  font-size: 1.3em;
  width: 100%;
  color: black;
  text-align: center;
  font-weight: bold;
  margin: 90px 0 30px 0;
`;

export default CampaignLecturePage;
