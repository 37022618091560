import React, { useMemo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { styled } from 'styles';
import { lectureDexter } from 'modules/workspace/images';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import { useUserContext } from 'providers/UserProvider';
import { useWorkspaceGuide } from 'modules/workspace/hooks/shared';
import { useWorkspaceTracker } from 'modules/workspace/hooks/main';
import { useCookies } from 'modules/shared/hooks';
import dayjs from 'dayjs';

const RESPONDEAI_URL = process.env.REACT_APP_RESPONDEAI_URL;

export default function LectureReminder() {
  const { contentSubtitle, lecture } = useWorkspaceContentContext();
  const { isGuideWorkspace } = useWorkspaceGuide();
  const { trackClickLectureButtonsFromWorkspace } = useWorkspaceTracker();
  const [cookies, setCookie] = useCookies([
    'closed-lecture-workspace-reminder',
  ]);
  const { user } = useUserContext();

  const shouldShowLectureReminder = useMemo(() => {
    return (
      !isGuideWorkspace() &&
      lecture &&
      !cookies['closed-lecture-workspace-reminder']
    );
  }, [isGuideWorkspace, lecture, cookies]);

  const lectureLink = useMemo(() => {
    return `${RESPONDEAI_URL}/lecture/aulao/${lecture?.id}`;
  }, [lecture]);

  const onCloseReminder = useCallback(() => {
    const expires = dayjs()
      .add(1, 'month')
      .toDate();
    setCookie('closed-lecture-workspace-reminder', 'true', { expires });
  }, [setCookie]);

  if (!shouldShowLectureReminder || !user) return null;

  return (
    <ToggleableComponent renderDelay={1000} open={true} height="260px">
      <Container>
        <img src={lectureDexter} />
        <Title>{`Com pouco tempo para estudar ${contentSubtitle}?`}</Title>
        <CTA href={lectureLink} onClick={trackClickLectureButtonsFromWorkspace}>
          <span>
            Assistir Aulão de <b>{`${contentSubtitle}`}</b>
          </span>
          <Icon icon={faPlay} />
        </CTA>
        <CancelCTA onClick={onCloseReminder}>Não, valeu!</CancelCTA>
      </Container>
    </ToggleableComponent>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;

  img {
    width: 200px;
    height: 91px;
  }
`;

const Title = styled.p`
  margin: 15px 0;
  color: #666;
`;

const CTA = styled.a`
  height: 42px;
  background-color: #ccc;
  border-radius: 50px;
  color: #666;
  display: block;
  width: 320px;
  margin: 0 auto 12px;
  padding: 5px 10px;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;

  span {
    text-align: center;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.2em;
  margin-left: 10px;
`;

const CancelCTA = styled.p`
  width: 80px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-bottom: 1px solid;
  margin: auto;
  cursor: pointer;
  color: #666;
`;
