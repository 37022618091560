import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';

import { useProfessorTracker } from 'modules/guides/hooks';
import { styled } from 'styles';
import { useSupportMaterialBanner } from 'modules/guides/hooks/useSupportMaterialBanner';

const SLIDER_SETTINGS = {
  speed: 500,
  slidesToShow: 1, // Será sobrescrito pelo `variableWidth`
  slidesToScroll: 1,
  infinite: false, // Desativa o loop
  variableWidth: true, // Permite largura variável dos slides
};

export default function BookSection({ professor, books }) {
  const { trackClickBook } = useProfessorTracker();
  const history = useHistory();
  const { setCookieShouldNotShowBanner } = useSupportMaterialBanner();

  const handleBookClick = useCallback(
    book => {
      setCookieShouldNotShowBanner();
      trackClickBook({
        professorName: professor.name,
        numberOfLists: professor.lists.length + professor.statementLists.length,
        bookName: book.name,
        disciplineName: professor.teachable.name,
        lists: [...professor.lists, ...professor.statementLists],
      });

      history.push(`/livros-resolvidos/livro/${book.id}/edicoes`);
    },
    [professor, trackClickBook, history, setCookieShouldNotShowBanner]
  );

  return (
    <Container>
      <Title>Livros Resolvidos</Title>
      <SubTitle>
        Aqui você encontra os exercícios dos principais livros resolvidos com a
        nossa metodologia.
      </SubTitle>

      <Slider {...SLIDER_SETTINGS}>
        {books.map(book => (
          <Book
            onClick={() => handleBookClick(book)}
            key={book.id}
            src={book.imagePath}
          />
        ))}
      </Slider>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 20px auto 20px auto;
  color: #515151;

  .slick-arrow {
    ::before {
      color: black !important;
      font-size: 25px !important;
    }
  }
`;

const Title = styled.p`
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const SubTitle = styled.p`
  font-size: 17px;
`;

const Book = styled.img`
  width: 120px !important;
  height: 180px;
  margin: 5px;
  margin-top: 10px;
  cursor: pointer;
`;
