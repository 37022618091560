import React from 'react';
import { styled } from 'styles';
import { useLectureContext } from '../../providers/LectureProvider';

const PreventClickPauseOverlay = () => {
  const { playing, setPlaying } = useLectureContext();

  return (
    <>
      <OverlayTop onClick={() => setPlaying(!playing)} />
      <OverlayBottom onClick={() => setPlaying(!playing)} />
    </>
  );
};
const OverlayTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 72%;
`;

const OverlayBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 26%;
`;

export default PreventClickPauseOverlay;
