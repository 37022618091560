import React from 'react';

import styled from 'styled-components';

import { eveLecturesVideoImage } from 'modules/lectures/images';

export default function LiveLecturesCard() {
  return (
    <CardHeader>
      <EveLecturesVideoImage src={eveLecturesVideoImage} />
      <TitleContainer>
        <Title>AULÕES</Title>
        <Subtitle>DE VÉSPERA</Subtitle>
      </TitleContainer>
    </CardHeader>
  );
}

const CardHeader = styled.div`
  display: flex;
  width: 240px;
  margin: 0 35px;
  align-items: center;
  justify-content: center;
  height: 80px;
`;

const EveLecturesVideoImage = styled.img`
  width: 70px;
  height: 80px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding-bottom: 5px;
  margin-left: 15px;
  text-align: center;
  color: #ffac00;
  font-weight: 900;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.6);
  transform: skewX(-5deg) scale(1, 1.12);
  user-select: none;
`;

const Title = styled.p`
  font-size: 2em;
`;

const Subtitle = styled.p`
  font-size: 0.95em;
`;
