import { LoginButtonsContainer } from 'modules/shared/components/login';
import React from 'react';
import styled from 'styled-components';

export default function UnloggedHeader() {
  return (
    <Container>
      <p>Quer ver mais matérias?</p>
      <LoginButtonsContainer where="header" origin="header" />
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 300px;
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  background: #fff;
  z-index: 2;

  p {
    margin-right: 10px;
    color: #666;
    font-weight: bold;
  }
`;
