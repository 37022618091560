import { useCallback } from 'react';
import { useCookies } from './useCookies';

export default function useActivationCounter() {
  const [cookies, setCookie] = useCookies([
    'activation_counter',
    'activation_counter_recommendation_test',
  ]);

  const increaseCookieCounter = useCallback(
    cookieName => {
      const counter = cookies[cookieName];

      let counterToSet = 1;
      if (counter) {
        counterToSet = parseInt(counter) + 1;
      }

      setCookie(cookieName, counterToSet);
    },
    [cookies, setCookie]
  );

  const addActivationToUserUniversityConfirmation = useCallback(() => {
    increaseCookieCounter('activation_counter');
  }, [increaseCookieCounter]);

  const getActivationUniversityConfirmation = useCallback(() => {
    return cookies.activation_counter;
  }, [cookies]);

  const addActivationToUser = useCallback(() => {
    addActivationToUserUniversityConfirmation();
  }, [addActivationToUserUniversityConfirmation]);

  return {
    addActivationToUser,
    getActivationUniversityConfirmation,
  };
}
