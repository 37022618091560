import { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { media } from 'styles';
import { useTracker } from 'modules/shared/hooks';
import { useRALazyQueryImmutable } from 'modules/shared/hooks/requests';

export default function useChangePlanBanner() {
  const history = useHistory();
  const { trackEvent } = useTracker();
  const [bannerData, setBannerData] = useState(null);

  const viewEventName = useMemo(() => 'Troca Plano: Viu Banner', []);
  const clickEventName = useMemo(() => 'Troca Plano: Clicou Banner', []);
  const eventProperties = useMemo(() => {
    return {
      Local: media.isMobile() ? 'Mobile' : 'Desktop',
    };
  }, []);
  const link = useMemo(
    () => '/minha-conta?troca_de_planos=1&utm_source=banner',
    []
  );

  const viewBannerEvent = useCallback(() => {
    trackEvent(viewEventName, eventProperties);
  }, [trackEvent, viewEventName, eventProperties]);

  const onClickBanner = useCallback(() => {
    trackEvent(clickEventName, eventProperties);
    history.push(link);
  }, [trackEvent, clickEventName, eventProperties, link, history]);

  let [
    getChangePlanBannerData,
    { data: changePlanBannerData },
  ] = useRALazyQueryImmutable('/banners/banners_data/change_plan');

  useEffect(() => {
    getChangePlanBannerData();
  }, [getChangePlanBannerData]);

  useEffect(() => {
    if (changePlanBannerData) setBannerData(changePlanBannerData);
  }, [changePlanBannerData]);

  return {
    viewBannerEvent,
    onClickBanner,
    bannerData,
  };
}
