import { styled, colors, media } from 'styles';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  position: relative;
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 80px;
  border-top: 10px solid ${colors.darkGreen};
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

export const Title = styled.h1`
  font-size: 1.3em;
  text-transform: uppercase;
  font-weight: bold;
  margin: 15px 0;
  color: ${colors.darkGreen};
`;

export const Button = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  color: ${colors.darkGreen};
  border: 2px solid ${colors.darkGreen};
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
`;
