import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { styled, media } from 'styles';
import CardInfoField from './CardInfoField';
import { useDate } from 'modules/shared/hooks';

export default function SubscriptionInfoDefault({ subscription }) {
  const { getHumanReadableDateFromTimestamp } = useDate();

  const formattedPaymentMethodsList = useMemo(() => {
    return {
      credit_card: 'Cartão',
      boleto: 'Boleto',
      pix: 'Pix',
      'google-pay': 'Google Pay',
    };
  }, []);

  const isPixSubscription = useCallback(() => {
    return (
      formattedPaymentMethodsList[subscription.order.paymentMethod] === 'Pix'
    );
  }, [formattedPaymentMethodsList, subscription]);

  const formattedPrice = useMemo(() => {
    return `R$${parseFloat(subscription.order.planPricing.price).toFixed(2)}`;
  }, [subscription]);

  const formattedPaymentMethod = useMemo(() => {
    const method =
      formattedPaymentMethodsList[subscription.order.paymentMethod];

    const { installments } = subscription.order;

    if (installments && installments > 1) {
      return `${method} (${installments}x sem juros)`;
    } else {
      return method;
    }
  }, [subscription, formattedPaymentMethodsList]);

  const subscriptionDateLabel = useMemo(() => {
    if (isPixSubscription()) return 'Acaba';
    return subscription.willRenew ? 'Renova' : 'Expira';
  }, [subscription, isPixSubscription]);

  const formattedExpiresIn = useMemo(() => {
    return getHumanReadableDateFromTimestamp(subscription.expiresIn);
  }, [subscription, getHumanReadableDateFromTimestamp]);

  const formattedInfoText = useMemo(() => {
    const expiresIn = formattedExpiresIn;
    if (isPixSubscription())
      return 'Assinaturas via Pix não possuem renovação automática';
    return subscription.willRenew
      ? `
      Sua renovação automática está ativa e prevista para ${expiresIn}, a menos que cancele antes dessa data.
    `
      : `
      Sua renovação automática foi cancelada e seu plano  fica ativo até ${expiresIn}.
    `;
  }, [subscription, formattedExpiresIn, isPixSubscription]);

  return (
    <>
      <CardInfoField fieldId="plan" label="Plano">
        {subscription.plan.description} {formattedPrice}
      </CardInfoField>

      <CardInfoField fieldId="payment" label="Meio de Pagamento">
        {formattedPaymentMethod}
      </CardInfoField>

      <CardInfoField
        fieldId="renovation-date"
        label={`${subscriptionDateLabel} em:`}
      >
        {formattedExpiresIn}
        <Info>
          <FontAwesomeIcon icon={faInfoCircle} />
          <span>{formattedInfoText}</span>
        </Info>
      </CardInfoField>
    </>
  );
}

const Info = styled.div`
  display: inline-block;
  position: relative;

  svg {
    font-size: 0.8em;
    margin-left: 5px;
    cursor: pointer;

    @media ${media.mobile} {
      display: none;
    }
  }

  span {
    visibility: hidden;
    width: 220px;
    background-color: #eee;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 150%;
    color: #666;
    border: 1px solid #bfbfbf;
  }

  &:hover {
    span {
      visibility: visible;
    }
  }
`;
