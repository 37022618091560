import { useCallback, useMemo } from 'react';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import { useUserContext } from 'providers/UserProvider';
import { useWorkspace } from 'modules/workspace/hooks/main';

export default function useWorkspacePermission() {
  const { user } = useUserContext();
  const { isFirstTopic, isSummaryWorkspace } = useWorkspace();
  const { currentChapter, currentTopic } = useWorkspaceContentContext();

  const hasAccessToTopic = useCallback(
    (topic, chapter = null) => {
      if (!chapter) return user?.hasAccess;
      if (isFirstTopic(chapter, topic)) return true;

      return user?.hasAccess;
    },
    [user, isFirstTopic]
  );

  const currentContentAccess = useMemo(() => {
    if (isSummaryWorkspace()) return true;

    if (!user) return false;

    return hasAccessToTopic(currentTopic, currentChapter);
  }, [
    hasAccessToTopic,
    isSummaryWorkspace,
    currentChapter,
    currentTopic,
    user,
  ]);

  return { hasAccessToTopic, currentContentAccess };
}
