import React, { useCallback, useEffect, useState } from 'react';
import { styled, media } from 'styles';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';

import { Error, Loading } from 'modules/shared/components/generic';
import VideoContainer from '../components/VideoContainer';
import SubjectButton from '../components/SubjectButton';
import Chat from '../components/Chat';
import Schedule from '../components/Schedule';
import VipButton from '../components/VipButton';

import {
  calculusImage,
  fisicsImage,
  calculusImageWhite,
  fisicsImageWhite,
} from '../images';
import { useRAQueryImmutable } from 'modules/shared/hooks/requests';

export default function IcbLecture() {
  const { lectureId } = useParams();
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [subjectChosenForSchedule, setSubjectChosenForSchedule] = useState(
    null
  );
  const { user } = useUserContext();
  const origin = encodeURIComponent(window.location.href);
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const getAmplitudeEventProperties = useCallback(() => {
    return {
      Campanha: 'Intensivão do Ciclo Básico',
      Onde: media.isDesktop() ? 'Desktop' : 'Mobile',
      ...getUTMsByLocation(window.location),
    };
  }, [getUTMsByLocation]);

  useEffect(() => {
    if (!user)
      Cookies.set('icb_lecture_url_callback', origin, {
        domain: process.env.REACT_APP_RESPONDEAI_DOMAIN,
      });
    else
      Cookies.remove('icb_lecture_url_callback', {
        domain: process.env.REACT_APP_RESPONDEAI_DOMAIN,
        path: '',
      });
  }, [user, origin]);

  const { data, error, loading } = useRAQueryImmutable(
    `/campaigns/icb/lecture/${lectureId}`
  );

  useEffect(() => {
    trackEvent(
      'Lançamentos: Acessou a LP de Aulão',
      getAmplitudeEventProperties()
    );
  }, [trackEvent, getAmplitudeEventProperties]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  const lecture = data.lecture;
  const scheduledLectures = data.lecture?.relatedLectures;

  return (
    <OutterContainer>
      <ContentContainer>
        <MainContainer isThereTrialBar={user && !user.subscriber}>
          <VideoContainer lecture={lecture} />
          <InfoContent>
            <h4>Busque sua resolução aqui</h4>
            <div className="buttonBox">
              <SubjectButton
                text="Cálculo"
                image={calculusImage}
                isScheduleOpen={isScheduleOpen}
                openSchedule={setIsScheduleOpen}
                subjectChosenForSchedule={subjectChosenForSchedule}
                setSubjectChosenForSchedule={setSubjectChosenForSchedule}
                imageHover={calculusImageWhite}
              />
              <SubjectButton
                text="Física"
                image={fisicsImage}
                isScheduleOpen={isScheduleOpen}
                openSchedule={setIsScheduleOpen}
                subjectChosenForSchedule={subjectChosenForSchedule}
                setSubjectChosenForSchedule={setSubjectChosenForSchedule}
                imageHover={fisicsImageWhite}
              />
            </div>
            {isScheduleOpen ? (
              <Schedule
                scheduledLectures={scheduledLectures}
                chosenSubject={subjectChosenForSchedule}
              />
            ) : (
              <Chat />
            )}
          </InfoContent>
        </MainContainer>
        <VipButton />
      </ContentContainer>
    </OutterContainer>
  );
}

const OutterContainer = styled.div`
  background-color: #333;
  width: calc(100vw - 16px);
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ContentContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0 3rem;

  @media ${media.mobile} {
    padding: 0;
  }
`;

const MainContainer = styled.div`
  background-color: #333;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem 0rem 2rem 0rem;
  flex-wrap: wrap;

  @media ${media.mobile} {
    padding: 1.5rem 0 2rem 0;
    margin-top: ${props => (props.isThereTrialBar ? '0' : '3.4rem')};
  }
`;

const InfoContent = styled.aside`
  width: 310px;
  height: 440px;

  h4 {
    margin-bottom: 1rem;
    font-family: Lato;
    font-weight: bold;
    line-height: 19px;
    color: #ffffff;
    text-align: center;
  }

  .buttonBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  @media ${media.mobile} {
    width: 95%;
    font-size: 17.006px;
    line-height: 20px;
    margin: 0 auto;

    h4 {
      margin-top: 0.8rem;
    }
  }
`;
