import React, { useEffect } from 'react';
import { styled, media } from 'styles';
import dicaprio from '../../../../../../images/purchase/dicaprio.gif';
import { useCheckoutDialogTracker } from './hooks/useCheckoutDialogTracker';

export function SuccessStep({ eventProps }) {
  const { trackSuccessDialogPayment } = useCheckoutDialogTracker(eventProps);

  useEffect(() => {
    trackSuccessDialogPayment();
  }, [trackSuccessDialogPayment]);

  return (
    <>
      <Title>
        Parabéns! Agora você tem todas as listas e todo o conteúdo do Responde
        Aí liberado!!
      </Title>
      <img src={dicaprio} alt="dicaprio" />
      <AdvanceButton
        onClick={() => {
          window.location.reload();
        }}
      >
        Estudar
      </AdvanceButton>
    </>
  );
}

const Title = styled.h1`
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 30px;
`;

const AdvanceButton = styled.div`
  width: 84%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
  color: #f2f2f2;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #f7ac3d;

  @media ${media.mobile} {
    width: 96%;
  }
`;
