import React, { useCallback } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';

import { CardInfoField } from 'modules/profile/components';
import { useDialogContext } from 'providers/DialogProvider';
import EditPersonalInfo from 'modules/profile/dialogs/EditPersonalInfo';

export default function PersonalInfo({ user }) {
  const { setDialog } = useDialogContext();

  const handleEditPersonalInfo = useCallback(() => {
    setDialog(<EditPersonalInfo />);
  }, [setDialog]);

  return (
    <section>
      <div className="sectionTitle">
        <h3>Informações Pessoais</h3>
        <button onClick={handleEditPersonalInfo}>
          <Icon icon={faEdit} /> Editar
        </button>
      </div>
      <CardInfoField fieldId="name" label="Nome">
        {user.name}
      </CardInfoField>

      {user.phone && (
        <CardInfoField fieldId="phone" label="Telefone">
          {user.phone}
        </CardInfoField>
      )}
    </section>
  );
}
