import React, { useCallback, useEffect } from 'react';
import { styled, media, colors } from 'styles';
import { FaSearch } from 'react-icons/fa';
import { IoCamera } from 'react-icons/io5';
import { useDialogContext } from 'providers/DialogProvider';
import SearchDialog from '../dialogs/SearchDialog';
import useSearchAutomaticallyOpen from '../hooks/useSearchAutomaticallyOpen';

export default function SearchCTA({ color = '#f2b138' }) {
  const { setDialog } = useDialogContext();
  const {
    shouldShowOpenSearchDialogAutomaticallyByUtm,
  } = useSearchAutomaticallyOpen();

  const openSearch = useCallback(() => {
    setDialog(<SearchDialog />);
  }, [setDialog]);

  useEffect(() => {
    if (shouldShowOpenSearchDialogAutomaticallyByUtm()) {
      openSearch();
    }
  }, [shouldShowOpenSearchDialogAutomaticallyByUtm, openSearch]);

  return (
    <SearchContainer
      onClick={openSearch}
      color={color}
      data-cy="new-search-CTA"
    >
      <FaSearch className="search-icon" color={color} size={26} />
      <Input
        type="text"
        placeholder="Faça upload da sua questão ou busque por texto"
        readonly={true}
      />
      <IoCamera className="camera-icon" color={color} size={30} />
      <Strip>BETA</Strip>
    </SearchContainer>
  );
}

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  margin-top: 30px;
  z-index: 9;
  cursor: pointer;
  padding: 0 20px 0 13px;
  background-color: #fff;
  border-radius: 50px;
  border: ${({ color }) => `2px solid ${color};`};
  text-align: center;
  color: #888;
  width: 600px;

  font-size: 1.1em;
  font-style: normal;
  text-shadow: none;

  .search-icon {
    font-size: 1.4rem;
    margin-right: 10px;
  }

  .camera-icon {
    font-size: 1.4rem;
    margin-left: 10px;
  }

  @media ${media.mobile} {
    width: 98%;
    margin: 30px auto;
  }
`;

const Input = styled.input`
  border: none;
  background: none;
  width: 100%;
  font-size: 1.1em;
  cursor: pointer;
  color: #888;
  font-family: 'Roboto', sans-serif;
  z-index: 1;
  caret-color: transparent;

  &::placeholder {
    user-select: none;
  }
`;

const Strip = styled.div`
  position: absolute;
  padding: 2px 12px;
  font-size: 0.6em;
  color: #fff;
  background-color: ${colors.lightPurple};
  transform: rotate(30deg);
  top: 4px;
  right: -10px;
`;
