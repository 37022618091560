import React, { useCallback } from 'react';

import { styled } from 'styles';
import { isMediumScreen } from 'modules/workspace/helpers';

export default function MenuSpace({ open }) {
  const showSpace = useCallback(() => {
    return open && !isMediumScreen();
  }, [open]);

  return <Container className={showSpace() ? 'show' : ''} />;
}

const Container = styled.div`
  width: 0;
  height: 100vh;
  transition: width 0.3s ease-in-out;

  &.show {
    width: 300px;
  }
`;
