import { useCallback, useMemo } from 'react';
import Cookies from 'js-cookie';

import { useRAQueryImmutable } from 'modules/shared/hooks/requests';

export default function useChangePlanCampaign() {
  const { data: getChangePlanResponse } = useRAQueryImmutable(
    '/campaigns/change_plan'
  );

  const changePlanData = useMemo(() => {
    return getChangePlanResponse;
  }, [getChangePlanResponse]);

  const isSubscriptionEligibleToChangePlan = useCallback(() => {
    return changePlanData?.eligible;
  }, [changePlanData]);

  const getAvailablePlanPricingsToChange = useCallback(() => {
    return changePlanData?.planPricingsAvailable;
  }, [changePlanData]);

  const calculatePlanEconomy = useCallback((subscription, newPlanPricing) => {
    const currentPrice =
      subscription.order.planPricing.price * newPlanPricing.plan.months;
    return currentPrice - newPlanPricing.price;
  }, []);

  const getCardUsedOnChangePlan = useCallback(() => {
    return changePlanData?.card;
  }, [changePlanData]);

  const isOnChangePlanCampaign = useCallback(() => {
    return changePlanData?.oldOrder;
  }, [changePlanData]);

  const getOldChangePlanOrder = useCallback(() => {
    return changePlanData?.oldOrder;
  }, [changePlanData]);

  const getInstallmentPriceFromPlanPricing = useCallback(
    (planPricing, plan) => {
      const price = planPricing.price;
      const months = plan.months;

      return price / months;
    },
    []
  );

  const openChangePlanDialogAutomaticallyByUrl = useCallback(
    location => {
      const { search } = location;
      const searchParams = new URLSearchParams(search);

      const hasChangePlanQueryParams = !!searchParams.get('troca_de_planos');
      const isEligibleToChangePlan = isSubscriptionEligibleToChangePlan();
      return hasChangePlanQueryParams && isEligibleToChangePlan;
    },
    [isSubscriptionEligibleToChangePlan]
  );

  const openChangePlanDialogAutomaticallyOnOpenPage = useCallback(() => {
    const isEligibleToChangePlan = isSubscriptionEligibleToChangePlan();
    const alreadyOpenDialog = Cookies.get('change_plan');
    return isEligibleToChangePlan && !alreadyOpenDialog;
  }, [isSubscriptionEligibleToChangePlan]);

  return {
    isSubscriptionEligibleToChangePlan,
    getAvailablePlanPricingsToChange,
    calculatePlanEconomy,
    getCardUsedOnChangePlan,
    isOnChangePlanCampaign,
    getOldChangePlanOrder,
    getInstallmentPriceFromPlanPricing,
    openChangePlanDialogAutomaticallyByUrl,
    openChangePlanDialogAutomaticallyOnOpenPage,
  };
}
