import React from 'react';
import { styled } from 'styles';

export default function BeatLoader({ color = '#000000', size = 15 }) {
  return (
    <Container>
      <Bullets iteration={1} color={color} size={size} />
      <Bullets iteration={2} color={color} size={size} />
      <Bullets iteration={3} color={color} size={size} />
    </Container>
  );
}

const Container = styled.span`
  display: inherit;
`;

const Bullets = styled.span`
  display: inline-block;
  background-color: ${({ color }) => color};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin: 2px;
  border-radius: 100%;
  animation: beat 0.7s ${({ iteration }) => (iteration % 2 ? '0s' : '0.35s')}
    infinite linear;

  @keyframes beat {
    50% {
      transform: scale(0.75);
      opacity: 0.2;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;
