import React, { useEffect, useState } from 'react';

import { styled } from 'styles';
import { usePaymentFailedRecoverCampaign } from 'modules/campaigns/recover-subscriber/payment-failed-recover/hooks/PaymentFailedRecoverCampaign';
import { useTracker } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import {
  Container,
  Content,
  Title,
  ButtonsContainer,
  OptionButton,
  Strong,
  ChoosePlan,
  PlanOption,
  PlanDot,
  PlanName,
  PlanDescription,
  PlanStrip,
} from 'modules/campaigns/recover-subscriber/styles';
import { RetryWithSameCardDialog, RetryWithNewCardDialog } from '.';

export default function PaymentFailedRecoverDialog({ source }) {
  const [selectedPricing, setSelectedPricing] = useState();
  const { setDialog, unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const {
    pricings,
    defaultAmplitudeEventAttributes,
  } = usePaymentFailedRecoverCampaign();

  useEffect(() => {
    if (!pricings) return;

    setSelectedPricing(pricings[0]);
  }, [pricings]);

  useEffect(() => {
    trackEvent('MVP Falha de Pagamento 2.0: Visualizou Opcoes', {
      utm_source: source,
      ...defaultAmplitudeEventAttributes,
    });
  }, [source, trackEvent, defaultAmplitudeEventAttributes]);

  if (!pricings) return null;
  return (
    <Container onClick={unsetDialog}>
      <Content onClick={e => e.stopPropagation()}>
        <Title>Falha na renovação de sua assinatura</Title>
        <ChoosePlan>
          {pricings.map(pricing => (
            <PlanOption
              onClick={() => setSelectedPricing(pricing)}
              className={
                selectedPricing &&
                pricing.id === selectedPricing.id &&
                'selected'
              }
              key={pricing.id}
            >
              <PlanDot
                className={
                  selectedPricing &&
                  pricing.id === selectedPricing.id &&
                  'selected'
                }
              />
              <PlanName>{pricing.description}</PlanName>
              <PlanDescription>
                {pricing.months}x de{' '}
                <b>R$ {pricing.monthlyPricing.toFixed(2)}</b>
              </PlanDescription>
              <PlanStrip className="plan-strip">Desconto BLACK</PlanStrip>
            </PlanOption>
          ))}
        </ChoosePlan>
        <FailMessage>
          Houve uma falha de pagamento com seu cartão e por isso{' '}
          <Strong>seu acesso ao conteúdo foi bloqueado.</Strong>
        </FailMessage>
        <FailCTA>
          Como deseja prosseguir para{' '}
          <Strong>efetuar o pagamento e liberar novamente o seu acesso</Strong>{' '}
          ao Responde Aí?
        </FailCTA>
        <ButtonsContainer>
          <OptionButton
            onClick={() =>
              setDialog(
                <RetryWithSameCardDialog
                  source={source}
                  pricing={selectedPricing}
                />
              )
            }
          >
            Pagar com mesmo cartão
          </OptionButton>

          <OptionButton
            onClick={() =>
              setDialog(
                <RetryWithNewCardDialog
                  source={source}
                  pricing={selectedPricing}
                />
              )
            }
          >
            Alterar cartão
          </OptionButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const FailMessage = styled.div`
  margin: 30px auto;
  font-size: 1.15em;
  width: 400px;
  max-width: 100%;
`;

const FailCTA = styled.div`
  margin: 30px auto;
  width: 90%;
  font-size: 1.3em;
`;
