import { useUserContext } from 'providers/UserProvider';
import { useMemo } from 'react';

export function useFormOptions() {
  const { user } = useUserContext();

  const categoryListByUniversityId = useMemo(() => {
    return {
      //utfpr
      338: [
        { label: 'AV1' },
        { label: 'AV2' },
        { label: 'AV3' },
        { label: 'AVF' },
        { label: 'Outro' },
      ],
      //ufv
      238: [
        { label: 'P1' },
        { label: 'P2' },
        { label: 'PF' },
        { label: 'Outro' },
      ],
      //ufjf
      231: [
        { label: 'AV1' },
        { label: 'AV2' },
        { label: 'AV3' },
        { label: 'AVF' },
        { label: 'Outro' },
      ],
      //ufcg
      325: [
        { label: '1º estágio' },
        { label: '2º estágio' },
        { label: '3º estágio' },
        { label: 'Outro' },
      ],
      //ufscar
      665: [
        { label: 'AV1' },
        { label: 'AV2' },
        { label: 'AV3' },
        { label: 'AVF' },
        { label: 'Outro' },
      ],
      //ufmg
      233: [
        { label: 'P1' },
        { label: 'P2' },
        { label: 'P3' },
        { label: 'Outro' },
      ],
      //ufpe
      371: [
        { label: '1º EE' },
        { label: '2º EE' },
        { label: '3º EE' },
        { label: 'PF' },
        { label: 'Outro' },
      ],
      //ufsc
      639: [
        { label: 'P1' },
        { label: 'P2' },
        { label: 'P3' },
        { label: 'PSUB' },
        { label: 'Outro' },
      ],
      //uff
      507: [
        { label: 'P1' },
        { label: 'P2' },
        { label: 'P3' },
        { label: 'VS' },
        { label: 'VR' },
        { label: 'Outro' },
      ],
      //usp
      657: [
        { label: 'P1' },
        { label: 'P2' },
        { label: 'P3' },
        { label: 'PF' },
        { label: 'Outro' },
      ],
      //unb
      130: [
        { label: 'P1' },
        { label: 'P2' },
        { label: 'P3' },
        { label: 'PF' },
        { label: 'Outro' },
      ],
      //ufrj
      508: [
        { label: 'P1' },
        { label: 'P2' },
        { label: 'P3' },
        { label: 'PF' },
        { label: 'Outro' },
      ],
      //ufes
      148: [
        { label: 'P1' },
        { label: 'P2' },
        { label: 'P3' },
        { label: 'PF' },
        { label: 'Outro' },
      ],
      //ufpa
      301: [
        { label: 'P1' },
        { label: 'P2' },
        { label: 'P3' },
        { label: 'Outro' },
      ],
      //ufpb
      324: [
        { label: 'P1' },
        { label: 'P2' },
        { label: 'P3' },
        { label: 'Outro' },
      ],
      //ufu
      237: [
        { label: 'P1' },
        { label: 'P2' },
        { label: 'P3' },
        { label: 'SUB' },
        { label: 'Outro' },
      ],
      //unicamp
      658: [
        { label: 'P1' },
        { label: 'P2' },
        { label: 'P3' },
        { label: 'EXAME' },
        { label: 'Outro' },
      ],
      //ufba
      49: [{ label: 'P1' }, { label: 'Outro' }],
      //uerj
      501: [{ label: 'P1' }, { label: 'Outro' }],
    };
  }, []);

  const categoriesByUniversity = useMemo(
    () => categoryListByUniversityId[user?.university?.id],
    [categoryListByUniversityId, user]
  );

  return { categoriesByUniversity };
}
