import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';

import { styled } from 'styles';

export default function LectureCard({ lecture, color, guideId }) {
  const location = useLocation();
  const formattedDuration = useMemo(() => {
    const durationInSeconds = parseInt(lecture.duration);
    const durationInMinutes = parseInt(durationInSeconds / 60);
    const hours = parseInt(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;

    if (hours > 0) {
      return `${hours}h${minutes}min`;
    }

    return `${minutes}min`;
  }, [lecture.duration]);

  const lectureRedirectUrl = useMemo(() => {
    if (guideId) return `/lecture/${guideId}/aulao/${lecture.id}`;

    return `/lecture/aulao/${lecture.id}`;
  }, [guideId, lecture]);

  return (
    <Container
      to={{
        pathname: lectureRedirectUrl,
        state: { previousUrl: location.pathname },
      }}
    >
      <HiddenName className="hidden-name">{lecture.name}</HiddenName>
      <Name>{lecture.name}</Name>
      <Tint color={color} />
      <BackgroundImage src={lecture.imagePath} color={color} />
      <Play className="play">
        <Icon icon={faPlay} />
      </Play>
      <Duration>{formattedDuration}</Duration>
    </Container>
  );
}

const Container = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  height: 133px;

  margin: 0 17px 17px;

  &:hover {
    .play {
      transform: scale(1.1);
    }

    .hidden-name {
      visibility: visible;
    }
  }
`;

const HiddenName = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: -25px;
  text-align: center;
  width: fit-content;
  margin: auto;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 0.65em;
  border-radius: 5px;
  visibility: hidden;
`;

const Name = styled.div`
  text-transform: uppercase;
  line-height: 1.5em;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
  z-index: 2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
  margin: auto;
`;

const Tint = styled.div`
  position: absolute;
  background-color: ${({ color }) => color};
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  opacity: 0.8;
`;

const BackgroundImage = styled.img`
  background-color: ${({ color }) => color};
  width: 100%;
  height: 100%;
  border-radius: 15px;
`;

const Play = styled.div`
  transition: transform 150ms ease-in;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  z-index: 2;

  border: 3px solid #fff;
  border-radius: 50%;
  padding: 16px 18px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.3em;
`;

const Duration = styled.div`
  position: absolute;
  bottom: 7px;
  right: 10px;
  z-index: 2;
  font-size: 0.85em;
  font-weight: bold;
  line-height: normal;
`;
