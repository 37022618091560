import React from 'react';

import { styled, colors, media } from 'styles';
import { CardTitle } from 'modules/profile/components';

import { Account, Course, PersonalInfo } from './components';

export default function UserProfile({ user }) {
  return (
    <Container>
      <CardTitle title="Seu Perfil" upper />
      <Content>
        <Picture src={user.pictureUrl} alt="" />

        <InfoContainer>
          <PersonalInfo user={user} />

          <Course user={user} />

          <Account user={user} />
        </InfoContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 90%;
  max-width: 720px;
  margin-bottom: 50px;
  padding-bottom: 10px;

  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 15px -2px rgba(51, 51, 51, 0.5);
`;

const Content = styled.div`
  padding: 0 40px 20px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  @media ${media.mobile} {
    padding: 0 10px 20px 10px;
    flex-direction: column;
  }
`;

const Picture = styled.img`
  width: 125px;
  height: auto;
  border-radius: 50%;
  margin-right: 35px;

  @media ${media.mobile} {
    width: 100px;
    height: 100px;
  }
`;

const InfoContainer = styled.div`
  flex: 1;

  section {
    border-bottom: 1px solid ${colors.lightGray};
    margin-bottom: 16px;

    &:last-child {
      border: none;
    }

    .sectionTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      padding: 8px 0;

      h3 {
        font-size: 1.1em;
        font-weight: bold;
      }

      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #ddd;
        border-radius: 5px;
        padding: 8px;
        font-size: 0.9em;

        &:hover {
          background-color: #eee;
        }
      }
    }
  }

  @media ${media.mobile} {
    width: 100%;

    section {
      button {
        font-size: 0.8em;
      }
    }
  }
`;
