import React, { useMemo } from 'react';
import { useRenderDialog } from 'modules/shared/hooks';
import {
  EmailInvolvementDialogB,
  useEmailInvolvement,
} from 'modules/campaigns/email-involvement';
import useSearchFeedback from 'modules/search/hooks/useSearchFeedback';
import SearchFeedbackDialog from 'modules/search/dialogs/SearchFeedbackDialog';

export default function useWorkspaceListRenderDialog() {
  const { showEmailInvolvementDialog } = useEmailInvolvement();
  const { shouldShowSearchFeedbackDialog } = useSearchFeedback();

  const dialogs = useMemo(() => {
    return [
      {
        condition: showEmailInvolvementDialog,
        render: () => <EmailInvolvementDialogB />,
      },
      {
        condition: () => shouldShowSearchFeedbackDialog(),
        render: () => (
          <SearchFeedbackDialog
            source="Questão Workspace"
            text="Você encontrou o que estava procurando?"
          />
        ),
        timeToOpen: 10000,
      },
    ];
  }, [showEmailInvolvementDialog, shouldShowSearchFeedbackDialog]);

  useRenderDialog(dialogs);
}
