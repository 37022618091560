import React, { useState, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { styled, media } from 'styles';
import {
  CreditCardMask,
  CreditCardValidateDateMask,
} from 'modules/shared/components/inputs';

export default function CreditCardForm({
  creditCardData,
  onInputChange,
  creditCardDataError,
}) {
  const [cardFocus, setCardFocus] = useState('');

  const handleInputFocus = useCallback(
    name => {
      setCardFocus(name);
    },
    [setCardFocus]
  );

  return (
    <>
      {media.isDesktop() && (
        <Cards
          cvc={creditCardData.cvv}
          expiry={creditCardData.expiry}
          focused={cardFocus}
          name={creditCardData.holderName}
          number={creditCardData.number}
          placeholders={{ name: 'NOME NO CARTAO' }}
        />
      )}
      <Form>
        <CustomTextField
          label="Número do Cartão"
          InputProps={{
            inputComponent: CreditCardMask,
          }}
          value={creditCardData.number}
          onChange={e => onInputChange('number', e.target.value)}
          onFocus={() => handleInputFocus('number')}
          helperText={creditCardDataError.number}
          error={!!creditCardDataError.number}
        />
        <CustomTextField
          label="Nome no Cartão"
          value={creditCardData.holderName}
          onChange={e => {
            const newName = e.target.value;
            if (newName.length > 64) return;
            if (!newName.match(/^[a-zA-Z ]*$/)) return;

            onInputChange('holderName', e.target.value);
          }}
          onFocus={() => handleInputFocus('name')}
          helperText={creditCardDataError.holderName}
          error={!!creditCardDataError.holderName}
        />
        <CustomTextField
          label="Validade"
          InputProps={{
            inputComponent: CreditCardValidateDateMask,
          }}
          value={creditCardData.expiry}
          onChange={e => onInputChange('expiry', e.target.value)}
          onFocus={() => handleInputFocus('expiry')}
          helperText={creditCardDataError.expiry}
          error={!!creditCardDataError.expiry}
        />
        <CustomTextField
          label="Código de Segurança do Cartão"
          value={creditCardData.cvv}
          onChange={e => {
            const newCvv = e.target.value;
            const newCvvOnlyNumbers = newCvv.replace(/[^\d.-]/g, '');
            if (newCvvOnlyNumbers.length > 4) return;

            onInputChange('cvv', newCvvOnlyNumbers);
          }}
          onFocus={() => handleInputFocus('cvc')}
          helperText={creditCardDataError.cvv}
          error={!!creditCardDataError.cvv}
        />
      </Form>
    </>
  );
}

const Form = styled.div`
  width: 400px;
  max-width: 95%;
  margin: auto;
`;

const CustomTextField = styled(TextField)`
  margin: 5px 0 !important;
  width: 100%;
  font-weight: bold;

  &.select {
    .MuiInput-formControl {
      text-align: left;
    }
  }
`;
