import React, { useCallback, useEffect, useMemo } from 'react';
import { styled, media } from 'styles';

import {
  GuideHeader,
  LecturesCards,
  SubjectsCards,
  CardsContent,
} from '../components';
import { Loading, Error } from 'modules/shared/components/generic';
import { useGuide } from '../hooks';
import { useUserContext } from 'providers/UserProvider';
import {
  useUniversityContext,
  getAcronymByUniversityName,
} from 'providers/UniversityProvider';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import useRenderDisciplinePageDialog from '../hooks/useRenderDisciplinePageDialog';
import { ProfessorListsCards } from '../components/Professor/ListsCards';
import { useProfessorTracker } from '../hooks';

export default function ProfessorWithoutListsPage({
  discipline,
  guideId,
  professor,
  mutateProfessorQuery,
}) {
  const { user } = useUserContext();
  const { universityPronoumn } = useUniversityContext();
  const { trackViewProfessorNotEligiblePage } = useProfessorTracker();

  const { data: guide, loading, error } = useContentQueryImmutable(
    `/guides/${guideId}`
  );

  const trackPageAccess = useCallback(
    discipline => {
      trackViewProfessorNotEligiblePage({
        professorName: professor.name,
        disciplineName: discipline.name,
        lists: [...professor.lists, ...professor.statementLists],
      });
    },
    [professor, trackViewProfessorNotEligiblePage]
  );

  useEffect(() => {
    if (discipline) trackPageAccess(discipline);
  }, [trackPageAccess, discipline]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showLecturesCards = useCallback(lectures => {
    return lectures.length > 0;
  }, []);

  const { getLecturesFromSubjects, disciplineGuidableName } = useGuide();

  const getAcronymDisciplineUniversity = useCallback(() => {
    const acronym = getAcronymByUniversityName(discipline?.university.name);
    return acronym ? acronym : '';
  }, [discipline]);

  const getCopyFromHeader = useCallback(() => {
    if (!discipline) return '';
    if (!user || !user.university) return discipline.name;

    if (
      user.university.acronym === 'Selecione a universidade' ||
      user.university.acronym === 'Não faço faculdade'
    )
      return `${discipline.name} da sua faculdade`;

    if (user.university.id !== discipline.university.id) {
      const acronym = getAcronymDisciplineUniversity();
      return `${discipline.name} ${acronym}`;
    }

    return `${discipline.name} ${universityPronoumn} ${user.university.acronym} (Prof. ${professor.name})`;
  }, [
    user,
    discipline,
    universityPronoumn,
    professor,
    getAcronymDisciplineUniversity,
  ]);

  const getNumColunms = useCallback(
    lectures => {
      let cont = 2;
      if (showLecturesCards(lectures)) cont++;

      return cont;
    },
    [showLecturesCards]
  );

  const lectures = useMemo(() => {
    if (!guide) return [];

    return getLecturesFromSubjects(guide.subjects);
  }, [getLecturesFromSubjects, guide]);

  const shouldAppearBookListsFirst = useMemo(() => {
    if (media.isDesktop()) return false;

    return true;
  }, []);

  const getCards = useCallback(() => {
    if (shouldAppearBookListsFirst) {
      return (
        <>
          <ProfessorListsCards
            professor={professor}
            acronymDisciplineUniversity={getAcronymDisciplineUniversity()}
            utmContent="pag_prof_nao_elegivel"
          />

          <SubjectsCards
            subjects={guide.subjects}
            color={discipline?.theme?.color}
            guideId={guide.id}
            guidableType={disciplineGuidableName}
            name={discipline.name}
            isFree={discipline.isFree}
            shouldHaveFreeTag={false}
          />

          {showLecturesCards(lectures) && (
            <LecturesCards
              lectures={lectures}
              color={discipline?.theme?.color}
              guideId={guide.id}
              shouldHaveFreeTag={false}
            />
          )}
        </>
      );
    }
    return (
      <>
        {showLecturesCards(lectures) && (
          <LecturesCards
            lectures={lectures}
            color={discipline?.theme?.color}
            guideId={guide.id}
            shouldHaveFreeTag={false}
          />
        )}

        <SubjectsCards
          subjects={guide.subjects}
          color={discipline?.theme?.color}
          guideId={guide.id}
          guidableType={disciplineGuidableName}
          name={discipline.name}
          isFree={discipline.isFree}
          shouldHaveFreeTag={false}
        />

        <ProfessorListsCards
          professor={professor}
          acronymDisciplineUniversity={getAcronymDisciplineUniversity()}
          mutateProfessorQuery={mutateProfessorQuery}
          utmContent="pag_prof_nao_elegivel"
        />
      </>
    );
  }, [
    discipline,
    disciplineGuidableName,
    getAcronymDisciplineUniversity,
    guide,
    lectures,
    professor,
    shouldAppearBookListsFirst,
    showLecturesCards,
    mutateProfessorQuery,
  ]);

  useRenderDisciplinePageDialog(discipline);

  if (error) return <Error />;
  if (loading) return <Loading />;

  return (
    <Container>
      <GuideHeader
        name={getCopyFromHeader()}
        color={discipline.theme.color}
        shouldHaveFreeTag={false}
        themeId={discipline.theme.id}
        utmContent={'pag_prof_nao_elegivel'}
      />
      <CardsContent numColumns={getNumColunms(lectures)}>
        {getCards()}
      </CardsContent>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;
