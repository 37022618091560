import { useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import {
  convertObjectToUnderscore,
  convertObjectToCamelcase,
} from 'modules/shared/utils';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_RESPONDEAI_URL}/api/v3`,
  withCredentials: true,
});

export default function useRAPostRequest(url) {
  const sendPostRequest = useCallback(
    async (data = {}, options = {}) => {
      const userJwt = Cookies.get('user_jwt');
      options.headers = {
        ...(options.headers || {}),
        'User-JWT': userJwt,
      };
      const response = await instance.post(
        url,
        convertObjectToUnderscore(data),
        options
      );
      return { data: convertObjectToCamelcase(response.data || {}) };
    },
    [url]
  );

  return sendPostRequest;
}
