import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVideo,
  faCheck,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons';

import { styled, colors, media } from 'styles';
import { useWorkspaceExercise } from 'modules/workspace/hooks/shared';
import { useUTM } from 'modules/shared/hooks';

export default function ExerciseList({
  exercises,
  exercisesLimit,
  getExerciseLink,
  getExerciseName,
}) {
  const { utmParamsWithoutInternalSource } = useUTM();
  const [currentExercisesLimit, setCurrentExercisesLimit] = useState(
    exercisesLimit
  );
  const {
    isCurrentExercise,
    getFlagFromExercise,
    getExerciseColor,
  } = useWorkspaceExercise();

  const exercisesToShow = useMemo(() => {
    if (!currentExercisesLimit) return exercises || [];

    return exercises?.slice(0, currentExercisesLimit) || [];
  }, [exercises, currentExercisesLimit]);

  const showMoreExercisesButton = useMemo(() => {
    if (!currentExercisesLimit) return false;

    return currentExercisesLimit < exercises.length;
  }, [currentExercisesLimit, exercises]);

  const increaseExercisesLimit = useCallback(() => {
    const newExercisesLimit = Math.min(
      currentExercisesLimit + 5,
      exercises.length
    );
    setCurrentExercisesLimit(newExercisesLimit);
  }, [currentExercisesLimit, exercises, setCurrentExercisesLimit]);

  const isCheckedExercise = useCallback(
    exercise => {
      const flag = getFlagFromExercise(exercise);
      return flag?.name === 'check';
    },
    [getFlagFromExercise]
  );

  const isSeeLaterExercise = useCallback(
    exercise => {
      const flag = getFlagFromExercise(exercise);
      return flag?.name === 'see_later';
    },
    [getFlagFromExercise]
  );

  const getLinkWithCorrectUtmParams = useCallback(
    exercise => {
      const link = getExerciseLink(exercise);

      return `${link}${utmParamsWithoutInternalSource}`;
    },
    [getExerciseLink, utmParamsWithoutInternalSource]
  );

  return (
    <Container data-cy="exercise-list">
      {exercisesToShow.map((exercise, index) => (
        <Exercise
          key={exercise.id}
          color={getExerciseColor()}
          className={isCurrentExercise(exercise) ? 'selected' : ''}
          to={getLinkWithCorrectUtmParams(exercise)}
        >
          {isCheckedExercise(exercise) && (
            <ExerciseFlagIconContainer className="check">
              <FontAwesomeIcon icon={faCheck} />
            </ExerciseFlagIconContainer>
          )}
          {isSeeLaterExercise(exercise) && (
            <ExerciseFlagIconContainer className="see_later">
              <FontAwesomeIcon icon={faExclamation} />
            </ExerciseFlagIconContainer>
          )}
          {getExerciseName(exercise, index)}
          {exercise.videos?.length > 0 && <VideoIcon icon={faVideo} />}
        </Exercise>
      ))}
      {showMoreExercisesButton && (
        <MoreExerciseButton
          color={getExerciseColor()}
          onClick={increaseExercisesLimit}
          data-cy="more-exercise-button"
        >
          +
        </MoreExerciseButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 30px;

  @media ${media.mobile} {
    padding: 0 10px;
  }
`;

const roundedButtonLayout = `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-width: 32px;
  background-color: #fff;
  border-radius: 30px;
  margin: 4px 6px;
  padding: 2px 4px;
  cursor: pointer;
`;

const Exercise = styled(Link)`
  position: relative;
  ${roundedButtonLayout}
  border: 2px solid ${({ color }) => color};
  color: ${({ color }) => color};

  &.selected {
    color: #fff;
    background-color: ${({ color }) => color};
  }
`;

const MoreExerciseButton = styled.div`
  ${roundedButtonLayout}
  font-family: monospace;
  font-size: 1.5em;
  font-weight: bold;
  color: #fff;
  background-color: ${({ color }) => color};
`;

const VideoIcon = styled(FontAwesomeIcon)`
  font-size: 0.8em;
  margin-left: 4px;
`;

const ExerciseFlagIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 15px;
  font-size: 0.6em;
  top: -8px;
  right: -8px;
  color: #fff;

  &.check {
    background-color: ${colors.check};
  }

  &.see_later {
    background-color: ${colors.darkGray};
  }
`;
