import React, { useCallback } from 'react';
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { media, styled } from 'styles';
import { changeToVideoImage } from 'modules/workspace/images';
import {
  useWorkspaceContentFormat,
  useWorkspaceVideo,
} from 'modules/workspace/hooks/shared';

export default function ContentFormatToggle({
  content,
  videos,
  currentContentFormat,
  setContentFormat,
  color,
  className,
  videoDuration,
  trackChangeContentFormatMethod,
}) {
  const { setFormatInCookies } = useWorkspaceContentFormat();
  const { getVideoThumbSrc, getDurationInMinutes } = useWorkspaceVideo();

  const getFormatToToggle = useCallback(() => {
    return currentContentFormat === 'video' ? 'texto' : 'video';
  }, [currentContentFormat]);

  const onChangeFormat = useCallback(() => {
    const newFormat = currentContentFormat === 'video' ? 'text' : 'video';
    setContentFormat(newFormat);
    setFormatInCookies(newFormat);
    trackChangeContentFormatMethod(newFormat);
  }, [
    currentContentFormat,
    setContentFormat,
    setFormatInCookies,
    trackChangeContentFormatMethod,
  ]);

  return (
    <Container
      color={color}
      onClick={onChangeFormat}
      className={className}
      data-cy="content-format-toggle-container"
    >
      <p>
        Alternar para {media.isMobile() && <br />}
        {getFormatToToggle()} {'>>'}
      </p>
      <ToggleImageContainer>
        {currentContentFormat === 'text' ? (
          <>
            <ThumbImage imageUrl={getVideoThumbSrc(videos)} />
            <Label>{content?.__typename === 'Theory' && 'T'} </Label>
            <Tint color={color}></Tint>
            <Icon icon={faPlayCircle}></Icon>
            <Duration>{`${getDurationInMinutes(videoDuration)}min`}</Duration>
          </>
        ) : (
          <ChangeToVideoImage src={changeToVideoImage} />
        )}
      </ToggleImageContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: #fafafa;
  padding-left: 30px;
  line-height: 50px;
  color: ${({ color }) => color};
  cursor: pointer;
  font-weight: bold;
  font-size: 1.13rem;
  position: relative;
  margin-bottom: 70px;

  @media ${media.mobile} {
    font-size: 1em;
    line-height: 20px;
    padding: 8px 0 10px 15px;
    margin-bottom: 40px;
  }
`;

const ToggleImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -35px;
  right: 30px;
  width: 220px;
  height: 120px;
  box-shadow: 0px 6px 14px -1px rgb(136 136 136 / 90%);

  @media ${media.mobile} {
    right: 15px;
    top: -23px;
    width: 160px;
    height: 100px;
  }
`;

const ThumbImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: 0 -22px;

  @media ${media.mobile} {
    background-position: 0 -11px;
  }
`;

const Tint = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ color }) => color};
  opacity: 0.85;
`;

const Icon = styled(FontAwesomeIcon)`
  position: relative;
  font-size: 4rem;
  color: #fff;
`;

const Label = styled.div`
  position: absolute;
  bottom: -10px;
  left: 10px;
  z-index: 1;
  color: #fff;
  font-family: 'Droid Serif', serif;

  @media ${media.mobile} {
    bottom: 4px;
  }
`;

const Duration = styled.p`
  position: absolute;
  bottom: -10px;
  right: 10px;
  color: #fff;
  font-size: 0.85em;

  @media ${media.mobile} {
    bottom: 4px;
  }
`;

const ChangeToVideoImage = styled.img`
  width: 100%;
  height: 100%;
`;
