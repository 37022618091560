import React, { useEffect } from 'react';
import { styled } from 'styles';
import { useMathJax } from 'modules/shared/hooks/useMathJax';
import MathJaxProvider from 'providers/MathJaxProvider';

export default function MathjaxContentLatex({ textWithLatex }) {
  return (
    <MathJaxProvider
      url="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js"
      options={{
        tex: {
          inlineMath: [
            ['$', '$'],
            ['\\(', '\\)'],
          ],
          macros: {
            phi: '\\varphi',
          },
          packages: { '[+]': ['text'] },
        },
      }}
    >
      <Container>
        <Formula formula={textWithLatex} />
      </Container>
    </MathJaxProvider>
  );
}

function Formula({ formula }) {
  const [MathJax, updateMathContent] = useMathJax();

  useEffect(() => {
    updateMathContent();
  });

  if (!MathJax) return null;

  return <div>{formula}</div>;
}

const Container = styled.div`
  white-space: pre-line;

  span {
    max-width: 100% !important;
  }
`;
