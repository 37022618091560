import React, { useCallback } from 'react';
import { clockImage } from 'modules/campaigns/default-trial/images';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';
import { media, styled } from 'styles';
import useDefaultTrial from '../hooks/useDefaultTrial';
import { useToast } from 'modules/shared/hooks';

export default function WrapperSecondStep() {
  const { createFreePassReq } = useDefaultTrial();
  const { setToastAfterReload, errorToast } = useToast();

  const onAsk30MinutesTrial = useCallback(async () => {
    try {
      await createFreePassReq();
      setToastAfterReload(
        'Seu período de teste foi ativado com sucesso! Aproveite mais 30 minutos de acesso grátis :)',
        'success'
      );
      window.location.reload();
    } catch (e) {
      errorToast(
        'Houve um erro ao tentar criar seu trial. Por favor, tente novamente, e caso persista o erro entre em contato com o atendimento'
      );
    }
  }, [createFreePassReq, errorToast, setToastAfterReload]);

  return (
    <ToggleableComponent
      width="100%"
      height="500px"
      renderDelay={400}
      animationTime="0.2s"
      open={true}
    >
      <Container>
        <Title>
          Quer ganhar mais <br />
          <b>30 minutos grátis?</b>
        </Title>
        <img src={clockImage} />
        <TrialCTA onClick={onAsk30MinutesTrial}>
          Iniciar meus 30 min grátis
        </TrialCTA>
      </Container>
    </ToggleableComponent>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 130px;
    margin: 40px 0;
  }

  @media ${media.mobile} {
    width: 95%;
    margin: 0 auto;
    padding-top: 20px;
  }
`;

const Title = styled.div`
  font-size: 2em;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const TrialCTA = styled.button`
  width: 300px;
  height: 60px;
  line-height: 60px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 40px;
  cursor: pointer;
  background: #f9ac3e;
  font-size: 17px;
`;
