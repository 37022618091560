import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useUserContext } from 'providers/UserProvider';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import { useWorkspace } from 'modules/workspace/hooks/main';
import { useWorkspaceExercise } from 'modules/workspace/hooks/shared';

export default function useWorkspaceErrorReport() {
  const { user } = useUserContext();
  const {
    currentTheme,
    currentSubject,
    currentChapter,
    currentTopic,
    currentContent,
    currentContentFormat,
    currentFixationExercises,
    currentUniversityExercises,
    currentExtraExercises,
    guide,
    contentTitle,
    contentSubtitle,
  } = useWorkspaceContentContext();
  const {
    isTheoryWorkspace,
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    isExtraExerciseWorkspace,
    isExtraTimePageWorkspace,
    isSummaryWorkspace,
  } = useWorkspace();
  const { getExerciseIndexFromExercises } = useWorkspaceExercise();
  const { summaryId } = useParams();

  const errorReportType = useMemo(() => {
    if (isTheoryWorkspace()) return 'Teoria';
    if (isFixationExerciseWorkspace()) return 'exercício_de_fixação';
    if (
      isUniversityExerciseWorkspace() ||
      isExtraExerciseWorkspace() ||
      isExtraTimePageWorkspace()
    )
      return 'exercício_de_prova';
    if (isSummaryWorkspace()) return 'Resuminho';
  }, [
    isTheoryWorkspace,
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    isExtraExerciseWorkspace,
    isExtraTimePageWorkspace,
    isSummaryWorkspace,
  ]);

  const errorReportGuidableTypes = useMemo(
    () => ({
      Default: 'Default',
      StudyPlans: 'Plano de Estudos',
      Disciplines: 'Guia Personalizado',
      StudyTracks: 'Guia Personalizado (Privadas)',
    }),
    []
  );

  const errorReportLocation = useMemo(() => {
    if (!currentContent) return '';

    const themeName = `${currentTheme.name} - ${currentTheme.id}`;
    const topicName = `${currentTopic?.acronym} - ${currentTopic?.name}`;
    const subjectName = `${currentSubject.name} - ${currentSubject.id}`;
    const disciplineName = `${errorReportGuidableTypes[guide?.guidable] ||
      'Default'} (${contentTitle} - ${contentSubtitle || ''})`;
    const university = user?.university?.acronym;
    const campus = user?.universityCampus?.name || '';
    const contentFormat =
      currentContentFormat === 'video' && currentContent.videos?.length > 0
        ? 'Vídeo'
        : 'Texto';

    if (isTheoryWorkspace())
      return `
        Matéria: ${themeName}
        Capítulo: ${currentChapter.name}
        Assunto: ${subjectName}
        Tópico: ${topicName}
        Teoria: ${currentContent.id} (${contentFormat})
        Universidade: ${university}
        Campus: ${campus}
        Url do conteúdo: ${location.href}
        `;

    if (isSummaryWorkspace()) {
      return `
        Matéria: ${themeName}
        Capítulo: ${currentChapter.name}
        Assunto: ${subjectName}
        Resuminho: ${summaryId}
        Universidade: ${university}
        Campus: ${campus}
        Url do conteúdo: ${location.href}
      `;
    }

    if (isFixationExerciseWorkspace()) {
      const exerciseName =
        getExerciseIndexFromExercises(
          currentContent,
          currentFixationExercises
        ) + 1;

      return `
        Matéria: ${themeName}
        Capítulo: ${currentChapter.name}
        Assunto: ${subjectName}
        Tópico: ${topicName}
        Exercício de Fixação: ${exerciseName} (${contentFormat})
        Universidade: ${university}
        Campus: ${campus}
        Url do conteúdo: ${location.href}
      `;
    }

    if (
      isUniversityExerciseWorkspace() ||
      isExtraExerciseWorkspace() ||
      isExtraTimePageWorkspace()
    ) {
      const exerciseIndex = isUniversityExerciseWorkspace()
        ? getExerciseIndexFromExercises(
            currentContent,
            currentUniversityExercises
          )
        : getExerciseIndexFromExercises(currentContent, currentExtraExercises);
      const exerciseName = exerciseIndex + 1;

      return `
        Matéria: ${themeName}
        Assunto: ${subjectName}
        Disciplina: ${disciplineName}
        Origem: ${currentContent.metadata}
        Exercício de Lista: ${exerciseName}
        Universidade: ${university}
        Campus: ${campus}
        Url do conteúdo: ${location.href}
      `;
    }
  }, [
    isTheoryWorkspace,
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    isExtraExerciseWorkspace,
    getExerciseIndexFromExercises,
    currentTheme,
    currentChapter,
    currentSubject,
    currentTopic,
    currentContent,
    currentContentFormat,
    currentFixationExercises,
    currentUniversityExercises,
    currentExtraExercises,
    contentTitle,
    contentSubtitle,
    user,
    guide,
    errorReportGuidableTypes,
    isExtraTimePageWorkspace,
    isSummaryWorkspace,
    summaryId,
  ]);

  const getErrorReportData = useCallback(() => {
    return {
      type: errorReportType,
      location: errorReportLocation,
      ra_subject: `${currentSubject.name} - ${currentSubject.id}`,
    };
  }, [errorReportType, currentSubject, errorReportLocation]);
  return { getErrorReportData };
}
