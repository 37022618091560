import { useCallback } from 'react';
import { emitter } from '@marvelapp/react-ab-test';
import { useCookies } from './useCookies';

export function useTestAB() {
  const [cookies, setCookie] = useCookies();

  const getVariantFromTestAB = useCallback(testName => {
    if (!testName) return null;

    const variant = emitter.calculateActiveVariant(testName);
    return variant;
  }, []);

  const isUserOnABTest = useCallback(
    cookieName => {
      const cookieValue = cookies[cookieName];

      return cookieValue === 'true';
    },
    [cookies]
  );

  const doesAbTestCookieExist = useCallback(
    cookieName => {
      const cookieValue = cookies[cookieName];

      return cookieValue !== undefined;
    },
    [cookies]
  );

  // call the function passing the proportion with percent unit
  const setABTestRandomCookie = useCallback(
    (cookieName, proportionForTest) => {
      if (cookies[cookieName]) return isUserOnABTest(cookieName);

      const isOnTest = parseInt(Math.random() * 100) < proportionForTest;
      setCookie(cookieName, isOnTest);

      return isOnTest;
    },
    [setCookie, cookies, isUserOnABTest]
  );

  return {
    getVariantFromTestAB,
    isUserOnABTest,
    setABTestRandomCookie,
    doesAbTestCookieExist,
  };
}
