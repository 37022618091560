import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ListCreationRow from './ListCreationRow';
import { FaCirclePlus } from 'react-icons/fa6';
import useBookFormatter from '../hooks/useBookFormatter';
import { media } from 'styles';
import { useMvpBooksTracker } from '../hooks/useMVpBooksTracker';
import { useUploadDialogContext } from '../providers/UploadDialogProvider';
import useFormValidation from '../hooks/useFromValidation';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';
import { useToast } from 'modules/shared/hooks';

export const ManualInput = () => {
  const [isBookCorrect, setIsBookCorrect] = useState(false);
  const { isUserInputedListInvalid } = useFormValidation();
  const { errorToast } = useToast();
  const { sendPostRequest } = useContentRestRequest();
  const { user } = useUserContext();
  const { unsetDialog } = useDialogContext();

  const {
    inputList,
    setInputList,
    setStep,
    selectedBookEdition,
    selectedBook,
    exam,
    professor,
    statementList,
    setLoading,
    selectedDiscipline,
    setListLink,
    setFoundPercentage,
    selectedTheme,
    invalidateProfessorQuery,
  } = useUploadDialogContext();

  const {
    trackViewManualInputListScreen,
    trackClickChangeBook,
    trackClickAddSection,
    trackClickRemoveSection,
    trackClickConfirmLink,
    trackUploadListViaTextInput,
    trackListAccepted,
    trackError,
  } = useMvpBooksTracker();

  const {
    getBookEditionType,
    getBookAuthorById,
    getFormattedBookNameById,
  } = useBookFormatter();

  useEffect(() => {
    trackViewManualInputListScreen({
      professor,
      exam,
      theme: selectedTheme.name,
      edition: selectedBookEdition.name,
      book: selectedBook.name,
      statement: statementList,
    });
  }, [
    exam,
    professor,
    selectedBook.name,
    selectedBookEdition.name,
    statementList,
    selectedTheme,
    trackViewManualInputListScreen,
  ]);

  const sendManullyInputedListRequest = useCallback(async () => {
    if (isUserInputedListInvalid(inputList))
      return errorToast('Nenhum campo pode ficar em branco');

    setLoading(true);
    setStep('loading');

    try {
      const response = await sendPostRequest('/mvp/user-inputed-lists', {
        bookEditionId: selectedBookEdition?.id,
        professor,
        userName: user.name,
        isSubscriber: user.subscriber,
        exam,
        userInputedList: inputList,
        discipline: selectedDiscipline,
      });
      setListLink(response.data.link);
      setFoundPercentage(response.data.foundPercentage);
      setStep(7);
      if (response.data.link) {
        trackUploadListViaTextInput({
          theme: selectedTheme.name,
          professor: professor,
          exam,
          book: selectedBook.name,
          edition: selectedBookEdition.name,
          statement: statementList,
        });
        trackListAccepted({
          professor: professor.name,
          exam,
          themeName: selectedTheme?.name,
          bookName: selectedBookEdition?.amplitudeName,
          link: response.data.link,
          discipline: response.data.disciplineName,
        });
        if (!professor?.id) invalidateProfessorQuery();
      }
    } catch (e) {
      trackError('Erro ao enviar lista');
      unsetDialog();
      errorToast('Alguma coisa deu errado, tente novamente mais tarde');
    } finally {
      setLoading(false);
    }
  }, [
    isUserInputedListInvalid,
    inputList,
    errorToast,
    setLoading,
    setStep,
    sendPostRequest,
    selectedBookEdition,
    professor,
    user,
    exam,
    selectedDiscipline,
    setListLink,
    setFoundPercentage,
    trackUploadListViaTextInput,
    selectedTheme.name,
    selectedBook.name,
    statementList,
    trackListAccepted,
    invalidateProfessorQuery,
    trackError,
    unsetDialog,
  ]);

  const handleChangeBookClick = useCallback(() => {
    trackClickChangeBook({
      professor,
      exam,
      theme: selectedTheme.name,
      edition: selectedBookEdition.name,
      book: selectedBook.name,
      statement: statementList,
    });
    setStep(2);
  }, [
    exam,
    professor,
    selectedBook,
    selectedBookEdition,
    selectedTheme,
    setStep,
    statementList,
    trackClickChangeBook,
  ]);

  const confirmBook = useCallback(() => {
    setIsBookCorrect(true);
    trackClickConfirmLink({
      theme: selectedTheme.name,
      professor,
      exam,
      book: selectedBook.name,
      edition: selectedBookEdition.name,
    });
  }, [
    exam,
    professor,
    selectedBook.name,
    selectedBookEdition.name,
    selectedTheme,
    trackClickConfirmLink,
  ]);

  const addSection = useCallback(() => {
    trackClickAddSection({
      professor,
      exam,
      theme: selectedTheme.name,
      edition: selectedBookEdition.name,
      book: selectedBook.name,
      statement: statementList,
    });
    setInputList(inputList.concat({ section: '', chapter: '', exercises: '' }));
  }, [
    exam,
    inputList,
    professor,
    selectedBook.name,
    selectedBookEdition.name,
    setInputList,
    statementList,
    selectedTheme,
    trackClickAddSection,
  ]);

  const handleChapterSectionChange = useCallback(
    (chapterSection, i) => {
      const [chapter, section] = chapterSection.split('.');

      setInputList([
        ...inputList.slice(0, i),
        { ...inputList[i], chapter: chapter, section: section ? section : '' },
        ...inputList.slice(i + 1),
      ]);
    },
    [inputList, setInputList]
  );

  const handleExerciseChange = useCallback(
    (exercises, i) => {
      setInputList([
        ...inputList.slice(0, i),
        { ...inputList[i], exercises: exercises },
        ...inputList.slice(i + 1),
      ]);
    },
    [inputList, setInputList]
  );

  const handleRemoveSection = useCallback(
    indexToRemove => {
      trackClickRemoveSection({
        professor,
        exam,
        theme: selectedTheme.name,
        edition: selectedBookEdition.name,
        book: selectedBook.name,
        statement: statementList,
      });
      setInputList(
        inputList
          .slice(0, indexToRemove)
          .concat(inputList.slice(indexToRemove + 1))
      );
    },
    [
      exam,
      inputList,
      professor,
      selectedBook.name,
      selectedBookEdition.name,
      setInputList,
      statementList,
      selectedTheme,
      trackClickRemoveSection,
    ]
  );

  return (
    <Container className={statementList ? '' : 'section'}>
      <Title className={statementList ? '' : 'section'}>
        {statementList
          ? 'Atualmente, nós resolvemos as listas com as informações de seção e exercícios do livro.'
          : 'Infelizmente, não conseguimos identificar a sua lista Você tem certeza que as questões são desse livro?'}
      </Title>

      <BookInfoContainer>
        <BookCover src={`${selectedBook.imagePath}`} />
        <BookEditorContainer>
          <BookInfos>
            <Info>
              Autor: <b>{`${getBookAuthorById(selectedBook.id)}`}</b>
            </Info>
            <Info>
              Nome: <b>{`${getFormattedBookNameById(selectedBook.id)}`}</b>
            </Info>
            <Info>
              Edição: <b>{`${selectedBookEdition.name}ª Edição`}</b>
            </Info>
          </BookInfos>
          {!isBookCorrect && (
            <ButtonsContainer>
              {!statementList && (
                <EditorButton onClick={confirmBook}>
                  O Livro está correto
                </EditorButton>
              )}
              <EditorLink onClick={() => handleChangeBookClick()}>
                Alterar Livro
              </EditorLink>
            </ButtonsContainer>
          )}
        </BookEditorContainer>
      </BookInfoContainer>

      <ListCreationContainer>
        <Subtitle>Digite abaixo as questões da sua lista</Subtitle>
        <CreationFormContainer>
          {inputList.map((input, i) => (
            <ListCreationRow
              key={i}
              selectedSection={`${input.chapter}${
                input.section ? `.${input.section}` : ``
              }`}
              currentExercises={input.exercises}
              onChapterSectionChange={chapterSection =>
                handleChapterSectionChange(chapterSection, i)
              }
              onExerciseChange={exercise => handleExerciseChange(exercise, i)}
              removeSection={() => handleRemoveSection(i)}
              selectedBookEdition={selectedBookEdition}
            />
          ))}
        </CreationFormContainer>
        <AddSection>
          <Divider />
          <Button onClick={addSection}>
            <FaCirclePlus />
            <AddText>{`Adicionar ${getBookEditionType(
              selectedBookEdition.id
            )}`}</AddText>
          </Button>
          <Divider />
        </AddSection>
      </ListCreationContainer>
      <SendListButton
        onClick={() => {
          sendManullyInputedListRequest();
        }}
      >
        Enviar Lista
      </SendListButton>
    </Container>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: centerc;
`;

const EditorButton = styled.div`
  margin-right: 15px;
  color: white;
  background-color: #0bc15f;
  border-radius: 20px;
  font-weight: bold;
  font-size: 17px;
  cursor: pointer;
  padding: 10px 7px;
  @media ${media.mobile} {
    font-size: 15px;
    border-radius: 10px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  border-radius: 11px 11px 0 0;
  max-height: 640px;
  overflow: auto;
  &.section {
    border-top: 22px solid #ff5555;
    padding-top: 0px;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #f2f2f2;
    border: 1px solid #eeeeee;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #cccccc;
  }
`;

const Title = styled.div`
  color: #555555;
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 25px;
  &.section {
    margin-top: 10px;
  }
`;

const BookCover = styled.img`
  width: 110px;
  margin-right: 40px;

  @media ${media.mobile} {
    margin-right: 15px;
  }
`;
const BookInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

const BookEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

const BookInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;
`;

const Info = styled.span`
  color: black;
  font-size: 18px;
  margin-bottom: 5px;
`;

const EditorLink = styled.span`
  color: #f9ac3e;
  font-size: 18px;
  text-decoration: underline;
  text-underline-offset: 3px; /* Ajuste conforme necessário */
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
`;

const ListCreationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subtitle = styled.span`
  font-size: 22px;
  color: #555555;
  margin-bottom: 20px;
`;

const CreationFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddSection = styled.div`
  display: flex;
  align-items: center;
  color: #4a4a4a;
`;
const Divider = styled.div`
  flex: 1;
  height: 1px;
  background-color: #dfdfdf;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 31px;
  border: 1px solid #dfdfdf;
  padding: 4px 8px;
  margin: 0px 8px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const AddText = styled.div`
  margin-left: 5px;
`;

const SendListButton = styled.div`
  background-color: #f9ac3e;
  border-radius: 20px;
  color: white;
  font-size: 17px;
  font-weight: bold;
  display: inline-block;
  padding: 10px 40px;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
