import React, { useCallback, useEffect, useMemo } from 'react';
import { styled, media } from 'styles';
import { DoubleButtonContainer, StepsContainer } from './styles';
import { useTracker } from 'modules/shared/hooks';
import ThemesMenu from './ThemesMenu';
import { Error, Loading } from 'modules/shared/components/generic';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';

export default function DisciplineInfo({
  selectedTheme,
  setDiscipline,
  onFormBack,
  onSubmitInfo,
  materialType,
  discipline,
  isThemesMenuOpen,
  setIsThemesMenuOpen,
  setSelectedTheme,
}) {
  const canSubmit = !!discipline;
  const { data: disciplines, loading, error } = useContentQueryImmutable(
    `/disciplines`
  );

  const { trackEvent } = useTracker();

  useEffect(() => {
    trackEvent('Listas: Visualizou Dialog de Disciplinas');
  }, [trackEvent]);

  const handleNext = useCallback(() => {
    if (!canSubmit) return;
    onSubmitInfo();
    trackEvent('Listas: Dialog das Disciplinas Selecionou Disciplina', {
      Matéria: selectedTheme?.name,
      Disciplina: discipline?.name,
    });
  }, [canSubmit, trackEvent, selectedTheme, discipline, onSubmitInfo]);

  const handleDropdown = useCallback(() => {
    setIsThemesMenuOpen(!isThemesMenuOpen);
    trackEvent('Listas: Dialog das Disciplinas Selecionou Seletor de Matérias');
  }, [trackEvent, setIsThemesMenuOpen, isThemesMenuOpen]);

  const handleOtherDisciplineCardClick = useCallback(() => {
    trackEvent('Listas: Dialog das Disciplinas Selecionou Disciplina', {
      Matéria: selectedTheme?.name,
      Disciplina: 'Outra disciplina',
    });
  }, [trackEvent, selectedTheme]);

  const disciplinesFromTheme = useMemo(() => {
    if (!disciplines) return [];
    if (!selectedTheme) return disciplines;

    return disciplines.filter(d => d.theme.id === selectedTheme.id);
  }, [selectedTheme, disciplines]);

  if (error) return <Error />;
  if (loading) return <Loading />;

  return (
    <Container>
      <Title>
        <h1> De qual disciplina é sua {materialType.toLowerCase()}? </h1>
      </Title>
      <StepsContainer>
        <span></span>
        <span className={'fill'}></span>
        <span></span>
        <span></span>
        <span></span>
      </StepsContainer>
      <ThemesMenu
        selectedTheme={selectedTheme}
        isThemesMenuOpen={isThemesMenuOpen}
        setIsThemesMenuOpen={handleDropdown}
        setSelectedTheme={setSelectedTheme}
        setDiscipline={setDiscipline}
      />
      {!isThemesMenuOpen && (
        <>
          <DisciplinesCardsWrapper>
            {disciplinesFromTheme?.map(d => {
              return (
                <DisciplineCard
                  key={d.id}
                  theme={selectedTheme}
                  onClick={() => setDiscipline(d)}
                  isSelected={discipline?.name === d.name}
                >
                  <img src={selectedTheme.imagePath} alt="" />
                  <span className="name">{d.name}</span>
                </DisciplineCard>
              );
            })}
            <DisciplineCard
              theme={selectedTheme}
              onClick={() => handleOtherDisciplineCardClick()}
            >
              <img src={selectedTheme.imagePath} alt="" />
              <span className="name">Outra Disciplina</span>
              <SoonTag>EM BREVE</SoonTag>
            </DisciplineCard>
          </DisciplinesCardsWrapper>
          <DoubleButtonContainer>
            <Button backButton onClick={onFormBack}>
              VOLTAR
            </Button>
            <Button canSubmit={canSubmit} onClick={handleNext}>
              PRÓXIMO
            </Button>
          </DoubleButtonContainer>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: sticky;
  z-index: 1;
`;

export const Button = styled.button`
  width: 48%;
  border: 1px solid
    ${({ backButton, canSubmit }) =>
      canSubmit || backButton ? '#f6ac3a' : '#B8B8B8'};
  border-radius: 10px;

  color: ${({ backButton }) => (backButton ? '#f6ac3a' : '#fff')};
  background-color: ${({ backButton, canSubmit }) =>
    backButton ? '#fff' : canSubmit ? '#f6ac3a' : '#B8B8B8'};
  margin: 45px 0px;
  padding: 15px;

  font-weight: bold;
  font-size: 1.1em;

  cursor: pointer;

  @media ${media.mobile} {
    margin: 10px;
    width: 100%;
  }
`;

const Title = styled.div`
  color: #888;
  font-weight: bold;
  text-align: center;

  h1 {
    font-size: 2.2em;

    @media ${media.mobile} {
      font-size: 2em;
    }
  }

  h3 {
    font-size: 1.1em;
    margin-top: 10px;
    line-height: 20px;

    @media ${media.mobile} {
      font-size: 1em;
    }
  }
`;

const DisciplinesCardsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media ${media.mobile} {
    flex-wrap: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scrollbar-color: red;
    scrollbar-width: none;
    padding-left: 350px;
    ::-webkit-scrollbar {
      display: none !important;
    }
  }
`;

const DisciplineCard = styled.div`
  display: block;
  transition: opacity 100ms ease-in;

  background: ${({ isSelected, theme }) =>
    isSelected ? theme.color : '#B8B8B8'};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 184px;
  min-width: 120px;
  height: 118px;
  margin: 10px 8px 20px 8px;
  padding-top: 15px;
  padding-bottom: 10px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    padding: 0 10px;
  }

  .university {
    position: absolute;
    bottom: 4px;
    width: 100%;
    text-align: center;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.3);
  }

  .free-strip {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12);
    position: absolute;
    left: -14px;
    top: 4px;
    background-color: #ff9f00;
    padding: 6px 10px;
    color: #000;
    font-size: 0.8em;
    z-index: 2;
    transform: rotate(-25deg);
  }

  &:hover {
    opacity: 0.8;
  }

  @media ${media.mobile} {
    height: 170px;
    max-width: 120px;

    img {
      width: 70px;
      height: 70px;
      margin-top: 15px;
    }
  }
`;

const SoonTag = styled.div`
  background-color: #9b9b9b;
  color: #fff;
  padding: 3px 7px;
  font-weight: bold;
  font-size: 13px;
`;
