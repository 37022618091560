export const CONTENT_ITEMS = [
  {
    id: 1,
    name: 'linkedin',
    displayName: 'Curso de Linkedin',
    downloadLink:
      'https://www.youtube.com/playlist?list=PLNG6I1M7eOuzoRvWhX6ydjN_V8JoTehvI',
    blocked: true,
    color: '#7BD77D',
    lightColor: '#D9F5E5',
  },
  {
    id: 2,
    name: 'resumo',
    displayName: 'Resumo',
    downloadLink:
      'https://drive.google.com/drive/u/0/folders/1U8SsiiuUjDy9EC5dIoCqzzwpTJQp6aE9',
    blocked: true,
    color: '#7BD77D',
    lightColor: '#D9F5E5',
  },
  {
    id: 3,
    name: 'mapa',
    displayName: 'Mapa Mental',
    downloadLink:
      'https://drive.google.com/drive/u/0/folders/1CiTlu2xvxw48cUnmz5HIsjKP5XzPaZNd',
    blocked: true,
    color: '#7BD77D',
    lightColor: '#D9F5E5',
  },
  {
    id: 4,
    name: 'raiox',
    displayName: 'Cálculo',
    downloadLink:
      'https://drive.google.com/u/0/uc?id=1ZkDmHQu6PrRmTjmCmiaflGIXBShPjaAN&export=download',
    blocked: true,
    color: '#7BD77D',
    lightColor: '#D9F5E5',
  },
  {
    id: 5,
    name: 'raiox',
    displayName: 'Física',
    downloadLink:
      'https://drive.google.com/u/0/uc?id=191MeVAgm0PF6nXxA7uVThl3E4BQcbW9S&export=download',
    blocked: true,
    color: '#7BD77D',
    lightColor: '#D9F5E5',
  },
  {
    id: 6,
    name: 'excel',
    displayName: 'Curso Excel',
    downloadLink:
      'https://drive.google.com/u/0/uc?id=16dDJVtGKkm2BM431WrQx3TL1bhGedUxz&export=download',
    blocked: true,
    color: '#7BD77D',
    lightColor: '#D9F5E5',
  },
];
