import _ from 'lodash';

function camelToUnderscore(key) {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export default function convertObjectToUnderscore(obj) {
  return _.transform(obj, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : camelToUnderscore(key);

    acc[camelKey] = _.isObject(value)
      ? convertObjectToUnderscore(value)
      : value;
  });
}
