import React from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@material-ui/core';
import { matchSorter } from 'match-sorter';

import { styled } from 'styles';

export default function ProfessorSelector({
  professors,
  selectedProfessor,
  setSelectedProfessor,
  disabled = false,
}) {
  const professorSearchFilterOption = (options, { inputValue }) =>
    matchSorter(options, inputValue, {
      keys: ['name'],
      threshold: matchSorter.rankings.WORD_STARTS_WITH,
    });

  return (
    <CustomAutoComplete
      id="size-small-standard"
      size="small"
      freeSolo
      options={professors}
      value={selectedProfessor}
      filterOptions={(options, params) => {
        const filtered = professorSearchFilterOption(options, params);

        if (filtered.length === 0 && params.inputValue !== '') {
          filtered.push({
            id: null,
            name: params.inputValue,
            customLabel: `${params.inputValue} (Criar Professor)`,
          });
        }

        return filtered;
      }}
      onChange={(event, newValue) => {
        if (!newValue) {
          setSelectedProfessor(null);
          return;
        }
        if (typeof newValue === 'string') {
          // if user press enter, newValue will be a string
          setSelectedProfessor({
            id: null,
            name: newValue,
          });
          return;
        }

        if (!newValue.id) {
          setSelectedProfessor({
            id: null,
            name: newValue.name,
          });
        } else {
          setSelectedProfessor(newValue);
        }
      }}
      getOptionLabel={option => {
        if (!option.id) return option.customLabel || option.name;

        return option.name;
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          label="Selecione ou digite o seu professor"
          placeholder="Digite"
        />
      )}
      clearOnBlur
      selectOnFocus
      autoHighlight
      disabled={disabled}
    />
  );
}

const CustomAutoComplete = styled(Autocomplete)`
  width: 100%;
  min-width: 320px;
  max-width: 100%;
`;
