import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useRAPostRequest, useRAQuery } from 'modules/shared/hooks/requests';
import Answer from './Answer';
import { useToast } from 'modules/shared/hooks';
import MultilineText from 'modules/shared/components/MultilineText';
import QuestionUserProfile from '../generic/QuestionUserProfile';
import { useDialogContext } from 'providers/DialogProvider';
import ImageZoomDialog from '../ImageZoomDialog';
import {
  faSpinner as loadingIcon,
  faExclamationCircle as errorIcon,
} from '@fortawesome/free-solid-svg-icons';
import WarningText from '../generic/WarningText';
import { useUserContext } from 'providers/UserProvider';
import ShareButton from '../generic/ShareButton';
import {
  alert,
  alerted,
  alertHover,
  comment,
  commentClick,
  commentHover,
} from 'images/forum';
import { Row } from '../shared/styles';
import ActionButton from '../generic/ActionButton';
import {
  Container,
  Content,
  QuestionImage,
  QuestionTitle,
  Back,
  MessageContainer,
  Icon,
} from './styles';
import MenuButton from '../generic/MenuButton';
import ForumReportInputDialog from '../dialogs/ForumReportInputDialog';
import { media, styled } from 'styles';
import { useForumContext } from 'providers/ForumProvider';
import CreateOrUpdateQuestion from '../generic/CreateOrUpdateQuestion';
import CreateOrUpdateAnswer from '../generic/CreateOrUpdateAnswer';
import DeletePostConfirmation from '../generic/DeletePostConfirmation';
import useForumTracker from 'modules/forum/hooks/useForumTracker';

export default function SelectedQuestion({
  id,
  setSelectedQuestion,
  answerToFocus,
}) {
  const {
    trackClickToShare,
    trackSawAnswers,
    trackFollowQuestion,
    trackHighlightAnswer,
    trackRemoveHighlightAnswer,
    trackClickToReport,
    trackReport,
    trackDeletedQuestion,
  } = useForumTracker();
  const { user } = useUserContext();
  const { successToast, errorToast } = useToast();

  const { setDialog } = useDialogContext();
  const { forumContent: content } = useForumContext();

  const [question, setQuestion] = useState(null);
  const [
    shouldShowCreateOrUpdateQuestion,
    setShouldShowCreateOrUpdateQuestion,
  ] = useState(false);
  const [
    shouldShowCreateOrUpdateAnswer,
    setShouldShowCreateOrUpdateAnswer,
  ] = useState(false);
  const [answerToEdit, setAnswerToEdit] = useState(null);

  const { data, error, loading } = useRAQuery(
    `/ra_forum/questions/${id}/${content?.type}/${content?.id}`
  );

  const deleteAnswerFileReq = useRAPostRequest(
    `/ra_forum/questions/${id}/delete_file`
  );

  const toggleFollowReq = useRAPostRequest(
    `/ra_forum/questions/${id}/toggle_follow`
  );

  const setToast = useCallback(() => {
    const toastText = question?.following
      ? 'Você não está mais seguindo essa publicação.'
      : 'Você está seguindo essa publicação.';
    successToast(toastText);
  }, [question, successToast]);

  const toggleFollow = useCallback(async () => {
    try {
      await toggleFollowReq();
      setQuestion(question => ({
        ...question,
        following: !question.following,
      }));
      trackFollowQuestion();
    } catch (e) {
      errorToast(e);
    }
  }, [errorToast, toggleFollowReq, trackFollowQuestion]);

  const onRemoveImage = useCallback(async () => {
    try {
      await deleteAnswerFileReq();
      setQuestion({ ...question, fileUrl: null, visible: true });
    } catch (e) {
      errorToast('Erro ao excluir resposta!');
    }
  }, [deleteAnswerFileReq, errorToast, question]);

  const back = useCallback(() => {
    setSelectedQuestion(null);
  }, [setSelectedQuestion]);

  useEffect(() => {
    if (!data) return;

    setQuestion(data);
    trackSawAnswers(data?.answers?.length);
  }, [data, trackSawAnswers]);

  const report = useCallback(() => {
    trackClickToReport('Pergunta');
    setDialog(
      <ForumReportInputDialog
        data={question}
        contentType={'question'}
        trackReport={() => trackReport('Pergunta')}
      />
    );
  }, [question, setDialog, trackClickToReport, trackReport]);

  const deleteQuestionReq = useRAPostRequest(
    `/ra_forum/questions/${id}/delete`
  );

  const deleteQuestion = useCallback(async () => {
    try {
      await deleteQuestionReq();
      setSelectedQuestion(null);
      trackDeletedQuestion();
    } catch (e) {
      errorToast('Erro ao excluir questão!');
    }
  }, [
    deleteQuestionReq,
    errorToast,
    setSelectedQuestion,
    trackDeletedQuestion,
  ]);

  const isUserQuestion = useMemo(() => {
    if (!question) return false;
    return question.user.id === user.id;
  }, [question, user]);
  const waitingApproval = useMemo(() => !question?.visible, [question]);

  const openEditionQuestion = useCallback(() => {
    setShouldShowCreateOrUpdateQuestion(true);
  }, [setShouldShowCreateOrUpdateQuestion]);

  const onMarkAsCorrect = useCallback(
    (correct, answer) => {
      setQuestion({
        ...question,
        answers: question.answers.map(a =>
          a.id === answer.id ? { ...a, correct } : { ...a, correct: false }
        ),
      });
      if (correct) trackHighlightAnswer();
      else trackRemoveHighlightAnswer();
    },
    [trackHighlightAnswer, setQuestion, question, trackRemoveHighlightAnswer]
  );

  const openDeletePostConfirmation = useCallback(() => {
    setDialog(<DeletePostConfirmation onSubmit={deleteQuestion} />);
  }, [setDialog, deleteQuestion]);

  if (error || loading || !question)
    return (
      <Container>
        {loading && (
          <MessageContainer>
            Carrregando...
            <Icon icon={loadingIcon} spin />
          </MessageContainer>
        )}
        {error && (
          <MessageContainer>
            Ocorreu um erro ao carregar as perguntas
            <Icon icon={errorIcon} />
          </MessageContainer>
        )}
      </Container>
    );

  if (shouldShowCreateOrUpdateQuestion)
    return (
      <CreateOrUpdateQuestion
        action="edition"
        question={question}
        setQuestion={setQuestion}
        setShouldShowCreateOrUpdate={setShouldShowCreateOrUpdateQuestion}
        setSelectedQuestion={setSelectedQuestion}
      />
    );

  return (
    <Container>
      <HeaderRow>
        <Back className="back" onClick={back}>
          Voltar
        </Back>
      </HeaderRow>
      <QuestionHeader>
        <QuestionUserProfile
          createdAt={question.createdAt}
          user={question.user}
          university={question.university.acronym}
          universityCourse={question.universityCourse?.name}
        />
        <MenuButton
          onClickEdit={isUserQuestion ? openEditionQuestion : null}
          onClickDelete={isUserQuestion ? openDeletePostConfirmation : null}
          onClickReport={!isUserQuestion ? report : null}
        />
      </QuestionHeader>

      <Content waitingApproval={waitingApproval}>
        <QuestionTitle>{question.title}</QuestionTitle>
        {waitingApproval && <WarningText />}
        <MultilineText text={question.body} id={`q-${question.id}`} />

        {question.fileUrl && (
          <QuestionImage
            src={question.fileUrl}
            alt="Imagem da pergunta"
            onClick={() =>
              setDialog(
                <ImageZoomDialog
                  url={question.fileUrl}
                  filename={question.fileUrl.split('_____')[1]}
                  onRemoveImage={
                    user && question?.user?.id === user?.id && onRemoveImage
                  }
                />
              )
            }
          />
        )}

        <Row>
          <ActionButton
            text={'Responder'}
            image={comment}
            hoverImage={commentHover}
            clickImage={commentClick}
            showTextOnMobile
            onClick={() => {
              // focus on textarea id new-answer-input
              const newAnswerInput = document.getElementById(
                'new-answer-input'
              );
              newAnswerInput?.focus();
            }}
            underline={true}
          />

          <ShareButton
            questionId={question.id}
            trackClickToShare={trackClickToShare}
          />

          {user && question?.user?.id !== user?.id && (
            <ActionButton
              image={question.following ? alerted : alert}
              hoverImage={alertHover}
              onClick={() => {
                toggleFollow();
                setToast();
              }}
              underline={true}
              text={question.following ? 'Seguindo' : 'Seguir'}
            />
          )}
        </Row>
      </Content>

      <Answers>
        {question.answers.map(answer => (
          <Answer
            focus={`${answerToFocus}` === `${answer.id}`}
            question={question}
            key={answer.id}
            answer={answer}
            onDeleteImage={() => {
              setQuestion({
                ...question,
                answers: question.answers.map(a =>
                  a.id === answer.id
                    ? { ...a, fileUrl: null, visible: true }
                    : a
                ),
              });
            }}
            onMarkAsCorrect={correct => onMarkAsCorrect(correct, answer)}
            shouldShowCreateOrUpdateAnswer={shouldShowCreateOrUpdateAnswer}
            setShouldShowCreateOrUpdateAnswer={
              setShouldShowCreateOrUpdateAnswer
            }
            setQuestion={setQuestion}
            answerToEdit={answerToEdit}
            setAnswerToEdit={setAnswerToEdit}
          />
        ))}
        {!shouldShowCreateOrUpdateAnswer && (
          <CreateOrUpdateAnswer
            action="create"
            question={question}
            setQuestion={setQuestion}
            setShouldShowCreateOrUpdate={setShouldShowCreateOrUpdateAnswer}
          />
        )}
      </Answers>
    </Container>
  );
}

const HeaderRow = styled(Row)`
  justify-content: space-between;
  margin-top: 20px;
`;

const Answers = styled.div`
  margin-left: ${media.isMobile() ? '0' : '70px'};
  border-left: ${media.isMobile() ? '2px solid #d0d0d0' : ''};
  padding: ${media.isMobile() ? '0 0 0 8px' : ''};
`;

const QuestionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
