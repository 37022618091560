import { useCallback } from 'react';

export default function useSearchExerciseType() {
  const getExerciseIdByResult = useCallback(result => {
    if (!result) return null;

    let key;
    if (result._source.exerciseType === 'exercise') key = 'exerciseId';
    if (result._source.exerciseType === 'listExercise') key = 'listExerciseId';
    if (!result._source.exerciseType) key = 'bookExerciseId';

    return result._source[key];
  }, []);

  const getExerciseLink = useCallback((result, searchType) => {
    const { _source: content } = result;

    if (content.exerciseType === 'exercise') {
      const { subjectId, topicId, exerciseId } = content;
      return `/aprender/topico/${subjectId}/${topicId}/exercicio/${exerciseId}?internal_source=search_${searchType}`;
    }

    if (content.exerciseType === 'listExercise') {
      const {
        guideId,
        listCategoryId,
        listId,
        listExerciseId,
        subjectId,
        chapterId,
      } = content;

      const isClassified = subjectId && chapterId;

      if (isClassified)
        return `/aprender/extras/${subjectId}/${chapterId}/exercicio-lista/${listExerciseId}?internal_source=search_${searchType}`;

      return `/praticar/${guideId}/lista/${listCategoryId}/${listId}/exercicio-lista/${listExerciseId}?internal_source=search_${searchType}`;
    }

    const { bookId, editionId, bookExerciseId } = content;
    return `/materias/solucionario/livro/${bookId}/edicao/${editionId}/exercicio/${bookExerciseId}?internal_source=search_${searchType}`;
  }, []);

  const getHtmlStatement = useCallback(result => {
    const { _source: content } = result;

    if (content.exerciseType === 'exercise') {
      return content.exerciseStatement;
    }

    if (content.exerciseType === 'listExercise') {
      return content.listExerciseStatement;
    }

    return content.bookExerciseStatement;
  }, []);

  const geTitleName = useCallback(result => {
    const { _source: content } = result;

    if (content.exerciseType === 'exercise') {
      return `${content.subjectName} > ${content.chapterName}`;
    }

    if (content.exerciseType === 'listExercise') {
      return `${content.disciplineName} > ${content.listCategoryName} > ${content.listName}`;
    }

    return `${content.amplitudeName} ${content.editionName} > Cap ${content.chapterName} > Sec. ${content.sectionName} > Ex. ${content.bookExerciseName}`;
  }, []);

  const getExerciseTypeByResult = useCallback(result => {
    const { _source: content } = result;

    if (content.exerciseType === 'exercise') {
      return 'Exercício';
    }

    if (content.exerciseType === 'listExercise') {
      return 'Exercício Lista';
    }

    return 'Exercício Livro';
  }, []);

  return {
    getExerciseIdByResult,
    getExerciseLink,
    getHtmlStatement,
    geTitleName,
    getExerciseTypeByResult,
  };
}
