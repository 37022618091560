import React, { useRef } from 'react';
import Slick from 'react-slick';
import { ThemeItem } from 'modules/summaries/components';

import { styled } from 'styles';
import { useCookies } from 'modules/shared/hooks';

export default function ThemesSlider({
  themes,
  selectedThemeId,
  setSelectedThemeId,
}) {
  const ref = useRef(null);
  const [, setCookie] = useCookies(['summaries_selected_theme_id']);

  const themeIndex = selectedThemeId
    ? themes.findIndex(t => selectedThemeId === t.id)
    : 0;

  function handleThemeClick(i) {
    setCookie('summaries_selected_theme_id', themes[i].id);

    ref.curent && ref.current.slickGoTo(i);

    setSelectedThemeId(themes[i].id);
  }

  function slickAfterchange(current) {
    setCookie('summaries_selected_theme_id', themes[current].id);
    setSelectedThemeId(themes[current].id);
  }

  const config = {
    slidesToShow: 7,
    slidesToScroll: 1,
    speed: 400,
    centerMode: true,
    centerPadding: '0',
    draggable: false,
    initialSlide: themeIndex,
    afterChange: slickAfterchange,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
          arrows: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Slider ref={ref} {...config}>
      {themes.map((t, i) => (
        <ThemeItem
          onClick={() => handleThemeClick(i)}
          key={t.id}
          color={t.color}
          imagePath={t.imagePath}
          name={t.name}
        />
      ))}
    </Slider>
  );
}

const Slider = styled(Slick)`
  width: 75%;
  margin: 50px auto;
  max-width: 960px;

  button.slick-prev,
  button.slick-next {
    &:before {
      color: #555;
      font-size: 26px;
    }
  }
`;
