import { useUserContext } from 'providers/UserProvider';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { styled } from 'styles';
import { periodsList, yearsList } from '../forms-options';

export default function AdmissionSelects({ onChangeYear, onChangePeriod }) {
  const { user } = useUserContext();

  const [yearAdmission, setYearAdmission] = useState(user?.yearAdmission);
  const [periodAdmission, setPeriodAdmission] = useState(user?.periodAdmission);

  useEffect(() => {
    if (user?.yearAdmission) setYearAdmission({ label: user?.yearAdmission });
    if (user?.periodAdmission)
      setPeriodAdmission({ label: user?.periodAdmission });
  }, [user]);

  useEffect(() => {
    onChangeYear(yearAdmission?.label);
    onChangePeriod(periodAdmission?.label);
  }, [yearAdmission, periodAdmission, onChangeYear, onChangePeriod]);

  return (
    <>
      <SelectorContainer data-cy="admission-year-select">
        <Select
          placeholder={'Ano de Ingresso'}
          options={yearsList}
          isClearable={true}
          onChange={e => {
            setYearAdmission(e);
          }}
          value={yearAdmission}
          filterOption={() => true}
          components={selectCustomComponents}
          styles={selectCustomStyles}
        />
      </SelectorContainer>
      <SelectorContainer data-cy="admission-period-select">
        <Select
          placeholder={'Período de Ingresso'}
          options={periodsList}
          isClearable={true}
          value={periodAdmission}
          onChange={e => {
            setPeriodAdmission(e);
          }}
          filterOption={() => true}
          components={selectCustomComponents}
          styles={selectCustomStyles}
        />
      </SelectorContainer>
    </>
  );
}

const selectCustomStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      border: 'none',
      borderBottom: '1px solid #e6e6e6',
      justifyContent: 'flex-start',
      textAlign: 'left',
      boxShadow: state.isFocused ? 0 : 0,
    };
  },
  option: provided => {
    return {
      ...provided,
      textAlign: 'left',
      '&:hover': {
        backgroundColor: '#eee',
      },
      '&:selected': {
        backgroundColor: '#eee',
      },
      backgroundColor: 'transparent',
      color: 'black',
    };
  },
};

const selectCustomComponents = {
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null,
};

const SelectorContainer = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;
