import React from 'react';
import BookResult from './BookResult';
import ExerciseResult from './ExerciseResult';
import TheoryResult from './TheoryResult';

// import { Container } from './styles';

function Results({ results, theme, onlyVideos, clickedInResult }) {
  const ret = [];

  results.map((it, i) => {
    let Component = null;

    if (it.type === 'theory') Component = TheoryResult;
    if (it.type === 'exercise') Component = ExerciseResult;
    if (it.type === 'bookExercise') Component = BookResult;

    if (Component)
      ret.push(
        <Component
          result={it}
          key={`search_results_${it.type}_${i}`}
          theme={theme}
          onlyVideos={onlyVideos}
          clickedInResult={() => clickedInResult(it, i)}
        />
      );
  });

  return ret;
}

export default Results;
