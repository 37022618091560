import QuestionUserProfile from '../generic/QuestionUserProfile';
import WarningText from '../generic/WarningText';
import React, { useMemo } from 'react';
import { css } from 'styled-components';
import { styled } from 'styles';
import { comment } from 'images/forum';

export default function QuestionPreview({ question, setSelectedQuestion }) {
  const { university, universityCourse } = question;

  const answerCountText = useMemo(
    () =>
      question.forumAnswersQuantity > 1
        ? `${question.forumAnswersQuantity} Respostas`
        : '1 Resposta',
    [question]
  );

  const waitingApproval = useMemo(() => !question.visible, [question]);

  return (
    <>
      <QuestionHeader>
        <QuestionUserProfile
          user={question.user}
          university={university?.acronym}
          universityCourse={universityCourse?.name}
          createdAt={question.createdAt}
        />
      </QuestionHeader>
      <Container waitingApproval={waitingApproval}>
        {waitingApproval && <WarningText />}
        <QuestionTitle onClick={() => setSelectedQuestion(question.id)}>
          {question.title}
        </QuestionTitle>

        {question.forumAnswersQuantity > 0 && (
          <AnswerCount onClick={() => setSelectedQuestion(question.id)}>
            <img src={comment}></img>
            {answerCountText}
          </AnswerCount>
        )}
      </Container>
    </>
  );
}

const QuestionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;

  > span {
    margin-right: 5px;
    display: none;
  }

  &:hover {
    span {
      display: flex;
    }
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-left: 70px;
  margin-bottom: 30px;

  ${({ waitingApproval }) =>
    waitingApproval &&
    css`
      color: gray;
    `}
`;

const QuestionTitle = styled.h1`
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.3em;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const AnswerCount = styled.div`
  font-size: 1.1em;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: underline;
  margin-top: 15px;
  display: flex;
  cursor: pointer;
  img {
    margin-right: 5px;
  }
`;
