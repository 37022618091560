import { highlightObject, prepareQuery } from './utils';

export default class ElasticSearchTopics {
  static getSearchQuery(term, themeId) {
    const weight = term.length <= 35 ? 2 : 1;
    const keys = [
      { name: 'subject.topic.theory.lightBody', weight },
      { name: 'subject.topic.name', weight: weight * 1.1 },
    ];

    const source = [
      'id',
      'name',
      'color',
      'subject.id',
      'subject.name',
      'subject.topic.id',
      'subject.topic.name',
      'subject.topic.theory.id',
      'subject.topic.theory.videos',
      'subject.topic.chapter.id',
      'subject.topic.chapter.name',
      'subject.topic.theory.lightBodyMaths',
    ];

    const query = prepareQuery(term, keys, themeId);

    const json = {
      query,
      _source: source,
      size: 10,
      ...highlightObject(keys),
    };

    return json;
  }

  // static async adjustResults(data, universityId) {
  static async adjustResults(data) {
    const ret = [];

    const hits = data.hits.hits
      .filter(it => it._index === 'ra_theories')
      .filter(it => it.highlight);

    const disciplines = {};

    for (let i = 0; i < hits.length; i++) {
      const it = hits[i];

      it._source.score = it._score;
      it._source.max_score = data.hits.max_score;

      let discipline = null;

      const mj = JSON.parse(it._source.subject.topic.theory.lightBodyMaths);

      if (Object.keys(disciplines).indexOf(it._source.subject.id) !== -1) {
        discipline = disciplines[it._source.subject.id];
      } else {
        // discipline = await getDisciplineBySubjectAndUniversity(
        //   it._source.subject.id,
        //   universityId
        // );

        disciplines[it._source.subject.id] = discipline;
      }

      if (discipline) it._source.discipline = discipline;

      let highlight = it.highlight['subject.topic.theory.lightBody'];
      if (highlight && highlight.length > 0) {
        let lb = highlight[0];

        for (let i = 0; i < mj.length; i++) {
          lb = lb.replace(`_m${i + 1}_`, mj[i]);
          lb = lb.replace(`_m${i + 1}`, mj[i]);
        }

        it._source.subject.topic.theory.lightBody = lb;
      }

      highlight = it.highlight['subject.topic.name'];
      if (highlight && highlight.length > 0) {
        it._source.subject.topic.name = highlight[0];
      }

      ret.push(it._source);
    }

    return ret;
  }
}
