import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useMvpBooksTracker } from '../hooks/useMVpBooksTracker';
import { useUploadDialogContext } from '../providers/UploadDialogProvider';
import { booksToShow } from '../data';

export const ChooseBookStep = () => {
  const {
    professor,
    setStep,
    exam,
    setSelectedBookEdition,
    selectedTheme,
    themes,
    selectedBook,
    setSelectedBook,
  } = useUploadDialogContext();

  const { trackViewEditionFormScreen } = useMvpBooksTracker();

  useEffect(() => {
    trackViewEditionFormScreen({
      theme: selectedTheme.name,
      professor: professor,
      exam,
    });
  }, [exam, professor, selectedTheme.name, trackViewEditionFormScreen]);

  const booksFromTheme = useMemo(() => {
    const allBooks = themes.find(theme => theme.id === selectedTheme.id).books;

    return allBooks.filter(book => booksToShow.includes(book.id));
  }, [selectedTheme, themes]);

  const handleEditionClick = useCallback(
    edition => {
      setSelectedBookEdition(edition);
      setStep(4);
    },
    [setSelectedBookEdition, setStep]
  );

  return (
    <ContainerDialog>
      <BackIcon icon={faArrowLeft} onClick={() => setStep(2)} />

      <Title>{`Qual é o livro do professor ${professor?.name}?`}</Title>
      <BooksContainer>
        {booksFromTheme.map((book, i) => (
          <BookCover
            className={
              selectedBook?.id === book.id
                ? 'selected'
                : selectedBook !== null
                ? 'opacity'
                : ''
            }
            onClick={() => setSelectedBook(book)}
            key={i}
          >
            <img src={`${book.imagePath}`} loading="lazy" />
          </BookCover>
        ))}
      </BooksContainer>

      <Container>
        {selectedBook && selectedBook.editions && (
          <>
            <Subtitle>
              {`Selecione a edição do livro: `}
              <span>{`${selectedBook.name}`}</span>
            </Subtitle>
            <EditionsContainer>
              {selectedBook.editions.map((edition, i) => (
                <Edition
                  onClick={() => {
                    handleEditionClick(edition);
                  }}
                  className={
                    i === selectedBook.editions.length - 1 ? 'last' : ''
                  }
                  key={edition.id}
                >
                  {`${edition.name}ª Edição`}
                </Edition>
              ))}
            </EditionsContainer>
          </>
        )}
      </Container>
    </ContainerDialog>
  );
};

const ContainerDialog = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackIcon = styled(FontAwesomeIcon)`
  margin-right: auto;
  font-size: 1.5rem;
  color: #999;
  cursor: pointer;

  &:hover {
    scale: 1.1;
  }
`;

const Container = styled.div`
  max-width: 95%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;
const Title = styled.span`
  color: black;
  font-size: 28px;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 38px;
`;

const BookCover = styled.div`
  width: 110px;
  margin: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  &.selected {
    background-color: orange;
  }
  &.opacity {
    img {
      opacity: 0.5;
    }
  }

  &:hover {
    background-color: orange;
  }
  img {
    width: 92%;
    height: 94%;
    object-fit: cover;
  }
`;

const BooksContainer = styled.div`
  display: flex;
  max-width: 400px;
  flex-wrap: wrap;
  justify-content: center;
`;

const Subtitle = styled.span`
  font-size: 1.2em;
  width: 100%;
  margin-top: 14px;
  font-weight: bold;
  color: black;
  align-self: flex-start;
  margin-bottom: 10px;
  span {
    color: orange;
  }
`;

const EditionsContainer = styled.div`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
`;

const Edition = styled.div`
  margin-right: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  &.last {
    margin-right: 0px;
  }
  &:hover {
    text-decoration: underline;
  }
  border: 1px solid #f7ac3d;
  padding: 4px 7px;
  border-radius: 3px;
  font-size: 1.1em;
  color: #f7ac3d;
`;
