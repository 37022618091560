import { useCallback } from 'react';

export default function useCurrency() {
  const formatCurrency = useCallback(value => {
    const valueInString = value.toFixed(2).toString();
    return valueInString.replace('.', ',');
  }, []);

  return { formatCurrency };
}
