import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import BookIcon from '../images/ic-solucionario.png';
import CardsContainer from './CardsContainer';
import { colors, styled } from 'styles';
import { useBookPermission } from 'modules/books/hooks';
import BookSuggestion from '../../books/components/BookSuggestion';
import { useUserContext } from 'providers/UserProvider';

export default function BooksCards({ books, subject, discipline, studyTrack }) {
  const { search } = useLocation();
  const { shouldBooksFirstChaptersBeFree } = useBookPermission();
  const { user } = useUserContext();
  const [guideName, setGuideName] = useState('');
  const getBookAuthor = useCallback(book => book.name.split(' - ')[0], []);
  const getBookLinkUrl = useCallback(book => {
    if (book.editions.length == 1) {
      const edition = book.editions[0];
      return `/materias/solucionario/livro/${book.id}/edicao/${edition.id}`;
    }

    return `/livros-resolvidos/livro/${book.id}/edicoes`;
  }, []);

  useEffect(() => {
    if (subject?.name) {
      setGuideName(subject.name);
    } else if (discipline) {
      setGuideName(`${discipline} - ${user?.university.name}`);
    } else if (studyTrack) {
      setGuideName(`${studyTrack} - ${user?.university.name}`);
    } else {
      setGuideName(`Plano de Estudo - ${user?.university.name}`);
    }
  }, [discipline, subject, studyTrack, user]);

  return (
    <CardsContainer
      color="#2C4F69"
      title="Livros Resolvidos"
      icon={BookIcon}
      shouldHaveFreeTag={shouldBooksFirstChaptersBeFree}
      source="booksCards"
    >
      <Content>
        {books.map(book => (
          <BookItem
            key={book.id}
            to={{ pathname: getBookLinkUrl(book), search: search }}
          >
            <BookInfo>
              <BookAuthor>{getBookAuthor(book)}</BookAuthor>
              <BookVolume>{book.volume}</BookVolume>
            </BookInfo>
            <BookImg src={book.imagePath} />
          </BookItem>
        ))}
      </Content>
      <BookSuggestion guideName={guideName} />
    </CardsContainer>
  );
}

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;

const BookItem = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.03);
  color: ${colors.book};
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const BookInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2px;
`;

const BookAuthor = styled.span`
  font-size: 1.05em;
  font-weight: bold;
`;

const BookVolume = styled.span`
  font-size: 0.8em;
  margin-top: 6px;
`;

const BookImg = styled.img`
  width: 50px;
  height: 65px;
`;
