import React, { useCallback } from 'react';
import styled from 'styled-components';
import { PlayArrow } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';

export default function LecturesItems({ themeData }) {
  const location = useLocation();
  const formatDuration = useCallback(duration => {
    return `${Math.floor(duration / 3600)}h${Math.floor(
      (duration % 3600) / 60
    )}min`;
  }, []);

  return (
    <LecturesContainer>
      {themeData?.subjects?.map(subject => (
        <LectureItem
          key={subject.id}
          color={themeData.color}
          image={subject.imagePath}
          to={{
            pathname: `/lecture/aulao/${subject.lecture.id}`,
            state: { previousUrl: location.pathname },
          }}
        >
          <Title>{subject.name}</Title>
          <IconContainer>
            <PlayIcon fontSize="large" />
          </IconContainer>
          <Duration>{formatDuration(subject.lecture.duration)}</Duration>
          <Tint color={themeData.color} />
        </LectureItem>
      ))}
      ;
    </LecturesContainer>
  );
}

const IconContainer = styled.div`
  height: 65px;
`;

const PlayIcon = styled(PlayArrow)`
  color: white;
  border: 3px solid white;
  border-radius: 50%;
  padding: 7px;
  margin: auto;
  display: block;
  font-weight: lighter;
  height: 60px !important;
  width: 60px !important;
  transition: all 0.2s ease-in-out !important;
`;

const LectureItem = styled(Link)`
  width: 225px;
  height: 133px;
  border-radius: 20px;
  margin: 0 6px 15px 6px;
  box-sizing: border-box;
  text-align: center;
  background-size: cover;
  background-image: url(${({ image }) => image});
  background-color: ${({ color }) => color};
  cursor: pointer;
  z-index: 1;
  &:hover ${PlayIcon} {
    transform: scale(1.1);
  }
`;

const LecturesContainer = styled.div`
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Title = styled.span`
  color: white;
  text-transform: uppercase;
  padding: 15px 10px 0 10px;
  font-size: 1em;
  font-weight: 700;
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  margin-bottom: 5px;
`;

const Duration = styled.span`
  font-size: 0.85em;
  padding-right: 10px;
  font-weight: 600;
  margin-top: 5px;
  text-align: end;
  display: block;
  color: white;
`;

const Tint = styled.div`
  position: relative;
  top: -125px;
  width: 225px;
  height: 133px;
  border-radius: 20px;
  background-color: ${({ color }) => color};
  opacity: 0.8;
  z-index: -1;
`;
