import React, { useCallback, useEffect } from 'react';
import { styled } from 'styles';

import { useDialogContext } from 'providers/DialogProvider';
import { NoAccessPaymentRecoverDialog } from 'modules/campaigns/recover-subscriber';
import { useTracker } from 'modules/shared/hooks';

export default function PaymentFailedRecoverBanner() {
  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();

  useEffect(() => {
    trackEvent('MVP Falha de Pagamento 2.0: Visualizou Banner', {
      where: 'desktop-main-banner',
    });
  }, [trackEvent]);

  const onBannerClick = useCallback(() => {
    setDialog(<NoAccessPaymentRecoverDialog source="desktop-main-banner" />);
  }, [setDialog]);

  return (
    <ContainerBanner onClick={onBannerClick}>
      <Content>
        <CTA>
          <Title>Falha na renovação da sua assinatura</Title>
          <Button>Clique aqui para resolver</Button>
        </CTA>
      </Content>
    </ContainerBanner>
  );
}

const ContainerBanner = styled.div`
  width: 100%;
  height: 120px;
  background-color: #e4613c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1100px;
  width: 90%;
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  color: #fff;
  font-weight: bold;
  font-size: 1.5em;
`;

const Button = styled.button`
  margin-top: 5px;
  font-size: 1.2em;
  background-color: #fff;
  width: 300px;
  padding: 8px 0;
  color: #e4613c;
  border-radius: 20px;
`;
