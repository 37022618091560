import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useRAQueryImmutable } from 'modules/shared/hooks/requests';
import { useDialogContext } from 'providers/DialogProvider';
import { Error, Loading } from 'modules/shared/components/generic';
import { useCheckoutLight } from 'modules/campaigns/checkout-light/hooks';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { styled, colors, media } from 'styles';
import { ButtonsContainer, Button } from '../components';
import CheckoutDialog from './CheckoutDialog';

export default function PlansDialog({ source, removeClose = false }) {
  const [selectedPlan, setselectedPlan] = useState();

  const { data, loading, error } = useRAQueryImmutable('/plans');
  const { getMonthlyPriceValue, getMonthsInfo } = useCheckoutLight();
  const { setDialog, unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  useEffect(() => {
    trackEvent('Checkout Light: Visualizou Planos', {
      ...getUTMsByLocation(window.location),
      Origem: source,
    });
  }, [trackEvent, getUTMsByLocation, source]);

  useEffect(() => {
    if (loading || error) return;

    setselectedPlan(data.plans[0]);
  }, [data, loading, error]);

  const handleNextPress = useCallback(() => {
    setDialog(<CheckoutDialog plan={selectedPlan} removeClose={removeClose} />);
  }, [removeClose, selectedPlan, setDialog]);

  const handleExitPress = useCallback(() => {
    unsetDialog();
  }, [unsetDialog]);

  const plans = useMemo(() => {
    return data?.plans;
  }, [data]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <Container>
      <Content>
        {removeClose ? (
          <></>
        ) : (
          <CloseButton onClick={handleExitPress}>X</CloseButton>
        )}

        <Title>Escolha seu plano</Title>
        <ChoosePlan>
          {plans.map(plan => (
            <PlanOption
              onClick={() => setselectedPlan(plan)}
              className={
                selectedPlan && plan.id === selectedPlan.id && 'selected'
              }
              key={plan.id}
            >
              <PlanDot
                className={
                  selectedPlan && plan.id === selectedPlan.id && 'selected'
                }
              />
              <PlanName>{plan.description}</PlanName>
              <PlanDescription>
                <span>Acesso ilimitado por {getMonthsInfo(plan)}</span>
                <br />
                {plan.months > 1 ? `${plan.months}x de ` : ''}

                <b>R$ {getMonthlyPriceValue(plan, plan.pricing)}</b>
              </PlanDescription>
            </PlanOption>
          ))}
        </ChoosePlan>

        <PurchaseInfo>
          <PurchaeInfoTitle>Garantia de 7 dias</PurchaeInfoTitle>
          <PurchaeInfoDescription>
            Se você não gostar em 7 dias, devolvemos todo $$ sem burocracia
          </PurchaeInfoDescription>
        </PurchaseInfo>

        <ButtonsContainer>
          <Button className="highlight" onClick={handleNextPress}>
            Avançar
          </Button>
        </ButtonsContainer>

        <Faq>
          Dúvidas?{' '}
          <FaqLink href="https://www.respondeai.com.br/faq" target="_blank">
            Leia aqui nossos termos
          </FaqLink>
        </Faq>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  padding: 30px 15px 20px;
  background-color: #fff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
`;

const CloseButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0.6;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 2em;
  margin: 20px 0 40px 0;
`;

const ChoosePlan = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 400px;
  max-width: 100%;
`;

const PlanOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
  cursor: pointer;

  &.selected {
    background-color: rgba(249, 172, 62, 0.1);
  }
`;

const PlanDot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50px;
  border: 2px solid #666;
  background: #fff;

  &.selected {
    background: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
  }
`;

const PlanName = styled.span`
  margin: 5px 0;
  font-size: 1.1em;
  font-weight: bold;
`;

const PlanDescription = styled.span`
  line-height: 1.3;
  font-size: 0.9em;

  span {
    font-size: 0.9em;
    margin-bottom: 5px;
  }

  @media ${media.mobile} {
    font-size: 0.75em;
  }
`;

const PurchaseInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0 10px 0;
  width: 300px;
  max-width: 95%;
`;

const PurchaeInfoTitle = styled.div`
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 5px;
`;

const PurchaeInfoDescription = styled.div`
  font-size: 0.85em;
  opacity: 0.85;
`;

const Faq = styled.div`
  opacity: 0.6;
  font-size: 0.9em;
  margin-top: 20px;
`;

const FaqLink = styled.a`
  text-decoration: underline;
`;
