import React, { useEffect } from 'react';
import TopBackground from '../components/theme-page/top-background/TopBackground';
import SubjectBlock from '../components/theme-page/SubjectBlock';
import { styled } from 'styles';
import { useParams, useHistory } from 'react-router-dom';
import { Loading, Error } from 'modules/shared/components/generic';
import { useUniversityContext } from 'providers/UniversityProvider';
import { useGTM } from 'modules/shared/hooks';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';

export default function ThemePage() {
  const { isFromPrivateUniversityCampaign } = useUniversityContext();

  const { id } = useParams();
  const history = useHistory();
  const { addVariableToGTMDataLayer } = useGTM();

  const { data: theme, loading, error } = useContentQueryImmutable(
    `/themes/${id}/with-subjects`
  );

  useEffect(() => {
    if (theme) {
      addVariableToGTMDataLayer({
        contentMateria: theme.name,
        contentType: 'Matéria',
      });
    }
  }, [theme, addVariableToGTMDataLayer]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  if (!theme) {
    history.push('/materias');
    return null;
  }

  if (!isFromPrivateUniversityCampaign) {
    const subjectsRemovingOnlyPrivateContent = theme.subjects.filter(
      s => !s.isFromPrivateUniversity
    );
    theme.subjects = subjectsRemovingOnlyPrivateContent;
  }

  return (
    <div>
      <TopBackground theme={theme} />
      <ContainerThemes>
        {theme.subjects.map(s => (
          <SubjectBlock
            key={s.id}
            subject={s}
            color={theme.color}
            themeId={id}
          />
        ))}
      </ContainerThemes>
    </div>
  );
}

const ContainerThemes = styled.div`
  max-width: 960px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: -95px auto 0 auto;
  padding: 0 20px;
  margin-bottom: 80px;
`;
