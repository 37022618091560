import { useState, useCallback, useMemo } from 'react';
import useSwrQueryGenerator from './useSwrQueryGenerator';

const baseUrl = `${process.env.REACT_APP_RA_CONTENT_URL}/api/v2`;

export default function useContentLazyQueryImmutable(
  url,
  swrConfig = {},
  recaptcha = {}
) {
  const [makeQuery, setMakeQuery] = useState(false);
  const [queryParams, setQueryParams] = useState('');

  const updateQueryParams = useCallback(params => {
    const newQueryParams = new URLSearchParams(params).toString();
    setQueryParams(newQueryParams);
  }, []);

  const query = useCallback(
    (params = {}) => {
      updateQueryParams(params);
      setMakeQuery(true);
    },
    [updateQueryParams]
  );

  const completeUrl = useMemo(() => {
    return `${baseUrl}${url}${queryParams && `?${queryParams}`}`;
  }, [queryParams, url]);

  const swrResponse = useSwrQueryGenerator(
    makeQuery ? completeUrl : null,
    {
      ...swrConfig,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    },
    recaptcha
  );

  return useMemo(() => {
    return [query, swrResponse];
  }, [query, swrResponse]);
}
