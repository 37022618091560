import React, { useCallback, useEffect } from 'react';

import { styled, colors, media } from 'styles';
import { useUserContext } from 'providers/UserProvider';
import { useTracker } from 'modules/shared/hooks';

export default function FreezeSubscriptionConfirmation({
  freezeEndDate,
  activeUntil,
}) {
  const { user } = useUserContext();
  const { trackEvent } = useTracker();

  useEffect(() => {
    trackEvent('Congelamento Assinatura: Confirmacao Visualizou');
  }, [trackEvent]);

  const handleCloseDialogPress = useCallback(() => {
    window.location.reload(false);
  }, []);

  return (
    <Container>
      <Content>
        <FreezeStrip>Pausar assinatura</FreezeStrip>
        <Title>
          Seu plano foi pausado com sucesso! Mas antes de entrar de férias...
        </Title>

        <Paragraph>
          <li>
            Você recebeu um email de confirmação dessa transação em {user.email}
            .
          </li>
          <br />
          <li>
            Seu plano ficará pausado entre os dias {activeUntil} e{' '}
            {freezeEndDate}. Durante esse período você não receberá nenhuma
            cobrança.
          </li>
          <br />
          <li>
            Durante o período de férias você não terá acesso aos conteúdos do
            Responde Aí.
          </li>
          <br />
          <li>
            Após a pausa, a renovação do seu plano acontecerá em {freezeEndDate}{' '}
            e as cobranças e seu acesso serão normalizados.
          </li>
          <br />
          Bom descanso e boas férias ;)
        </Paragraph>

        <ButtonsContainer>
          <button onClick={handleCloseDialogPress} className="positive">
            Ok, entendi
          </button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-top: 10px solid ${colors.darkGreen};
  text-align: left;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const FreezeStrip = styled.div`
  display: inline-block;
  padding: 3px 10px;
  position: relative;
  left: -48px;
  margin-bottom: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${colors.darkGreen};
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  @media ${media.mobile} {
    left: -24px;
  }
`;

const Title = styled.h1`
  margin-bottom: 15px;
  font-size: 1.6em;
  font-weight: bold;
  color: ${colors.darkGreen};
`;

const Paragraph = styled.p`
  font-size: 1em;
  margin: 20px 0;
  padding: 0 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 500px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 60px;

  button {
    width: 45%;
    font-size: 0.95em;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 20px 20px 0;
    height: 50px;
    border: 2px solid #989898;
    color: #989898;
    text-transform: uppercase;
    font-weight: bold;

    &.positive {
      border-color: ${colors.darkGreen};
      color: ${colors.darkGreen};
    }

    @media ${media.mobile} {
      width: 95%;
    }
  }
`;
