import React, { useCallback, useEffect, useMemo } from 'react';
import { styled, media } from 'styles';
import { useInView } from 'react-intersection-observer';
import { useForumContext } from 'providers/ForumProvider';
import { MathjaxContent } from 'modules/shared/components/generic';
import {
  NoAccessDisclaimer,
  NoUnloggedAccessDisclaimer,
} from 'modules/shared/components/permission';
import { useUserContext } from 'providers/UserProvider';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';

export default function Solution({ blur }) {
  const { user } = useUserContext();
  const { bookExercise } = useBookEditionContext();
  const { setCanShowForumCTA, isForumInView } = useForumContext();

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const solutionSteps = useMemo(() => {
    return bookExercise?.lightSolution || [];
  }, [bookExercise]);

  const getStepClassName = useCallback(
    index => {
      if (index > 0 && blur) return 'blurs';
      else return '';
    },
    [blur]
  );

  useEffect(() => {
    setCanShowForumCTA(isForumInView ? false : inView);
  }, [inView, isForumInView, setCanShowForumCTA]);

  return (
    <Container ref={ref}>
      {solutionSteps.map((step, index) => (
        <div key={index} className={getStepClassName(index)}>
          <StepHeader>Passo {index + 1}</StepHeader>
          <MathjaxContent
            html={index > 0 && blur ? solutionSteps[0] : step}
            dataCy={`book-solution-step-${index + 1}`}
          />
          {index === 0 && blur && (
            <>
              {!user && <NoUnloggedAccessDisclaimer origin="Book Solution" />}
              {user && <StyledNoAccessDisclaimer />}
            </>
          )}
        </div>
      ))}
    </Container>
  );
}

const Container = styled.div`
  padding: 0 30px;

  p,
  li {
    text-align: left;
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media ${media.mobile} {
    padding: 0 10px;
  }
`;

const StepHeader = styled.h2`
  color: #36aaad;
  font-size: 1.5em;
  font-family: 'Droid Serif', serif;
`;

const StyledNoAccessDisclaimer = styled(NoAccessDisclaimer)`
  margin: 60px auto 20px auto;

  @media ${media.mobile} {
    margin: 20px auto;
  }
`;
