import React, { useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';

import { styled, colors, media } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker, useCurrency, useUTM } from 'modules/shared/hooks';
import { useChangePlanCampaign } from 'modules/campaigns/change-plan/hooks';
import PigImage from 'images/campaigns/change-plan/pig.png';
import ChangePlanOptionsDialog from './ChangePlanOptionsDialog';

export default function ChangePlanCTADialog({ subscription }) {
  const { unsetDialog, setDialog } = useDialogContext();
  const { getUTMsByLocation } = useUTM();
  const {
    calculatePlanEconomy,
    getAvailablePlanPricingsToChange,
  } = useChangePlanCampaign();
  const { formatCurrency } = useCurrency();
  const { trackEvent } = useTracker();

  const getFormatedMonthPlanEconomy = useCallback(() => {
    const newPlanPricings = getAvailablePlanPricingsToChange();
    const newPlanPricing = newPlanPricings[newPlanPricings.length - 1];

    const monthPlanEconomy =
      calculatePlanEconomy(subscription, newPlanPricing) /
      newPlanPricing.plan.months;
    return formatCurrency(monthPlanEconomy);
  }, [
    calculatePlanEconomy,
    formatCurrency,
    getAvailablePlanPricingsToChange,
    subscription,
  ]);

  const getAmplitudeEventProperties = useCallback(() => {
    return {
      'Preco Plano Atual': formatCurrency(subscription.order.planPricing.price),
      'Valor Economia Exibido': getFormatedMonthPlanEconomy(),
      Onde: media.isDesktop() ? 'Desktop' : 'Mobile',
      ...getUTMsByLocation(window.location),
    };
  }, [
    subscription,
    formatCurrency,
    getFormatedMonthPlanEconomy,
    getUTMsByLocation,
  ]);

  useEffect(() => {
    trackEvent(
      'Troca de Plano: Dialog Porquinho Visualizou',
      getAmplitudeEventProperties()
    );
  }, [trackEvent, getAmplitudeEventProperties]);

  useEffect(() => {
    Cookies.set('change_plan', 1, { expires: 1 });
  }, []);

  const handleExitPress = useCallback(() => {
    trackEvent('Troca de Plano: Saiu do Fluxo', {
      ...getAmplitudeEventProperties(),
      Origem: 'Dialog Porquinho',
    });
    unsetDialog();
  }, [trackEvent, getAmplitudeEventProperties, unsetDialog]);

  const handleNextPress = useCallback(() => {
    trackEvent(
      'Troca de Plano: Dialog Porquinho Clicou Avançar',
      getAmplitudeEventProperties()
    );
    setDialog(<ChangePlanOptionsDialog subscription={subscription} />);
  }, [trackEvent, getAmplitudeEventProperties, setDialog, subscription]);

  return (
    <Container>
      <Content>
        <Icon icon={faTimes} onClick={handleExitPress} />
        <Image src={PigImage} />
        <Title>TROQUE DE PLANO E</Title>
        <TitleHighlight>
          Economize até R${getFormatedMonthPlanEconomy()}/mês!
        </TitleHighlight>
        <NextButton onClick={handleNextPress}>DESCUBRA</NextButton>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 80px;
  border-top: 10px solid ${colors.purple};
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const Image = styled.img`
  width: 150px;
`;

const Title = styled.div`
  margin-top: 25px;
  font-weight: bold;
  font-size: 1.3em;
`;

const TitleHighlight = styled.div`
  margin-top: 5px;
  padding: 3px 4px;
  background-color: ${colors.mainOrange};
  color: #fff;
  font-weight: bold;
  font-size: 1.5em;
`;

const NextButton = styled.button`
  width: 170px;
  background-color: ${colors.purple};
  color: #fff;
  font-weight: bold;
  font-size: 1.05em;
  padding: 7px 0;
  border-radius: 5px;
  text-align: center;
  margin-top: 25px;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 1.5em;
  opacity: 0.5;
  cursor: pointer;
`;
