import { Skeleton } from '@mui/material';
import React from 'react';

import { media, styled } from 'styles';

export function PageSkeleton() {
  return (
    <Container>
      <Title variant="text" />
      <Percentage variant="text" />
      <Question variant="rounded" />
    </Container>
  );
}

const Container = styled.div`
  width: 1080px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media ${media.mobile} {
    margin-top: 80px;
  }
`;

const Title = styled(Skeleton)`
  &.MuiSkeleton-root {
    margin: 40px 0 20px 0;
    width: 700px;
    max-width: 95%;
    height: 70px;
  }
`;

const Percentage = styled(Skeleton)`
  &.MuiSkeleton-root {
    width: 100%;
    height: 50px;
  }
`;

const Question = styled(Skeleton)`
  &.MuiSkeleton-root {
    margin-top: 30px;
    width: 100%;
    height: 800px;
  }
`;
