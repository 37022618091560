import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import { MdMarkEmailRead } from 'react-icons/md';
import { WhatsApp } from '@material-ui/icons';
import { useRAQuery, useRAPostRequest } from 'modules/shared/hooks/requests';
import { Loading, Error } from 'modules/shared/components/generic';
import Cookies from 'js-cookie';
import { useUserContext } from 'providers/UserProvider';
import { useTracker } from 'modules/shared/hooks';

export default function Notifications() {
  const { data, error, loading } = useRAQuery('/notifications/to_render');
  const { user } = useUserContext();
  const { trackEvent } = useTracker();
  const sendPlannerEmailReq = useRAPostRequest(
    `campaigns/crm_journey/notification_journey`
  );

  useEffect(() => {
    trackEvent('Notificações: Abriu Notificações', {
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
    });

    Cookies.set('opened_notification', false, {
      domain: process.env.REACT_APP_RESPONDEAI_DOMAIN,
    });
  }, [trackEvent]);

  const goGoEmail = useCallback(() => {
    if (user.provider === 'google_oauth2') {
      window.open('https://mail.google.com/mail/u/0/#spam', '_blank');
      return;
    }

    const outlookDomains = ['outlook.com', 'hotmail.com', 'live.com'];
    const yahooDomains = [
      'yahoo.com',
      'yahoo.com.br',
      'ymail.com',
      'ymail.com.br',
    ];
    const domain = user.email.split('@')[1];
    if (outlookDomains.includes(domain)) {
      window.open('https://outlook.live.com/mail/junkemail', '_blank');
    } else if (yahooDomains.includes(domain)) {
      window.open('https://mail.yahoo.com/d/folders/6', '_blank');
    } else {
      window.open('https://mail.google.com/mail/u/0/#spam', '_blank');
    }
  }, [user]);

  const sendToJourney = useCallback(async () => {
    try {
      await sendPlannerEmailReq();
      goGoEmail(false);
    } catch (e) {
      console.log(e);
    }
  }, [sendPlannerEmailReq, goGoEmail]);

  const handleNotificationClick = useCallback(
    notification => {
      trackEvent('Notificações: Clicou Notificação', {
        Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
        Título: notification.title,
      });

      if (notification.notificationType === 'email') {
        sendToJourney();
      }
      if (notification.notificationType === 'redirect') {
        window.open('https://chat.whatsapp.com/J0bkjWej75I0vU2aQ0x0cH');
      }
    },
    [sendToJourney, trackEvent]
  );

  const renderNotificationIcon = useCallback(notificationType => {
    if (notificationType === 'redirect')
      return <WppIcon className="icon wpp-icon" />;
    return <MdMarkEmailRead className="icon" />;
  }, []);

  if (error) return <Error />;
  if (loading) return <Loading />;

  return (
    <>
      {data.length < 0 && (
        <NoNotificationMessage>
          <Title>Você está com tudo em dia!</Title>
          <Body>Nenhuma notificação nova</Body>
        </NoNotificationMessage>
      )}
      {data.map((notification, index) => (
        <Notification
          key={index}
          onClick={() => handleNotificationClick(notification)}
        >
          {renderNotificationIcon(notification.notificationType)}
          <NotificationContent>
            <Title>{notification.title}</Title>
            <Body>{notification.body}</Body>
          </NotificationContent>
        </Notification>
      ))}
    </>
  );
}

const Notification = styled.div`
  padding: 10px 0 10px 0;
  border-bottom: ${({ last }) => (last ? 'none' : '1px solid #00000057')};
  display: flex;
  border-radius: 10px;
  margin-bottom: 4px;
  flex-direction: row;
  width: 100%;
  .icon {
    width: 12%;
    font-size: 21px;
    color: #36d7b7;
  }
  .wpp-icon {
    margin-top: 2px;
    color: #25d366;
  }
  &:hover {
    background-color: #f5f5f5;
    transition: all 0.2s ease;
  }
`;

const WppIcon = styled(WhatsApp)``;

const NoNotificationMessage = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NotificationContent = styled.div`
  width: 85%;
  position: relative;
`;

const Title = styled.div`
  font-size: 17px;
  margin-bottom: 3px;
  color: #141414;
  font-weight: bold;
`;

const Body = styled.div`
  font-size: 14px;
  color: darkgray;
`;
