import React, { useCallback, useEffect, useState } from 'react';
import { styled, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import ContactPreferenceRequestRemotion from 'modules/profile/dialogs/ContactPreferenceRequestRemotion';
import { useTracker } from 'modules/shared/hooks';

export default function ToggleSwitch({ user }) {
  const [permission, setPermission] = useState(true);
  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();

  useEffect(() => {
    if (user.contactContentPreference !== null) {
      setPermission(user.contactContentPreference.permited);
    }
  }, [user]);

  const handleSwitch = useCallback(() => {
    if (permission) {
      setDialog(
        <ContactPreferenceRequestRemotion setPermission={setPermission} />
      );

      trackEvent(
        'Comunicações por e-mail: Clicou no botão Não Permitir Comunicações'
      );
    } else {
      trackEvent('Comunicações por e-mail: Retornar Comunicações', {
        Status: 'Já removido',
      });
    }
  }, [permission, setDialog, setPermission, trackEvent]);

  return (
    <Container className={permission ? '' : 'no-permission'}>
      <Label>
        <Input type="checkbox" checked={permission} onChange={handleSwitch} />
        <Switch permission={permission} data-cy="communication-switch" />
      </Label>
      {!permission ? (
        <>
          <span>Não Permitir Comunicações por email</span>
          <Message>
            Você ainda receberá mensagens transacionais relacionadas a
            informações de conta, assinaturas, cancelamentos e termos de uso.
          </Message>
        </>
      ) : (
        <span>Permitir Comunicações por email</span>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 20px;

  &.no-permission {
    padding-bottom: 60px;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
`;

const Switch = styled.div`
  width: 38px;
  height: 20px;
  background: #ddd;
  border-radius: 10px;
  border: 1px solid ${colors.darkGray};
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 35px;
    background: ${colors.darkGray};
    top: 50%;
    right: ${props => (props.permission ? '11px' : '12px')};
    left: ${props => (!props.permission ? '5px' : '')};
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  display: none;

  &:checked + ${Switch} {
    border: 1px solid #5fb070;
    &${Switch}:before {
      background: #5fb070;
    }
    &:before {
      transform: translate(8px, -50%);
    }
  }
`;

const Message = styled.p`
  position: absolute;
  top: 25px;
  left: 48px;
  font-size: 13px;
  font-weight: bold;
  max-width: 250px;
  padding-bottom: 20px;
  margin-top: 8px;
`;
