import { useProfessorTracker } from 'modules/guides/hooks';
import React, { useCallback } from 'react';
import { styled } from 'styles';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';
import { useSupportMaterialBanner } from 'modules/guides/hooks/useSupportMaterialBanner';

const SLIDER_SETTINGS = {
  speed: 500,
  slidesToShow: 1, // Será sobrescrito pelo `variableWidth`
  slidesToScroll: 1,
  infinite: false, // Desativa o loop
  variableWidth: true, // Permite largura variável dos slides
};

export default function GuideSection({ professor, subjects, guideId }) {
  const { trackClickSubject } = useProfessorTracker();
  const history = useHistory();
  const { setCookieShouldNotShowBanner } = useSupportMaterialBanner();

  const handleSubjectClick = useCallback(
    subject => {
      setCookieShouldNotShowBanner();
      trackClickSubject({
        professorName: professor.name,
        disciplineName: professor.teachable.name,
        subjectName: subject.name,
        lists: [...professor.lists, ...professor.statementLists],
      });

      const topicId = subject.chapters[0] && subject.chapters[0].topics[0]?.id;
      const theoryId = topicId && subject.chapters[0].topics[0].theory?.id;

      history.push(
        `/aprender/${guideId}/topico/${subject.id}/${topicId}/teoria/${theoryId}`,
        { previousUrl: history.location.pathname }
      );
    },
    [
      trackClickSubject,
      professor,
      history,
      guideId,
      setCookieShouldNotShowBanner,
    ]
  );

  return (
    <Container>
      <Title>Todos os assuntos de Cálculo</Title>
      <SubTitle>
        Aqui você encontra a teoria de todas as suas matérias em texto ou vídeo.
      </SubTitle>
      <Slider {...SLIDER_SETTINGS}>
        {subjects.map(subject => (
          <SubjectContainer
            key={subject.id}
            imgSrc={subject.imagePath}
            bgColor={professor.teachable.theme.color}
            onClick={() => handleSubjectClick(subject)}
          >
            <SubjectTitle>{subject.name}</SubjectTitle>
          </SubjectContainer>
        ))}
      </Slider>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 20px auto 20px auto;
  color: #515151;

  .slick-arrow {
    ::before {
      color: black !important;
      font-size: 25px !important;
    }
  }
`;

const Title = styled.p`
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const SubTitle = styled.p`
  font-size: 17px;
`;

const SubjectContainer = styled.div`
  position: relative;
  width: 120px !important;
  height: 180px;
  margin: 5px;
  margin-top: 10px;
  background-color: ${({ bgColor }) => bgColor};
  background-image: ${({ imgSrc }) => `url(${imgSrc})`};
  background-position: center 20px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
  cursor: pointer;
  &:hover .playerIco {
    font-size: 70px;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    transition: all 0.1s ease;
  }
`;

const SubjectTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: calc(50% + 35px);
  left: 5px;
`;
