import { Row } from '../shared/styles';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { media } from 'styles';

function ActionButton({
  text,
  image,
  hoverImage = null,
  clickImage = null,
  onClick = () => {},
  underline = false,
  className = '',
  showTextOnMobile = false,
  shouldHideText = false,
  dataCy = '',
}) {
  const [isClicked, setIsClicked] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleMouseHover = useCallback(() => {
    setIsHover(true);
  }, [setIsHover]);

  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
  }, [setIsHover]);

  return (
    <Container
      onClick={() => {
        setIsClicked(true);
        onClick();
      }}
      className={className}
      data-cy={dataCy}
    >
      <Image
        style={!isClicked && !isHover ? {} : { width: '0' }}
        src={image}
        onMouseEnter={handleMouseHover}
        onMouseLeave={handleMouseLeave}
        data-cy="default-image"
      />
      <HoverImage
        style={isClicked || !isHover ? { width: '0' } : {}}
        src={hoverImage}
      />
      <ClickedImage
        style={isClicked ? {} : { width: '0' }}
        src={clickImage || image}
        data-cy="clicked-image"
      />

      {(media.isDesktop() || (media.isMobile() && showTextOnMobile)) &&
        !shouldHideText && <Text underline={underline}>{text}</Text>}
    </Container>
  );
}

export default ActionButton;

const Container = styled(Row)`
  margin: 10px 10px 10px 0;
  cursor: pointer;
`;

const Text = styled.span`
  margin-left: 5px;
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  font-size: 15px;
  color: #a2a2a2;
`;

const Image = styled.img``;

const HoverImage = styled.img``;

const ClickedImage = styled.img``;
