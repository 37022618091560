import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { styled, media } from 'styles';
import { Loading, Error } from 'modules/shared/components/generic';
import { SideMenuListExercises } from 'modules/workspace/components/side-menu';
import { ListExerciseContentContainer } from '../components/content';
import UnloggedHeader from '../components/UnloggedHeader';
import ScrollToTopButton from 'modules/shared/components/ScrollToTopButton';
import WorkspaceFlagsProvider from '../providers/WorkspaceFlagsProvider';
import WorkspaceListExerciseProvider from '../providers/WorkspaceListExerciseProvider';
import { useUserContext } from 'providers/UserProvider';
import { useWorkspaceGuide } from '../hooks/shared';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import ForumProvider from 'providers/ForumProvider';
import MathJaxProvider from 'providers/MathJaxProvider';

export default function WorkspaceListExercisePage() {
  const location = useLocation();
  const { guideId } = useParams();
  const { user } = useUserContext();

  const { getGuidableNameFromGuide, getThemeFromGuide } = useWorkspaceGuide();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const { data: guide, loading, error } = useContentQueryImmutable(
    `/guides/${guideId}/with-list-exercises-from-guidable`
  );

  if (error) return <Error />;
  if (loading) return <Loading />;

  return (
    <MathJaxProvider>
      <ForumProvider>
        <WorkspaceFlagsProvider>
          <WorkspaceListExerciseProvider
            contentTitle={getGuidableNameFromGuide(guide)}
            guide={guide}
            theme={getThemeFromGuide(guide)}
          >
            <Container>
              {!user && media.isDesktop() && <UnloggedHeader />}
              <SideMenuListExercises />
              <ListExerciseContentContainer
                theme={getThemeFromGuide(guide)}
                guide={guide}
              />
            </Container>
            <ScrollToTopButton />
          </WorkspaceListExerciseProvider>
        </WorkspaceFlagsProvider>
      </ForumProvider>
    </MathJaxProvider>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #f6f6f6;
`;
