import { useWorkspaceErrorReport } from 'modules/workspace/hooks/main';
import { useCallback } from 'react';

export default function useForumRedirectToExerciseErrorReport() {
  const { getErrorReportData } = useWorkspaceErrorReport();

  const getContentLocationData = useCallback(() => {
    return getErrorReportData();
  }, [getErrorReportData]);
  return { getContentLocationData };
}
