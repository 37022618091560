import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { PageSkeleton } from '../components/ListWithStatementQuestion';
import { useContentQuery } from 'modules/shared/hooks/requests';
import { useToast } from 'modules/shared/hooks';

export function AiExerciseRedirect() {
  const { aiExerciseId } = useParams();
  const history = useHistory();
  const { errorToast } = useToast();

  const { data, error } = useContentQuery(`/ai/ai-exercises/${aiExerciseId}`);

  useEffect(() => {
    if (!data) return;

    history.push(data.link);
  }, [data, history]);

  useEffect(() => {
    if (!error) return;

    errorToast('Desculpe, não conseguimos encontrar sua questão!');
    history.push('/materias');
  }, [error, history, errorToast]);

  return <PageSkeleton />;
}
