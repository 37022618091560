import React from 'react';
import { styled } from 'styles';

export default function SyncLoader({ color = '#000000', size = 15 }) {
  return (
    <Container>
      <Bullets iteration={1} color={color} size={size} />
      <Bullets iteration={2} color={color} size={size} />
      <Bullets iteration={3} color={color} size={size} />
    </Container>
  );
}

const Container = styled.span`
  display: inherit;
`;

const Bullets = styled.span`
  display: inline-block;
  background-color: ${({ color }) => color};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin: 2px;
  border-radius: 100%;
  animation: sync 0.6s calc(${props => props.iteration} * 0.07s) infinite
    ease-in-out;
  animation-fill-mode: both;

  @keyframes sync {
    33% {
      transform: translateY(10px);
    }
    66% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
