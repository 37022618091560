import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import React, { useEffect } from 'react';
import RedirectTab from './RedirectTab';

export default function LectureTab() {
  const { bookExercise } = useBookEditionContext();

  useEffect(() => {
    if (!bookExercise) return;

    window.open(
      `${process.env.REACT_APP_RESPONDEAI_URL}/lecture/aulao/${bookExercise.topic.subject.lecture?.id}`,
      '_blank'
    );
  }, [bookExercise]);

  if (!bookExercise) return null;

  return (
    <RedirectTab
      to={`/lecture/aulao/${bookExercise.topic.subject.lecture?.id}`}
      textCTA="Abrir aulão deste assunto"
    />
  );
}
