import React, { useEffect, useCallback, useState } from 'react';

import { styled, colors, media } from 'styles';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';
import { useEmailInvolvement } from 'modules/campaigns/email-involvement';
import { useTracker } from 'modules/shared/hooks';
import stepImage from 'images/campaigns/email-involvement/step.png';
import stepImageMobile from 'images/campaigns/email-involvement/step-mobile.jpeg';

function EmailInvolvement() {
  const [loading, setLoading] = useState(false);
  const { user } = useUserContext();
  const { unsetDialog } = useDialogContext();
  const { clearEmailInvolvementCookies } = useEmailInvolvement();
  const { trackEvent } = useTracker();

  useEffect(() => {
    trackEvent('Email Involvement: Visualizou Dialog', {
      versão: 'Dialog tela cheia',
    });
  }, [trackEvent]);

  useEffect(() => {
    clearEmailInvolvementCookies();
  }, [clearEmailInvolvementCookies]);

  const goGoEmail = useCallback(() => {
    if (user.provider === 'google_oauth2') {
      trackEvent('Email Involvement: Clicou CTA', {
        type: 'google',
        versão: 'Dialog tela cheia',
      });
      window.open('https://mail.google.com/mail/u/0/#spam', '_blank');
      unsetDialog();
      return;
    }

    const outlookDomains = ['outlook.com', 'hotmail.com', 'live.com'];
    const yahooDomains = [
      'yahoo.com',
      'yahoo.com.br',
      'ymail.com',
      'ymail.com.br',
    ];
    const domain = user.email.split('@')[1];
    if (outlookDomains.includes(domain)) {
      trackEvent('Email Involvement: Clicou CTA', {
        type: 'outlook',
        versão: 'Dialog tela cheia',
      });
      window.open('https://outlook.live.com/mail/junkemail', '_blank');
      unsetDialog();
    } else if (yahooDomains.includes(domain)) {
      trackEvent('Email Involvement: Clicou CTA', {
        type: 'yahoo',
        versão: 'Dialog tela cheia',
      });
      window.open('https://mail.yahoo.com/d/folders/6', '_blank');
      unsetDialog();
    } else {
      trackEvent('Email Involvement: Clicou CTA', {
        type: 'google',
        versão: 'Dialog tela cheia',
      });
      window.open('https://mail.google.com/mail/u/0/#spam', '_blank');
      unsetDialog();
    }
  }, [user, trackEvent, unsetDialog]);

  const ctaClick = useCallback(() => {
    if (loading) return;
    setLoading(true);

    setTimeout(() => goGoEmail(), 900);
  }, [loading, setLoading, goGoEmail]);

  const getUserName = useCallback(() => {
    return user.name.split(' ')[0];
  }, [user]);

  const closeDialog = useCallback(() => {
    trackEvent('Email Involvement: Fechou Dialog', {
      versão: 'Dialog tela cheia',
    });
    unsetDialog();
  }, [trackEvent, unsetDialog]);

  return (
    <Container>
      <Title className="highlight">
        Você acabou de receber um Presente Grátis
      </Title>
      <Title>do Responde Aí!</Title>
      <CTAButton onClick={ctaClick}>
        {loading ? (
          <>
            Estamos enviando seu presente...
            <Small>Pode demorar até 1 minuto</Small>
          </>
        ) : (
          <>
            <Small>Entre no email {user.email} e</Small>Pegue AGORA o seu
            Presente!
          </>
        )}
      </CTAButton>
      <CloseButton onClick={closeDialog}>
        Não quero presente &#128546;
      </CloseButton>
      <InstructionsContainer>
        <InstructionsTitle>
          Para pegar, é só seguir esse passo a passo. Simples Assim ;)
        </InstructionsTitle>

        <StepsContainer>
          <Step>
            Entre no seu email e clique na opção <Bold>"Mais"</Bold> do menu
            lateral, até encontrar a opção <Bold>"Spam"</Bold>
            <StepNumber>1</StepNumber>
          </Step>

          <Step>
            Você vai encontrar o email do João com o título:{' '}
            <Bold>
              Agora você faz parte da comunidade do Responde Aí, {getUserName()}
            </Bold>
            <StepNumber>2</StepNumber>
          </Step>

          <Step>
            Ai é só clicar no botão de <Bold>Não é Spam</Bold> que tem no topo
            do email.
            <StepNumber>3</StepNumber>
          </Step>

          <Step>
            Para terminar, vai na caixa de entrada, abre esse email e{' '}
            <Bold>pegue seu presente.</Bold> Prontinho!
            <StepNumber>4</StepNumber>
          </Step>
        </StepsContainer>

        <InstructionsTitle>Passo a passo na prática</InstructionsTitle>
        <Image src={media.isMobile() ? stepImageMobile : stepImage} />
      </InstructionsContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.9);
  padding-top: 40px;

  @media ${media.mobile} {
    padding-top: 80px;
  }
`;

const Title = styled.h1`
  max-width: 95%;
  font-size: 3.8em;
  color: #fff;
  font-weight: bold;

  &.highlight {
    background-color: #fff;
    color: #000;
    padding: 2px 10px;
    font-size: 3em;
  }

  @media ${media.mobile} {
    font-size: 2em;

    &.highlight {
      font-size: 1.2em;
    }
  }
`;

const CTAButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  width: 450px;
  max-width: 95%;
  padding: 25px 0;
  border-radius: 55px;
  background-color: ${colors.mainOrange};
  color: #000;
  font-size: 1.4em;
  font-weight: bold;
  cursor: pointer;

  @media ${media.mobile} {
    margin-top: 30px;
    padding: 20px 0;
    font-size: 1.2em;
  }
`;

const Small = styled.div`
  font-size: 0.6em;
  margin-bottom: 3px;
`;

const CloseButton = styled.h3`
  font-size: 0.9em;
  margin-top: 10px;
  margin-bottom: 40px;
  text-decoration: underline;
  cursor: pointer;

  @media ${media.mobile} {
    font-size: 0.8em;
  }
`;

const InstructionsContainer = styled.div`
  width: 95%;
  max-width: 1280px;
  background-color: #fff;
  padding: 10px 10px;
  border-radius: 15px;
`;

const InstructionsTitle = styled.h1`
  font-size: 1.7em;
  font-weight: bold;
  color: #000;
  margin: 20px 0;

  @media ${media.mobile} {
    font-size: 1.4em;
  }
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;

  @media ${media.mobile} {
    justify-content: center;
  }
`;

const Step = styled.div`
  position: relative;
  width: 230px;
  max-width: 95%;
  padding: 25px 20px;
  border-radius: 10px;
  background-color: #eaeaea;
  color: #000;
  margin: 0 3px 35px 3px;
`;

const StepNumber = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: ${colors.mainOrange};
  border-radius: 50%;
  color: #fff;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Image = styled.img`
  max-width: 95%;
`;

export default EmailInvolvement;
