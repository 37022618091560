import useForumTracker from 'modules/forum/hooks/useForumTracker';
import { useToast } from 'modules/shared/hooks';
import { useRAPostFileRequest } from 'modules/shared/hooks/requests';
import { useDialogContext } from 'providers/DialogProvider';
import React, { useCallback, useMemo } from 'react';
import ExitConfirmation from './ExitConfirmation';
import PostManager from './PostManager';

export default function CreateOrUpdateAnswer({
  action,
  answer = null,
  question,
  setQuestion,
  setShouldShowCreateOrUpdate,
}) {
  const { setDialog } = useDialogContext();
  const { trackAnsweredQuestion, trackEditPost } = useForumTracker();
  const saveAnswer = useRAPostFileRequest('ra_forum/answers');
  const editAnswer = useRAPostFileRequest(
    `/ra_forum/answers/${answer?.id}/edit`
  );
  const { errorToast } = useToast();

  const isEditing = useMemo(() => {
    return action === 'edition';
  }, [action]);

  const buttonTextData = useMemo(() => {
    return {
      default: isEditing ? 'Salvar Alterações' : 'Responder',
      onSending: isEditing ? 'Salvando...' : 'Respondendo...',
    };
  }, [isEditing]);

  const onBack = useCallback(() => {
    if (isEditing) {
      setDialog(
        <ExitConfirmation onExit={() => setShouldShowCreateOrUpdate(false)} />
      );
    } else setShouldShowCreateOrUpdate(false);
  }, [setShouldShowCreateOrUpdate, setDialog, isEditing]);

  const afterSubmit = useCallback(
    data => {
      const questionCopy = { ...question };
      let answersUpdated;
      if (isEditing) {
        const answerIndex = question.answers.findIndex(
          answer => answer.id === data.id
        );
        answersUpdated = question.answers.filter(
          answer => answer.id !== data.id
        );
        answersUpdated.splice(answerIndex, 0, data);
        questionCopy.answers = answersUpdated;
      } else {
        questionCopy.answers.push(data);
      }

      setQuestion(questionCopy);
      setShouldShowCreateOrUpdate(false);
    },
    [setQuestion, question, setShouldShowCreateOrUpdate, isEditing]
  );

  const onSubmit = useCallback(
    async (params, file = null) => {
      let dataSend = {
        questionId: question.id,
        body: params.text,
        questionUrl: location.href,
      };

      if (file) params['filename'] = file.name;
      if (isEditing) {
        try {
          const { data } = await editAnswer(file, {
            ...dataSend,
            answerId: answer.id,
          });
          afterSubmit(data);
          trackEditPost('Resposta');
        } catch (e) {
          errorToast('Erro ao enviar resposta!');
        }
      } else {
        try {
          const { data } = await saveAnswer(file, dataSend);
          afterSubmit(data);
          trackAnsweredQuestion();
        } catch (e) {
          errorToast('Erro ao enviar resposta!');
        }
      }
    },
    [
      isEditing,
      saveAnswer,
      editAnswer,
      errorToast,
      afterSubmit,
      question,
      trackAnsweredQuestion,
      trackEditPost,
      answer,
    ]
  );

  return (
    <PostManager
      isEditing={isEditing}
      postContent={answer}
      onBack={onBack}
      onSubmit={onSubmit}
      hasBackButton={false}
      buttonTextData={buttonTextData}
    />
  );
}
