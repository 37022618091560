import React, { useMemo, useEffect } from 'react';

import { styled, colors, media } from 'styles';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import {
  useSummary,
  useWorkspacePermission,
} from 'modules/workspace/hooks/main';
import { Loading, Error } from 'modules/shared/components/generic';
import { MathjaxContent } from 'modules/shared/components/generic';
import { useUserContext } from 'providers/UserProvider';
import { useContentLazyQueryImmutable } from 'modules/shared/hooks/requests';

export default function SummaryContent() {
  const { currentSummaries } = useSummary();
  const { currentContentAccess } = useWorkspacePermission();
  const { setCurrentContent } = useWorkspaceContentContext();
  const { user } = useUserContext();

  const recaptchaConfig = useMemo(() => {
    if (user) return null;

    return { action: 'view_theory' };
  }, [user]);

  const [
    getSummaries,
    { data: summaries, loading, error },
  ] = useContentLazyQueryImmutable(`/summaries`, {}, recaptchaConfig);

  useEffect(() => {
    if (!currentSummaries) return;

    const summariesIds = currentSummaries.map(summary => summary.id);
    getSummaries({ ids: summariesIds });
  }, [currentSummaries, getSummaries]);

  useEffect(() => {
    if (summaries) setCurrentContent(summaries[0]);
  }, [summaries, setCurrentContent]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  if (!summaries) return null;

  return (
    <Container data-cy="summaries-content">
      {summaries.map(summary => (
        <div key={summary.id}>
          <SummaryTitle>{summary.topic.name}</SummaryTitle>
          <Content>
            <MathjaxContent
              html={summary.lightBody}
              dataCy="summary-text-content"
              replaceToFakeContent={!currentContentAccess}
            />
          </Content>
        </div>
      ))}
    </Container>
  );
}

const Container = styled.div`
  background: #fff;
  padding-top: 70px;
  border-top: 1px solid #e5e5e5;
`;

const SummaryTitle = styled.div`
  font-size: 1.65em;
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
  color: ${colors.summary};
`;

const Content = styled.div`
  padding: 0 30px 30px 30px;
  margin-bottom: 60px;
  font-size: 1.05em;
  border-bottom: 1px solid #e5e5e5;

  p {
    padding-bottom: 15px;
  }

  h2,
  b {
    color: ${colors.theory} !important;
  }

  @media ${media.mobile} {
    padding: 0 10px 30px 10px;
    font-size: 1.15em;
  }
`;
