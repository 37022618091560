import React from 'react';
import DesktopFrontBanner from 'modules/banner/main-banner/DesktopFrontBanner';
import SideBanner from 'modules/banner/side-banner/SideBanner';
import {
  ThemesMenu,
  ThemeContents,
} from 'modules/main/components/themes-page/theme';
import ProfileCompleteButton from '../components/themes-page/buttons/ProfileCompleteButton';
import PrivateThemesMenu from '../components/themes-page/theme/PrivateThemesMenu';
import { ThemesContainer, LeftContainer, RightContainer } from './styles';
import UserProfessorsListDesktop from '../components/themes-page/professor/UserProfessorsListDesktop';
import { useProfessor } from 'modules/professors/hooks/useProfessor';

export default function ThemesPageDesktop({
  generalThemes,
  privateThemes,
  disciplines,
  selectedTheme,
  setSelectedTheme,
  studyPlans,
  mvpLists,
  listsWithStatement,
}) {
  const { showUserProfessors } = useProfessor();

  return (
    <>
      <DesktopFrontBanner />
      {showUserProfessors() && <UserProfessorsListDesktop />}
      <ThemesContainer showingBigSearch={true}>
        <LeftContainer>
          <SideBanner />
          {privateThemes.length > 0 && (
            <PrivateThemesMenu
              privateThemes={privateThemes}
              disciplines={disciplines}
              onSelectTheme={setSelectedTheme}
              selected={selectedTheme || privateThemes[0] || generalThemes[0]}
            />
          )}
          <ThemesMenu
            themes={generalThemes}
            selected={selectedTheme || privateThemes[0] || generalThemes[0]}
            onSelectTheme={setSelectedTheme}
            disciplines={disciplines}
          />
        </LeftContainer>

        <RightContainer>
          <ProfileCompleteButton />
          <ThemeContents
            selectedTheme={
              selectedTheme || privateThemes[0] || generalThemes[0]
            }
            disciplines={disciplines}
            studyPlans={studyPlans}
            mvpLists={mvpLists}
            listsWithStatement={listsWithStatement}
          />
        </RightContainer>
      </ThemesContainer>
    </>
  );
}
