import { useRALazyQueryImmutable } from 'modules/shared/hooks/requests';
import { useCallback } from 'react';
import { useUserContext } from 'providers/UserProvider';
import { NOT_UNIVERSITIES } from './sharedData.js';

function useBannerMakerFunctions() {
  const [getBannerLayoutData, response] = useRALazyQueryImmutable(
    '/banners/banner_maker/layout_from_banner_to_render'
  );

  const { user } = useUserContext();

  const isUserNotOnUniversity = useCallback(() => {
    if (!user?.university?.id) return true;
    return NOT_UNIVERSITIES.includes(user?.university?.id);
  }, [user]);

  const getTitleToShow = useCallback(
    title => {
      if (isUserNotOnUniversity() && title.defaultText)
        return title.defaultText;
      return title.text;
    },
    [isUserNotOnUniversity]
  );

  const getSubTitleToShow = useCallback(
    subtitle => {
      if (isUserNotOnUniversity() && subtitle.defaultText)
        return subtitle.defaultText;
      return subtitle.text;
    },
    [isUserNotOnUniversity]
  );

  return {
    getTitleToShow,
    getSubTitleToShow,
    getBannerLayoutData,
    bannerData: response.data,
  };
}

export { useBannerMakerFunctions };
