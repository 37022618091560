import { ProfileInfo, ProfileTag } from '../shared/styles';
import React, { useMemo } from 'react';
import { styled } from 'styles';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import pt from 'dayjs/locale/pt-br';
dayjs.extend(relativeTime);
dayjs.locale(pt);

export default function QuestionUserProfile({
  user,
  university,
  universityCourse,
  createdAt,
  size = 'normal',
}) {
  const humanizedCreatedAt = useMemo(() => {
    if (!createdAt) return '';
    // from now in brazilian portuguese
    return dayjs()
      .locale('pt')
      .from(dayjs(createdAt), true);
  }, [createdAt]);

  const printableName = useMemo(() => {
    if (!user?.name) return 'Anônimo';

    const firstName = user?.name?.split(' ')[0];
    return firstName[0].toUpperCase() + firstName.slice(1).toLowerCase();
  }, [user]);

  const userIsNotOnUniversity = useMemo(() => {
    return university === 'Não faço faculdade';
  }, [university]);

  return (
    <Container>
      <ProfileImg src={user.pictureUrl} size={size} />
      <Column>
        <Row>
          <ProfileName size={size}>{printableName}</ProfileName>
          <CreatedAt size={size}>{`há ${humanizedCreatedAt}`}</CreatedAt>
        </Row>
        <ProfileInfo>
          {!userIsNotOnUniversity && (
            <ProfileTag size={size}>{university}</ProfileTag>
          )}
          {!userIsNotOnUniversity && universityCourse && (
            <ProfileTag size={size}>{universityCourse}</ProfileTag>
          )}
          {userIsNotOnUniversity && user.academicEducation && (
            <ProfileTag size={size}>{user.academicEducation}</ProfileTag>
          )}
        </ProfileInfo>
      </Column>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: default;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 5px;
`;

const CreatedAt = styled.span`
  font-size: ${({ size }) => (size === 'small' ? '0.7rem' : '0.8rem')};
  color: gray;
  margin-left: 10px;
`;

const ProfileImg = styled.img`
  width: ${({ size }) => (size === 'small' ? '40px' : '50px')};
  height: ${({ size }) => (size === 'small' ? '40px' : '50px')};
  border-radius: 50%;
  margin-right: 10px;
  align-self: flex-start;
`;

const ProfileName = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.5);
  font-size: ${({ size }) => (size === 'small' ? '0.9rem' : '17px')};
`;
