import React from 'react';
import { Route } from 'react-router-dom';
import LearningPath from './pages/LearningPath';

export default [
  <Route
    exact
    path="/mvp/trilha-de-estudos"
    component={LearningPath}
    key="learning-path"
  />,
];
