import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { styled, media } from 'styles';

export default function ContentFeedbackGrade({
  src,
  text,
  onClick,
  isSelected,
}) {
  return (
    <Container onClick={onClick} isSelected={isSelected}>
      {isSelected && <CheckIcon icon={faCheckCircle} right="25" />}
      <img alt="" className="grade-element" src={src} />
      <div className="grade-description">{text}</div>
    </Container>
  );
}

const Container = styled.div`
  cursor: pointer;
  display: block;
  min-width: 100px;
  position: relative;
  font-size: 0.9em;

  :hover {
    .grade-description {
      visibility: visible;
    }

    .grade-element {
      filter: none;
    }
  }

  .grade-element {
    max-width: 40px !important;
    ${({ isSelected }) => isSelected || 'filter: grayscale(100%);'}
  }

  @media ${media.desktop} {
    .grade-description {
      visibility: hidden;
    }
  }
`;

const CheckIcon = styled(FontAwesomeIcon)`
  filter: none;
  color: green;
  position: absolute;
  right: ${({ right }) => right || '0'}%;
  top: 0;
`;
