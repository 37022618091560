import QuestionPreview from './QuestionPreview';
import ForumHeader from './ForumHeader';
import {
  faSpinner as loadingIcon,
  faExclamationCircle as errorIcon,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRAQuery } from 'modules/shared/hooks/requests';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from 'styles';
import CreateOrUpdateQuestion from '../generic/CreateOrUpdateQuestion';
import useForumTracker from 'modules/forum/hooks/useForumTracker';
import { useForumContext } from 'providers/ForumProvider';

export default function Questions({ setSelectedQuestion }) {
  const [questions, setQuestions] = useState([]);
  const [shouldShowCreateOrUpdate, setShouldShowCreateOrUpdate] = useState(
    false
  );
  const {
    trackClickedToCreateQuestion,
    trackDeletedQuestion,
  } = useForumTracker();
  const { forumContent: content } = useForumContext();

  const { data: questionsData, loading, error } = useRAQuery(
    '/ra_forum/questions',
    {
      contentType: content?.type,
      contentId: content?.id,
    }
  );

  useEffect(() => {
    if (questionsData) setQuestions(questionsData);
  }, [questionsData]);

  const questionsCountText = useMemo(() => {
    if (!questions) return null;

    const count = questions.length;

    if (count === 0) return 'Nenhuma pergunta';
    if (count === 1) return '1 Pergunta';

    return `${count} Perguntas`;
  }, [questions]);

  const onCreateQuestionButton = useCallback(() => {
    trackClickedToCreateQuestion();
    setShouldShowCreateOrUpdate(true);
  }, [trackClickedToCreateQuestion, setShouldShowCreateOrUpdate]);

  if (loading || error)
    return (
      <Container>
        <ForumHeader onCreateQuestionButton={onCreateQuestionButton} />
        {loading && (
          <MessageContainer>
            Carrregando...
            <Icon icon={loadingIcon} spin />
          </MessageContainer>
        )}
        {error && (
          <MessageContainer>
            Ocorreu um erro ao carregar as perguntas
            <Icon icon={errorIcon} />
          </MessageContainer>
        )}
      </Container>
    );

  if (shouldShowCreateOrUpdate)
    return (
      <CreateOrUpdateQuestion
        action="create"
        setShouldShowCreateOrUpdate={setShouldShowCreateOrUpdate}
        setSelectedQuestion={setSelectedQuestion}
      />
    );

  return (
    <Container>
      <ForumHeader onCreateQuestionButton={onCreateQuestionButton} />
      {questions?.length > 3 && (
        <QuestionsCount>{questionsCountText}</QuestionsCount>
      )}
      {questions.map(question => (
        <QuestionPreview
          key={question.id}
          question={question}
          setSelectedQuestion={setSelectedQuestion}
          onDelete={() => {
            setQuestions(questions.filter(q => q.id !== question.id));
            trackDeletedQuestion();
          }}
        />
      ))}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const QuestionsCount = styled.div`
  width: 100%;
  margin-bottom: 20px;
  font-size: 1.2em;
`;

const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1.1em;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;
