import React from 'react';
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { styled, colors } from 'styles';

export default function ExerciseFlagFeedback({ feedback, useRef }) {
  if (!feedback) return null;

  return (
    <Container ref={useRef} data-cy="exercise-flag-feedback">
      <DexterImg src={feedback.image} />
      <MessageContainer>
        <Title>
          <IconContainer className={feedback.type}>
            <FontAwesomeIcon
              icon={feedback.type === 'check' ? faCheck : faExclamation}
            />
          </IconContainer>
          {feedback.title}
        </Title>
        <Subtitle dangerouslySetInnerHTML={{ __html: feedback.subtitle }} />
      </MessageContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 500px;
  max-width: 95%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`;

const DexterImg = styled.img`
  width: 80px;
`;

const MessageContainer = styled.div`
  position: relative;
  top: -10px;
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 2px solid ${colors.lightGray};
  background-color: #f3f3f3;
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.2em;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 3px solid #fff;
  border-radius: 30px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  margin-right: 8px;
  color: #fff;
  font-size: 0.9em;

  &.check {
    background-color: ${colors.check};
  }

  &.see_later {
    background-color: ${colors.darkGray};
  }
`;

const Subtitle = styled.div`
  text-align: center;
  margin-top: 8px;
  font-size: 0.9em;
  line-height: 1.5;
`;
