import React from 'react';

import { media, styled } from 'styles';
import WorkspaceIconsContainer from './WorkspaceIconsContainer';

export default function ContentHeader({ color, title, label, iconsData }) {
  return (
    <Container data-cy="content-header">
      <WorkspaceIconsContainer iconsData={iconsData} />
      <Label>{label}</Label>
      <Title color={color}>{title}</Title>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 20px;
  position: relative;

  @media ${media.mobile} {
    padding: 20px 5px 10px 5px;
  }
`;

const Label = styled.h2`
  font-size: 0.8em;
  color: #ccc;
  font-style: italic;
  font-weight: bold;
`;

const Title = styled.h1`
  font-family: 'Droid Serif', serif;
  color: ${({ color }) => color};
  font-size: 1.85em;
  margin-top: 12px;

  @media ${media.mobile} {
    font-size: 1.4em;
  }
`;
