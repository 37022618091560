import React from 'react';
import { styled } from 'styles';
import { media } from 'styles';

export default function DexterChallengeBanner() {
  return (
    <Container>
      <span>
        Tivemos problemas técnicos com as dicas do Desafio do Dexter, mas já
        está tudo resolvido! Por causa disso, resolvemos adiar a liberação da
        pista 3 para o dia <strong>04/08 às 13:00</strong>. Se você já tinha
        resolvido as pistas anteriores, a dica já está <strong>certa!</strong>
      </span>
    </Container>
  );
}

const Container = styled.div`
  background: #ead308;
  width: 100%;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 3px;
  @media ${media.mobile} {
    height: inherit;
    font-size: 10px;
  }
  z-index: 3;
`;
