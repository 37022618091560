import React, { useCallback } from 'react';

import { styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';

import {
  FreezeSubscriptionCancelation,
  FreezeSubscriptionReactivate,
} from 'modules/campaigns/freeze-subscription/dialogs';

export default function FreezeSubscriptionOptionsButton({ subscription }) {
  const { setDialog } = useDialogContext();

  const handleCancelSubscriptionPress = useCallback(() => {
    setDialog(<FreezeSubscriptionCancelation subscription={subscription} />);
  }, [subscription, setDialog]);

  const handleReactivateSubscriptionPress = useCallback(() => {
    setDialog(<FreezeSubscriptionReactivate subscription={subscription} />);
  }, [subscription, setDialog]);

  return (
    <Container>
      <Option onClick={handleReactivateSubscriptionPress}>
        Reativar assinatura
      </Option>
      <Option onClick={handleCancelSubscriptionPress}>
        Cancelar a assinatura
      </Option>
      <Option onClick={() => window.UserVoice.show()}>
        Duvidas? Entre em contato com o atendimento
      </Option>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Option = styled.button`
  background-color: #eee;
  font-size: 1em;
  padding: 5px 15px;
  margin: 5px 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  svg {
    margin-right: 5px;
  }
`;
