import React, { useEffect, useState } from 'react';
import { styled } from 'styles';
import ReminderActivation from './ReminderActivation';
import LectureInfo from './LectureInfo';

export default function Schedule(props) {
  const { scheduledLectures, chosenSubject } = props;
  const [lecturesFromSubjectToShow, setLecturesFromSubjectToShow] = useState(
    null
  );

  useEffect(() => {
    const specificLectures = scheduledLectures.filter(
      l => l.theme === chosenSubject
    );
    setLecturesFromSubjectToShow(specificLectures);
  }, [chosenSubject, scheduledLectures]);

  return (
    <Container>
      <ReminderActivation chosenSubject={chosenSubject} />
      {lecturesFromSubjectToShow &&
        lecturesFromSubjectToShow.map(l => (
          <LectureInfo key={l.id} lecture={l} />
        ))}
    </Container>
  );
}

const Container = styled.div`
  background: #fff;
  width: 100%;
  max-height: 330px;
  padding: 1rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  overflow-y: scroll;

  ::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 0.5em;
  }
  ::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    padding: 0.5em;
  }
  ::-webkit-scrollbar-thumb {
    background: #ddd;
    margin: 0 5px 0 0;
    border-radius: 1em;
  }
`;
