import { styled } from 'styles';

const ContainerBanner = styled.div`
  background: #b47fb7;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;

  .tag {
    position: absolute;
    width: 250px;
    padding: 10px 0;
    left: 0;
    top: 40px;
    transform: rotate(-41deg);
    background-color: #51a751;
    color: #fff;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  .main-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;

    .banner-copy {
      color: #fff;
      text-align: center;
      margin-left: 50px;
      font-size: 1.2em;
      margin-right: 30px;

      h1 {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 1.2em;

        span {
          color: #b47fb7;
          background: #fff;
          padding: 0 5px;
        }
      }
    }

    img {
      width: 450px;
      margin-bottom: -8px;
    }
  }
`;

export { ContainerBanner };
