import React from 'react';

import { styled } from 'styles';

export default function ThemeItem({ color, imagePath, name, onClick }) {
  return (
    <Container onClick={onClick}>
      <ImageContainer color={color}>
        <Image src={imagePath} />
      </ImageContainer>
      <Name>{name}</Name>
    </Container>
  );
}

const Container = styled.div`
  width: 132px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const ImageContainer = styled.div`
  background-color: ${props => props.color};
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
  width: 90px;
  height: 90px;
  margin-top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
`;

const Name = styled.p`
  font-weight: bold;
  font-size: 0.9em;
  text-align: center;
  margin: 10px auto 0 auto;
`;
