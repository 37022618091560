import React from 'react';
import styled from 'styled-components';

import { media } from 'styles';
import shineIcon from 'images/book/shine-icon.png';

export default function BookNotCompleteMessage() {
  return (
    <Container>
      <Image src={shineIcon} />
      Novas questões toda terça-feira!
    </Container>
  );
}

const Container = styled.span`
  font-size: 1.1em;
  font-weight: bold;
  color: #14b9a6;
  margin: 0 25px 0 0;

  @media ${media.mobile} {
    text-align: center;
    margin: 0;
  }
`;

const Image = styled.img`
  position: relative;
  top: 5px;
  left: -5px;
  height: 25px;
`;
