import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import { MathjaxContent } from 'modules/shared/components/generic';
import React, { useMemo } from 'react';
import { styled } from 'styles';

export default function Answer({ blur }) {
  const { bookExercise } = useBookEditionContext();

  const fakeAnswer = useMemo(() => {
    if (!bookExercise?.lightSolution) return '';

    return bookExercise.lightSolution[0];
  }, [bookExercise]);

  return (
    <div>
      <StepHeader>Resposta</StepHeader>
      <AnswerContainer className={blur && 'blurs'}>
        <MathjaxContent
          html={blur ? fakeAnswer : bookExercise?.lightAnswer}
          dataCy="book-answer"
        />
      </AnswerContainer>
    </div>
  );
}

const AnswerContainer = styled.div`
  border-left: 4px solid #36aaad;
  padding: 20px;
  font-size: 1.2em;

  p,
  li {
    color: #888;
    margin-bottom: 15px;
    text-align: left;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

const StepHeader = styled.h2`
  color: #36aaad;
  font-size: 1.5em;
  font-family: 'Droid Serif', serif;
`;
