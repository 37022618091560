import useForumTracker from '../hooks/useForumTracker';
import { CourseDialog, Questions, SelectedQuestion } from './index';
import { useForumContext } from 'providers/ForumProvider';
import { useUserContext } from 'providers/UserProvider';
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { media, styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';

export default function Forum() {
  const { search } = useLocation();
  const { forumContent: content } = useForumContext();
  const { setDialog } = useDialogContext();

  const { setCanShowForumCTA, setIsForumInView } = useForumContext();

  const questionToOpen = useMemo(
    () => new URLSearchParams(search).get('question'),
    [search]
  );

  const answerToOpen = useMemo(
    () => new URLSearchParams(search).get('answer'),
    [search]
  );

  const { user } = useUserContext();
  const { trackClickedToSeeQuestion } = useForumTracker();

  const { ref, inView } = useInView({
    threshold: 0.75,
  });

  const [notifiedView, setNotifiedView] = useState(false);
  useEffect(() => {
    setIsForumInView(inView);
    setCanShowForumCTA(inView);
    if (!inView || notifiedView) return;

    setNotifiedView(true);
  }, [inView, notifiedView, setCanShowForumCTA, setIsForumInView]);

  useEffect(() => {
    setNotifiedView(false);
  }, [content]);

  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const showQuestions = useMemo(() => {
    return !selectedQuestion;
  }, [selectedQuestion]);
  const showSelectedQuestion = useMemo(() => {
    return !!selectedQuestion;
  }, [selectedQuestion]);

  const onChangeSelectedQuestion = useCallback(
    selectedQuestion => {
      trackClickedToSeeQuestion();
      if (!user?.course) {
        setDialog(
          <CourseDialog
            onChange={() => {
              setSelectedQuestion(selectedQuestion);
            }}
          />
        );
        return;
      }
      setSelectedQuestion(selectedQuestion);
    },
    [user, trackClickedToSeeQuestion, setDialog]
  );

  const scrollRef = useRef();
  useEffect(() => {
    if (!questionToOpen) return;

    onChangeSelectedQuestion(questionToOpen);

    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, [onChangeSelectedQuestion, questionToOpen, ref]);

  if (!user) return null;

  return (
    <Container ref={ref}>
      <SubContainer ref={scrollRef} id="forumContainer">
        {showQuestions && (
          <Questions setSelectedQuestion={onChangeSelectedQuestion} />
        )}
        {showSelectedQuestion && (
          <SelectedQuestion
            id={selectedQuestion}
            setSelectedQuestion={onChangeSelectedQuestion}
            answerToFocus={answerToOpen}
          />
        )}
      </SubContainer>
    </Container>
  );
}

const Container = styled.div``;

const SubContainer = styled.div`
  width: 960px;
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  border-radius: 5px;
  padding: ${media.isMobile() ? '10px 12px' : '10px 40px'};
  align-items: flex-start;
  color: black;
  background-color: white;
  margin-bottom: 30px;
  margin-top: 0;
`;
