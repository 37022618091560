import React, { useCallback, useState, useRef, useEffect } from 'react';

import { styled, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import {
  UserDataForm,
  CreditCardForm,
} from 'modules/campaigns/checkout-light/components';
import { PlansDialog, SuccessDialog } from '.';
import { useCheckoutLight } from 'modules/campaigns/checkout-light/hooks';
import { useTracker } from 'modules/shared/hooks/Tracker';

export default function CheckoutDialog({ plan, removeClose = false }) {
  const { setDialog } = useDialogContext();
  const { getMonthsInfo, processPurchase } = useCheckoutLight();
  const { trackEvent } = useTracker();

  const [step, setStep] = useState(1);
  const [purchaseData, setPurchaseData] = useState({
    userData: null,
    billingAddress: {
      postalCode: '20091-901',
      district: 'Centro',
      street: 'Rua Visconde de Inhauma',
      number: '134',
      state: 'RJ',
      city: 'Rio de Janeiro',
      cityCode: '3304557',
      complement: '20 Andar Sala 2001',
    },
    creditCard: null,
    planPricingId: plan.pricing.id,
  });
  const [loading, setLoading] = useState(false);
  const [purchaseErrorMessage, setPurchaseErrorMessage] = useState(null);
  const componentRef = useRef();

  useEffect(() => {
    componentRef.current.scrollIntoView();
  }, [step]);

  const afterCompleteUserData = useCallback(
    userData => {
      setPurchaseData({ ...purchaseData, userData });
      setStep(2);
    },
    [purchaseData]
  );

  const backFromUserDataForm = useCallback(() => {
    setDialog(<PlansDialog removeClose={removeClose} />);
  }, [removeClose, setDialog]);

  const afterCompleteCreditCardData = useCallback(
    async (creditCard, installments) => {
      if (loading) return;
      setLoading(true);

      trackEvent('Enviou Pagamento', {
        'Forma de Pagamento': 'Cartão',
        Plano: plan.description,
        'Gateway de Pagamento': 'NEW_MUNDIPAGG',
      });

      const form = {
        ...purchaseData,
        creditCard,
        installments,
        paymentMethod: 'credit_card',
        trialAfterPurchase: true,
      };
      const success = await processPurchase(form);
      if (!success) {
        setLoading(false);
        setPurchaseErrorMessage('Erro ao processar pagamento.');
        setTimeout(() => setPurchaseErrorMessage(null), 5000);
        return;
      }

      setDialog(<SuccessDialog plan={plan} />);
    },
    [loading, purchaseData, processPurchase, setDialog, trackEvent, plan]
  );

  const backFromCreditCardForm = useCallback(() => {
    if (loading) return;
    setStep(1);
  }, [loading, setStep]);

  return (
    <Container ref={componentRef}>
      <Content>
        <Title>Preencha suas informações</Title>
        <SelectedPlanInfo>
          <b>Plano {plan.description}</b>
          <br />
          Acesso ilimitado {getMonthsInfo(plan, plan.pricing)}
          <br />
          Renovação automática
        </SelectedPlanInfo>

        <StepsContainer>
          <Step className={step === 1 && 'selected'} />
          <Step className={step === 2 && 'selected'} />
        </StepsContainer>

        <StepInputContainer>
          {step === 1 && (
            <UserDataForm
              afterCompleteUserData={afterCompleteUserData}
              backFromUserDataForm={backFromUserDataForm}
              initialUserData={purchaseData.userData}
              plan={plan}
            />
          )}
          {step === 2 && (
            <CreditCardForm
              afterCompleteCreditCardData={afterCompleteCreditCardData}
              backFromCreditCardForm={backFromCreditCardForm}
              loading={loading}
              plan={plan}
            />
          )}
        </StepInputContainer>

        {purchaseErrorMessage && (
          <PurchaseErrorContainer>
            <PurchaseErrorMessage>
              <b>Erro ao tentar criar sua assinatura</b>
              <br />
              {purchaseErrorMessage}
            </PurchaseErrorMessage>
          </PurchaseErrorContainer>
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  padding: 30px 15px 20px;
  background-color: #fff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 2em;
  margin: 20px 0 40px 0;
`;

const SelectedPlanInfo = styled.div`
  width: 300px;
  max-width: 95%;
  font-size: 0.8em;
  margin-bottom: 15px;
  line-height: 1.4;

  b {
    font-size: 1.5em;
    line-height: 1.5;
  }
`;

const StepsContainer = styled.div`
  width: 120px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;
`;

const Step = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #ccc;

  &.selected {
    width: 14px;
    height: 14px;
    background-color: ${colors.mainOrange};
  }
`;

const StepInputContainer = styled.div`
  width: 400px;
  max-width: 90%;
  margin-top: 15px;
`;

const PurchaseErrorContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PurchaseErrorMessage = styled.div`
  width: 380px;
  max-width: 95%;
  padding: 20px;
  border-radius: 10px;
  background-color: ${colors.warningRed};
  color: #fff;
  line-height: 1.2;

  b {
    line-height: 2;
    font-size: 1.1em;
  }
`;
