import React, { useMemo, useEffect, useCallback } from 'react';
import { media } from 'styles';
import {
  Container,
  WrapperContent,
  ExerciseFlag,
} from 'modules/workspace/components/content/shared';
import LectureReminder from './LectureReminder';
import MainContentHeader from './MainContentHeader';
import MainContentTopNavigation from './MainContentTopNavigation';
import TheoryContent from './TheoryContent';
import SummaryContent from './SummaryContent';
import MainExerciseContent from './MainExerciseContent';
import MainContentFeedbackContainer from './MainContentFeedbackContainer';
import MainWorkspaceNavigationButtons from './MainWorkspaceNavigationButtons';
import WorkspaceBanner from 'modules/banner/workspace-banner/WorkspaceBanner';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import { useBannerContext } from 'providers/BannerProvider';
import {
  useWorkspace,
  useWorkspacePermission,
  useContentFeedback,
  useWorkspaceTracker,
  useWorkspaceNavigation,
  useWorkspaceMainFlags,
  useWorkspaceRenderDialog,
} from 'modules/workspace/hooks/main';
import { useWorkspaceExercise } from 'modules/workspace/hooks/shared';
import { Forum } from 'modules/forum/components';
import useForumPermission from 'modules/forum/hooks/useForumPermission';
import { useForumContext } from 'providers/ForumProvider';
import WorkspaceMobileBanner from 'modules/banner/workspace-banner/WorkspaceMobileBanner';
import useActivationCounter from 'modules/shared/hooks/useActivationCounter';

export default function MainContentContainer() {
  const { setForumContent } = useForumContext();
  const { shouldShowForumForUserInContent } = useForumPermission();
  const { addActivationToUser } = useActivationCounter();

  const {
    currentTheme,
    currentChapter,
    currentTopic,
    currentContent,
    setCurrentContent,
    setCurrentContentFormat,
  } = useWorkspaceContentContext();
  const { bannersToShowInfo } = useBannerContext();
  const {
    getCurrentContentData,
    isTheoryWorkspace,
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    isExtraExerciseWorkspace,
    isExtraTimePageWorkspace,
    isSummaryWorkspace,
  } = useWorkspace();
  const {
    trackViewContent,
    trackFormatChange,
    trackFirstVideoPlay,
  } = useWorkspaceTracker();
  const { getListExercisesFromChapter } = useWorkspaceExercise();
  const { currentContentAccess } = useWorkspacePermission();
  const { showContentFeedback } = useContentFeedback();
  const { nextContentLink } = useWorkspaceNavigation();
  const { showFlagExercise } = useWorkspaceMainFlags();

  const showWorkspaceDesktopBanner = useMemo(() => {
    return media.isDesktop() && bannersToShowInfo?.workspaceBannerName;
  }, [bannersToShowInfo]);

  const showWorkspaceMobileBanner = useMemo(() => {
    return media.isMobile() && bannersToShowInfo?.workspaceBannerName;
  }, [bannersToShowInfo]);

  const isListExerciseExercise = useMemo(() => {
    return (
      isUniversityExerciseWorkspace() ||
      isExtraExerciseWorkspace() ||
      isExtraTimePageWorkspace()
    );
  }, [
    isUniversityExerciseWorkspace,
    isExtraExerciseWorkspace,
    isExtraTimePageWorkspace,
  ]);

  const exerciseContentTrackMethods = useMemo(() => {
    return {
      trackFormatChange,
      trackFirstVideoPlay,
    };
  }, [trackFormatChange, trackFirstVideoPlay]);

  const listExercises = useMemo(() => {
    if (currentTopic) return currentTopic.listExercises;

    return getListExercisesFromChapter(currentChapter);
  }, [currentTopic, getListExercisesFromChapter, currentChapter]);

  const showTopNavigation = useCallback(() => {
    return !isSummaryWorkspace() && !isExtraTimePageWorkspace();
  }, [isSummaryWorkspace, isExtraTimePageWorkspace]);

  const hideBlurWrapper = useCallback(() => {
    if (isExtraTimePageWorkspace()) return true;

    return currentContentAccess;
  }, [isExtraTimePageWorkspace, currentContentAccess]);

  useWorkspaceRenderDialog();

  useEffect(() => {
    if (currentContent) trackViewContent();
  }, [trackViewContent, currentContent]);

  const forumContent = useMemo(() => {
    let data = {
      theme: currentTheme,
      id: currentContent?.id,
      chapter: currentChapter,
      topic: currentTopic,
    };

    if (isTheoryWorkspace()) return { ...data, type: 'Theory' };

    if (isFixationExerciseWorkspace())
      return { ...data, type: 'FixationExercise' };
    if (isUniversityExerciseWorkspace())
      return { ...data, type: 'ListExercise' };
    if (isExtraTimePageWorkspace() || isExtraExerciseWorkspace())
      return { ...data, type: 'ExtraTimeExercise' };
    return {};
  }, [
    currentChapter,
    currentTheme,
    currentTopic,
    isTheoryWorkspace,
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    currentContent,
    isExtraTimePageWorkspace,
    isExtraExerciseWorkspace,
  ]);

  useEffect(() => {
    setForumContent(forumContent);
  }, [forumContent, setForumContent]);

  useEffect(() => {
    if (!currentContent) return;
    addActivationToUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContent]);

  if (!currentChapter) return null;
  return (
    <Container>
      {showWorkspaceDesktopBanner && <WorkspaceBanner />}
      {showWorkspaceMobileBanner && <WorkspaceMobileBanner />}
      {!showWorkspaceMobileBanner && !showWorkspaceDesktopBanner && (
        <LectureReminder />
      )}

      <WrapperContent
        color={getCurrentContentData('color')}
        contentAccess={hideBlurWrapper()}
      >
        <MainContentHeader />
        {showTopNavigation() && <MainContentTopNavigation />}
        {isTheoryWorkspace() && <TheoryContent />}
        {isSummaryWorkspace() && <SummaryContent />}
        {isFixationExerciseWorkspace() && (
          <MainExerciseContent
            exercises={currentTopic.exercises}
            contentAccess={currentContentAccess}
            exerciseContentTrackMethods={exerciseContentTrackMethods}
            setCurrentContent={setCurrentContent}
            setCurrentContentFormat={setCurrentContentFormat}
          />
        )}
        {isListExerciseExercise && (
          <MainExerciseContent
            exercises={listExercises}
            contentAccess={currentContentAccess}
            exerciseContentTrackMethods={exerciseContentTrackMethods}
            setCurrentContent={setCurrentContent}
            setCurrentContentFormat={setCurrentContentFormat}
          />
        )}
        {showContentFeedback() && <MainContentFeedbackContainer />}
        <MainWorkspaceNavigationButtons />
      </WrapperContent>
      {showFlagExercise() && <ExerciseFlag nextContentLink={nextContentLink} />}

      {shouldShowForumForUserInContent(forumContent) && (
        <div
          style={{
            marginTop: '20px',
          }}
        >
          <Forum />
        </div>
      )}
    </Container>
  );
}
