import { useTracker } from 'modules/shared/hooks';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { styled, media } from 'styles';
import { testPeriods } from './forms-options';
import { useFormOptions } from './hooks/useFormOptions';
import {
  DoubleButtonContainer,
  SelectContainer,
  selectCustomComponents,
  selectCustomStyles,
  StepsContainer,
} from './styles';

export default function FileInfo({
  materialType,
  testInfo,
  setTestInfo,
  onSubmitInfo,
  onFormBack,
  discipline,
  selectedTheme,
}) {
  const [testCategory, setTestCategory] = useState('');
  const [testPeriod, setTestPeriod] = useState('');
  const [testYear, setTestYear] = useState('');

  const shouldShowCategoryInput = useMemo(() => materialType !== 'Lista', [
    materialType,
  ]);

  const { trackEvent } = useTracker();
  const { categoriesByUniversity } = useFormOptions();

  useEffect(() => {
    trackEvent('Listas: Visualizou Dialog de Infos de Prova/Lista');
  }, [trackEvent]);

  useEffect(() => {
    if (testInfo) {
      const selectedTestCategory = categoriesByUniversity?.find(
        t => t.label === testInfo.category
      );
      setTestCategory(selectedTestCategory?.label);

      const labelValueToSearch = `${testInfo.year}.${testInfo.period}`;
      const selectedTestYearAndPeriod = testPeriods.find(
        t => t.label === labelValueToSearch
      );

      const separatedYearAndPeriod = selectedTestYearAndPeriod?.label.split(
        '.'
      );

      if (separatedYearAndPeriod) {
        const selectedTestYear = separatedYearAndPeriod[0];
        const selectedTestPeriod = separatedYearAndPeriod[1];
        setTestPeriod(selectedTestPeriod);
        setTestYear(selectedTestYear);
      }
    }
  }, [testInfo, categoriesByUniversity]);

  const canSubmit = useMemo(() => {
    return (
      testPeriod !== '' &&
      testYear !== '' &&
      (shouldShowCategoryInput ? testCategory !== '' : true)
    );
  }, [testCategory, testPeriod, testYear, shouldShowCategoryInput]);

  const handleNext = useCallback(() => {
    if (!canSubmit) return;

    const testInfoData = {
      category: testCategory,
      period: parseInt(testPeriod),
      year: parseInt(testYear),
    };
    setTestInfo(testInfoData);
    onSubmitInfo();
    trackEvent('Listas: Dialog de Infos de Prova/Lista - Informou', {
      'Tipo de Material': materialType,
      Matéria: selectedTheme?.name,
      Disciplina: discipline?.name,
      Categoria: testCategory,
      Periodo: testPeriod,
      Ano: testYear,
    });
  }, [
    testCategory,
    testPeriod,
    testYear,
    canSubmit,
    onSubmitInfo,
    trackEvent,
    materialType,
    discipline,
    selectedTheme,
    setTestInfo,
  ]);

  const handleBack = useCallback(() => {
    onFormBack();
  }, [onFormBack]);

  const handleSelectCategory = useCallback(
    e => {
      setTestCategory(e.label);
      setTestInfo({ ...testInfo, category: e.label });
    },
    [setTestCategory, setTestInfo, testInfo]
  );

  const handleSelectPeriod = useCallback(
    e => {
      const separatedYearAndPeriod = e.label.split('.');
      setTestYear(separatedYearAndPeriod[0]);
      setTestPeriod(separatedYearAndPeriod[1]);
      setTestInfo({
        ...testInfo,
        period: separatedYearAndPeriod[1],
        year: separatedYearAndPeriod[0],
      });
    },
    [setTestPeriod, setTestYear, setTestInfo, testInfo]
  );

  const testYearAndPeriodInputValue = useMemo(() => {
    if (!testYear || !testPeriod) return null;

    const labelValue = `${testYear}.${testPeriod}`;
    return { label: labelValue };
  }, [testYear, testPeriod]);

  const testCategoryInputValue = useMemo(() => {
    if (!testCategory) return null;
    return { label: testCategory };
  }, [testCategory]);

  return (
    <Container>
      <Title>
        <h1>
          De quando é a {materialType.toLowerCase()} que você quer enviar?{' '}
        </h1>
      </Title>
      <StepsContainer>
        <span></span>
        <span></span>
        <span className={'fill'}></span>
        <span></span>
        <span></span>
      </StepsContainer>
      <FormContainer>
        {shouldShowCategoryInput && (
          <SelectContainer
            options={categoriesByUniversity}
            value={testCategoryInputValue}
            onChange={handleSelectCategory}
            placeholder={'Tipo de avaliação'}
            components={selectCustomComponents}
            styles={selectCustomStyles}
            dropDown
            isSearchable={false}
          />
        )}
        <SelectContainer
          options={testPeriods}
          value={testYearAndPeriodInputValue}
          onChange={handleSelectPeriod}
          placeholder={'Período'}
          components={selectCustomComponents}
          styles={selectCustomStyles}
          dropDown
          shouldSetZindex
          isSearchable={false}
        />
      </FormContainer>
      <DoubleButtonContainer>
        <Button backButton onClick={handleBack}>
          VOLTAR
        </Button>
        <Button canSubmit={canSubmit} onClick={handleNext}>
          PRÓXIMO
        </Button>
      </DoubleButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  position: sticky;
  z-index: 1;
`;

export const Button = styled.button`
  width: 48%;
  border: 1px solid
    ${({ backButton, canSubmit }) =>
      canSubmit || backButton ? '#f6ac3a' : '#B8B8B8'};
  border-radius: 10px;

  color: ${({ backButton }) => (backButton ? '#f6ac3a' : '#fff')};
  background-color: ${({ backButton, canSubmit }) =>
    backButton ? '#fff' : canSubmit ? '#f6ac3a' : '#B8B8B8'};
  margin: 45px 0px;
  padding: 15px;

  font-weight: bold;
  font-size: 1.1em;

  cursor: pointer;

  @media ${media.mobile} {
    margin: 10px;
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-top: 10px solid #f6ac3a;
  border-radius: 10px;
  padding: 35px 10px;
  padding-bottom: 45px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  color: #7a7a7a;
  background-color: white;
  align-self: center;

  width: 80%;
  max-width: 600px;

  @media ${media.mobile} {
    /* padding: 30px; */
    width: 85%;
  }
`;

const Title = styled.div`
  color: #888;
  font-weight: bold;
  text-align: center;

  h1 {
    font-size: 2.2em;

    @media ${media.mobile} {
      font-size: 2em;
    }
  }

  h3 {
    font-size: 1.1em;
    margin-top: 10px;
    line-height: 20px;

    @media ${media.mobile} {
      font-size: 1em;
    }
  }
`;
