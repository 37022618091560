import React, { useCallback } from 'react';

import { MobileBannerMaker } from 'modules/banner/shared/banners';
import { styled } from 'styles';
import { useBannerContext } from 'providers/BannerProvider';

function MobileBooksBanner() {
  const { bannersToShowInfo } = useBannerContext();

  const getPriorityBanner = useCallback(() => {
    if (bannersToShowInfo?.mobileFrontBannerName === 'mobileBannerMaker')
      return <MobileBannerMaker source="Book" />;
  }, [bannersToShowInfo]);

  return <Container>{getPriorityBanner()}</Container>;
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 100;
  cursor: pointer;
  overflow: hidden;
`;

export default MobileBooksBanner;
