import React from 'react';

import { ContentFeedback } from 'modules/feedback/components';

import {
  useContentFeedback,
  useWorkspaceTracker,
} from 'modules/workspace/hooks/main';
import { CONTENT_FEEDBACK_GRADES } from 'modules/workspace/consts/content-feedback-grades';

export default function MainContentFeedbackContainer() {
  const {
    feedbackContentType,
    feedbackContentId,
    feedbackContentName,
    feedbackContentFormat,
    feedbackMediaProviderId,
    feedbackFeelings,
    feedbackHeaderText,
  } = useContentFeedback();
  const {
    trackContentFeedbackNote,
    trackContentFeedbackFeeling,
    trackContentFeedbackComment,
  } = useWorkspaceTracker();

  if (!feedbackContentType || !feedbackContentFormat || !feedbackContentId)
    return null;
  return (
    <ContentFeedback
      contentType={feedbackContentType}
      contentId={feedbackContentId}
      contentName={feedbackContentName}
      contentMediaType={feedbackContentFormat}
      contentMediaProviderId={feedbackMediaProviderId || ''}
      allGrades={CONTENT_FEEDBACK_GRADES}
      allFeelings={feedbackFeelings}
      headerText={feedbackHeaderText}
      trackFeedbackNoteCallback={trackContentFeedbackNote}
      trackFeedbackFeelingCallback={trackContentFeedbackFeeling}
      trackFeedbackCommentCallback={trackContentFeedbackComment}
      dataCy="content-feedback-workspace"
    />
  );
}
