import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled, media } from 'styles';
import bookListIcon from '../images/book_list_icon.png';
import CardsContainer from './CardsContainer';
import uploadIcon from '../../campaigns/mvp-books/images/upload_icon.svg';
import { useDialogContext } from 'providers/DialogProvider';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { BookListProfessorToggle } from './index';
import { useUserContext } from 'providers/UserProvider';
import { IoCalendarOutline } from 'react-icons/io5';
import { useTracker } from 'modules/shared/hooks';

export default function BookListCards({
  professors,
  acronymDisciplineUniversity,
  currentPeriod,
  disciplineName,
}) {
  const { setDialog } = useDialogContext();
  const { user } = useUserContext();
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const { trackEvent } = useTracker();

  const getListsFromProfessorAndPeriod = useCallback(
    professor => {
      if (!selectedPeriod) return [];

      let lists = professor.lists || [];
      let listsWithStatement = professor.statementLists || [];

      const allLists = [...lists, ...listsWithStatement];

      return allLists.filter(list => list?.period?.id === selectedPeriod.id);
    },
    [selectedPeriod]
  );

  const professorsThatHaveListsInSelectedPeriod = useMemo(() => {
    if (currentPeriod?.id === selectedPeriod?.id) return professors;

    return professors.filter(
      professor => getListsFromProfessorAndPeriod(professor).length > 0
    );
  }, [
    currentPeriod,
    getListsFromProfessorAndPeriod,
    professors,
    selectedPeriod,
  ]);

  const periods = useMemo(() => {
    if (!professors) return [];

    const allPeriods = professors.reduce((acc, professor) => {
      const periods = professor.lists.map(list => list.period);

      periods.forEach(period => {
        if (!acc[period.id]) {
          acc[period.id] = period;
        }
      });

      return acc;
    }, {});

    const pastPeriods = Object.values(allPeriods)
      .filter(period => period.id !== currentPeriod.id)
      .sort((a, b) => (a.name < b.name ? 1 : -1));

    return [currentPeriod, ...pastPeriods];
  }, [professors, currentPeriod]);

  const onPeriodClick = useCallback(
    period => {
      trackEvent('Página de Disciplina: Clicou Período', {
        Período: period,
        Disciplina: disciplineName,
      });
      setSelectedPeriod(period);
    },
    [disciplineName, trackEvent]
  );

  const getPeriodName = useCallback(() => {
    if (!currentPeriod || !selectedPeriod) return `Atual`;

    if (selectedPeriod.id === currentPeriod.id)
      return `Atual ( ${selectedPeriod.name} )`;

    return selectedPeriod.name;
  }, [currentPeriod, selectedPeriod]);

  const numberOfFakePeriods = useMemo(() => {
    if (!periods) return 0;

    return 3 - periods.slice(0, 3).length;
  }, [periods]);

  useEffect(() => {
    if (!currentPeriod) return;

    setSelectedPeriod(currentPeriod);
  }, [currentPeriod]);

  return (
    <CardsContainer
      color="#70BFC7"
      title={`Listas resolvidas dos professores ${acronymDisciplineUniversity}`}
      icon={bookListIcon}
      shouldHaveFreeTag={false}
      source="lecturesCards"
      styleTitle={{ margin: '0 3px 40px 3px', fontSize: '0.9em' }}
      styleIcon={{ marginTop: '23px' }}
    >
      <PeriodTitle>
        <CalenderIcon />
        <p>{`Período ${getPeriodName()}`}</p>
      </PeriodTitle>

      <PeriodsContainer>
        {periods?.slice(0, 3).map(period => (
          <Period
            className={selectedPeriod?.id === period?.id ? 'selected' : ''}
            key={period.id}
            onClick={() => onPeriodClick(period)}
          >
            {period.name}
          </Period>
        ))}
        {Array(numberOfFakePeriods)
          .fill(null)
          .map((_, i) => (
            <FakePeriod key={i} />
          ))}
      </PeriodsContainer>

      <ListContainer>
        {professorsThatHaveListsInSelectedPeriod.map((professor, i) => (
          <BookListProfessorToggle
            key={i}
            professorName={professor.name}
            lists={getListsFromProfessorAndPeriod(professor)}
          />
        ))}
      </ListContainer>
      {user && (
        <ButtonToUploadList
          onClick={() =>
            setDialog(
              <UploadBookListDialog
                origin="Botão Pagina Disciplina"
                utms={{
                  utmSource: 'site_upload',
                  utmMedium: ' botao_enviar_lista_fora_toggle',
                  utmContent: 'pag_disciplina',
                }}
              />
            )
          }
        >
          <img src={uploadIcon} />
          <p>Quero enviar minha lista</p>
        </ButtonToUploadList>
      )}
    </CardsContainer>
  );
}

const PeriodsContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
`;

const Period = styled.div`
  width: 33%;
  padding: 5px 10px;
  font-size: 16px;
  color: #515151;
  border-right: 1px solid #70bfc7;
  border-top: 1px solid #70bfc7;
  border-bottom: 0;
  border-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  cursor: pointer;
  border-bottom: 1px solid #70bfc7;
  text-align: center;

  &:first-child {
    width: 34%;
    border-left: 1px solid #70bfc7;
    border-top-left-radius: 2px;
  }

  &.selected {
    color: #70bfc7;
    border-bottom: 0;
  }
`;

const FakePeriod = styled.div`
  width: 33%;
  border-bottom: 1px solid #70bfc7;
`;

const PeriodTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 10px 5%;
  color: #515151;
  font-weight: bold;
`;

const CalenderIcon = styled(IoCalendarOutline)`
  font-size: 17px;
  color: #515151;
  margin-right: 7px;
  @media ${media.mobile} {
    margin-right: 3px;
  }
`;

const ListContainer = styled.ul`
  width: 90%;
  margin: 0 auto;
  border-radius: 6px;
  border: 1px solid #70bfc7;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
`;

const ButtonToUploadList = styled.div`
  width: 90%;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px auto 0 auto;
  color: #70bfc7;
  border: 1px solid #70bfc7;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;

  img {
    width: 24px;
    height: 24px;
  }

  p {
    margin-left: 8px;
    font-weight: 600;
  }
`;
