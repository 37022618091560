import React from 'react';
import { media, styled } from 'styles';

export default function DisciplinesHeader({
  disciplines,
  selectedDiscipline,
  setSelectedDiscipline,
}) {
  const numberDisciplines = disciplines.length;
  return (
    <Header>
      {disciplines.map(d => (
        <DisciplineBox
          numberDisciplines={numberDisciplines}
          key={d.id}
          selected={d.id === selectedDiscipline.id}
          color={selectedDiscipline?.theme.color}
          onClick={() => setSelectedDiscipline(d)}
        >
          {d.name}
        </DisciplineBox>
      ))}
    </Header>
  );
}

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;

  @media ${media.mobile} {
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scrollbar-color: red;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none !important;
    }
  }
`;

const DisciplineBox = styled.div`
  border-right: 2px solid #d8d8d8;
  border-top: 8px solid
    ${({ selected, color }) => (selected ? color : '#d8d8d8')};
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 188px;
  width: calc(100% / ${({ numberDisciplines }) => numberDisciplines});
  min-height: 50px;
  cursor: pointer;
  color: ${({ selected, color }) => (selected ? color : '#d8d8d8')};
  font-weight: ${({ selected }) => selected && 'bold'};

  &:first-child {
    border-left: 2px solid #d8d8d8;
    border-top-left-radius: 6px;
  }

  @media ${media.mobile} {
    border: 1.5px solid
      ${({ selected, color }) => (selected ? color : '#d8d8d8')};
    min-width: 120px;
    min-height: 40px;
    width: calc(100% / ${({ numberDisciplines }) => numberDisciplines});
    flex: 1;

    &:first-child {
      border-left: 1.5px solid
        ${({ selected, color }) => (selected ? color : '#d8d8d8')};
      border-radius: 0;
    }
  }
`;
