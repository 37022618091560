import React, { useState } from 'react';
import styled from 'styled-components';
import { ArrowBack } from '@material-ui/icons';
import { Calendar } from 'react-multi-date-picker';
import { WEEK_DAYS, MONTHS } from 'modules/learning-path/utils/calendar';
const DaysToStudyStep = ({ goBack }) => {
  const [values, setValues] = useState([]);

  return (
    <Container>
      <Content>
        <Header>
          <ArrowBackIcon onClick={goBack} />
          <StepIndicator>Passo 4 de 4</StepIndicator>
        </Header>
        <Title>Quais dias você pretende estudar?</Title>
        <Calendar
          multiple
          value={values}
          onChange={setValues}
          showOtherDays={true}
          weekDays={WEEK_DAYS}
          months={MONTHS}
          highlightToday={false}
        />
        <SubmitButton>QUERO MEU PLANO DE ESTUDOS</SubmitButton>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Content = styled.div`
  width: 95%;
  max-width: 700px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #00000040;
  padding: 40px 20px 75px 20px;
`;
const StepIndicator = styled.div`
  color: #888;
  font-size: 20px;
`;

const ArrowBackIcon = styled(ArrowBack)`
  width: 20px !important;
  position: absolute;
  left: 0;
  height: 20px !important;
  cursor: pointer;
`;

const Title = styled.p`
  font-size: 30px;
  margin: 35px 0;
  font-weight: 700;
  color: #f7ac3b;
`;

const SubmitButton = styled.div`
  padding: 10px 25px;
  background-color: #47d47c;
  color: #fff;
  font-weight: bold;
  border-radius: 21px;
  margin-top: 34px;
`;

export default DaysToStudyStep;
