import React, { useCallback } from 'react';
import { styled, media } from 'styles';
import { useTracker, useUTM } from 'modules/shared/hooks';

export default function VipButton({ lectureId }) {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const getAmplitudeEventProperties = useCallback(() => {
    return {
      Campanha: 'ICB 21.2',
      Onde: media.isDesktop() ? 'Desktop' : 'Mobile',
      ...getUTMsByLocation(window.location),
    };
  }, [getUTMsByLocation]);

  const trackClickCTA = useCallback(() => {
    trackEvent('Lançamentos: Clicou CTA Aulão', getAmplitudeEventProperties());
  }, [trackEvent, getAmplitudeEventProperties]);

  const onClickCTA = useCallback(() => {
    const calculusId = '40';
    const link =
      lectureId === calculusId
        ? 'https://www.respondeai.com.br/planos-icb-2021-2?utm_source=aulao&utm_medium=calculo'
        : 'https://www.respondeai.com.br/planos-icb-2021-2?utm_source=aulao&utm_medium=fisica';
    trackClickCTA();
    window.open(link, '_blank');
  }, [trackClickCTA, lectureId]);

  return (
    <Button onClick={() => onClickCTA()}>
      ASSINE AGORA COM DESCONTO EXCLUSIVO
    </Button>
  );
}

const Button = styled.button`
  background: #f87e25;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.9rem;
  color: #fff;
  width: 100%;
  height: 60px;
  font-family: Lato;
  font-weight: normal;
  font-size: 35px;
  line-height: 42px;

  &:hover {
    background: #fff;
    border: 2px solid #fa6e09;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #fa6e09;
  }

  @media ${media.mobile} {
    width: 95%;
    font-size: 17.006px;
    line-height: 20px;
    margin-bottom: 1.7rem;
    margin-left: 2.5%;
    padding: 0 10px;
  }
`;
