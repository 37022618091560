import React, { useEffect } from 'react';

import { ContainerBanner } from './styles';
import { useChangePlanBanner } from '../../hooks';

export default function ChangePlanMobile() {
  const { viewBannerEvent, onClickBanner, bannerData } = useChangePlanBanner();

  useEffect(() => {
    viewBannerEvent();
  }, [viewBannerEvent]);

  return (
    <ContainerBanner onClick={onClickBanner}>
      <p>TROQUE DE PLANO E</p>
      <button>{`Economize Até R$${bannerData?.economy}/mês!`}</button>
    </ContainerBanner>
  );
}
