import { FaSearch, FaArrowLeft } from 'react-icons/fa';
import { styled } from 'styles';
import React, { useState } from 'react';
import SearchFieldSuggestions from './SearchFieldSuggestions';

function SearchFieldMobile({ closeDialog, term, setTerm, color = '#f2b138' }) {
  const [searchTerm, setSearchTerm] = useState(term);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [currentIndexSuggestion, setCurrentIndexSuggestion] = useState(-1);
  const [suggestions, setSuggestions] = useState([]);

  return (
    <>
      <div className="close-controls">
        <div className="controls-content" onClick={closeDialog}>
          <FaArrowLeft
            className="search-icon"
            size={26}
            color={'#CCC'}
            cursor={'pointer'}
          />
        </div>
      </div>

      <Controls color={color}>
        <div className="controls-content">
          <FaSearch className="search-icon" size={26} color={color} />

          <input
            className="search-input"
            type="text"
            placeholder="Buscar teorias e exercícios resolvidos"
            value={searchTerm}
            onKeyDown={e => {
              if (e.key === 'ArrowDown') {
                setCurrentIndexSuggestion(currentIndexSuggestion + 1);
              } else if (e.key === 'ArrowUp') {
                setCurrentIndexSuggestion(
                  Math.max(-1, currentIndexSuggestion - 1)
                );
              }
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                if (
                  currentIndexSuggestion > -1 &&
                  suggestions.length >= currentIndexSuggestion + 1
                ) {
                  setSearchTerm(suggestions[currentIndexSuggestion]);
                } else {
                  setTerm(e.target.value);
                }
                setShowSuggestions(false);
              }
            }}
            onChange={e => {
              setSearchTerm(e.target.value);
              setShowSuggestions(true);
              setCurrentIndexSuggestion(-1);
              // setTerm(e.target.value);
            }}
            autoFocus
          />
        </div>
      </Controls>

      <SearchFieldSuggestions
        showSuggestions={showSuggestions}
        setShowSuggestions={setShowSuggestions}
        searchTerm={searchTerm}
        setTerm={setTerm}
        setSearchTerm={setSearchTerm}
        currentIndexSuggestion={currentIndexSuggestion}
        setSuggestions={setSuggestions}
      />
    </>
  );
}

export default SearchFieldMobile;

const Controls = styled.div`
  height: 50px;
  display: inline-block;
  position: relative;

  width: ${() => `calc(85% - 50px)`};

  max-width: 700px;

  border-radius: 30px;
  border: 2px solid;
  border-color: ${({ color }) => `${color}`};

  .controls-content {
    display: flex;
    padding-left: 10px;
    justify-content: center;

    .search-icon {
      align-self: center;
      cursor: pointer;
    }

    input {
      width: 100%;
      height: 50px;
      border: none;
      background-color: #fff0;
      align-self: center;

      padding: 0 50px 0 30px;
      font-size: 1.1em;
      line-height: 50px;

      @include input-placeholder {
        font-size: 0.8em;
        line-height: 20px;
      }

      @include md(mobile) {
        -webkit-appearance: none;
        line-height: initial;
      }
    }
  }
`;
