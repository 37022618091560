import React from 'react';
import { styled } from 'styles';

export default function ClipLoader({ color = '#000000', size = 35 }) {
  return <StyledClipLoader size={size} color={color} />;
}

const StyledClipLoader = styled.span`
  background: transparent !important;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 100%;
  border: 2px solid;
  border-top-color: ${({ color }) => color};
  border-bottom-color: transparent;
  border-left-color: ${({ color }) => color};
  border-right-color: ${({ color }) => color};
  display: inline-block;
  animation: clip 0.75s 0s infinite linear;

  @keyframes clip {
    0% {
      transform: rotate(0deg) scale(1);
    }

    50% {
      transform: rotate(180deg) scale(0.8);
    }

    100% {
      transform: rotate(360deg) scale(1);
    }
  }
`;
