import React, { useCallback } from 'react';
import { YoutubeLiveLectureBanner } from './banners/index';
import { styled } from 'styles';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';
import { useUserContext } from 'providers/UserProvider';
import { useBannerContext } from 'providers/BannerProvider';

export default function SideBanner() {
  const { user } = useUserContext();
  const { bannersToShowInfo } = useBannerContext();

  const getPriorityBanner = useCallback(() => {
    if (bannersToShowInfo?.sideBannerName === 'youtubeLiveLecture')
      return <YoutubeLiveLectureBanner />;
    return null;
  }, [bannersToShowInfo]);

  if (!bannersToShowInfo) return null;

  const banner = getPriorityBanner();
  if (!user || !banner) return null;

  return (
    <ToggleableComponent
      renderDelay={2500}
      open={true}
      width="100%"
      height="800px"
      marginBottom="10px"
    >
      <Container>{banner}</Container>
    </ToggleableComponent>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
`;
