export const NOT_UNIVERSITIES = [
  2907,
  1778,
  1779,
  1781,
  1780,
  1783,
  1784,
  1786,
  1787,
  1788,
  1789,
  1790,
  1793,
  1794,
  1797,
  1798,
  1772,
  1775,
  1774,
  1777,
  1792,
  1796,
  1773,
  1782,
  1785,
  1791,
  1795,
  1776,
];
