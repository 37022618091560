import { useCallback } from 'react';

import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useFormValidate, useCurrency } from 'modules/shared/hooks';

export default function useCheckoutLight() {
  const { formatCurrency } = useCurrency();
  const {
    validPhone,
    validName,
    validCpf,
    validPostalCode,
    validCreditCardHolderName,
    validCreditCardNumber,
    validCreditCardValidateDate,
    validCreditCardCvv,
  } = useFormValidate();

  const processPurchaseReq = useRAPostRequest('/payments/process_purchase');

  const getMonthlyPriceValue = useCallback(
    (plan, pricing) => {
      return formatCurrency(pricing.price / plan.months);
    },
    [formatCurrency]
  );

  const getInstallmentsMonthlyPrice = useCallback(
    (pricing, installments) => {
      return formatCurrency(pricing.price / installments);
    },
    [formatCurrency]
  );

  const getMonthsInfo = useCallback(plan => {
    if (plan.months === 1) return '1 mês';

    return `${plan.months} meses`;
  }, []);

  const getUserDataErrors = useCallback(
    userData => {
      const phone = validPhone(userData.phone)
        ? ''
        : 'Número incorreto. Por favor, preencha no formato (xx) xxxxx-xxxx';
      const name = validName(userData.name)
        ? ''
        : 'Por favor, preencha seu nome completo.';
      const cpf = validCpf(userData.cpf)
        ? ''
        : 'Por favor, preencha um CPF válido.';

      return { phone, name, cpf };
    },
    [validPhone, validName, validCpf]
  );

  const getBillingAddressErrors = useCallback(
    billingAddress => {
      const postalCode = validPostalCode(billingAddress.postalCode)
        ? ''
        : 'Cep inválido. Por favor, insira o CEP corretamente.';
      const district = billingAddress.district
        ? ''
        : 'Por favor, insira um bairro.';
      const street = billingAddress.street ? '' : 'Por favor, insira a rua.';
      const number = billingAddress.number ? '' : 'Por favor, insira o número.';
      const state = billingAddress.state
        ? ''
        : 'Por favor, selecione um estado.';
      const city = billingAddress.city
        ? ''
        : 'Por favor, selecione uma cidade.';

      return { postalCode, district, street, number, state, city };
    },
    [validPostalCode]
  );

  const getCreditCardErrors = useCallback(
    creditCard => {
      const number = validCreditCardNumber(creditCard.number)
        ? ''
        : 'Número de cartão inválido.';
      const holderName = validCreditCardHolderName(creditCard.holderName)
        ? ''
        : 'Nome inválido.';
      const expiry = validCreditCardValidateDate(creditCard.expiry)
        ? ''
        : 'Data inválida.';
      const cvv = validCreditCardCvv(creditCard.cvv)
        ? ''
        : 'Código de segurança inválido.';

      return { number, holderName, expiry, cvv };
    },
    [
      validCreditCardNumber,
      validCreditCardHolderName,
      validCreditCardValidateDate,
      validCreditCardCvv,
    ]
  );

  const processPurchase = useCallback(
    async purchaseData => {
      const formattedPurchaseData = {
        ...purchaseData,
        billingAddress: {
          ...purchaseData.billingAddress,
        },
      };

      try {
        await processPurchaseReq({
          purchaseData: formattedPurchaseData,
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    [processPurchaseReq]
  );

  return {
    getMonthlyPriceValue,
    getMonthsInfo,
    getUserDataErrors,
    getBillingAddressErrors,
    getCreditCardErrors,
    getInstallmentsMonthlyPrice,
    processPurchase,
  };
}
