import React from 'react';
import { Route } from 'react-router-dom';
import { AccountPage, ProfileCompletePage } from 'modules/profile/pages';

export default [
  <Route exact path="/minha-conta" component={AccountPage} key="minha-conta" />,
  <Route
    exact
    path="/profile-complete"
    component={ProfileCompletePage}
    key="profile-complete-page"
  />,
];
