import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useBookEditionContext } from '../providers/BookEditionProvider';
import { useUserContext } from 'providers/UserProvider';
import { useBookExercisePermission } from '.';

export default function useBookSelector() {
  const { user } = useUserContext();
  const { edition, currentSectionId } = useBookEditionContext();
  const { isFreemiumSection } = useBookExercisePermission(edition);

  const getQuestionsFromSelectedSection = useCallback(() => {
    const allSections = _.flatten(edition.chapters.map(c => c.sections));

    const questionsFromSectionSelected = allSections.find(
      section => section.id === currentSectionId
    )?.questions;

    return questionsFromSectionSelected;
  }, [edition, currentSectionId]);

  const exerciseOptions = useMemo(() => {
    const questionsFromSection = getQuestionsFromSelectedSection();

    return questionsFromSection?.map(question => {
      return { label: question.name, value: question.exercise.id };
    });
  }, [getQuestionsFromSelectedSection]);

  const includeChapterInSectionName = useCallback(chapter => {
    return section => ({
      ...section,
      name: `${chapter.name}.${section.name}`,
    });
  }, []);

  const getEnrichedEditionSectionsWithChapters = useCallback(() => {
    return edition.chapters.reduce((sectionWithChapters, chapter) => {
      const enrichedSections = chapter.sections.map(
        includeChapterInSectionName(chapter)
      );
      return [...sectionWithChapters, ...enrichedSections];
    }, []);
  }, [edition, includeChapterInSectionName]);

  const shouldSectionHasFreeTag = useCallback(
    sectionId => {
      if (user?.subscriber || user?.isFromPlaybookFreeUniversityCampaign)
        return false;

      return isFreemiumSection(sectionId);
    },
    [isFreemiumSection, user]
  );

  const sectionOptions = useMemo(() => {
    return getEnrichedEditionSectionsWithChapters().map(enrichedSection => ({
      label: enrichedSection.name,
      value: enrichedSection.id,
      isFree: shouldSectionHasFreeTag(enrichedSection.id),
    }));
  }, [getEnrichedEditionSectionsWithChapters, shouldSectionHasFreeTag]);

  return {
    getQuestionsFromSelectedSection,
    exerciseOptions,
    sectionOptions,
  };
}
