import React from 'react';
import { styled } from 'styles';
import { useWorkspaceExercise } from 'modules/workspace/hooks/shared';
import { Video } from 'modules/workspace/components/content/shared';

export default function ExerciseVideos({
  contentFormat,
  setVideoDuration,
  trackFirstPlayVideoMethod,
  videos,
}) {
  const { getExerciseColor } = useWorkspaceExercise();

  if (!videos || videos.length === 0) return null;
  return (
    <Container
      className={contentFormat === 'text' ? 'none' : ''}
      data-cy="exercise-videos"
    >
      <Title color={getExerciseColor()}>Resolução</Title>
      {videos.map(video => (
        <Video
          key={video.id}
          video={video}
          setVideoDuration={setVideoDuration}
          trackFirstPlayVideoMethod={trackFirstPlayVideoMethod}
        ></Video>
      ))}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 0 30px;

  &.none {
    display: none;
  }
`;

const Title = styled.h1`
  font-size: 1.6em;
  color: ${({ color }) => color};
  font-family: 'Droid Serif', serif;
  padding-bottom: 30px;
`;
