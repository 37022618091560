import React from 'react';
import { checkIcon, errorIcon, sectionWay, statementWay } from '../images';
import { styled, media } from 'styles';

export default function FormatExample() {
  return (
    <>
      <CorrectWay>
        <h3>
          <span>Jeito certo:</span> Seção e exercícios do livro
        </h3>
        <img src={sectionWay} className="list" />
        <img src={checkIcon} className="icon" />
      </CorrectWay>
      <IncorrectWay>
        <h5>
          <span>Jeito errado:</span> Listas só com o <br /> enunciado não
          funciona.
        </h5>
        <img src={statementWay} className="list" />
        <img src={errorIcon} className="icon" />
      </IncorrectWay>
    </>
  );
}

const CorrectWay = styled.div`
  user-select: none;
  position: relative;

  h3 {
    font-size: 1.4rem;
    margin-top: 1.4rem;
    font-weight: bold;
    color: #999999;
    width: 100%;
    user-select: none;

    span {
      color: #06a54f;
    }
  }

  .list {
    margin-top: 1rem;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #06a54f;

    @media ${media.mobile} {
      width: 100%;
    }
  }

  .icon {
    position: absolute;
    right: 5px;
    bottom: -9px;

    @media ${media.mobile} {
      right: -12px;
    }
  }
`;

const IncorrectWay = styled.div`
  user-select: none;
  position: relative;

  h5 {
    margin-top: 1.4rem;
    font-size: 1.2rem;
    color: #999999;
    user-select: none;

    span {
      color: #fe0707;
    }
  }

  .list {
    margin-top: 1rem;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #fe0707;
  }

  .icon {
    position: absolute;
    right: -7px;
    bottom: -9px;
  }
`;
