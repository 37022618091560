import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';

function useToast() {
  const successToast = useCallback(message => {
    toast.success(message);
  }, []);

  const errorToast = useCallback(message => {
    toast.error(message);
  }, []);

  const setToastAfterReload = useCallback((message, type) => {
    sessionStorage.setItem(
      'toast-message',
      JSON.stringify({
        message,
        type,
      })
    );
  }, []);

  const toastTypes = useMemo(() => {
    return {
      success: message => successToast(message),
      error: message => errorToast(message),
    };
  }, [successToast, errorToast]);

  const monitoreToastInSessionStorage = useCallback(() => {
    const toast = JSON.parse(sessionStorage.getItem('toast-message'));
    if (toast) {
      const { message, type } = toast;
      toastTypes[type](message);
      sessionStorage.removeItem('toast-message');
    }
  }, [toastTypes]);

  return {
    successToast,
    errorToast,
    monitoreToastInSessionStorage,
    setToastAfterReload,
  };
}

export { useToast };
