import styled from 'styled-components';
import { colors, media } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 60px;
  padding-bottom: 20px;
  align-items: center;
  background-color: #ffffff44;
`;

export const ErrorContainer = styled.div`
  align-self: flex-end;
  z-index: 10;
  background: red;
  color: white;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  margin: -20px;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  align-items: center;
  cursor: pointer;
`;

export const Title = styled.h2`
  font-size: 17px;
  color: ${colors.darkGray};
  font-weight: bold;
  margin: 10px 20px;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-top: 10px solid #f6ac3a;
  border-radius: 10px;
  padding: 56px 40px;
  padding-top: 35px;
  margin-top: 30px;
  align-items: flex-start;
  color: black;
  background-color: white;

  width: 80%;
  max-width: 600px;

  @media ${media.mobile} {
    padding: 30px;
  }
`;

export const Button = styled.button`
  width: 100%;
  border: 1px solid #f6ac3a;
  border-radius: 10px;

  color: white;
  background-color: #f6ac3a;
  margin-top: 20px;
  padding: 15px;

  font-weight: bold;
  font-size: 1.1em;

  cursor: ${({ saving }) => (saving ? 'not-allowed' : 'pointer')};
`;

export const Logo = styled.img`
  width: auto;
  height: 45px;
  margin-bottom: 40px;
`;
