import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import { styled, colors } from 'styles';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import lectureImg from 'images/workspace/lecture-reminder-sidemenu-ic.png';
import { useCookies } from 'modules/shared/hooks';
import { useWorkspaceTracker } from 'modules/workspace/hooks/main';
import { Link, useLocation } from 'react-router-dom';

export default function LectureLink({ lectureId }) {
  const location = useLocation();
  const { contentSubtitle } = useWorkspaceContentContext();
  const [cookies] = useCookies(['closed-lecture-workspace-reminder']);
  const { trackClickLectureButtonsFromWorkspace } = useWorkspaceTracker();

  const lectureName = useMemo(() => {
    return contentSubtitle;
  }, [contentSubtitle]);

  const shouldShowLectureButton = useMemo(() => {
    return cookies['closed-lecture-workspace-reminder'];
  }, [cookies]);

  if (!shouldShowLectureButton) return null;

  return (
    <Container
      to={{
        pathname: `/lecture/aulao/${lectureId}`,
        state: { previousUrl: location.pathname },
      }}
      href={`${process.env.REACT_APP_RESPONDEAI_URL}`}
      data-cy="side-menu-lecture-button"
      onClick={trackClickLectureButtonsFromWorkspace}
    >
      <Img src={lectureImg} />
      <Info>
        <LectureMessage>Assistir aulão de</LectureMessage>
        <LectureName>{lectureName}</LectureName>
        <IconContainer>
          <Icon icon={faPlay} />
        </IconContainer>
      </Info>
    </Container>
  );
}

const Container = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin: 0 10px 10px;
  background-color: #393939;
  border-radius: 10px;
`;

const Img = styled.img`
  position: relative;
  top: 2px;
  height: 24px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 0.9em;
  margin-left: 15px;
`;

const LectureMessage = styled.div`
  text-transform: uppercase;
  margin-bottom: 3px;
  color: ${colors.darkGray};
  font-size: 0.8em;
`;

const LectureName = styled.div`
  max-width: 175px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #fff;
`;

const IconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 15px;
  top: 0;
  height: 100%;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.2em;
`;
