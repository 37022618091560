import useForumTracker from 'modules/forum/hooks/useForumTracker';
import { useToast } from 'modules/shared/hooks';
import { useRAPostFileRequest } from 'modules/shared/hooks/requests';
import { useForumContext } from 'providers/ForumProvider';
import React, { useCallback, useMemo } from 'react';
import PostManager from './PostManager';
import ExitConfirmation from './ExitConfirmation';
import { useDialogContext } from 'providers/DialogProvider';
import { styled } from 'styles';

export default function CreateOrUpdateQuestion({
  action,
  question = null,
  setQuestion,
  setShouldShowCreateOrUpdate,
  setSelectedQuestion,
}) {
  const { forumContent } = useForumContext();
  const { setDialog } = useDialogContext();
  const { trackCreatedQuestion, trackEditPost } = useForumTracker();
  const sendQuestion = useRAPostFileRequest('/ra_forum/questions');
  const editQuestion = useRAPostFileRequest(
    `/ra_forum/questions/${question?.id}/edit`
  );

  const { errorToast } = useToast();

  const isEditing = useMemo(() => {
    return action === 'edition';
  }, [action]);

  const buttonTextData = useMemo(() => {
    return {
      default: isEditing ? 'Salvar Alterações' : 'Pergunta aí',
      onSending: isEditing ? 'Salvando...' : 'Perguntando...',
    };
  }, [isEditing]);

  const onBack = useCallback(() => {
    if (isEditing) {
      setDialog(
        <ExitConfirmation onExit={() => setShouldShowCreateOrUpdate(false)} />
      );
    } else setShouldShowCreateOrUpdate(false);
  }, [setShouldShowCreateOrUpdate, setDialog, isEditing]);

  const onSubmit = useCallback(
    async (params, file = null) => {
      let dataSend = {
        body: params.text,
        title: params.title,
      };
      if (file) params['filename'] = file.name;
      if (isEditing) {
        try {
          const { data } = await editQuestion(file, dataSend);

          setSelectedQuestion(data.id);
          setQuestion(data);
          setShouldShowCreateOrUpdate(false);
          trackEditPost('Pergunta');
        } catch (e) {
          errorToast('Não foi possível criar a pergunta. Tente novamente.');
        }
      } else {
        try {
          dataSend = {
            ...dataSend,
            content_id: forumContent?.id,
            content_url: window.location.href,
            content_type: forumContent?.type,
          };
          const { data } = await sendQuestion(file, dataSend);

          setSelectedQuestion(data.id);
          trackCreatedQuestion();
          setShouldShowCreateOrUpdate(false);
        } catch (e) {
          errorToast('Não foi possível criar a pergunta. Tente novamente.');
        }
      }
    },
    [
      forumContent,
      isEditing,
      sendQuestion,
      editQuestion,
      setSelectedQuestion,
      setShouldShowCreateOrUpdate,
      errorToast,
      trackCreatedQuestion,
      trackEditPost,
      setQuestion,
    ]
  );

  return (
    <Container>
      <PostManager
        isEditing={isEditing}
        hasTitle
        postContent={question}
        onBack={onBack}
        onSubmit={onSubmit}
        buttonTextData={buttonTextData}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
