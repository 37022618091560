import React from 'react';
import { styled, media } from 'styles';
import { PreviousButton } from 'modules/lectures/components';
import { useLectureContext } from 'modules/lectures/providers/LectureProvider';

export default function Header() {
  const { lectureName } = useLectureContext();

  return (
    <Container>
      <CustuomPreviousButton />
      <Title>{lectureName}</Title>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  background-color: transparent;
  z-index: 1;
`;

const CustuomPreviousButton = styled(PreviousButton)`
  position: absolute;
  left: 13px;
  top: 19px;
`;

const Title = styled.h1`
  margin: auto;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 70px;
  font-size: 1.5em;
  color: #fff;
  @media ${media.mobile} {
    font-size: 1.1em;
  }
`;
