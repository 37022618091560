import { useCallback } from 'react';
import _ from 'lodash';

function useArrangeData() {
  const getThemes = useCallback(disciplines => {
    const allThemes = disciplines.map(d => d.theme);
    const themesArrayWithNoRepetition = allThemes.filter(
      (theme, index) => allThemes.indexOf(theme) === index
    );
    return themesArrayWithNoRepetition;
  }, []);

  const getAllExamsFromDiscipline = useCallback(discipline => {
    const listCategoryOfExams = discipline?.listCategories?.filter(
      it =>
        it.name.includes('P1') ||
        it.name.includes('P2') ||
        it.name.includes('P3') ||
        it.name.includes('PF')
    );
    return listCategoryOfExams;
  }, []);

  const getDisciplinesFromSelectedTheme = useCallback(
    (disciplines, selectedTheme) => {
      return disciplines.filter(d => d.theme.id === selectedTheme.id);
    },
    []
  );

  const insertListCategoryKeyInLists = useCallback(listCategories => {
    const listWithItsListCategory = listCategories?.map(listCategory => {
      listCategory.lists.map(list => {
        list.listCategoryName = listCategory.name;
        list.listCategoryId = listCategory.id;
      });
      return listCategory;
    });
    return listWithItsListCategory;
  }, []);

  const getSevenLastExamsFromDiscipline = useCallback(
    discipline => {
      let allListCategoriesExams = getAllExamsFromDiscipline(discipline);
      allListCategoriesExams = insertListCategoryKeyInLists(
        allListCategoriesExams
      );

      let allExams = _.flatten(
        allListCategoriesExams.map(listCategory => listCategory.lists)
      );
      const examsOrderedByDate = _.orderBy(allExams, ['name'], ['desc']);
      return examsOrderedByDate.slice(0, 7);
    },
    [getAllExamsFromDiscipline, insertListCategoryKeyInLists]
  );

  const getAllListsCategoryOfExercisesLists = useCallback(discipline => {
    const listCategoryOfExercisesLists = discipline?.listCategories?.filter(
      it =>
        !it.name.includes('P1') &&
        !it.name.includes('P2') &&
        !it.name.includes('P3') &&
        !it.name.includes('PF')
    );
    return listCategoryOfExercisesLists;
  }, []);

  return {
    getThemes,
    getDisciplinesFromSelectedTheme,
    getSevenLastExamsFromDiscipline,
    getAllExamsFromDiscipline,
    getAllListsCategoryOfExercisesLists,
  };
}

export { useArrangeData };
