import ActionButton from './ActionButton';
import { share, shareHover, shareClick } from 'images/forum';
import { useToast } from 'modules/shared/hooks';
import React, { useCallback, useMemo } from 'react';

function ShareButton({ questionId, answerId = null, trackClickToShare }) {
  const { successToast } = useToast();

  const urlToCopy = useMemo(() => {
    let url = window.location.href.split('?')[0];

    url += `?question=${questionId}`;
    if (answerId) url += `&answer=${answerId}`;

    return url;
  }, [questionId, answerId]);

  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(urlToCopy);
    successToast('Link copiado!');

    const publicationType = answerId ? 'Resposta' : 'Pergunta';
    trackClickToShare(publicationType);
  }, [urlToCopy, successToast, trackClickToShare, answerId]);

  return (
    <ActionButton
      text={'Compartilhar'}
      image={share}
      hoverImage={shareHover}
      clickImage={shareClick}
      onClick={handleClick}
      underline={true}
    />
  );
}

export default ShareButton;
