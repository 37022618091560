import React from 'react';
import { Link } from 'react-router-dom';
import { styled, media } from 'styles';

export default function OtherSubjectsButton({ theme }) {
  return (
    <Link to={`/materias/completas/${theme.id}`}>
      <OtherSubjectsContainer>
        Outros assuntos de {theme.name}
      </OtherSubjectsContainer>
    </Link>
  );
}

const OtherSubjectsContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #cfcfcf;
  font-size: 0.9em;
  font-weight: bold;
  color: white;
  min-width: 225px;
  min-height: 38px;
  padding: 0 20px;

  @media ${media.mobile} {
    margin-left: 20px;
    max-width: 95%;
    line-height: 1.5rem;
  }
`;
