import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { media } from 'styles';
import { defaultSubjectImage } from 'images/main';
import { useProfessor } from 'modules/professors/hooks/useProfessor';
import { useDialogContext } from 'providers/DialogProvider';
import CreateUserProfessorDialog from 'modules/professors/dialogs/CreateUserProfessorDialog';
import { useHomeProfessorTracker } from 'modules/main/hooks/useHomeProfessorTracker';
import { useTracker } from 'modules/shared/hooks';

export default function SubjectCard({ theme, subject }) {
  const {
    isSubjectEligibleToUserProfessor,
    getUserProfessorFromSubject,
    getDisciplinesFromSubject,
  } = useProfessor();
  const { trackClickSubject } = useHomeProfessorTracker();
  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();

  if (!subject.imagePath) subject.imagePath = defaultSubjectImage;

  const subjectProfessor = useMemo(() => {
    return getUserProfessorFromSubject(subject.id)?.professor;
  }, [getUserProfessorFromSubject, subject]);

  const shouldGoToWorkspace = useMemo(() => {
    return (
      !subject.hasLecture &&
      !subject.hasBookSubject &&
      !isSubjectEligibleToUserProfessor(subject.id)
    );
  }, [subject, isSubjectEligibleToUserProfessor]);

  const urlLink = useMemo(() => {
    if (shouldGoToWorkspace)
      return `/aprender/topico/${subject.id}/${subject.firstTopic.id}/teoria/${subject.firstTheoryId}`;

    if (!isSubjectEligibleToUserProfessor(subject.id))
      return `/materias/completas/${theme.id}/assunto/${subject.id}`;

    if (subjectProfessor)
      return `/materias/professor/${subjectProfessor.id}/assunto/${subject.id}`;

    return '#';
  }, [
    shouldGoToWorkspace,
    subject,
    theme,
    isSubjectEligibleToUserProfessor,
    subjectProfessor,
  ]);

  const trackClickDiscipline = useCallback(
    ({ openDialog }) => {
      trackEvent('Clicou Disciplina Default', {
        origem: 'Home',
        Matéria: theme?.name,
        Assunto: subject?.name,
        'Abre Dialog Professor': openDialog ? 'Sim' : 'Não',
      });
    },
    [subject?.name, theme?.name, trackEvent]
  );

  const onLinkClick = useCallback(() => {
    trackClickDiscipline({ openDialog: true });
    if (!isSubjectEligibleToUserProfessor(subject.id)) return;
    trackClickSubject(subject, theme);

    if (subjectProfessor) return;
    const disciplinesFromSubject = getDisciplinesFromSubject(subject.id);
    const defaultDiscipline =
      disciplinesFromSubject.length === 1 ? disciplinesFromSubject[0] : null;

    setDialog(
      <CreateUserProfessorDialog
        defaultThemeId={theme.id}
        defaultDisciplineId={defaultDiscipline?.id}
        defaultDisciplineType={defaultDiscipline?.modelName}
        availableDisciplines={disciplinesFromSubject}
        defaultSubject={subject}
        showRejectCta={true}
        color={theme.color}
        source="Assunto Default"
      />
    );
  }, [
    getDisciplinesFromSubject,
    isSubjectEligibleToUserProfessor,
    setDialog,
    subject,
    subjectProfessor,
    theme,
    trackClickDiscipline,
    trackClickSubject,
  ]);

  if (shouldGoToWorkspace) {
    return (
      <CustomLinkProvisory
        to={urlLink}
        theme={theme}
        onClick={() => trackClickDiscipline({ openDialog: false })}
      >
        <img src={subject.imagePath} alt="" />
        <div className="name">{subject.name}</div>
      </CustomLinkProvisory>
    );
  }

  return (
    <CustomLink to={urlLink} theme={theme} onClick={onLinkClick}>
      <img src={subject.imagePath} alt="" />
      <div className="name">{subject.name}</div>
    </CustomLink>
  );
}

const CustomLink = styled(Link)`
  display: flex;
  margin: 10px 4px 15px 4px;
  max-width: 45%;
  position: relative;
  width: 260px;
  height: 40px;

  img {
    background-color: ${props => props.theme.color};
    opacity: 0.8;
    width: 65px;
    height: 40px;
    border-radius: 8px;
  }

  .name {
    display: flex;
    align-items: center;
    height: 40px;
    max-width: 175px;
    margin-left: 10px;
    color: #666;
    font-weight: 100;
  }

  &:hover img {
    opacity: 1 !important;
    transform: scale(1.05);
  }

  @media ${media.mobile} {
    flex-direction: column;
    width: 120px;
    height: 170px;
    min-width: 120px;
    background-color: ${props => props.theme.color};
    border-radius: 10px;
    justify-content: space-around;
    padding-bottom: 15px;

    img {
      width: 110px;
      height: 65px;
      margin-top: 15px;
    }

    .name {
      max-width: 92%;
      min-height: 40px;
      margin-top: 25px;
      font-size: 0.75em;
      margin-left: 10px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      word-break: break-word;
      text-align: left;
    }
  }
`;

const CustomLinkProvisory = styled(Link)`
  display: flex;
  margin: 10px 4px 15px 4px;
  max-width: 45%;
  position: relative;
  width: 260px;
  height: 40px;

  img {
    background-color: ${props => props.theme.color};
    opacity: 0.8;
    width: 65px;
    height: 40px;
    border-radius: 8px;
  }

  .name {
    display: flex;
    align-items: center;
    height: 40px;
    max-width: 175px;
    margin-left: 10px;
    color: #666;
    font-weight: 100;
  }

  &:hover img {
    opacity: 1 !important;
    transform: scale(1.05);
  }

  @media ${media.mobile} {
    flex-direction: column;
    width: 120px;
    height: 170px;
    min-width: 120px;
    background-color: ${props => props.theme.color};
    border-radius: 10px;
    justify-content: space-around;
    padding-bottom: 15px;

    img {
      width: 110px;
      height: 65px;
      margin-top: 15px;
    }

    .name {
      max-width: 92%;
      min-height: 40px;
      margin-top: 25px;
      font-size: 0.75em;
      margin-left: 10px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      word-break: break-word;
      text-align: left;
    }
  }
`;
