import React, { useMemo } from 'react';
import { styled, media } from 'styles';
import { useCheckoutDialogTracker } from './hooks/useCheckoutDialogTracker';

export function PlanCard({ pricing, setPricingId, pricingId, eventProps }) {
  const { id, plan, monthlyPrice } = pricing;
  const { name, months } = plan;
  const { clickPlanCard } = useCheckoutDialogTracker(eventProps);

  const shouldHaveTag = useMemo(() => {
    const isAnnual = plan.id === 3;
    const isDesktop = !media.isMobile();

    return isAnnual && isDesktop;
  }, [plan]);

  const onPlanClick = () => {
    clickPlanCard(name);
    setPricingId(id);
  };

  return (
    <Container
      onClick={onPlanClick}
      className={pricingId === pricing.id ? 'selected' : undefined}
    >
      <Top>
        <div>
          <span> Plano {name}</span>
          {shouldHaveTag && <span className="tag"> Mais barato</span>}
        </div>
        <Ball className={pricingId === id ? 'selected' : undefined}>
          <div></div>
        </Ball>
      </Top>
      <Price> R$ {monthlyPrice.toFixed(2)}/mês</Price>
      <Description>
        Acesso ilimitado ao Responde Aí por {months}{' '}
        {months === 1 ? 'mês' : 'meses'}
      </Description>
    </Container>
  );
}

const Container = styled.div`
  border: 1px solid #cecece;
  padding: 10px;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;

  &.selected {
    border: 1px solid #f7ac3d;
  }

  &:hover {
    scale: 1.008;
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    text-transform: uppercase;
    color: #777;
    font-size: 0.7rem;
  }

  .tag {
    background-color: #dcffec;
    color: #2a8253;
    font-size: 0.68rem;
    padding: 3px;
    margin-left: 12px;
  }
`;

const Ball = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid #cecece;

  &.selected {
    border: 1px solid #f7ac3d;

    div {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #f7ac3d;
      margin: 2px;
    }
  }
`;

const Price = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0;

  @media ${media.mobile} {
    font-size: 1rem;
  }
`;

const Description = styled.p`
  font-size: 0.62rem;
  font-weight: bold;
`;
