import React from 'react';
import { styled, media } from 'styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default function DismissButton({ onClick }) {
  return (
    <Container onClick={onClick}>
      <Button>
        <IconContainer>
          <Icon icon={faArrowLeft} />
        </IconContainer>
        <span>Voltar</span>
      </Button>
    </Container>
  );
}

// const Container = styled.a`
const Container = styled.div`
  position: absolute;
  top: 10px;
  left: 0px;
  display: block;
  width: 200px;
  height: 40px;
  margin: 10px 0 0 10px;
  text-align: left;
  z-index: 9;

  @media ${media.mobile} {
    top: 0;
    width: 120px;
    height: 30px;
  }
`;

const Button = styled.button`
  transition: opacity 150ms ease-in-out;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #bbb;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0.85;
  &:hover {
    opacity: 1;
  }

  @media ${media.desktop} {
    font-size: 1.2em;
  }
`;

const IconContainer = styled.span`
  display: inline-block;
  margin-right: 8px;
  /* background: rgba(0, 0, 0, 0.15); */
  border-radius: 5px;
  padding: 10px 15px;
  text-align: center;

  @media ${media.mobile} {
    padding: 5px 10px;
  }
`;

const Icon = styled(FontAwesomeIcon)``;
