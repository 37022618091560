import React, { useRef, useState } from 'react';
import { media, styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useEscClick } from 'modules/shared/hooks';
import WrapperFirstStep from '../components/WrapperFirstStep';
import WrapperSecondStep from '../components/WrapperSecondStep';

export default function WorkspaceNoAccessDialog() {
  const [stepCampaign, setStepCampaign] = useState(1);
  const { unsetDialog } = useDialogContext();
  const ref = useRef(null);

  useEscClick(ref, unsetDialog);

  return (
    <Container ref={ref}>
      <Content data-cy="trial-default-dialog">
        <CloseButton onClick={unsetDialog}>X</CloseButton>
        {stepCampaign === 1 && (
          <WrapperFirstStep setStepCampaign={setStepCampaign} />
        )}
        {stepCampaign === 2 && <WrapperSecondStep />}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.35);
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  padding-top: 30px;
  color: #666;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 23px;

  @media ${media.mobile} {
    top: 10px;
    right: 7px;
  }
`;
