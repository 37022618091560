import { useTracker } from 'modules/shared/hooks';
import React, { useCallback } from 'react';
import { styled } from 'styles';
import ContentTitle from './ContentTitle';

const RA_URL = process.env.REACT_APP_RESPONDEAI_URL;

export default function ExerciseListLink({ selectedDiscipline }) {
  const { trackEvent } = useTracker();

  const getFirstExerciseListLink = useCallback(() => {
    const guideId = selectedDiscipline.guide.id;
    const listCategoryId = selectedDiscipline.listCategories[0].id;
    const listId = selectedDiscipline.listCategories[0].lists[0].id;
    const exerciseId =
      selectedDiscipline.listCategories[0].lists[0].listExercises[0].id;

    return `${RA_URL}/praticar/${guideId}/lista/${listCategoryId}/${listId}/exercicio-lista/${exerciseId}?internal_source=custom-dialog`;
  }, [selectedDiscipline]);

  const fireListExerciseClickEvent = useCallback(() => {
    trackEvent('Dialog Conteúdo Personalizado: Clicou Lista', {
      Disciplina: selectedDiscipline.name,
      Matéria: selectedDiscipline?.theme.name,
    });
  }, [selectedDiscipline, trackEvent]);

  const handleListExerciseClick = useCallback(() => {
    fireListExerciseClickEvent();
    const link = getFirstExerciseListLink();
    if (link) {
      window.open(link, '_blank');
    }
  }, [getFirstExerciseListLink, fireListExerciseClickEvent]);

  return (
    <Container onClick={() => handleListExerciseClick()}>
      <ContentTitle
        title={`Listas de Exercícios Resolvidas de ${selectedDiscipline.name}`}
        color={selectedDiscipline?.theme.color}
      />
    </Container>
  );
}

const Container = styled.a`
  cursor: pointer;
`;
