import React, { useCallback } from 'react';

import { useDate } from 'modules/shared/hooks';
import { styled } from 'styles';
import { useReactivateSubscription } from 'modules/profile/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import ReactivateSubscriptionDIalog from '../dialogs/ReactivateSubscriptionDIalog';

export default function VoidedSubscriptionCopy({ subscription }) {
  const { setDialog } = useDialogContext();

  const { getHumanReadableDateFromTimestamp } = useDate();
  const { subscriptionEligibleToReactivate } = useReactivateSubscription();

  const onCtaClick = useCallback(() => {
    if (subscriptionEligibleToReactivate(subscription)) {
      setDialog(
        <ReactivateSubscriptionDIalog
          utmSource="botao-minha-conta"
          subscription={subscription}
        />
      );
      return;
    }

    window.href = `${process.env.REACT_APP_RA_WEB_SSR_URL}/planos`;
  }, [subscriptionEligibleToReactivate, subscription, setDialog]);

  const ctaCopy = useCallback(() => {
    if (subscriptionEligibleToReactivate(subscription))
      return 'Reativar recorrência';

    return 'Renovar';
  }, [subscriptionEligibleToReactivate, subscription]);

  return (
    <Container>
      <a href="#" onClick={onCtaClick}>
        {ctaCopy()}
      </a>
      <p>
        Se você já cancelou, não se preocupe, você não será cobrado novamente.
        Você poderá continuar usando nossa plataforma até{' '}
        {getHumanReadableDateFromTimestamp(subscription.expiresIn)}
      </p>
    </Container>
  );
}

const Container = styled.div`
  a {
    background-color: #eee;
    font-size: 1em;
    padding: 5px 15px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  p {
    margin-top: 5px;
    font-size: 0.8em;
    font-weight: bold;
  }
`;
