import React, { useCallback } from 'react';
import { styled, media } from 'styles';
import { useDropzone } from 'react-dropzone';
import { MdOutlineUploadFile } from 'react-icons/md';
import { useToast } from 'modules/shared/hooks';
import useSearchTracker from '../hooks/useSearchTracker';
import { useSearchContext } from '../providers/SearchProvider';
import useSearchNavigation from '../hooks/useScreenNavigation';
import { SendQuestionButton } from '../screens/InputTutorialScreen';

export default function UploadImageArea() {
  const { setFile, setImgUploadedSrc, setResults } = useSearchContext();
  const { goNextScreen } = useSearchNavigation();
  const { errorToast } = useToast();
  const { trackErrorOnUploadImage, trackClickSendImage } = useSearchTracker();

  const isFileValid = useCallback(
    async acceptedFiles => {
      if (acceptedFiles.length === 0) {
        trackErrorOnUploadImage('Formato não permitido');
        errorToast('Você só pode fazer o upload dos formatos permitidos');
        return false;
      }

      if (acceptedFiles.length > 1) {
        trackErrorOnUploadImage('Multiplos arquivos');
        errorToast('Você só pode fazer o upload de um único arquivo.');
        return false;
      }

      return true;
    },
    [errorToast, trackErrorOnUploadImage]
  );

  const onDrop = useCallback(
    async acceptedFiles => {
      const isValid = await isFileValid(acceptedFiles);
      if (!isValid) return;

      setFile(acceptedFiles[0]);
      const imageUrl = URL.createObjectURL(acceptedFiles[0]);
      setImgUploadedSrc(imageUrl);
      setResults(null);

      if (media.isMobile()) goNextScreen('cropped');
    },
    [isFileValid, setFile, setImgUploadedSrc, setResults, goNextScreen]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    },
    onDrop,
  });

  return (
    <UploadArea {...getRootProps()} data-cy="search-upload-area">
      <input {...getInputProps()} />
      {media.isMobile() ? (
        <SendQuestionButton onClick={trackClickSendImage}>
          Enviar questão
        </SendQuestionButton>
      ) : isDragActive ? (
        <p className="release-text">Agora é só soltar</p>
      ) : (
        <InsideContainer onClick={trackClickSendImage}>
          <MdOutlineUploadFile color="#149f8c" />
          <p>
            <b>Arraste e solte</b> sua imagem ou <b>clique aqui</b> para
            escolher a foto da questão
          </p>
        </InsideContainer>
      )}
    </UploadArea>
  );
}

const UploadArea = styled.div`
  min-height: 130px;
  border: 1px dashed #888888;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: 'center';
  padding: 10px;
  background-color: #fff;
  margin: 15px 0;

  .release-text {
    margin-top: 50px;
    color: #149f8c;
    font-size: 20px;
  }

  @media ${media.mobile} {
    margin-bottom: 15px;
    min-height: initial;
    width: 60px;
    height: 60px;
    border: 0;
    padding: 0;
    background: #f9faf9;
    margin: 0;
    display: initial;
  }
`;

const InsideContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: #868686;

  svg {
    font-size: 30px;
    color: #f6ac3a;
  }
`;
