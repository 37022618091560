import React from 'react';
import { styled } from 'styles';
import { useLectureContext } from '../../providers/LectureProvider';

const PreventClickOverlay = () => {
  const { playing, setPlaying } = useLectureContext();
  return <Overlay onClick={() => setPlaying(!playing)} />;
};

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default PreventClickOverlay;
