import React, { useCallback, useEffect, useMemo } from 'react';
import { styled, media } from 'styles';
import { MdOutlineUploadFile } from 'react-icons/md';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { useDialogContext } from 'providers/DialogProvider';
import { useProfessorsLists } from 'modules/books/hooks/useProfessorsLists';
import { useBookExerciseTracker } from 'modules/books/hooks';

export default function ListsContainer({
  selectedProfessor,
  origin,
  openingType,
}) {
  const { setDialog } = useDialogContext();
  const {
    getListsThatHaveSameExercise,
    getListsThatDoesNotHaveSameExercise,
  } = useProfessorsLists();
  const {
    trackListClick,
    trackClickSendListButton,
    trackViewLists,
  } = useBookExerciseTracker();

  const listsThatHaveSameExercise = useMemo(() => {
    if (!selectedProfessor) return [];

    return getListsThatHaveSameExercise(selectedProfessor);
  }, [getListsThatHaveSameExercise, selectedProfessor]);

  const listsThatDoesNotHaveSameExercise = useMemo(() => {
    if (!selectedProfessor) return [];

    return getListsThatDoesNotHaveSameExercise(selectedProfessor);
  }, [selectedProfessor, getListsThatDoesNotHaveSameExercise]);

  const shouldShowListsFromSameExerciseSection = useMemo(() => {
    return listsThatHaveSameExercise.length > 0;
  }, [listsThatHaveSameExercise.length]);

  const shouldShowSendListButton = useMemo(() => {
    return !selectedProfessor;
  }, [selectedProfessor]);

  const shouldShowListsFromProfessor = useMemo(() => {
    return selectedProfessor;
  }, [selectedProfessor]);

  const openListClick = useCallback(
    (list, index, from) => {
      if (!list) return;

      trackListClick({
        list,
        index,
        isFromSameQuestionSection: from === 'Seção Questão Específica',
      });

      const utmSource = 'site_navegacao';
      const utmMedium = 'botao_lista';
      const utmContent = 'pag_exercicio_livro';

      const url =
        list.type === 'bookList'
          ? `${process.env.REACT_APP_MVP_URL}/listas/${list.id}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}`
          : `${process.env.REACT_APP_REACT_URL}/lista-de-enunciado/${list.id}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}`;

      window.open(url, '_blank');
    },
    [trackListClick]
  );

  const onClickSendList = useCallback(
    local => {
      trackClickSendListButton({ local });
      const utms = {
        utmSource: 'site_upload',
        utmMedium: 'botao_enviar_lista',
        utmContent: 'pag_exercicio_livro',
      };

      setDialog(<UploadBookListDialog origin={origin} utms={utms} />);
    },
    [trackClickSendListButton, setDialog, origin]
  );

  useEffect(() => {
    const listsRendered = !!selectedProfessor;

    if (listsRendered) {
      trackViewLists({
        professorId: selectedProfessor.id,
        numberListsWithSameQuestion: listsThatHaveSameExercise.length,
        numberOtherLists: listsThatDoesNotHaveSameExercise.length,
        openingType,
      });
    }
  }, [
    listsThatDoesNotHaveSameExercise.length,
    listsThatHaveSameExercise.length,
    selectedProfessor,
    trackViewLists,
    openingType,
  ]);

  return (
    <Container>
      {shouldShowSendListButton && (
        <SendListButton onClick={() => onClickSendList('Botao Superior')}>
          <MdOutlineUploadFile color="#fff" size="20px" />
          <p>Enviar Nova Lista</p>
        </SendListButton>
      )}
      {shouldShowListsFromSameExerciseSection && (
        <ListsSection>
          <h2>Listas com o exercício que você acabou de procurar</h2>
          <div className="lists">
            {listsThatHaveSameExercise.slice(0, 15).map((list, index) => (
              <a
                key={`${list.type}_${list.id}`}
                onClick={() =>
                  openListClick(list, index, 'Seção Questão Específica')
                }
              >{`Lista ${index + 1}`}</a>
            ))}
          </div>
        </ListsSection>
      )}
      {shouldShowListsFromProfessor && (
        <ListsSection>
          <h2 className="allLists">
            {shouldShowListsFromSameExerciseSection
              ? 'Outras Listas do Professor'
              : 'Listas do Professor'}
          </h2>
          <div className="lists allLists">
            {listsThatDoesNotHaveSameExercise
              .slice(0, 18)
              .map((list, index) => (
                <a
                  key={`${list.type}_${list.id}`}
                  onClick={() =>
                    openListClick(list, index, 'Seção Outras Listas')
                  }
                >{`Lista ${index + 1}`}</a>
              ))}
            <SendListButton
              className="inside-section"
              onClick={() => onClickSendList('Botão dentro da seção de listas')}
            >
              <MdOutlineUploadFile size="20px" className="icon" />
              <p>Enviar Nova Lista</p>
            </SendListButton>
          </div>
        </ListsSection>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 415px;
  height: 260px;

  @media ${media.mobile} {
    height: auto;
    width: 100%;
  }
`;

const ListsSection = styled.div`
  margin-left: 10px;
  width: 100%;

  h2 {
    font-size: 20px;
    color: #14b9a6;
    margin-bottom: 5px;

    &.allLists {
      margin-top: 20px;
    }
  }

  .lists {
    width: 90%;
    min-height: 50px;
    border: 1px solid #70bfc7;
    border-radius: 15px;
    margin-top: 10px;
    padding: 5px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;

    a {
      text-decoration: underline;
      color: #999999;
      text-underline-offset: 3px;
      font-size: 18px;
      padding: 0 2px;
      width: 70px;
      height: 25px;
    }

    &.allLists {
    }
  }

  @media ${media.mobile} {
    margin-left: 0;

    h2 {
      font-size: 17px;
      margin-left: 8px;
      margin-top: 10px;
    }

    .lists {
      margin-left: 10px;
      width: 96%;

      a {
        font-size: 15px;
        margin: 3px 0;
        width: 60px;
        height: 20px;
      }
    }
  }
`;

const SendListButton = styled.button`
  position: absolute;
  top: -13px;
  right: -13px;
  width: 212px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #70bfc7;
  color: #fff;
  border-radius: 15px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s ease opacity;

  p {
    margin-left: 5px;
    font-size: 18px;
  }

  .icon {
    color: #fff;
    font-size: 20px;
  }

  &:hover {
    opacity: 0.9;
  }

  &.inside-section {
    position: initial;
    width: 135px;
    height: 27px;
    background-color: #fff;
    color: #70bfc7;

    p {
      margin-left: 1px;
      font-size: 13px;
      text-decoration: underline;
      font-weight: bold;
      text-underline-offset: 3px;
    }

    .icon {
      color: #70bfc7;
    }
  }
`;
