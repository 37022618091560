import React, { useCallback, useEffect, useState } from 'react';
import { styled, colors } from 'styles';

export default function LoadingBar({ initialProgress, onProgressChange }) {
  const [progress, setProgress] = useState(initialProgress || 5);

  const increaseProgress = useCallback(() => {
    setProgress(prev => {
      if (prev <= 99.9) {
        const nextProgress = prev + (100 - prev) * 0.01;
        return nextProgress;
      }
      return prev;
    });

    onProgressChange(progress);
  }, [onProgressChange, progress]);

  useEffect(() => {
    let interval;

    interval = setInterval(() => {
      increaseProgress();
    }, 800);

    return () => clearInterval(interval);
  }, [increaseProgress, progress]);

  return (
    <LoadingContainer>
      <ProgressBar progress={progress} />
      <p>{`${progress.toFixed(1)} %`}</p>
    </LoadingContainer>
  );
}

const LoadingContainer = styled.div`
  width: 300px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid ${colors.darkGray};
  margin: 20px 0;
  padding: 3px;
  p {
    margin-top: 10px;
    font-weight: bold;
    text-align: right;
    font-size: 17px;
  }
`;

const ProgressBar = styled.div`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  border-radius: 15px;
  background-color: #06a54f;
`;
