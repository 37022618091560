import React from 'react';
import { Route } from 'react-router-dom';
import { BypassWithExtensionPage } from './pages';

export default [
  <Route
    exact
    path="/burlando-conteudo"
    component={BypassWithExtensionPage}
    key="bypass-message"
  />,
];
