import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import { styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import EditStudyPlanTopicsDialog from 'modules/guides/dialogs/EditStudyPlanTopicsDialog';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';

export default function StudyPlanEditButton({ guide }) {
  const { setDialog } = useDialogContext();

  const { data: studyPlan } = useContentQueryImmutable(
    `/study-plans/${guide.guidableId}`
  );

  const onClick = useCallback(() => {
    setDialog(
      <EditStudyPlanTopicsDialog studyPlan={studyPlan} guideId={guide.id} />
    );
  }, [studyPlan, guide, setDialog]);

  if (!studyPlan) return null;
  return (
    <Container
      icon={faPencilAlt}
      onClick={onClick}
      data-cy="side-menu-edit-study-plan"
    />
  );
}

const Container = styled(FontAwesomeIcon)`
  font-size: 0.7em;
  margin-left: 10px;
  cursor: pointer;
`;
