import { useCallback } from 'react';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useSWRConfig } from 'swr';

export default function useStudyPlan() {
  const { sendPutRequest, sendDeleteRequest } = useContentRestRequest();
  const { mutate } = useSWRConfig();

  const destroyStudyPlan = useCallback(
    async studyPlan => {
      const { data } = await sendDeleteRequest(`/study-plans/${studyPlan.id}`);

      return data.success;
    },
    [sendDeleteRequest]
  );

  const eraseCacheFromStudyPlansQueries = useCallback(
    async studyPlanId => {
      await mutate(
        `${process.env.REACT_APP_RA_CONTENT_URL}/api/v2/study-plans/${studyPlanId}`
      );
    },
    [mutate]
  );

  const updateStudyPlanBasicInfos = useCallback(
    async studyPlan => {
      await sendPutRequest('study-plans', {
        id: studyPlan.id,
        name: studyPlan.name,
        discipline: studyPlan.discipline,
        professor: studyPlan.professor,
        examDate: studyPlan.examDate,
      });

      await eraseCacheFromStudyPlansQueries(studyPlan.id);
    },
    [sendPutRequest, eraseCacheFromStudyPlansQueries]
  );

  const saveStudyPlanTopics = useCallback(
    (guideId, removedTopics) => {
      return sendPutRequest('study-plans', {
        guideId,
        removedTopics,
      });
    },
    [sendPutRequest]
  );

  return { destroyStudyPlan, updateStudyPlanBasicInfos, saveStudyPlanTopics };
}
