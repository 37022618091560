import React, { useCallback } from 'react';
import { styled, media } from 'styles';
import { format, isFuture, isPast, add } from 'date-fns';
import { useUserContext } from 'providers/UserProvider';

import { videoBackground, logo } from '../images';
import LoginContainer from './LoginContainer';
import { useTracker, useUTM } from 'modules/shared/hooks';

export default function Overlay(props) {
  const { lecture, setVideoIsPlaying } = props;
  const { user } = useUserContext();
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const getAmplitudeEventProperties = useCallback(() => {
    return {
      campanha: 'ICB 21.2',
      onde: media.isDesktop() ? 'Desktop' : 'Mobile',
      aulao: lecture.id,
      ...getUTMsByLocation(window.location),
    };
  }, [lecture, getUTMsByLocation]);

  const trackPlayLecture = useCallback(() => {
    trackEvent(
      'Lançamentos: Clicou Botão Aulão',
      getAmplitudeEventProperties()
    );
  }, [trackEvent, getAmplitudeEventProperties]);

  const onPlayButton = useCallback(() => {
    setVideoIsPlaying(true);
    trackPlayLecture();
  }, [setVideoIsPlaying, trackPlayLecture]);

  const checkIfLectureIsHapenning = useCallback(() => {
    const startingDate = new Date(lecture.transmissionDate * 1000);
    const hasAlreadyBegan = isPast(startingDate);
    const hasNotFinished = isFuture(
      add(startingDate, { minutes: lecture.duration })
    );
    return hasAlreadyBegan && hasNotFinished;
  }, [lecture]);

  const isLectureInPast = useCallback(() => {
    const startingDate = new Date(lecture.transmissionDate * 1000);
    return isPast(startingDate);
  }, [lecture]);

  const getCopyToShowBasedOnTransmitionDate = useCallback(() => {
    if (checkIfLectureIsHapenning()) return 'Esse aulão está rolando agora!';
    if (isLectureInPast())
      return 'Esse aulão já passou, mas você pode assistir a reprise na íntegra';

    return 'Esse aulão ainda vai rolar!';
  }, [checkIfLectureIsHapenning, isLectureInPast]);

  return (
    <OverlayStyled videoBackground={videoBackground}>
      <img alt="" src={logo} />
      <h2>{lecture.name}</h2>
      <p className="time">
        {format(new Date(lecture.transmissionDate * 1000), 'dd/MM - HH')}h
      </p>
      {user ? (
        <>
          <p>{getCopyToShowBasedOnTransmitionDate()}</p>
          <button className="now" onClick={onPlayButton}>
            Assistir agora
          </button>
        </>
      ) : (
        <>
          <p> Para assistir a esse aulão, faça login ou cadastre-se grátis </p>
          <LoginContainer />
        </>
      )}
    </OverlayStyled>
  );
}

const OverlayStyled = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    283.19deg,
    rgba(255, 238, 2, 0.86) 1.31%,
    rgba(255, 65, 0, 0.86) 100%
  );
  background-size: cover;
  padding-top: 2rem;

  img {
    width: 15rem;
  }

  h2 {
    font-family: Lato;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
  }

  .time {
    margin: 0.4rem 0 2.5rem 0;
  }

  p {
    font-family: Lato;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 3.39777px 3.39777px rgba(0, 0, 0, 0.25);
    text-align: center;
  }

  .now {
    background: #ffffff;
    border: 1px solid #ed893e;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.6rem;
    width: 15.5rem;
    height: 3.2rem;
    font-family: Lato;
    font-weight: 900;
    font-size: 1.3rem;
    line-height: 1.6rem;
    text-align: center;
    color: #fa6e09;
    margin-top: 1.1rem;
  }

  @media ${media.mobile} {
    img {
      width: 12.5rem;
    }

    h2 {
      font-size: 1.3rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.1rem;
    }
  }
`;
