import { poo, partyHard, pokerFace } from 'images/content-feedback';

export const FEEDBACK_OPTIONS = [
  {
    id: 1,
    text: 'Ruim!',
    iconSrc: poo,
  },
  {
    id: 2,
    text: 'Meh!',
    iconSrc: pokerFace,
  },
  {
    id: 3,
    text: 'Demais!',
    iconSrc: partyHard,
  },
];
