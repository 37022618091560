import { useUserContext } from 'providers/UserProvider';
import React, { useEffect, useCallback } from 'react';
import { media, styled } from 'styles';
import dexterImage from '../../images/book-banner/university-personalized-content/dexter.png';
import PersonalizedContentDialog from 'modules/shared/dialogs/personalized-content-dialog/PersonalizedContentDialog';
import { useDialogContext } from 'providers/DialogProvider';
import { useHistory } from 'react-router-dom';
import { useTracker } from 'modules/shared/hooks';

export default function UniversityPersonalizedContentBanner() {
  const { user } = useUserContext();
  const { setDialog } = useDialogContext();
  const history = useHistory();
  const { trackEvent } = useTracker();

  const viewBannerEvent = useCallback(() => {
    trackEvent('Visualizou Banner de Conteúdo Personalizado', {
      Onde: 'Livros',
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
    });
  }, [trackEvent]);

  const onClickBanner = useCallback(() => {
    trackEvent('Clicou Banner de Conteúdo Personalizado', {
      Onde: 'Livros',
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
    });
    setDialog(
      <PersonalizedContentDialog history={history} source="banner-livros" />
    );
  }, [trackEvent, setDialog, history]);

  useEffect(() => {
    viewBannerEvent();
  }, [viewBannerEvent]);

  return (
    <ContainerBanner onClick={onClickBanner} data-cy="personalized-banner">
      <DexterImage src={dexterImage} alt="dexter" />
      <Description>
        <h2>
          Conteúdos feitos para você <br />
          {` mandar bem ${user?.university?.gender === 'F' ? 'na' : 'no'} ${
            user?.university?.acronym
          }`}
        </h2>
      </Description>
      <CTAButton>clique e confira</CTAButton>
    </ContainerBanner>
  );
}

const ContainerBanner = styled.div`
  width: 900px;
  height: 120px;
  background: linear-gradient(180deg, #7000ff 0%, #92227a 59.37%, #ff9d2d 100%),
    #bb8ab9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  border-radius: 6px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;

  @media ${media.mobile} {
    width: 95%;
    height: 90px;
    padding: 0 10px;
    flex-direction: column;
    margin: 0 auto;
  }
`;

const DexterImage = styled.img`
  height: 115px;

  @media ${media.mobile} {
    display: none;
  }
`;

const Description = styled.div`
  margin-left: -15px;

  h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: #ffffff;
  }

  @media ${media.mobile} {
    width: 95%;
    margin: 0 auto;

    h2 {
      font-size: 14.5px;
      margin-bottom: 5px;
      margin: 0 auto;
      line-height: 20px;
    }
  }
`;

const CTAButton = styled.button`
  width: 220px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 30px #ffffff;
  border-radius: 10px;
  outline: 0;
  border: 0;
  text-transform: uppercase;
  color: #410094;
  font-weight: bold;
  font-size: 17px;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }

    100% {
      transform: scale(1.035);
    }
  }

  &.pulse {
    animation: pulse 1.4s infinite;
  }

  @media ${media.mobile} {
    width: 150px;
    height: 30px;
    font-size: 11px;
  }
`;
