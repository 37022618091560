import React from 'react';

import { styled } from 'styles';

export default function RecaptchaInfo() {
  return (
    <Container>
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </Container>
  );
}

const Container = styled.p`
  width: 100%;
  padding: 5px 20px;
  text-align: left;
  font-size: 0.65em;

  a {
    text-decoration: underline;
  }
`;
