import React from 'react';
import {
  LoginComponent,
  UserFreePlan,
  UserPendingBoleto,
  UserProfile,
  UserSubscription,
} from 'modules/profile/components';
import { Error, Loading } from 'modules/shared/components/generic';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { useRAQueryImmutable } from 'modules/shared/hooks/requests';
import { useUserContext } from 'providers/UserProvider';
import { useEffect, useMemo } from 'react';
import { media, styled } from 'styles';
import UserAppleSubscription from '../components/UserAppleSubscription';
import UserGooglePlaySubscription from '../components/UserGooglePlaySubscription';
import { useRenderAccountPageDialog } from '../hooks';

export default function AccountPage() {
  const { user } = useUserContext();
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const { data, loading, error } = useRAQueryImmutable('/subscriptions');
  const { data: pendingBoletoResponse } = useRAQueryImmutable(
    '/payments/boleto'
  );

  useRenderAccountPageDialog(data?.subscription);

  useEffect(() => {
    trackEvent('Visualizou Minha Conta', {
      ...getUTMsByLocation(window.location),
      logado: user ? 'Sim' : 'Não',
    });
  }, [getUTMsByLocation, user, trackEvent]);

  const subscription = useMemo(() => {
    return data?.subscription;
  }, [data]);

  const pendingBoleto = useMemo(() => {
    return pendingBoletoResponse?.boleto;
  }, [pendingBoletoResponse]);

  const isAppSubscription = useMemo(() => {
    return (
      subscription &&
      (subscription.order.fromApple || subscription.order.fromGooglePlay)
    );
  }, [subscription]);

  if (!user) return <LoginComponent />;

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <Container>
      <UserProfile user={user} />

      {subscription && !isAppSubscription && (
        <UserSubscription user={user} subscription={subscription} />
      )}

      {pendingBoleto && <UserPendingBoleto pendingBoleto={pendingBoleto} />}

      {subscription && subscription.order.fromApple && (
        <UserAppleSubscription />
      )}

      {subscription && subscription.order.fromGooglePlay && (
        <UserGooglePlaySubscription subscription={subscription} />
      )}

      {!subscription && <UserFreePlan />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;

  @media ${media.mobile} {
    padding-top: 90px;
  }
`;
