import React, { useCallback, useEffect, useRef } from 'react';
import { ListSection, StudySupportSection } from '../components/Professor';
import ProfessorHeader from '../components/headers/ProfessorHeader';
import { useParams } from 'react-router-dom';
import { Loading, Error } from 'modules/shared/components/generic';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { useProfessorTracker } from '../hooks';
import { useToast } from 'modules/shared/hooks';
import Cookies from 'js-cookie';
import SupportMaterialBanner from '../components/Professor/SupportMaterialBanner';
import { useSupportMaterialBanner } from '../hooks/useSupportMaterialBanner';

export default function ProfessorWithListsPage({
  professorWithLists,
  mutateProfessorQuery,
}) {
  const { professorId } = useParams();
  const { trackViewProfessorPage } = useProfessorTracker();
  const studySupportRef = useRef(null);
  const { successToast } = useToast();
  const { isEligibleToBanner } = useSupportMaterialBanner();

  useEffect(() => {
    if (!professorWithLists) return;
    const visitedPages = Cookies.get('visitedProfessorPages');

    let visitedPagesArray = visitedPages ? JSON.parse(visitedPages) : [];

    if (!visitedPagesArray.includes(professorId)) {
      visitedPagesArray.push(professorId);

      Cookies.set('visitedProfessorPages', JSON.stringify(visitedPagesArray), {
        expires: 365,
      });

      const totalLists =
        professorWithLists.lists.length +
        professorWithLists.statementLists.length;

      successToast(
        `${
          totalLists > 1 ? `${totalLists} Listas` : `${totalLists} Lista`
        } do seu(a) Professor(a) resolvidas!`
      );
    }
  }, [professorId, professorWithLists, successToast]);

  const {
    data: studySupportMaterial,
    loading: studySupportMaterialLoading,
    error: studySupportMaterialError,
  } = useContentQueryImmutable(
    `/mvp/guide-with-lectures-and-books-from-professor/${professorId}`
  );

  const handleScrollToSupportSection = useCallback(() => {
    studySupportRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!professorWithLists) return;

    trackViewProfessorPage({
      professorName: professorWithLists?.name,
      disciplineName: professorWithLists?.teachable?.name,
      lists: [
        ...professorWithLists.lists,
        ...professorWithLists.statementLists,
      ],
    });
    // IPC: not increment trackViewProfessorPage since it depends on cookie counting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professorWithLists]);

  if (studySupportMaterialError) return <Error />;
  if (studySupportMaterialLoading) return <Loading />;

  return (
    <>
      <ProfessorHeader
        professor={professorWithLists}
        color={professorWithLists.teachable.theme.color}
      />

      <ListSection
        professor={professorWithLists}
        handleScrollToSupportSection={handleScrollToSupportSection}
        mutateProfessorQuery={mutateProfessorQuery}
      />

      <div ref={studySupportRef}>
        <StudySupportSection
          professor={professorWithLists}
          studySupportMaterial={studySupportMaterial}
        />
      </div>
      {isEligibleToBanner() && (
        <SupportMaterialBanner professor={professorWithLists} />
      )}
    </>
  );
}
