import { useCookies } from 'modules/shared/hooks/useCookies';
import { useEffect } from 'react';

const COOKIE_NAME = 'user_history';

export function useUserHistoryTracking() {
  const [cookies, setCookie] = useCookies([COOKIE_NAME]);

  useEffect(() => {
    const cookieData = cookies[COOKIE_NAME];
    const newCookieData = {
      firstURL: cookieData?.firstURL || window.location.pathname,
      lastURL: window.location.pathname,
    };

    setCookie(COOKIE_NAME, JSON.stringify(newCookieData));
    // important not set cookies as dependency, otherwise it will be an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
