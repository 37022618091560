import React, { useCallback } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';

import { CardInfoField } from 'modules/profile/components';
import { useDialogContext } from 'providers/DialogProvider';
import ProfileEdit from 'modules/profile/dialogs/profile-edit/ProfileEdit';

export default function Course({ user }) {
  const { setDialog } = useDialogContext();
  const handleEditCourseInfo = useCallback(() => {
    setDialog(<ProfileEdit />);
  }, [setDialog]);

  return (
    <section>
      <div className="sectionTitle">
        <h3>Seu Curso</h3>
        <button onClick={handleEditCourseInfo}>
          <Icon icon={faEdit} data-cy="course-edit" /> Editar
        </button>
      </div>

      <CardInfoField fieldId="state" label="Estado">
        {user.university?.state}
      </CardInfoField>

      <CardInfoField fieldId="university" label="Faculdade">
        {user.university?.name}
      </CardInfoField>

      {user.universityCampus && (
        <CardInfoField fieldId="university-campus" label="Campus">
          {user.universityCampus.name}
        </CardInfoField>
      )}

      {user?.university?.name !== 'Não faço faculdade' && (
        <>
          <CardInfoField fieldId="course" label="Curso">
            {user?.course?.name || 'Não informado'}
          </CardInfoField>

          {user?.periodAdmission && user?.yearAdmission ? (
            <CardInfoField fieldId="admission" label="Admissão">
              {user?.periodAdmission} - {user?.yearAdmission}
            </CardInfoField>
          ) : (
            <>
              <CardInfoField
                fieldId="period-admission"
                label="Período de Admissão"
              >
                {user?.periodAdmission || 'Não informado'}
              </CardInfoField>

              <CardInfoField fieldId="year-admission" label="Ano de Admissão">
                {user?.yearAdmission || 'Não informado'}
              </CardInfoField>
            </>
          )}
        </>
      )}
    </section>
  );
}
