import React, { useCallback, useMemo } from 'react';
import { addMonths, format } from 'date-fns';

import { colors, styled } from 'styles';
import { useDate } from 'modules/shared/hooks';
import {
  Container,
  Content,
  ButtonsContainer,
  Button,
} from 'modules/campaigns/change-plan-on-cancel/components';
import MoneyIcon from 'images/cancellation/money.png';

export default function ChangePlanOnCancelSuccessDialog({
  subscription,
  newPlanPricing,
}) {
  const { getHumanReadableDateFromTimestamp } = useDate();

  const onButtonClick = useCallback(() => {
    window.location.reload();
  }, []);

  const nextRenovationDate = useMemo(() => {
    const currentRenovationDate = new Date(subscription.expiresIn * 1000);
    const newRenovationDate = addMonths(
      currentRenovationDate,
      newPlanPricing.plan.months
    );

    return format(newRenovationDate, 'dd/MM/yyyy');
  }, [subscription, newPlanPricing]);

  return (
    <Container>
      <Content borderColor={colors.successGreen}>
        <Icon src={MoneyIcon} />
        <Title>Plano trocado com sucesso!</Title>
        <Info>
          Agora você é assinante do plano {newPlanPricing.plan.description} do
          Responde Aí. À partir do dia{' '}
          {getHumanReadableDateFromTimestamp(subscription.expiresIn)}, receberá
          as cobranças desse novo plano, e a data de renovação dele será no dia{' '}
          {nextRenovationDate}.
        </Info>
        <Info>Bons estudos!</Info>
        <ButtonsContainer center={true}>
          <Button onClick={onButtonClick}>Ok, entendi</Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Icon = styled.img`
  display: block;
  margin: 20px auto 5px auto;
  width: 65px;
`;

const Title = styled.h1`
  font-weight: bold;
  color: ${colors.successGreen};
  font-size: 1.4em;
  text-align: center;
`;

const Info = styled.div`
  font-size: 0.9em;
  font-weight: bold;
  margin: 15px 0;
`;
