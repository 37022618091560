import React, { useCallback, useEffect } from 'react';
import { styled } from 'styles';
import moduleCompleted from '../../images/module-completed.png';
import useLectureTracker from 'modules/lectures/hooks/useLectureTracker';
import { useLectureContext } from '../../providers/LectureProvider';
import { IoMdPlay } from 'react-icons/io';
import { TbFileDescription } from 'react-icons/tb';
import { media } from 'styles';

const FinishedModuleOverlay = ({ seekTo }) => {
  const {
    currentVideoIndex,
    setCurrentVideoIndex,
    setProgressPercentage,
  } = useLectureContext();

  const { trackPraticeMoreClick, trackFinishLecture } = useLectureTracker();

  useEffect(() => {
    trackFinishLecture();
  }, [trackFinishLecture]);

  const onNextModuleClick = useCallback(() => {
    seekTo(0, currentVideoIndex + 1);
    setCurrentVideoIndex(currentVideoIndex + 1);
    setProgressPercentage(0);
  }, [seekTo, setCurrentVideoIndex, currentVideoIndex, setProgressPercentage]);

  return (
    <Overlay>
      <ImageContainer>
        <ModuleCompletedImg src={moduleCompleted} />
      </ImageContainer>
      <Title>
        Você concluiu este módulo!
        <br />
        <strong>PARABÉNS!!</strong>
      </Title>
      <Text>O que deseja fazer agora?</Text>
      <ButtonContainer>
        <Button href="#pratice" onClick={() => trackPraticeMoreClick()}>
          <TbFileDescription className="icon" />
          Praticar mais
        </Button>
        <Button onClick={onNextModuleClick}>
          <IoMdPlay className="icon" /> Continuar
        </Button>
      </ButtonContainer>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
  text-align: center;
  .icon {
    font-size: 20px;
    margin-right: 3px;
  }
`;

const ImageContainer = styled.div`
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 30px auto;
  border-bottom: 1px solid #ccc;
  @media ${media.mobile} {
    height: 20%;
    margin: 5px 0 5px 0;
  }
`;

const ModuleCompletedImg = styled.img`
  height: 100%;
`;

const Title = styled.div`
  font-size: 0.8em;
`;

const Text = styled.div`
  margin-top: 80px;
  @media ${media.mobile} {
    margin-top: 30px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
`;

const Button = styled.a`
  width: 200px;
  height: 60px;
  margin: 0 5px;
  color: white;
  background-color: #f9ac3e;
  font-size: 1.1em;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${media.mobile} {
    width: 100px;
    height: 30px;
    font-size: 0.75em;
  }
`;

export default FinishedModuleOverlay;
