import React, { useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import { useField } from '@unform/core';

import { styled, colors } from 'styles';

export default function FormInput({ name, ...props }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container error={error}>
      <InputMask ref={inputRef} defaultValue={defaultValue} {...props} />
      {error && <p>{error}</p>}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;

  > input {
    ${props => props.error && `border-color: ${colors.ErrorReportRed}`}
  }

  > p {
    color: ${colors.ErrorReportRed};
  }
`;
