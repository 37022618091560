import React from 'react';
import { colors, media, styled } from 'styles';
import CardTitle from './CardTitle';
import SubscriptionInfoDefault from './SubscriptionInfoDefault';

export default function UserGooglePlaySubscription({ subscription }) {
  const subscriptionState = subscriptionStates[subscription?.order?.state] || {
    message: '',
    color: 'transparent',
  };

  return (
    <Container>
      <CardTitle title="Assinatura" upper />

      <Content>
        <SubscriptionTitle color={subscriptionState.color}>
          <div>
            <h3>Acesso Completo ao Responde Aí!</h3>
            <span>{subscriptionState.message}</span>
          </div>
        </SubscriptionTitle>

        <SubscriptionInfoDefault subscription={subscription} />

        <p>Você assinou seu plano através do Google Play.</p>

        <p>
          Para cancelar ou gerenciar seu plano, acesse diretamente o Google Play
          pelo seu aparelho.
        </p>

        <p>Qualquer dúvida, entre em contato pelo:</p>
        <p>
          <a href="mailto:faleai@respondeai.com.br">faleai@respondeai.com.br</a>
        </p>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 90%;

  max-width: 720px;
  margin-bottom: 50px;

  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 15px -2px rgba(51, 51, 51, 0.5);
`;

const Content = styled.div`
  padding: 0 40px 20px 40px;

  p {
    margin-bottom: 20px;

    a {
      color: #4040f2;
    }
  }

  @media ${media.mobile} {
    padding: 0 10px 20px 10px;
  }
`;

const SubscriptionTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;

  div {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 1.1em;
      font-weight: bold;
      margin-bottom: 5px;
    }

    span {
      background-color: ${({ color }) => color};
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      display: flex;
      width: max-content;
      padding: 2px 5px;
    }
  }
`;

const subscriptionStates = {
  voided: {
    message: 'Cancelada',
    color: colors.warningRed,
  },
  waiting_void: {
    message: 'Cancelando...',
    color: colors.warningRed,
  },
  paid: {
    message: 'Ativa',
    color: colors.successGreen,
  },
  opened: {
    message: 'Ativa',
    color: colors.successGreen,
  },
  reactivated: {
    message: 'Reativada',
    color: colors.successGreen,
  },
  freezed: {
    message: '',
    color: '#8DADEC',
  },
  refunded: {
    message: 'Estornada',
    color: '#8DADEC',
  },
  scheduled: {
    message: 'Agendada',
    color: '#8DADEC',
  },
  payment_failed: {
    message: 'Falha no pagamento',
    color: colors.warningRed,
  },
};
