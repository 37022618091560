import { useCallback, useEffect } from 'react';
import { useLectureContext } from 'modules/lectures/providers/LectureProvider';

export default function useLectureKeyboardPlayer({ seekTo }) {
  const {
    playing,
    setPlaying,
    progressSeconds,
    currentVideoIndex,
    errorReportInputIsOpen,
  } = useLectureContext();

  const handleKeyboardEvent = useCallback(
    e => {
      if (e.code === 'Space' && !errorReportInputIsOpen) {
        setPlaying(!playing);
        e.preventDefault();
      }

      if (e.code === 'ArrowRight') {
        seekTo(progressSeconds + 15, currentVideoIndex, 'seconds');
        e.preventDefault();
      }

      if (e.code === 'ArrowLeft') {
        seekTo(progressSeconds - 15, currentVideoIndex, 'seconds');
        e.preventDefault();
      }
    },
    [
      playing,
      setPlaying,
      progressSeconds,
      seekTo,
      currentVideoIndex,
      errorReportInputIsOpen,
    ]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardEvent);

    return () => {
      document.removeEventListener('keydown', handleKeyboardEvent);
    };
  }, [handleKeyboardEvent]);
}
