import { useToast, useTracker } from 'modules/shared/hooks';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useDialogContext } from 'providers/DialogProvider';
import { useUserContext } from 'providers/UserProvider';
import React, { useCallback, useEffect, useState } from 'react';

import { styled, colors, media } from 'styles';

export function DeleteAccountDialog() {
  const [loading, setLoading] = useState(false);
  const { unsetDialog } = useDialogContext();
  const deleteAccountReq = useRAPostRequest('profile/delete_account');
  const { onLogout } = useUserContext();
  const { successToast, errorToast } = useToast();
  const { trackEvent } = useTracker();

  useEffect(() => {
    trackEvent('Minha Conta: Abriu Dialog Deletar Conta');
  }, [trackEvent]);

  const onDeleteClick = useCallback(async () => {
    if (loading) return;

    try {
      setLoading(true);
      await deleteAccountReq();
      onLogout();
      unsetDialog();
      successToast('Conta excluída com sucesso!');
      trackEvent('Minha Conta: Deletou Conta');
    } catch (e) {
      errorToast(
        'Erro ao deletar conta! Por favor, entre em contato com o relacionamento para continuar o processo de exclusão.'
      );
    } finally {
      setLoading(false);
    }
  }, [loading, deleteAccountReq, trackEvent, unsetDialog]);

  return (
    <Container onClick={unsetDialog}>
      <Content onClick={e => e.stopPropagation()}>
        <Confirmation>Tem certeza que deseja excluir sua conta?</Confirmation>

        <ConfirmationInfo>
          Ao excluir sua conta, você perderá acesso a todos os conteúdos e
          materiais, e seus dados serão excluídos da plataforma.
        </ConfirmationInfo>

        <ButtonsContainer>
          <button onClick={unsetDialog}>Não</button>

          <button onClick={onDeleteClick} className="highlight">
            {loading ? 'Deletando...' : 'Sim'}
          </button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-top: 10px solid ${colors.warningRed};
  text-align: left;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const Confirmation = styled.h1`
  font-weight: bold;
  color: ${colors.warningRed};
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 30px;
`;

const ConfirmationInfo = styled.p`
  width: 100%;
  line-height: 1.2;
  margin: 25px 0;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;

  button {
    flex: 1;
    margin: 0 10px;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    border: 2px solid #929292;
    color: #929292;
    text-transform: uppercase;

    &.highlight {
      border-color: ${colors.warningRed};
      color: ${colors.warningRed};
    }
  }

  @media ${media.mobile} {
    flex-direction: column-reverse;

    button {
      width: 100%;
      margin: 5px 0;
      padding: 10px 0;
    }
  }
`;
