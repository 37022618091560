import React from 'react';
import { Route } from 'react-router-dom';
import { BookEditionPage, BookEditionsPage, BooksPage } from './pages';

export default [
  <Route
    exact
    path="/livros-resolvidos"
    component={BooksPage}
    key="livros-resolvidos"
  />,
  <Route
    exact
    path="/livros-resolvidos/livro/:bookId/edicoes"
    component={BookEditionsPage}
    key="livros-resolvidos-edicoes"
  />,
  <Route
    exact
    path="/materias/solucionario/livro/:bookId/edicao/:editionId"
    component={BookEditionPage}
    key="livro-edicao"
  />,
  <Route
    exact
    path="/materias/solucionario/livro/:bookId/edicao/:editionId/exercicio/:exerciseId"
    component={BookEditionPage}
    key="livro-edicao-exercicio"
  />,
];
