import React from 'react';
import { styled } from 'styles';
import { media } from 'styles';

export default function NewYerWarningBanner() {
  return (
    <Container>
      <span>
        Nos dias <strong>31/12 e 01/01</strong> não vamos ter atendimento, mas
        não se preocupe, que a gente te responderá assim que voltar
      </span>
    </Container>
  );
}

const Container = styled.div`
  background: #ead308;
  width: 100%;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 3px;
  @media ${media.mobile} {
    height: inherit;
    font-size: 10px;
  }
  z-index: 3;
`;
