import React, { useCallback, useEffect } from 'react';
import { styled, media } from 'styles';
import dexter from 'images/permission/dexter-blink.png';
import { useSupportMaterialBanner } from 'modules/guides/hooks/useSupportMaterialBanner';
import { useProfessorTracker } from 'modules/guides/hooks';

export default function SupportMaterialBanner({ professor }) {
  const {
    setCookieShouldNotShowBanner,
    incrementViewBanner,
  } = useSupportMaterialBanner();
  const { trackClickSupportBanner } = useProfessorTracker();

  const onClickButton = useCallback(() => {
    setCookieShouldNotShowBanner();
    trackClickSupportBanner(professor);
  }, [professor, setCookieShouldNotShowBanner, trackClickSupportBanner]);

  useEffect(() => {
    incrementViewBanner();
    // important no to put any dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <img src={dexter} />
      <p>
        A página mudou e agora você encontra <br /> seus Livros, Aulões e
        Explicações da <br /> Matéria aqui embaixo
      </p>
      <ButtonStyled onClick={onClickButton}> Ok, entendi</ButtonStyled>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 130px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #efefef;

  img {
    width: 145px;
    margin-right: 40px;
    margin-top: 57px;
  }

  p {
    color: #515151;
    font-size: 1.4rem;
    font-weight: bold;
    margin-right: 55px;
  }

  @media ${media.mobile} {
    height: 70px;
    justify-content: space-between;
    padding: 0 10px;

    img {
      width: 64px;
      margin-right: -1px;
      margin-top: 36px;
    }

    p {
      font-size: 0.7rem;
      margin-right: 2px;
      margin-top: 2px;
    }
  }
`;

const ButtonStyled = styled.button`
  background-color: #f7ac3d;
  width: 160px;
  height: 33px;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1rem;

  @media ${media.mobile} {
    width: 92px;
    height: 20px;
    font-size: 0.7rem;
    margin-top: 2px;
  }
`;
