import _ from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker, useCreditCard } from 'modules/shared/hooks';
import { Container, Content, Title, Button } from './styles';
import useChangeCreditCard from 'modules/campaigns/change-credit-card/hooks/useChangeCreditCard';
import MundiSecurityImage from 'images/generic/mundipagg-secutiry.png';
import CardForm from 'modules/shared/components/CardForm';
import SuccessDialog from './SuccessDialog';

export default function ChangeCreditCardDialog({ subscription }) {
  const { setDialog, unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const { getCreditCardErrors } = useCreditCard();
  const { submitUpdateCreditCard } = useChangeCreditCard();

  const [creditCardData, setCreditCardData] = useState(
    INITIAL_CREDIT_CARD_DATA
  );
  const [creditCardDataError, setCreditCardDataError] = useState(
    INITIAL_CREDIT_CARD_DATA
  );
  const [loading, setLoading] = useState(false);

  const alreadyChangeForm = useMemo(() => {
    return !_.isEqual(creditCardDataError, INITIAL_CREDIT_CARD_DATA);
  }, [creditCardDataError]);

  useEffect(() => {
    trackEvent('Troca Cartao: Visualizou Dialog', {
      Plano: subscription.plan?.description,
      'Meio Pagamento': subscription.order?.paymentMethod,
    });
  }, [trackEvent, subscription]);

  useEffect(() => {
    if (!alreadyChangeForm) return;

    const errors = getCreditCardErrors(creditCardData);
    setCreditCardDataError(errors);
  }, [alreadyChangeForm, creditCardData, getCreditCardErrors]);

  const onUpdateCardClick = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    const errors = getCreditCardErrors(creditCardData);
    if (errors.number || errors.holderName || errors.expiry || errors.cvv) {
      setCreditCardDataError(errors);
      setLoading(false);
      return;
    }

    try {
      await submitUpdateCreditCard(subscription, creditCardData);
      setDialog(<SuccessDialog />);
    } catch (e) {
      setLoading(false);
      const message = e?.response?.data?.message;
      alert(
        message ||
          'Erro ao atualizar o cartão. Revise os dados do mesmo e caso o erro continue, entre em contato com o atendimento'
      );
    }
  }, [
    creditCardData,
    getCreditCardErrors,
    loading,
    setDialog,
    submitUpdateCreditCard,
    subscription,
  ]);

  return (
    <Container onClick={unsetDialog}>
      <Content onClick={e => e.stopPropagation()}>
        <Title>Trocar cartão de crédito</Title>
        <SecurityImage src={MundiSecurityImage} />
        <CardForm
          creditCardData={creditCardData}
          setCreditCardData={setCreditCardData}
          creditCardDataError={creditCardDataError}
        />

        <Button onClick={onUpdateCardClick}>
          {loading ? 'Atualizando cartão...' : 'Atualizar cartão'}
        </Button>
      </Content>
    </Container>
  );
}

const INITIAL_CREDIT_CARD_DATA = {
  number: '',
  holderName: '',
  expiry: '',
  cvv: '',
};

const SecurityImage = styled.img`
  width: 220px;
  max-width: 90%;
  margin-bottom: 20px;
`;
