import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { styled, media } from 'styles';

export const Heart = styled.span`
  color: red;
  font-size: 1.2em;
  font-weight: bold;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  padding: 20px;

  &.ai {
    background-color: rgba(106, 13, 145, 0.1);

    .title {
      color: #6a0d91;
    }
  }

  &.ai-dialog {
    background-color: transparent;

    .title {
      color: #6a0d91;
      font-weight: bold;
    }

    p {
      font-weight: bold;
      font-size: 0.9em;
    }
  }
`;

export const Title = styled.h1`
  font-size: 1em;
  text-align: center;
  max-width: 80%;

  @media ${media.mobile} {
    font-size: 0.9em;
  }
`;

export const FeedbackGrade = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export const FeedbackIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FeedbackIcon = styled.img`
  width: 40px;
  height: 40px;
  filter: grayscale(100%);
  cursor: pointer;

  &:hover {
    filter: none;
  }

  @media ${media.mobile} {
    filter: none;
    width: 30px;
    height: 30px;
  }
`;

export const FeedbackLabel = styled.p`
  width: 100%;
  text-align: center;
  font-size: 0.75em;
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 1.2em;
  margin-right: 5px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
`;

export const SubmitIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4em;
  cursor: pointer;
`;

export const CommentContainer = styled.div`
  margin: auto;
  width: 33%;
  position: relative;
  min-width: 250px;

  .comment-area {
    border: #bcbcbc solid 1px;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;

    padding: 10px 40px 10px 10px;
    width: 100%;
    min-height: 100px;
    border-radius: 10px;
  }
`;
