import React, { useCallback } from 'react';
import styled from 'styled-components';
import { BookNotCompleteMessage, BookCard } from 'modules/books/components';
import { media } from 'styles';
import TagAsFreeContent from 'modules/shared/components/TagAsFreeContent';
import { useBookPermission } from 'modules/books/hooks';
import { usePermission } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';

export default function ThemeBooks({ theme }) {
  const { UserInPlaybookFreeUniversity } = usePermission();
  const { shouldBooksFirstChaptersBeFree } = useBookPermission();
  const { user } = useUserContext();

  const shouldShowFreeTag = useCallback(() => {
    return shouldBooksFirstChaptersBeFree && !user?.subscriber;
  }, [shouldBooksFirstChaptersBeFree, user]);

  return (
    <Container key={theme.id}>
      <ThemeInfo>
        <ThemeTitle color={theme.color}>{theme.name}</ThemeTitle>
        {!media.isMobile() && (
          <>
            {shouldShowFreeTag() ? (
              <CustomTagAsFreeContent
                text={
                  UserInPlaybookFreeUniversity()
                    ? 'RESOLUÇÕES 100% GRÁTIS ATÉ AS PRIMEIRAS PROVAS'
                    : 'RESOLUÇÃO DO 1º CAPÍTULO GRÁTIS'
                }
                playbook={UserInPlaybookFreeUniversity()}
              />
            ) : (
              <BookNotCompleteMessage />
            )}
          </>
        )}
      </ThemeInfo>
      <BooksContainer>
        {theme.books.map(book => (
          <BookCard key={book.id} book={book} />
        ))}
      </BooksContainer>
      {media.isMobile() && (
        <>
          {shouldShowFreeTag() ? (
            <CustomTagAsFreeContentMobile text="RESOLUÇÃO DO 1º CAPÍTULO GRÁTIS" />
          ) : (
            <BookNotCompleteMessage />
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 960px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);

  @media ${media.mobile} {
    width: 100%;
    box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.75);
    border-radius: 0;
    padding-bottom: 25px;
  }
`;

const ThemeInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
  width: 100%;

  @media ${media.mobile} {
    padding: 20px 0;
  }
`;

const ThemeTitle = styled.h3`
  font-size: 1.6em;
  font-weight: bold;
  padding-left: 25px;
  color: ${props => props.color};

  @media ${media.mobile} {
    font-size: 1.3em;
  }
`;

const BooksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 25px 0px 25px;

  @media ${media.mobile} {
    overflow-x: auto;
    flex-wrap: nowrap;
  }
`;

const CustomTagAsFreeContent = styled(TagAsFreeContent)`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  top: 25px;
  right: 45px;
  width: ${({ playbook }) => (playbook ? '460px' : '415px')};
  height: 34px;
  font-size: ${({ playbook }) => (playbook ? '16px' : '20px')};

  .star-icon {
    font-size: 24px;
    color: #f7ac3b;
  }

  @media ${media.mobile} {
    font-size: 13px;
    line-height: 15px;
    max-width: 160px;
  }
`;

const CustomTagAsFreeContentMobile = styled(TagAsFreeContent)`
  position: auto;
  width: ${({ playbook }) => (playbook ? '400px' : '280px')};
  height: ${({ playbook }) => (playbook ? '45px' : '34px')};
  font-size: ${({ playbook }) => (playbook ? '14px' : '20px')};
  margin-top: 14px;
  margin-left: 28px;
  background-color: rgba(0, 0, 0, 0.8);

  .star-icon {
    font-size: 24px;
    color: #f7ac3b;
  }

  @media ${media.mobile} {
    font-size: 13px;
    line-height: 15px;
  }
`;
