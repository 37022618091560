import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { styled, colors } from 'styles';
import { LoginButtonsContainer } from 'modules/shared/components/login';
import {
  useFormerSubscriberRecoverCampaign,
  usePaymentFailedRecoverCampaign,
} from 'modules/campaigns/recover-subscriber';

export default function LoginComponent() {
  const location = useLocation();

  const {
    hasFormerSubscriberRecoverQueryParams,
  } = useFormerSubscriberRecoverCampaign();
  const {
    hasPaymentFailedRecoverQueryParams,
  } = usePaymentFailedRecoverCampaign();

  const getCTA = useCallback(() => {
    if (hasFormerSubscriberRecoverQueryParams(location))
      return (
        <>
          <Bold>Logue</Bold> para voltar a ser assinante com uma condição
          exclusiva!
        </>
      );

    if (hasPaymentFailedRecoverQueryParams(location)) {
      return (
        <>
          <Bold>Logue</Bold> para recuperar sua assinatura com uma condição
          exclusiva!
        </>
      );
    }

    return (
      <>
        <Bold>Logue ou Cadastre-se grátis</Bold> no maior portal de exatas do
        Brasil!
      </>
    );
  }, [
    location,
    hasFormerSubscriberRecoverQueryParams,
    hasPaymentFailedRecoverQueryParams,
  ]);

  return (
    <Container>
      <Content onClick={e => e.stopPropagation()}>
        <Paragraph>{getCTA()}</Paragraph>
        <LoginButtonsContainer where="dialog" origin="minha conta" />
        <Disclaimer>Relaxa, não vamos publicar nada no seu nome.</Disclaimer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
`;

const Paragraph = styled.p`
  font-size: 1.3em;
  margin-bottom: 30px;
  font-weight: 100;
  &:last-child {
    margin-bottom: 40px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Disclaimer = styled.p`
  color: ${colors.mediumGray};
  padding: 5px;
`;
