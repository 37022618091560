export const academicEducationList = [
  {
    id: 1,
    value: 'Estou no Ensino Fundamental',
  },
  {
    id: 2,
    value: 'Estou no Ensino Médio',
  },
  {
    id: 3,
    value: 'Estou no Ensino Superior',
  },
  {
    id: 4,
    value: 'Terminei o Ensino Superior',
  },
  {
    id: 5,
    value: 'Não tenho matrícula em uma instituição de ensino',
  },
  {
    id: 6,
    value: 'Outro',
  },
];
