import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router';

import { paper } from 'modules/summaries/images';
import { styled } from 'styles';

export default function SubjectItem({ name, subjectId, color }) {
  const { search } = useLocation();
  return (
    <Link to={{ pathname: `/resumoes-gratis/${subjectId}`, search: search }}>
      <Image src={paper} />
      <Name color={color}>{name}</Name>
    </Link>
  );
}

const Link = styled(RouterLink)`
  transition: transform 0.3s ease-in;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

const Image = styled.img`
  width: 130px;
  height: 170px;
`;

const Name = styled.span`
  color: ${props => props.color};
  position: absolute;
  top: 25px;
  left: 15px;
  width: 100px;
  max-width: 95%;
  font-weight: bold;
  word-break: break-word;
`;
