import { useCallback, useMemo, useState } from 'react';
import { useTracker, useGTM, useUTM } from 'modules/shared/hooks';
import { media } from 'styles';

export default function useSummaryTracker(subject) {
  const [lastChapterIdTracked, setLastChapterIdTracked] = useState(null);
  const {
    trackEvent,
    trackMktCloudActivationEvents,
    trackEventToRaEvents,
  } = useTracker();
  const { getUTMsByLocation } = useUTM();
  const { addVariableToGTMDataLayer } = useGTM();

  const getRaEventsProperties = useCallback(
    chapter => {
      return {
        themeName: subject?.theme.name,
        themeId: subject?.theme.id,
        subjectName: subject?.name,
        subjectId: subject?.id,
        chapterName: chapter?.name,
        chapterId: chapter?.id,
        topicName: null,
        topicId: null,
        guideType: 'Assunto Default',
        guideId: null,
        contentType: 'Resuminho',
        contentId: chapter.id,
        contentFormat: 'Texto',
        hasVideo: false,
        blockedContent: false,
      };
    },
    [subject]
  );

  const mktCloudTrackingInfo = useMemo(() => {
    return {
      subjectName: subject?.name,
      subjectId: subject?.id,
      themeName: subject?.theme.name,
      themeId: subject?.theme.id,
      blockedContent: false,
      platform: 'Web',
    };
  }, [subject]);

  const trackContentToGTM = useCallback(() => {
    if (subject) {
      const { theme, name } = subject;
      addVariableToGTMDataLayer({
        contentMateria: theme.name,
        contentAssunto: name,
        contentType: 'Resumão',
      });
    }
  }, [addVariableToGTMDataLayer, subject]);

  const trackViewSummary = useCallback(
    chapterToTrack => {
      if (lastChapterIdTracked === chapterToTrack.id) return;

      const raEventsProperties = getRaEventsProperties(chapterToTrack);

      trackMktCloudActivationEvents('ON_VIEW_SUMMARY', mktCloudTrackingInfo);
      trackEventToRaEvents('/view-workspace-content', raEventsProperties);
      setLastChapterIdTracked(chapterToTrack.id);
    },
    [
      mktCloudTrackingInfo,
      trackMktCloudActivationEvents,
      lastChapterIdTracked,
      trackEventToRaEvents,
      getRaEventsProperties,
    ]
  );

  const trackViewSummaries = useCallback(() => {
    trackEvent('Resumão: Visualizou todos os resumões', {
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      ...getUTMsByLocation(window.location),
    });
  }, [getUTMsByLocation, trackEvent]);

  return { trackViewSummary, trackContentToGTM, trackViewSummaries };
}
