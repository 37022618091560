import { useCallback, useMemo } from 'react';

import {
  useWorkspace,
  useWorkspacePermission,
} from 'modules/workspace/hooks/main';
import {
  useWorkspaceExercise,
  useWorkspaceVideo,
} from 'modules/workspace/hooks/shared';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import { useUserContext } from 'providers/UserProvider';
import {
  CONTENT_FEEDBACK_FEELINGS_THEORY_TEXT,
  CONTENT_FEEDBACK_FEELINGS_THEORY_VIDEO,
  CONTENT_FEEDBACK_FEELINGS_EXERCISE_TEXT,
  CONTENT_FEEDBACK_FEELINGS_EXERCISE_VIDEO,
} from 'modules/workspace/consts/content-feedback-grades';

export default function useContentFeedback() {
  const {
    isTheoryWorkspace,
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    isExtraTimePageWorkspace,
    isExtraExerciseWorkspace,
  } = useWorkspace();
  const { currentContentAccess } = useWorkspacePermission();
  const { getExerciseIndexFromExercises } = useWorkspaceExercise();
  const {
    currentContent,
    currentSubject,
    currentChapter,
    currentTopic,
    currentFixationExercises,
    currentContentFormat,
  } = useWorkspaceContentContext();
  const { user } = useUserContext();
  const { getCurrentContentVideoIds, getVideosToRender } = useWorkspaceVideo();

  const showContentFeedback = useCallback(() => {
    return (
      user &&
      (isTheoryWorkspace() ||
        isFixationExerciseWorkspace() ||
        isUniversityExerciseWorkspace() ||
        isExtraTimePageWorkspace() ||
        isExtraExerciseWorkspace())
    );
  }, [
    user,
    isTheoryWorkspace,
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    isExtraTimePageWorkspace,
    isExtraExerciseWorkspace,
  ]);

  const feedbackContentType = useMemo(() => {
    if (isTheoryWorkspace()) return 'teoria';
    if (isFixationExerciseWorkspace()) return 'exercicio';
    if (isUniversityExerciseWorkspace()) return 'exercicio-lista';
    if (isExtraTimePageWorkspace()) return 'exercicio-lista';
    if (isExtraExerciseWorkspace()) return 'exercicio-lista';
  }, [
    isTheoryWorkspace,
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    isExtraTimePageWorkspace,
    isExtraExerciseWorkspace,
  ]);

  const feedbackContentId = useMemo(() => {
    return parseInt(currentContent?.id);
  }, [currentContent]);

  const feedbackContentName = useMemo(() => {
    if (!currentContent) return '';

    const subjectName = `(${currentSubject.id}) ${currentSubject.name}`;
    const chapterName = currentChapter
      ? `(${currentChapter.id}) ${currentChapter.name}`
      : '';
    const topicName = `(${currentTopic?.id}) ${currentTopic?.name}`;
    if (isTheoryWorkspace())
      return `Assunto: ${subjectName} \nCapítulo: ${chapterName} \nTópico: ${topicName}`;

    const exerciseIndex = getExerciseIndexFromExercises(
      currentContent,
      currentFixationExercises
    );
    const exerciseName = `(${currentContent.id}) - E${exerciseIndex + 1}`;
    if (isExtraTimePageWorkspace())
      return `Assunto: ${subjectName} \nCapítulo: ${chapterName} \nExercício: ${exerciseName}`;
    return `Assunto: ${subjectName} \nCapítulo: ${chapterName} \nTópico: ${topicName} \nExercício: ${exerciseName}`;
  }, [
    isTheoryWorkspace,
    currentSubject,
    currentChapter,
    currentTopic,
    currentContent,
    currentFixationExercises,
    getExerciseIndexFromExercises,
    isExtraTimePageWorkspace,
  ]);

  const feedbackContentFormat = useMemo(() => {
    return currentContentFormat;
  }, [currentContentFormat]);

  const feedbackMediaProviderId = useMemo(() => {
    if (currentContentFormat === 'video') {
      const videos = getVideosToRender(
        currentContent?.videos,
        currentContentAccess
      );
      return getCurrentContentVideoIds(videos);
    }
  }, [
    currentContentFormat,
    currentContent,
    currentContentAccess,
    getCurrentContentVideoIds,
    getVideosToRender,
  ]);

  const feedbackFeelings = useMemo(() => {
    if (isTheoryWorkspace() && currentContentFormat === 'text')
      return CONTENT_FEEDBACK_FEELINGS_THEORY_TEXT;
    if (isTheoryWorkspace() && currentContentFormat === 'video')
      return CONTENT_FEEDBACK_FEELINGS_THEORY_VIDEO;

    if (isFixationExerciseWorkspace() && currentContentFormat === 'text')
      return CONTENT_FEEDBACK_FEELINGS_EXERCISE_TEXT;
    if (isFixationExerciseWorkspace() && currentContentFormat === 'video')
      return CONTENT_FEEDBACK_FEELINGS_EXERCISE_VIDEO;

    if (isUniversityExerciseWorkspace() && currentContentFormat === 'text')
      return CONTENT_FEEDBACK_FEELINGS_EXERCISE_TEXT;
    if (isUniversityExerciseWorkspace() && currentContentFormat === 'video')
      return CONTENT_FEEDBACK_FEELINGS_EXERCISE_VIDEO;
    if (isExtraTimePageWorkspace() && currentContentFormat === 'text')
      return CONTENT_FEEDBACK_FEELINGS_EXERCISE_TEXT;
    if (isExtraTimePageWorkspace() && currentContentFormat === 'video')
      return CONTENT_FEEDBACK_FEELINGS_EXERCISE_VIDEO;
    if (isExtraExerciseWorkspace() && currentContentFormat === 'text')
      return CONTENT_FEEDBACK_FEELINGS_EXERCISE_TEXT;
    if (isExtraExerciseWorkspace() && currentContentFormat === 'video')
      return CONTENT_FEEDBACK_FEELINGS_EXERCISE_VIDEO;
  }, [
    isTheoryWorkspace,
    isFixationExerciseWorkspace,
    currentContentFormat,
    isUniversityExerciseWorkspace,
    isExtraTimePageWorkspace,
    isExtraExerciseWorkspace,
  ]);

  const feedbackHeaderText = useMemo(() => {
    if (currentContentFormat === 'text') return 'E aí, este texto te ajudou?';

    return 'E aí, este video te ajudou?';
  }, [currentContentFormat]);

  return {
    showContentFeedback,
    getExerciseIndexFromExercises,
    feedbackContentType,
    feedbackContentId,
    feedbackContentName,
    feedbackContentFormat,
    feedbackMediaProviderId,
    feedbackFeelings,
    feedbackHeaderText,
  };
}
