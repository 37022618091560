import React, { useCallback, useEffect, useMemo } from 'react';

import { styled, media } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker, useDate } from 'modules/shared/hooks';
import HeartBreakCancelDialog from 'modules/profile/dialogs/cancellation/HeartBreakCancelDialog';
import { useFreezeSubscriptionCampaign } from 'modules/campaigns/freeze-subscription/hooks';

export default function FreezeSubscriptionCancelation({ subscription }) {
  const { setDialog, unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const { getHumanReadableDateFromTimestamp } = useDate();
  const { getFreezedUntilDateInTimestamp } = useFreezeSubscriptionCampaign();

  useEffect(() => {
    trackEvent('Congelamento Assinatura: PAUSE Visualizou');
  }, [trackEvent]);

  const formattedFreezedUntil = useMemo(() => {
    return getHumanReadableDateFromTimestamp(getFreezedUntilDateInTimestamp());
  }, [getFreezedUntilDateInTimestamp, getHumanReadableDateFromTimestamp]);

  const handleFreezeSubscriptionPress = useCallback(() => {
    trackEvent('Congelamento Assinatura: PAUSE Clicou Manter');
    unsetDialog();
  }, [unsetDialog, trackEvent]);

  const handleCancellationPress = useCallback(() => {
    trackEvent('Congelamento Assinatura: PAUSE Clicou Cancelar');
    setDialog(<HeartBreakCancelDialog subscription={subscription} />);
  }, [setDialog, trackEvent, subscription]);

  return (
    <Container>
      <Content>
        <CancelStrip>Assinatura Pausada</CancelStrip>
        <Title>
          Deseja cancelar sua assinatura e perder seu desconto exclusivo?
        </Title>
        <Paragraph>
          Atualmente, você já está com sua assinatura pausada até o dia{' '}
          {formattedFreezedUntil}, e você não será mais cobrado até essa data.
          <br />
          <br />
          Além disso, você ainda está com um desconto exclusivo no seu plano.
          Caso opte por cancelar sua assinatura, esse desconto será perdido.
        </Paragraph>

        <ButtonsContainer>
          <button onClick={handleCancellationPress} type="button">
            Perder desconto para sempre
          </button>

          <button onClick={handleFreezeSubscriptionPress} className="positive">
            Manter assinatura pausada
          </button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-top: 10px solid #8dadec;
  text-align: left;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const CancelStrip = styled.div`
  display: inline-block;
  padding: 3px 10px;
  position: relative;
  left: -48px;
  margin-bottom: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #8dadec;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  @media ${media.mobile} {
    left: -24px;
  }
`;

const Title = styled.h1`
  margin-bottom: 15px;
  font-size: 1.6em;
  font-weight: bold;
  color: #8dadec;
`;

const Paragraph = styled.p`
  font-size: 1em;
  margin: 20px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 500px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 60px;

  button {
    width: 45%;
    font-size: 0.95em;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 20px 20px 0;
    height: 50px;
    border: 2px solid #989898;
    color: #989898;
    text-transform: uppercase;
    font-weight: bold;

    &.positive {
      border-color: #8dadec;
      color: #8dadec;
    }
  }

  @media ${media.mobile} {
    flex-direction: column-reverse;

    button {
      width: 95%;
    }
  }
`;
