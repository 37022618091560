import { useRAQueryImmutable } from 'modules/shared/hooks/requests';
import { useUserContext } from 'providers/UserProvider';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { styled } from 'styles';

function normalizeTerm(word) {
  return (
    word
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '')
      ?.toLowerCase() || ''
  );
}

export default function CourseSelect({ setSelectedCourse }) {
  const { user } = useUserContext();
  const [courses, setCourses] = useState([]);

  const { data, loading } = useRAQueryImmutable(`/courses`);

  const [selectedCourseLocal, setSelectedCourseLocal] = useState(null);
  const [coursesFiltered, setCoursesFiltered] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!data) return;

    const enrichedCourses = data.map(course => {
      return {
        id: course?.id,
        label: course?.name,
        name: course?.name,
        value: course.id,
      };
    });

    setCourses(enrichedCourses);
  }, [data, setCourses]);

  useEffect(() => {
    // set selectedCourseLocal to the user course
    if (!user?.course) return;

    const course = courses.find(c => c.id === user?.course?.id);

    setSelectedCourseLocal(course);
    setSelectedCourse(course);
  }, [courses, user?.course, setSelectedCourseLocal, setSelectedCourse]);

  const onChangeCourse = useCallback(
    course => {
      if (!course) {
        setSelectedCourseLocal(null);
        setSelectedCourse(null);
        return;
      }

      const clickedCourse = courses.find(
        c => parseInt(c.id) === parseInt(course.id)
      );

      if (!clickedCourse) return;

      setSelectedCourseLocal(clickedCourse);
      setSelectedCourse(clickedCourse);
    },
    [courses, setSelectedCourseLocal, setSelectedCourse]
  );

  const onInputChange = useCallback(
    input => {
      setInputValue(input);
      input = input.trim();

      if (!input) {
        setCoursesFiltered([]);
        return;
      }

      const otherOption = courses.find(c => c.name === 'Outro');
      let coursesFiltered = courses.filter(c => c.name !== 'Outro');
      input = normalizeTerm(input);

      coursesFiltered = coursesFiltered.filter(course =>
        input
          .split(' ')
          .every(word => normalizeTerm(course.name).includes(word))
      );

      coursesFiltered.sort((a, b) => {
        a = normalizeTerm(a.name);
        b = normalizeTerm(b.name);

        let aStartsWith = 0;
        let aStartsWithIndex = 0;
        input.split(' ').forEach(word => {
          aStartsWith += a.split(' ').filter((c, index) => {
            const starts = c.startsWith(word);
            if (starts) aStartsWithIndex -= index;
            return starts;
          }).length;
        });

        let bStartsWith = 0;
        let bStartsWithIndex = 0;
        input.split(' ').forEach(word => {
          bStartsWith += b.split(' ').filter((c, index) => {
            const starts = c.startsWith(word);
            if (starts) bStartsWithIndex -= index;
            return starts;
          }).length;
        });

        return aStartsWith === bStartsWith
          ? bStartsWithIndex === aStartsWithIndex
            ? a.localeCompare(b)
            : bStartsWithIndex - aStartsWithIndex
          : bStartsWith - aStartsWith;
      });

      coursesFiltered = [
        ...coursesFiltered.slice(0, 5),
        otherOption,
        ...coursesFiltered.slice(5, 100),
      ];

      setCoursesFiltered(coursesFiltered);
    },
    [courses]
  );

  return (
    <SelectorContainer data-cy="course-select">
      <Select
        placeholder={loading ? 'Carregando cursos' : 'Curso'}
        options={coursesFiltered}
        isClearable={true}
        onChange={e => {
          setSelectedCourseLocal(e);
          onChangeCourse(e);
        }}
        filterOption={() => true}
        menuIsOpen={inputValue.length >= 1}
        onInputChange={onInputChange}
        value={selectedCourseLocal}
        components={selectCustomComponents}
        styles={selectCustomStyles}
      />
    </SelectorContainer>
  );
}

const selectCustomStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      border: 'none',
      borderBottom: '1px solid #e6e6e6',
      justifyContent: 'flex-start',
      textAlign: 'left',
      boxShadow: state.isFocused ? 0 : 0,
    };
  },
  option: provided => {
    return {
      ...provided,
      textAlign: 'left',
      '&:hover': {
        backgroundColor: '#eee',
      },
      '&:selected': {
        backgroundColor: '#eee',
      },
      backgroundColor: 'transparent',
      color: 'black',
    };
  },
};

const selectCustomComponents = {
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null,
};

const SelectorContainer = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;
