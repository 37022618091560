import { styled } from 'styles';

export const Button = styled.button`
  width: 100%;
  border: 1px solid #f6ac3a;
  border-radius: 10px;

  color: white;
  background-color: #f6ac3a;
  margin-top: 20px;
  padding: 15px;

  font-weight: bold;
  font-size: 1.1em;

  cursor: ${({ saving }) => (saving ? 'not-allowed' : 'pointer')};
`;
