import { useHistory } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useTracker } from 'modules/shared/hooks';
import { media } from 'styles';

function useSolvedBooksSharedFunctions() {
  const history = useHistory();
  const { trackEvent } = useTracker();

  const viewEventName = useMemo(
    () => 'Solucionário: Visualizou banner de livros resolvidos',
    []
  );
  const clickEventName = useMemo(
    () => 'Solucionário: Clicou banner de livros resolvidos',
    []
  );
  const eventProperties = useMemo(() => {
    return {
      Onde: media.isMobile() ? 'Mobile' : 'Desktop',
    };
  }, []);

  const link = useMemo(() => 'livros-resolvidos', []);

  const viewBannerEvent = useCallback(() => {
    trackEvent(viewEventName, eventProperties);
  }, [trackEvent, viewEventName, eventProperties]);

  const onClickBanner = useCallback(() => {
    trackEvent(clickEventName, eventProperties);
    history.push(link);
  }, [trackEvent, clickEventName, eventProperties, link, history]);

  return {
    viewBannerEvent,
    onClickBanner,
  };
}

export { useSolvedBooksSharedFunctions };
