import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';
import React, { useCallback, useMemo, useState } from 'react';
import { FaRegListAlt } from 'react-icons/fa';
import styled from 'styled-components';
import BookListExamToggle from './BookListExamToggle';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { useDialogContext } from 'providers/DialogProvider';
import { MdUploadFile } from 'react-icons/md';

const BookListProfessorToggle = ({ professorName, lists }) => {
  const [open, setOpen] = useState(false);
  const { setDialog } = useDialogContext();

  const correctOrder = [
    'Primeira prova',
    'Segunda prova',
    'Terceira prova',
    'Outras provas',
  ];

  const calculateToggleHeight = useCallback(() => {
    const height = lists.length * 45 + 40 + 4 * 45;

    return `${height}px`;
  }, [lists.length]);

  const listsGroupByExam = useMemo(() => {
    if (!lists) return;
    const groupedListByExam = lists.reduce((acc, list) => {
      if (!acc[list.exam]) {
        acc[list.exam] = [];
      }
      acc[list.exam].push(list);
      return acc;
    }, {});

    return groupedListByExam;
  }, [lists]);

  const getFormattedProfessorName = useCallback(name => {
    if (!name) return 'Professor Desconhecido';
    if (name.length < 40) return name;

    const [firstName, secondName] = name.split(' ');

    const isBiggerThan50Caractheres = `${firstName} ${secondName}`.length > 50;

    return isBiggerThan50Caractheres || !secondName
      ? `${firstName}`
      : `${firstName} ${secondName}`;
  }, []);

  const onSendList = useCallback(() => {
    const utms = {
      utmSource: 'site_upload',
      utmMedium: 'botao_enviar_lista_dentro_toggle',
      utmContent: 'pag_disciplina',
    };

    setDialog(<UploadBookListDialog origin="Pagina Disciplina" utms={utms} />);
  }, [setDialog]);

  const shouldShowSendListButton = useMemo(() => {
    return Object.keys(listsGroupByExam).length === 0;
  }, [listsGroupByExam]);

  return (
    <ProfessorToggle>
      <ProfessorName onClick={() => setOpen(!open)}>
        <div>
          <FaRegListAlt
            style={{
              fontSize: '1.1em',
              strokeWidth: '5',
              stroke: 'black',
              marginTop: '-10px',
            }}
          />
        </div>
        <div>
          <Title>{`Listas ${getFormattedProfessorName(professorName)}`}</Title>
          <SolvedLists>({lists?.length} listas resolvidas)</SolvedLists>
        </div>
        <AngleIcon icon={open ? faAngleUp : faAngleDown} />
      </ProfessorName>
      <ToggleableComponent
        open={open}
        animationTime="0.2s"
        height={calculateToggleHeight()}
      >
        <Lists>
          {Object.keys(listsGroupByExam)
            .sort((a, b) => correctOrder.indexOf(a) - correctOrder.indexOf(b))
            .map((examName, i) => (
              <BookListExamToggle
                key={i}
                examName={examName}
                lists={listsGroupByExam[examName]}
              />
            ))}
          {shouldShowSendListButton && (
            <SendListButton onClick={onSendList}>
              <MdUploadFile />
              <p>Enviar sua lista</p>
            </SendListButton>
          )}
        </Lists>
      </ToggleableComponent>
    </ProfessorToggle>
  );
};

const ProfessorToggle = styled.div`
  padding: 8px 0 8px 0;
  width: 90%;
  margin: auto;
`;

const AngleIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.3em;
  opacity: 0.7;
  color: #2e3030;
`;

const ProfessorName = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #8c8c8c;
`;

const Title = styled.p`
  margin-left: 6px;
  padding-right: 20px;
  color: #515151;
  font-weight: bold;
  margin-top: 10px;
  font-size: 0.9rem;
`;

const SolvedLists = styled.p`
  margin-left: 6px;
  margin-top: 3px;
  font-size: 0.9rem;
`;

const Lists = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 7px 10px;
  border-radius: 10px;
`;

const SendListButton = styled.button`
  padding: 12px 10px;
  background-color: rgba(112, 192, 199, 0.13);
  margin-bottom: 7px;
  border-radius: 5px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #515151;

  p {
    margin-left: 10px;
  }

  &:hover {
    background-color: rgba(112, 192, 199, 0.2);
  }
`;

export default BookListProfessorToggle;
