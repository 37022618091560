import React from 'react';

import { styled } from 'styles';
import { Notifications } from 'modules/shared/components/header';

export default function NotificationList({ isOpen }) {
  return (
    <Container isOpen={isOpen}>
      <List>{isOpen && <Notifications />}</List>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 53px;
  left: -150px;
  z-index: 10;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
`;

const List = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
`;
