import React, { useCallback, useEffect } from 'react';

import { styled, media, colors } from 'styles';
import { useTracker } from 'modules/shared/hooks';

export default function CreditCardTrialCpfDialog() {
  const { trackEvent } = useTracker();

  useEffect(() => {
    trackEvent('Trial com Cartão: Visualizou dialog erro CPF');
  }, [trackEvent]);

  const onClickPlan = useCallback(() => {
    trackEvent('Trial com Cartão: Clicou planos dialog erro CPF');
  }, [trackEvent]);

  return (
    <Container data-cy="credit-card-dialog">
      <Content>
        <Title>Esse CPF não é válido :(</Title>

        <Description>
          Você experimentou o Responde Aí no passado, e por isso não pode ativar
          um novo teste gratuito
        </Description>

        <Description big={true}>
          Para acessar ao nosso conteúdo completo, escolha o melhor plano para
          você
        </Description>

        <ButtonsContainer>
          <Button
            className="highlight"
            href={`${process.env.REACT_APP_RA_WEB_SSR_URL}/planos`}
            target="_blank"
            onClick={onClickPlan}
          >
            Ver Planos
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  padding: 30px 15px 20px;
  background-color: #fff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
`;

const Title = styled.h1`
  font-size: 1.6em;
  font-weight: bold;
  color: #000;
  margin-bottom: 25px;
  color: 'red';

  @media ${media.mobile} {
    font-size: 1.7em;
  }
`;

const Description = styled.p`
  width: 80%;
  margin: 10px 0;

  ${({ big }) => (big ? 'font-size: 1.3em;' : '')}
`;

const ButtonsContainer = styled.div`
  width: 400px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Button = styled.a`
  width: 100%;
  border: none;
  border: 2px solid #000;
  font-weight: bold;
  font-size: 1.5em;
  margin: 5px 0;
  padding: 10px 0;
  border-radius: 5px;

  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: #fff;

  @media ${media.mobile} {
    font-size: 1.4em;
  }
`;
