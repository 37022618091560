import React, { useMemo } from 'react';

import { colors } from 'styles';
import { useWorkspaceListExerciseNavigation } from 'modules/workspace/hooks/list-exercise';
import { WorkspaceNavigationButtons } from 'modules/workspace/components/content/shared';

export default function ListExerciseWorkspaceNavigationButtons() {
  const { previusContent, nextContent } = useWorkspaceListExerciseNavigation();

  const previous = useMemo(() => {
    if (!previusContent) return;

    return {
      link: previusContent.link,
      color: colors.listExercise,
    };
  }, [previusContent]);

  const next = useMemo(() => {
    if (!nextContent) return;

    return {
      link: nextContent.link,
      color: colors.listExercise,
      text: 'Próximo exercício',
    };
  }, [nextContent]);

  return <WorkspaceNavigationButtons previous={previous} next={next} />;
}
