import React from 'react';
import { styled } from 'styles';
import { Auloes, Livro, Conteudo } from 'images/campaigns/campaign-lecture';

const RaFeatures = () => {
  return (
    <Features id="content">
      <Feature href="/livros-resolvidos" target="_blank">
        <Img src={Livro} />
        <FeatureDescription>
          Passo a passo das questões resolvidas dos principais livros da sua
          faculdade.
        </FeatureDescription>
      </Feature>
      <Feature href="/materias" target="_blank">
        <Img src={Conteudo} />
        <FeatureDescription>
          Material feito sem enrolação e de uma maneira fácil de você entender.
        </FeatureDescription>
      </Feature>
      <Feature href="/auloes" target="_blank">
        <Img src={Auloes} />
        <FeatureDescription>
          Aulões Salva Vidas para você sair do 0 pra aprovação em apenas 2
          horas.
        </FeatureDescription>
      </Feature>
    </Features>
  );
};

const Features = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 25px 0 110px 0;
  flex-wrap: wrap;
`;

const Feature = styled.a`
  display: flex;
  flex-direction: column;
  width: 270px;
  margin: 20px 10px;
  cursor: pointer;
`;

const Img = styled.img`
  height: 85px;
  width: max-content;
  margin: 0 auto 20px auto;
`;

const FeatureDescription = styled.div`
  text-align: center;
  font-size: 0.95em;
  font-weight: bold;
`;

export default RaFeatures;
