import React from 'react';

import { styled, media } from 'styles';
import CardTitle from './CardTitle';

export default function UserAppleSubscription() {
  return (
    <Container>
      <CardTitle title="Assinatura" upper />

      <Content>
        <p>Você assinou seu plano através do aplicativo para iPhone ou iPad.</p>

        <p>
          Para cancelar ou gerenciar seu plano, acesse diretamente a App Store
          pelo seu aparelho.
        </p>

        <p>Qualquer dúvida, entre em contato pelo:</p>
        <p>
          <a href="mailto:faleai@respondeai.com.br">faleai@respondeai.com.br</a>
        </p>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 90%;

  max-width: 720px;
  margin-bottom: 50px;

  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 15px -2px rgba(51, 51, 51, 0.5);
`;

const Content = styled.div`
  padding: 0 40px 20px 40px;

  p {
    margin-bottom: 20px;

    a {
      color: #4040f2;
    }
  }

  @media ${media.mobile} {
    padding: 0 10px 20px 10px;
  }
`;
