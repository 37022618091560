import React from 'react';
import { styled } from 'styles';
import LoginButton from './LoginButton';
import {
  faFacebookF,
  faGoogle,
  faApple,
} from '@fortawesome/free-brands-svg-icons';
import {
  getAppleAuthUrl,
  getFbAuthUrl,
  getGoogleAuthUrl,
} from 'modules/auth/AuthUrl';

export default function LoginContainer() {
  const linkFacebook = getFbAuthUrl();
  const linkGoogle = getGoogleAuthUrl();
  const linkApple = getAppleAuthUrl();

  return (
    <Container>
      <LoginButton
        text="Acesse com Facebook"
        icon={faFacebookF}
        link={linkFacebook}
      />
      <LoginButton text="Acesse com Google" icon={faGoogle} link={linkGoogle} />
      <LoginButton text="Acesse com a Apple" icon={faApple} link={linkApple} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  .fb {
    background: #407bff;
    box-shadow: 0px 2.81672px 2.81672px rgba(0, 0, 0, 0.25);
    border-radius: 10.5627px;
  }
`;
