import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { styled } from 'styles';

export default function FeelingElement({
  text,
  icon,
  AddOrRemoveFeeling,
  feelings,
}) {
  const isSelected = feelings.includes(text);

  return (
    <FeelingElementContainer
      onClick={() => AddOrRemoveFeeling(text)}
      isSelected={isSelected}
    >
      {isSelected && <CheckIcon icon={faCheckCircle} />}
      <img alt="" src={icon} />
      <div className="feeling-text">{text}</div>
    </FeelingElementContainer>
  );
}

const FeelingElementContainer = styled.div`
  display: flex;
  padding: 5px 20px;
  border-radius: 25px;
  border: #bcbcbc solid 1px;
  margin: 10px;
  min-width: 250px;
  position: relative;
  cursor: pointer;
  ${({ isSelected }) => isSelected || 'filter: grayscale(100%);'} :hover {
    filter: none;
  }

  .check-icon {
    right: 0;
    transform: translate(-50%, -50%);
  }

  div {
    margin: auto;
  }
`;

const CheckIcon = styled(FontAwesomeIcon)`
  filter: none;
  color: green;
  position: absolute;
  right: ${({ right }) => right || '0'}%;
  top: 0;
`;
