import React, { useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { styled, colors, media } from 'styles';
import {
  IconContainer,
  PathLine,
} from 'modules/workspace/components/side-menu/shared';
import Tooltip from './Tooltip';
import { useUserContext } from 'providers/UserProvider';
import { useWorkspaceFlags } from 'modules/workspace/hooks/shared';

export default function ChapterTitle({ chapter, open, title }) {
  const {
    getChapterFlag,
    saveChapterFlag,
    removeChapterFlag,
  } = useWorkspaceFlags();
  const { user } = useUserContext();
  const { guideId } = useParams();

  const isLogged = useMemo(() => {
    return !!user;
  }, [user]);

  const defaultClassName = useMemo(() => {
    const openClass = open ? 'open' : '';
    return openClass;
  }, [open]);

  const isFlagged = useMemo(() => {
    return !!getChapterFlag(chapter.id, guideId);
  }, [getChapterFlag, chapter, guideId]);

  const onChapterIconClick = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      if (!isLogged) return;

      if (isFlagged) {
        removeChapterFlag(chapter.id, guideId);
        return;
      }

      saveChapterFlag(chapter.id, guideId);
    },
    [isLogged, isFlagged, chapter, saveChapterFlag, removeChapterFlag, guideId]
  );

  const pathLineClass = useMemo(() => {
    const firstClass = 'first';
    const flaggedClass = isFlagged ? 'flagged' : '';
    return `${defaultClassName} ${firstClass} ${flaggedClass}`;
  }, [isFlagged, defaultClassName]);

  return (
    <>
      <IconContainer className={defaultClassName} onClick={onChapterIconClick}>
        <CheckIcon icon={faCheck} className={isFlagged ? 'flagged' : ''} />
      </IconContainer>
      {title}
      {isLogged && <PathLine className={pathLineClass} />}
      {media.isDesktop() && <Tooltip className="tooltip" chapter={chapter} />}
    </>
  );
}

const CheckIcon = styled(FontAwesomeIcon)`
  color: #858585;
  font-size: 16px;

  &:hover {
    color: rgba(126, 212, 138, 0.5);
  }

  &.flagged {
    color: ${colors.check};
  }
`;
