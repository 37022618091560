import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ErrorReportFloatButton } from 'modules/feedback/components';
import useLectureTracker from 'modules/lectures/hooks/useLectureTracker';
import { useLectureContext } from '../../providers/LectureProvider';
import { useContentLazyQueryImmutable } from 'modules/shared/hooks/requests';
import { styled, media } from 'styles';
import TopicsList from './TopicsList';

export default function PracticeContainer() {
  const { guideId } = useParams();
  const { trackErrorReport } = useLectureTracker();

  const {
    currentModuleIndex: moduleIndex,
    currentModule: module,
    lectureData: lecture,
    setErrorReportInputIsOpen,
  } = useLectureContext();

  const [getGuide, guideResponse] = useContentLazyQueryImmutable(
    `/guides/${guideId}/with-guide-topics`
  );

  useEffect(() => {
    if (guideId) getGuide();
  }, [guideId, getGuide]);

  const shouldShowUncoveredTopics = useCallback(() => {
    return module.unCoveredTopics.length > 0;
  }, [module]);

  const getErrorReportLocation = useCallback(() => {
    const { id, name, theme } = lecture.subject;
    return `Matéria: ${theme.name} - ${theme.id} Assunto: ${name} - ${id} Url do conteúdo: ${location.href}`;
  }, [lecture]);

  if (!module) return null;
  const guide = guideResponse?.data;

  return (
    <Container>
      <Header>
        <Title>
          <ModuleNumber>{moduleIndex + 1}</ModuleNumber>
          <ModuleName>{module.name}</ModuleName>
        </Title>
        <ErrorReportFloatButton
          data={{
            type: 'Aulão Salva-Vidas',
            location: getErrorReportLocation(),
          }}
          trackFeedbackCallback={trackErrorReport}
          setErrorReportInputIsOpen={setErrorReportInputIsOpen}
        />
      </Header>
      <CoveredTopics>
        <Subtitle>Tópicos abordados no módulo {moduleIndex + 1}</Subtitle>
        <TopicsList topics={module.coveredTopics} guide={guide} />
      </CoveredTopics>
      {shouldShowUncoveredTopics() && (
        <UnCoveredTopics>
          <Subtitle>Tópicos mais avançados</Subtitle>
          <TopicsList topics={module.unCoveredTopics} guide={guide} />
        </UnCoveredTopics>
      )}
    </Container>
  );
}

const Container = styled.div`
  min-height: 300px;
  width: 100%;
  padding: 30px;

  @media ${media.mobile} {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 1.2em;
  color: #f9ac3e;
  display: flex;
`;

const ModuleNumber = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;
  background: #f9ac3e;
  margin: 0 10px 0 -10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${media.mobile} {
    width: 28px;
    height: 28px;
    font-size: 1em;
    margin: 0 10px 0 0;
    line-height: 27px;
  }
`;

const ModuleName = styled.div`
  font-size: 1.2em;
  color: #f9ac3e;
  line-height: 38px;

  @media ${media.mobile} {
    font-size: 1em;
    line-height: 20px;
  }
`;

const CoveredTopics = styled.div``;
const UnCoveredTopics = styled.div``;

const Subtitle = styled.div`
  font-size: 0.7em;
  color: #333;
  text-transform: uppercase;
  margin: 30px 0 10px 0;

  @media ${media.mobile} {
    text-align: center;
    margin: 30px 0 20px 0;
  }
`;
