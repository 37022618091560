import React, { useEffect, useCallback, useMemo, useState } from 'react';

import { styled, media, colors } from 'styles';
import { useTracker, useDate, useUTM } from 'modules/shared/hooks';
import { useToast } from 'modules/shared/hooks';
import { useCurrency } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import { useReactivateSubscription } from '../hooks';

export default function ReactivateSubscriptionDialog({
  subscription,
  utmSource = null,
}) {
  const { trackEvent } = useTracker();
  const { getHumanReadableDateFromTimestamp } = useDate();
  const { getUTMsByLocation } = useUTM();
  const { formatCurrency } = useCurrency();
  const { reactivateSubscription } = useReactivateSubscription();
  const { unsetDialog } = useDialogContext();
  const { setToastAfterReload, errorToast } = useToast();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const props = getUTMsByLocation(window.location);
    if (utmSource) props['MKT: UTM Source'] = utmSource;

    trackEvent('Reativar Renovacao: Visualizou Dialog', props);
  }, [trackEvent, getUTMsByLocation, utmSource]);

  const formattedPrice = useMemo(() => {
    const monthlyPrice =
      subscription.order.planPricing.price /
      (subscription.order.installments || 1);

    return subscription.order.installments > 1
      ? `${subscription.order.installments}x de R$ ${formatCurrency(
          monthlyPrice
        )}`
      : `R$ ${formatCurrency(monthlyPrice)}`;
  }, [
    formatCurrency,
    subscription.order.installments,
    subscription.order.planPricing.price,
  ]);

  const submitReactivating = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    const success = await reactivateSubscription(subscription);
    setLoading(false);

    if (success) {
      setToastAfterReload(
        'Renovacao automática reativada com sucesso',
        'success'
      );
      window.location.reload();
    } else
      errorToast(
        'Erro ao reativar renovação automática. Por favor, entre em contato com o atendimento.'
      );
  }, [
    loading,
    reactivateSubscription,
    subscription,
    errorToast,
    setToastAfterReload,
  ]);

  return (
    <Container onClick={unsetDialog}>
      <Content onClick={e => e.stopPropagation()}>
        <Strip>Reativar renovação</Strip>

        <Confirmation>Reative sua assinatura agora!</Confirmation>

        <ConfirmationInfo>
          Sua recorrência automática está desativada e seu acesso expira em{' '}
          {getHumanReadableDateFromTimestamp(subscription.expiresIn)}. Reative a
          recorrência agora e, em{' '}
          {getHumanReadableDateFromTimestamp(subscription.expiresIn)}, sua
          assinatura será renovada com o mesmo valor que você paga hoje (
          {formattedPrice}).
        </ConfirmationInfo>

        <ButtonsContainer>
          <button onClick={submitReactivating} className="enable">
            {loading ? 'Reativando renovação' : 'Reativar renovação automática'}
          </button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-top: 10px solid ${colors.mainOrange};
  text-align: left;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const Strip = styled.div`
  display: inline-block;
  padding: 3px 10px;
  position: relative;
  left: -48px;
  margin-bottom: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${colors.mainOrange};
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  @media ${media.mobile} {
    left: -24px;
  }
`;

const Confirmation = styled.h1`
  font-weight: bold;
  color: ${colors.mainOrange};
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 30px;
`;

const ConfirmationInfo = styled.p`
  width: 100%;
  line-height: 1.2;
  margin: 25px 0;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;

  button {
    flex: 1;
    margin: 0 10px;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    border: 2px solid #929292;
    color: #929292;
    text-transform: uppercase;

    &.enable {
      border-color: ${colors.mainOrange};
      color: ${colors.mainOrange};
    }
  }

  @media ${media.mobile} {
    flex-direction: column-reverse;

    button {
      width: 100%;
      margin: 5px 0;
      padding: 10px 0;
    }
  }
`;
