import React, { useCallback, useState } from 'react';
import { media, styled } from 'styles';
import { GoPencil } from 'react-icons/go';
import { Loading, Error } from 'modules/shared/components/generic';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { useTracker } from 'modules/shared/hooks';
import { ErrorContainer } from './styles';
import { useShouldShowUploadFileDialog } from 'modules/main/hooks/useShouldShowUploadFileDialog';

export default function ThemesMenu({
  selectedTheme,
  isThemesMenuOpen,
  setIsThemesMenuOpen,
  setSelectedTheme,
  setDiscipline,
}) {
  const { trackEvent } = useTracker();
  const [errorMessage, setErrorMessage] = useState('');
  const { isElegibleUniversitiesTheme } = useShouldShowUploadFileDialog();

  const { data: themes, loading, error } = useContentQueryImmutable(
    `/themes/with-subjects`
  );

  const handleThemeClick = useCallback(
    theme => {
      if (theme.id === selectedTheme.id) {
        setIsThemesMenuOpen(false);
        return;
      }

      if (isElegibleUniversitiesTheme(theme?.name)) {
        setSelectedTheme(theme);
        setIsThemesMenuOpen(false);
        setDiscipline(null);
        return;
      }

      trackEvent(
        'Listas: Dialog das Disciplinas Selecionou Matéria Indisponível',
        {
          Matéria: theme?.name,
        }
      );
      setErrorMessage(
        'Ainda não estamos resolvendo listas e provas dessa matéria'
      );

      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    },
    [
      trackEvent,
      setErrorMessage,
      setIsThemesMenuOpen,
      isElegibleUniversitiesTheme,
      setSelectedTheme,
      selectedTheme,
      setDiscipline,
    ]
  );

  if (error) return <Error />;
  if (loading) return <Loading />;

  return (
    <Container>
      {isThemesMenuOpen ? (
        <>
          {errorMessage && (
            <ErrorContainer>
              <span>{errorMessage}</span>
            </ErrorContainer>
          )}
          <ul>
            {themes?.map(theme => (
              <li key={theme.id} onClick={() => handleThemeClick(theme)}>
                {theme.name}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div
          onClick={() => {
            setIsThemesMenuOpen(true);
          }}
        >
          <span>{selectedTheme.name}</span>
          <GoPencil className="icon" />
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  box-shadow: 0 1px 10px 2px rgb(0 0 0 / 30%);
  width: 320px;
  min-height: 80px;
  margin: 0 auto;
  padding: 15px 0;
  border-radius: 10px;
  border-top: 10px solid #f9ac3e;
  background: #fff;
  padding: 15px 0;
  font-size: 0.9em;
  color: #888;
  text-align: left;
  position: relative;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 100%; */
    padding: 0 15px;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }

    span {
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 30px;
      flex-grow: 1;
    }

    .icon {
      font-size: 1.1rem;
    }
  }

  ul {
    cursor: pointer;

    li {
      width: 100%;
      min-height: 40px;
      padding: 0 15px 0 45px;
      display: flex;
      align-items: center;
      justify-content: start;

      &:hover {
        background-color: #eee;
      }
    }
  }

  @media ${media.mobile} {
    width: 300px;
    margin: 0 auto;
  }
`;
