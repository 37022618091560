const originalOpen = XMLHttpRequest.prototype.open;
XMLHttpRequest.prototype.open = function(method, url, ...args) {
  if (!isOnWarningPage() && url.includes('possoler.tech')) {
    document.cookie = `old_url_bypass=${window.location.href}; path=/; domain=.respondeai.com.br;`;
    window.location.href = '/burlando-conteudo';
  }

  return originalOpen.apply(this, [method, url, ...args]);
};

function isOnWarningPage() {
  return window.location.href.indexOf('/burlando-conteudo') != -1;
}
