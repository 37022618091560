import React, { useCallback, useEffect, useState } from 'react';

import { styled } from 'styles';
import { faComment, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useFeedback } from 'modules/mvp/hooks/useFeedback';
import { useDialogContext } from 'providers/DialogProvider';
import { useToast } from 'modules/shared/hooks';
import { FEEDBACK_OPTIONS } from './options';
import {
  Container,
  Title,
  CommentContainer,
  FeedbackGrade,
  FeedbackIconContainer,
  FeedbackIcon,
  FeedbackLabel,
  Heart,
  Icon,
  SubmitIcon,
} from './styles';

export function AiFeedbackDialog({ listId, questionId }) {
  const [score, setScore] = useState(null);
  const [comment, setComment] = useState('');
  const { unsetDialog } = useDialogContext();
  const { successToast } = useToast();
  const {
    setExerciseFeedbackSent,
    trackViewAiDialogFeedback,
    trackSendAiDialogScore,
    trackSendAiDialogComment,
  } = useFeedback();

  const onScoreClick = useCallback(
    newScore => {
      trackSendAiDialogScore({
        listId,
        questionId,
        score: newScore,
      });
      setScore(newScore);
      setExerciseFeedbackSent('MvpExercise');

      if (newScore === 3) {
        unsetDialog();
        successToast('Obrigado pelo feedback!');
      }
    },
    [
      trackSendAiDialogScore,
      listId,
      questionId,
      setExerciseFeedbackSent,
      unsetDialog,
      successToast,
    ]
  );

  const onSendComment = useCallback(() => {
    trackSendAiDialogComment({
      listId,
      questionId,
      score,
      comment,
    });

    unsetDialog();
    successToast('Obrigado pelo feedback!');
  }, [
    comment,
    listId,
    questionId,
    score,
    successToast,
    trackSendAiDialogComment,
    unsetDialog,
  ]);

  useEffect(() => {
    trackViewAiDialogFeedback({ listId, questionId });
  }, [trackViewAiDialogFeedback, listId, questionId]);

  return (
    <DialogContainer onClick={unsetDialog}>
      <DialogContent onClick={e => e.stopPropagation()}>
        {!score && (
          <Container className="ai-dialog">
            <Title
              className="title"
              style={{ fontSize: '1.4em', marginBottom: '30px' }}
            >
              Queremos te ouvir!
            </Title>
            <Title
              className="title"
              style={{ marginBottom: '30px', fontSize: '1.2em' }}
            >
              O que você tem achado das resoluções por IA até aqui?
            </Title>
            <FeedbackGrade>
              {FEEDBACK_OPTIONS.map(option => (
                <FeedbackIconContainer
                  key={option.id}
                  onClick={() => onScoreClick(option.id)}
                >
                  <FeedbackIcon src={option.iconSrc} alt={option.text} />
                  <FeedbackLabel>{option.text}</FeedbackLabel>
                </FeedbackIconContainer>
              ))}
            </FeedbackGrade>
          </Container>
        )}

        {(score === 1 || score === 2) && (
          <Container className="ai-dialog">
            <Title
              style={{ fontSize: '1.4em', marginBottom: '30px' }}
              className="title"
            >
              Deixe seu comentário para melhorarmos <Heart>&#9829;</Heart>
            </Title>

            <CommentContainer>
              <Icon icon={faComment} />
              <textarea
                value={comment}
                onChange={event => setComment(event.target.value)}
                className="comment-area"
                placeholder="Quanto mais detalhes, melhor ;)"
              ></textarea>
              <SubmitIcon icon={faPaperPlane} onClick={onSendComment} />
            </CommentContainer>
          </Container>
        )}
      </DialogContent>
    </DialogContainer>
  );
}

const DialogContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogContent = styled.div`
  width: 720px;
  max-width: 90%;
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
  padding: 30px 0;
  border-top: 10px solid #6a0d91;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
