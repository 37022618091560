import React, { useCallback, useEffect, useMemo } from 'react';
import sadDexter from 'images/dexter/feedbacks/dexter-frawn.png';

import { dexterBlink } from 'images/permission';
import { styled, colors } from 'styles';
import { useListWithStatementTracker } from 'modules/mvp/hooks/useListWithStatementTracker';
import { useUploadDialogContext } from '../providers/UploadDialogProvider';

export default function SuccessListWithStatementStep() {
  const {
    trackViewThankYouScren,
    trackClickList,
  } = useListWithStatementTracker();

  const {
    listLink,
    selectedTheme,
    professor,
    exam,
    foundPercentage,
    setStep,
    utms,
  } = useUploadDialogContext();

  useEffect(() => {
    trackViewThankYouScren({
      listLink,
      theme: selectedTheme.name,
      professor: professor.name,
      exam,
      foundPercentage,
    });
  }, [
    trackViewThankYouScren,
    listLink,
    selectedTheme,
    professor,
    exam,
    foundPercentage,
  ]);

  const wasListSucceded = useMemo(() => {
    if (foundPercentage <= 0) {
      return false;
    }
    return true;
  }, [foundPercentage]);

  const onOpenLink = useCallback(() => {
    trackClickList({
      professor: professor.name,
      listId: listLink.split('/').pop(),
      origin: 'Tela de obrigado',
    });

    const { utmSource, utmMedium, utmContent } = utms;

    window.open(
      `${listLink}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}`,
      '_blank'
    );
  }, [listLink, professor.name, trackClickList, utms]);

  return (
    <Container className={wasListSucceded ? '' : 'border-red'}>
      {wasListSucceded ? (
        <Image src={dexterBlink} />
      ) : (
        <Image src={sadDexter} />
      )}
      <Title>
        {wasListSucceded
          ? 'Boa! Sua lista resolvida está pronta!'
          : 'Obrigado! Infelizmente, ainda não conseguimos resolver a sua lista!'}
      </Title>
      <ButtonContainer>
        {wasListSucceded ? (
          <Button className="fill" onClick={onOpenLink}>
            Acessar minha lista resolvida
          </Button>
        ) : (
          <>
            <Button onClick={() => setStep(3)}>Voltar</Button>
            <Button onClick={onOpenLink}>Buscar questões parecidas</Button>
          </>
        )}
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: 100%;
  padding: 20px 15px 20px;
  &.border-red {
    border-top: 22px solid #ff5555;
  }
`;

const Image = styled.img`
  width: 75px;
  height: 75px;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.9rem;
  color: #555;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-around;
`;

const Button = styled.button`
  min-width: 30%;
  padding: 10px;
  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: white;
  margin-top: 1.5rem;
  border-radius: 15px;
  font-size: 1.1em;
  font-weight: bold;
  border: 1px solid rgb(204, 204, 204);

  &.fill {
    width: 90%;
  }

  &:hover {
    background-color: white;
    border-color: ${colors.mainOrange};
    color: ${colors.mainOrange};
  }
`;
