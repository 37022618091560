import React, { useCallback } from 'react';

import { styled } from 'styles';
import SubscriptionButton from './SubscriptionButton';

export default function CancelSubscriptionButton() {
  const handleClick = useCallback(async () => {
    alert(
      'Para efetuar o cancelamento em conjunto com a extensão da sua assinatura, por favor envie um email para faleai@respondeai.com.br informando sua solicitação'
    );
  }, []);

  return (
    <Container onClick={handleClick}>
      Cancelar o meu plano e receber o bônus no fim da assinatura
    </Container>
  );
}

const Container = styled(SubscriptionButton)`
  background-color: lightcoral;
`;
