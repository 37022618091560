import React from 'react';

import { styled } from 'styles';
import { Container, Content, Title, Button } from './styles';
import { useDialogContext } from 'providers/DialogProvider';

export default function SuccessDialog() {
  const { unsetDialog } = useDialogContext();

  return (
    <Container onClick={unsetDialog}>
      <Content onClick={e => e.stopPropagation()}>
        <Title>Trocar cartão de crédito</Title>
        <Description>
          Seu cartão foi trocado com sucesso! Agora, sua recorrência automática
          está configurada para ocorrer no novo cartão informado :)
        </Description>
        <Button onClick={unsetDialog}>Fechar</Button>
      </Content>
    </Container>
  );
}

const Description = styled.p`
  margin: 20px 0;
`;
