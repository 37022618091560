import { useCallback, useMemo } from 'react';
import { useTracker } from 'modules/shared/hooks';
import { useBooks } from 'modules/books/hooks';
import { media } from 'styles';
import { useUserContext } from 'providers/UserProvider';
import { useForumContext } from 'providers/ForumProvider';

function useListExerciseForumTracker(content) {
  const getListExerciseTrackingInfo = useCallback(() => {
    if (!content || content.type !== 'ListExercise') return {};

    const props = {
      'Tipo Conteúdo': 'Exercício de Lista',
      Matéria: content.theme.name,
      'Categoria Lista': content.listCategory,
      Lista: content.listName,
      Exercício: content.id,
    };

    return props;
  }, [content]);

  return { getListExerciseTrackingInfo };
}

function useBooksForumTracker(content) {
  const { getChapterDotSectionName, getQuestionByBookExerciseId } = useBooks();

  const getBookTrackingInfo = useCallback(() => {
    if (!content || content?.type !== 'BookExercise') return {};

    const book = content?.edition?.book;

    if (!book) return {};
    const chapterDotSectionName = getChapterDotSectionName(
      content.edition,
      content.selectedSectionId
    );
    const question = getQuestionByBookExerciseId(
      content.edition,
      content.bookExerciseId
    );

    const props = {
      'Tipo Conteúdo': 'Livro',
      Exercício: question.name,
      Capítulo: chapterDotSectionName,
      Livro: content.edition.amplitudeName,
      Edição: content.edition.name,
      Matéria: book.theme.name,
      Categoria: media.platformName(),
    };

    return props;
  }, [content, getChapterDotSectionName, getQuestionByBookExerciseId]);

  return { getBookTrackingInfo };
}

function useFixationExerciseForumTracker(content) {
  const getFixationExerciseTrackingInfo = useCallback(() => {
    if (!content || content.type !== 'FixationExercise') return {};

    const props = {
      'Tipo Conteúdo': 'Exercício de fixação',
      Matéria: content.theme.name,
      Capítulo: content.chapter?.name,
      Tópico: content.topic?.name,
    };

    return props;
  }, [content]);

  return { getFixationExerciseTrackingInfo };
}

function useExtraTimeExerciseForumTracker(content) {
  const getExtraTimeExerciseTrackingInfo = useCallback(() => {
    if (!content || content.type !== 'ExtraTimeExercise') return {};

    const props = {
      'Tipo Conteúdo': 'Exercício de Tempo Extra',
      Matéria: content.theme.name,
      Capítulo: content.chapter.name,
    };

    return props;
  }, [content]);

  return { getExtraTimeExerciseTrackingInfo };
}

export default function useForumTracker() {
  const { trackEvent } = useTracker();
  const { user } = useUserContext();
  const { forumContent: content } = useForumContext();
  const { href } = window.location;

  const { getListExerciseTrackingInfo } = useListExerciseForumTracker(content);
  const { getBookTrackingInfo } = useBooksForumTracker(content);
  const { getFixationExerciseTrackingInfo } = useFixationExerciseForumTracker(
    content
  );
  const { getExtraTimeExerciseTrackingInfo } = useExtraTimeExerciseForumTracker(
    content
  );

  const amplitudeTrackingInfoGeneral = useMemo(() => {
    if (!content) return {};

    let props = {};
    if (content.type === 'BookExercise') props = getBookTrackingInfo();
    if (content.type === 'ListExercise') props = getListExerciseTrackingInfo();
    if (content.type === 'FixationExercise')
      props = getFixationExerciseTrackingInfo();
    if (content.type === 'ExtraTimeExercise')
      props = getExtraTimeExerciseTrackingInfo();

    if (user) {
      props['Universidade'] = user?.university?.acronym;
      props['Curso'] = user?.course?.name;
    }
    props['Url'] = href;

    return props;
  }, [
    content,
    getBookTrackingInfo,
    getFixationExerciseTrackingInfo,
    getListExerciseTrackingInfo,
    getExtraTimeExerciseTrackingInfo,
    user,
    href,
  ]);

  const trackInteractionToHotJar = useCallback(() => {
    if (!window.hj) return;
    window.hj('event', 'interact_with_forum');
  }, []);

  const trackClickedToCreateQuestion = useCallback(() => {
    trackEvent('Fórum RA: Clicou Para Perguntar', amplitudeTrackingInfoGeneral);
    trackInteractionToHotJar();
  }, [trackEvent, amplitudeTrackingInfoGeneral, trackInteractionToHotJar]);

  const trackClickedToSeeQuestion = useCallback(() => {
    trackEvent(
      'Fórum RA: Clicou Para Ver/Responder',
      amplitudeTrackingInfoGeneral
    );
    trackInteractionToHotJar();
  }, [trackEvent, amplitudeTrackingInfoGeneral, trackInteractionToHotJar]);

  const trackVotedInAnswer = useCallback(
    vote => {
      trackEvent(`Fórum RA: Votou em resposta`, {
        ...amplitudeTrackingInfoGeneral,
        Voto: vote,
      });
      trackInteractionToHotJar();
    },
    [trackEvent, amplitudeTrackingInfoGeneral, trackInteractionToHotJar]
  );

  const trackClickedInForumCTA = useCallback(() => {
    trackEvent('Fórum RA: Clicou em CTA', amplitudeTrackingInfoGeneral);
    trackInteractionToHotJar();
  }, [trackEvent, amplitudeTrackingInfoGeneral, trackInteractionToHotJar]);

  const trackCreatedQuestion = useCallback(() => {
    trackEvent('Fórum RA: Enviou Pergunta', amplitudeTrackingInfoGeneral);
    trackInteractionToHotJar();
  }, [trackEvent, amplitudeTrackingInfoGeneral, trackInteractionToHotJar]);

  const trackAnsweredQuestion = useCallback(() => {
    trackEvent('Fórum RA: Respondeu Pergunta', amplitudeTrackingInfoGeneral);
    trackInteractionToHotJar();
  }, [trackEvent, amplitudeTrackingInfoGeneral, trackInteractionToHotJar]);

  const trackDeletedQuestion = useCallback(() => {
    trackEvent('Fórum RA: Deletou Pergunta', amplitudeTrackingInfoGeneral);
    trackInteractionToHotJar();
  }, [trackEvent, amplitudeTrackingInfoGeneral, trackInteractionToHotJar]);

  const trackDeletedAnswer = useCallback(() => {
    trackEvent('Fórum RA: Deletou Resposta', amplitudeTrackingInfoGeneral);
    trackInteractionToHotJar();
  }, [trackEvent, amplitudeTrackingInfoGeneral, trackInteractionToHotJar]);

  const trackSawForum = useCallback(() => {
    trackEvent('Fórum RA: Visualizou Fórum', amplitudeTrackingInfoGeneral);
  }, [trackEvent, amplitudeTrackingInfoGeneral]);

  const trackSawAnswers = useCallback(
    quantityAnswers => {
      trackEvent('Fórum RA: Visualizou Respostas', {
        ...amplitudeTrackingInfoGeneral,
        'Qnt respostas': quantityAnswers,
      });
      trackInteractionToHotJar();
    },
    [trackEvent, amplitudeTrackingInfoGeneral, trackInteractionToHotJar]
  );

  const trackClickToShare = useCallback(
    publicationType => {
      trackEvent('Fórum RA: Clicou para compartilhar publicação', {
        ...amplitudeTrackingInfoGeneral,
        'Tipo de publicação': publicationType,
      });
    },
    [trackEvent, amplitudeTrackingInfoGeneral]
  );

  const trackFollowQuestion = useCallback(() => {
    trackEvent('Fórum RA: Seguiu publicação', amplitudeTrackingInfoGeneral);
  }, [trackEvent, amplitudeTrackingInfoGeneral]);

  const trackHighlightAnswer = useCallback(() => {
    trackEvent('Fórum RA: Destacou resposta', amplitudeTrackingInfoGeneral);
  }, [trackEvent, amplitudeTrackingInfoGeneral]);

  const trackRemoveHighlightAnswer = useCallback(() => {
    trackEvent('Fórum RA: Removeu destaque', amplitudeTrackingInfoGeneral);
  }, [trackEvent, amplitudeTrackingInfoGeneral]);

  const trackClickToReport = useCallback(
    publicationType => {
      trackEvent('Fórum RA: Clicou para Denunciar Publicação', {
        ...amplitudeTrackingInfoGeneral,
        'Tipo de publicação': publicationType,
      });
    },
    [trackEvent, amplitudeTrackingInfoGeneral]
  );

  const trackReport = useCallback(
    publicationType => {
      trackEvent('Fórum RA: Denunciou Publicação', {
        ...amplitudeTrackingInfoGeneral,
        'Tipo de publicação': publicationType,
      });
    },
    [trackEvent, amplitudeTrackingInfoGeneral]
  );

  const trackClickToSendImage = useCallback(() => {
    trackEvent(
      'Fórum RA: Clicou para enviar imagem',
      amplitudeTrackingInfoGeneral
    );
  }, [trackEvent, amplitudeTrackingInfoGeneral]);

  const trackEditPost = useCallback(
    publicationType => {
      trackEvent('Fórum RA: Editou publicação', {
        ...amplitudeTrackingInfoGeneral,
        'Tipo de publicação': publicationType,
      });
    },
    [trackEvent, amplitudeTrackingInfoGeneral]
  );

  const trackErrorReportRedirect = useCallback(
    keyWord => {
      trackEvent('Fórum RA: Foi Redirecionado', {
        ...amplitudeTrackingInfoGeneral,
        'Palavra chave': keyWord,
      });
    },
    [trackEvent, amplitudeTrackingInfoGeneral]
  );

  const trackCloseErrorReportDialog = useCallback(() => {
    trackEvent('Fórum RA: Fechou Dialog de Redirecionamento', {
      ...amplitudeTrackingInfoGeneral,
    });
  }, [trackEvent, amplitudeTrackingInfoGeneral]);

  const trackViewErrorReportDialog = useCallback(() => {
    trackEvent('Fórum RA: Viu Dialog de Redirecionamento', {
      ...amplitudeTrackingInfoGeneral,
    });
  }, [trackEvent, amplitudeTrackingInfoGeneral]);

  const trackSendErrorReportDialog = useCallback(() => {
    trackEvent('Reportou erro', {
      ...amplitudeTrackingInfoGeneral,
      Local: 'Fórum',
    });
  }, [trackEvent, amplitudeTrackingInfoGeneral]);

  return {
    trackClickedToCreateQuestion,
    trackClickedToSeeQuestion,
    trackVotedInAnswer,
    trackCreatedQuestion,
    trackAnsweredQuestion,
    trackDeletedQuestion,
    trackDeletedAnswer,
    trackSawAnswers,
    trackSawForum,
    trackClickedInForumCTA,
    trackClickToShare,
    trackFollowQuestion,
    trackHighlightAnswer,
    trackRemoveHighlightAnswer,
    trackClickToReport,
    trackReport,
    trackClickToSendImage,
    trackEditPost,
    trackErrorReportRedirect,
    trackCloseErrorReportDialog,
    trackViewErrorReportDialog,
    trackSendErrorReportDialog,
  };
}
