import React from 'react';

import { headerImage, papers } from 'modules/summaries/images';

import { styled, colors, media } from 'styles';

export default function SummariesHeader() {
  return (
    <Container>
      <Content>
        <Title>
          <img alt="" src={papers} />
          <h1>
            Resumões <div>Grátis</div>
          </h1>
        </Title>
        <SubTitle>Resumões grátis para toda a família</SubTitle>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  padding: 10px 0;
  background-color: ${colors.mainOrange};

  @media ${media.mobile} {
    padding-top: 75px;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-repeat: no-repeat;
  background-image: url(${headerImage});
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
    margin-right: 15px;
  }

  h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${colors.white};
    font-weight: bold;
    font-size: 2em;
    text-shadow: 3px 1px 5px rgba(32, 32, 32, 0.6);
    text-transform: uppercase;

    div {
      font-size: 140%;
    }
  }
`;

const SubTitle = styled.h2`
  font-size: 1.1em;
  text-transform: uppercase;
  text-align: center;
  max-width: 90%;
  color: ${colors.white};
  margin-top: 15px;
`;
