import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

import { styled } from 'styles';

export default function ListCategoryTitle({ listCategory }) {
  return (
    <>
      <PasteIcon icon={faCopy} />
      {listCategory.name}
    </>
  );
}

const PasteIcon = styled(FontAwesomeIcon)`
  color: #858585;
  font-size: 18px;
  margin-right: 10px;
`;
