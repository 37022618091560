import ElasticSearchAll from './elastic-search-all';

export async function ExecSearch(term, themeId = '', universityId = '') {
  const time = new Date();

  const all = await ElasticSearchAll.searchAll(term, themeId, universityId);
  const ret = { ...all, time: new Date() - time };

  return JSON.stringify(ret);
}
