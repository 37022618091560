import useForumTracker from 'modules/forum/hooks/useForumTracker';
import React from 'react';
// import { useForumContext } from 'providers/ForumProvider';
import ExerciseErrorReportRedirect from '../errorReportRedirect/ExerciseErrorReportRedirect';
// import ListExerciseListErrorReportRedirect from '../errorReportRedirect/ExerciseListErrorReportRedirect';
export default function ErrorReportRedirect({
  onReportOrClose,
  sendPost,
  title = '',
  text,
  shouldConfirmRedirectToErrorReport,
}) {
  // const { forumContent } = useForumContext();
  const {
    trackCloseErrorReportDialog,
    trackViewErrorReportDialog,
    trackSendErrorReportDialog,
  } = useForumTracker();
  return (
    <>
      {/* {forumContent?.type === 'FixationExercise' && ( */}
      {shouldConfirmRedirectToErrorReport && (
        <ExerciseErrorReportRedirect
          onReportOrClose={onReportOrClose}
          sendPost={sendPost}
          title={title}
          text={text}
          shouldConfirmRedirectToErrorReport={
            shouldConfirmRedirectToErrorReport
          }
          trackCloseErrorReportDialog={trackCloseErrorReportDialog}
          trackViewErrorReportDialog={trackViewErrorReportDialog}
          trackSendErrorReportDialog={trackSendErrorReportDialog}
        />
      )}
      {/* )} */}
      {/* {forumContent?.type === 'ListExercise' && (
        <ListExerciseListErrorReportRedirect
          sendPost={sendPost}
          title={title}
          text={text}
          shouldConfirmRedirectToErrorReport={
            shouldConfirmRedirectToErrorReport
          }
        />
      )} */}
    </>
  );
}
