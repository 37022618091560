import React, { useCallback, useEffect } from 'react';
import { styled } from 'styles';
import { crownImage } from 'modules/campaigns/default-trial/images';
import useDefaultTrial from '../hooks/useDefaultTrial';
import { Loading } from 'modules/shared/components/generic';
import { useFreeMonth } from 'modules/campaigns/free-month';

export default function WrapperFirstStep({ setStepCampaign }) {
  const {
    isUserEligibleToDefaultTrial,
    isLoadingCampaign,
    trackViewDialog,
  } = useDefaultTrial();
  const { isUserElegibleForFreeMonth } = useFreeMonth();

  useEffect(() => {
    console.log(isUserElegibleForFreeMonth());
  }, [isUserElegibleForFreeMonth]);

  const onAsk30MinutesTrial = useCallback(() => {
    setStepCampaign(2);
  }, [setStepCampaign]);

  useEffect(() => {
    if (!isLoadingCampaign) trackViewDialog();
  }, [trackViewDialog, isLoadingCampaign]);

  if (isLoadingCampaign) return <Loading />;

  return (
    <Container>
      <CrownImage src={crownImage} />
      <Title>
        {isUserElegibleForFreeMonth() ? 'OPORTUNIDADE ÚNICA' : 'KEEP CALM'}
      </Title>

      {isUserEligibleToDefaultTrial && (
        <SubTitle>Este conteúdo está bloqueado</SubTitle>
      )}
      {isUserEligibleToDefaultTrial ? (
        <RelaxMessage>Mas relaxa, temos 2 opções aqui pra você.</RelaxMessage>
      ) : (
        <>
          <RelaxMessage>
            Este conteúdo é exclusivo para assinantes.
          </RelaxMessage>
          <br />
          <RelaxMessage>
            {isUserElegibleForFreeMonth()
              ? 'Assine agora e ganhe um mês totalmente grátis!'
              : 'Pegue aqui seu desconto e adquira acesso a todo conteúdo!'}
            <br />
          </RelaxMessage>
          {isUserElegibleForFreeMonth() && (
            <RelaxMessage>
              <br />
              As férias estão logo ali, não perca essa chance!
              <br />
            </RelaxMessage>
          )}
          <br />
        </>
      )}
      {isUserEligibleToDefaultTrial && (
        <TrialCTA onClick={onAsk30MinutesTrial}>GANHAR TEMPO GRÁTIS</TrialCTA>
      )}
      <PlansCTA
        href={
          isUserElegibleForFreeMonth()
            ? 'https://www.respondeai.com.br/marketing/cupom/free_month'
            : 'https://www.respondeai.com.br/planos'
        }
      >
        {isUserEligibleToDefaultTrial
          ? 'VER PLANOS'
          : isUserElegibleForFreeMonth()
          ? 'EU QUERO!'
          : 'Quero conteúdo ilimitado!'}
      </PlansCTA>
    </Container>
  );
}

const Container = styled.div``;

const CrownImage = styled.img`
  width: 120px;
`;

const Title = styled.div`
  font-size: 2em;
  font-weight: bold;
  margin: 20px 0 30px;
`;

const SubTitle = styled.div`
  text-transform: uppercase;
  font-size: 1.5em;
`;

const RelaxMessage = styled.div`
  font-size: 1.15em;
  letter-spacing: 2px;
  margin: 5px;
`;

const TrialCTA = styled.button`
  margin: 0 auto;
  width: 300px;
  height: 60px;
  line-height: 60px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 40px;
  cursor: pointer;
  margin: 60px auto 20px auto;
  background: #47ba76;
  font-size: 17px;
`;

const PlansCTA = styled.a`
  margin: 0 auto;
  width: 300px;
  height: 60px;
  font-size: 17px;
  line-height: 60px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 40px;
  cursor: pointer;
  text-decoration: none;
  background: #f9ac3e;
  display: block;
`;
