import { styled } from 'styles';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  ${({
    backgroundType,
    backgroundColor,
    backgroundColorLinear01,
    backgroundColorLinear02,
  }) => {
    if (backgroundType === 'normal')
      return `background-color: ${backgroundColor};`;
    return `background-image: linear-gradient(to right, ${backgroundColorLinear01}, ${backgroundColorLinear02});`;
  }}
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  ${({ elementsDirection }) => {
    if (elementsDirection === 'horizontal') return 'flex-direction: row;';

    return 'flex-direction: column;';
  }}
  padding: 5px 0;
  width: 100%;
  height: 100%;
  margin: auto;
`;

const TextContainer = styled.div`
  padding: 3px 0;
`;

const TextTitle = styled.h1`
  font-weight: bold;
  text-align: center;
  color: ${({ fontColor }) => fontColor};
`;

const TextSubtitle = styled.h1`
  font-size: ${({ size }) => (size === 'big' ? '1em' : '.9em')};
  font-weight: bold;
  text-align: center;
  color: ${({ fontColor }) => fontColor};
`;

const Button = styled.div`
  max-width: 150px;
  padding: 8px 10px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#000'};
  color: ${({ fontColor }) => fontColor || '#FFF'};
  border-radius: 30px;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  text-align: center;
  font-size: 0.8em;
`;

export { Container, TextContainer, TextTitle, TextSubtitle, Button, Content };
