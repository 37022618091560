import React, { useCallback, useState } from 'react';

import { styled, media } from 'styles';
import { DexterAiProfile } from '../shared';
import { StepsAndAnswer } from './StepsAndAnswer';
import { useQuestions, useTrackEvents } from 'modules/mvp/hooks';
import { useUserContext } from 'providers/UserProvider';

export function ShowAiResponse({ question, refetchList, list }) {
  const [showResolution, setShowResolution] = useState(false);
  const { incrementFreeViewQuestion } = useQuestions();
  const { trackClickToSeeSolutionButton } = useTrackEvents();
  const { user } = useUserContext();

  const toggleShowResolution = useCallback(async () => {
    const shouldSentViewFreeResolution = !showResolution && !user?.hasAccess;
    if (shouldSentViewFreeResolution) {
      await incrementFreeViewQuestion(question?.id, refetchList);
    }

    if (!showResolution) trackClickToSeeSolutionButton(list, question);

    setShowResolution(!showResolution);
  }, [
    incrementFreeViewQuestion,
    list,
    question,
    refetchList,
    showResolution,
    trackClickToSeeSolutionButton,
    user?.hasAccess,
  ]);

  return (
    <>
      <SolutionTitle onClick={toggleShowResolution}>
        Resolução passo a passo
      </SolutionTitle>
      <Container>
        {showResolution && (
          <>
            {media.isDesktop() && <DexterAiProfile />}
            <Content>
              <StepsAndAnswer question={question} />
            </Content>
          </>
        )}
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  padding: 20px;
`;

const SolutionTitle = styled.h1`
  width: fit-content;
  max-width: 95%;
  margin: auto;
  font-size: 1.5em;
  font-weight: bold;
  padding: 15px 50px;
  background-color: #6a0d91;
  color: #fff;
  border-radius: 25px;
  cursor: pointer;

  @media ${media.mobile} {
    font-size: 1.1em;
    padding: 10px 50px;
    text-align: center;
  }
`;

const Content = styled.div`
  margin-top: 10px;
  margin-left: 20px;
  max-width: 100%;

  @media ${media.mobile} {
    margin-left: 0px;
  }
`;
