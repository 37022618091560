import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { styled } from 'styles';
import { useFormerSubscriberRecoverCampaign } from 'modules/campaigns/recover-subscriber/former-subscriber-recover/hooks';
import { useTracker } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import {
  Container,
  Content,
  Title,
  ButtonsContainer,
  OptionButton,
  ChoosePlan,
  Strong,
  Message,
  PlanOption,
  PlanDot,
  PlanName,
  PlanDescription,
  PlanStrip,
} from 'modules/campaigns/recover-subscriber/styles';
import {
  SameCardRecoverDialog,
  NewCardRecoverDialog,
  BoletoRecoverDialog,
} from '.';

export default function NoAccessDialogFormerSubscriber({ source }) {
  const [selectedPricing, setSelectedPricing] = useState();
  const { setDialog, unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const {
    pricings,
    paymentMethod,
    defaultAmplitudeEventAttributes,
  } = useFormerSubscriberRecoverCampaign();

  useEffect(() => {
    if (!pricings) return;

    setSelectedPricing(pricings[0]);
  }, [pricings]);

  useEffect(() => {
    trackEvent('Recuperacao Ex Assinantes: Visualizou Opcoes', {
      utm_source: source,
      ...defaultAmplitudeEventAttributes,
    });
  }, [source, trackEvent, defaultAmplitudeEventAttributes]);

  const renderCreditCardButtons = useCallback(
    () => (
      <>
        <OptionButton
          onClick={() =>
            setDialog(
              <SameCardRecoverDialog
                source={source}
                pricing={selectedPricing}
              />
            )
          }
        >
          Usar o mesmo cartão
        </OptionButton>
        <OptionButton
          onClick={() =>
            setDialog(
              <NewCardRecoverDialog source={source} pricing={selectedPricing} />
            )
          }
        >
          Atualizar cartão
        </OptionButton>
      </>
    ),
    [selectedPricing, setDialog, source]
  );

  const renderBoletoButtons = useCallback(
    () => (
      <>
        <OptionButton
          onClick={() =>
            setDialog(
              <BoletoRecoverDialog source={source} pricing={selectedPricing} />
            )
          }
        >
          Emitir novo boleto
        </OptionButton>
        <OptionButton
          onClick={() =>
            setDialog(
              <NewCardRecoverDialog source={source} pricing={selectedPricing} />
            )
          }
        >
          Pagar com cartão
        </OptionButton>
      </>
    ),
    [selectedPricing, setDialog, source]
  );

  const isBoleto = useMemo(() => {
    return paymentMethod === 'boleto';
  }, [paymentMethod]);

  const isCreditCard = useMemo(() => {
    return paymentMethod === 'credit_card';
  }, [paymentMethod]);

  if (!pricings) return null;
  return (
    <Container onClick={unsetDialog}>
      <Content onClick={e => e.stopPropagation()}>
        <Title>Reative e libere todo conteúdo AGORA!</Title>
        <ChoosePlan>
          {pricings.map(pricing => (
            <PlanOption
              data-cy={`former-subscriber-option-${pricing.description}`}
              onClick={() => setSelectedPricing(pricing)}
              className={
                selectedPricing &&
                pricing.id === selectedPricing.id &&
                'selected'
              }
              key={pricing.id}
            >
              <PlanDot
                className={
                  selectedPricing &&
                  pricing.id === selectedPricing.id &&
                  'selected'
                }
              />
              <PlanName>{pricing.description}</PlanName>
              <PlanDescription>
                {pricing.months}x de{' '}
                <b>
                  R$ {pricing?.monthlyPricingToShow} {isBoleto && '*'}
                </b>
              </PlanDescription>
              <PlanStrip className="plan-strip">Desconto exclusivo</PlanStrip>
            </PlanOption>
          ))}
        </ChoosePlan>
        {isBoleto && (
          <SmallMessage>
            * Parcelamento disponível apenas via cartão de crédito
          </SmallMessage>
        )}
        <Message>
          Basta reativar sua assinatura e todo conteúdo estará disponível para
          você :)
        </Message>
        <CTA>
          Como deseja prosseguir para{' '}
          <Strong>efetuar o pagamento e liberar novamente o seu acesso</Strong>{' '}
          ao Responde Aí?
        </CTA>
        <ButtonsContainer>
          {isCreditCard && renderCreditCardButtons()}
          {isBoleto && renderBoletoButtons()}
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const CTA = styled.div`
  margin: 30px auto;
  width: 90%;
  font-size: 1.3em;
`;

const SmallMessage = styled.div`
  margin-top: 20px;
`;
