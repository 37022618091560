import React from 'react';
import { styled } from 'styles';
import { useUserContext } from 'providers/UserProvider';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

function UserPicture({ isUserMenuOpen, toggleUserMenu }) {
  const { user } = useUserContext();

  return (
    <>
      <Container onClick={toggleUserMenu}>
        {isUserMenuOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
        <Picture src={user.pictureUrl} />
      </Container>
    </>
  );
}

export default UserPicture;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5em;
  cursor: pointer;
`;

const Picture = styled.img`
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: block;
  margin-left: 5px;
`;
