import { useCallback, useState } from 'react';

export function useUploadTracker() {
  const [hasAlreadyTrack, setHasAlreadyTrack] = useState(false);

  const trackViewUploadToHotjar = useCallback(() => {
    if (hasAlreadyTrack) return;
    if (!window.hj) return;

    window.hj('event', 'view_upload_feature');
    setHasAlreadyTrack(true);
  }, [hasAlreadyTrack]);

  const trackClickUploadToHotjar = useCallback(() => {
    if (!window.hj) return;

    window.hj('event', 'click_upload_feature');
  }, []);

  return {
    trackViewUploadToHotjar,
    trackClickUploadToHotjar,
  };
}
