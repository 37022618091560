import React, { useMemo, useEffect } from 'react';
import { useTracker } from 'modules/shared/hooks';
import Cookies from 'js-cookie';

import { colors, styled } from 'styles';

export default function BypassWithExtensionPage() {
  const { trackEvent } = useTracker();

  const redirectUrl = useMemo(() => {
    return Cookies.get('old_url_bypass');
  }, []);

  useEffect(() => {
    trackEvent('Burlou Conteúdo: Foi identificado', {
      url: redirectUrl,
    });
  }, [trackEvent, redirectUrl]);

  const urlToRedirect = useMemo(() => {
    if (redirectUrl) return redirectUrl;

    return `${process.env.REACT_APP_REACT_URL}/materias`;
  }, [redirectUrl]);

  return (
    <Container>
      <Content>
        <Title>EXTENSÃO BURLANDO CONTEÚDO</Title>
        <Description>
          Olá. Identificamos uma extensão no seu navegador que visa burlar
          conteúdos pagos para serem visualizados de forma gratuita. Como essa
          medida vai contra nossas políticas de uso da plataforma, pedimos que
          por favor desative ou desinstale essa extensão para poder voltar a
          acessar o Responde Aí :)
        </Description>
        <Description>
          Caso já tenha desativado ou desinstalado a extensão, basta clicar no
          botão abaixo para voltar à página que estava acessando.
        </Description>
        <Link href={urlToRedirect}>Já desativei a extensão</Link>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
  max-width: 95%;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
  padding: 20px;
  border-radius: 5px;
`;

const Title = styled.h1`
  color: ${colors.warningRed};
  font-weight: bold;
  font-size: 1.5em;
  text-transform: uppercase;
  text-align: center;
`;

const Description = styled.p`
  margin-top: 30px;
`;

const Link = styled.a`
  margin-top: 30px;
  background-color: ${colors.warningRed};
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  padding: 15px 40px;
  text-transform: uppercase;
`;
