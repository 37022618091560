import React, { useEffect, useState, useMemo } from 'react';
import { colors, media, styled } from 'styles';
import { useParams } from 'react-router-dom';

import {
  ContentFormatToggle,
  Video,
} from 'modules/workspace/components/content/shared';
import { Loading, Error } from 'modules/shared/components/generic';
import { MathjaxContent } from 'modules/shared/components/generic';
import {
  useWorkspaceContentFormat,
  useWorkspaceVideo,
} from 'modules/workspace/hooks/shared';
import {
  useWorkspacePermission,
  useWorkspaceTracker,
} from 'modules/workspace/hooks/main';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import { useUserContext } from 'providers/UserProvider';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';

export default function TheoryContent() {
  const { theoryId } = useParams();
  const {
    getContentFormat,
    hasManyContentFormats,
  } = useWorkspaceContentFormat();
  const { trackFormatChange, trackFirstVideoPlay } = useWorkspaceTracker();
  const { getVideosToRender } = useWorkspaceVideo();
  const {
    setCurrentContent,
    setCurrentContentFormat,
    currentContentFormat,
    currentContent,
  } = useWorkspaceContentContext();
  const { currentContentAccess } = useWorkspacePermission();
  const [videoDuration, setVideoDuration] = useState(0);
  const { user } = useUserContext();

  const recaptchaConfig = useMemo(() => {
    if (user) return null;

    return { action: 'view_theory' };
  }, [user]);

  const { data: theory, loading, error } = useContentQueryImmutable(
    `/theories/${theoryId}`,
    {},
    {},
    recaptchaConfig
  );

  const videos = useMemo(() => {
    return getVideosToRender(currentContent?.videos, currentContentAccess);
  }, [getVideosToRender, currentContent, currentContentAccess]);

  useEffect(() => {
    if (theory) setCurrentContent(theory);
  }, [theory, setCurrentContent]);

  useEffect(() => {
    if (theory) {
      const initialContentFormat = getContentFormat(theory);
      setCurrentContentFormat(initialContentFormat);
    }
  }, [getContentFormat, setCurrentContentFormat, theory]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  if (!theory) return null;

  return (
    <Container>
      {hasManyContentFormats(theory) && (
        <ContentFormatToggle
          currentContentFormat={currentContentFormat}
          setContentFormat={setCurrentContentFormat}
          color={colors.theory}
          content={theory}
          videoDuration={videoDuration}
          trackChangeContentFormatMethod={trackFormatChange}
          videos={videos}
        />
      )}
      <Content>
        {currentContentFormat === 'text' && (
          <MathjaxContent
            html={theory.lightBody}
            dataCy="theory-text-content-container"
            replaceToFakeContent={!currentContentAccess}
          />
        )}
        <ContainerVideos
          className={currentContentFormat === 'video' ? '' : 'none'}
          data-cy="theory-video-content-container"
        >
          {videos.map(video => (
            <Video
              key={video.providerId}
              video={video}
              setVideoDuration={setVideoDuration}
              trackFirstPlayVideoMethod={trackFirstVideoPlay}
            ></Video>
          ))}
        </ContainerVideos>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  background: #fff;
  padding-top: 70px;
`;

const Content = styled.div`
  padding: 0 30px 60px 30px;
  font-size: 1.05em;

  p {
    padding-bottom: 15px;
  }

  h2,
  b {
    color: ${colors.theory} !important;
  }

  @media ${media.mobile} {
    padding: 0 10px 30px 10px;
    font-size: 1.15em;
  }
`;

const ContainerVideos = styled.div`
  &.none {
    display: none;
  }
`;
