import { useCallback } from 'react';
import { useBookEditionContext } from '../providers/BookEditionProvider';
import { useUserContext } from 'providers/UserProvider';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';

export function useProfessorsLists() {
  const { professors, selectedProfessor } = useBookEditionContext();
  const { user } = useUserContext();
  const {
    isBannerEligibleInBookProfessorsListsSection,
  } = useMvpBooksEligible();

  const getOneProfessorFromUserProfessors = useCallback(
    (professors, userProfessors) => {
      if (!professors || professors.length === 0) return null;
      if (!userProfessors || userProfessors.length === 0) return null;

      const firstProfessorFromUserProfessors = professors.find(p => {
        const userProfessor = userProfessors.find(
          up => up.professor.id === p.id
        );

        return !!userProfessor;
      });

      return firstProfessorFromUserProfessors;
    },
    []
  );

  const getProfessorsWhoHaveListsThatHaveTheSameExercise = useCallback(
    professors => {
      const professorsWhoHaveListsThatHaveTheSameExercise = professors.filter(
        p => {
          const hasAnyBookList = p.bookLists.some(l => l.hasSpecificExercise);
          const hasAnyStatementList = p.statementLists.some(
            l => l.hasSpecificExercise
          );

          return hasAnyBookList || hasAnyStatementList;
        }
      );

      return professorsWhoHaveListsThatHaveTheSameExercise;
    },
    []
  );

  const getListsThatHaveSameExercise = useCallback(professor => {
    if (!professor) return [];

    const bookLists = professor.bookLists.filter(l => l.hasSpecificExercise);
    const statementLists = professor.statementLists.filter(
      l => l.hasSpecificExercise
    );

    const allListsOrderedDesc = [...bookLists, ...statementLists].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    return allListsOrderedDesc;
  }, []);

  const getListsThatDoesNotHaveSameExercise = useCallback(professor => {
    if (!professor) return [];

    const bookLists = professor.bookLists.filter(l => !l.hasSpecificExercise);
    const statementLists = professor.statementLists.filter(
      l => !l.hasSpecificExercise
    );

    const allListsOrderedDesc = [...bookLists, ...statementLists].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    return allListsOrderedDesc;
  }, []);

  const getTypeProfessorLists = useCallback(() => {
    const canShowUploadBanner = isBannerEligibleInBookProfessorsListsSection();
    const canRenderProfessorsLists = user && canShowUploadBanner;

    if (!canRenderProfessorsLists) return 'Não tem seção de listas';

    if (canRenderProfessorsLists && professors?.length === 0)
      return 'Tem seção, aparece o banner';

    if (professors?.length > 0 && !selectedProfessor)
      return 'Tem Lista, mas veio fechada';

    if (selectedProfessor) return 'Tem Lista e veio aberta';
  }, [
    isBannerEligibleInBookProfessorsListsSection,
    professors?.length,
    selectedProfessor,
    user,
  ]);

  return {
    getOneProfessorFromUserProfessors,
    getProfessorsWhoHaveListsThatHaveTheSameExercise,
    getListsThatHaveSameExercise,
    getListsThatDoesNotHaveSameExercise,
    getTypeProfessorLists,
  };
}
