import React, { useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';

import {
  SummariesHeader,
  ThemesSlider,
  SubjectItem,
} from 'modules/summaries/components';
import { Error, Loading } from 'modules/shared/components/generic';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { useLocation } from 'react-router-dom';
import { styled } from 'styles';
import useSummaryTracker from '../hooks/useSummaryTracker';

export default function SummariesPage() {
  const { data: themes, loading, error } = useContentQueryImmutable(
    `/themes/with-summaries`
  );

  const { trackViewSummaries } = useSummaryTracker();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);

  const themeIdCookie = Cookies.get('summaries_selected_theme_id');
  const [selectedThemeId, setSelectedThemeId] = useState(themeIdCookie || null);

  const selectedTheme = selectedThemeId
    ? themes?.find(t => t.id === parseInt(selectedThemeId))
    : themes && themes[0];

  // do not put any other dependency in this useEffects
  useEffect(() => {
    trackViewSummaries();
  }, [trackViewSummaries]);

  useEffect(() => {
    const themeIdFromUrl = params.get('themeId');
    if (themeIdFromUrl) {
      const themeToSelect = themes?.find(
        t => t.id === parseInt(themeIdFromUrl)
      );
      if (themeToSelect) setSelectedThemeId(themeToSelect.id);
    }
  }, [themes, params]);
  if (error) return <Error />;
  if (loading) return <Loading />;

  return (
    <>
      <SummariesHeader />

      <ThemesSlider
        selectedThemeId={selectedThemeId}
        setSelectedThemeId={setSelectedThemeId}
        themes={themes}
      />

      <SubjectsContainer>
        {selectedTheme?.subjects.map(s => (
          <SubjectItem
            key={s.id}
            name={s.name}
            subjectId={s.id}
            color={selectedTheme.color}
          />
        ))}
      </SubjectsContainer>
    </>
  );
}

const SubjectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: auto;
  width: 760px;
  max-width: 90%;
  min-height: 400px;
`;
