import React, { useCallback, useEffect, useMemo } from 'react';

import { styled } from 'styles';
import { useTracker } from 'modules/shared/hooks';
import {
  Container,
  Content,
  Title,
  ButtonsContainer,
  OptionButton,
} from 'modules/campaigns/recover-subscriber/styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useFormerSubscriberRecoverCampaign } from 'modules/campaigns/recover-subscriber/former-subscriber-recover/hooks';

export default function SuccessBoletoDialog({ source, pricing, boletoUrl }) {
  const { unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const {
    defaultAmplitudeEventAttributes,
  } = useFormerSubscriberRecoverCampaign();

  useEffect(() => {
    trackEvent('Recuperacao Ex Assinantes: Visualizou Sucesso', {
      utm_source: source,
      opcao: 'boleto',
      plan: pricing.description,
      ...defaultAmplitudeEventAttributes,
    });
  }, [source, trackEvent, pricing, defaultAmplitudeEventAttributes]);

  const bonusLink = useMemo(() => {
    return 'https://storage.googleapis.com/responde-ai-static/planners/PLANNEREDITAVEL_2023.pdf';
  }, []);

  const trackBonusClick = useCallback(() => {
    trackEvent('Recuperacao Ex Assinantes: Clicou Bonus', {
      utm_source: source,
      opcao: 'boleto',
      plan: pricing.description,
    });
  }, [pricing.description, source, trackEvent]);

  const openBoleto = useCallback(() => {
    window.open(boletoUrl, '_blank');

    unsetDialog();
  }, [boletoUrl, unsetDialog]);

  return (
    <Container>
      <Content>
        <Title>Boleto emitido com sucesso</Title>
        <PaymentInfo>
          Parabéns, você emitiu um novo boleto para recuperar seu acesso. Basta
          efetuar o pagamento, e em até 3 dias úteis seu acesso será restaurado.
        </PaymentInfo>

        <BonusLink href={bonusLink} onClick={trackBonusClick} target="_blank">
          Bonus: clique aqui e baixe o planner de estudos
        </BonusLink>

        <ButtonsContainer>
          <OptionButton onClick={openBoleto}>Abrir boleto</OptionButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const PaymentInfo = styled.div`
  font-size: 1.2em;
  margin: 15px 0;
`;

const BonusLink = styled.a`
  font-size: 1.2em;
  text-decoration: underline;
  cursor: pointer;
`;
