export const keyWordsList = [
  'erro',
  'errado',
  'errada',
  'incorreto',
  'problema',
  'diferente',
  'deveria ser',
  'nao e',
  'divergente',
];
