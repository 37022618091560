import React, { useCallback, useEffect, useState } from 'react';
import { styled, media } from 'styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default function ScrollToTopButton() {
  const [shouldShow, setShouldShowTo] = useState(false);

  const onDocumentScroll = useCallback(
    ({ target: { body, documentElement } }) => {
      setShouldShowTo(body.scrollTop > 100 || documentElement.scrollTop > 100);
    },
    []
  );

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    window.onscroll = onDocumentScroll;

    return () => {
      window.onscroll = null;
    };
  }, [onDocumentScroll]);

  return (
    <Container onClick={scrollToTop} show={shouldShow}>
      <FontAwesomeIcon icon={faChevronUp} />
    </Container>
  );
}

const Container = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1;
  width: 56px;
  height: 56px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 1px 1px 6px #aaa;
  color: #f7ac3c;
  padding: 10px;
  border-radius: 50%;
  font-size: 1.5em;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #f7ac3c;
  }

  @media ${media.mobile} {
    bottom: 15px;
    right: 15px;
    width: 35px;
    height: 35px;
    font-size: 1em;
  }
`;
