import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';

import { styled } from 'styles';
import { useWorkspaceListExerciseContext } from 'modules/workspace/providers/WorkspaceListExerciseProvider';
import { useUTM } from 'modules/shared/hooks';

export default function ListLink({ list }) {
  const { guideId } = useParams();
  const { utmParamsWithoutInternalSource } = useUTM();
  const { currentList } = useWorkspaceListExerciseContext();

  const isCurrent = useMemo(() => {
    if (!list || !currentList) return false;

    return list.id === currentList.id;
  }, [list, currentList]);

  const linkUrl = useMemo(() => {
    const firstExercise = list.listExercises[0];
    return `/praticar/${guideId}/lista/${list.listCategoryId}/${list.id}/exercicio-lista/${firstExercise.id}${utmParamsWithoutInternalSource}`;
  }, [list, guideId, utmParamsWithoutInternalSource]);

  return (
    <Container
      className={isCurrent ? 'current' : ''}
      to={linkUrl}
      onClick={e => e.stopPropagation()}
    >
      <Icon icon={faFileAlt} />
      {list.name}
    </Container>
  );
}

const Container = styled(Link)`
  display: flex;
  align-items: center;
  padding: 15px 25px 15px 10px;
  font-size: 0.9em;

  &.current {
    font-weight: bold;
    color: #fff;
    font-size: 0.95em;
  }

  &:hover {
    background-color: #3f3f3f;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin-right: 10px;
`;
