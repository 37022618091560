import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useRaSearchPostFileRequest } from 'modules/shared/hooks/requests';
import { media } from 'styles';
import { useSearchContext } from '../providers/SearchProvider';
import useSearchNavigation from '../hooks/useScreenNavigation';
import useSearchCropped from '../hooks/useSearchCropped';
import useSearchTracker from '../hooks/useSearchTracker';
import { useToast } from 'modules/shared/hooks';

export default function ImagePreview() {
  const {
    imgUploadedSrc,
    setResults,
    setLoading,
    loading,
  } = useSearchContext();
  const { goNextScreen } = useSearchNavigation();
  const { getCroppedImageFile } = useSearchCropped();
  const { sendPostFileRequest } = useRaSearchPostFileRequest();
  const imgRef = useRef(null);
  const { trackClickImageToCrop, trackErrorOnUploadImage } = useSearchTracker();
  const { errorToast } = useToast();

  const [crop, setCrop] = useState({
    unit: '%',
    x: 5,
    y: 5,
    width: 90,
    height: 90,
  });

  const imageWasCropped = useMemo(() => {
    return crop.width !== 100 || crop.height !== 100;
  }, [crop]);

  const sendCropImage = useCallback(async () => {
    setLoading(true);
    trackClickImageToCrop(imageWasCropped);

    const imageCroppedFile = await getCroppedImageFile(imgRef, crop);
    if (media.isMobile()) goNextScreen();

    const { data, error } = await sendPostFileRequest(imageCroppedFile);

    if (!error) {
      setResults(data);
    } else {
      errorToast(error.message);
      trackErrorOnUploadImage(error.message);
    }

    setLoading(false);
  }, [
    sendPostFileRequest,
    setResults,
    setLoading,
    getCroppedImageFile,
    goNextScreen,
    crop,
    trackClickImageToCrop,
    imageWasCropped,
    trackErrorOnUploadImage,
    errorToast,
  ]);

  if (!imgUploadedSrc) return null;

  return (
    <Container data-cy="cropped-container">
      <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
      >
        <img ref={imgRef} src={imgUploadedSrc} />
      </ReactCrop>
      {!loading && (
        <SubmitButton onClick={sendCropImage}>TÁ PRONTO</SubmitButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media ${media.mobile} {
    width: 100%;
    margin: 20px auto 0 auto;
    padding: 0 20px;
  }
`;

const SubmitButton = styled.button`
  border-radius: 30px;
  background: #56b6a6;
  color: white;
  padding: 5px 16px;
  width: 197px;
  height: 44px;
  font-weight: bold;
  font-size: 14px;
  margin: 20px 0;
  z-index: 4;

  @media ${media.mobile} {
    font-size: 13px;
    margin: 20px 0 14px 0;
  }
`;
