import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { styled, media } from 'styles';
import { CardInfoField } from 'modules/profile/components';
import { useDate, useCurrency } from 'modules/shared/hooks';
import { useChangePlanCampaign } from 'modules/campaigns/change-plan/hooks';

export default function SubscriptionInfoChangePlan({ subscription }) {
  const {
    getOldChangePlanOrder,
    getInstallmentPriceFromPlanPricing,
  } = useChangePlanCampaign();
  const { formatCurrency } = useCurrency();
  const { getHumanReadableDateFromTimestamp } = useDate();

  const oldOrder = getOldChangePlanOrder();

  const formattedPaymentMethod = useMemo(() => {
    const method =
      subscription.order.paymentMethod === 'credit_card' ? 'Cartão' : 'Boleto';

    const { installments } = subscription.order;

    if (installments && installments > 1) {
      return `${method} (${installments}x sem juros)`;
    } else {
      return method;
    }
  }, [subscription]);

  const subscriptionDateLabel = useMemo(() => {
    return subscription.willRenew ? 'Renova' : 'Expira';
  }, [subscription]);

  const formattedExpiresIn = useMemo(() => {
    return getHumanReadableDateFromTimestamp(subscription.expiresIn);
  }, [subscription, getHumanReadableDateFromTimestamp]);

  const formattedInfoText = useMemo(() => {
    const expiresIn = formattedExpiresIn;
    return subscription.willRenew
      ? `
      Sua renovação automática está ativa e prevista para ${expiresIn}, a menos que cancele antes dessa data.
    `
      : `
      Sua renovação automática foi cancelada e seu plano  fica ativo até ${expiresIn}.
    `;
  }, [subscription, formattedExpiresIn]);

  return (
    <>
      <CardInfoField fieldId="plan-old" label="Plano Antigo">
        {oldOrder.planPricing.plan.description}{' '}
        {formatCurrency(oldOrder.planPricing.price)}- Expira em{' '}
        {getHumanReadableDateFromTimestamp(subscription.expiresIn)}
      </CardInfoField>

      <CardInfoField fieldId="plan-new" label="Plano Novo">
        {subscription.plan.description}
        &nbsp;({subscription.order.installments}x de{' '}
        {formatCurrency(
          getInstallmentPriceFromPlanPricing(
            subscription.order.planPricing,
            subscription.plan
          )
        )}
        /mês) - Primeira cobrança em{' '}
        {getHumanReadableDateFromTimestamp(subscription.expiresIn)}
      </CardInfoField>

      <CardInfoField fieldId="payment" label="Meio de Pagamento">
        {formattedPaymentMethod}
      </CardInfoField>

      <CardInfoField
        fieldId="renovation-date"
        label={`${subscriptionDateLabel} em:`}
      >
        {formattedExpiresIn}
        <Info>
          <FontAwesomeIcon icon={faInfoCircle} />
          <span>{formattedInfoText}</span>
        </Info>
      </CardInfoField>
    </>
  );
}

const Info = styled.div`
  display: inline-block;
  position: relative;

  svg {
    font-size: 0.8em;
    margin-left: 5px;
    cursor: pointer;

    @media ${media.mobile} {
      display: none;
    }
  }

  span {
    visibility: hidden;
    width: 220px;
    background-color: #eee;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 150%;
    color: #666;
    border: 1px solid #bfbfbf;
  }

  &:hover {
    span {
      visibility: visible;
    }
  }
`;
