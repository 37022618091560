import React from 'react';
import { media } from 'styles';
import {
  TypeSelectorContainer,
  TypeSelectorItem,
  VideoSelectContainer,
} from './styles';

function TypeSelector({
  color,
  setResultType,
  resultType,
  resultsType,
  onlyVideos,
  setOnlyVideos,
}) {
  return (
    <>
      <TypeSelectorContainer>
        <TypeSelectorItem
          color={color}
          onClick={() => setResultType(resultsType.ALL)}
          selected={resultType === resultsType.ALL}
        >
          Tudo
        </TypeSelectorItem>
        <TypeSelectorItem
          color={color}
          onClick={() => setResultType(resultsType.THEORIES)}
          selected={resultType === resultsType.THEORIES}
        >
          Teoria
        </TypeSelectorItem>
        <TypeSelectorItem
          color={color}
          onClick={() => setResultType(resultsType.EXERCISES)}
          selected={resultType === resultsType.EXERCISES}
        >
          Exercícios
        </TypeSelectorItem>

        {media.isDesktop() && (
          <TypeSelectorItem color={color}>
            <input
              name="onlyVideo"
              type="checkbox"
              style={{ height: '15px', width: '15px', marginRight: '5px' }}
              checked={onlyVideos}
              onChange={e => setOnlyVideos(e.target.checked)}
            />
            <span onClick={() => setOnlyVideos(!onlyVideos)}>
              Só conteúdo com vídeo
            </span>
          </TypeSelectorItem>
        )}
      </TypeSelectorContainer>
      {media.isMobile() && (
        <TypeSelectorContainer>
          <VideoSelectContainer color={color}>
            <input
              name="onlyVideo"
              type="checkbox"
              style={{ height: '15px', width: '15px', marginRight: '5px' }}
              checked={onlyVideos}
              onChange={e => setOnlyVideos(e.target.checked)}
            />
            <span onClick={() => setOnlyVideos(!onlyVideos)}>
              Só conteúdo com vídeo
            </span>
          </VideoSelectContainer>
        </TypeSelectorContainer>
      )}
    </>
  );
}

export default TypeSelector;
