import React, { useCallback } from 'react';
import { styled } from 'styles';
import ResultsContainer from '../components/ResultsContainer';
import { useSearchContext } from '../providers/SearchProvider';
import { BeatLoader } from 'modules/shared/components/generic/loaders';
import useSearchNavigation from '../hooks/useScreenNavigation';

export default function ResultsScreenOnMobile() {
  const { loading } = useSearchContext();
  const { goNextScreen } = useSearchNavigation();

  const sendToUploadScreen = useCallback(() => {
    goNextScreen();
  }, [goNextScreen]);

  return (
    <Container>
      <ResendButton onClick={sendToUploadScreen}>
        Enviar outra questão
      </ResendButton>
      {loading ? (
        <BeatLoader size={12} color="#149f8c" />
      ) : (
        <ResultsContainer />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding-top: 60px;
`;

const ResendButton = styled.button`
  border-radius: 30px;
  background: #56b6a6;
  width: 197px;
  height: 44px;
  margin-bottom: 20px;
  color: white;
  font-weight: bold;
  font-size: 14px;
`;
