import React from 'react';
import { css } from 'styled-components';
import { Link as BrowserLink } from 'react-router-dom';
import { styled } from 'styles';

function UserMenuLink(props) {
  if (props.to)
    return (
      <Link to={props.to} onClick={props.onClick}>
        {props.children}
      </Link>
    );

  if (props.href) {
    return (
      <ExternalLink href="#" {...props}>
        {props.children}
      </ExternalLink>
    );
  }

  return <Button onClick={props.onClick}>{props.children}</Button>;
}

export default UserMenuLink;

const shared = css`
  display: flex;
  margin: 5px 30px;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
  height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 10px;
  width: 120px;
  color: #666;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: rgba(158, 158, 158, 0.2);
  }
`;

const Link = styled(BrowserLink)`
  ${shared}
`;
const ExternalLink = styled.a`
  ${shared}
`;
const Button = styled.button`
  ${shared}
`;
