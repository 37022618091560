import React from 'react';
import { styled, media, colors } from 'styles';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

export default function LoginButton(props) {
  const { text, icon, link } = props;
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  function trackLoginEvent() {
    trackEvent(
      'Lançamentos: Cadastrou/Logou LP de Aulão',
      getAmplitudeEventProperties()
    );
  }

  function getAmplitudeEventProperties() {
    return {
      Campanha: 'Intensivão do Ciclo Básico',
      Onde: media.platformName(),
      ...getUTMsByLocation(window.location),
    };
  }

  return (
    <Button text={text} href={link} onClick={() => trackLoginEvent()}>
      <Icon icon={icon} />
      {text}
    </Button>
  );
}

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #407bff;
  box-shadow: 0px 1.42709px 1.42709px rgba(0, 0, 0, 0.25);
  border-radius: 5.35158px;
  font-family: Lato;
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 1.1rem;
  color: #fff;
  width: 13.6rem;
  height: 2.2rem;
  background-color: ${props => {
    if (props.text.toLowerCase().index('google') > -1) return colors.googleRed;
    if (props.text.toLowerCase().index('facebook') > -1)
      return colors.facebookBlue;
    return colors.appleBlack;
  }};
  margin: 0 0.5rem;

  @media ${media.mobile} {
    width: 8.5rem;
    height: 1.6rem;
    font-size: 0.6rem;
    line-height: 0.9rem;
  }
`;
