import React from 'react';
import { styled, media } from 'styles';
import ThemeButton from './ThemeButton';
import ThemeAccordeon from './ThemeAccordeon';

export default function PrivateThemesMenu({
  privateThemes,
  disciplines,
  onSelectTheme,
  selected,
  studyPlans,
}) {
  return (
    <Container>
      <PrivateThemesDisclaimer anyThemeSelected={!!selected}>
        {' '}
        Matérias com matemática básica
        <TriangleBottomLeft />
      </PrivateThemesDisclaimer>

      {privateThemes.map(theme => {
        const disciplinesFromThisTheme = disciplines?.filter(
          d => parseInt(d.theme.id) === theme.id
        );
        return [
          <ThemeButton
            key={`theme_button_${theme.id}`}
            theme={theme}
            onThemeClick={onSelectTheme}
            selected={theme === selected}
          />,
          media.isMobile() && theme === selected && (
            <ThemeAccordeon
              key={`theme_accordeon_${theme.id}`}
              theme={theme}
              disciplines={disciplinesFromThisTheme}
              isPrivateTheme={true}
              studyPlans={studyPlans}
            />
          ),
        ];
      })}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  padding: 30px 0 15px 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;

  @media ${media.mobile} {
    margin-bottom: 30px;
  }
`;

const PrivateThemesDisclaimer = styled.div`
  position: absolute;
  top: 10px;
  left: -12px;
  color: white;
  background-color: red;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 0.8em;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 2px 4px;
  margin-left: ${({ anyThemeSelected }) => anyThemeSelected && '5px'};
`;

const TriangleBottomLeft = styled.div`
  position: absolute;
  left: 0;
  bottom: -7px;
  border-style: solid;
  border-width: 0 7px 7px 0;
  border-color: transparent #ccc transparent transparent;
`;
