/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { styled } from 'styles';
import { useTracker } from 'modules/shared/hooks';
import { useRAPostRequest } from 'modules/shared/hooks/requests';

function SearchFeedback({ version, setFeedbackSent, where = 'above' }) {
  const { trackEvent } = useTracker();

  const [feedback] = useState('');
  const [showThanks, setShowThanks] = useState('');
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendFeedbackReq = useRAPostRequest('/search/feedback');

  const sendFeedback = useCallback(
    async selected => {
      if (loading) return;

      setLoading(true);

      const variables = {
        thumbs: selected,
        version,
        feedback,
        where,
      };

      trackEvent('Busca: Enviou Feedback', variables);

      const { data } = await sendFeedbackReq(variables);

      const { success, errorMessage, successMessage } = data;

      setShowThanks(success ? successMessage : errorMessage);

      localStorage.setItem('search_already_voted', 'true');

      setTimeout(() => {
        setShowThanks(false);
        setSent(true);
        setFeedbackSent(true);
      }, 3000);

      setLoading(false);
    },
    [
      feedback,
      loading,
      sendFeedbackReq,
      setFeedbackSent,
      trackEvent,
      version,
      where,
    ]
  );

  if (sent) return <></>;
  if (loading) return <Title>Enviando...</Title>;
  if (showThanks) return <Title>{showThanks}</Title>;

  return (
    <Container>
      <Title>A busca está te ajudando?</Title>
      <Thumbs>
        {[
          <Thumb
            key="thumbs-up"
            onClick={() => {
              sendFeedback('up');
            }}
          >
            <Icon className="thumb-icon" icon={faThumbsUp} color={'white'} />
            Sim
          </Thumb>,
          <Thumb
            key="thumbs-down"
            onClick={() => {
              sendFeedback('down');
            }}
          >
            <Icon className="thumb-icon" icon={faThumbsDown} color={'white'} />
            Não
          </Thumb>,
        ].sort(() => (Math.random() > 0.5 ? 1 : -1))}
      </Thumbs>
    </Container>
  );
}

export default SearchFeedback;

const Container = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const Thumbs = styled.h2`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 180px;
  justify-self: center;
`;

const Thumb = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  background-color: darkgray;
  margin: 10px 0;
  font-size: 1em;
  padding: 5px 7px;
  border-radius: 5px;
  justify-content: space-evenly;
  width: 80px;
  cursor: pointer;

  &:hover {
    color: #ffda1a;

    .thumb-icon {
      color: #ffda1a;
    }
  }
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 1.2em;
`;
