import React, { useEffect } from 'react';
import { media, styled } from 'styles';
import ImagePreview from '../components/ImagePreview';
import useSearchNavigation from '../hooks/useScreenNavigation';
import useSearchTracker from '../hooks/useSearchTracker';

export default function CroppedScreenOnMobile() {
  const { goPreviousScreen } = useSearchNavigation();
  const { trackViewCroppedPage } = useSearchTracker();

  useEffect(() => {
    trackViewCroppedPage();
  }, [trackViewCroppedPage]);

  return (
    <Container>
      <Title>Corte sua imagem selecionando apenas 1 questão</Title>
      <ImagePreview />
      <TakeAnotherPhoto onClick={goPreviousScreen}>
        Enviar uma outra foto
      </TakeAnotherPhoto>
    </Container>
  );
}

const Container = styled.div`
  @media ${media.mobile} {
    padding-top: 50px;
    padding-bottom: 75px;
  }
`;

const Title = styled.h1`
  color: #000;
  text-align: center;
  font-size: 21px;
  font-weight: 700;
  width: 90%;
  margin: 0 auto;
`;

const TakeAnotherPhoto = styled.button`
  color: #000;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  z-index: 1;
  margin-bottom: 20px;
`;
