import React from 'react';
import { Route } from 'react-router-dom';
import { IcbLecture } from './pages';

export default [
  <Route
    exact
    path="/icb/aulao-intensivao/:lectureId"
    component={IcbLecture}
    key="icb-lecture"
  />,
];
