import React from 'react';
import styled from 'styled-components';
import { landscape, lectureIc } from '../images/index';
import { media } from 'styles';

export default function LecturesHeader() {
  return (
    <Header data-cy="lectures-header">
      <LandscapeImg src={landscape} />
      <HeaderTitle>
        <LectureIcImg src={lectureIc} />
        <TitleContainer>
          <Title>AULÕES</Title>
          <Subtitle>SALVA-VIDAS</Subtitle>
        </TitleContainer>
      </HeaderTitle>
      <HeaderContent>
        <ContentText>
          Saia do <strong>ZERO</strong> para a <strong>APROVAÇÃO</strong> em{' '}
          <strong>TEMPO RECORDE</strong> com aulões daqueles de salvar a vida!
        </ContentText>
      </HeaderContent>
    </Header>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  padding: 0;
  background-color: rgb(255, 111, 76);
  color: white;
  position: relative;
  overflow: hidden;
  @media ${media.mobile} {
    justify-content: end;
    height: 170px;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 20px;
  z-index: 1;
`;

const LectureIcImg = styled.img`
  margin-right: 25px;
  height: 88px;
  @media ${media.mobile} {
    height: 70px;
    margin-right: 15px;
  }
`;

const TitleContainer = styled.div`
  font-weight: bold;
  text-shadow: 2px 1px 0px #8d3c26;
  transform: skewX(-5deg);
`;

const Title = styled.p`
  transform: scale(1, 1.12);
  font-size: 3.2em;
  @media ${media.mobile} {
    font-size: 2.2em;
  }
`;

const Subtitle = styled.p`
  transform: scale(1, 1.1);
  font-size: 2em;
  @media ${media.mobile} {
    font-size: 1.4em;
  }
`;

const HeaderContent = styled.div`
  z-index: 1;
  @media ${media.mobile} {
    display: none;
  }
`;

const ContentText = styled.p`
  text-align: center;
  font-size: 1.1em;
`;

const LandscapeImg = styled.img`
  height: 249px;
  position: absolute;
  bottom: 0;
  left: 0;
  @media ${media.mobile} {
    top: -80px;
    max-width: 100%;
  }
`;
