import React, { useState, useEffect, useCallback } from 'react';

import { styled, media, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker } from 'modules/shared/hooks';
import BookCreditCardTrialCTADialog from './BookCreditCardTrialCTADialog';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import dayjs from 'dayjs';

export default function Test6BookCreditCardTrialFirstDialog() {
  const { setDialog, unsetDialog } = useDialogContext();
  const [today, setToday] = useState('');
  const [seventhDay, setSeventhDay] = useState('');
  const [eighthDay, setEighthDay] = useState('');

  const { trackEvent } = useTracker();

  const handleNextPress = useCallback(() => {
    trackEvent('Trial com Cartão: Dialog de Como Funciona Avançou');
    setDialog(
      <BookCreditCardTrialCTADialog
        source="react"
        isOnInstructionTest
        isOnInstructionTestVersion
      />
    );
  }, [setDialog, trackEvent]);

  const handleRefusePress = useCallback(() => {
    trackEvent('Trial com Cartão: Dialog de Como Funciona Recusou');
    unsetDialog();
  }, [unsetDialog, trackEvent]);

  useEffect(() => {
    setDate();

    trackEvent('Trial com Cartão: Visualizou Dialog de Como Funciona');
  }, [setDate, trackEvent]);

  const setDate = useCallback(() => {
    const todayDate = dayjs().format('DD/MM/YYYY');
    setToday(todayDate);
    const seventhDate = dayjs()
      .add(7, 'day')
      .format('DD/MM/YYYY');
    setSeventhDay(seventhDate);
    const eighthDate = dayjs()
      .add(8, 'day')
      .format('DD/MM/YYYY');
    setEighthDay(eighthDate);
  }, [setToday, setSeventhDay, setEighthDay]);

  return (
    <Container>
      <Content>
        <Title>Teste Gratuito de 7 dias</Title>
        <GreenTag>✨ COMO GANHAR RESPONDE AÍ GRÁTIS? ✨</GreenTag>
        <ContainerInfo>
          <Third>
            <ProgressBar>
              <Bar filled border />
              <MarkCircle filled />
              <Bar />
            </ProgressBar>
            <MarkBar>
              <Day>Hoje</Day>
              <Date>{today}</Date>
              <Description maxwidth>
                <CheckItem color={'#14b9a6'}>
                  <CheckCircleOutlineIcon fontSize="small" />
                  <span>Escolha sua Faculdade</span>
                </CheckItem>
                <CheckItem color={'#303030'} bold>
                  <RadioButtonUncheckedIcon fontSize="small" />
                  <span>Aceitar o Teste de 7 dias</span>
                </CheckItem>
                <CheckItem color={'#AAAAAA'} bold>
                  <RadioButtonUncheckedIcon fontSize="small" />
                  <span>Escolher Plano</span>
                </CheckItem>
                <CheckItem color={'#AAAAAA'}>
                  <RadioButtonUncheckedIcon fontSize="small" />
                  <span>Preencher Dados</span>
                </CheckItem>
                <CheckItem color={'#AAAAAA'}>
                  <RadioButtonUncheckedIcon fontSize="small" />
                  <span>Experimentar 7 dias de Graça</span>
                </CheckItem>
              </Description>
            </MarkBar>
          </Third>
          <Third>
            <ProgressBar>
              <Bar shouldShowMinWidthInMobile />
              <MarkCircle />
              <Bar />
            </ProgressBar>
            <MarkBar>
              <Day>7º Dia</Day>
              <Date>{seventhDay}</Date>
              <Description>
                Último dia para cancelar
                <br /> antes da primeira cobrança
              </Description>
            </MarkBar>
          </Third>
          <Third>
            <ProgressBar>
              <Bar shouldShowMinWidthInMobile />
              <MarkCircle />
              <Bar shouldNotShowInMobile />
            </ProgressBar>
            <MarkBar>
              <Day>8º Dia</Day>
              <Date>{eighthDay}</Date>
              <Description>
                Primeira cobrança do
                <br />
                plano escolhido caso não
                <br /> seja feito cancelamento
              </Description>
            </MarkBar>
          </Third>
        </ContainerInfo>
        <ButtonsContainer>
          <Button className="highlight" onClick={handleNextPress}>
            Ganhar 7 dias de Responde Aí
          </Button>
          <RefuseButton className="light" onClick={handleRefusePress}>
            RECUSAR
          </RefuseButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 100vh;
  padding-top: 60px;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff44;
  font-family: 'Lato', sans-serif;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  border-radius: 10px;
  padding: 30px;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: white;

  width: 30%;
  max-width: 700px;
  min-width: 470px;
`;

const ContainerInfo = styled.div`
  display: flex;
  width: 50%;
  position: relative;
  z-index: 1;
  align-self: center;
  margin: 0 auto;
  flex-direction: column;
`;

const Third = styled.div`
  width: 100%;
  display: flex;
`;

const ProgressBar = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  width: 10px;
  height: auto;
  flex-direction: column;
  background: #fff;
`;

const MarkBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
  padding-top: 5px;
`;

const CheckItem = styled.div`
  display: flex;
  color: ${({ color }) => color};
  align-items: center;
  justify-content: flex-start;
  padding: 4px 0px;
  width: 100%;
  span {
    padding-left: 8px;

    font-weight: ${({ bold }) => (bold ? 'bold' : '')};
  }
`;

const Description = styled.span`
  text-align: start;
  padding-top: 5px;
  width: ${({ maxwidth }) => (maxwidth ? '100%' : '')};
  font-size: 1em;
`;

const Date = styled.span`
  font-size: 0.8em;
  font-weight: bold;
  color: #989898;
  padding: 3px;
`;

const Day = styled.span`
  padding-top: 10px;
  font-size: 1.5em;
  font-weight: bold;

  text-align: start;
`;

const Bar = styled.div`
  background: ${({ filled }) => (filled ? '#14b9a6' : '#d9d9d9')};
  width: 100%;

  height: ${({ filled, shouldShowMinWidthInMobile }) =>
    filled ? '30px' : shouldShowMinWidthInMobile ? '30px' : '100%'};
  display: ${({ shouldNotShowInMobile }) =>
    shouldNotShowInMobile ? 'none' : ''};

  border-top-left-radius: ${({ border }) => (border ? '5px' : '')};
  border-top-right-radius: ${({ border }) => (border ? '5px' : '')};
`;

const MarkCircle = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ filled }) => (filled ? '#14b9a6' : '#d9d9d9')};
  border-radius: 50%;
  border: 3px solid ${({ filled }) => (filled ? '#3e8e85' : '#999999')};
  flex-shrink: 0;
  margin: -5px 0px;
  z-index: 1;

  @media ${media.mobile} {
    margin: -5px 0px;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  color: #000;
  margin-bottom: 25px;
  text-align: center;

  font-size: 1.7em;
  padding: 0px 40px;
`;

const ButtonsContainer = styled.div`
  width: 90%;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  height: 56px;
  border: none;
  border: 3px solid #000;
  font-weight: bold;
  border-radius: 10px;
  font-family: 'Lato', sans-serif;
  box-shadow: 0px 1.64659px 0.823293px rgba(0, 0, 0, 0.25);
  font-size: 1em;
  width: 100%;
  margin-bottom: 10px;

  &.highlight {
    background-color: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
    color: #fff;
  }

  &.light {
    color: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
  }

  @media ${media.mobile} {
    width: 70%;
  }
`;
const GreenTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3e8e85;
  color: #fff;
  padding: 5px;
  border-radius: 2px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const RefuseButton = styled.button`
  text-decoration-line: underline;
  color: #999999;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 1em;
  margin-top: 15px;
`;
