import React, { useCallback } from 'react';

import { styled, colors, media } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker, useCurrency } from 'modules/shared/hooks';

export default function ChangePlanExitDialog({ subscription }) {
  const { unsetDialog } = useDialogContext();
  const { formatCurrency } = useCurrency();
  const { trackEvent } = useTracker();

  const handleExitPress = useCallback(() => {
    unsetDialog();
  }, [unsetDialog]);

  const handleContactSupportPress = useCallback(() => {
    trackEvent('Troca de Plano: Clicou Atendimento', {});
    unsetDialog();
    window.UserVoice.show();
  }, [trackEvent, unsetDialog]);

  return (
    <Container>
      <Content>
        <Title>Troca de planos cancelada!</Title>
        <Message>
          Você permaneceu no plano mensal e continuará pagando R$
          {formatCurrency(subscription.order.planPricing.price)}/mês. Caso mude
          de idéia e queira trocar seu plano em outro momento, basta acessar o
          Minha Conta no menu superior, e clicar no botão &quot;trocar
          assinatura&quot;.
        </Message>
        <Message className="without-margin-top">
          Se ainda tiver dúvidas sobre a troca de plano, você também pode entrar
          em contato com nosso time de relacionamento no botão abaixo ou pelo
          faleai@respondeai.com.br
        </Message>
        <ButtonsContainer>
          <Button onClick={handleContactSupportPress}>
            Falar com o <br />
            atendimento
          </Button>
          <Button onClick={handleExitPress}>Fechar</Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 80px;
  border-top: 10px solid ${colors.purple};
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const Title = styled.h1`
  margin-top: 25px;
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
`;

const Message = styled.div`
  margin: 25px 0;
  line-height: 1.4em;

  &.without-margin-top {
    margin-top: 0;
  }
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  height: 50px;
  margin-top: 25px;
  color: ${colors.mainOrange};
  border: 2px solid ${colors.mainOrange};
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
`;
