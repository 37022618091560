import { styled, media } from 'styles';
import Select from 'react-select';

export const DoubleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 600px;
  margin-top: 30px;
  margin: 0 auto;

  @media ${media.mobile} {
    flex-direction: column;
    width: 85%;
  }
`;

const option = provided => ({
  ...provided,
  textAlign: 'left',
  '&:hover': {
    backgroundColor: '#eee',
  },
  '&:selected': {
    backgroundColor: '#eee',
  },
  backgroundColor: 'transparent',
  color: 'black',
});

const control = (provided, state) => ({
  ...provided,
  border: 'none',
  borderBottom: '1px solid #e6e6e6',
  justifyContent: 'flex-start',
  textAlign: 'left',
  boxShadow: state.isFocused ? 0 : 0,
});

export const selectCustomComponents = {
  IndicatorSeparator: () => null,
};

export const selectCustomStyles = {
  control: (provided, state) => control(provided, state),
  option: provided => option(provided),
};

export const selectCustomComponentsWithoutDropdown = {
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null,
};

export const SelectContainer = styled(Select)`
  width: 90%;
  padding: 7px 0px;
`;

export const UniversitySelectorContainer = styled.div`
  width: 90%;

  input {
    padding: 7px 0px;
  }
`;

export const UploadArea = styled.div`
  min-height: 150px;
  border: 1px ${({ error }) => (error ? 'solid #ff6f4e' : 'dashed #888888')};
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ error }) => (error ? 'space-between' : 'center')};
  padding: 10px;
  background-color: #fff;

  svg {
    font-size: 30px;
    color: #f6ac3a;
    margin-bottom: 10px;
  }

  p {
    padding-bottom: 10px;
  }

  span {
    font-size: 12px;
  }

  @media ${media.mobile} {
    margin-bottom: 15px;
  }
`;

export const StepsContainer = styled.div`
  text-align: center;
  margin: 40px 0;

  span {
    width: 30px;
    height: 10px;
    display: inline-block;
    background: #fff;
    transition: background 300ms ease-in-out;
    width: 30px;
    height: 10px;
    border: 1px solid #f9ac3e;
    border-radius: 5px;
    margin: 0 3px;
  }

  .fill {
    background: #f9ac3e;
  }

  @media ${media.mobile} {
    span {
      width: 44px;
      height: 10px;
    }
  }
`;

export const ErrorContainer = styled.div`
  align-self: flex-end;
  z-index: 10;
  background: red;
  position: absolute;
  top: 50px;
  left: -50px;

  color: white;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  width: 448px;

  @media ${media.mobile} {
    width: 320px;
    left: 0px;
  }
`;
