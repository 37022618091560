import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { CenterContainer, MainContainer } from './styles';
import ErrorMessage from './components/ErrorMessage';
import Header from './components/Header';
import { academicEducationList } from './forms-options';
import useProfileCompleteTracker from 'modules/profile/hooks/useProfileCompleteTracker';
import { useProfileComplete } from 'modules/profile/hooks';

export default function AcademicEducationForm({ onBack }) {
  const [academicEducation, setAcademicEducation] = useState(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const {
    trackViewAcademicEducationDialog,
    trackSubmitAcademicEducation,
  } = useProfileCompleteTracker();
  const { sendToCorrectUrl } = useProfileComplete();
  const sendAcademicEducation = useRAPostRequest(
    'profile/update_academic_education'
  );

  useEffect(() => {
    trackViewAcademicEducationDialog();
  }, [trackViewAcademicEducationDialog]);

  const handleSelect = useCallback(
    option => {
      setAcademicEducation(option);
    },
    [setAcademicEducation]
  );

  const canSendForm = useCallback(() => {
    if (!academicEducation) {
      setError('Você precisa selecionar sua formação acadêmica');
    }

    setTimeout(() => {
      setError('');
    }, 3000);

    return !!academicEducation;
  }, [academicEducation, setError]);

  const handleSendAcademicEducation = useCallback(async () => {
    if (saving) return;

    if (!canSendForm()) return;

    const eventData = {
      'Formação Acadêmica': academicEducation?.value,
    };

    if (academicEducation?.id === 3) {
      trackSubmitAcademicEducation(eventData);
      onBack();
      return;
    }

    setSaving(true);
    const params = {
      academic_education: academicEducation?.value,
    };

    try {
      await sendAcademicEducation(params);

      trackSubmitAcademicEducation(eventData);
      sendToCorrectUrl();
    } catch (e) {
      setError('Houve um erro ao salvar o perfil. Tente novamente.');
      setTimeout(() => {
        setError('');
      }, 3000);
    } finally {
      setSaving(false);
    }
  }, [
    canSendForm,
    setSaving,
    academicEducation,
    sendAcademicEducation,
    trackSubmitAcademicEducation,
    setError,
    saving,
    onBack,
    sendToCorrectUrl,
  ]);

  return (
    <MainContainer>
      <Header copy="Quase lá! Complete sua formação." />

      <CenterContainer>
        <ErrorMessage error={error} />
        <Container>
          <Title>Selecione sua formação acadêmica atual:</Title>
          <CheckboxesContainer>
            {academicEducationList.map(option => {
              return (
                <CheckboxContainer
                  key={option.id}
                  onClick={() => handleSelect(option)}
                >
                  {academicEducation?.id === option.id ? (
                    <CheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                  <span>{option.value}</span>
                </CheckboxContainer>
              );
            })}
          </CheckboxesContainer>
          <Button onClick={handleSendAcademicEducation} saving={saving}>
            {saving ? 'ENVIANDO...' : 'ENVIAR'}
          </Button>
        </Container>
      </CenterContainer>
    </MainContainer>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  margin: 20px 0px;
  font-weight: bold;
  color: #303030;
  text-align: start;
  font-size: 18px;
`;

const CheckboxesContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 6px;

  span {
    margin-left: 5px;
    text-align: start;
  }
`;

const Button = styled.button`
  width: 100%;
  border: 1px solid #f6ac3a;
  border-radius: 10px;

  color: white;
  background-color: #f6ac3a;
  margin-top: 20px;
  padding: 15px;

  font-weight: bold;
  font-size: 1.1em;

  cursor: ${({ saving }) => (saving ? 'not-allowed' : 'pointer')};
`;

const CheckedIcon = styled(RadioButtonCheckedIcon)`
  color: #f6ac3a;
`;
