import { useCallback } from 'react';
import Cookies from 'js-cookie';

import { useCookies } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';

export default function useEmailInvolvement() {
  const [cookies] = useCookies(['is_email_involvement']);
  const { user } = useUserContext();

  const showEmailInvolvementDialog = useCallback(() => {
    if (!user) return false;

    const hasCookie =
      !!cookies.is_email_involvement ||
      !!cookies.is_on_email_involvement_sign_in;

    return hasCookie && !user?.hasAccess;
  }, [user, cookies]);

  const clearEmailInvolvementCookies = useCallback(() => {
    Cookies.remove('is_email_involvement', {
      domain: process.env.REACT_APP_RESPONDEAI_DOMAIN,
      path: '/',
    });
    Cookies.remove('is_on_email_involvement_sign_in', {
      domain: process.env.REACT_APP_RESPONDEAI_DOMAIN,
      path: '/',
    });
  }, []);

  return { showEmailInvolvementDialog, clearEmailInvolvementCookies };
}
