import React, { useState } from 'react';
import { styled } from 'styles';

export default function SubjectButton(props) {
  const {
    text,
    image,
    isScheduleOpen,
    openSchedule,
    subjectChosenForSchedule,
    setSubjectChosenForSchedule,
    imageHover,
  } = props;
  const [isHover, setIsHover] = useState(false);

  function openSpecificSchedule() {
    if (!isScheduleOpen || subjectChosenForSchedule === text) {
      openSchedule(!isScheduleOpen);
    }
    setSubjectChosenForSchedule(text);
  }

  return (
    <Button
      text={text}
      onClick={openSpecificSchedule}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <img alt="" src={isHover ? imageHover : image} />
      {text}
    </Button>
  );
}

const Button = styled.button`
  background: #ffffff;
  border: ${props =>
    props.text === 'Cálculo' ? '1px solid #FDA243' : '1px solid #3EBD87'};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.6rem;
  width: 47%;
  height: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  color: #000;

  &:hover {
    background: ${props => (props.text === 'Cálculo' ? '#F87E25' : '#2E9A6C')};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  img {
    margin-right: 0.5rem;
  }
`;
