import React from 'react';

import { styled } from 'styles';

export function SolvedPercentage({ percentage = 0 }) {
  return (
    <Container>
      <SolvedListProgress percentage={percentage}>
        Lista {percentage}% Resolvida
      </SolvedListProgress>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  border-radius: 10px;
  padding: 10px 0;
  height: 40px;
  text-align: center;
  overflow: hidden;
  border: 1px solid #ccc;
`;

const SolvedListProgress = styled.h1`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0 10px;
  min-width: ${({ percentage }) => `${percentage}%`};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.3em;
  font-weight: bold;
  background-color: #76c06a;
  border-radius: 10px;
`;
