import React from 'react';
import { styled } from 'styles';

export default function GenericWarningBar() {
  return (
    <Container>
      <span>
        Nos dias 24/12 e 25/12 não vamos ter atendimento, mas não se preocupe,
        que a gente te responderá assim que voltar.
      </span>
    </Container>
  );
}

const Container = styled.div`
  background: #ead308;
  width: 100%;
  height: 1.5rem;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  /* cursor: pointer; */
`;
