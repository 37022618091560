import { useCallback, useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import {
  useWorkspace,
  useWorkspaceTopNavigation,
} from 'modules/workspace/hooks/main';
import { colors } from 'styles';
import { useWorkspaceExercise } from '../shared';
import { useUTM } from 'modules/shared/hooks';

export default function useWorkspaceNavigation() {
  const { utmParamsWithoutInternalSource } = useUTM();
  const {
    theoryId,
    exerciseId,
    listExerciseId,
    subjectId,
    guideId,
    summaryId,
  } = useParams();
  const {
    chapters,
    currentChapter,
    filterExtraExercises,
    filterUniversityExercises,
  } = useWorkspaceContentContext();
  const {
    isTheoryWorkspace,
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    isExtraExerciseWorkspace,
    isSummaryWorkspace,
    getChapterIndex,
    isExtraTimePageWorkspace,
  } = useWorkspace();
  const { showExtraExerciseTopNavigation } = useWorkspaceTopNavigation();
  const { getExtraExercisesFromChapter } = useWorkspaceExercise();

  const [previousContent, setPreviousContent] = useState(null);
  const [currentContent, setCurrentContent] = useState(null);
  const [nextContent, setNextContent] = useState(null);

  const mountContents = useCallback(
    (contentType, contentId, topicId, chapterId) => {
      return {
        contentType,
        contentId,
        topicId,
        chapterId,
      };
    },
    []
  );

  const getContentsArrayOrderedForSummary = useCallback(() => {
    const currentChapterIndex = getChapterIndex(currentChapter);
    const nextChapter = chapters[currentChapterIndex + 1];
    if (!nextChapter) return [];

    const topic = nextChapter.topics[0];
    if (!topic) return [];

    const theoryObject = mountContents(
      'theory',
      topic.theory.id,
      topic.id,
      topic.chapterId
    );
    const summaryObject = mountContents('summary', summaryId);
    return [summaryObject, theoryObject];
  }, [chapters, currentChapter, getChapterIndex, mountContents, summaryId]);

  const getLastContentFromChapter = useCallback(
    chapter => {
      if (!chapter) return null;
      const lastTopic = chapter.topics[chapter.topics.length - 1];

      const hasListExercises = lastTopic.listExercises.length > 0;
      if (hasListExercises) {
        const universityExercises = filterUniversityExercises(
          lastTopic.listExercises
        );
        const lastExercise =
          universityExercises[universityExercises.length - 1];

        if (lastExercise)
          return mountContents(
            'universityExercise',
            lastExercise.id,
            lastTopic.id,
            chapter.id
          );
      }

      const hasExercises = lastTopic.exercises.length > 0;
      if (hasExercises) {
        const { exercises } = lastTopic;
        const lastExercise = exercises[exercises.length - 1];

        if (lastExercise)
          return mountContents(
            'fixationExercise',
            lastExercise.id,
            lastTopic.id,
            chapter.id
          );
      }

      return mountContents(
        'theory',
        lastTopic.theory.id,
        lastTopic.id,
        chapter.id
      );
    },
    [filterUniversityExercises, mountContents]
  );

  const getContentsArrayOrderedForExtraTimePage = useCallback(() => {
    const lastContentObject = getLastContentFromChapter(currentChapter);

    const extraExercisesObject = getExtraExercisesFromChapter(
      currentChapter
    ).map(e =>
      mountContents(
        'extraExerciseFromExtraTimePage',
        e.id,
        null,
        currentChapter.id
      )
    );

    const currentChapterIndex = getChapterIndex(currentChapter);
    const nextChapter = chapters[currentChapterIndex + 1];

    if (nextChapter) {
      const firstTopic = nextChapter.topics[0];

      const nextTheoryObject = mountContents(
        'theory',
        firstTopic.theory.id,
        firstTopic.id,
        nextChapter.id
      );

      return [lastContentObject, ...extraExercisesObject, nextTheoryObject];
    }

    return [lastContentObject, ...extraExercisesObject];
  }, [
    getChapterIndex,
    currentChapter,
    getLastContentFromChapter,
    chapters,
    getExtraExercisesFromChapter,
    mountContents,
  ]);

  const contentsArrayOrdered = useMemo(() => {
    if (isSummaryWorkspace()) return getContentsArrayOrderedForSummary();
    if (isExtraTimePageWorkspace())
      return getContentsArrayOrderedForExtraTimePage();

    let orderedContents = [];
    const topics = _.flatten(chapters.map(chapter => chapter.topics));

    topics.forEach(topic => {
      const { id, theory, exercises, listExercises, chapterId } = topic;

      const theoryObject = mountContents('theory', theory.id, id, chapterId);
      const fixationExercisesObjects = exercises.map(e =>
        mountContents('fixationExercise', e.id, id, chapterId)
      );

      const universityExercises = filterUniversityExercises(listExercises);
      const universityExercisesObject = universityExercises.map(e =>
        mountContents('universityExercise', e.id, id, chapterId)
      );

      const extraExercisesObject = showExtraExerciseTopNavigation()
        ? filterExtraExercises(listExercises, universityExercises).map(e =>
            mountContents('extraExercise', e.id, id, chapterId)
          )
        : [];

      orderedContents = [
        ...orderedContents,
        theoryObject,
        ...fixationExercisesObjects,
        ...universityExercisesObject,
        ...extraExercisesObject,
      ];
    });

    return orderedContents;
  }, [
    chapters,
    mountContents,
    showExtraExerciseTopNavigation,
    filterUniversityExercises,
    filterExtraExercises,
    isSummaryWorkspace,
    getContentsArrayOrderedForSummary,
    isExtraTimePageWorkspace,
    getContentsArrayOrderedForExtraTimePage,
  ]);

  const getCurrentIndex = useCallback(() => {
    let currentIndex = null;
    if (isTheoryWorkspace()) {
      currentIndex = contentsArrayOrdered.findIndex(
        c =>
          c.contentType === 'theory' &&
          parseInt(c.contentId) === parseInt(theoryId)
      );
    }

    if (isFixationExerciseWorkspace()) {
      currentIndex = contentsArrayOrdered.findIndex(
        c =>
          c.contentType === 'fixationExercise' &&
          parseInt(c.contentId) === parseInt(exerciseId)
      );
    }

    if (isUniversityExerciseWorkspace()) {
      currentIndex = contentsArrayOrdered.findIndex(
        c =>
          c.contentType === 'universityExercise' &&
          parseInt(c.contentId) === parseInt(listExerciseId)
      );
    }

    if (isExtraExerciseWorkspace()) {
      currentIndex = contentsArrayOrdered.findIndex(
        c =>
          c.contentType === 'extraExercise' &&
          parseInt(c.contentId) === parseInt(listExerciseId)
      );
    }
    if (isExtraTimePageWorkspace()) {
      currentIndex = contentsArrayOrdered.findIndex(
        c =>
          c.contentType === 'extraExerciseFromExtraTimePage' &&
          parseInt(c.contentId) === parseInt(listExerciseId)
      );
    }

    if (isSummaryWorkspace()) {
      currentIndex = contentsArrayOrdered.findIndex(
        c =>
          c.contentType === 'summary' &&
          parseInt(c.contentId) === parseInt(summaryId)
      );
    }

    return currentIndex;
  }, [
    isTheoryWorkspace,
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    isExtraExerciseWorkspace,
    isSummaryWorkspace,
    contentsArrayOrdered,
    theoryId,
    exerciseId,
    listExerciseId,
    summaryId,
    isExtraTimePageWorkspace,
  ]);

  const updateCurrentContent = useCallback(
    currentContentIndex => {
      const currentContent = contentsArrayOrdered[currentContentIndex];
      setCurrentContent(currentContent);
    },
    [setCurrentContent, contentsArrayOrdered]
  );

  const updateNextContent = useCallback(
    currentContentIndex => {
      const nextContent = contentsArrayOrdered[currentContentIndex + 1];
      setNextContent(nextContent);
    },
    [setNextContent, contentsArrayOrdered]
  );

  const updatePreviousContent = useCallback(
    currentContentIndex => {
      const previousContent = contentsArrayOrdered[currentContentIndex - 1];
      setPreviousContent(previousContent);
    },
    [setPreviousContent, contentsArrayOrdered]
  );

  useEffect(() => {
    const currentContentIndex = getCurrentIndex();

    updateCurrentContent(currentContentIndex);
    updateNextContent(currentContentIndex);
    updatePreviousContent(currentContentIndex);
  }, [
    getCurrentIndex,
    updateCurrentContent,
    updateNextContent,
    updatePreviousContent,
  ]);

  const contentNavigationData = useMemo(() => {
    return {
      theory: {
        color: colors.theory,
        getLinkFromSubjectPage: ({ topicId, contentId }) =>
          `/aprender/topico/${subjectId}/${topicId}/teoria/${contentId}${utmParamsWithoutInternalSource}`,
        getLinkFromGuidePage: ({ topicId, contentId }) =>
          `/aprender/${guideId}/topico/${subjectId}/${topicId}/teoria/${contentId}${utmParamsWithoutInternalSource}`,
      },
      fixationExercise: {
        color: colors.exercise,
        getLinkFromSubjectPage: ({ topicId, contentId }) =>
          `/aprender/topico/${subjectId}/${topicId}/exercicio/${contentId}${utmParamsWithoutInternalSource}`,
        getLinkFromGuidePage: ({ topicId, contentId }) =>
          `/aprender/${guideId}/topico/${subjectId}/${topicId}/exercicio/${contentId}${utmParamsWithoutInternalSource}`,
      },
      universityExercise: {
        color: colors.listExercise,
        getLinkFromSubjectPage: ({ topicId, contentId }) =>
          `/aprender/topico/${subjectId}/${topicId}/exercicio-lista/${contentId}${utmParamsWithoutInternalSource}`,
        getLinkFromGuidePage: ({ topicId, contentId }) =>
          `/aprender/${guideId}/topico/${subjectId}/${topicId}/exercicio-lista/${contentId}${utmParamsWithoutInternalSource}`,
      },
      extraExercise: {
        color: colors.extraExercise,
        getLinkFromSubjectPage: ({ topicId, contentId }) =>
          `/aprender/topico/${subjectId}/${topicId}/exercicio-lista/${contentId}${utmParamsWithoutInternalSource}`,
        getLinkFromGuidePage: ({ topicId, contentId }) =>
          `/aprender/${guideId}/topico/${subjectId}/${topicId}/exercicio-lista/${contentId}${utmParamsWithoutInternalSource}`,
      },
      extraExerciseFromExtraTimePage: {
        color: colors.extraExercise,
        getLinkFromSubjectPage: ({ chapterId, contentId }) =>
          `/aprender/extras/${subjectId}/${chapterId}/exercicio-lista/${contentId}${utmParamsWithoutInternalSource}`,
        getLinkFromGuidePage: ({ chapterId, contentId }) =>
          `/aprender/${guideId}/extras/${subjectId}/${chapterId}/exercicio-lista/${contentId}${utmParamsWithoutInternalSource}`,
      },
    };
  }, [guideId, subjectId, utmParamsWithoutInternalSource]);

  const nextContentLink = useMemo(() => {
    if (!nextContent) return;
    const { contentType } = nextContent;

    return guideId
      ? contentNavigationData[contentType].getLinkFromGuidePage(nextContent)
      : contentNavigationData[contentType].getLinkFromSubjectPage(nextContent);
  }, [nextContent, guideId, contentNavigationData]);

  return {
    previousContent,
    currentContent,
    nextContent,
    contentNavigationData,
    nextContentLink,
  };
}
