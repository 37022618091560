import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useRenderDialog } from 'modules/shared/hooks';
import { useChangePlanCampaign } from 'modules/campaigns/change-plan/hooks';
import { usePaymentFailedRecoverCampaign } from 'modules/campaigns/recover-subscriber/payment-failed-recover/hooks';
import {
  ChangePlanCTADialog,
  ChangePlanOptionsDialog,
} from 'modules/campaigns/change-plan/components';
import {
  NoAccessDialogFormerSubscriber,
  useFormerSubscriberRecoverCampaign,
} from 'modules/campaigns/recover-subscriber';
import { useReactivateSubscription } from 'modules/profile/hooks';
import ReactivateSubscriptionDialog from 'modules/profile/dialogs/ReactivateSubscriptionDIalog';
import NoAccessDialogFormerTrial from 'modules/campaigns/recover-subscriber/former-subscriber-recover/dialogs/NoAccessDialogFormerTrial/NoAccessDialogFormerTrial';

export default function useRenderAccountPageDialog(subscription) {
  const location = useLocation();
  const {
    openChangePlanDialogAutomaticallyByUrl,
    openChangePlanDialogAutomaticallyOnOpenPage,
  } = useChangePlanCampaign();
  const {
    openPaymentFailedRecoverDialogAutomaticallyByUrl,
    getPaymentFailedRecoverDialogByUrl,
  } = usePaymentFailedRecoverCampaign();
  const {
    openFormerSubscriberRecoverOnOpenPage,
    openFormerSubscriberRecoverDialogAutomaticallyByUrl,
    getFormerSubscriberRecoverDialogByUrl,
    openTrialSubscriberRecoverOnOpenPage,
  } = useFormerSubscriberRecoverCampaign();
  const { autoOpenReactivateDialog } = useReactivateSubscription();

  const dialogs = useMemo(
    () => [
      {
        condition: () =>
          subscription && openChangePlanDialogAutomaticallyByUrl(location),
        render: () => <ChangePlanOptionsDialog subscription={subscription} />,
      },
      {
        condition: () =>
          subscription && openChangePlanDialogAutomaticallyOnOpenPage(),
        render: () => <ChangePlanCTADialog subscription={subscription} />,
      },
      {
        condition: () =>
          openPaymentFailedRecoverDialogAutomaticallyByUrl(location),
        render: () => getPaymentFailedRecoverDialogByUrl(location),
      },
      {
        condition: () =>
          openFormerSubscriberRecoverDialogAutomaticallyByUrl(location),
        render: () => getFormerSubscriberRecoverDialogByUrl(location),
      },
      {
        condition: openFormerSubscriberRecoverOnOpenPage,
        render: () => <NoAccessDialogFormerSubscriber source="minha_conta" />,
      },
      {
        condition: openTrialSubscriberRecoverOnOpenPage,
        render: () => <NoAccessDialogFormerTrial source="minha_conta" />,
      },
      {
        condition: () =>
          subscription && autoOpenReactivateDialog(subscription, location),
        render: () => (
          <ReactivateSubscriptionDialog subscription={subscription} />
        ),
      },
    ],
    [
      autoOpenReactivateDialog,
      getFormerSubscriberRecoverDialogByUrl,
      getPaymentFailedRecoverDialogByUrl,
      location,
      openChangePlanDialogAutomaticallyByUrl,
      openChangePlanDialogAutomaticallyOnOpenPage,
      openFormerSubscriberRecoverDialogAutomaticallyByUrl,
      openFormerSubscriberRecoverOnOpenPage,
      openPaymentFailedRecoverDialogAutomaticallyByUrl,
      subscription,
    ]
  );

  useRenderDialog(dialogs);
}
