import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { styled, colors, media } from 'styles';
import {
  useWorkspace,
  useWorkspaceTopNavigation,
} from 'modules/workspace/hooks/main';
import { useUserContext } from 'providers/UserProvider';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import { useUTM } from 'modules/shared/hooks';

export default function MainContentTopNavigation() {
  const { user } = useUserContext();
  const { utmParamsWithoutInternalSource } = useUTM();
  const {
    currentFixationExercises,
    currentUniversityExercises,
    currentExtraExercises,
  } = useWorkspaceContentContext();
  const {
    isTheoryWorkspace,
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    isExtraExerciseWorkspace,
  } = useWorkspace();
  const {
    getTheoryLink,
    getFixationExerciseLink,
    getUniversityExerciseLink,
    getExtraExerciseLink,
    showExtraExerciseTopNavigation,
  } = useWorkspaceTopNavigation();

  const exercisesCount = useMemo(() => {
    return currentFixationExercises.length;
  }, [currentFixationExercises]);

  const universityExercisesCount = useMemo(() => {
    return currentUniversityExercises.length;
  }, [currentUniversityExercises]);

  const extraExerciseCount = useMemo(() => {
    return currentExtraExercises.length;
  }, [currentExtraExercises]);

  const getOptionLinkWithUtm = useCallback(
    linkCallback => {
      const link = linkCallback();

      return `${link}${utmParamsWithoutInternalSource}`;
    },
    [utmParamsWithoutInternalSource]
  );

  return (
    <Container data-cy="content-navigation">
      <OptionLink
        className={isTheoryWorkspace() ? 'selected' : ''}
        color={colors.theory}
        to={() => getOptionLinkWithUtm(getTheoryLink)}
      >
        Teoria
      </OptionLink>
      {exercisesCount > 0 && (
        <OptionLink
          className={isFixationExerciseWorkspace() ? 'selected' : ''}
          color={colors.exercise}
          to={() => getOptionLinkWithUtm(getFixationExerciseLink)}
        >
          Exercício de Fixação ({exercisesCount})
        </OptionLink>
      )}
      {universityExercisesCount > 0 && (
        <OptionLink
          className={isUniversityExerciseWorkspace() ? 'selected' : ''}
          to={() => getOptionLinkWithUtm(getUniversityExerciseLink)}
        >
          Exercícios {user?.university?.acronym} ({universityExercisesCount})
        </OptionLink>
      )}
      {showExtraExerciseTopNavigation() && (
        <OptionLink
          className={isExtraExerciseWorkspace() ? 'selected' : ''}
          to={() => getOptionLinkWithUtm(getExtraExerciseLink)}
        >
          Tempo Sobrando? <br />
          Exercícios de Prova ({extraExerciseCount})
        </OptionLink>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 10px 0 20px 0;
  background-color: #fff;
`;

const OptionLink = styled(Link)`
  display: flex;
  flex: 1;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  background-color: #fafafa;
  text-align: center;

  &:not(:first-child) {
    border-left: 1px solid #e5e5e5;
  }

  &.selected {
    background-color: #fff;
    border-bottom: none;

    ${({ color }) => `color: ${color};`}
  }

  @media ${media.mobile} {
    font-size: 0.85em;
    text-align: center;
    height: 50px;
  }
`;
