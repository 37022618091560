import React from 'react';
import { ContainerBanner } from './styles';
import { useCovid19Functions } from '../sharedFunctions';

export default function Covid19() {
  const { onClickBanner } = useCovid19Functions();

  return (
    <ContainerBanner onClick={onClickBanner}>
      <div className="banner-content">
        <div className="copy-container">
          <p>
            Você já pode ativar o período grátis da Campanha Corrente do Bem!
          </p>
          <p className="subtitle gray">
            Lembra daquela vez que você decidiu continuar com a gente? Obrigada
            por ter ficado do nosso lado durante esse tempo! Agora chegou a hora
            de acrescentar seu período grátis como crédito no fim da sua
            assinatura.{' '}
          </p>
        </div>
        <div className="action-container">
          <button className="copy-action pulse">Clique aqui pra ativar</button>
        </div>
      </div>
    </ContainerBanner>
  );
}
