import React, { useEffect, useState } from 'react';

import { styled } from 'styles';

export default function CloseOverlay({ onClick }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, [setVisible]);

  return (
    <Container
      className={visible ? 'visible' : ''}
      onClick={onClick}
      data-cy="side-menu-close-overlay"
    />
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;

  &.visible {
    opacity: 1;
  }
`;
