import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { WorkspaceNavigationButtons } from 'modules/workspace/components/content/shared';
import { useWorkspaceNavigation } from 'modules/workspace/hooks/main';

export default function MainWorkspaceNavigationButtons() {
  const {
    previousContent,
    currentContent,
    nextContent,
    contentNavigationData,
    nextContentLink,
  } = useWorkspaceNavigation();
  const { theoryId, guideId, summaryId } = useParams();

  const hasPreviousButton = useCallback(() => {
    return !theoryId && !summaryId;
  }, [theoryId, summaryId]);

  const getNextButtonText = useCallback(() => {
    if (!nextContent) return;

    if (theoryId) return 'Ir para exercícios';

    if (nextContent.contentType === 'theory') {
      return currentContent?.chapterId === nextContent.chapterId
        ? 'Ir para o próximo tópico'
        : 'Ir para o próximo capítulo';
    }
    return 'Próximo exercício';
  }, [theoryId, nextContent, currentContent]);

  const getNextButtonLink = useCallback(() => {
    return nextContentLink;
  }, [nextContentLink]);

  const getPreviousButtonLink = useCallback(() => {
    if (!previousContent) return;
    const { contentType } = previousContent;

    return guideId
      ? contentNavigationData[contentType].getLinkFromGuidePage(previousContent)
      : contentNavigationData[contentType].getLinkFromSubjectPage(
          previousContent
        );
  }, [previousContent, contentNavigationData, guideId]);

  const getNextButtonColor = useCallback(() => {
    if (!nextContent) return;

    return contentNavigationData[nextContent.contentType].color;
  }, [nextContent, contentNavigationData]);

  const getPreviousButtonColor = useCallback(() => {
    if (!previousContent) return;

    return contentNavigationData[previousContent.contentType].color;
  }, [previousContent, contentNavigationData]);

  const previous = useMemo(() => {
    if (!hasPreviousButton()) return;

    return {
      link: getPreviousButtonLink(),
      color: getPreviousButtonColor(),
    };
  }, [hasPreviousButton, getPreviousButtonLink, getPreviousButtonColor]);

  const next = useMemo(() => {
    return {
      link: getNextButtonLink(),
      color: getNextButtonColor(),
      text: getNextButtonText(),
    };
  }, [getNextButtonLink, getNextButtonColor, getNextButtonText]);

  if (!currentContent) return null;
  return <WorkspaceNavigationButtons previous={previous} next={next} />;
}
