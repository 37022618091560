import { useCallback } from 'react';

export default function useSearchElegebility() {
  const shouldShowExerciseStatement = useCallback(() => {
    const utmParams = new URLSearchParams(window.location.search);
    const isFromSearch = utmParams.get('internal_source');

    return isFromSearch === 'search_image' || isFromSearch === 'search_text';
  }, []);

  return { shouldShowExerciseStatement };
}
