import styled from 'styled-components';
import { media } from 'styles';
import React, { useRef, useCallback, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { useDialogContext } from 'providers/DialogProvider';
import { useEscClick } from 'modules/shared/hooks';
import { useRAPostRequest } from 'modules/shared/hooks/requests';

export default function ErrorReportRedirectDialog({
  onReportOrClose,
  sendPost,
  title = '',
  text,
  dataToReport,
  trackCloseErrorReportDialog,
  trackViewErrorReportDialog,
  trackSendErrorReportDialog,
}) {
  const ref = useRef(null);
  const { unsetDialog } = useDialogContext();
  useEscClick(ref, unsetDialog);

  useEffect(() => {
    trackViewErrorReportDialog();
  }, [trackViewErrorReportDialog]);

  const onSubmit = useCallback(async () => {
    await sendPost();
    unsetDialog();
  }, [sendPost, unsetDialog]);
  const sendErrorReport = useRAPostRequest('error_reports/send_report');

  useEffect(() => {
    dataToReport.location = `${dataToReport.location} Origem: Veio do Fórum`;
  }, [dataToReport]);

  const onClickToReport = useCallback(async () => {
    const message = `Origem - Fórum: ${title} ${text}`;

    await sendErrorReport({
      data: JSON.stringify({
        ...dataToReport,
        message,
      }),
    });
    trackSendErrorReportDialog();
    onReportOrClose();
    unsetDialog();
  }, [
    sendErrorReport,
    title,
    text,
    dataToReport,
    unsetDialog,
    onReportOrClose,
    trackSendErrorReportDialog,
  ]);

  const onClose = useCallback(() => {
    onReportOrClose();
    unsetDialog();
    trackCloseErrorReportDialog();
  }, [onReportOrClose, unsetDialog, trackCloseErrorReportDialog]);

  return (
    <Container ref={ref}>
      <MdClose className="close-icon" onClick={onClose} />

      <p>DESEJA REPORTAR UM ERRO?</p>
      <span>
        Você está tentando adicionar um comentário no fórum, mas a{' '}
        <strong>
          melhor forma de reportar um erro é enviando a mensagem para o nosso
          suporte.
        </strong>
      </span>
      <span>Como quer continuar?</span>
      <DoubleButtonContainer>
        <Button onClick={onClickToReport}>Reportar erro</Button>
        <Button onClick={onSubmit} escButton>
          Postar comentário
        </Button>
      </DoubleButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-top: 10px solid #e9784f;
  border-radius: 10px;
  padding-top: 35px;
  padding: 45px;
  margin: 100px auto;
  justify-content: center;
  align-items: center;
  color: #7a7a7a;
  box-shadow: 0px 3.29968px 3.29968px rgba(0, 0, 0, 0.25);

  background-color: white;
  align-self: center;
  position: relative;
  z-index: 1;

  width: 100%;
  max-width: 680px;

  p {
    font-weight: bold;
    font-size: 23px;
  }

  span {
    font-size: 15px;
    padding-top: 20px;
  }

  @media ${media.mobile} {
    padding: 30px;
    width: 95%;
  }

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }
`;
const Button = styled.button`
  width: 48%;
  border: 2px solid ${({ escButton }) => (escButton ? '#E9784F' : '#36AAAD')};
  border-radius: 40px;

  color: ${({ escButton }) => (escButton ? '#E9784F' : '#36AAAD')};
  background-color: #fff;
  margin: 30px 0px;
  padding: 15px;

  font-weight: bold;
  font-size: 0.9em;

  cursor: pointer;

  @media ${media.mobile} {
    margin: 10px;
    width: 100%;
  }
`;

const DoubleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 600px;
  margin-top: 30px;
  margin: 0 auto;

  @media ${media.mobile} {
    flex-direction: column;
    width: 85%;
  }
`;
