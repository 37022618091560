import { useCallback, useMemo } from 'react';

export default function useGuide() {
  const getLecturesFromSubjects = useCallback(subjects => {
    if (!subjects) return [];
    subjects = subjects.filter(subject => !!subject.lecture);

    return subjects.map(subject => {
      return {
        ...subject.lecture,
        imagePath: subject.imagePath,
        name: subject.name,
      };
    });
  }, []);

  const filterSubjectsChaptersWithTopics = useCallback((subjects, topics) => {
    return subjects.map(subject => {
      subject.chapters = subject.chapters.filter(chapter =>
        topics.find(topic => !!topic.chapterId === chapter.id)
      );
      return subject;
    });
  }, []);

  const disciplineGuidableName = useMemo(() => {
    return 'discipline';
  }, []);

  const studyTrackGuidableName = useMemo(() => {
    return 'studyTrack';
  }, []);

  const studyPlanGuidableName = useMemo(() => {
    return 'studyPlan';
  }, []);

  const subjectGuidableName = useMemo(() => {
    return 'subject';
  }, []);

  const isStudyPlan = useCallback(
    guidableType => {
      return guidableType === studyPlanGuidableName;
    },
    [studyPlanGuidableName]
  );

  const isDiscipline = useCallback(
    guidableType => {
      return guidableType === disciplineGuidableName;
    },
    [disciplineGuidableName]
  );

  const isStudyTrack = useCallback(
    guidableType => {
      return guidableType === studyTrackGuidableName;
    },
    [studyTrackGuidableName]
  );

  return {
    getLecturesFromSubjects,
    filterSubjectsChaptersWithTopics,
    isStudyPlan,
    isDiscipline,
    isStudyTrack,
    disciplineGuidableName,
    studyTrackGuidableName,
    studyPlanGuidableName,
    subjectGuidableName,
  };
}
