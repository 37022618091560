import _ from 'lodash';
import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { styled, media, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import HeartBreakCancelDialog from './HeartBreakCancelDialog';
import CancelDetailedReasonDialog from './CancelDetailedReasonDialog';
import MoneyIcon from 'images/cancellation/money.png';
import MoneyIconPb from 'images/cancellation/money_pb.png';
import OtherIcon from 'images/cancellation/other.png';
import OtherIconPb from 'images/cancellation/other_pb.png';
import SearchIcon from 'images/cancellation/search.png';
import SearchIconPb from 'images/cancellation/search_pb.png';
import TimeIcon from 'images/cancellation/time.png';
import TimeIconPb from 'images/cancellation/time_pb.png';
import UnlikeIcon from 'images/cancellation/unlike.png';
import UnlikeIconPb from 'images/cancellation/unlike_pb.png';
import { useTracker } from 'modules/shared/hooks/Tracker';

export default function CancelReasonDialog({ subscription }) {
  const { trackEvent } = useTracker();
  const { setDialog } = useDialogContext();
  const [selectedReason, setSelectedReason] = useState(null);
  const [reasons, setReasons] = useState([]);

  useEffect(() => {
    trackEvent('Fluxo de Cancelamento: Visualizou Motivos Genericos');

    const otherReason = REASONS[REASONS.length - 1];
    const reasonsWithoutOther = REASONS.slice(0, REASONS.length - 1);

    const reasonsWithoutOtherShuffled = _.shuffle(reasonsWithoutOther);
    setReasons([...reasonsWithoutOtherShuffled, otherReason]);
  }, [trackEvent]);

  const handleBackButtonPress = useCallback(() => {
    setDialog(<HeartBreakCancelDialog subscription={subscription} />);
  }, [setDialog, subscription]);

  const handleContinuePress = useCallback(() => {
    if (!selectedReason) {
      alert('Por favor, selecione um motivo abaixo');
      return;
    }

    setDialog(
      <CancelDetailedReasonDialog
        subscription={subscription}
        selectedReason={selectedReason}
      />
    );
  }, [selectedReason, subscription, setDialog]);

  const cancelText = useMemo(() => {
    if (subscription.plan.months === 1) return 'Cancelar a Assinatura';
    else return 'Desativar a Renovação';
  }, [subscription]);

  function isReasonSelected(reason) {
    return selectedReason && selectedReason.id === reason.id;
  }

  function onSelectReason(reason) {
    setSelectedReason(reason);
  }

  return (
    <Container>
      <Content>
        <CancelStrip>{cancelText}</CancelStrip>

        <ReasonsContainer>
          {reasons.map(reason => (
            <Reason
              key={reason.id}
              onClick={() => onSelectReason(reason)}
              className={isReasonSelected(reason) && 'selected'}
              color={reason.color}
            >
              {isReasonSelected(reason) ? (
                <ReasonIcon src={reason.iconSelected} />
              ) : (
                <ReasonIcon src={reason.iconNotSelected} />
              )}
              <ReasonText>{reason.text}</ReasonText>
            </Reason>
          ))}
        </ReasonsContainer>

        <ButtonsContainer>
          <button onClick={handleBackButtonPress} type="button">
            Voltar
          </button>

          <button
            onClick={handleContinuePress}
            className={selectedReason && 'enable'}
          >
            Continuar
          </button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const customLightGrey = '#F9F9F9';

const REASONS = [
  {
    id: 1,
    text: 'Não posso pagar no momento',
    iconNotSelected: MoneyIconPb,
    iconSelected: MoneyIcon,
    color: '#F3B038',
    detailedReasons: [
      {
        id: 1,
        text: 'Não tenho como continuar pagando no cartão',
      },
      {
        id: 2,
        text: 'Estou com problemas financeiros',
      },
    ],
  },
  {
    id: 2,
    text: 'Não preciso usar o Responde Aí agora',
    iconNotSelected: TimeIconPb,
    iconSelected: TimeIcon,
    color: '#71BFC7',
    detailedReasons: [
      {
        id: 3,
        text: 'Vou entrar de férias',
      },
      {
        id: 4,
        text: 'Minhas aulas estão paralizadas',
      },
      {
        id: 5,
        text: 'Vou precisar trancar a faculdade',
      },
      {
        id: 6,
        text: 'Terminei as matérias da faculdade',
      },
    ],
  },
  {
    id: 3,
    text: 'Não tem as matérias que eu preciso',
    iconNotSelected: SearchIconPb,
    iconSelected: SearchIcon,
    color: '#824A83',
    detailedReasons: [
      {
        id: 7,
        text: 'Não encontrei as matérias que precisava no Responde Aí',
      },
      {
        id: 8,
        text: 'Não faço mais nenhuma matéria que tenha no Responde Aí',
      },
    ],
  },
  {
    id: 4,
    text: 'Não gostei do Responde Aí',
    iconNotSelected: UnlikeIconPb,
    iconSelected: UnlikeIcon,
    color: '#CD4521',
    detailedReasons: [
      {
        id: 9,
        text: 'Não gostei do conteúdo ou da didática',
      },
      {
        id: 10,
        text: 'Não mandei bem nas provas estudando pelo Responde Aí',
      },
      {
        id: 11,
        text: 'Tive problemas para usar o site ou app',
      },
    ],
  },
  {
    id: 5,
    text: 'Outro motivo',
    iconNotSelected: OtherIconPb,
    iconSelected: OtherIcon,
    color: '#6BB363',
    detailedReasons: [],
  },
];

const Container = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${media.desktop} {
    height: 100%;
  }
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-top: 10px solid ${colors.warningRed};
  text-align: left;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const CancelStrip = styled.div`
  display: inline-block;
  padding: 3px 10px;
  position: relative;
  left: -48px;
  margin-bottom: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${colors.warningRed};
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  @media ${media.mobile} {
    left: -24px;
  }
`;

const ReasonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 50px 0;

  @media ${media.mobile} {
    flex-direction: column;
    margin-top: 20px;
  }
`;

const Reason = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 3px;
  border: 2px solid ${customLightGrey};
  border-radius: 15px;
  background-color: ${customLightGrey};
  padding: 15px;
  cursor: pointer;

  &.selected {
    background-color: transparent;
    border-color: ${({ color }) => color};
  }

  @media ${media.mobile} {
    margin: 10px 0;
    flex-direction: row;
  }
`;

const ReasonIcon = styled.img`
  width: 55px;
`;

const ReasonText = styled.p`
  text-align: center;
  margin-top: 10px;
  font-size: 0.85em;
  line-height: 1.2;

  @media ${media.mobile} {
    font-size: 1.15em;
    margin: 0;
    margin-left: 8px;
  }
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  button {
    flex: 1;
    margin: 0 10px;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    border: 2px solid #929292;
    color: #929292;
    text-transform: uppercase;

    &.enable {
      border-color: ${colors.warningRed};
      color: ${colors.warningRed};
    }
  }

  @media ${media.mobile} {
    flex-direction: column-reverse;

    button {
      width: 100%;
      margin: 5px 0;
      padding: 10px 0;
    }
  }
`;
