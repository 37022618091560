import React, { useRef } from 'react';
import { useDialogContext } from 'providers/DialogProvider';
import { useEscClick, useOutsideClick } from 'modules/shared/hooks';
import { styled, media } from 'styles';
import AppIcon from 'images/generic/apple-store-ico.png';
import { MdClose } from 'react-icons/md';
import PlayIcon from 'images/generic/google_play_ico.png';
import IlApp from 'images/generic/il-app.png';

export default function DownloadAppDialog() {
  const ref = useRef(null);

  const { unsetDialog } = useDialogContext();

  useEscClick(ref, () => unsetDialog());
  useOutsideClick(ref, () => unsetDialog());

  return (
    <Container>
      <MdClose className="close-icon" onClick={unsetDialog} />
      <div
        ref={ref}
        className="cta-download"
        onClick={event => event.stopPropagation()}
      >
        <h1>
          BAIXE AGORA E TENHA ACESSO AOS MATERIAIS DO <span>RESPONDE AÍ</span>{' '}
          OFFLINE
        </h1>
        <div className="cta-download-buttons">
          <a
            target="_blank"
            href="https://itunes.apple.com/app/id1360368171"
            rel="noreferrer"
          >
            <div className="content">
              <img className="app-store" src={AppIcon} />
              <div className="text">
                <span className="small">Disponível na</span>
                <br />
                <span className="big">App Store</span>
              </div>
            </div>
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=br.com.respondeai.app"
            rel="noreferrer"
          >
            <div className="content">
              <img className="app-store" src={PlayIcon} />
              <div className="text">
                <span className="small">Disponível na</span>
                <br />
                <span className="big">Google Play</span>
              </div>
            </div>
          </a>
        </div>
      </div>

      <img className="dexter" src={IlApp} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;

  .dexter {
    width: 350px;

    @media ${media.mobile} {
      display: none;
    }
  }

  .cta-download {
    text-align: center;
    max-width: 550px;
    margin: 0 30px;

    > h1 {
      font-size: 1.8em;
      font-weight: bold;
      line-height: 1.3;

      > span {
        color: #ffac00;
      }
    }
  }

  .cta-download-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 50px 0;
    width: 100%;
    max-width: 500px;

    > a {
      width: 220px;
      border: 1px solid rgb(182, 155, 1);
      border-radius: 60px;
      padding: 5px 20px;
      margin: 0 10px 10px 10px;
      text-decoration: none;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }

      .content {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .text {
        position: relative;
        top: -2px;
        text-align: left;
        line-height: 1.1;
        color: #fff;

        .small {
          font-size: 0.75em;
        }

        .big {
          font-size: 1.4em;
          font-weight: bold;
        }
      }

      .app-store {
        position: relative;
        left: -5px;
        margin-right: 3px;
        height: 40px;
        width: 40px;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.7rem;
    color: #888;
    cursor: pointer;
    z-index: 3;

    &:hover {
      transform: scale(1.3);
      color: #555;
      transition: color 180ms ease-in-out;
      transition: transform 180ms ease-in-out;
    }
  }

  .exit {
    position: absolute;
    top: 30px;
    left: 50px;
    font-size: 2em;
    cursor: pointer;
    color: #fff;
  }
`;
