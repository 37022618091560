import { useCallback } from 'react';
import { useSearchContext } from '../providers/SearchProvider';

export default function useSearchCropped() {
  const { file } = useSearchContext();

  const getCroppedImageFile = useCallback(
    async (imgRef, crop) => {
      return new Promise(resolve => {
        const canvas = document.createElement('canvas');
        const imageUploaded = imgRef.current;
        const croppedWidthPixel =
          (crop.width * imageUploaded.naturalWidth) / 100;
        const croppedHeightPixel =
          (crop.height * imageUploaded.naturalHeight) / 100;

        const xDisplacementPixel = (crop.x * imageUploaded.naturalWidth) / 100;
        const yDisplacementPixel = (crop.y * imageUploaded.naturalHeight) / 100;

        canvas.width = croppedWidthPixel;
        canvas.height = croppedHeightPixel;

        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingQuality = 'high';
        ctx.save();

        ctx.drawImage(
          imageUploaded,
          xDisplacementPixel,
          yDisplacementPixel,
          croppedWidthPixel,
          croppedHeightPixel,
          0,
          0,
          croppedWidthPixel,
          croppedHeightPixel
        );

        const lastDotIndex = file?.name.lastIndexOf('.');
        const fileNameWithoutExtension =
          file?.name.substring(0, lastDotIndex) || 'image-by-camera';

        canvas.toBlob(blob => {
          resolve(
            new File([blob], `${fileNameWithoutExtension}.png`, {
              type: 'image/png',
            })
          );
        }, 'image/png');
      });
    },
    [file]
  );

  return {
    getCroppedImageFile,
  };
}
