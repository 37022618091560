import React, { useCallback, useMemo } from 'react';

import { NoAccessPaymentRecoverDialog } from 'modules/campaigns/recover-subscriber/payment-failed-recover/dialogs';
import {
  useRAQueryImmutable,
  useRAPostRequest,
} from 'modules/shared/hooks/requests';
import { useUTM } from 'modules/shared/hooks';

function usePaymentFailedRecoverCampaign() {
  const { data: paymentFailedRecoverData, loading } = useRAQueryImmutable(
    '/campaigns/payment_failed_recover'
  );
  const { getUTMsByLocation } = useUTM();

  const retryPaymentReq = useRAPostRequest('/campaigns/recover_subscriber');

  const campaignDataLoaded = useCallback(() => {
    return !loading;
  }, [loading]);

  const pricings = useMemo(() => {
    return paymentFailedRecoverData?.pricings;
  }, [paymentFailedRecoverData]);

  const hasPaymentFailedToRecover = useCallback(() => {
    return !!paymentFailedRecoverData;
  }, [paymentFailedRecoverData]);

  const getCard = useCallback(() => {
    return paymentFailedRecoverData?.card;
  }, [paymentFailedRecoverData]);

  const hasPaymentFailedRecoverQueryParams = useCallback(location => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);

    return !!searchParams.get('falha_de_pagamento');
  }, []);

  const openPaymentFailedRecoverDialogAutomaticallyByUrl = useCallback(
    location => {
      const isEligibleToPaymentFailedRecover = hasPaymentFailedToRecover();
      return (
        hasPaymentFailedRecoverQueryParams(location) &&
        isEligibleToPaymentFailedRecover
      );
    },
    [hasPaymentFailedToRecover, hasPaymentFailedRecoverQueryParams]
  );

  const getPaymentFailedRecoverDialogByUrl = useCallback(location => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);

    const utmSourceQueryParam =
      searchParams.get('utm_source') || searchParams.get('amp;utm_source');

    return <NoAccessPaymentRecoverDialog source={utmSourceQueryParam} />;
  }, []);

  const retryPayment = useCallback(
    async (pricing, creditCard, cardId) => {
      try {
        await retryPaymentReq({
          cardId,
          creditCard,
          planPricingId: pricing.id,
          type: 'payment_failed',
          paymentMethod: 'credit_card',
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    [retryPaymentReq]
  );

  const defaultAmplitudeEventAttributes = useMemo(() => {
    return getUTMsByLocation(window.location);
  }, [getUTMsByLocation]);

  return {
    pricings,
    hasPaymentFailedToRecover,
    campaignDataLoaded,
    getCard,
    retryPayment,
    openPaymentFailedRecoverDialogAutomaticallyByUrl,
    getPaymentFailedRecoverDialogByUrl,
    hasPaymentFailedRecoverQueryParams,
    defaultAmplitudeEventAttributes,
  };
}

export { usePaymentFailedRecoverCampaign };
