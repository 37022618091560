import React, { createContext, useState, useCallback, useContext } from 'react';
import { BaseDialog } from 'modules/shared/dialogs';

const DialogContext = createContext();

const DialogProvider = props => {
  const [dialog, setDialog] = useState(null);
  const [globalDialog, setGlobalDialog] = useState(null);

  const unsetDialog = useCallback(() => {
    setDialog(null);
    setGlobalDialog(null);
  }, []);

  return (
    <DialogContext.Provider
      value={{ setDialog, setGlobalDialog, unsetDialog }}
      {...props}
    >
      {props.children}
      {globalDialog && <BaseDialog dialog={globalDialog} />}
      {!globalDialog && dialog && <BaseDialog dialog={dialog} />}
    </DialogContext.Provider>
  );
};

const useDialogContext = () => {
  const context = useContext(DialogContext);
  if (!context)
    throw new Error('useDialog must be used within a DialogProvider');
  return context;
};

export { DialogContext, useDialogContext };
export default DialogProvider;
