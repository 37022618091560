import { execAPICall } from './requests';
import { sortByScore } from './utils';

export default class ElasticSearchSuggestions {
  static adjustTheories(data) {
    let maxScore = data.hits.max_score;

    const ret = data.hits.hits
      .filter(it => it._index === 'ra_theories')
      .map(it => ({
        term: it._source.subject.topic.name,
        score: it._score / maxScore,
        theme: it._source.name,
      }));

    return ret;
  }

  static async getTheoriesSuggestions(term) {
    // ra_theories
    let query = {
      query: {
        match_phrase_prefix: {
          'subject.topic.name': term,
        },
      },
      _source: ['subject.topic.name', 'name'],
    };

    let data = await execAPICall(query, 'ra_theories');
    return this.adjustTheories(data);
  }

  static adjustExercises(data) {
    let maxScore = data.hits.max_score;

    const ret = data.hits.hits
      .filter(it => it._index === 'ra_exercises')
      .map(it => {
        const maths = it._source.subject.chapter.topic.exercise.lightBodyMaths;

        let lb = it._source.subject.chapter.topic.exercise.lightBody.substring(
          0,
          60
        );

        for (let j = 0; j < maths.length; j++) {
          lb = lb.replace(`_m${j + 1}_`, maths[j]);
        }

        return {
          term: lb,
          score: it._score / maxScore,
          theme: it._source.name,
        };
      });

    return ret;
  }

  static async getExercisesSuggestions(term) {
    // ra_exercises
    let query = {
      query: {
        match_phrase_prefix: {
          'subject.chapter.topic.exercise.lightBody': term,
        },
      },
      _source: [
        'subject.chapter.topic.exercise.lightBody',
        'subject.chapter.topic.exercise.lightBodyMaths',
        'name',
      ],
    };

    let data = await execAPICall(query, 'ra_exercises');

    return this.adjustExercises(data);
  }

  static adjustBookExercises(data) {
    let maxScore = data.hits.max_score;

    const ret = data.hits.hits
      .filter(it => it._index === 'ra_book_exercises')
      .map(it => {
        const maths =
          it._source.book.edition.chapter.section.exercise.lightBodyMaths;
        let lb = it._source.book.edition.chapter.section.exercise.lightBody.substring(
          0,
          60
        );

        for (let key in maths) {
          lb = lb.replace(`_m${key}_`, maths[key - 1]);
        }

        return {
          term: lb,
          score: it._score / maxScore,
          theme: it._source.name,
        };
      });

    return ret;
  }

  static async getBookExercisesSuggestions(term) {
    // ra_book_exercises
    let query = {
      query: {
        match_phrase_prefix: {
          'book.edition.chapter.section.exercise.lightBody': term,
        },
      },
      _source: [
        'book.edition.chapter.section.exercise.lightBody',
        'book.edition.chapter.section.exercise.lightBodyMaths',
        'name',
      ],
    };

    let data = await execAPICall(query, 'ra_book_exercises');

    return this.adjustBookExercises(data);
  }

  static async getAllSuggestions(term) {
    // ra_theories
    let query = {
      query: {
        bool: {
          should: [
            {
              match_phrase_prefix: {
                'subject.topic.name': term,
              },
            },
            {
              match_phrase_prefix: {
                'subject.chapter.topic.exercise.lightBody': term,
              },
            },
            {
              match_phrase_prefix: {
                'book.edition.chapter.section.exercise.lightBody': term,
              },
            },
          ],
        },
      },
      size: 100,
      _source: [
        'subject.topic.name',
        'subject.chapter.topic.exercise.lightBody',
        'subject.chapter.topic.exercise.lightBodyMaths',
        'name',
        'book.edition.chapter.section.exercise.lightBody',
        'book.edition.chapter.section.exercise.lightBodyMaths',
      ],
    };

    let data = await execAPICall(
      query,
      'ra_book_exercises,ra_theories,ra_exercises'
    );

    return [
      ...this.adjustBookExercises(data),
      ...this.adjustExercises(data),
      ...this.adjustTheories(data),
    ];
  }

  static async getSuggestions(term, onlyStartsWith = true) {
    const loadTheoriesSuggestions = true;
    const loadExercisesSuggestions = false;

    const loadAllSuggestions =
      loadTheoriesSuggestions && loadExercisesSuggestions;

    let ret = [];
    if (loadAllSuggestions) {
      ret = await this.getAllSuggestions(term);
    } else {
      ret = await this.getTheoriesSuggestions(term);
    }

    const n = suggestion =>
      suggestion
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

    if (onlyStartsWith) {
      ret = ret.filter(it => n(it.term).startsWith(n(term)));
    }

    // sort ret by score
    ret = sortByScore(ret);

    const newRet = [];
    ret.map(it => {
      if (!newRet.find(item => item.term === it.term)) newRet.push(it);
    });

    return newRet.slice(0, 5);
  }
}
