import { useCallback } from 'react';

const REACT_URL = process.env.REACT_APP_REACT_URL;

export default function useWorkspaceVideo() {
  const getVideosToRender = useCallback((videos, contentAccess) => {
    if (!videos || videos.length === 0) return [];
    if (!contentAccess) return [];

    const vimeoVideos = videos.filter(video => video.provider === 'vimeo');
    if (vimeoVideos.length > 0) return vimeoVideos;

    const youtubeVideos = videos.filter(video => video.provider === 'youtube');
    return youtubeVideos;
  }, []);

  const getCurrentContentVideoIds = useCallback(videos => {
    if (!videos) return null;
    if (videos.length === 1) return videos[0].providerId;

    let IdsConcatenated = '';

    videos.forEach(v => {
      IdsConcatenated += `${v.providerId}.`;
    });

    return IdsConcatenated.slice(0, IdsConcatenated.length - 1);
  }, []);

  const getVideoUrl = useCallback(videoData => {
    const { providerId, provider } = videoData;
    let url = `https://www.youtube.com/embed/${providerId}?autoplay=0&showinfo=0&origin=${REACT_URL}&enablejsapi=1&version=3&rel=0`;

    if (provider === 'vimeo')
      url = `https://player.vimeo.com/video/${providerId}`;
    return url;
  }, []);

  const getVideoThumbSrc = useCallback(videos => {
    if (videos.length === 0) return;

    const { provider, providerId } = videos[0];
    if (provider === 'youtube')
      return `https://i.ytimg.com/vi/${providerId}/hqdefault.jpg`;
    else {
      return null;
    }
  }, []);

  const getDurationInMinutes = useCallback(duration => {
    return Math.floor(duration / 60);
  }, []);

  return {
    getVideosToRender,
    getVideoUrl,
    getVideoThumbSrc,
    getDurationInMinutes,
    getCurrentContentVideoIds,
  };
}
