import React, { useCallback } from 'react';
import { styled } from 'styles';
import { MdFileDownloadOff, MdFileDownload } from 'react-icons/md';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { media } from 'styles';
export default function DownloadableItem({
  name,
  link,
  blocked,
  color,
  lightColor,
  updated,
  displayName,
}) {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const handleDownload = useCallback(link => {
    const anchor = document.createElement('a');
    anchor.href = link;
    anchor.download = 'meu-arquivo.pdf'; // Nome do arquivo a ser baixado
    anchor.style.display = 'none';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }, []);

  const handleFileClick = useCallback(() => {
    trackEvent('Pedir Telefone: Clicou botão download', {
      ...getUTMsByLocation(window.location),
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Conteudo: name,
      bloqueado: blocked && !updated ? 'Sim' : 'Não',
    });
    if (blocked && !updated) {
      alert('Atualize seu cadastro para garantir esse conteúdo');
      return;
    }
    handleDownload(link);
  }, [
    blocked,
    link,
    updated,
    getUTMsByLocation,
    name,
    trackEvent,
    handleDownload,
  ]);

  return (
    <Card
      color={color}
      lightColor={lightColor}
      onClick={() => handleFileClick(link)}
      className={`${blocked && !updated ? 'blocked' : 'free'}`}
    >
      {blocked && !updated ? (
        <MdFileDownloadOff className="icon" />
      ) : (
        <MdFileDownload color={color} className="icon" />
      )}

      <FileName>{displayName}</FileName>
    </Card>
  );
}

const Card = styled.div`
  &:hover {
    cursor: pointer;
  }
  user-select: none;
  display: flex;
  padding: 10px 20px;
  border-radius: 12px;
  border: 2px solid #d4d4d4;
  box-sizing: border-box;
  min-width: 145px;
  margin: 10px 0;
  .icon {
    font-size: 1.6em;
    margin-top: auto;
  }
  &.free {
    &:hover {
      background-color: ${({ lightColor }) => lightColor};
    }
    border-color: ${({ color }) => color};
    .icon {
      color: ${({ color }) => color};
    }
  }
`;

const FileName = styled.p`
  font-size: 1.3em;
  margin-left: 5px;
`;
