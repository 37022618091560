import React from 'react';
import { styled } from 'styles';

export function ProfileTag({ size, children }) {
  if (!children) return null;
  return <ProfileTagContainer size={size}>{children}</ProfileTagContainer>;
}

export const ProfileTagContainer = styled.div`
  margin: 0 10px 5px 0;
  font-weight: bold;
  color: #a2a2a2;
  border: 1px solid #dddddd;
  border-radius: 20px;
  padding: ${({ size }) => (size === 'small' ? '4px 9px' : '7px 12px')};
  font-size: ${({ size }) => (size === 'small' ? '0.7em' : '0.8em')};
  text-align: center;
`;

export const ProfileInfo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: default;
`;

export const QuestionImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
  overflow: auto;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
