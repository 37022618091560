import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Drawer } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { styled } from 'styles';

export function ShareDrawer({ open, onClose }) {
  const textAreaRef = useRef(null);
  const [copyToClipboardClicked, setCopyToClipboardClicked] = useState(false);

  const addUtmToCurrentUrl = useCallback(() => {
    let url = window.location.href;
    const utm_source = 'site_navegacao';
    const utm_medium = 'botao_compartilhar';
    const utm_content = 'pag_lista_enunciado';

    if (url.includes('?')) {
      url = `${url}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_content=${utm_content}`;
    } else {
      url = `${url}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_content=${utm_content}`;
    }

    return url;
  }, []);

  const shareMessageEncoded = useCallback(
    utm_source => {
      const shareUrl = addUtmToCurrentUrl();

      return encodeURIComponent(
        `Se liga nas novas resoluções passo a passo que o Responde Aí fez.\n\nEles personalizaram exatamente a lista do nosso professor e colocaram várias informações a mais sobre as questões pra ajudar nessa reta final de estudo.\n\n${shareUrl}`
      );
    },
    [addUtmToCurrentUrl]
  );

  const copyToClipboard = () => {
    if (textAreaRef.current) {
      textAreaRef.current?.select();
      document.execCommand('copy');

      setCopyToClipboardClicked(true);
      setTimeout(() => setCopyToClipboardClicked(false), 3000);
    }
  };

  const clipboardText = useMemo(() => {
    return addUtmToCurrentUrl();
  }, [addUtmToCurrentUrl]);

  const onWppShareClick = useCallback(() => {
    window.open(
      `https://api.whatsapp.com/send?text=${shareMessageEncoded('whatsapp')}`,
      '_blank'
    );
  }, [shareMessageEncoded]);

  const onTelegramShareClick = useCallback(() => {
    window.open(
      `https://t.me/share/url?url=${encodeURIComponent(
        addUtmToCurrentUrl()
      )}&text=${shareMessageEncoded('telegram')}`,
      '_blank'
    );
  }, [addUtmToCurrentUrl, shareMessageEncoded]);

  return (
    <CustomDrawer anchor="right" open={open} onClose={onClose}>
      <Container>
        <Title>Compartilhar essa lista</Title>

        <Option>Link de acesso</Option>
        <CopyLinkButton onClick={copyToClipboard}>
          {copyToClipboardClicked ? 'Link copiado com sucesso' : 'Copiar link'}
        </CopyLinkButton>

        <Option>Compartilhar nas redes sociais {'>'}</Option>
        <SocialMediaButton
          color="#25D366"
          onClick={onWppShareClick}
          style={{ marginTop: 0 }}
        >
          <SocialMediaIcon icon={faWhatsapp} /> Whatsapp
        </SocialMediaButton>

        <SocialMediaButton color="#0088cc" onClick={onTelegramShareClick}>
          <SocialMediaIcon icon={faTelegram} /> Telegram
        </SocialMediaButton>
      </Container>

      <textarea
        style={{ position: 'absolute', left: '-9999px' }}
        ref={textAreaRef}
        value={clipboardText}
        readOnly
      />
    </CustomDrawer>
  );
}

const CustomDrawer = styled(Drawer)`
  .MuiPaper-root {
    max-width: 90%;
  }
`;

const Container = styled.div`
  position: relative;
  width: 400px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

const Title = styled.h1`
  font-size: 1.5em;
`;

const Option = styled.p``;

const shareButtonStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 12px;
  border-radius: 10px;
  font-size: 1.3em;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
`;

const CopyLinkButton = styled.div`
  ${shareButtonStyles}
  background-color: #fbc531;
  margin-bottom: 20px;
`;

const SocialMediaButton = styled.div`
  ${shareButtonStyles}
  background-color: ${({ color }) => color};
  margin: 5px 0;
`;

const SocialMediaIcon = styled(FontAwesomeIcon)`
  font-size: 1.2em;
  margin-right: 10px;
`;
