import { useMemo } from 'react';

import {
  useWorkspaceExercise,
  useWorkspaceVideo,
} from 'modules/workspace/hooks/shared';
import {
  CONTENT_FEEDBACK_FEELINGS_EXERCISE_TEXT,
  CONTENT_FEEDBACK_FEELINGS_EXERCISE_VIDEO,
} from 'modules/workspace/consts/content-feedback-grades';
import useWorkspaceListExercisePermission from './useWorkspaceListExercisePermission';
import { useWorkspaceListExerciseContext } from 'modules/workspace/providers/WorkspaceListExerciseProvider';
import { useUserContext } from 'providers/UserProvider';

export default function useWorkspaceListExerciseContentFeedback() {
  const { currentContentAccess } = useWorkspaceListExercisePermission();
  const {
    currentContent,
    currentContentFormat,
    currentList,
  } = useWorkspaceListExerciseContext();
  const { getCurrentContentVideoIds, getVideosToRender } = useWorkspaceVideo();
  const { getExerciseIndexFromExercises } = useWorkspaceExercise();
  const { user } = useUserContext();

  const showContentFeedback = useMemo(() => {
    return !!user;
  }, [user]);

  const feedbackContentType = useMemo(() => 'exercicio-lista', []);

  const feedbackContentId = useMemo(() => {
    return parseInt(currentContent?.id);
  }, [currentContent]);

  const feedbackContentName = useMemo(() => {
    const exerciseIndex = getExerciseIndexFromExercises(
      currentContent,
      currentList?.listExercises
    );

    const exerciseName = `(${currentContent?.id}) - E${exerciseIndex + 1}`;
    const exerciseMetadata = currentContent?.metadata;

    return `Questão: ${exerciseMetadata} - ${exerciseName}`;
  }, [getExerciseIndexFromExercises, currentContent, currentList]);

  const feedbackContentFormat = useMemo(() => {
    return currentContentFormat;
  }, [currentContentFormat]);

  const feedbackMediaProviderId = useMemo(() => {
    if (currentContentFormat === 'video') {
      const videos = getVideosToRender(
        currentContent?.videos,
        currentContentAccess
      );
      return getCurrentContentVideoIds(videos);
    }
  }, [
    currentContentFormat,
    currentContent,
    currentContentAccess,
    getCurrentContentVideoIds,
    getVideosToRender,
  ]);

  const feedbackFeelings = useMemo(() => {
    if (currentContentFormat === 'text')
      return CONTENT_FEEDBACK_FEELINGS_EXERCISE_TEXT;
    if (currentContentFormat === 'video')
      return CONTENT_FEEDBACK_FEELINGS_EXERCISE_VIDEO;
  }, [currentContentFormat]);

  const feedbackHeaderText = useMemo(() => {
    if (currentContentFormat === 'text') return 'E aí, este texto te ajudou?';

    return 'E aí, este video te ajudou?';
  }, [currentContentFormat]);

  return {
    feedbackContentType,
    feedbackContentId,
    feedbackContentName,
    feedbackContentFormat,
    feedbackMediaProviderId,
    feedbackFeelings,
    feedbackHeaderText,
    showContentFeedback,
  };
}
