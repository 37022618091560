import { useTracker, useUTM } from 'modules/shared/hooks';
import { useCallback, useMemo } from 'react';
import { media } from 'styles';

export function useCheckoutDialogTracker(extraEventsProps = {}) {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const utms = useMemo(() => {
    return getUTMsByLocation(window.location);
  }, [getUTMsByLocation]);

  const defaultProperties = useMemo(() => {
    return {
      ...utms,
      Versão: 1,
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      ...extraEventsProps,
    };
  }, [extraEventsProps, utms]);

  const trackViewDialogNoAccess = useCallback(
    origin => {
      trackEvent('Checkout Dialog: Visualizou Planos', {
        ...defaultProperties,
        Origem: origin,
      });
    },
    [defaultProperties, trackEvent]
  );

  const clickPlanCard = useCallback(
    plan => {
      trackEvent('Checkout Dialog: Clicou Plano', {
        ...defaultProperties,
        Plano: plan,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackViewCheckoutForm = useCallback(() => {
    trackEvent('Checkout Dialog: Visualizou Formulário Cartão ', {
      ...defaultProperties,
    });
  }, [defaultProperties, trackEvent]);

  const trackSentPayment = useCallback(() => {
    trackEvent('Checkout Dialog: Enviou Pagamento', {
      ...defaultProperties,
    });
  }, [defaultProperties, trackEvent]);

  const trackSuccessDialogPayment = useCallback(() => {
    trackEvent('Checkout Dialog: Visualizou sucesso de pagamento', {
      ...defaultProperties,
    });
  }, [defaultProperties, trackEvent]);

  const trackFailedPayment = useCallback(
    error => {
      trackEvent('Checkout Dialog: Falha Enviou Pagamento', {
        ...defaultProperties,
        Erro: error,
      });
    },
    [defaultProperties, trackEvent]
  );

  return {
    trackViewDialogNoAccess,
    clickPlanCard,
    trackViewCheckoutForm,
    trackSentPayment,
    trackFailedPayment,
    trackSuccessDialogPayment,
  };
}
