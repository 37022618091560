import styled from 'styled-components';
import { colors } from 'styles';
import { useEscClick, useToast } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import React, { useState, useRef } from 'react';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { MdClose } from 'react-icons/md';

export default function ForumReportInputDialog({
  contentType,
  data,
  trackReport,
}) {
  const ref = useRef(null);
  const { unsetDialog } = useDialogContext();

  useEscClick(ref, unsetDialog);

  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const { errorToast, successToast } = useToast();

  const reportQuestionRequestReq = useRAPostRequest(
    `/ra_forum/questions/${data?.id}/report`
  );

  const reportAnswerRequestReq = useRAPostRequest(
    `/ra_forum/answers/${data?.id}/report`
  );

  async function submit() {
    try {
      if (!reason) {
        errorToast('Informe o motivo do report!');
        return;
      }
      setLoading(true);

      if (contentType === 'question') {
        await reportQuestionRequestReq({
          reason,
        });
      } else {
        await reportAnswerRequestReq({
          reason,
        });
      }
      trackReport();

      successToast('Conteúdo reportado com sucesso!');

      unsetDialog();
    } catch (e) {
      console.log('error', e);
      errorToast('Erro ao reportar resposta!');
    } finally {
      setLoading(false);
    }

    setLoading(false);
  }

  return (
    <ExternalContainer ref={ref}>
      <Container>
        <MdClose className="close-icon" onClick={unsetDialog} />

        <p>Qual o motivo da denúncia?</p>
        <FeedbackContent
          value={reason}
          onChange={e => setReason(e.target.value)}
          rows={5}
          placeholder={'Digite sua resposta'}
        />

        <SendFeedbackButton onClick={submit}>
          {loading ? 'Enviando...' : 'Enviar'}
        </SendFeedbackButton>
      </Container>
    </ExternalContainer>
  );
}

const ExternalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;
const Container = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: rgb(255, 255, 255);
  font-family: 'Lato', sans-serif;

  width: 300px;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-top: 10px solid ${colors.ErrorReportRed};
  z-index: 10;
  padding-top: 25px;
  position: relative;
  p {
    width: 100%;
    text-align: start;
    font-weight: bold;
    padding: 5px;
    color: #808080;
  }

  .close-icon {
    position: absolute;
    top: 6px;
    right: 6px;
    font-size: 1.2rem;
    color: #b7b7b7;
    cursor: pointer;
    z-index: 3;

    &:hover {
      transform: scale(1.3);
      color: #555;
      transition: color 180ms ease-in-out;
      transition: transform 180ms ease-in-out;
    }
  }
`;

const FeedbackContent = styled.textarea`
  font-family: 'Lato', sans-serif;
  width: 100%;
  background: #eee;
  border-radius: 8px;
  resize: vertical;
  padding: 8px;
  color: #888;
  margin-top: 3px;
  margin-bottom: 0px;
  height: 110px;
  resize: vertical;
  font-size: 15px;
  border: 1px solid #a2a2a2;
  font-weight: bold;
`;

const SendFeedbackButton = styled.button`
  font-family: 'Lato', sans-serif;
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 46px;
  line-height: 46px;
  border: 1px solid ${colors.ErrorReportRed};
  border-radius: 30px;
  font-size: 0.9em;
  font-weight: bold;
  color: ${colors.ErrorReportRed};
  text-align: center;
  padding: 0 20px;
  margin: 20px 0 2px 0;
  min-width: 150px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.ErrorReportRed};
    color: white;
  }
`;
