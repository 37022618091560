import React, { useCallback } from 'react';
import { faMoneyBillWaveAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { NoAccessPaymentRecoverDialog } from 'modules/campaigns/recover-subscriber';

export default function PaymentFailedRecoverButton() {
  const { setDialog } = useDialogContext();

  const handleRecoverPress = useCallback(() => {
    setDialog(<NoAccessPaymentRecoverDialog source="minha_conta" />);
  }, [setDialog]);

  return (
    <Container onClick={handleRecoverPress}>
      <button onClick={handleRecoverPress}>
        <Icon icon={faMoneyBillWaveAlt} />
        Recuperar acesso
      </button>
    </Container>
  );
}

const Container = styled.div`
  margin: 10px 0;

  button {
    background-color: #eee;
    font-size: 1em;
    padding: 5px 15px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    svg {
      margin-right: 5px;
    }
  }

  p {
    margin-top: 5px;
    font-size: 0.8em;
    font-weight: bold;
  }
`;
