import React, { useCallback } from 'react';
import { DesktopBannerMaker } from '../shared/banners';
import { useBannerContext } from 'providers/BannerProvider';
import styled from 'styled-components';

export default function DesktopBooksBanner() {
  const { bannersToShowInfo } = useBannerContext();

  const getHighPriorityFrontBanner = useCallback(() => {
    if (bannersToShowInfo?.desktopFrontBannerName === 'desktopBannerMaker')
      return <CustomDesktopBannerMaker source="Book" />;
  }, [bannersToShowInfo]);

  if (!bannersToShowInfo) return null;

  const banner = getHighPriorityFrontBanner();
  if (!banner) return null;

  return <BooksBannerContainer>{banner}</BooksBannerContainer>;
}

const CustomDesktopBannerMaker = styled(DesktopBannerMaker)`
  border-radius: 20px;
  width: 980px;
  height: 120px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(11, 74, 67, 0.3);
  box-shadow: rgba(11, 74, 67, 0.7) 0px 0px 10px;
`;

const BooksBannerContainer = styled.div`
  position: relative;
  height: ${({ height }) => (height ? height : '120px')};
  top: -65px;
  margin-bottom: -50px;
`;
