import React from 'react';
import { styled, colors } from 'styles';

export default function CardInfoField({ fieldId, label, children, className }) {
  return (
    <Container className={className}>
      <label htmlFor={fieldId}>{label}</label>
      <div id={fieldId}>{children}</div>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 16px;

  label {
    font-size: 0.8em;
    color: ${colors.mediumGray};
  }

  div {
    word-break: break-word;
    margin-top: 2px;
    font-size: 1em;
    color: ${colors.darkGray};
  }
`;
