import React from 'react';
import { styled, media } from 'styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

export default function BackButton({ to = '' }) {
  const history = useHistory();
  return (
    <Container
      onClick={() => {
        if (to) {
          history.push(to);
          return;
        }

        history.goBack();
      }}
    >
      <Button>
        <IconContainer>
          <Icon icon={faArrowLeft} />
        </IconContainer>
        <span>Voltar</span>
      </Button>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  height: 40px;
  z-index: 10;
  width: fit-content;
`;

const Button = styled.button`
  transition: opacity 150ms ease-in-out;
  display: block;
  height: 100%;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0.85;
  margin-left: 15px;
  font-size: 1em;
  &:hover {
    opacity: 1;
  }

  @media ${media.desktop} {
    font-size: 1.2em;
  }
`;

const IconContainer = styled.span`
  display: inline-block;
  margin-right: 8px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 10px 15px;
  text-align: center;

  @media ${media.mobile} {
    padding: 5px 10px;
  }
`;

const Icon = styled(FontAwesomeIcon)``;
