import { useCallback } from 'react';

import { useGuide } from 'modules/guides/hooks';
import { useUserContext } from 'providers/UserProvider';
import { useTracker, useFlag } from 'modules/shared/hooks';
import { useRAPostRequest } from 'modules/shared/hooks/requests';

export default function useSyllabusFeedback() {
  const { isDiscipline, isStudyTrack } = useGuide();
  const { user } = useUserContext();
  const { trackEvent } = useTracker();
  const { saveFlag } = useFlag();

  const sendSyllabusFeedbackReq = useRAPostRequest(
    'syllabus_feedbacks/send_feedback'
  );

  const hasFlag = useCallback((flags, flagName, contentId) => {
    if (!flags) return;

    return flags.find(
      flag =>
        flag.name === flagName &&
        flag.contentType === 'guia' &&
        flag.contentId == contentId
    );
  }, []);

  const hasViewAnyChapterFlag = useCallback((flags, guideId) => {
    if (!flags) return;

    return flags.find(
      flag =>
        flag.name === 'check' &&
        flag.contentType === 'capitulo' &&
        parseInt(flag.guideId) === parseInt(guideId)
    );
  }, []);

  const eligibleToShowSyllabusFeedback = useCallback(
    (guidableType, guidableId, flags) => {
      const validGuidableType =
        isDiscipline(guidableType) || isStudyTrack(guidableType);

      const hasViewedGuideChapter = !!hasViewAnyChapterFlag(flags, guidableId);

      return validGuidableType && hasViewedGuideChapter;
    },
    [isDiscipline, isStudyTrack, hasViewAnyChapterFlag]
  );

  const showTopSyllabusFeedback = useCallback(
    (guidableType, guidableId, flags) => {
      const eligible = eligibleToShowSyllabusFeedback(
        guidableType,
        guidableId,
        flags
      );

      const hasAlreadySendFeedback = !!hasFlag(
        flags,
        'sent_guide_syllabus_feedback',
        guidableId
      );

      return eligible && !hasAlreadySendFeedback;
    },
    [hasFlag, eligibleToShowSyllabusFeedback]
  );

  const showBottomSyllabusFeedback = useCallback(
    (guidableType, guidableId, flags) => {
      const eligible = eligibleToShowSyllabusFeedback(
        guidableType,
        guidableId,
        flags
      );

      const hasAlreadySendFeedback = !!hasFlag(
        flags,
        'sent_guide_syllabus_feedback',
        guidableId
      );

      return eligible && hasAlreadySendFeedback;
    },
    [hasFlag, eligibleToShowSyllabusFeedback]
  );

  const getUserCampusName = useCallback(user => {
    if (!user.universityCampus) return 'Sem Campus';

    const campusName = user.universityCampus.name;
    const campusNameAlreadyHasAcronym = campusName.includes(
      user.university.acronym
    );
    return campusNameAlreadyHasAcronym
      ? campusName
      : `${user.university.acronym} - ${campusName}`;
  }, []);

  const getSyllabusFeedbackEventAttributes = useCallback(
    (star, name) => {
      const { university, universityCampus } = user;
      const campusName = getUserCampusName(user);

      return {
        Estrelas: star.id,
        Universidade: university.acronym,
        Campus: campusName,
        Disciplina: universityCampus
          ? `${campusName} - ${name}`
          : `${university.acronym} - ${name}`,
      };
    },
    [getUserCampusName, user]
  );

  const sendSyllabusFeedback = useCallback(
    (star, contentName, text = '') => {
      trackEvent(
        'Feedback: Ementa',
        getSyllabusFeedbackEventAttributes(star, contentName)
      );

      const textToSend = star.id < 5 ? `${star.description} - ${text}` : null;
      return sendSyllabusFeedbackReq({
        stars: star.id,
        text: textToSend,
        contentName: contentName,
      });
    },
    [sendSyllabusFeedbackReq, trackEvent, getSyllabusFeedbackEventAttributes]
  );

  const saveSyllabusFeedbackFlag = useCallback(
    guidableId => {
      return saveFlag(
        'sent_guide_syllabus_feedback',
        'guia',
        guidableId,
        guidableId
      );
    },
    [saveFlag]
  );

  return {
    showTopSyllabusFeedback,
    getSyllabusFeedbackEventAttributes,
    sendSyllabusFeedback,
    saveSyllabusFeedbackFlag,
    showBottomSyllabusFeedback,
  };
}
