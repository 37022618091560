import React, { useEffect, useCallback, useState } from 'react';

import { styled, colors } from 'styles';
import { usePaymentFailedRecoverCampaign } from 'modules/campaigns/recover-subscriber';
import { useFormValidate } from 'modules/shared/hooks';
import { useTracker } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import {
  Container,
  Content,
  Title,
  ButtonsContainer,
  OptionButton,
  PlanInfo,
  InstallmentsInfo,
} from 'modules/campaigns/recover-subscriber/styles';
import { NoAccessPaymentRecoverDialog, RetrySuccessDialog } from '.';
import CreditCardForm from 'modules/shared/components/CreditCardForm';

export default function RetryWithNewCardDialog({ source, pricing }) {
  const [creditCardData, setCreditCardData] = useState(
    INITIAL_CREDIT_CARD_DATA
  );
  const [creditCardDataError, setCreditCardDataError] = useState(
    INITIAL_CREDIT_CARD_DATA
  );
  const [alreadyReceiveFormError, setAlreadyReceiveFormError] = useState(false);
  const [purchaseErrorMessage, setPurchaseErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setDialog, unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const {
    retryPayment,
    defaultAmplitudeEventAttributes,
  } = usePaymentFailedRecoverCampaign();
  const { getCreditCardErrors } = useFormValidate();

  useEffect(() => {
    trackEvent('MVP Falha de Pagamento 2.0: Visualizou Confirmacao', {
      utm_source: source,
      opcao: 'novo cartao',
      plan: pricing.description,
      ...defaultAmplitudeEventAttributes,
    });
  }, [source, trackEvent, pricing, defaultAmplitudeEventAttributes]);

  useEffect(() => {
    if (!alreadyReceiveFormError) return;

    const errors = getCreditCardErrors(creditCardData);
    setCreditCardDataError(errors);
  }, [creditCardData, alreadyReceiveFormError, getCreditCardErrors]);

  const onInputChange = useCallback(
    (key, value) => {
      setCreditCardData({ ...creditCardData, [key]: value });
    },
    [setCreditCardData, creditCardData]
  );

  const onRetryClick = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    const errors = getCreditCardErrors(creditCardData);
    if (errors.number || errors.holderName || errors.expiry || errors.cvv) {
      setCreditCardDataError(errors);
      setLoading(false);
      setAlreadyReceiveFormError(true);
      return;
    }

    const success = await retryPayment(pricing, creditCardData, null);
    if (!success) {
      setLoading(false);
      setPurchaseErrorMessage(
        'Erro ao tentar efetuar a cobrança. Verifique se o cartão tem limites, tente com outro cartão e caso o problema persista, entre em contato com o atendimento'
      );
      setTimeout(() => setPurchaseErrorMessage(null), 8000);
      return;
    }

    setDialog(<RetrySuccessDialog source={source} pricing={pricing} />);
  }, [
    creditCardData,
    getCreditCardErrors,
    loading,
    setLoading,
    source,
    setDialog,
    retryPayment,
    pricing,
  ]);

  return (
    <Container onClick={unsetDialog}>
      <Content onClick={e => e.stopPropagation()}>
        <Title color="#787878">Trocar de cartão</Title>
        <PlanInfo>Seu plano: {pricing.description}</PlanInfo>
        <InstallmentsInfo>
          {pricing.months}x de R${pricing.monthlyPricing.toFixed(2)} (
          {pricing.price.toFixed(2)})
        </InstallmentsInfo>
        <NewCardTitle>Dados do novo cartão</NewCardTitle>
        <CreditCardForm
          creditCardData={creditCardData}
          onInputChange={onInputChange}
          creditCardDataError={creditCardDataError}
        />

        <ButtonsContainer>
          <OptionButton
            onClick={() =>
              setDialog(<NoAccessPaymentRecoverDialog source={source} />)
            }
          >
            Voltar
          </OptionButton>

          <OptionButton onClick={onRetryClick}>
            {loading ? 'Retentando cobrança...' : 'Retentar cobrança'}
          </OptionButton>
        </ButtonsContainer>

        {purchaseErrorMessage && (
          <PurchaseErrorContainer>
            <PurchaseErrorMessage>
              <b>Erro ao tentar efetuar cobrança</b>
              <br />
              {purchaseErrorMessage}
            </PurchaseErrorMessage>
          </PurchaseErrorContainer>
        )}
      </Content>
    </Container>
  );
}

const INITIAL_CREDIT_CARD_DATA = {
  number: '',
  holderName: '',
  expiry: '',
  cvv: '',
};

const NewCardTitle = styled.div`
  font-size: 1.3em;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const PurchaseErrorContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PurchaseErrorMessage = styled.div`
  width: 380px;
  max-width: 95%;
  padding: 20px;
  border-radius: 10px;
  background-color: ${colors.warningRed};
  color: #fff;
  line-height: 1.2;

  b {
    line-height: 2;
    font-size: 1.1em;
  }
`;
