import { useEffect, useCallback } from 'react';

function useEscClick(ref, handler) {
  const executeHandler = useCallback(
    e => {
      if (ref.current && e.key === 'Escape') {
        handler();
        e.stopPropagation();
      }
    },
    [ref, handler]
  );

  useEffect(() => {
    document.addEventListener('keyup', executeHandler);
    return () => {
      document.removeEventListener('keyup', executeHandler);
    };
  }, [executeHandler]);
}

export { useEscClick };
