import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { styled } from 'styles';
import {
  useWorkspaceContentFormat,
  useWorkspaceExercise,
  useWorkspaceVideo,
} from 'modules/workspace/hooks/shared';
import { useDialogContext } from 'providers/DialogProvider';
import { Loading } from 'modules/shared/components/generic';
import { ToggleableComponent } from 'modules/shared/components/generic';
import { ContentFormatToggle } from 'modules/workspace/components/content/shared';
import ExerciseList from './ExerciseList';
import ExerciseStatement from './ExerciseStatement';
import ExerciseShowSolutionAndAnswerToggle from './ExerciseShowSolutionAndAnswerToggle';
import ExerciseSolution from './ExerciseSolution';
import ExerciseAnswer from './ExerciseAnswer';
import ExerciseVideos from './ExerciseVideos';
import WorkspaceNoAccessDialog from 'modules/campaigns/default-trial/dialogs/WorkspaceNoAccessDialog';

export default function ExerciseContent({
  exercises,
  exercisesLimit,
  contentAccess,
  exerciseContentTrackMethods,
  setCurrentContent,
  setCurrentContentFormat,
  getExerciseLink,
  getExerciseName,
}) {
  const [showSolutionAndAnswer, setShowSolutionAndAnswer] = useState(false);
  const { exercise, loadExercise, getExerciseColor } = useWorkspaceExercise();
  const { getVideosToRender } = useWorkspaceVideo();
  const {
    getContentFormat,
    hasManyContentFormats,
  } = useWorkspaceContentFormat();
  const { setDialog } = useDialogContext();

  const solutionComponent = useRef(null);
  const answerComponent = useRef(null);
  const [exerciseFormat, setExerciseFormat] = useState();
  const [videoDuration, setVideoDuration] = useState(0);

  const scrollToSolution = useCallback(solutionComponent => {
    solutionComponent.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (exercise) {
      setCurrentContent(exercise);
    }
  }, [exercise, setCurrentContent, setShowSolutionAndAnswer, scrollToSolution]);

  useEffect(() => {
    if (exerciseFormat) setCurrentContentFormat(exerciseFormat);
  }, [exerciseFormat, setCurrentContentFormat]);

  useEffect(() => {
    loadExercise();
    setShowSolutionAndAnswer(false);
  }, [loadExercise, setShowSolutionAndAnswer]);

  useEffect(() => {
    if (!exercise) return;

    const initialExerciseFormat = getContentFormat(exercise);
    setExerciseFormat(initialExerciseFormat);
  }, [exercise, getContentFormat, setExerciseFormat]);

  const toggleShowSolutionAndAnswer = useCallback(() => {
    if (!contentAccess) {
      setDialog(<WorkspaceNoAccessDialog />);
      return;
    }

    const newShowSolutionAndAnswer = !showSolutionAndAnswer;
    setShowSolutionAndAnswer(newShowSolutionAndAnswer);

    if (newShowSolutionAndAnswer) {
      setTimeout(() => scrollToSolution(solutionComponent), 700);
    }
  }, [
    contentAccess,
    showSolutionAndAnswer,
    setShowSolutionAndAnswer,
    scrollToSolution,
    setDialog,
    solutionComponent,
  ]);

  const toggleableComponentHeigth = useMemo(() => {
    const solutionComponentHeigth =
      solutionComponent.current?.clientHeight || 0;
    const answerComponentHeigth = answerComponent.current?.clientHeight || 0;

    const totalHeigth = solutionComponentHeigth + answerComponentHeigth + 200;

    return `${totalHeigth}px`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    solutionComponent.current?.clientHeight,
    answerComponent.current?.clientHeight,
  ]);

  const exerciseVideos = useMemo(() => {
    return getVideosToRender(exercise?.videos, contentAccess);
  }, [exercise, contentAccess, getVideosToRender]);

  return (
    <Container>
      <ExerciseList
        exercises={exercises}
        exercisesLimit={exercisesLimit}
        getExerciseLink={getExerciseLink}
        getExerciseName={getExerciseName}
      />
      {!exercise && <Loading />}
      {exercise && (
        <>
          {hasManyContentFormats(exercise) && (
            <CustomContentFormatToggle
              currentContentFormat={exerciseFormat}
              setContentFormat={setExerciseFormat}
              color={getExerciseColor()}
              content={exercise}
              videos={exerciseVideos}
              videoDuration={videoDuration}
              trackChangeContentFormatMethod={
                exerciseContentTrackMethods?.trackFormatChange
              }
            />
          )}

          <ExerciseStatement exercise={exercise} />

          {exerciseFormat === 'text' && (
            <>
              <ExerciseShowSolutionAndAnswerToggle
                showSolutionAndAnswer={showSolutionAndAnswer}
                onClick={toggleShowSolutionAndAnswer}
                answerComponent={answerComponent}
              />
              <ToggleableComponent
                open={showSolutionAndAnswer}
                height={toggleableComponentHeigth}
                animationTime="0.5s"
              >
                <ExerciseSolution
                  exercise={exercise}
                  useRef={solutionComponent}
                  contentAccess={contentAccess}
                />
                <ExerciseAnswer
                  exercise={exercise}
                  useRef={answerComponent}
                  contentAccess={contentAccess}
                />
              </ToggleableComponent>
            </>
          )}

          <ExerciseVideos
            contentFormat={exerciseFormat}
            setVideoDuration={setVideoDuration}
            trackFirstPlayVideoMethod={
              exerciseContentTrackMethods?.trackFirstVideoPlay
            }
            videos={exerciseVideos}
          />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding-bottom: 60px;
`;

const CustomContentFormatToggle = styled(ContentFormatToggle)`
  margin-top: 60px;
`;
