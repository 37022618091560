import React, { useCallback, useMemo } from 'react';
import { media, styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker } from 'modules/shared/hooks';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';

export default function MvpTrialBanner({
  origin,
  utmContent,
  styleContainer = {},
  styleTitle = {},
  styleButton = {},
}) {
  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();

  const eventProperties = useMemo(() => {
    return {
      Onde: origin,
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Versão: '2.2',
    };
  }, [origin]);

  const onClickBanner = useCallback(() => {
    trackEvent('MVP Lista: Clicou Banner', eventProperties);

    const utms = {
      utmSource: 'site_upload',
      utmMedium: 'banner',
      utmContent,
    };

    setDialog(<UploadBookListDialog origin={origin} utms={utms} />);
  }, [setDialog, trackEvent, eventProperties, origin, utmContent]);

  const endDateOfTrial = useMemo(() => {
    const now = new Date();
    const isAfterEndDate = now > new Date('2024-09-26T23:59:59');
    return isAfterEndDate ? '29/09' : '26/09';
  }, []);

  const spanTitleCopy = useMemo(() => {
    return media.isDesktop() ? 'DE GRAÇA!' : `DE GRAÇA! ATÉ ${endDateOfTrial}`;
  }, [endDateOfTrial]);

  return (
    <ContainerBanner onClick={onClickBanner} style={styleContainer}>
      {media.isDesktop() && <Strip>{`ATÉ ${endDateOfTrial}`}</Strip>}
      <BannerTitle style={styleTitle}>
        <span>{spanTitleCopy}</span> Resolvemos sua <br />
        lista da faculdade em 45 segundos
      </BannerTitle>
      <BannerButton style={styleButton}>
        Quero minha lista resolvida
      </BannerButton>
    </ContainerBanner>
  );
}

const ContainerBanner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1000px;
  max-width: 90%;
  height: 110px;
  margin: 0 auto;
  cursor: pointer;
  background-color: #ac0091;
  overflow: hidden;
  z-index: 10;

  @media ${media.mobile} {
    height: 70px;
    flex-direction: column;
    margin-top: 10px;
    z-index: 10;
  }
`;

const Strip = styled.div`
  position: absolute;
  top: 9px;
  left: -43px;
  rotate: -22deg;
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #faff00;
  color: #ac0091;
  font-weight: bold;
  font-size: 1.1rem;
  padding-right: 7px;
  padding-top: 3px;
`;

const BannerTitle = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  color: #fff;
  margin-right: 50px;
  text-align: center;

  span {
    color: #faff00;
  }

  @media ${media.mobile} {
    font-size: 0.68rem;
    line-height: 15px;
    margin-right: 0px;
    margin-bottom: 4px;
  }
`;

const BannerButton = styled.div`
  width: 350px;
  height: 50px;
  background-color: #faff00;
  color: #000;
  margin-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;

  font-size: 1.2rem;
  font-weight: bold;

  @media ${media.mobile} {
    width: 122px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;

    font-size: 0.55rem;
  }
`;
