import { useCallback } from 'react';
import Cookies from 'js-cookie';
import { useTracker } from 'modules/shared/hooks';

const COOKIE_PREFIX = 'list_with_statement';
const SENT_FEEDBACK_COOKIE_NAME = `${COOKIE_PREFIX}_sent_feedback`;
const SENT_AI_FEEDBACK_COOKIE_NAME = `${COOKIE_PREFIX}_sent_ai_feedback`;

export const AI_QUESTION_VIEWS_COOKIE_NAME = `${COOKIE_PREFIX}_ai_question_views`;

export function useFeedback() {
  const { trackEvent } = useTracker();

  const setExerciseFeedbackSent = useCallback(exerciseType => {
    if (exerciseType === 'MvpExercise') {
      Cookies.set(SENT_AI_FEEDBACK_COOKIE_NAME, true, { expires: 30 });
      return;
    }

    Cookies.set(SENT_FEEDBACK_COOKIE_NAME, true, { expires: 30 });
  }, []);

  const showAiFeedbackDialog = useCallback(exerciseType => {
    if (exerciseType !== 'MvpExercise') return false;

    const sentAiFeedback = !!Cookies.get(SENT_AI_FEEDBACK_COOKIE_NAME);
    if (sentAiFeedback) return false;

    const views = parseInt(Cookies.get(AI_QUESTION_VIEWS_COOKIE_NAME) || 0);
    return views > 2;
  }, []);

  const incrementAiQuestionViews = useCallback(() => {
    const views = parseInt(Cookies.get(AI_QUESTION_VIEWS_COOKIE_NAME) || 0);
    Cookies.set(AI_QUESTION_VIEWS_COOKIE_NAME, views + 1, { expires: 30 });
  }, []);

  const trackViewFeedback = useCallback(
    ({
      exerciseType,
      exerciseId,
      questionId,
      listId,
      origin,
      disciplineName,
    }) => {
      trackEvent('Lista de Enunciado: Visualizou Feedback de Conteúdo', {
        'ID Questao': questionId,
        'ID Exercicio': exerciseId,
        'ID Lista': listId,
        'Tipo Exercicio': exerciseType,
        'Resolucao por IA?': exerciseType === 'MvpExercise' ? 'Sim' : 'Não',
        'Onde Apareceu': origin,
        Disciplina: disciplineName,
      });
    },
    [trackEvent]
  );

  const trackSendScore = useCallback(
    ({
      exerciseType,
      exerciseId,
      questionId,
      listId,
      origin,
      score,
      disciplineName,
    }) => {
      trackEvent('Lista de Enunciado: Feedback de Conteúdo Nova Nota', {
        'ID Questao': questionId,
        'ID Exercicio': exerciseId,
        'ID Lista': listId,
        'Tipo Exercicio': exerciseType,
        'Resolucao por IA?': exerciseType === 'MvpExercise' ? 'Sim' : 'Não',
        'Onde Apareceu': origin,
        Nota: score,
        Disciplina: disciplineName,
      });
    },
    [trackEvent]
  );

  const trackSendComment = useCallback(
    ({
      exerciseType,
      exerciseId,
      questionId,
      listId,
      origin,
      score,
      comment,
      disciplineName,
    }) => {
      trackEvent('Lista de Enunciado: Feedback de Conteúdo Novo Comentario', {
        'ID Questao': questionId,
        'ID Exercicio': exerciseId,
        'ID Lista': listId,
        'Tipo Exercicio': exerciseType,
        'Resolucao por IA?': exerciseType === 'MvpExercise' ? 'Sim' : 'Não',
        'Onde Apareceu': origin,
        Nota: score,
        Comentario: comment,
        Disciplina: disciplineName,
      });
    },
    [trackEvent]
  );

  const trackViewAiDialogFeedback = useCallback(
    ({ questionId, listId }) => {
      trackEvent('Lista de Enunciado: Visualizou Feedback da IA', {
        'ID Questao': questionId,
        'ID Lista': listId,
      });
    },
    [trackEvent]
  );

  const trackSendAiDialogScore = useCallback(
    ({ questionId, listId, score }) => {
      trackEvent('Lista de Enunciado: Feedback da IA Nova Nota', {
        'ID Questao': questionId,
        'ID Lista': listId,
        Nota: score,
      });
    },
    [trackEvent]
  );

  const trackSendAiDialogComment = useCallback(
    ({ questionId, listId, score, comment }) => {
      trackEvent('Lista de Enunciado: Feedback da IA Novo Comentário', {
        'ID Questao': questionId,
        'ID Lista': listId,
        Nota: score,
        Comentario: comment,
      });
    },
    [trackEvent]
  );

  return {
    setExerciseFeedbackSent,
    showAiFeedbackDialog,
    incrementAiQuestionViews,
    trackViewFeedback,
    trackSendScore,
    trackSendComment,
    trackViewAiDialogFeedback,
    trackSendAiDialogScore,
    trackSendAiDialogComment,
  };
}
