import React, { useState, useRef, useCallback } from 'react';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors, styled, media } from 'styles';
import { ErrorReportInput } from 'modules/feedback/components';
import { useOutsideClick } from 'modules/shared/hooks';

export default function ErrorReportFloatButton({
  data,
  trackFeedbackCallback,
  setErrorReportInputIsOpen = () => {},
}) {
  const ref = useRef(null);
  const [showInput, setShowInput] = useState(false);

  const onErrorReportClick = useCallback(() => {
    if (!showInput) setErrorReportInputIsOpen(true);
    else setErrorReportInputIsOpen(false);
    setShowInput(!showInput);
    trackFeedbackCallback();
  }, [
    trackFeedbackCallback,
    setShowInput,
    showInput,
    setErrorReportInputIsOpen,
  ]);

  useOutsideClick(ref, () => setShowInput(false));

  return (
    <Container ref={ref} data-cy="error-report">
      <IconContainer onClick={onErrorReportClick}>
        <Icon icon={faBullhorn} />
      </IconContainer>
      {showInput && <ErrorReportInput data={data} setShow={setShowInput} />}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  float: right;
  cursor: pointer;
`;

const IconContainer = styled.div`
  border: 2px solid currentColor;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-color: ${colors.ErrorReportRed};

  @media ${media.mobile} {
    width: 30px;
    height: 30px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colors.ErrorReportRed};
  transform: rotate(-25deg);

  @media ${media.mobile} {
    width: 18px;
    height: 18px;
  }
`;
