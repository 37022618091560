import React, { useCallback, useMemo, useState } from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdUploadFile } from 'react-icons/md';

import { styled } from 'styles';
import NewToggleableComponent from 'modules/shared/components/NewToggleableComponent';
import { useDialogContext } from 'providers/DialogProvider';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import ListLink from '../ListLink';

export default function ExamToggle({
  exam,
  lists,
  professor,
  mutateProfessorQuery,
  utmContent,
}) {
  const [open, setOpen] = useState(false);
  const { setDialog } = useDialogContext();

  const listsToShow = useMemo(() => {
    const filteredLists = lists.filter(l => l.exam === exam);
    return filteredLists.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
  }, [exam, lists]);

  const onSendListClick = useCallback(() => {
    setDialog(
      <UploadBookListDialog
        defaultThemeId={professor.teachable.theme?.id}
        defaultTeachable={{
          id: professor.teachable.id,
          type: 'Discipline',
        }}
        defaultProfessor={{
          id: professor.id,
          name: professor.name,
        }}
        defaultExam={exam}
        utms={{
          utmSource: 'site_upload',
          utmMedium: 'botao_enviar_lista_dentro_toggle',
          utmContent,
        }}
      />
    );
  }, [professor, exam, setDialog, utmContent]);

  return (
    <Container>
      <ExamToggleButton onClick={() => setOpen(!open)}>
        <ExamListContainer>
          <p className="bold">{exam}</p>
          <p>
            {`(${listsToShow.length} ${
              lists.length === 1 ? 'lista resolvida' : 'listas resolvidas'
            })`}
          </p>
        </ExamListContainer>
        <AngleIcon icon={open ? faAngleUp : faAngleDown} />
      </ExamToggleButton>
      <NewToggleableComponent open={open}>
        <ListsContent>
          {listsToShow.map((list, index) => (
            <ListLink
              key={list.id}
              list={list}
              professor={professor}
              name={`Lista ${index + 1}`}
              mutateProfessorQuery={mutateProfessorQuery}
              fontSize={14}
              utmContent={utmContent}
            />
          ))}

          {listsToShow.length === 0 && (
            <SendListButton href="#" onClick={onSendListClick}>
              <MdUploadFile />
              <p>Enviar sua lista </p>
            </SendListButton>
          )}
        </ListsContent>
      </NewToggleableComponent>
    </Container>
  );
}

const Container = styled.div`
  width: 90%;
  border: 1px solid #70bfc7;
  border-radius: 5px;
  margin: 8px auto;
`;

const ExamToggleButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1em;
  margin: 5px 10px;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  .book-icon {
    margin-right: 6px;
    font-size: 1.1em;
  }
`;

const ExamListContainer = styled.div`
  margin-left: 4px;

  p {
    color: #515151;
    font-size: 0.9rem;
  }

  .bold {
    margin-top: 3px;
    font-weight: bold;
  }
`;

const AngleIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 9px;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0.7;
  font-size: 2em;
  color: #2e3030;
  font-weight: bold;
`;

const ListsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 13px;
  margin-bottom: 15px;
`;

const SendListButton = styled.a`
  padding: 12px 10px;
  background-color: rgba(112, 192, 199, 0.13);
  margin-bottom: 7px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-left: 10px;
  }

  &:hover {
    background-color: rgba(112, 192, 199, 0.2);
  }
`;
