import { styled } from 'styles';

const ContainerBanner = styled.div`
  background: #f4b039;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 2px;

  p {
    font-weight: bold;
    font-size: 1.1em;
    color: #a74ca3;
    margin-bottom: 5px;
  }

  button {
    background: #a74ca3;
    color: #fff;
    border: none;
    padding: 3px 5px;
    font-size: 1.2em;
    font-weight: bold;
  }
`;

export { ContainerBanner };
