import React from 'react';
import { urlByResult } from './helper';
import ResultItem from './ResultItem';

const ExerciseResult = ({ result, clickedInResult, onlyVideos }) => {
  let topic = result.subject.chapter.topic;
  let exercise = topic.exercise;

  let title = `${exercise.lightBody} | Exercício de ${result.name}`;

  let lightSolution = exercise.lightSolution.filter(it => it)[0];

  if (!lightSolution) return <></>;

  lightSolution = lightSolution.substring(0, 100);

  const lsm = result.subject.chapter.topic.exercise.lightSolutionMaths;

  for (let key2 in lsm[0]) {
    lightSolution = lightSolution.replace(`_m${key2}_`, lsm[key2 - 1]);
    lightSolution = lightSolution.replace(`_m${key2}`, lsm[key2 - 1]);
  }

  const url = urlByResult(result);
  const theoryUrl = `https://www.respondeai.com.br/aprender/topico/${result.subject?.id}/${topic?.id}/teoria/${result.theory?.id}`;

  const breadcrumbsItems = [
    {
      label: result?.name,
      link: `${process.env.REACT_APP_RESPONDEAI_URL}/materias/completas/${result.id}`,
      color: result.color,
    },
    {
      label: result?.subject?.name,
      link: theoryUrl,
    },

    { label: result.subject.chapter.name, link: theoryUrl },
  ];

  return (
    <ResultItem
      hasVideos={topic.exercise.videos.length > 0}
      onlyVideos={onlyVideos}
      resultUrl={url}
      breadcrumbsItems={breadcrumbsItems}
      title={title}
      clickedInResult={clickedInResult}
      content={lightSolution}
    />
  );
};

export default ExerciseResult;
