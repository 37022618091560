import React, { createContext, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useUserContext } from 'providers/UserProvider';

export const WorkspaceListExerciseContext = createContext(null);

const WorkspaceListExerciseProvider = props => {
  const { user } = useUserContext();
  const { theme, contentTitle, contentSubtitle, guide } = props;
  const { listCategoryId, listId } = useParams();
  const [currentContent, setCurrentContent] = useState(null);
  const [currentContentFormat, setCurrentContentFormat] = useState(null);

  const currentTheme = useMemo(() => {
    return theme;
  }, [theme]);

  const allListCategories = useMemo(() => {
    return guide.listCategories;
  }, [guide]);

  const listCategories = useMemo(() => {
    if (!user || !user.university) return allListCategories;

    const listCategoriesByCampus =
      user.universityCampus &&
      allListCategories.filter(
        listCategory =>
          listCategory?.discipline?.universityCampusId ===
          user.universityCampus.id
      );

    const listCategoriesByUniversity =
      user.university &&
      allListCategories.filter(
        listCategory =>
          listCategory?.discipline?.universityId === user.university.id
      );

    if (listCategoriesByCampus?.length > 0) return listCategoriesByCampus;
    return listCategoriesByUniversity.length > 0
      ? listCategoriesByUniversity
      : allListCategories;
  }, [allListCategories, user]);

  const currentListCategory = useMemo(() => {
    return allListCategories.find(
      listCategory => listCategory.id === parseInt(listCategoryId)
    );
  }, [allListCategories, listCategoryId]);

  const currentList = useMemo(() => {
    if (!currentListCategory) return null;

    return currentListCategory.lists.find(list => list.id === parseInt(listId));
  }, [currentListCategory, listId]);

  return (
    <WorkspaceListExerciseContext.Provider
      value={{
        currentTheme,
        currentListCategory,
        currentList,
        currentContent,
        currentContentFormat,
        contentTitle,
        contentSubtitle,
        guide,
        listCategories,
        setCurrentContent,
        setCurrentContentFormat,
      }}
      {...props}
    />
  );
};

export const useWorkspaceListExerciseContext = () =>
  useContext(WorkspaceListExerciseContext);

export default WorkspaceListExerciseProvider;
