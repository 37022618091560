import Cookies from 'js-cookie';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import { useTestAB } from 'modules/shared/hooks';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useBookExercisePageAbTest() {
  const location = useLocation();
  const { getVariantFromTestAB } = useTestAB();
  const { isFromAllowedUniversity } = useMvpBooksEligible();

  const testName = 'book_exercise_page_layout';

  const isEligibleToAbTest = useCallback(() => {
    return isFromAllowedUniversity();
  }, [isFromAllowedUniversity]);

  const isOnTestVersion = useMemo(() => {
    if (!isEligibleToAbTest()) return false;

    const variant = getVariantFromTestAB(testName);
    return variant === 'new_layout';
  }, [isEligibleToAbTest, getVariantFromTestAB]);

  const isInBookExercisePage = useMemo(() => {
    const regex = /^\/materias\/solucionario\/livro\/\d+\/edicao\/\d+\/exercicio\/(-1|\d+)$/; // book exercise page url

    return regex.test(location.pathname);
  }, [location]);

  const shouldChangeLayout = useMemo(() => {
    return isOnTestVersion && isInBookExercisePage;
  }, [isInBookExercisePage, isOnTestVersion]);

  const isFromSEO = useMemo(() => {
    const hasCookie = !!Cookies.get('seo_last_page');

    return hasCookie;
  }, []);

  const shouldShowStatementFromAbTest = useCallback(
    numViewsStatement => {
      if (numViewsStatement > 1) return false;

      return shouldChangeLayout && isFromSEO;
    },
    [isFromSEO, shouldChangeLayout]
  );

  return {
    isEligibleToAbTest,
    isOnTestVersion,
    testName,
    shouldChangeLayout,
    isFromSEO,
    shouldShowStatementFromAbTest,
  };
}
