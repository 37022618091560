import React, { useMemo, useEffect } from 'react';
import useForumRedirectToExerciseErrorReport from 'modules/forum/hooks/useForumRedirectToErrorReport/useForumRedirectToExerciseErrorReport';
import { useDialogContext } from 'providers/DialogProvider';
import ErrorReportRedirectDialog from '../dialogs/ErrorReportRedirectDialog';

export default function ExerciseErrorReportRedirect({
  onReportOrClose,
  sendPost,
  title = '',
  text,
  shouldConfirmRedirectToErrorReport,
  trackCloseErrorReportDialog,
  trackViewErrorReportDialog,
  trackSendErrorReportDialog,
}) {
  const { getContentLocationData } = useForumRedirectToExerciseErrorReport();
  const { setDialog } = useDialogContext();

  const dataToReport = useMemo(() => {
    return getContentLocationData();
  }, [getContentLocationData]);

  useEffect(() => {
    if (shouldConfirmRedirectToErrorReport)
      setDialog(
        <ErrorReportRedirectDialog
          onReportOrClose={onReportOrClose}
          sendPost={sendPost}
          title={title}
          text={text}
          dataToReport={dataToReport}
          trackCloseErrorReportDialog={trackCloseErrorReportDialog}
          trackViewErrorReportDialog={trackViewErrorReportDialog}
          trackSendErrorReportDialog={trackSendErrorReportDialog}
        />
      );
  }, [shouldConfirmRedirectToErrorReport]);

  return <></>;
}
