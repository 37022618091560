import { useHomeProfessorTracker } from 'modules/main/hooks/useHomeProfessorTracker';
import { useToast } from 'modules/shared/hooks';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useDialogContext } from 'providers/DialogProvider';
import React, { useCallback, useState } from 'react';
import { styled, colors } from 'styles';
import { useSWRConfig } from 'swr';

export default function DeleteProfessorDialog({ userProfessor }) {
  const { unsetDialog } = useDialogContext();
  const { sendDeleteRequest } = useContentRestRequest();
  const [loading, setLoading] = useState(false);
  const { mutate } = useSWRConfig();
  const { errorToast, successToast } = useToast();
  const { trackDeleteUserProfessorSuccess } = useHomeProfessorTracker();

  const deleteProfessor = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await sendDeleteRequest(
        `/mvp/user-professor/${userProfessor.id}`
      );

      setLoading(false);
      if (data.success) {
        successToast('Disciplina removida com sucesso!');
        trackDeleteUserProfessorSuccess(userProfessor);
        mutate(
          `${process.env.REACT_APP_RA_CONTENT_URL}/api/v2/mvp/user-professors`
        );
        unsetDialog();
        return;
      }

      errorToast(
        'Erro ao tentar remover a disciplina, contate o suporte ou tente novamente mais tarde'
      );
      unsetDialog();
    } catch (e) {
      errorToast(
        'Erro ao tentar remover a disciplina, contate o suporte ou tente novamente mais tarde'
      );
      unsetDialog();
    }
  }, [
    sendDeleteRequest,
    userProfessor,
    errorToast,
    unsetDialog,
    successToast,
    trackDeleteUserProfessorSuccess,
    mutate,
  ]);
  return (
    <Container>
      <Content onClick={e => e.stopPropagation()}>
        <Title>
          Você tem certeza que gostaria de remover essa turma do seu perfil ?
        </Title>
        <ButtonsContainer>
          <Button className="red" onClick={unsetDialog}>
            Não
          </Button>
          <Button className="green" onClick={() => deleteProfessor()}>
            {loading ? 'Removendo...' : 'Sim'}
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 1.2em;
  margin: 15px 0;
`;

export const Content = styled.div`
  width: 680px;
  max-width: 95%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
  border-top: 10px solid ${colors.warningRed};
  padding: 20px;
  margin: auto;
`;

const Button = styled.div`
  border-radius: 15px;
  width: 146px;
  padding: 10px 0;
  font-weight: bold;
  font-size: 18px;
  background-color: ${colors.darkGreen};
  margin: 0 15px;
  user-select: none;
  cursor: pointer;
  &.green {
    background-color: white;
    border: 2px solid ${colors.successGreen};
    color: ${colors.darkGreen};
    &:hover {
      text-decoration: underline;
      color: white;
      background-color: ${colors.semiannualGreen};
      opacity: 0.8;
    }
  }
  &.red {
    background-color: ${colors.warningRed};
    color: white;
    &:hover {
      text-decoration: underline;
      color: ${colors.warningRed};
      background-color: white;
      opacity: 0.8;
      border: 2px solid ${colors.warningRed};
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;
