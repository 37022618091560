import React from 'react';
import { styled, colors } from 'styles';
import CardInfoField from './CardInfoField';
import CardTitle from './CardTitle';

export default function UserFreePlan() {
  const RESPONDEAI_URL = process.env.REACT_APP_RESPONDEAI_URL;

  return (
    <Container>
      <CardTitle title="Assinatura" upper />

      <Content>
        <Info fieldId="plan" label="Plano">
          Gratuito
        </Info>

        <SubscribeButton href={`${RESPONDEAI_URL}/planos`}>
          Assinar
        </SubscribeButton>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 90%;
  max-width: 720px;
  margin-bottom: 50px;

  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 15px -2px rgba(51, 51, 51, 0.5);
`;

const Content = styled.div`
  padding: 0 40px 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
`;

const SubscribeButton = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  background-color: ${colors.mainOrange};
  padding: 10px 25px;
  border-radius: 5px;
  margin-top: 10px;
  color: white;
  width: 100%;
`;

const Info = styled(CardInfoField)`
  label {
    font-size: 1.5em;
    font-weight: bold;
    color: #666;
  }
`;
