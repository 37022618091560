import { Skeleton } from '@mui/material';
import React from 'react';

import { media, styled } from 'styles';

export function PageSkeleton() {
  return (
    <Container>
      <TopBar>
        <Skeleton variant="rounded" width={130} height={40} />
        <Skeleton variant="rounded" width={200} height={40} />
      </TopBar>

      <Question variant="rounded" />
    </Container>
  );
}

const Container = styled.div`
  width: 1080px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media ${media.mobile} {
    margin-top: 80px;
  }
`;

const TopBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Question = styled(Skeleton)`
  &.MuiSkeleton-root {
    margin-top: 30px;
    width: 100%;
    height: 800px;
  }
`;
