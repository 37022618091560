import React, { useEffect, useCallback, useState } from 'react';

import { styled, colors } from 'styles';
import { useFormerSubscriberRecoverCampaign } from 'modules/campaigns/recover-subscriber/former-subscriber-recover/hooks';
import { useFormValidate } from 'modules/shared/hooks';
import { useTracker } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import {
  Container,
  Content,
  Title,
  ButtonsContainer,
  OptionButton,
  PlanInfo,
  InstallmentsInfo,
} from 'modules/campaigns/recover-subscriber/styles';
import CreditCardForm from 'modules/shared/components/CreditCardForm';
import { SuccessDialog } from '../index';
import { NoAccessDialogFormerTrial } from './';

export default function RetryWithNewCardDialog({ source, pricing }) {
  const [creditCardData, setCreditCardData] = useState(
    INITIAL_CREDIT_CARD_DATA
  );
  const [creditCardDataError, setCreditCardDataError] = useState(
    INITIAL_CREDIT_CARD_DATA
  );
  const [alreadyReceiveFormError, setAlreadyReceiveFormError] = useState(false);
  const [purchaseErrorMessage, setPurchaseErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { unsetDialog, setDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const {
    recoverPayment,
    defaultAmplitudeEventAttributes,
  } = useFormerSubscriberRecoverCampaign();
  const { getCreditCardErrors } = useFormValidate();

  useEffect(() => {
    trackEvent(
      'Recuperacao Ex Trial Com Cartão: Visualizou Dialog Confirmacao',
      {
        utm_source: source,
        opcao: 'novo cartao',
        plan: pricing.description,
        ...defaultAmplitudeEventAttributes,
      }
    );
  }, [source, trackEvent, pricing, defaultAmplitudeEventAttributes]);

  useEffect(() => {
    if (!alreadyReceiveFormError) return;

    const errors = getCreditCardErrors(creditCardData);
    setCreditCardDataError(errors);
  }, [creditCardData, alreadyReceiveFormError, getCreditCardErrors]);

  const onInputChange = useCallback(
    (key, value) => {
      setCreditCardData({ ...creditCardData, [key]: value });
    },
    [setCreditCardData, creditCardData]
  );

  const onRetryClick = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    const errors = getCreditCardErrors(creditCardData);
    if (errors.number || errors.holderName || errors.expiry || errors.cvv) {
      setCreditCardDataError(errors);
      setLoading(false);
      setAlreadyReceiveFormError(true);
      return;
    }

    const result = await recoverPayment(
      pricing,
      'credit_card',
      null,
      creditCardData,
      'former_trial'
    );
    if (!result.success) {
      setLoading(false);
      setPurchaseErrorMessage(
        'Erro ao tentar efetuar a cobrança. Verifique se o cartão tem limites, tente com outro cartão e caso o problema persista, entre em contato com o atendimento'
      );
      setTimeout(() => setPurchaseErrorMessage(null), 8000);
      return;
    }

    setDialog(
      <SuccessDialog
        source={source}
        pricing={pricing}
        recoverOption="novo cartao"
        trackViewSuccessName="Recuperacao Ex Trial Com Cartão: Visualizou Sucesso"
        trackClickBonusName="Recuperacao Ex Trial Com Cartão: Clicou Bonus"
      />
    );
  }, [
    loading,
    creditCardData,
    getCreditCardErrors,
    recoverPayment,
    setDialog,
    source,
    pricing,
  ]);

  if (!pricing) return null;
  return (
    <Container
      onClick={unsetDialog}
      data-cy="former-subscriber-new-card-dialog"
    >
      <Content onClick={e => e.stopPropagation()}>
        <Title color="#787878">Atualizar informações de Pagamento</Title>
        <PlanInfo>Seu plano: {pricing.description}</PlanInfo>
        <InstallmentsInfo>
          {pricing.months}x de R${pricing.monthlyPricingToShow} (
          {pricing.priceToShow})
        </InstallmentsInfo>
        <NewCardTitle>Dados do novo cartão</NewCardTitle>
        <CreditCardForm
          creditCardData={creditCardData}
          onInputChange={onInputChange}
          creditCardDataError={creditCardDataError}
        />

        <ButtonsContainer>
          <OptionButton
            onClick={() =>
              setDialog(<NoAccessDialogFormerTrial source={source} />)
            }
          >
            Voltar
          </OptionButton>

          <OptionButton onClick={onRetryClick}>
            {loading ? 'Reativando assinatura...' : 'Ativar assinatura'}
          </OptionButton>
        </ButtonsContainer>

        {purchaseErrorMessage && (
          <PurchaseErrorContainer>
            <PurchaseErrorMessage>
              <b>Erro ao tentar efetuar cobrança</b>
              <br />
              {purchaseErrorMessage}
            </PurchaseErrorMessage>
          </PurchaseErrorContainer>
        )}
      </Content>
    </Container>
  );
}

const INITIAL_CREDIT_CARD_DATA = {
  number: '',
  holderName: '',
  expiry: '',
  cvv: '',
};

const NewCardTitle = styled.div`
  font-size: 1.3em;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const PurchaseErrorContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PurchaseErrorMessage = styled.div`
  width: 380px;
  max-width: 95%;
  padding: 20px;
  border-radius: 10px;
  background-color: ${colors.warningRed};
  color: #fff;
  line-height: 1.2;

  b {
    line-height: 2;
    font-size: 1.1em;
  }
`;
