import React, { useState, useCallback } from 'react';
import Cards from 'react-credit-cards';
import { TextField } from '@material-ui/core';
import 'react-credit-cards/es/styles-compiled.css';

import { styled, media } from 'styles';
import CreditCardMask from 'modules/shared/components/inputs/CreditCardMask';
import CreditCardValidateDateMask from 'modules/shared/components/inputs/CreditCardValidateDateMask';

export default function CardForm({
  creditCardData,
  setCreditCardData,
  creditCardDataError,
}) {
  const [cardFocus, setCardFocus] = useState('');

  const onInputChange = useCallback(
    (key, value) => {
      setCreditCardData({ ...creditCardData, [key]: value });
    },
    [setCreditCardData, creditCardData]
  );

  const handleInputFocus = useCallback(
    name => {
      setCardFocus(name);
    },
    [setCardFocus]
  );

  return (
    <Container>
      {media.isDesktop() && (
        <Cards
          cvc={creditCardData.cvv}
          expiry={creditCardData.expiry}
          focused={cardFocus}
          name={creditCardData.holderName}
          number={creditCardData.number}
          placeholders={{ name: 'NOME NO CARTAO' }}
        />
      )}

      <CustomTextField
        label="Número do Cartão"
        InputProps={{
          inputComponent: CreditCardMask,
        }}
        value={creditCardData.number}
        onChange={e => onInputChange('number', e.target.value)}
        helperText={creditCardDataError.number}
        error={!!creditCardDataError.number}
        onFocus={() => handleInputFocus('number')}
      />

      <CustomTextField
        label="Nome no Cartão"
        value={creditCardData.holderName}
        onChange={e => {
          const newName = e.target.value;
          if (newName.length > 64) return;
          if (!newName.match(/^[a-zA-Z ]*$/)) return;

          onInputChange('holderName', e.target.value);
        }}
        helperText={creditCardDataError.holderName}
        error={!!creditCardDataError.holderName}
        onFocus={() => handleInputFocus('name')}
      />

      <CustomTextField
        label="Validade"
        InputProps={{
          inputComponent: CreditCardValidateDateMask,
        }}
        value={creditCardData.expiry}
        onChange={e => onInputChange('expiry', e.target.value)}
        helperText={creditCardDataError.expiry}
        error={!!creditCardDataError.expiry}
        onFocus={() => handleInputFocus('expiry')}
      />

      <CustomTextField
        label="Código de Segurança do Cartão"
        value={creditCardData.cvv}
        onChange={e => {
          const newCvv = e.target.value;
          const newCvvOnlyNumbers = newCvv.replace(/[^\d.-]/g, '');
          if (newCvvOnlyNumbers.length > 4) return;

          onInputChange('cvv', newCvvOnlyNumbers);
        }}
        helperText={creditCardDataError.cvv}
        error={!!creditCardDataError.cvv}
        onFocus={() => handleInputFocus('cvc')}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CustomTextField = styled(TextField)`
  margin: 5px 0 !important;
  width: 100%;
  font-weight: bold;
`;
