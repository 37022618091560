import { useTracker } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import React, { useCallback, useEffect } from 'react';
import { styled, media } from 'styles';

export default function ExitConfirmation({ handleContinue }) {
  const { unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();

  useEffect(() => {
    const header = document.getElementsByClassName('header');
    if (header[0]) header[0].style.display = 'none';

    return () => {
      if (header[0]) header[0].style.display = 'block';
    };
  }, []);

  const handleClose = useCallback(() => {
    trackEvent('DexterBot: Clicou para Sair');
    unsetDialog();
  }, [trackEvent, unsetDialog]);

  return (
    <Container>
      <p>Tem certeza que deseja sair?</p>
      <span>Ao sair as informações não serão salvas</span>
      <DoubleButtonContainer>
        <Button onClick={handleClose} escButton>
          SAIR
        </Button>
        <Button onClick={handleContinue}>CONTINUAR</Button>
      </DoubleButtonContainer>
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-top: 10px solid #ff6f4e;
  border-radius: 10px;
  padding: 35px 5px;
  margin: 100px auto;
  justify-content: center;
  align-items: center;
  color: #7a7a7a;
  box-shadow: 0px 3.29968px 3.29968px rgba(0, 0, 0, 0.25);

  background-color: white;
  align-self: center;
  position: sticky;
  z-index: 1;

  width: 100%;
  max-width: 680px;

  p {
    font-weight: bold;
    font-size: 23px;
  }

  span {
    font-size: 15px;
    padding-top: 20px;
  }

  @media ${media.mobile} {
    padding: 30px;
    width: 95%;
  }
`;

export const Button = styled.button`
  width: 48%;
  border: 2px solid ${({ escButton }) => (escButton ? '#ff6f4e' : '#36AAAD')};
  border-radius: 10px;

  color: ${({ escButton }) => (escButton ? '#ff6f4e' : '#fff')};
  background-color: ${({ escButton }) => (escButton ? '#fff' : '#36AAAD')};
  margin: 45px 0px;
  padding: 15px;

  font-weight: bold;
  font-size: 0.9em;

  cursor: pointer;

  @media ${media.mobile} {
    margin: 10px;
    width: 100%;
  }
`;

const DoubleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 600px;
  margin-top: 30px;
  margin: 0 auto;

  @media ${media.mobile} {
    flex-direction: column;
    width: 85%;
  }
`;
