import React from 'react';
import { Route } from 'react-router-dom';
import CampaignLecturePage from './pages/CampaignLecturePage';

export default [
  <Route
    exact
    path="/aulao-de-campanha/:lectureId"
    component={CampaignLecturePage}
    key="lectures"
  />,
];
