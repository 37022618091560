import { useMemo, useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { useWorkspaceListExerciseContext } from 'modules/workspace/providers/WorkspaceListExerciseProvider';
import { useUTM } from 'modules/shared/hooks';

export default function useWorkspaceListExerciseNavigation() {
  const [currentContent, setCurrentContent] = useState(null);
  const [previusContent, setPreviusContent] = useState(null);
  const [nextContent, setNextContent] = useState(null);
  const { listCategories } = useWorkspaceListExerciseContext();
  const { listExerciseId, guideId } = useParams();
  const { utmParamsWithoutInternalSource } = useUTM();

  const lists = useMemo(() => {
    return _.flatten(listCategories.map(listCategory => listCategory.lists));
  }, [listCategories]);

  const exercises = useMemo(() => {
    return _.flatten(lists.map(list => list.listExercises));
  }, [lists]);

  const contents = useMemo(() => {
    return exercises.map(exercise => {
      const list = lists.find(list => list.id === exercise.listId);
      const listCategory = listCategories.find(
        listCategory => listCategory.id === list.listCategoryId
      );

      return {
        id: exercise.id,
        link: `/praticar/${guideId}/lista/${listCategory.id}/${list.id}/exercicio-lista/${exercise.id}${utmParamsWithoutInternalSource}`,
      };
    });
  }, [
    exercises,
    listCategories,
    lists,
    guideId,
    utmParamsWithoutInternalSource,
  ]);

  const updatePreviusContent = useCallback(
    currentContentIndex => {
      if (currentContentIndex <= 0) {
        setPreviusContent(null);
        return;
      }
      setPreviusContent(contents[currentContentIndex - 1]);
    },
    [contents]
  );

  const updateNextContent = useCallback(
    currentContentIndex => {
      if (currentContentIndex >= contents.length - 1) {
        setNextContent(null);
        return;
      }
      setNextContent(contents[currentContentIndex + 1]);
    },
    [contents]
  );

  useEffect(() => {
    const currentContentIndex = contents.findIndex(
      exercise => exercise.id === parseInt(listExerciseId)
    );
    updatePreviusContent(currentContentIndex);
    updateNextContent(currentContentIndex);
    setCurrentContent(contents[currentContentIndex]);
  }, [contents, listExerciseId, updatePreviusContent, updateNextContent]);

  return {
    currentContent,
    previusContent,
    nextContent,
  };
}
