import React from 'react';
import styled from 'styled-components';

export default function ErrorMessage({ error }) {
  return (
    <>
      {error && (
        <ErrorContainer>
          <span>{error}</span>
        </ErrorContainer>
      )}
    </>
  );
}

export const ErrorContainer = styled.div`
  align-self: flex-end;
  z-index: 10;
  background: red;
  color: white;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  margin: -20px;
`;
