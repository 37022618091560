import React from 'react';
import { Copy, Logo } from '../styles';
import { raLogo } from 'images/main';
import styled from 'styled-components';

export default function Header({ copy }) {
  return (
    <>
      <LogoContainer>
        <Logo src={raLogo} />
      </LogoContainer>
      <Copy>{copy}</Copy>
    </>
  );
}

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
