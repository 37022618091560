import React from 'react';
import { styled } from 'styles';

export default function DownloadableHeader({ headerName }) {
  return (
    <Header>
      <Line />
      <Title>{headerName}</Title>
      <Line />
    </Header>
  );
}

const Header = styled.div`
  margin: 10px 0 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Line = styled.div`
  border: 1px solid #d4d4d4;
  flex-grow: 100;
`;

const Title = styled.div`
  font-size: 1.2em;
  margin: 5px;
`;
