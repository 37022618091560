import useSwrQueryGenerator from './useSwrQueryGenerator';
import { convertObjectToUnderscore } from 'modules/shared/utils';

const baseUrl = `${process.env.REACT_APP_RESPONDEAI_URL}/api/v3`;

export default function useRAQuery(url, params = {}, swrConfig = {}) {
  const queryParams = new URLSearchParams(
    convertObjectToUnderscore(params)
  ).toString();
  const completeUrl = `${baseUrl}${url}${queryParams && `?${queryParams}`}`;

  return useSwrQueryGenerator(completeUrl, {
    ...swrConfig,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });
}
