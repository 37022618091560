import { useCallback, useMemo } from 'react';

import { media } from 'styles';
import { useTracker } from 'modules/shared/hooks';
// import { useRAQueryImmutable } from 'modules/shared/hooks/requests';
import { useRAPostRequest } from 'modules/shared/hooks/requests';

export default function useDefaultTrial() {
  const { trackEvent } = useTracker();

  // const { data: defaultTrialData, loading } = useRAQueryImmutable(
  //   '/campaigns/default_trial'
  // );

  const createFreePassReq = useRAPostRequest('/campaigns/default_trial');

  const isLoadingCampaign = useMemo(() => {
    return false;
    // return loading;
  }, []);

  const isUserEligibleToDefaultTrial = useMemo(() => {
    return false;
    //return defaultTrialData?.isEligible;
  }, []);

  const eventName = useMemo(() => {
    return isUserEligibleToDefaultTrial
      ? 'Trial Default: Visualizou Dialog'
      : 'Trial Default: Visualizou dialog de CTA /planos';
  }, [isUserEligibleToDefaultTrial]);

  const trackViewDialog = useCallback(() => {
    trackEvent(eventName, {
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
    });
  }, [eventName, trackEvent]);

  return {
    isUserEligibleToDefaultTrial,
    createFreePassReq,
    isLoadingCampaign,
    trackViewDialog,
  };
}
