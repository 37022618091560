import React from 'react';
import { styled, media } from 'styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { useTracker, useUTM } from 'modules/shared/hooks';

export default function ReminderActivation(props) {
  const { chosenSubject } = props;
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  function onActivateReminder() {
    trackActivationReminder();
    window.open('https://t.me/intensivaoRespondeAi', '_blank');
  }

  function trackActivationReminder() {
    trackEvent(
      'Lançamentos: Entrou Telegram Aulão',
      getAmplitudeEventProperties()
    );
  }

  function getAmplitudeEventProperties() {
    return {
      Campanha: 'Intensivão do Ciclo Básico',
      Onde: media.isDesktop() ? 'Desktop' : 'Mobile',
      ...getUTMsByLocation(window.location),
    };
  }

  return (
    <Container chosenSubject={chosenSubject} onClick={onActivateReminder}>
      <Icon icon={faBell} className="icon" />
      <p>Ativar lembrete</p>
    </Container>
  );
}

const Container = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  cursor: pointer;

  font-size: 1.1rem;
  font-family: Lato;
  font-weight: bold;
  line-height: 18px;
  text-decoration: underline;
  color: #000;

  &:hover {
    text-decoration-color: ${props =>
      props.chosenSubject === 'Cálculo' ? '#FDA243' : '#3EBD87'};

    p {
      color: ${props =>
        props.chosenSubject === 'Cálculo' ? '#FDA243' : '#3EBD87'};
    }
    .icon {
      color: #f2e738;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;
