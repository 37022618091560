import { useCallback, useMemo } from 'react';

import { useWorkspaceGuide } from 'modules/workspace/hooks/shared';
import { useWorkspaceListExerciseContext } from 'modules/workspace/providers/WorkspaceListExerciseProvider';

export default function useWorkspaceSideMenuListExercise() {
  const { guide } = useWorkspaceListExerciseContext();
  const {
    studyPlanGuidable,
    disciplineGuidable,
    studyTrackGuidable,
  } = useWorkspaceGuide();

  const backButtonGuideLinks = useMemo(() => {
    const { guidableId, id: guideId } = guide;
    return {
      [disciplineGuidable]: `/materias/${guidableId}/guia/${guideId}`,
      [studyPlanGuidable]: `/plano-de-estudo/${guidableId}/guia/${guideId}`,
      [studyTrackGuidable]: `/trilha-de-estudo/${guidableId}/guia/${guideId}`,
    };
  }, [guide, disciplineGuidable, studyPlanGuidable, studyTrackGuidable]);

  const getBackButtonUrl = useCallback(() => {
    return backButtonGuideLinks[guide.guidable];
  }, [guide, backButtonGuideLinks]);

  return { getBackButtonUrl };
}
