import React, { useCallback } from 'react';
import { styled, media } from 'styles';
import { useSearchContext } from '../providers/SearchProvider';
import useSearchNavigation from '../hooks/useScreenNavigation';
import useSearchTracker from '../hooks/useSearchTracker';

export default function ControlSearchTypeScreen() {
  const { setSearchType, setResults } = useSearchContext();
  const { goNextScreen } = useSearchNavigation();
  const {
    trackClickSearchByText,
    trackClickSearchByImage,
  } = useSearchTracker();

  const onImageButtonClick = useCallback(() => {
    setSearchType('image');
    setResults(null);
    trackClickSearchByImage();
    goNextScreen('image');
  }, [setSearchType, setResults, goNextScreen, trackClickSearchByImage]);

  const onTextButtonClick = useCallback(() => {
    setSearchType('text');
    setResults(null);
    trackClickSearchByText();
    goNextScreen('text');
  }, [setSearchType, setResults, goNextScreen, trackClickSearchByText]);

  return (
    <>
      <ButtonImage onClick={onImageButtonClick}>
        Buscar por imagem
        <BetaStrip>BETA</BetaStrip>
      </ButtonImage>

      <ButtonText onClick={onTextButtonClick}>Buscar por texto</ButtonText>
    </>
  );
}

const Button = styled.button`
  font-size: 21px;
  font-weight: 700;
  padding: 5px 16px;
  height: 70px;
  width: 355px;
  margin: 15px 0;

  @media ${media.mobile} {
    width: 80vw;
    max-width: 355px;
    height: 65px;
  }
`;

const ButtonText = styled(Button)`
  border-radius: 54.059px;
  border: 1.802px solid #56b6a6;
  background: #fff;
  color: #56b6a6;
`;

const ButtonImage = styled(Button)`
  position: relative;
  border-radius: 54.059px;
  background: #56b6a6;
  color: #fff;
`;

const BetaStrip = styled.div`
  position: absolute;
  font-size: 0.6em;
  padding: 4px 26px;
  color: #fff;
  background: #bf0aff;
  transform: rotate(30deg);
  top: 11px;
  right: -14px;
`;
