export function urlByResult(it) {
  const baseBookURL =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3006'
      : 'https://app.respondeai.com.br';

  const baseURL =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : 'https://www.respondeai.com.br';

  if (it.type === 'bookExercise') {
    const exercise = it.book.edition.chapter.section.exercise;
    const exerciseId = exercise.id;

    return `${baseBookURL}/materias/solucionario/livro/${it.book.id}/edicao/${it.book.edition.id}/exercicio/${exerciseId}`;
  }

  if (it.type === 'exercise') {
    let topic = it.subject.chapter.topic;
    let exercise = topic.exercise;

    let lightSolution = exercise.lightSolution.filter(it => it)[0];

    if (!lightSolution) return '';

    return `${baseURL}/aprender/topico/${it.subject.id}/${topic.id}/exercicio/${topic.exercise.id}`;
  }

  if (it.type === 'theory') {
    const { discipline } = it;
    const { subject } = it;
    const { topic } = subject;
    const { theory } = topic;

    if (discipline)
      return `${baseURL}/aprender/${discipline.id}/topico/${subject.id}/${topic.id}/teoria/${theory.id}`;

    return `${baseURL}/aprender/topico/${subject.id}/${topic.id}/teoria/${theory.id}`;
  }

  return '';
}

export function prepareResults(all, term) {
  return [
    ...(all.topics || []).map(it => {
      it.type = 'theory';
      return it;
    }),
    ...(all.bookExercises || []).map(it => {
      if (term.length > 35) it.score *= 1.4;

      it.type = 'bookExercise';
      return it;
    }),
    ...(all.exercises || []).map(it => {
      if (term.length > 35) it.score *= 1.4;

      it.type = 'exercise';
      return it;
    }),
    ...(all.lectures || []).map(it => {
      it.type = 'lecture';
      return it;
    }),
  ].sort(compareScore);
}

export function compareScore(a, b) {
  const aScoreToCompare = a.score;
  const bScoreToCompare = b.score;

  if (aScoreToCompare === bScoreToCompare) return 0;
  return aScoreToCompare > bScoreToCompare ? -1 : 1;
}

export function removeMathsFromHtml(data) {
  if (!data) return;

  let startTag = data.indexOf('<math');
  let endTag = data.indexOf('math>', startTag) + 5;

  let torep = data.substring(startTag, endTag);

  const maths = [];
  while (torep.indexOf('<math') !== -1) {
    data = data.replace(torep, '');

    startTag = data.indexOf('<math');
    endTag = data.indexOf('math>', startTag) + 5;

    torep = data.substring(startTag, endTag);
  }

  return [data, maths];
}
