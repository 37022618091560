import React, { useCallback, useEffect, useState } from 'react';
import { styled, media } from 'styles';
import { MdPictureAsPdf } from 'react-icons/md';
import { DoubleButtonContainer, StepsContainer } from './styles';
import { useTracker } from 'modules/shared/hooks';
import { BeatLoader } from 'modules/shared/components/generic/loaders';

export default function Confirmation({
  materialType,
  discipline,
  testInfo,
  file,
  onSubmitInfo,
  onFormBack,
  loading,
}) {
  const { trackEvent } = useTracker();
  const [errorOnSubmitFile, setErrorOnSubmitFile] = useState('');

  const handleNext = useCallback(async () => {
    if (!loading) {
      const error = await onSubmitInfo();

      if (error)
        setErrorOnSubmitFile('Erro ao enviar o arquivo. Tente novamente.');
    }
  }, [onSubmitInfo, loading, setErrorOnSubmitFile]);

  const handleBack = useCallback(() => onFormBack(), [onFormBack]);

  useEffect(() => {
    trackEvent('Listas: Visualizou Dialog de Confirmação');
  }, [trackEvent]);

  return (
    <Container>
      <Title>
        <h1>Confirme as informações </h1>
      </Title>
      <StepsContainer>
        <span className={''}></span>
        <span className={''}></span>
        <span className={''}></span>
        <span className={''}></span>
        <span className={'fill'}></span>
      </StepsContainer>
      <h2>Essas são as informações e o arquivo que você está enviando</h2>
      <InfoContainer>
        <FileContainer>
          <MdPictureAsPdf />
          <p>{file.name}</p>
        </FileContainer>
        <Spacer />
        <div>
          <span>
            Tipo: <strong>{materialType}</strong>
          </span>
          <span>
            Disciplina: <strong>{discipline.name}</strong>
          </span>
          <span>
            <strong>
              {testInfo.category} - {testInfo.year}.{testInfo.period}
            </strong>
          </span>
        </div>
      </InfoContainer>
      {errorOnSubmitFile && (
        <SubmitErrorMessage>{errorOnSubmitFile}</SubmitErrorMessage>
      )}

      <DoubleButtonContainer>
        <Button backButton onClick={handleBack} disabled={loading}>
          VOLTAR
        </Button>
        <Button canSubmit onClick={handleNext}>
          {loading ? <BeatLoader size={10} color="#fff" /> : 'ENVIAR'}
        </Button>
      </DoubleButtonContainer>
    </Container>
  );
}

const SubmitErrorMessage = styled.div`
  color: #ff6f4e;
  font-size: 13px;
  text-decoration: underline;
  font-weight: bold;
`;

const Spacer = styled.div`
  background-color: #d4d4d4;
  height: 1px;
  width: 95%;
  margin-top: 20px;
`;

const InfoContainer = styled.div`
  border: 1px solid #888888;
  border-radius: 10px;
  width: 45%;
  padding: 10px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div:last-child {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;

    span {
      margin-left: 8px;
      padding-top: 5px;
    }
  }

  @media ${media.mobile} {
    width: 85%;

    span {
      text-align: start;
    }
  }
`;

const FileContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: start;
  width: 90%;
  text-decoration: underline;
  margin-top: 20px;

  svg {
    font-size: 20px;
    margin: 0 8px;
    color: #f7ac3d;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-weight: 300;
  }

  position: sticky;
  z-index: 3;
`;

export const Button = styled.button`
  width: 48%;
  border: 1px solid
    ${({ backButton, canSubmit }) =>
      canSubmit || backButton ? '#f6ac3a' : '#B8B8B8'};
  border-radius: 10px;

  color: ${({ backButton }) => (backButton ? '#f6ac3a' : '#fff')};
  background-color: ${({ backButton, canSubmit }) =>
    backButton ? '#fff' : canSubmit ? '#f6ac3a' : '#B8B8B8'};
  margin: 45px 0px;
  padding: 15px;

  font-weight: bold;
  font-size: 1.1em;

  cursor: pointer;

  position: sticky;
  z-index: 2;

  @media ${media.mobile} {
    margin: 10px;
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-top: 10px solid #f6ac3a;
  border-radius: 10px;
  padding: 35px 10px;
  padding-bottom: 45px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  color: #7a7a7a;
  background-color: white;
  align-self: center;
  position: sticky;
  z-index: 1;

  width: 80%;
  max-width: 600px;

  @media ${media.mobile} {
    padding: 30px;
  }
`;

const Title = styled.div`
  color: #888;
  font-weight: bold;
  text-align: center;

  h1 {
    font-size: 2.2em;

    @media ${media.mobile} {
      font-size: 2em;
    }
  }

  h3 {
    font-size: 1.1em;
    margin-top: 10px;
    line-height: 20px;

    @media ${media.mobile} {
      font-size: 1em;
    }
  }
`;
