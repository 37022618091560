import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';

import { styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import ChangeCreditCardDialog from '../dialogs/ChangeCreditCardDialog';

export default function ChangeCreditCardButton({ subscription }) {
  const { setDialog } = useDialogContext();

  return (
    <Container>
      <Button
        onClick={() =>
          setDialog(<ChangeCreditCardDialog subscription={subscription} />)
        }
      >
        <Icon icon={faCreditCard} />
        Trocar cartão de crédito
      </Button>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 10px;
`;

const Button = styled.button`
  background-color: #eee;
  font-size: 1em;
  padding: 5px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }
`;
