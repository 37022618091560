import { faTimes as removeIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEscClick } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { colors, media } from 'styles';

export default function ImageZoomDialog({ url, filename, onRemoveImage }) {
  const ref = useRef(null);
  const imageRef = useRef(null);
  const removeRef = useRef(null);
  const { unsetDialog } = useDialogContext();

  useEscClick(ref, unsetDialog);

  const onClick = useCallback(
    e => {
      if (e.target !== imageRef.current && e.target !== removeRef.current)
        unsetDialog();
    },

    [unsetDialog]
  );

  const removeImage = useCallback(() => {
    if (!onRemoveImage) return;

    const confirmDelete = window.confirm(
      'Você tem certeza que deseja excluir a imagem?'
    );

    if (!confirmDelete) return;

    unsetDialog();
    onRemoveImage();
  }, [onRemoveImage, unsetDialog]);

  return (
    <Container ref={ref} onClick={onClick}>
      <CenterContainer>
        <Actions>
          <Column>
            <Filename>{filename}</Filename>
            {onRemoveImage && (
              <RemoveImage ref={removeRef} onClick={removeImage}>
                Remover
              </RemoveImage>
            )}
          </Column>
          <CloseButton>
            <CloseIcon onClick={unsetDialog} icon={removeIcon} />
          </CloseButton>
        </Actions>
        <Image ref={imageRef} src={url} />
      </CenterContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff44;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  align-self: center;
`;

const CloseButton = styled.div`
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
`;

const Actions = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 10px;
  flex-direction: row;
  justify-content: space-between;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-top: 10px solid #f6ac3a;
  border-radius: 10px;
  padding: 5px 5px;
  margin-top: 30px;
  align-items: flex-start;
  color: black;
  background-color: white;

  width: 80%;

  @media ${media.mobile} {
    padding: 30px;
  }
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 1.5em;
  color: ${colors.mediumGray};
  cursor: pointer;
  margin-left: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
`;

const Filename = styled.p`
  font-size: 1.1em;
  font-weight: bold;
  color: ${colors.darkGray};
`;

const RemoveImage = styled.p`
  font-weight: bold;
  text-decoration: underline;
  font-size: 1em;
  cursor: pointer;
  color: ${colors.mediumGray};
  margin-top: 5px;
`;
