import React from 'react';

import { styled, media } from 'styles';
import ShareLinkContainer from 'modules/shared/components/ShareLinkContainer';
import { ErrorReportFloatButton } from 'modules/feedback/components';

export default function WorkspaceIconsContainer({ iconsData }) {
  return (
    <IconsContainer>
      <ShareLinkContainer
        wppMessageEncoded={iconsData?.whatsapp?.message}
        trackContentCallback={iconsData?.whatsapp?.trackMethod}
      />
      <ErrorReportFloatButton
        data={iconsData?.errorReport?.data}
        trackFeedbackCallback={iconsData?.errorReport?.trackMethod}
      />
    </IconsContainer>
  );
}

const IconsContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;

  @media ${media.mobile} {
    position: static;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
  }
`;
