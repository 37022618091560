import React, { useCallback, useMemo } from 'react';
import { media } from 'styles';
import ControlSearchTypeScreen from '../screens/ControlSearchTypeScreen';
import InputTutorialScreen from '../screens/InputTutorialScreen';
import CroppedScreenOnMobile from '../screens/CroppedScreenOnMobile';
import TextSearchScreen from '../screens/TextSearchScreen';
import ResultsScreenOnMobile from '../screens/ResultsScreenOnMobile';
import UploadScreenOnDesktop from '../screens/UploadScreenOnDesktop';
import { useSearchContext } from '../providers/SearchProvider';
import { useDialogContext } from 'providers/DialogProvider';

export default function useSearchNavigation() {
  const isMobile = useMemo(() => media.isMobile(), []);
  const { screenId, setScreenId } = useSearchContext();
  const { unsetDialog } = useDialogContext();

  const mobileScreens = useMemo(() => {
    return {
      0: {
        name: 'SEARCH_TYPE_SELECTION_SCREEN',
        screen: <ControlSearchTypeScreen />,
        getNextScreenId: params => (params === 'image' ? 1 : 3),
        getPreviousScreenId: () => unsetDialog(),
      },
      1: {
        name: 'INPUT_TUTORIAL_SCREEN',
        screen: <InputTutorialScreen />,
        getNextScreenId: () => 2,
        getPreviousScreenId: () => 0,
      },
      2: {
        name: 'CROPPED_IMAGE_SCREEN',
        screen: <CroppedScreenOnMobile />,
        getNextScreenId: () => 4,
        getPreviousScreenId: () => 1,
      },
      3: {
        name: 'SEARCH_BY_TEXT_SCREEN',
        screen: <TextSearchScreen />,
        getPreviousScreenId: () => 0,
      },
      4: {
        name: 'RESULTS_SCREEN',
        screen: <ResultsScreenOnMobile />,
        getNextScreenId: () => 1,
        getPreviousScreenId: () => 2,
      },
    };
  }, [unsetDialog]);

  const desktopScreens = useMemo(() => {
    return {
      0: {
        name: 'SEARCH_TYPE_SELECTION_SCREEN',
        screen: <ControlSearchTypeScreen />,
        getNextScreenId: params => (params === 'image' ? 1 : 3),
        getPreviousScreenId: () => unsetDialog(),
      },
      1: {
        name: 'INPUT_TUTORIAL_SCREEN',
        screen: <InputTutorialScreen />,
        getNextScreenId: () => 2,
        getPreviousScreenId: () => 0,
      },
      2: {
        name: 'UPLOAD_IMAGE_SCREEN',
        screen: <UploadScreenOnDesktop />,
        getPreviousScreenId: () => 1,
      },
      3: {
        name: 'SEARCH_BY_TEXT_SCREEN',
        screen: <TextSearchScreen />,
        getPreviousScreenId: () => 0,
      },
    };
  }, [unsetDialog]);

  const getScreenToRender = useCallback(() => {
    return isMobile
      ? mobileScreens[screenId]?.screen
      : desktopScreens[screenId]?.screen;
  }, [mobileScreens, desktopScreens, screenId, isMobile]);

  const goNextScreen = useCallback(
    params => {
      const currentScreen = isMobile
        ? mobileScreens[screenId]
        : desktopScreens[screenId];

      const nextScreenId = currentScreen.getNextScreenId(params);
      setScreenId(nextScreenId);
    },
    [mobileScreens, desktopScreens, screenId, isMobile, setScreenId]
  );

  const goPreviousScreen = useCallback(
    params => {
      const currentScreen = isMobile
        ? mobileScreens[screenId]
        : desktopScreens[screenId];

      const previousScreenId = currentScreen.getPreviousScreenId(params);
      setScreenId(previousScreenId);
    },
    [mobileScreens, desktopScreens, screenId, isMobile, setScreenId]
  );

  return { getScreenToRender, goNextScreen, goPreviousScreen };
}
