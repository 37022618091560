import React, { useCallback } from 'react';
import {
  playYellow,
  playGreen,
  playRed,
} from 'modules/banner/images/side-banner/private-live-lecture';
import { LecturePlayBox } from './styles';
import { useTracker } from 'modules/shared/hooks';

export default function LectureBox(props) {
  const { index, lecture } = props;
  const { trackEvent } = useTracker();

  const onClickLecture = useCallback(() => {
    trackEvent('Lives YT: Clicou Aulao Banner Lateral', {
      Aulao: lecture.name,
    });

    const videoUrl = `https://www.youtube.com/watch?v=${lecture.videoLinkId}`;
    window.open(videoUrl, '_blank');
  }, [trackEvent, lecture]);

  const getImage = useCallback(indexArray => {
    if (indexArray % 3 === 0) return playYellow;
    if (indexArray % 3 === 1) return playGreen;
    if (indexArray % 3 === 2) return playRed;
  }, []);

  const getDateCopy = useCallback(lecture => {
    return lecture.isTransmitting
      ? 'ASSISTA AGORA'
      : `${lecture.dateFormatted}h`;
  }, []);

  return (
    <LecturePlayBox key={index} onClick={onClickLecture}>
      <img src={getImage(index)} alt="play-icon" />
      <strong>{lecture.name}</strong>
      <strong>{getDateCopy(lecture)}</strong>
    </LecturePlayBox>
  );
}
