const colors = {
  lightGray: '#CCC',
  mediumGray: '#AAA',
  darkGray: '#888',
  bodyBackground: '#F3F3F3',
  check: '#7ED48A',
  theory: '#00B8D6',
  summary: '#00B8D6',
  exercise: '#F7AC3C',
  workspaceOrange: '#F7AC3C',
  book: '#14b9a6',
  listExercise: '#36AAAD',
  listCategory: '#79B97A',
  lecture: '#f06541',
  extraExercise: '#51BA75',
  mainOrange: '#F9AC3E',
  ErrorReportRed: '#E74B3B',
  white: '#fff',
  lightPurple: '#BA5DE2',
  facebookBlue: '#3C6BB6',
  googleRed: '#D55E58',
  appleBlack: '#000000',
  warningRed: '#E4613C',
  successGreen: '#00DE3B',
  darkGreen: '#0AB64E',
  purple: '#BB89B8',
  driven: '#E94692',
  annualBlue: '#42cfcf',
  semiannualGreen: '#14d47c',
  monthlyOrange: '#f7ac3b',
};

export default colors;
