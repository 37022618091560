import React from 'react';
import { styled, colors } from 'styles';
import { LoginButtonsContainer } from '../login';

export default function NoUnloggedAccessDisclaimer(props) {
  return (
    <Container {...props}>
      <Message>Tá curtindo o conteúdo? Loga aí pra continuar!</Message>

      <LoginButtonsContainer
        where="dialog"
        config={{ addOrigin: false }}
        origin={props.origin}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: 'Lato', sans-serif;
  z-index: 10;
`;

const Message = styled.h2`
  font-weight: bold;
  color: ${colors.darkGrey};
`;
