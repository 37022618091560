import React, { useCallback, useMemo } from 'react';

import { media, styled } from 'styles';
import dexterBlink from 'images/dexter/feedbacks/dexter-blink.png';
import { useDialogContext } from 'providers/DialogProvider';
import { usePaymentFailedRecoverCampaign } from 'modules/campaigns/recover-subscriber/payment-failed-recover/hooks';
import { NoAccessPaymentRecoverDialog } from 'modules/campaigns/recover-subscriber/payment-failed-recover/dialogs';
import {
  NoAccessDialogFormerSubscriber,
  useFormerSubscriberRecoverCampaign,
} from 'modules/campaigns/recover-subscriber';
import { LoginButtonsContainer } from 'modules/shared/components/login';
import { useUserContext } from 'providers/UserProvider';
import NoAccessDialogFormerTrial from 'modules/campaigns/recover-subscriber/former-subscriber-recover/dialogs/NoAccessDialogFormerTrial/NoAccessDialogFormerTrial';

export default function DexterNoAccessCTA({ origin }) {
  const {
    isOnFormerSubscriberRecoverCampaign,
    isOnFormerTrialRecoverCampaign,
  } = useFormerSubscriberRecoverCampaign();
  const { hasPaymentFailedToRecover } = usePaymentFailedRecoverCampaign();
  const { setDialog } = useDialogContext();
  const { user } = useUserContext();

  const title = useMemo(() => {
    if (!user) return 'Faça login para continuar.';

    return 'Este conteúdo está bloqueado';
  }, [user]);

  const message = useMemo(() => {
    if (!user) return 'Fique tranquilo, nada será publicado no seu nome!';
    if (hasPaymentFailedToRecover())
      return 'Houve uma falha de pagamento da sua assinatura, e por isso seu acesso está bloqueado';
    if (isOnFormerSubscriberRecoverCampaign())
      return 'Reative sua assinatura no mesmo plano que você já usava!';
    return 'Veja nossos planos e adquira acesso a todo conteúdo!';
  }, [user, hasPaymentFailedToRecover, isOnFormerSubscriberRecoverCampaign]);

  const ctaMessage = useMemo(() => {
    if (hasPaymentFailedToRecover()) return 'Recuperar acesso';
    if (isOnFormerSubscriberRecoverCampaign()) return 'Reativar assinatura';
    return 'Quero conteúdo ilimitado!';
  }, [hasPaymentFailedToRecover, isOnFormerSubscriberRecoverCampaign]);

  const onCtaClick = useCallback(() => {
    if (hasPaymentFailedToRecover()) {
      setDialog(
        <NoAccessPaymentRecoverDialog source="no_access_dialog_workspace" />
      );
      return;
    }
    if (isOnFormerSubscriberRecoverCampaign()) {
      setDialog(
        <NoAccessDialogFormerSubscriber source="no_access_dialog_workspace" />
      );
      return;
    }
    if (isOnFormerTrialRecoverCampaign()) {
      setDialog(
        <NoAccessDialogFormerTrial source="no_access_dialog_workspace" />
      );
      return;
    }

    window.open(`${process.env.REACT_APP_RA_WEB_SSR_URL}/planos`);
  }, [
    setDialog,
    hasPaymentFailedToRecover,
    isOnFormerSubscriberRecoverCampaign,
    isOnFormerTrialRecoverCampaign,
  ]);

  if (media.isMobile())
    return (
      <Container data-cy="no-access-dexter-wall">
        <WarningContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <DexterImage src={dexterBlink} />
            <WarningMessage>
              <h3>{title}</h3>
              <p>{message}</p>
            </WarningMessage>
          </div>
          {user ? (
            <CTA onClick={onCtaClick}>{ctaMessage}</CTA>
          ) : (
            <LoginButtonsContainer where="dialog" origin={origin} />
          )}
        </WarningContent>
      </Container>
    );

  return (
    <Container data-cy="no-access-dexter-wall">
      <WarningContent>
        <DexterImage src={dexterBlink} />
        <WarningMessage>
          <h3>{title}</h3>
          <p>{message}</p>
          {user ? (
            <CTA onClick={onCtaClick}>{ctaMessage}</CTA>
          ) : (
            <LoginButtonsContainer where="dialog" origin={origin} />
          )}
        </WarningMessage>
      </WarningContent>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

const WarningContent = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  width: 94%;
  display: flex;
  flex-direction: ${() => (media.isMobile() ? 'column' : 'row')};
  justify-content: center;
  margin: 150px auto 0 auto;

  @media ${media.mobile} {
    margin-top: 100px;
    padding: 0 10px;
  }
`;

const WarningMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 300px;

  h3 {
    font-weight: bold;
    font-size: 1.2em;
    color: #888;
  }

  p {
    font-size: 0.8em;
    color: #888;
  }

  @media ${media.mobile} {
    h3 {
      margin-bottom: 5px;
    }
  }
`;

const DexterImage = styled.img`
  width: 87px;
  height: 100px;
  margin-right: 20px;
`;

const CTA = styled.div`
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  padding: 0 20px;
  background-color: #f7ac3c;
  border: none;
  height: 40px;
  line-height: 40px;
  text-decoration: none;
  font-size: 1em;
  color: #fff;
  font-weight: bold;
  margin-top: 10px;

  @media ${media.mobile} {
    font-size: 0.85em;
  }
`;
