import { Skeleton } from '@mui/material';
import { styled, media } from 'styles';

export const ProfessorSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    margin: 10px 16px 5px 0;
    width: 230px;
    height: 27px;
    border-radius: 15px;

    @media ${media.mobile} {
      width: 45% !important;
      max-width: 170px;
    }
  }
`;
