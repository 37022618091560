import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { media } from 'styles';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker } from 'modules/shared/hooks';

export default function DisciplineCard({ theme, discipline, history }) {
  const { user } = useUserContext();
  const { unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();

  const fireDisciplineClickEvents = useCallback(() => {
    trackEvent('Dialog Conteúdo Personalizado: Clicou Teoria', {
      Disciplina: discipline.name,
      Matéria: theme.name,
    });
  }, [trackEvent, discipline, theme]);

  return (
    <Container
      onClick={() => {
        fireDisciplineClickEvents();
        history.push(
          `/materias/${discipline.id}/guia/${discipline.guide.id}?internal_source=custom-dialog`
        );
        unsetDialog();
      }}
      theme={theme}
      discipline={discipline}
    >
      <img src={theme.imagePath} alt="" />
      <span className="name">{discipline.name}</span>
      <span className="university">
        {user.university && user.university.acronym}
      </span>

      {discipline.isFree && <div className="free-strip">GRÁTIS!</div>}
    </Container>
  );
}

const Container = styled.div`
  display: block;
  transition: opacity 100ms ease-in;
  background: ${props => props.theme.color};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 184px;
  min-width: 120px;
  height: 118px;
  margin: 10px 8px 20px 8px;
  padding-top: 15px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    padding: 0 10px;
  }

  .university {
    position: absolute;
    bottom: 4px;
    width: 100%;
    text-align: center;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.3);
  }

  .free-strip {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12);
    position: absolute;
    left: -14px;
    top: 4px;
    background-color: #ff9f00;
    padding: 6px 10px;
    color: #000;
    font-size: 0.8em;
    z-index: 2;
    transform: rotate(-25deg);
  }

  &:hover {
    opacity: 0.8;
  }

  @media ${media.mobile} {
    height: 170px;
    max-width: 120px;

    img {
      width: 70px;
      height: 70px;
      margin-top: 15px;
    }
  }
`;
