import React from 'react';

import { CardInfoField } from 'modules/profile/components';
import ToggleSwitch from './ToggleSwitch';
import { DeleteButton } from '../../DeleteButton';

export default function Account({ user }) {
  return (
    <section>
      <div className="sectionTitle">
        <h3>Sua Conta</h3>
      </div>
      <CardInfoField fieldId="email" label="Email">
        {user.email}
      </CardInfoField>
      <>
        <CardInfoField
          fieldId="Permissões de comunicação"
          label="Permissões de comunicação"
        />
        <ToggleSwitch user={user} />

        {!user.subscriber && <DeleteButton />}
      </>
    </section>
  );
}
