import React from 'react';

import { ContentFeedback } from 'modules/feedback/components';
import { CONTENT_FEEDBACK_GRADES } from 'modules/workspace/consts/content-feedback-grades';
import useWorkspaceListExerciseContentFeedback from 'modules/workspace/hooks/list-exercise/useWorkspaceListExerciseContentFeedback';
import { useWorkspaceListExerciseTracker } from 'modules/workspace/hooks/list-exercise';

export default function ListExerciseContentFeedbackContainer() {
  const {
    feedbackContentType,
    feedbackContentId,
    feedbackContentName,
    feedbackContentFormat,
    feedbackMediaProviderId,
    feedbackFeelings,
    feedbackHeaderText,
  } = useWorkspaceListExerciseContentFeedback();
  const {
    trackContentFeedbackNote,
    trackContentFeedbackFeeling,
    trackContentFeedbackComment,
  } = useWorkspaceListExerciseTracker();

  if (!feedbackContentType || !feedbackContentFormat || !feedbackContentId)
    return null;
  return (
    <ContentFeedback
      contentType={feedbackContentType}
      contentId={feedbackContentId}
      contentName={feedbackContentName}
      contentMediaType={feedbackContentFormat}
      contentMediaProviderId={feedbackMediaProviderId || ''}
      allGrades={CONTENT_FEEDBACK_GRADES}
      allFeelings={feedbackFeelings}
      headerText={feedbackHeaderText}
      trackFeedbackNoteCallback={trackContentFeedbackNote}
      trackFeedbackFeelingCallback={trackContentFeedbackFeeling}
      trackFeedbackCommentCallback={trackContentFeedbackComment}
      dataCy="content-feedback-workspace"
    />
  );
}
