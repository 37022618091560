import React, { useCallback } from 'react';
import { styled } from 'styles';
import { StudyTrackCard } from 'modules/main/components/themes-page/study-track';
import { useUniversityContext } from 'providers/UniversityProvider';
import { DisciplineCard } from '../discipline';
import { StudyPlanCard } from 'modules/main/components/themes-page/study-plan';
import SubjectGroupsContainer from './SubjectGroupsContainer';
import BuildStudyPlan from '../guide/BuildStudyPlan';
import OtherSubjectsButton from '../buttons/OtherSubjectsButton';
import { useUserContext } from 'providers/UserProvider';
import MvpListCard from 'modules/campaigns/mvp-books/components/MvpListCard';
import { useMvpBooksTracker } from 'modules/campaigns/mvp-books/hooks/useMVpBooksTracker';
import { useListWithStatementTracker } from 'modules/mvp/hooks/useListWithStatementTracker';
import UserProfessorsListMobile from '../professor/UserProfessorsListMobile';
import { useProfessor } from 'modules/professors/hooks/useProfessor';

export default function ThemeAccordeon({
  theme,
  disciplines,
  studyPlans,
  isPrivateTheme = false,
  mvpLists,
  listsWithStatement,
}) {
  const { user } = useUserContext();
  const { university, universityPronoumn } = useUniversityContext();
  const { trackClickList } = useMvpBooksTracker();
  const {
    trackClickList: trackClickListWithStatement,
  } = useListWithStatementTracker();
  const { showUserProfessors } = useProfessor();

  if (!isPrivateTheme)
    studyPlans =
      studyPlans?.filter(sp => parseInt(sp.themeId) === theme.id) || [];

  const disciplineTitle = useCallback(() => {
    if (isPrivateTheme)
      return `${theme.name} e Matemática Básica ao mesmo tempo`;

    if (
      university &&
      university.acronym &&
      university.acronym != 'Selecione a universidade'
    )
      return `${theme.name} ${universityPronoumn} ${university.acronym}`;

    return `${theme.name} da sua faculdade`;
  }, [isPrivateTheme, university, theme, universityPronoumn]);

  const isThereFavoriteLists = useCallback(() => {
    return mvpLists?.length > 0 || listsWithStatement?.length > 0;
  }, [listsWithStatement, mvpLists]);

  return (
    <AccordeonContent>
      {user && (
        <>
          {showUserProfessors() && (
            <>
              <h1>Suas Disciplinas neste Semestre</h1>
              <UserProfessorsListMobile theme={theme} />
            </>
          )}
          {isThereFavoriteLists() && (
            <>
              <h1>{`Minhas listas favoritas de ${theme.name}`}</h1>
              <ContainerDisciplines>
                {mvpLists?.map(l => (
                  <MvpListCard
                    theme={theme}
                    key={l.id}
                    listId={l.id}
                    professorName={l.listProfessor?.name || l.professor}
                    link={`${process.env.REACT_APP_MVP_URL}/listas/${l.id}`}
                    onTrackCallback={trackClickList}
                    listType="MvpBookExerciseList"
                  />
                ))}

                {listsWithStatement?.map(l => (
                  <MvpListCard
                    theme={theme}
                    key={l.id}
                    listId={l.id}
                    professorName={l.professor.name}
                    link={`${process.env.REACT_APP_REACT_URL}/lista-de-enunciado/${l.id}`}
                    onTrackCallback={trackClickListWithStatement}
                    listType="MvpListWithStatement"
                  />
                ))}
              </ContainerDisciplines>
            </>
          )}
          <h1>{disciplineTitle()}</h1>
          <ContainerDisciplines>
            {isPrivateTheme ? (
              <>
                {theme.studyTracks.map(d => (
                  <StudyTrackCard theme={theme} studyTrack={d} key={d.id} />
                ))}
              </>
            ) : (
              <>
                {disciplines?.map(d => (
                  <DisciplineCard theme={theme} discipline={d} key={d.id} />
                ))}
              </>
            )}
            {studyPlans?.map(
              sp =>
                parseInt(sp.themeId) === theme.id && (
                  <StudyPlanCard
                    theme={theme}
                    studyPlan={sp}
                    key={sp.id}
                    privateThemeColor={isPrivateTheme}
                  />
                )
            )}
            <BuildStudyPlan theme={theme} privateThemeColor={isPrivateTheme} />
          </ContainerDisciplines>
        </>
      )}

      {isPrivateTheme ? (
        <OtherSubjectsButton theme={theme} />
      ) : (
        <SubjectGroupsContainer theme={theme} />
      )}
    </AccordeonContent>
  );
}

const AccordeonContent = styled.div`
  background-color: #f3f3f3;
  padding-bottom: 8px;

  h1 {
    padding-top: 25px;
    padding-left: 20px;
    font-size: 1.2em;
    max-width: 90%;
    font-weight: bold;
    color: #888;
    text-align: left !important;
  }
`;

const ContainerDisciplines = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-left: 25px;

  ::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
