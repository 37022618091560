import { useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import {
  convertObjectToUnderscore,
  convertObjectToCamelcase,
  buildFormData,
} from 'modules/shared/utils';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_RESPONDEAI_URL}/api/v3`,
  withCredentials: true,
});

export default function useRAPostFileRequest(url) {
  const sendPostFileRequest = useCallback(
    async (file = {}, data = {}, options = {}) => {
      const userJwt = Cookies.get('user_jwt');

      options.headers = {
        ...(options.headers || {}),
        'User-JWT': userJwt,
        'Content-Type': 'multipart/form-data',
      };

      const formData = new FormData();
      if (file) formData.append('file', file);
      buildFormData(formData, convertObjectToUnderscore(data));

      const response = await instance.post(url, formData, options);
      return { data: convertObjectToCamelcase(response.data || {}) };
    },
    [url]
  );

  return sendPostFileRequest;
}
