import React, { useMemo, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faBolt } from '@fortawesome/free-solid-svg-icons';

import { styled, colors } from 'styles';
import {
  useWorkspaceGuide,
  useWorkspaceExercise,
} from 'modules/workspace/hooks/shared';
import {
  useWorkspace,
  useWorkspaceSideMenuChapters,
} from 'modules/workspace/hooks/main';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import { useUTM } from 'modules/shared/hooks';

export default function ChapterExtraButtons({ chapter }) {
  const { guideId } = useParams();
  const { utmParamsWithoutInternalSource } = useUTM();
  const {
    showExtraExerciseButton,
    showExtraSummaryButton,
    isExtraTimeWorkspacePage,
    isSummaryWorkspacePage,
  } = useWorkspaceSideMenuChapters();

  const { currentSubject } = useWorkspaceContentContext();
  const { getExtraExercisesFromChapter } = useWorkspaceExercise();
  const { isGuideWorkspace } = useWorkspaceGuide();
  const { getSummariesFromChapter } = useWorkspace();
  const [activeHover, setActiveHover] = useState(null);

  const extraExerciseLink = useMemo(() => {
    const extraExercises = getExtraExercisesFromChapter(chapter);
    if (extraExercises.length === 0) return '#';

    if (isGuideWorkspace()) {
      return `/aprender/${guideId}/extras/${currentSubject.id}/${chapter.id}/exercicio-lista/${extraExercises[0].id}${utmParamsWithoutInternalSource}`;
    }
    return `/aprender/extras/${currentSubject.id}/${chapter.id}/exercicio-lista/${extraExercises[0].id}${utmParamsWithoutInternalSource}`;
  }, [
    currentSubject,
    chapter,
    guideId,
    getExtraExercisesFromChapter,
    isGuideWorkspace,
    utmParamsWithoutInternalSource,
  ]);

  const summaryLink = useMemo(() => {
    const summaries = getSummariesFromChapter(chapter);
    if (summaries.length === 0) return '#';

    if (isGuideWorkspace()) {
      return `/aprender/${guideId}/extras/${currentSubject.id}/${chapter.id}/resuminho/${summaries[0].id}${utmParamsWithoutInternalSource}`;
    }

    return `/aprender/extras/${currentSubject.id}/${chapter.id}/resuminho/${summaries[0].id}${utmParamsWithoutInternalSource}`;
  }, [
    currentSubject,
    chapter,
    guideId,
    getSummariesFromChapter,
    isGuideWorkspace,
    utmParamsWithoutInternalSource,
  ]);

  const extraExerciseButtonClass = useMemo(() => {
    if (isExtraTimeWorkspacePage(chapter) && activeHover !== 'summary')
      return 'active extra';

    return 'extra';
  }, [isExtraTimeWorkspacePage, chapter, activeHover]);

  const summaryButtonClass = useMemo(() => {
    if (isSummaryWorkspacePage(chapter) && activeHover !== 'extra')
      return 'active summary';

    return 'summary';
  }, [isSummaryWorkspacePage, chapter, activeHover]);

  return (
    <Container>
      <ButtonsContainer>
        {showExtraExerciseButton(chapter) && (
          <Button
            className={extraExerciseButtonClass}
            to={extraExerciseLink}
            onMouseEnter={() => setActiveHover('extra')}
            onMouseLeave={() => setActiveHover(null)}
          >
            <Icon className="icon extra" icon={faClock} />
            <Text className="text">
              Tempo sobrando? <br />
              Exercícios extras
            </Text>
          </Button>
        )}

        {showExtraSummaryButton(chapter) && (
          <Button
            className={summaryButtonClass}
            to={summaryLink}
            onMouseEnter={() => setActiveHover('summary')}
            onMouseLeave={() => setActiveHover(null)}
          >
            <Icon className="icon summary" icon={faBolt} />
            <Text className="text summary">Resuminho</Text>
          </Button>
        )}
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -22.5px;
  height: 45px;
  display: flex;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border-radius: 25px;
  background-color: #393939;
  border: 3px solid #393939;
  margin: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.5s ease-in-out;

  &.active {
    width: 180px;

    &.extra {
      background-color: ${colors.extraExercise};
      color: #fff;
    }

    &.summary {
      background-color: ${colors.theory};
      color: #fff;
    }

    .icon.extra,
    .icon.summary,
    .text {
      color: #fff;
    }

    .text {
      display: block;
    }
  }

  &:hover {
    width: 180px;
    background-color: #fff;

    &.extra {
      color: ${colors.extraExercise};
    }

    &.summary {
      color: ${colors.theory};
    }

    .icon.extra {
      color: ${colors.extraExercise};
    }

    .icon.summary {
      color: ${colors.theory};
    }

    .text {
      display: block;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  min-width: 45px;
  font-size: 1.1em;
  color: ${colors.darkGray};
  transition: all 0.5s ease-in-out;

  &.summary {
    font-size: 1.05em;
  }
`;

const Text = styled.div`
  display: none;
  text-transform: uppercase;
  font-size: 0.7em;
  line-height: 1.2;
  padding-right: 15px;
  text-align: left;
  overflow: hidden;

  &.summary {
    font-size: 1.1em;
  }
`;
