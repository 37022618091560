import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import React, { useMemo } from 'react';
import { styled, media } from 'styles';
import { Link } from 'react-router-dom';

export default function BookInfoInline() {
  const { edition } = useBookEditionContext();

  const bookIdToRedirect = useMemo(() => {
    if (!edition.book) return '';

    return edition.book.id;
  }, [edition]);

  if (!edition) return null;

  return (
    <Container>
      <span className="h1 colorful bold">{`${edition.book.author} |`}</span>
      <span className="h1 colorful">{` ${edition.book.volume} | `}</span>
      <span className="h1 bold">{`${edition.name}ª Edição `}</span>
      <Link
        className="underline"
        to={`/livros-resolvidos/livro/${bookIdToRedirect}/edicoes`}
      >
        trocar edição
      </Link>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;

  .h1 {
    font-size: 1.5rem;
    margin-left: 13px;
  }

  .bold {
    font-weight: bold;
  }

  .colorful {
    color: #14b9a6;
  }

  .underline {
    text-decoration: underline;
    margin-left: 14px;
    text-underline-offset: 3px;
    margin-top: -1px;
  }

  @media ${media.mobile} {
    .h1 {
      font-size: 1.1rem;
      margin-left: 8px;
    }

    .underline {
      font-size: 0.85rem;
      margin-left: 8px;
      margin-top: -1px;
    }
  }
`;
