import React, { useCallback, useMemo } from 'react';
import { usePaymentFailedRecoverCampaign } from 'modules/campaigns/recover-subscriber/payment-failed-recover/hooks';
import {
  NoAccessDialogFormerSubscriber,
  useFormerSubscriberRecoverCampaign,
} from 'modules/campaigns/recover-subscriber';
import { NoAccessPaymentRecoverDialog } from 'modules/campaigns/recover-subscriber/payment-failed-recover/dialogs';
import { useUserContext } from 'providers/UserProvider';
import { BookCreditCardTrialCTADialog } from 'modules/campaigns/book-trial/components';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import { PlansDialog } from 'modules/campaigns/checkout-light/dialogs';
import { useEmailInvolvement } from 'modules/campaigns/email-involvement';
import { EmailInvolvementDialogB } from 'modules/campaigns/email-involvement';
import {
  usePhoneNumberDialog,
  PhoneNumberRequestDialog,
} from 'modules/campaigns/phone-number-request';
import NoAccessDialogFormerTrial from 'modules/campaigns/recover-subscriber/former-subscriber-recover/dialogs/NoAccessDialogFormerTrial/NoAccessDialogFormerTrial';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import { useRenderDialog } from 'modules/shared/hooks';
import useSearchFeedback from 'modules/search/hooks/useSearchFeedback';
import SearchFeedbackDialog from 'modules/search/dialogs/SearchFeedbackDialog';
import useWorkspacePermission from './useWorkspacePermission';

export default function useWorkspaceRenderDialog() {
  const { user } = useUserContext();
  const { currentContent } = useWorkspaceContentContext();
  const {
    isOnFormerSubscriberRecoverCampaign,
    isOnFormerTrialRecoverCampaign,
  } = useFormerSubscriberRecoverCampaign();
  const { hasPaymentFailedToRecover } = usePaymentFailedRecoverCampaign();
  const { showBookCreditCardTrialCTA } = useBookTrialCampaign();
  const { shouldShowPhoneNumberDialog } = usePhoneNumberDialog();
  const { showEmailInvolvementDialog } = useEmailInvolvement();
  const { shouldShowSearchFeedbackDialog } = useSearchFeedback();
  const { currentContentAccess } = useWorkspacePermission();

  const workspaceSource = useMemo(() => {
    const { pathname } = window.location;
    if (pathname.includes('/exercicio/')) return 'Exercicio';
    if (pathname.includes('/exercicio-lista/')) return 'Exercicio de Lista';
    if (pathname.includes('/teoria/')) return 'Teoria';
  }, []);

  const isPlansDialog = useCallback(() => {
    return workspaceSource === 'Teoria';
  }, [workspaceSource]);

  const dialogs = useMemo(() => {
    if (!user || !currentContent) return [];

    return [
      {
        condition: showEmailInvolvementDialog,
        render: () => <EmailInvolvementDialogB />,
      },
      {
        condition: () => !currentContentAccess && hasPaymentFailedToRecover(),
        render: () => (
          <NoAccessPaymentRecoverDialog source="no_access_dialog_workspace" />
        ),
      },
      {
        condition: isOnFormerSubscriberRecoverCampaign,
        render: () => (
          <NoAccessDialogFormerSubscriber source="no_access_dialog_workspace" />
        ),
      },
      {
        condition: isOnFormerTrialRecoverCampaign,
        render: () => (
          <NoAccessDialogFormerTrial source="no_access_dialog_workspace" />
        ),
      },
      {
        condition: showBookCreditCardTrialCTA,
        render: () =>
          isPlansDialog() ? (
            <PlansDialog source={workspaceSource} />
          ) : (
            <BookCreditCardTrialCTADialog source={workspaceSource} />
          ),
      },
      {
        condition: () => shouldShowPhoneNumberDialog,
        render: () => <PhoneNumberRequestDialog />,
      },
      {
        condition: () => shouldShowSearchFeedbackDialog(),
        render: () => (
          <SearchFeedbackDialog
            source="Questão Workspace"
            text="Você encontrou o que estava procurando?"
          />
        ),
        timeToOpen: 10000,
      },
    ];
    // shouldShowPhoneNumberDialog must not be included because it is altered by cookies and set by BookCreditCardTrialCTADialog
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    currentContent,
    showEmailInvolvementDialog,
    isOnFormerSubscriberRecoverCampaign,
    isOnFormerTrialRecoverCampaign,
    showBookCreditCardTrialCTA,
    currentContentAccess,
    hasPaymentFailedToRecover,
    isPlansDialog,
    workspaceSource,
    shouldShowSearchFeedbackDialog,
  ]);

  useRenderDialog(dialogs);
}
