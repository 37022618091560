import { useCallback, useState } from 'react';
import useProfessorTracker from './useProfessorTracker';

export function usePeriod() {
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const { trackClickPrevPeriod } = useProfessorTracker();

  const getListsAndPeriodsGrouped = useCallback(lists => {
    if (!lists) return;

    const groupedListByPeriod = lists.reduce((acc, list) => {
      if (!acc[list.period.id]) {
        acc[list.period.id] = {
          period: {
            id: list.period.id,
            name: list.period.name,
            endDate: list.period.endDate,
          },
          lists: [],
        };
      }
      acc[list.period.id]['lists'].push(list);
      return acc;
    }, {});

    return Object.values(groupedListByPeriod);
  }, []);

  const getPeriodsFromListsOrdered = useCallback(
    (lists, currentPeriod) => {
      if (!currentPeriod) return [];
      if (!lists) return [];

      const listsAndPeriodsGrouped = getListsAndPeriodsGrouped(lists);

      const allPeriods = listsAndPeriodsGrouped.map(
        listsAndPeriodsGroup => listsAndPeriodsGroup.period
      );

      const pastPeriods = allPeriods
        .filter(period => period.id !== currentPeriod.id)
        .sort((a, b) => (a.name < b.name ? 1 : -1));

      return [currentPeriod, ...pastPeriods];
    },
    [getListsAndPeriodsGrouped]
  );

  const onPeriodClick = useCallback(
    ({ period, professor, numberLists }) => {
      trackClickPrevPeriod({
        period: period.name,
        professorName: professor.name,
        disciplineName: professor.teachable.name,
        numberOfLists: numberLists,
      });

      setSelectedPeriod(period);
    },
    [setSelectedPeriod, trackClickPrevPeriod]
  );

  const getPeriodName = useCallback(
    currentPeriod => {
      if (!currentPeriod || !selectedPeriod) return `Atual`;

      if (selectedPeriod.id === currentPeriod.id)
        return `Atual ( ${selectedPeriod.name} )`;

      return selectedPeriod.name;
    },
    [selectedPeriod]
  );

  const shouldShowPreviousPeriods = useCallback((allLists, professor) => {
    if (!allLists || !professor) return false;

    const currentPeriod = professor.currentPeriod;

    const allPeriods = allLists.map(list => list.period);

    return allPeriods.some(period => period.id !== currentPeriod.id);
  }, []);

  return {
    selectedPeriod,
    setSelectedPeriod,
    getListsAndPeriodsGrouped,
    getPeriodsFromListsOrdered,
    getPeriodName,
    onPeriodClick,
    shouldShowPreviousPeriods,
  };
}
