import React from 'react';
import { styled, colors, media } from 'styles';

export default function ForumHeader({ onCreateQuestionButton }) {
  return (
    <Container>
      Ficou com alguma dúvida?
      <MakeQuestionButton
        className="make-question-button"
        onClick={onCreateQuestionButton}
      >
        Pergunta Aí
      </MakeQuestionButton>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.2em;
  font-weight: bold;
  flex-wrap: wrap;
  color: #808080;
`;

const MakeQuestionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${colors.mainOrange};
  padding: 10px 20px;
  border-radius: 28px;
  color: #505050;
  font-weight: bold;
  margin-left: 17px;
  cursor: pointer;
  width: 319px;
  height: 55px;

  @media ${media.mobile} {
    padding: 10px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;
