import React, { useRef, useCallback } from 'react';
import { useLectureContext } from '../../providers/LectureProvider';
import { styled, media } from 'styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const VideoProgressBarControl = ({ seekTo }) => {
  const progressVideoRefs = useRef([]);

  const {
    getWidthFromIndex,
    currentVideoIndex,
    onVideoProgressClick,
    getFullProgressSeconds,
    getHoverProgressSeconds,
    onProgressBarHover,
    bulletHoverPosition,
    lecturesList,
    onMouseEnterModule,
    currentVideoHoverIndex,
    setCurrentVideoIndex,
  } = useLectureContext();

  const shouldShowModuleBulletInfo = useCallback(
    index => {
      if (index === currentVideoIndex) return true;
      if (currentVideoHoverIndex === index) return true;
    },
    [currentVideoIndex, currentVideoHoverIndex]
  );

  const shouldShowArrowIcon = useCallback(
    index => {
      if (currentVideoHoverIndex === null && index === currentVideoIndex)
        return true;
      if (currentVideoHoverIndex === index) return true;
      return false;
    },
    [currentVideoHoverIndex, currentVideoIndex]
  );

  const onBulleetClick = useCallback(
    bulletIndex => {
      if (bulletIndex != currentVideoIndex) setCurrentVideoIndex(bulletIndex);

      seekTo(0, bulletIndex);
    },
    [seekTo, currentVideoIndex, setCurrentVideoIndex]
  );

  return (
    <VideoBarContainer>
      {lecturesList.map(({ proportionalSize }, index) => (
        <ProgressVideoContainer
          onMouseEnter={() => onMouseEnterModule(index)}
          key={index}
          proportionalSize={proportionalSize}
        >
          <ModuleBullet
            onClick={() => onBulleetClick(index)}
            className={
              index > currentVideoIndex && index !== currentVideoHoverIndex
                ? 'turned-off'
                : ''
            }
          >
            {shouldShowModuleBulletInfo(index) && <>{index + 1}</>}
            {shouldShowArrowIcon(index) && (
              <Icon className="arrow" icon={faCaretDown} />
            )}
          </ModuleBullet>

          <VideoFullBar
            onMouseMove={e =>
              onProgressBarHover(e, progressVideoRefs.current[index])
            }
            mouseHoverPostion={bulletHoverPosition}
            ref={ref => (progressVideoRefs.current[index] = ref)}
            onClick={e =>
              onVideoProgressClick(
                e,
                index,
                progressVideoRefs.current[index],
                seekTo
              )
            }
            key={index}
          >
            <VideoCurrentBullet className="bullet-hover">
              <CurrentVideoTimer>
                {dayjs
                  .utc(Math.floor(getHoverProgressSeconds()) * 1000)
                  .format('HH:mm:ss')}
              </CurrentVideoTimer>
            </VideoCurrentBullet>

            <VideoCurrentBar
              className={currentVideoIndex === index ? 'current' : ''}
              width={getWidthFromIndex(index)}
            >
              {currentVideoIndex === index && (
                <VideoCurrentBullet className="bullet">
                  <CurrentVideoTimer>
                    {dayjs
                      .utc(Math.floor(getFullProgressSeconds()) * 1000)
                      .format('HH:mm:ss')}
                  </CurrentVideoTimer>
                </VideoCurrentBullet>
              )}
            </VideoCurrentBar>
          </VideoFullBar>
        </ProgressVideoContainer>
      ))}
    </VideoBarContainer>
  );
};

const VideoBarContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  .bullet-hover {
    display: none;
  }
  &:hover {
    .bullet {
      display: none;
    }
  }
`;

const VideoFullBar = styled.div`
  height: 8px;
  background-color: #ddd;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  flex-grow: 1;
  &:hover {
    .bullet-hover {
      display: block;
      position: absolute;
      margin-top: -2px;
      left: ${({ mouseHoverPostion }) =>
        `calc(${mouseHoverPostion * 100}% - 6px)`};
    }
  }
`;

const ProgressVideoContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${({ proportionalSize }) => `calc(${proportionalSize * 100}% - 0px)`};
  &:hover {
    .arrow {
    }
  }
`;

const ModuleBullet = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -2px 0 -2px;
  z-index: 4;
  position: relative;
  @media ${media.mobile} {
    width: 15px;
    height: 15px;
  }
  background-color: #f9ac3e;
  &.turned-off {
    background-color: #ddd;
  }
  cursor: pointer;
`;

const VideoCurrentBullet = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #f9ac3e;
  z-index: 5;
  margin-right: -6px;
  position: relative;
`;

const CurrentVideoTimer = styled.div`
  position: absolute;
  font-size: 0.7em;
  width: 100px;
  display: block;
  top: -20px;
  left: -45px;
  text-align: center;
  color: #666;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: -20px;
  font-size: 25px;
  color: #f9ac3e;
  transform: scaleY(1.4);
`;

const VideoCurrentBar = styled.div`
  width: ${({ width }) => (width ? `${width * 100}%` : '0%')};
  height: 100%;
  background-color: #f9ac3e;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.current {
    min-width: 6px;
  }
`;

export default VideoProgressBarControl;
