import React, { useMemo, useRef, useEffect, useState } from 'react';

import { styled, colors } from 'styles';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';

export default function SideMenuItems({
  open,
  onClick,
  renderTitleComponent,
  renderItems,
  className,
  spaceOnBottom = false,
  tooltip = false,
}) {
  const [toggleableHeight, setToggleableHeight] = useState(0);
  const listRef = useRef();

  useEffect(() => {
    setToggleableHeight(listRef.current.clientHeight + 60);
  }, [renderTitleComponent]);

  const containerClassName = useMemo(() => {
    const tooltipClass = tooltip ? 'has_tooltip' : '';
    const openClass = open ? 'open' : '';
    const spaceOnBottomClass = spaceOnBottom ? 'space-bottom' : '';
    return `${tooltipClass} ${openClass} ${spaceOnBottomClass} ${className}`;
  }, [tooltip, open, spaceOnBottom, className]);

  return (
    <Container className={containerClassName} onClick={onClick}>
      <Title className={open ? 'open' : ''}>{renderTitleComponent()}</Title>
      <ToggleableComponent
        open={open}
        animationTime="0.3s"
        height={`${toggleableHeight}px`}
      >
        <div ref={listRef}>{renderItems()}</div>
      </ToggleableComponent>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;

  &.open {
    background-color: #393939;

    &.space-bottom {
      padding-bottom: 20px;
      margin-bottom: 30px;
    }
  }

  &.has_tooltip {
    &:hover {
      #tooltip {
        display: flex !important;
      }
    }
  }
`;

const Title = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 25px 15px 10px;

  &.open {
    color: ${colors.workspaceOrange};
  }
`;
