import React from 'react';
import { KeepSubscriptionButton, CancelSubscriptionButton } from './components';
import { useDate } from 'modules/shared/hooks';
import { styled } from 'styles';

export default function Covid19CancellationRequestMessage({
  user,
  subscription,
}) {
  const { getHumanReadableDate, getHumanReadableDateFromTimestamp } = useDate();

  return (
    <Container>
      <p>
        Você pode receber o período grátis no fim do seu plano em{' '}
        {getHumanReadableDateFromTimestamp(subscription.expiresIn)}!
      </p>
      <p>
        Nesse caso, você não mantém o mesmo valor para assinaturas futuras. Se
        você precisar usar a plataforma por mais tempo, talvez seja melhor usar
        esse período gratuito no fim de renovações futuras.
      </p>
      <p>
        O período grátis conta da data em que você entrou na campanha até o
        final dela, que foi em 06/08/2020. Como você entrou nela em{' '}
        {getHumanReadableDate(user.covid19CancellationRequest.createdAt)}, você
        vai receber {user.covid19CancellationRequest.extendForMonths} meses de
        acesso gratuito.
      </p>
      <p>
        Caso queira manter o seu plano, não se preocupe, pois o período gratuito
        vai ficar guardado {':))'}
      </p>

      <KeepSubscriptionButton subscription={subscription} />

      <CancelSubscriptionButton />
    </Container>
  );
}

const Container = styled.div`
  p {
    margin-top: 5px;
    font-size: 0.8em;
    font-weight: bold;
  }
`;
