import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCircle } from '@fortawesome/free-regular-svg-icons';
import { LockOutlined } from '@material-ui/icons';

import { styled, colors } from 'styles';
import { IconContainer, PathLine } from '../shared';
import { useWorkspaceFlags } from 'modules/workspace/hooks/shared';
import {
  useWorkspace,
  useWorkspacePermission,
} from 'modules/workspace/hooks/main';
import { useUserContext } from 'providers/UserProvider';
import { useUTM } from 'modules/shared/hooks';

export default function TopicLink({ current, open, topic, guideId, chapter }) {
  const { isLastTopic } = useWorkspace();
  const { hasAccessToTopic } = useWorkspacePermission();
  const {
    getTopicFlag,
    saveTopicFlag,
    saveChapterFlag,
    removeTopicFlag,
    isOnlyTopicWithoutFlag,
  } = useWorkspaceFlags();
  const { user } = useUserContext();
  const { utmParamsWithoutInternalSource } = useUTM();

  const isLogged = useMemo(() => {
    return !!user;
  }, [user]);

  const topicLinkUrl = useMemo(() => {
    if (guideId)
      return `/aprender/${guideId}/topico/${chapter.subjectId}/${topic.id}/teoria/${topic.theory.id}${utmParamsWithoutInternalSource}`;

    return `/aprender/topico/${chapter.subjectId}/${topic.id}/teoria/${topic.theory.id}${utmParamsWithoutInternalSource}`;
  }, [chapter, topic, guideId, utmParamsWithoutInternalSource]);

  const isFlagged = useMemo(() => {
    return !!getTopicFlag(topic.id, guideId);
  }, [topic, getTopicFlag, guideId]);

  const pathLineClass = useMemo(() => {
    const lastTopicClass = isLastTopic(chapter, topic) ? 'last' : '';
    const openClass = open ? 'open' : '';
    const flaggedClass = isFlagged ? 'flagged' : '';
    return `${lastTopicClass} ${openClass} ${flaggedClass}`;
  }, [isLastTopic, chapter, topic, open, isFlagged]);

  const hasAccess = useMemo(() => {
    return hasAccessToTopic(topic, chapter);
  }, [hasAccessToTopic, topic, chapter]);

  const onIconClick = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      if (!isLogged) return;
      if (isFlagged) {
        removeTopicFlag(topic.id, guideId);
        return;
      }

      if (isOnlyTopicWithoutFlag(topic, chapter, guideId)) {
        saveChapterFlag(chapter.id, guideId);
      }
      saveTopicFlag(topic.id, guideId);
    },
    [
      isLogged,
      isFlagged,
      topic,
      chapter,
      guideId,
      isOnlyTopicWithoutFlag,
      saveTopicFlag,
      removeTopicFlag,
      saveChapterFlag,
    ]
  );

  const squareIconClass = useMemo(() => {
    const flaggedClass = isFlagged ? 'flagged' : '';
    const isLoggedClass = isLogged ? 'logged' : '';
    return `${flaggedClass} ${isLoggedClass}`;
  }, [isFlagged, isLogged]);

  return (
    <Container
      className={current ? 'current' : ''}
      to={topicLinkUrl}
      onClick={e => e.stopPropagation()}
    >
      <IconContainer
        className={open ? 'open' : ''}
        onClick={onIconClick}
        data-cy="side-menu-check-icon"
      >
        {hasAccess ? (
          <>
            <SquareIcon
              icon={isLogged ? faSquare : faCircle}
              className={squareIconClass}
            />
            {isFlagged && <CheckInsideSquareIcon icon={faCheck} />}
          </>
        ) : (
          <LockIcon />
        )}
      </IconContainer>
      {topic.name}
      {isLogged && <PathLine className={pathLineClass} />}
    </Container>
  );
}

const Container = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.85em;
  color: #bbb;
  padding: 15px 10px;

  &.current {
    color: #fff;
    font-weight: bold;
    font-size: 0.9em;
  }
`;

const LockIcon = styled(LockOutlined)`
  position: relative;
  left: -3px;
  color: #ccc;
  font-size: 20px !important;
`;

const SquareIcon = styled(FontAwesomeIcon)`
  color: #ccc;
  font-size: 16px;

  &:hover {
    &.logged {
      color: ${colors.check};
    }

    text-shadow: 0 0 7px rgb(126 212 138 / 70%);
  }

  &.flagged {
    color: ${colors.check};
  }
`;

const CheckInsideSquareIcon = styled(SquareIcon)`
  position: absolute;
  left: 2px;
  top: 2.5px;
  bottom: 0;
  font-size: 14px;
  color: ${colors.check};
`;
