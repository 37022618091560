import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useFlag } from 'modules/shared/hooks';
import { useWorkspaceFlagsContext } from 'modules/workspace/providers/WorkspaceFlagsProvider';

export default function useWorkspaceFlags() {
  const { saveFlag, removeFlag } = useFlag();
  const { flags, setFlags } = useWorkspaceFlagsContext();

  const getTopicFlag = useCallback(
    (topicId, guideId = null) => {
      return flags.find(
        flag =>
          flag.contentType === 'topico' &&
          flag.guideId == guideId &&
          flag.contentId == topicId &&
          flag.name === 'check'
      );
    },
    [flags]
  );

  const getChapterFlag = useCallback(
    (chapterId, guideId = null) => {
      return flags.find(
        flag =>
          flag.contentType === 'capitulo' &&
          flag.guideId == guideId &&
          flag.contentId == chapterId &&
          flag.name === 'check'
      );
    },
    [flags]
  );

  const getExerciseFlag = useCallback(
    (exerciseId, exerciseType, guideId = null) => {
      return flags.find(
        flag =>
          flag.contentType === exerciseType &&
          flag.guideId == guideId &&
          flag.contentId == exerciseId
      );
    },
    [flags]
  );

  const isOnlyTopicWithoutFlag = useCallback(
    (topic, chapter, guideId) => {
      const topicsWithFlag = chapter.topics.filter(topic => {
        return !!getTopicFlag(topic.id, guideId);
      });
      if (topicsWithFlag.length !== chapter.topics.length - 1) return false;

      return !topicsWithFlag.find(t => t.id == topic.id);
    },
    [getTopicFlag]
  );

  const saveFlagLocal = useCallback(
    (contentType, contentId, guideId = null, name = 'check') => {
      const newFlag = {
        id: uuidv4(),
        name,
        contentType,
        contentId,
        guideId,
      };
      setFlags(prevFlags => [...prevFlags, newFlag]);
    },
    [setFlags]
  );

  const saveTopicFlag = useCallback(
    (topicId, guideId = null) => {
      saveFlagLocal('topico', topicId, guideId);
      return saveFlag('check', 'topico', topicId, guideId);
    },
    [saveFlag, saveFlagLocal]
  );

  const saveChapterFlag = useCallback(
    (chapterId, guideId = null) => {
      saveFlagLocal('capitulo', chapterId, guideId);
      return saveFlag('check', 'capitulo', chapterId, guideId);
    },
    [saveFlag, saveFlagLocal]
  );

  const saveExerciseFlag = useCallback(
    (exerciseId, exerciseType, guideId, optionName) => {
      saveFlagLocal(exerciseType, exerciseId, guideId, optionName);
      return saveFlag(optionName, exerciseType, exerciseId, guideId);
    },
    [saveFlag, saveFlagLocal]
  );

  const removeFlagLocal = useCallback(
    (contentType, contentId, guideId = null, name = 'check') => {
      setFlags(prevFlags =>
        prevFlags.filter(flag => {
          return !(
            flag.name === name &&
            flag.contentType === contentType &&
            flag.contentId == contentId &&
            flag.guideId == guideId
          );
        })
      );
    },
    [setFlags]
  );

  const removeTopicFlag = useCallback(
    (topicId, guideId = null) => {
      removeFlagLocal('topico', topicId, guideId);
      return removeFlag('check', 'topico', topicId, guideId);
    },
    [removeFlag, removeFlagLocal]
  );

  const removeChapterFlag = useCallback(
    (chapterId, guideId = null) => {
      removeFlagLocal('capitulo', chapterId, guideId);
      return removeFlag('check', 'capitulo', chapterId, guideId);
    },
    [removeFlag, removeFlagLocal]
  );

  const removeExerciseFlag = useCallback(
    (exerciseId, exerciseType, guideId, optionName) => {
      removeFlagLocal(exerciseType, exerciseId, guideId, optionName);
      return removeFlag(optionName, exerciseType, exerciseId, guideId);
    },
    [removeFlag, removeFlagLocal]
  );

  return {
    flags,
    getTopicFlag,
    getChapterFlag,
    saveTopicFlag,
    saveChapterFlag,
    removeTopicFlag,
    removeChapterFlag,
    isOnlyTopicWithoutFlag,
    getExerciseFlag,
    removeExerciseFlag,
    saveExerciseFlag,
  };
}
