import React, { useCallback, useState } from 'react';
import ReactPlayer from 'react-player';

import { styled, media } from 'styles';
import { useWorkspaceVideo } from 'modules/workspace/hooks/shared';

export default function Video({
  video,
  setVideoDuration,
  trackFirstPlayVideoMethod,
}) {
  const [hasAlreadyPlayedVideo, setHasAlreadyPlayedVideo] = useState(false);
  const { getVideoUrl } = useWorkspaceVideo();

  const handlePlayVideo = useCallback(() => {
    if (!hasAlreadyPlayedVideo) {
      trackFirstPlayVideoMethod(video.provider);
    }
    setHasAlreadyPlayedVideo(true);
  }, [
    hasAlreadyPlayedVideo,
    setHasAlreadyPlayedVideo,
    trackFirstPlayVideoMethod,
    video,
  ]);

  return (
    <VideoContainer>
      <ReactPlayer
        data-cy="video-iframe"
        url={getVideoUrl(video)}
        allowFullScreen={true}
        frameBorder="0"
        controls={true}
        width="100%"
        height="100%"
        onDuration={duration => setVideoDuration(duration)}
        onPlay={handlePlayVideo}
        config={{
          vimeo: {
            playerOptions: {
              title: true,
              byline: true,
              portrait: true,
            },
          },
        }}
      />
    </VideoContainer>
  );
}

const VideoContainer = styled.div`
  height: 474px;
  margin-bottom: 10px;

  @media ${media.mobile} {
    height: 200px;
  }
`;
