import React, { useCallback, useMemo, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { styled, media, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker, useDate } from 'modules/shared/hooks';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import CancelSuccess from './CancelSuccess';

export default function CancelConfirmation({
  subscription,
  selectedReason,
  selectedDetailedReason,
  otherReason,
}) {
  const { trackEvent } = useTracker();
  const { unsetDialog, setDialog } = useDialogContext();
  const { getHumanReadableDateFromTimestamp } = useDate();
  const { hasCreditCardTrial } = useBookTrialCampaign();
  const [loading, setLoading] = useState(false);

  const cancelSubscriptionReq = useRAPostRequest('subscriptions/cancel');

  useEffect(() => {
    trackEvent('Fluxo de Cancelamento: Visualizou Confirmacao Cancelamento', {
      'Motivo Generico': selectedReason?.text,
      'Motivo Detalhado': selectedDetailedReason?.text,
      'Outro Motivo': otherReason,
    });
  }, [trackEvent, selectedReason, selectedDetailedReason, otherReason]);

  const handleBackButtonPress = useCallback(() => {
    unsetDialog();
    trackEvent(
      'Fluxo de Cancelamento: Desistiu de Cancelar na Confirmacao Cancelamento',
      {
        'Motivo Generico': selectedReason?.text,
        'Motivo Detalhado': selectedDetailedReason?.text,
        'Outro Motivo': otherReason,
      }
    );
  }, [
    unsetDialog,
    trackEvent,
    selectedReason,
    selectedDetailedReason,
    otherReason,
  ]);

  const handleContinuePress = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    try {
      await cancelSubscriptionReq({
        subscriptionId: subscription.id,
        genericReason: selectedReason?.text,
        genericReasonId: selectedReason?.id,
        detailedReason: selectedDetailedReason?.text,
        detailedReasonId: selectedDetailedReason?.id,
        otherReason: otherReason,
      });

      const expiresInTwoMinutes = new Date(
        new Date().getTime() + 2 * 60 * 1000
      );
      Cookies.set('cancel_subscription_processing', true, {
        domain: process.env.REACT_APP_RESPONDEAI_DOMAIN,
        expires: expiresInTwoMinutes,
      });

      setDialog(
        <CancelSuccess
          subscription={subscription}
          selectedReason={selectedReason}
          selectedDetailedReason={selectedDetailedReason}
          otherReason={otherReason}
        />
      );
    } catch (e) {
      alert(ERROR_MESSAGE);
      setLoading(false);
      return;
    }
  }, [
    cancelSubscriptionReq,
    setDialog,
    setLoading,
    subscription,
    selectedReason,
    selectedDetailedReason,
    otherReason,
    loading,
  ]);

  const cancelText = useMemo(() => {
    if (subscription.plan.months === 1) return 'Cancelar a Assinatura';
    else return 'Desativar a Renovação';
  }, [subscription]);

  const subscriptionExpiresIn = useMemo(() => {
    return getHumanReadableDateFromTimestamp(subscription.expiresIn);
  }, [subscription, getHumanReadableDateFromTimestamp]);

  const renderInstallmentsInfo = useCallback(() => {
    return 'As parcelas restantes da assinatura ainda serão cobradas de acordo com a duração do seu plano.';
  }, []);

  return (
    <Container>
      <Content>
        <CancelStrip>{cancelText}</CancelStrip>

        <ConfirmationAsk>Deseja continuar e {cancelText}?</ConfirmationAsk>

        {hasCreditCardTrial() ? (
          <ConfirmationInfo>
            Ao {cancelText} você garante que não será cobrado. Porém, como você
            está dentro do período gratuito de teste, seu acesso ao conteudo
            será bloqueado.
          </ConfirmationInfo>
        ) : (
          <ConfirmationInfo>
            Ao {cancelText} você garante que seu plano não renovará novamente e
            seu acesso vai até {subscriptionExpiresIn}.
            {subscription.plan.months > 1 && renderInstallmentsInfo()}
          </ConfirmationInfo>
        )}

        {!hasCreditCardTrial() && (
          <ConfirmationInfo>
            Não se esqueça: cancelar a assinatura não garante o estorno. Você
            pode verificar as nossas políticas de estorno{' '}
            <a
              target="_blank"
              href="https://www.respondeai.com.br/faq"
              rel="noopener noreferrer"
            >
              aqui
            </a>{' '}
            e, caso se encaixe nelas, pode enviar um e-mail para
            faleai@respondeai.com.br com o título "ESTORNO" que te respondemos
            em até 48h.
          </ConfirmationInfo>
        )}

        <ButtonsContainer>
          <button onClick={handleBackButtonPress} type="button">
            Não quero cancelar
          </button>

          <button onClick={handleContinuePress} className="enable">
            {loading ? 'Cancelando assinatura...' : 'Confirmar o cancelamento'}
          </button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const ERROR_MESSAGE =
  'Erro ao cancelar sua assinatura. Tente novamente e caso o erro persista, entre em contato com o atendimento.';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-top: 10px solid ${colors.warningRed};
  text-align: left;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const CancelStrip = styled.div`
  display: inline-block;
  padding: 3px 10px;
  position: relative;
  left: -48px;
  margin-bottom: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${colors.warningRed};
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  @media ${media.mobile} {
    left: -24px;
  }
`;

const ConfirmationAsk = styled.h1`
  font-weight: bold;
  color: ${colors.warningRed};
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 30px;
`;

const ConfirmationInfo = styled.p`
  width: 100%;
  line-height: 1.2;
  margin: 25px 0;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;

  button {
    flex: 1;
    margin: 0 10px;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    border: 2px solid #929292;
    color: #929292;
    text-transform: uppercase;

    &.enable {
      border-color: ${colors.warningRed};
      color: ${colors.warningRed};
    }
  }

  @media ${media.mobile} {
    flex-direction: column-reverse;

    button {
      width: 100%;
      margin: 5px 0;
      padding: 10px 0;
    }
  }
`;
