import React, { useCallback } from 'react';
import { styled } from 'styles';
import { useRAQueryImmutable } from 'modules/shared/hooks/requests';

const RESPONDEAI_URL = process.env.REACT_APP_RESPONDEAI_URL;

export default function TrialBar() {
  const { data: trialInfo, loading, error } = useRAQueryImmutable(
    '/banners/banners_data/warning_bar/trial_data'
  );

  const getCopyOfTrialBar = useCallback(() => {
    return trialInfo?.trialText;
  }, [trialInfo]);

  if (error) return null;
  if (loading) return null;

  return (
    <TrialBarStyled
      onClick={() => window.open(`${RESPONDEAI_URL}${trialInfo?.trialUrl}`)}
    >
      <span dangerouslySetInnerHTML={{ __html: getCopyOfTrialBar() }} />
    </TrialBarStyled>
  );
}

const TrialBarStyled = styled.div`
  background: #ead308;
  width: 100%;
  height: 1.5rem;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
