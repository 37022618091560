import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { colors, media, styled } from 'styles';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useUserContext } from 'providers/UserProvider';
import MultilineText from 'modules/shared/components/MultilineText';
import QuestionUserProfile from '../generic/QuestionUserProfile';
import { useToast } from 'modules/shared/hooks';
import ImageZoomDialog from '../ImageZoomDialog';
import { useDialogContext } from 'providers/DialogProvider';
import WarningText from '../generic/WarningText';
import { css, keyframes } from 'styled-components';
import ShareButton from '../generic/ShareButton';
import ActionButton from '../generic/ActionButton';
import {
  downvote,
  downvoted,
  downvoteHover,
  upvote,
  upvoted,
  upvoteHover,
  verified,
  verify,
  verifyHover,
} from 'images/forum';
import MenuButton from '../generic/MenuButton';
import ForumReportInputDialog from '../dialogs/ForumReportInputDialog';
import CreateOrUpdateAnswer from '../generic/CreateOrUpdateAnswer';
import DeletePostConfirmation from '../generic/DeletePostConfirmation';
import useForumTracker from 'modules/forum/hooks/useForumTracker';
import { MathjaxContentLatex } from 'modules/shared/components/generic';

export default function Answer({
  question,
  answer: a,
  onDeleteImage,
  onMarkAsCorrect,
  focus = false,
  shouldShowCreateOrUpdateAnswer,
  setShouldShowCreateOrUpdateAnswer,
  setQuestion,
  answerToEdit,
  setAnswerToEdit,
}) {
  const {
    trackVotedInAnswer,
    trackClickToShare,
    trackClickToReport,
    trackReport,
    trackDeletedAnswer,
  } = useForumTracker();
  const { user } = useUserContext();
  const [answer, setAnswer] = useState(a);

  const { errorToast } = useToast();
  const [upVotes, setUpVotes] = useState(0);
  const [downVotes, setDownVotes] = useState(0);

  const { setDialog } = useDialogContext();

  const voteAnswer = useRAPostRequest(`/ra_forum/answers/${answer.id}/vote`);

  const deleteAnswerReq = useRAPostRequest(
    `/ra_forum/answers/${answer.id}/delete`
  );

  const markAsCorrectReq = useRAPostRequest(
    `/ra_forum/answers/${answer.id}/mark_as_correct`
  );

  const deleteAnswerFileReq = useRAPostRequest(
    `/ra_forum/answers/${answer.id}/delete_file`
  );

  useEffect(() => {
    setAnswer(a);
  }, [a]);

  const isMyQuestion = useMemo(() => {
    return question.user.id === user.id;
  }, [question, user]);

  const markAsCorrect = useCallback(async () => {
    try {
      const { data } = await markAsCorrectReq();

      if (data.ok && onMarkAsCorrect) onMarkAsCorrect(data.answer.correct);
    } catch (e) {
      errorToast('Erro ao marcar resposta como correta!');
    }
  }, [errorToast, markAsCorrectReq, onMarkAsCorrect]);

  useEffect(() => {
    const upVotes = answer.votes.filter(vote => vote.vote === 'up');
    const downVotes = answer.votes.filter(vote => vote.vote === 'down');

    setUpVotes(upVotes.length);
    setDownVotes(downVotes.length);
  }, [answer]);

  const onRemoveImage = useCallback(async () => {
    try {
      await deleteAnswerFileReq();
      onDeleteImage();
    } catch (e) {
      errorToast('Erro ao excluir resposta!');
    }
  }, [deleteAnswerFileReq, onDeleteImage, errorToast]);

  const deleteAnswer = useCallback(async () => {
    try {
      await deleteAnswerReq();
      setQuestion({
        ...question,
        answers: question.answers.filter(a => a.id !== answer.id),
      });
      trackDeletedAnswer();
    } catch (e) {
      errorToast('Erro ao excluir resposta!');
    }
  }, [
    deleteAnswerReq,
    errorToast,
    trackDeletedAnswer,
    setQuestion,
    question,
    answer,
  ]);

  const openDeletePostConfirmation = useCallback(() => {
    setDialog(<DeletePostConfirmation onSubmit={deleteAnswer} />);
  }, [deleteAnswer, setDialog]);

  const report = useCallback(async () => {
    trackClickToReport('Resposta');
    setDialog(
      <ForumReportInputDialog
        data={answer}
        contentType={'answer'}
        trackReport={() => trackReport('Resposta')}
      />
    );
  }, [answer, setDialog, trackClickToReport, trackReport]);

  const downVote = useCallback(async () => {
    const { data } = await voteAnswer({
      vote_action: 'down',
    });

    trackVotedInAnswer('down');
    setAnswer(data);
  }, [trackVotedInAnswer, voteAnswer]);

  const upVote = useCallback(async () => {
    const { data } = await voteAnswer({
      vote_action: 'up',
    });

    trackVotedInAnswer('up');
    setAnswer(data);
  }, [voteAnswer, trackVotedInAnswer]);

  const upVoted = useMemo(
    () =>
      answer.votes.some(vote => vote.userId === user.id && vote.vote === 'up'),
    [answer.votes, user]
  );

  const downVoted = useMemo(
    () =>
      answer.votes.some(
        vote => vote.userId === user.id && vote.vote === 'down'
      ),
    [answer.votes, user]
  );

  const waitingApproval = useMemo(() => !answer.visible, [answer]);
  const isMyAnswer = useMemo(() => user.id === answer?.user?.id, [
    user,
    answer,
  ]);

  const openEditionQuestion = useCallback(() => {
    setShouldShowCreateOrUpdateAnswer(true);
    setAnswerToEdit(answer);
  }, [setShouldShowCreateOrUpdateAnswer, setAnswerToEdit, answer]);

  if (answer?.id === answerToEdit?.id && shouldShowCreateOrUpdateAnswer) {
    return (
      <CreateOrUpdateAnswer
        action="edition"
        answer={answer}
        question={question}
        setQuestion={setQuestion}
        setShouldShowCreateOrUpdate={setShouldShowCreateOrUpdateAnswer}
      />
    );
  }

  return (
    <AnswerContainer key={answer.id} focus={focus}>
      <AnswerHeader>
        <QuestionUserProfile
          createdAt={answer.createdAt}
          user={answer.user}
          university={answer.university.acronym}
          universityCourse={answer.universityCourse?.name}
          size={'small'}
        />
        <MenuButton
          onClickEdit={isMyAnswer ? openEditionQuestion : null}
          onClickDelete={isMyAnswer ? openDeletePostConfirmation : null}
          onClickReport={isMyAnswer ? null : report}
        />
      </AnswerHeader>
      <AnswerBody waitingApproval={waitingApproval}>
        {waitingApproval && <WarningText type="answer" />}
        <ContainerCorrectInfo>
          {answer?.correct && <CorrectAnswer />}
          {isMyQuestion && (
            <ActionButton
              image={verify}
              hoverImage={verifyHover}
              text={answer?.correct ? 'Tirar Destaque' : 'Destacar Resposta'}
              onClick={markAsCorrect}
            />
          )}
        </ContainerCorrectInfo>
        {answer.hasFormula ? (
          <MathjaxContentLatex textWithLatex={answer.body} />
        ) : (
          <MultilineText text={answer.body} id={`a-${answer.id}`} />
        )}

        {answer.fileUrl && (
          <QuestionImage
            src={answer.fileUrl}
            alt="Imagem da pergunta"
            onClick={() =>
              setDialog(
                <ImageZoomDialog
                  url={answer.fileUrl}
                  filename={answer.fileUrl.split('_____')[1]}
                  onRemoveImage={isMyAnswer && onRemoveImage}
                />
              )
            }
          />
        )}
      </AnswerBody>

      <AnswerLine style={{ marginTop: '10px', marginLeft: '60px' }}>
        <ActionButton
          className={'upvote'}
          dataCy="upvote"
          image={upVoted ? upvoted : upvote}
          text={upVotes}
          hoverImage={upvoteHover}
          onClick={upVote}
          underline={false}
          showTextOnMobile
        />

        <ActionButton
          className={'downvote'}
          dataCy="downvote"
          image={downVoted ? downvoted : downvote}
          hoverImage={downvoteHover}
          text={downVotes}
          onClick={downVote}
          underline={false}
          showTextOnMobile
          shouldHideText={!isMyAnswer}
        />

        <ShareButton
          questionId={question.id}
          answerId={answer.id}
          trackClickToShare={trackClickToShare}
        />
      </AnswerLine>
    </AnswerContainer>
  );
}

function CorrectAnswer() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '8px 0',
      }}
    >
      <img src={verified} alt="Resposta destacada" />
      <span
        style={{
          marginLeft: '5px',
          color: 'green',
          fontWeight: 'bold',
        }}
      >
        Resposta destacada
      </span>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const focusAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

// eslint-disable-next-line no-unused-vars
const backgroundAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: ${colors.mainOrange};
  }
  100% {
    background-color: #fff;
  }
`;

const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px 0;

  ${({ focus }) =>
    focus &&
    css`
      animation: ${backgroundAnimation} 3s ease-in-out;
    `}
`;

const AnswerBody = styled.div`
  margin-top: 10px;
  margin-left: 60px;

  color: #727272;

  ${({ waitingApproval }) =>
    waitingApproval &&
    css`
      color: gray;
    `}
`;

const AnswerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;

  > span {
    margin-right: 5px;
    display: none;

    @media ${media.mobile} {
      display: block;
    }
  }

  &:hover {
    span {
      display: flex;
    }
  }
`;

const AnswerLine = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 5px;
  }
`;

const QuestionImage = styled.img`
  max-height: 100px;
  object-fit: scale-down;
  align-self: flex-start;

  /* zoom cursor */
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
`;

const ContainerCorrectInfo = styled.div`
  display: flex;
  width: 45%;
  justify-content: space-between;

  @media ${media.mobile} {
    width: 100%;
  }
`;
