import React, { useCallback, useEffect } from 'react';
import { styled, media } from 'styles';
import { DoubleButtonContainer } from './styles';
import { dexterBlink } from 'images/permission/index';
import { useUserContext } from 'providers/UserProvider';
import { useTracker } from 'modules/shared/hooks';
export default function SuccessSession({
  onSubmitInfo,
  onFormBack,
  discipline,
  selectedTheme,
  materialType,
  testInfo,
}) {
  const { user } = useUserContext();
  const { trackEvent } = useTracker();
  const handleBack = useCallback(() => {
    onFormBack();
    trackEvent('Listas: Dialog de Sucesso Clicou em Enviar Outra');
  }, [onFormBack, trackEvent]);

  const handleNext = useCallback(() => {
    onSubmitInfo();
  }, [onSubmitInfo]);

  useEffect(() => {
    trackEvent('Listas: Visualizou Dialog de Sucesso', {
      'Tipo de Material': materialType,
      Matéria: selectedTheme?.name,
      Disciplina: discipline?.name,
      Categoria: testInfo?.category,
      Ano: testInfo?.year,
      Período: testInfo?.period,
    });
  }, [trackEvent, materialType, selectedTheme, discipline, testInfo]);

  return (
    <Container>
      <img src={dexterBlink}></img>
      <Title>
        <h1>Prova enviada com sucesso! </h1>
      </Title>
      <SuccessMessage>
        Seu arquivo foi enviado para a análise.<br></br> Se for aprovado, vamos
        resolver as questões e disponibilizar para você e todos os alunos da sua
        faculdade
      </SuccessMessage>
      <CommunicationMessage>
        Você receberá essa e outras informações pelo seu email
        {` ${user?.email}`}
      </CommunicationMessage>
      <DoubleButtonContainer>
        <Button backButton onClick={handleBack}>
          ENVIAR OUTRA
        </Button>
        <Button canSubmit onClick={handleNext}>
          CONTINUAR
        </Button>
      </DoubleButtonContainer>
    </Container>
  );
}

const SuccessMessage = styled.div`
  color: #888888;
  max-width: 400px;
  font-size: 15px;
  line-height: 18px;
  padding-bottom: 45px;
`;

const CommunicationMessage = styled.div`
  color: #888888;
  max-width: 400px;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  padding-bottom: 45px;

  @media ${media.mobile} {
    padding: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-weight: 300;
  }

  position: sticky;
  z-index: 3;

  img {
    width: 86px;
  }
`;

export const Button = styled.button`
  width: 48%;
  border: 1px solid
    ${({ backButton, canSubmit }) =>
      canSubmit || backButton ? '#f6ac3a' : '#B8B8B8'};
  border-radius: 10px;

  color: ${({ backButton }) => (backButton ? '#f6ac3a' : '#fff')};
  background-color: ${({ backButton, canSubmit }) =>
    backButton ? '#fff' : canSubmit ? '#f6ac3a' : '#B8B8B8'};
  margin: 45px 0px;
  padding: 15px;

  font-weight: bold;
  font-size: 1.1em;

  cursor: pointer;

  position: sticky;
  z-index: 2;

  @media ${media.mobile} {
    margin: 10px;
    width: 100%;
  }
`;

const Title = styled.div`
  color: #888;
  font-weight: bold;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 15px;
  h1 {
    font-size: 2.2em;

    @media ${media.mobile} {
      font-size: 2em;
    }
  }

  h3 {
    font-size: 1.1em;
    margin-top: 10px;
    line-height: 20px;

    @media ${media.mobile} {
      font-size: 1em;
    }
  }
`;
