import React, { useCallback, useEffect, useState } from 'react';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { MainContainer, CenterContainer, Button } from './styles';
import { useTracker } from 'modules/shared/hooks';

import CourseSelect from './components/CourseSelect';
import AdmissionSelects from './components/AdmissionSelects';
import Header from './components/Header';
import ErrorMessage from './components/ErrorMessage';
import { useProfileComplete } from 'modules/profile/hooks';

export default function CourseInfoForm() {
  const { trackEvent } = useTracker();

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const { sendToCorrectUrl } = useProfileComplete();
  const sendUserInfo = useRAPostRequest('profile/update_course_info');

  useEffect(() => {
    trackEvent('Visualizou Dialog de Qualificação');
  }, [trackEvent]);

  const canSendForm = useCallback(() => {
    let error = '';

    if (!selectedCourse) {
      error = 'Você precisa selecionar um curso';
    } else if (!selectedYear) {
      error = 'Você precisa selecionar um ano';
    } else if (!selectedPeriod) {
      error = 'Você precisa selecionar um período';
    }

    setError(error);

    setTimeout(() => {
      setError('');
    }, 3000);

    return error === '';
  }, [selectedYear, selectedPeriod, selectedCourse, setError]);

  const updateProfile = useCallback(async () => {
    if (!canSendForm()) return;
    setSaving(true);
    const params = {
      courseId: selectedCourse?.id,
      year: selectedYear,
      period: selectedPeriod,
    };

    let error = '';
    try {
      await sendUserInfo(params);
    } catch (e) {
      error =
        'Algo de errado ocorreu ao atualizar as informações. Tente novamente.';
    } finally {
      if (error) {
        setError(error);

        setTimeout(() => {
          setError('');
        }, 3000);
        setSaving(false);
      } else {
        sendToCorrectUrl();
      }
    }
  }, [
    selectedYear,
    selectedPeriod,
    selectedCourse,
    sendUserInfo,
    canSendForm,
    setError,
    sendToCorrectUrl,
  ]);

  return (
    <MainContainer id="profileCompleterDialogContainer">
      <Header copy={'Quase lá! Complete seu curso e período de ingresso.'} />

      <CenterContainer>
        <ErrorMessage error={error} />
        <>
          <>
            <CourseSelect setSelectedCourse={setSelectedCourse} />
            <AdmissionSelects
              onChangePeriod={setSelectedPeriod}
              onChangeYear={setSelectedYear}
            />
          </>

          <Button onClick={updateProfile} saving={saving}>
            {saving ? 'ENVIANDO...' : 'ENVIAR'}
          </Button>
        </>
      </CenterContainer>
    </MainContainer>
  );
}
