import React, { useEffect } from 'react';
import { media, styled } from 'styles';
import ResultItem from './ResultItem';
import useSearchTracker from '../hooks/useSearchTracker';
import { useSearchContext } from '../providers/SearchProvider';

export default function ResultsContainer() {
  const { results, searchType, searchTerm, file } = useSearchContext();
  const { trackViewSerpResults, trackViewResultsByText } = useSearchTracker();

  useEffect(() => {
    if (!results) return;

    if (searchType === 'image') trackViewSerpResults(results, file.name);
    if (searchType === 'text') trackViewResultsByText(searchTerm);

    // When searchTerm update, we do not want to trigger the events. Only after bounce
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, trackViewSerpResults, searchType, trackViewResultsByText]);

  if (!results) return null;
  if (results.length === 0)
    return <NoResults>Nenhum resultado foi encontrado</NoResults>;

  return (
    <Container data-cy="search-results-container">
      {results.map((result, index) => (
        <ResultItem key={result._id} result={result} resultPosition={index} />
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: auto;
  overflow: auto;
  width: 85%;
  max-width: 700px;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
  background-color: white;

  @media ${media.mobile} {
    margin: 9px auto;
    width: 94%;
  }
`;

const NoResults = styled.p`
  font-weight: bold;
  margin-top: 5px;
  font-size: 1.2rem;
`;
