export function getRecaptchaToken(action = 'default') {
  if (process.env.NODE_ENV !== 'production')
    return new Promise(resolve => resolve(''));

  return new Promise(resolve => {
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise
        .execute(process.env.REACT_APP_RECAPTCHA_TOKEN, { action: action })
        .then(token => resolve(token))
        .catch(() => resolve(''));
    });
  });
}
