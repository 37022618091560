import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { media, styled } from 'styles';
import { copyPasteImage, wppIconImage } from 'images/shareContainer';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function ShareLinkContainer({
  wppMessageEncoded,
  trackContentCallback,
}) {
  const [openCopiedDisclaimer, setOpenCopiedDisclaimer] = useState(false);

  const wppUrl = useMemo(() => {
    return `https://api.whatsapp.com/send?text=${wppMessageEncoded}`;
  }, [wppMessageEncoded]);

  const onWppShareClick = useCallback(() => {
    trackContentCallback('WhatsApp');
    window.open(wppUrl, '_blank');
  }, [trackContentCallback, wppUrl]);

  useEffect(() => {
    const closeDisclaimer = setTimeout(() => {
      setOpenCopiedDisclaimer(false);
    }, 750);

    return () => clearTimeout(closeDisclaimer);
  }, [openCopiedDisclaimer]);

  return (
    <Container>
      <CopyToClipboard
        text={`${window.location}?utm_source=share`}
        onCopy={() => setOpenCopiedDisclaimer(true)}
      >
        <Circle color="#00B8D6">
          <img
            src={copyPasteImage}
            onClick={() => trackContentCallback('Link direto')}
            className="share-icon"
          />
        </Circle>
      </CopyToClipboard>
      <Circle color="#00A859">
        <img
          src={wppIconImage}
          onClick={() => onWppShareClick()}
          className="wpp-icon"
        />
      </Circle>
      <CopiedDisclaimer openCopiedDisclaimer={openCopiedDisclaimer}>
        <div></div>
        <p>Link copiado com sucesso!</p>
      </CopiedDisclaimer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
`;

const Circle = styled.div`
  border: 2px solid ${props => props.color};
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 7px;
  cursor: pointer;

  .share-icon {
    width: 18px;
    height: auto;
    margin-left: -2px;
  }

  .wpp-icon {
    width: 35px;
    height: auto;
    margin-left: 1.5px;
  }

  @media ${media.mobile} {
    width: 30px;
    height: 30px;

    .share-icon {
      width: 13px;
    }

    .wpp-icon {
      width: 27px;
    }
  }
`;

const CopiedDisclaimer = styled.div`
  width: 157px;
  height: 22.7px;
  display: ${({ openCopiedDisclaimer }) =>
    openCopiedDisclaimer ? 'flex' : 'none'};
  transition: display 500ms ease-in-out;
  align-items: center;
  justify-content: center;
  background: #7e7e7e;
  position: absolute;
  bottom: -33px;
  left: -58px;
  z-index: 10;

  div {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #7e7e7e;
    position: absolute;
    top: -4px;
  }

  p {
    border-radius: 3.15261px;
    font-family: Lato;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }
`;
