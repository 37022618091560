import { useMemo, useCallback } from 'react';

import { useWorkspaceExercise } from 'modules/workspace/hooks/shared';
import { useWorkspaceListExerciseContext } from 'modules/workspace/providers/WorkspaceListExerciseProvider';
import { useUserContext } from 'providers/UserProvider';

export default function useWorkspaceListExerciseErrorReport() {
  const {
    guide,
    currentTheme,
    contentTitle,
    contentSubtitle,
    currentList,
    currentContent,
  } = useWorkspaceListExerciseContext();
  const { user } = useUserContext();
  const { getExerciseIndexFromExercises } = useWorkspaceExercise();

  const errorReportGuidableTypes = useMemo(
    () => ({
      Default: 'Default',
      StudyPlans: 'Plano de Estudos',
      Disciplines: 'Guia Personalizado',
      StudyTracks: 'Guia Personalizado (Privadas)',
    }),
    []
  );

  const errorReportLocation = useMemo(() => {
    if (!currentContent) return '';

    const themeName = `${currentTheme.name} - ${currentTheme.id}`;
    const disciplineName = `${errorReportGuidableTypes[guide?.guidable] ||
      'Default'} (${contentTitle} - ${contentSubtitle || ''})`;
    const university = user?.university?.acronym;
    const campus = user?.universityCampus?.name || '';

    const exerciseIndex = getExerciseIndexFromExercises(
      currentContent,
      currentList?.listExercises
    );
    const exerciseName = exerciseIndex + 1;

    return `
        Matéria: ${themeName}
        Disciplina: ${disciplineName}
        Origem: ${currentContent.metadata}
        Exercício de Lista: ${exerciseName}
        Universidade: ${university}
        Campus: ${campus}
        Url do conteúdo: ${location.href}
      `;
  }, [
    getExerciseIndexFromExercises,
    currentList,
    currentTheme,
    currentContent,
    contentTitle,
    contentSubtitle,
    user,
    guide,
    errorReportGuidableTypes,
  ]);

  const getErrorReportData = useCallback(() => {
    return {
      type: 'exercício_de_prova',
      location: errorReportLocation,
    };
  }, [errorReportLocation]);

  return { getErrorReportData };
}
