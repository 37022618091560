import React, { useEffect, useCallback, useState } from 'react';

import { styled } from 'styles';
import { useFormerSubscriberRecoverCampaign } from 'modules/campaigns/recover-subscriber/former-subscriber-recover/hooks';
import { useTracker } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import {
  Container,
  Content,
  Title,
  ButtonsContainer,
  OptionButton,
  Strong,
  Message,
} from 'modules/campaigns/recover-subscriber/styles';
import { NoAccessDialogFormerSubscriber, SuccessDialog, ErrorDialog } from '.';

export default function SameCardRecoverDialog({ source, pricing }) {
  const [loading, setLoading] = useState(false);
  const { setDialog, unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const {
    card,
    recoverPayment,
    defaultAmplitudeEventAttributes,
  } = useFormerSubscriberRecoverCampaign();

  useEffect(() => {
    trackEvent('Recuperacao Ex Assinantes: Visualizou Confirmacao', {
      utm_source: source,
      opcao: 'mesmo cartao',
      plan: pricing.description,
      ...defaultAmplitudeEventAttributes,
    });
  }, [source, trackEvent, pricing, defaultAmplitudeEventAttributes]);

  const onRetryClick = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    const result = await recoverPayment(
      pricing,
      'credit_card',
      card.id,
      null,
      'former_subscriber'
    );
    if (!result.success) {
      setDialog(
        <ErrorDialog
          source={source}
          pricing={pricing}
          trackViewErrorName="Recuperacao Ex Assinantes: Visualizou Erro"
        />
      );
      return;
    }

    setDialog(
      <SuccessDialog
        source={source}
        pricing={pricing}
        recoverOption="mesmo cartao"
        trackViewSuccessName="Recuperacao Ex Assinantes: Visualizou Sucesso"
        trackClickBonusName="Recuperacao Ex Assinantes: Clicou Bonus"
      />
    );
  }, [recoverPayment, card, loading, setDialog, source, pricing]);

  const renderPaymentNewPlanInfo = useCallback(() => {
    if (!card) return null;
    if (pricing.months === '1')
      return (
        <>
          <PaymentPlanInfo>
            <Strong>Forma de Pagamento:</Strong> Plano Mensal, 1 parcela de R${' '}
            {pricing.monthlyPrice} no{' '}
            <Strong>
              cartão {card.brand} de final X-
              {card.lastFourDigits}
            </Strong>
          </PaymentPlanInfo>
          <PaymentPlanInfo>
            <Strong>Primeira Cobrança:</Strong> Imediata
          </PaymentPlanInfo>
          <PaymentPlanInfo>
            <Strong>Renovação:</Strong> Mensal
          </PaymentPlanInfo>
        </>
      );

    return (
      <>
        <PaymentPlanInfo>
          <Strong>Forma de Pagamento:</Strong> Plano {pricing.description},
          {pricing.months} parcelas de R$ {pricing.monthlyPricing.toFixed(2)} no{' '}
          <Strong>
            cartão {card.brand} de final X-
            {card.lastFourDigits}
          </Strong>
        </PaymentPlanInfo>
        <PaymentPlanInfo>
          <Strong>Cobrança da primeira parcela:</Strong> Imediata
        </PaymentPlanInfo>
        <PaymentPlanInfo>
          <Strong>Renovação automática:</Strong> {pricing.description}
        </PaymentPlanInfo>
      </>
    );
  }, [pricing, card]);

  if (!pricing) return null;
  return (
    <Container
      onClick={unsetDialog}
      data-cy="former-subscriber-same-card-dialog"
    >
      <Content onClick={e => e.stopPropagation()}>
        <Title color="#787878">Informações de Pagamento</Title>
        <Message>Confirma essas informações pra gente, por favor?</Message>
        {renderPaymentNewPlanInfo()}
        <ButtonsContainer>
          <OptionButton
            onClick={() =>
              setDialog(<NoAccessDialogFormerSubscriber source={source} />)
            }
          >
            Voltar
          </OptionButton>

          <OptionButton onClick={onRetryClick}>
            {loading ? 'Reativando assinatura...' : 'Reativar assinatura'}
          </OptionButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const PaymentPlanInfo = styled.div`
  margin: 10px auto;
  font-size: 1.1em;
  width: 400px;
  max-width: 100%;
`;
