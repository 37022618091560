import React, { createContext, useContext, useEffect, useState } from 'react';

import { useUserContext } from './UserProvider';
import { useRALazyQueryImmutable } from 'modules/shared/hooks/requests';

export const BannerContext = createContext(null);

const BannerProvider = props => {
  const { user } = useUserContext();
  const [bannersToShowInfo, setBannersToShowInfo] = useState(null);

  const [getBannersToShow, { data }] = useRALazyQueryImmutable(
    '/banners/to_render'
  );

  useEffect(() => {
    if (user) getBannersToShow();
  }, [user, getBannersToShow]);

  useEffect(() => {
    const bannersToShow = data && data.banners;
    if (bannersToShow) setBannersToShowInfo(bannersToShow);
  }, [data]);

  return <BannerContext.Provider value={{ bannersToShowInfo }} {...props} />;
};

export const useBannerContext = () => useContext(BannerContext);

export default BannerProvider;
