import { useCallback } from 'react';

import { useUserContext } from 'providers/UserProvider';
import { useWorkspace } from 'modules/workspace/hooks/main';

export default function useWorkspaceMainFlags() {
  const { user } = useUserContext();
  const {
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    isExtraExerciseWorkspace,
    isExtraTimePageWorkspace,
  } = useWorkspace();

  const showFlagExercise = useCallback(() => {
    return (
      user &&
      (isFixationExerciseWorkspace() ||
        isUniversityExerciseWorkspace() ||
        isExtraExerciseWorkspace() ||
        isExtraTimePageWorkspace())
    );
  }, [
    user,
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    isExtraExerciseWorkspace,
    isExtraTimePageWorkspace,
  ]);

  return { showFlagExercise };
}
