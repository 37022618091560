import React, { useEffect } from 'react';
import { styled, media } from 'styles';
import { useParams, useLocation } from 'react-router-dom';
import { MainContentContainer } from '../components/content';
import { SideMenuChapters } from 'modules/workspace/components/side-menu';
import { Loading, Error } from 'modules/shared/components/generic';
import { useWorkspaceGuide } from '../hooks/shared';
import WorkspaceFlagsProvider from '../providers/WorkspaceFlagsProvider';
import WorkspaceContentProvider from '../providers/WorkspaceContentProvider';
import UnloggedHeader from '../components/UnloggedHeader';
import ScrollToTopButton from 'modules/shared/components/ScrollToTopButton';
import { useUserContext } from 'providers/UserProvider';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import ForumProvider from 'providers/ForumProvider';
import MathJaxProvider from 'providers/MathJaxProvider';

export default function WorkspaceGuidePage() {
  const location = useLocation();
  const { guideId } = useParams();
  const { user } = useUserContext();
  const {
    getChaptersFromGuide,
    getGuidableNameFromGuide,
    getThemeFromGuide,
  } = useWorkspaceGuide();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const { data: guide, loading, error } = useContentQueryImmutable(
    `/guides/${guideId}`
  );

  if (error) return <Error />;
  if (loading) return <Loading />;

  return (
    <MathJaxProvider>
      <ForumProvider>
        <WorkspaceFlagsProvider>
          <WorkspaceContentProvider
            chapters={getChaptersFromGuide(guide)}
            contentTitle={getGuidableNameFromGuide(guide)}
            guide={guide}
            subjects={guide.subjects}
            theme={getThemeFromGuide(guide)}
          >
            <Container>
              {!user && media.isDesktop() && <UnloggedHeader />}
              <SideMenuChapters />
              <MainContentContainer />
              <ScrollToTopButton />
            </Container>
          </WorkspaceContentProvider>
        </WorkspaceFlagsProvider>
      </ForumProvider>
    </MathJaxProvider>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #f6f6f6;
`;
