import React from 'react';
import { styled, colors } from 'styles';
import LoginButtonsContainer from './LoginButtonsContainer';

export default function LoginComponent({ origin }) {
  return (
    <Container>
      <Content onClick={e => e.stopPropagation()}>
        <Paragraph>
          <Bold>Logue ou Cadastre-se grátis</Bold> no maior portal de exatas do
          Brasil!
        </Paragraph>
        <LoginButtonsContainer where="dialog" origin={origin} />
        <Disclaimer>Relaxa, não vamos publicar nada no seu nome.</Disclaimer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
`;

const Paragraph = styled.p`
  font-size: 1.3em;
  margin-bottom: 30px;
  font-weight: 100;
  &:last-child {
    margin-bottom: 40px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Disclaimer = styled.p`
  color: ${colors.mediumGray};
  padding: 5px;
`;
