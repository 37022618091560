import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { addDays, format } from 'date-fns';

import { styled, colors, media } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker } from 'modules/shared/hooks';
import FreezeSubscriptionConfirmationDialog from './FreezeSubscriptionConfirmation';
import { useRAPostRequest } from 'modules/shared/hooks/requests';

export default function FreezeSubscriptionChooseDays({ subscription }) {
  const { setDialog, unsetDialog } = useDialogContext();
  const [days, setDays] = useState(null);
  const [loading, setLoading] = useState(false);
  const freezeSubscription = useRAPostRequest('campaigns/freeze_subscription');
  const { trackEvent } = useTracker();

  useEffect(() => {
    trackEvent('Congelamento Assinatura: Meses Visualizou');
  }, [trackEvent]);

  const formattedFreezeDate = useMemo(() => {
    if (!days) return '';

    const activeUntilDate = new Date(subscription.expiresIn * 1000);
    const endFreezeDate = addDays(activeUntilDate, days);
    return format(endFreezeDate, 'dd/MM/yyyy');
  }, [days, subscription]);

  const formattedActiveUntil = useMemo(() => {
    const activeUntilDate = new Date(subscription.expiresIn * 1000);
    return format(activeUntilDate, 'dd/MM/yyyy');
  }, [subscription]);

  const handleFreezeSubscriptionPress = useCallback(async () => {
    if (!days) {
      alert(
        'Por favor, escolha por quantos dias deseja pausar sua assinatura.'
      );
      return;
    }
    if (loading) return;

    trackEvent('Congelamento Assinatura: Dias Clicou Congelar', {
      Dias: days,
    });
    setLoading(true);

    try {
      await freezeSubscription({
        days: days.toString(),
      });

      setDialog(
        <FreezeSubscriptionConfirmationDialog
          freezeEndDate={formattedFreezeDate}
          activeUntil={formattedActiveUntil}
        />
      );
    } catch (e) {
      setLoading(false);
      alert(
        'Tivemos um problema ao pausar sua assinatura. Por favor, tente novamente ou entre em contato com o atendimento.'
      );
    }
  }, [
    days,
    loading,
    formattedActiveUntil,
    formattedFreezeDate,
    freezeSubscription,
    setDialog,
    trackEvent,
  ]);

  const handleChooseDays = useCallback(
    days => {
      trackEvent('Congelamento Assinatura: Dias Escolheu Dia', {
        Dias: days,
      });
      setDays(days);
    },
    [setDays, trackEvent]
  );

  const handleBackPress = useCallback(() => {
    trackEvent('Congelamento Assinatura: Dias Clicou Voltar');
    unsetDialog();
  }, [unsetDialog, trackEvent]);

  return (
    <Container>
      <Content>
        <FreezeStrip>Pausar assinatura</FreezeStrip>
        <Title>Escolha o período de pausa</Title>

        <PauseOptions>
          <PauseOption
            onClick={() => handleChooseDays(30)}
            className={days === 30 && 'selected'}
          >
            30 dias
          </PauseOption>
          <PauseOption
            onClick={() => handleChooseDays(45)}
            className={days === 45 && 'selected'}
          >
            45 dias
          </PauseOption>
          <PauseOption
            onClick={() => handleChooseDays(60)}
            className={days === 60 && 'selected'}
          >
            60 dias
          </PauseOption>
        </PauseOptions>

        <Paragraph className={days && 'selected'}>
          <strong>
            Seu plano ficará pausado entre os dias {formattedActiveUntil} e{' '}
            {formattedFreezeDate}.
          </strong>
          &nbsp; Você não receberá cobranças e não terá acesso liberado à
          plataforma nesse período.
          <br />
          <br />
          Após essa data, você voltará a receber a cobrança da sua assinatura no
          seu cartão e seu acesso será normalizado.
        </Paragraph>

        <ButtonsContainer className={days && 'selected'}>
          <button onClick={handleBackPress} type="button">
            Voltar
          </button>

          <button
            onClick={handleFreezeSubscriptionPress}
            className={days && 'positive'}
          >
            {loading ? 'Pausando plano... aguarde' : 'Quero pausar meu plano'}
          </button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-top: 10px solid ${colors.darkGreen};
  text-align: left;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const FreezeStrip = styled.div`
  display: inline-block;
  padding: 3px 10px;
  position: relative;
  left: -48px;
  margin-bottom: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${colors.darkGreen};
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  @media ${media.mobile} {
    left: -24px;
  }
`;

const Title = styled.h1`
  margin-bottom: 15px;
  font-size: 1.6em;
  font-weight: bold;
  color: ${colors.darkGreen};
`;

const PauseOptions = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0 10px 0;
  padding: 0 20px;

  @media ${media.mobile} {
    flex-direction: column;
  }
`;

const PauseOption = styled.div`
  width: 100px;
  max-width: 50%;
  border-radius: 8px;
  padding: 15px 0;
  margin: 0 10px;
  border: 1px solid #989898;
  text-align: center;
  font-size: 1.1em;
  color: #989898;
  font-weight: bold;
  cursor: pointer;

  &:hover,
  &.selected {
    color: #fff;
    border-color: #fff;
    background-color: ${colors.darkGreen};
  }

  @media ${media.mobile} {
    margin: 10px 0;
  }
`;

const Paragraph = styled.p`
  font-size: 1em;
  margin: 20px 0;
  padding: 0 20px;
  opacity: 0;

  &.selected {
    opacity: 1;
  }

  @media ${media.mobile} {
    display: none;

    &.selected {
      display: block;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 500px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 60px;

  button {
    width: 45%;
    font-size: 0.95em;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 20px 20px 0;
    height: 50px;
    border: 2px solid #989898;
    color: #989898;
    text-transform: uppercase;
    font-weight: bold;

    &.positive {
      border-color: ${colors.darkGreen};
      color: ${colors.darkGreen};
    }
  }

  @media ${media.mobile} {
    left: -24px;
    flex-direction: column-reverse;

    button {
      width: 95%;
    }
  }
`;
