import React, { useState, useRef, useCallback, useMemo } from 'react';

import { styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import { useTracker } from 'modules/shared/hooks';
import BookCreditCardTrialCTADialog from './BookCreditCardTrialCTADialog';
import DataInput from './DataInput';
import BookCreditCardTrialSuccessDialog from './BookCreditCardTrialSuccessDialog';
import CreditCardTrialCpfErrorDialog from './CreditCardTrialCpfErrorDialog';

export default function BookCreditCardTrialInputInfoDialog({
  planPricing,
  source,
}) {
  const [loading, setLoading] = useState(false);

  const purchaseData = useMemo(() => {
    return {
      userData: null,
      creditCard: null,
      planPricingId: planPricing.id,
    };
  }, [planPricing.id]);

  const { setDialog } = useDialogContext();
  const {
    bookCreditCardTrialFreeAccessBegin,
    bookCreditCardTrialFreeAccessEnd,
    bookCreditCardTrialFirstCharge,
    getMonthlyPriceValue,
    createBookTrialWithCard,
  } = useBookTrialCampaign();
  const componentRef = useRef();
  const { trackEvent } = useTracker();

  const getPlanPrice = useCallback(() => {
    if (planPricing.plan.months === 1)
      return `R$ ${getMonthlyPriceValue(planPricing)}`;
    return `até ${planPricing.plan.months}x de R$ ${getMonthlyPriceValue(
      planPricing
    )}`;
  }, [getMonthlyPriceValue, planPricing]);

  const createTrial = useCallback(
    async form => {
      const response = await createBookTrialWithCard(form);

      if (!response.success) {
        setLoading(false);
        trackEvent('Trial com Cartão: Erro ao gerar trial', {
          message: response.message,
        });

        if (response.errorType === 'uniq_cpf') {
          setDialog(<CreditCardTrialCpfErrorDialog />);
          return;
        }

        alert(response.message);
        return;
      }

      setDialog(<BookCreditCardTrialSuccessDialog planPricing={planPricing} />);
    },
    [createBookTrialWithCard, planPricing, setDialog, trackEvent]
  );

  const afterCompleteData = useCallback(
    async ({ creditCard, installments, userData }) => {
      if (loading) return;
      setLoading(true);

      const form = { ...purchaseData, installments, creditCard, userData };
      createTrial(form);
    },
    [createTrial, loading, purchaseData]
  );

  const backFromCreditCardForm = useCallback(() => {
    setDialog(<BookCreditCardTrialCTADialog source={source} />);
  }, [setDialog, source]);

  return (
    <Container ref={componentRef}>
      <Content>
        <Title>
          Preencha para ganhar <br /> seu acesso gratuito
        </Title>

        <Row className="with-grid">
          <FreeAccessDate>
            {bookCreditCardTrialFreeAccessBegin()} à{' '}
            {bookCreditCardTrialFreeAccessEnd()}
          </FreeAccessDate>
          <FreeMessage>Teste Gratuito</FreeMessage>
        </Row>
        <Line />
        <Row className="with-grid">
          <PlanBeginDate>
            À partir de {bookCreditCardTrialFirstCharge()}
          </PlanBeginDate>
          <SelectedPlan>
            Plano {planPricing.plan.description} {getPlanPrice()}
          </SelectedPlan>
        </Row>

        <Row style={{ fontWeight: 'bold' }}>
          Você não paga nada se cancelar até{' '}
          {bookCreditCardTrialFreeAccessEnd()}
        </Row>

        <StepInputContainer>
          <DataInput
            afterCompleteData={afterCompleteData}
            backFromCreditCardForm={backFromCreditCardForm}
            loading={loading}
            planPricing={planPricing}
          />
        </StepInputContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  padding: 30px 15px 20px;
  background-color: #fff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
`;

const Title = styled.h1`
  font-size: 2em;
  font-weight: bold;
  color: #000;
  margin-bottom: 25px;
`;

const Row = styled.div`
  width: 450px;
  max-width: 95%;

  &.with-grid {
    display: grid;
    grid-column-gap: 25px;
    grid-template-columns: 1fr 1fr;
  }
`;

const Line = styled.div`
  width: 410px;
  max-width: 90%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 10px 0;
`;

const FreeAccessDate = styled.span`
  text-align: right;
`;

const FreeMessage = styled.span`
  font-weight: bold;
  text-align: left;
`;

const PlanBeginDate = styled.span`
  text-align: right;
  margin-bottom: 20px;
`;

const SelectedPlan = styled.span`
  font-weight: bold;
  text-align: left;
`;

const StepInputContainer = styled.div`
  width: 400px;
  max-width: 90%;
  margin-top: 15px;
`;
