import { media, styled } from 'styles';

const CardsContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  width: ${({ numColumns }) => (numColumns === 4 ? '1160px' : '960px')};
  max-width: 95%;
  position: relative;
  top: ${({ top }) => top || '-130px'};
  margin: auto;

  @media ${media.mobile} {
    flex-direction: column;
    align-items: center;
    top: -30px;

    > div {
      margin-bottom: 60px;
    }
  }
`;

export default CardsContent;
