import React from 'react';
import { styled, colors } from 'styles';
import { AppAnnual, AppMonthly, AppSemiannual } from 'images/plansCard';

const plansInfo = {
  annual: {
    name: 'ANUAL',
    discount: '36%',
    months: '12',
    price: '23,90',
    color: colors.annualBlue,
    appImage: AppAnnual,
    link: 'https://www.respondeai.com.br/planos/3/orders/new',
  },
  semiannual: {
    name: 'SEMESTRAL',
    discount: '21%',
    months: '6',
    price: '29,90',
    color: colors.semiannualGreen,
    appImage: AppSemiannual,
    link: 'https://www.respondeai.com.br/planos/2/orders/new',
  },
  monthly: {
    name: 'MENSAL',
    discount: 'Preço original',
    months: '1',
    price: '37,90',
    color: colors.monthlyOrange,
    appImage: AppMonthly,
    link: 'https://www.respondeai.com.br/planos/1/orders/new',
  },
};

const PlanCard = ({ planName = 'annual' }) => {
  const { name, discount, price, months, color, appImage, link } = plansInfo[
    planName
  ];
  return (
    <Plan href={link} target="_blank" color={color}>
      <PriceRibbon color={color}>{`${discount} ${
        planName === 'monthly' ? '' : 'mais barato'
      }`}</PriceRibbon>
      <PlanTitle>{`${name}`}</PlanTitle>
      <PlanPaymentForm>
        {planName === 'monthly' ? (
          <>somente cartão de crédito</>
        ) : (
          <>
            via boleto ou
            <br />
            cartão em até
            <Strong>{` ${months}`} vezes s/ juros!</Strong>
          </>
        )}
      </PlanPaymentForm>
      <PlanPrice color={color}>
        R$ {`${price}`}
        <span>/mês</span>
      </PlanPrice>
      <PlanBenefits>
        <p>
          Acesso <Strong>ilimitado</Strong> ao conteúdo de todas as matérias
          <Strong>por {`${months}`} meses!</Strong>
        </p>
      </PlanBenefits>
      <AppContainer>
        <AppImg src={appImage} />
        <AppText color={color}>Estude offline com nosso aplicativo</AppText>
      </AppContainer>
      <PlanButton color={color}>Assine</PlanButton>
    </Plan>
  );
};

const PriceRibbon = styled.div`
  margin: 10px 0;
  width: 135px;
  border-radius: 0 6px 6px 0;
  padding: 7px 7px 7px 12px;
  color: white;
  position: relative;
  left: -90px;
  background-color: ${({ color }) => color};
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 7px 8px;
    border-color: transparent transparent #c3c3c3 transparent;
    top: -7px;
    left: 0;
  }
`;

const PlanTitle = styled.h1`
  font-size: 18px;
  margin-bottom: 3px;
`;

const PlanPaymentForm = styled.div`
  font-size: 14px;
  height: 15%;
`;

const PlanPrice = styled.div`
  font-size: 38px;
  width: 100%;
  padding: 15px 0 15px 0;
  margin-bottom: 10px;
  color: ${({ color }) => color};
  background-color: #f3f3f3;
  span {
    font-weight: 100;
    font-size: 18px;
  }
`;

const Strong = styled.span`
  font-weight: 900;
`;

const PlanBenefits = styled.div`
  font-size: 15px;
  padding: 20px 30px;
  margin-bottom: 15px;
`;

const PlanButton = styled.div`
  width: 200px;
  height: 50px;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  letter-spacing: 0.4px;
  border-radius: 35px;
  background-color: ${({ color }) => color};
`;

const Plan = styled.a`
  cursor: pointer;
  width: 300px;
  height: 450px;
  border-radius: 12px;
  background-color: white;
  margin: 15px 10px 15px 10px;
  border-top: ${({ color }) => `12px solid ${color}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #888;
  box-shadow: 0px 0px 1px 2px rgba(83, 83, 83, 0.1);
  &:hover {
    transform: scale(1.03);
    transition: all 0.2s ease;
  }
`;

const AppImg = styled.img``;

const AppText = styled.p`
  width: 50%;
  color: ${({ color }) => color};
  font-weight: bold;
`;

const AppContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`;

export default PlanCard;
