import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import {
  StudyPlanHeader,
  LecturesCards,
  SubjectsCards,
  ListsCategoriesCards,
  BooksCards,
  CardsContent,
} from '../components';
import { Loading, Error } from 'modules/shared/components/generic';
import { useGuide } from '../hooks';
import { useTracker } from 'modules/shared/hooks';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';

export default function StudyPlanPage() {
  const { studyPlanId, guideId } = useParams();

  const {
    data: studyPlan,
    loading: studyPlanLoading,
    error: studyPlanError,
  } = useContentQueryImmutable(`/study-plans/${studyPlanId}`);

  const { data: guide, loading, error } = useContentQueryImmutable(
    `/guides/${guideId}`
  );
  const { trackEvent } = useTracker();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    trackEvent('Plano de Estudos: Visualizou', { id: studyPlanId });
  }, [trackEvent, studyPlanId]);

  const showLecturesCards = useCallback(lectures => {
    return lectures.length > 0;
  }, []);

  const showListsCategoriesCards = useCallback(studyPlan => {
    const { listCategories } = studyPlan;
    return listCategories.length > 0;
  }, []);

  const showBooksCards = useCallback(
    (books, studyPlan) => {
      if (showListsCategoriesCards(studyPlan)) return false;

      return books.length > 0;
    },
    [showListsCategoriesCards]
  );

  const { getLecturesFromSubjects, studyPlanGuidableName } = useGuide();

  if (studyPlanError || error) return <Error />;
  if (studyPlanLoading || loading) return <Loading />;

  const lectures = getLecturesFromSubjects(guide.subjects);
  return (
    <Container>
      <StudyPlanHeader studyPlan={studyPlan} color={studyPlan.theme.color} />
      <CardsContent top="-60px">
        {showLecturesCards(lectures) && (
          <LecturesCards
            lectures={lectures}
            color={studyPlan.theme.color}
            guideId={guide.id}
          />
        )}

        <SubjectsCards
          subjects={guide.subjects}
          color={studyPlan.theme.color}
          guideId={guide.id}
          studyPlan={studyPlan}
          guidableType={studyPlanGuidableName}
        />

        {showListsCategoriesCards(studyPlan) && (
          <ListsCategoriesCards
            listCategories={studyPlan.listCategories}
            guideId={guide.id}
          />
        )}

        {showBooksCards(guide.books, studyPlan) && (
          <BooksCards books={guide.books} />
        )}
      </CardsContent>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;
