import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { useUserContext } from 'providers/UserProvider';

export const WorkspaceContentContext = createContext(null);

const WorkspaceContentProvider = props => {
  const {
    chapters,
    contentTitle,
    contentSubtitle,
    lecture,
    guide,
    subjects,
    theme,
  } = props;
  const { topicId, subjectId, chapterId } = useParams();
  const { user } = useUserContext();
  const [currentContent, setCurrentContent] = useState(null);
  const [currentContentFormat, setCurrentContentFormat] = useState(null);

  const currentTheme = useMemo(() => {
    return theme;
  }, [theme]);

  const currentSubject = useMemo(() => {
    return subjects.find(
      subject => parseInt(subject.id) === parseInt(subjectId)
    );
  }, [subjects, subjectId]);

  const currentChapter = useMemo(() => {
    if (chapterId)
      return chapters.find(
        chapter => parseInt(chapter.id) === parseInt(chapterId)
      );

    return chapters.find(chapter => {
      return chapter.topics.find(
        topic => parseInt(topic.id) === parseInt(topicId)
      );
    });
  }, [chapters, topicId, chapterId]);

  const currentTopic = useMemo(() => {
    if (!currentChapter || !topicId) return;

    return currentChapter.topics.find(
      topic => parseInt(topic.id) === parseInt(topicId)
    );
  }, [currentChapter, topicId]);

  const currentFixationExercises = useMemo(() => {
    if (!currentTopic) return [];

    return currentTopic.exercises;
  }, [currentTopic]);

  const filterUniversityExercises = useCallback(
    listExercises => {
      if (!user || !user.university || !listExercises) return [];

      if (user.universityCampus) {
        const listExercisesFromUniversityCampus = listExercises.filter(
          exercise =>
            parseInt(exercise.universityCampusId) === user.universityCampus.id
        );
        if (listExercisesFromUniversityCampus.length > 0)
          return listExercisesFromUniversityCampus;
      }

      const listExercisesFromUniversity = listExercises.filter(
        exercise => parseInt(exercise.universityId) === user.university.id
      );
      return listExercisesFromUniversity;
    },
    [user]
  );

  const currentUniversityExercises = useMemo(() => {
    if (!currentTopic) return [];

    return filterUniversityExercises(currentTopic.listExercises);
  }, [currentTopic, filterUniversityExercises]);

  const filterExtraExercises = useCallback(
    (listExercises, universityExercises) => {
      if (!listExercises || !universityExercises) return [];

      const universityExercisesIds = universityExercises.map(exercise =>
        parseInt(exercise.id)
      );
      return listExercises.filter(
        exercise => !universityExercisesIds.includes(parseInt(exercise.id))
      );
    },
    []
  );

  const currentExtraExercises = useMemo(() => {
    if (!currentTopic) return [];

    return filterExtraExercises(
      currentTopic.listExercises,
      currentUniversityExercises
    );
  }, [currentTopic, currentUniversityExercises, filterExtraExercises]);

  return (
    <WorkspaceContentContext.Provider
      value={{
        currentTheme,
        chapters,
        currentSubject,
        currentChapter,
        currentTopic,
        currentFixationExercises,
        currentUniversityExercises,
        currentExtraExercises,
        lecture,
        guide,
        contentTitle,
        contentSubtitle,
        currentContent,
        currentContentFormat,
        setCurrentContent,
        setCurrentContentFormat,
        filterUniversityExercises,
        filterExtraExercises,
      }}
      {...props}
    />
  );
};

export const useWorkspaceContentContext = () =>
  useContext(WorkspaceContentContext);

export default WorkspaceContentProvider;
