import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useDialogContext } from 'providers/DialogProvider';
import { styled, media } from 'styles';
import { raLogo } from 'images/main';
import dexterLoveImg from 'images/dexter/feedbacks/dexter-love.png';
import { TextField } from '@material-ui/core';
import { BeatLoader } from 'modules/shared/components/generic/loaders';
import { MdClose } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { useCookies, useEscClick, useTracker } from 'modules/shared/hooks';
import ExitConfirmation from './ExitConfirmation';

export default function PhoneNumberRequestDialog(source = '') {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [cookies, setCookie] = useCookies([
    'seo_last_page',
    'test_11_origin',
    'has_seen_dexter_dialog',
  ]);

  const { trackEvent } = useTracker();
  const { unsetDialog } = useDialogContext();
  const ref = useRef();

  useEscClick(ref, () => {
    handleClose();
  });

  const setNumberOfTimesHasSeenDialog = useCallback(() => {
    const counter = cookies.has_seen_dexter_dialog;
    const configOptions = {
      expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000), // one year from now
    };

    let counterToSet = 1;
    if (counter) {
      counterToSet = parseInt(counter) + 1;
    }

    setCookie('has_seen_dexter_dialog', counterToSet, configOptions);
  }, [cookies.has_seen_dexter_dialog, setCookie]);

  useEffect(() => {
    trackEvent('DexterBot: Visualizou Dialog de Informação', {
      Origem: source ? source : 'Disparo automático',
    });

    return () => setNumberOfTimesHasSeenDialog();
  }, [trackEvent, source, setNumberOfTimesHasSeenDialog]);

  const loginOrigin = useMemo(() => {
    return (
      cookies.seo_last_page?.split('|')[0] || cookies.test_11_origin || 'Outro'
    );
  }, [cookies]);

  const handleClose = useCallback(() => {
    trackEvent('DexterBot: Fechou Dialog de Informação');
    if (!showExitConfirmation && phoneNumber.length > 0) {
      setShowExitConfirmation(true);
      return;
    }

    unsetDialog();
  }, [
    unsetDialog,
    trackEvent,
    setShowExitConfirmation,
    showExitConfirmation,
    phoneNumber,
  ]);

  const sendPhoneNumber = useRAPostRequest('users/update_basic_infos');

  const handleValidation = useCallback(
    e => {
      const onlyNums = e.target.value.replace(/[^0-9]/g, '');
      if (onlyNums.length < 12) {
        setPhoneNumber(onlyNums);
      }
    },
    [setPhoneNumber]
  );

  useEffect(() => {
    const header = document.getElementsByClassName('header');
    if (header[0]) header[0].style.display = 'none';

    return () => {
      if (header[0]) header[0].style.display = 'block';
    };
  }, []);

  const canSubmit = useMemo(() => {
    return phoneNumber.length === 11;
  }, [phoneNumber]);

  const handleSubmit = useCallback(async () => {
    setSaving(true);

    if (!canSubmit) {
      setErrorMessage('Você precisa preencher seu telefone para continuar');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      setSaving(false);

      return;
    }

    try {
      await sendPhoneNumber({ phone: phoneNumber, from: 'Test11' });

      let text = 'Olá, gostaria de solicitar um conteúdo grátis!';
      const texturi = encodeURI(text);
      const linkurl = `https://wa.me/${process.env.REACT_APP_PHONE_NUMBER_DEXTER_BOT}?text=${texturi}`;
      window.open(linkurl, '_blank', 'noopener,noreferrer');
      trackEvent('DexterBot: Enviou Mensagem', {
        Origem: loginOrigin,
      });
      unsetDialog();
    } catch (err) {
      setErrorMessage('Você precisa preencher seu telefone para continuar');

      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    } finally {
      setSaving(false);
    }
  }, [
    phoneNumber,
    sendPhoneNumber,
    setSaving,
    canSubmit,
    setErrorMessage,
    trackEvent,
    unsetDialog,
    loginOrigin,
  ]);

  return (
    <Container ref={ref}>
      {showExitConfirmation ? (
        <ExitConfirmation
          handleContinue={() => {
            setShowExitConfirmation(false);
            trackEvent('DexterBot: Clicou para Continuar');
          }}
        />
      ) : (
        <>
          <Logo src={raLogo} />
          <CopyContainer>
            <Dexter src={dexterLoveImg} />
            <Copy>
              Mande um “Oi” no Whatsapp pra gente e receba{' '}
              <strong>vídeos, resumos e exercícios GRÁTIS</strong> para estudar
            </Copy>
          </CopyContainer>
          <MdClose className="close-icon" onClick={handleClose} />

          <CenterContainer>
            {errorMessage && <ErrorContainer>{errorMessage}</ErrorContainer>}{' '}
            <InputMask
              mask="(99)99999-9999"
              value={phoneNumber}
              maskChar=" "
              onChange={handleValidation}
            >
              <CustomTextField
                required
                label="Telefone"
                variant="standard"
                size="small"
                changeColor={phoneNumber.length > 0}
                placeholder="(XX)XXXXX-XXXX"
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: '#7A7A7A',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 'bold',
                    fontSize: '17px',
                  },
                }}
                inputProps={{
                  style: {
                    fontFamily: 'Lato, sans-serif',
                    fontSize: '21px',
                  },
                }}
              />
            </InputMask>
            <span>
              Ao continuar, você aceita receber comunicações sobre novidades,
              conteúdos gratuitos e oportunidades do mercado de trabalho
            </span>
            <Button onClick={handleSubmit}>
              {saving ? (
                <BeatLoader size={10} color="#fff" />
              ) : (
                'MANDAR UM "OI"'
              )}
            </Button>
          </CenterContainer>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 60px;
  padding-bottom: 20px;
  align-items: center;
  background-color: #ffffff;

  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.7rem;
    color: #888;
    cursor: pointer;
    z-index: 3;

    &:hover {
      transform: scale(1.3);
      color: #555;
      transition: color 180ms ease-in-out;
      transition: transform 180ms ease-in-out;
    }
  }

  @media ${media.mobile} {
    padding: 30px;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-top: 10px solid #f6ac3a;
  border-radius: 10px;
  padding: 56px 40px;
  padding-top: 35px;
  margin-top: 30px;
  align-items: flex-start;
  background-color: white;

  width: 80%;
  max-width: 600px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  span {
    text-align: start;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #898989;

    padding-top: 15px;
  }

  @media ${media.mobile} {
    padding: 30px 20px;
    width: 100%;

    span {
      font-size: 13px;
    }
  }
`;

const CopyContainer = styled.div`
  width: auto;
  display: flex;
  width: 80%;
  max-width: 600px;
  text-align: start;
  align-items: center;
  justify-content: center;

  padding-top: 50px;

  @media ${media.mobile} {
    width: 100%;
  }
`;

const Dexter = styled.img`
  width: auto;
  height: 80px;
  transform: rotate(-8.98deg);

  @media ${media.mobile} {
    height: 55px;
  }
`;

const Logo = styled.img`
  width: auto;
  height: 45px;
`;

const Copy = styled.h2`
  font-size: 22px;
  color: #808080;
  margin: 10px 20px;
  strong {
    color: #5eaf70;
  }

  @media ${media.mobile} {
    font-size: 17px;
  }
`;

const CustomTextField = styled(TextField)`
  & {
    margin: 5px;
    width: 100%;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    color: '#fff';
  }

  .MuiInputBase-input {
    color: #606060;
  }
`;

const Button = styled.button`
  width: 100%;
  border-radius: 10px;

  color: white;
  background-color: #5eaf70;
  margin-top: 20px;
  padding: 18px;
  margin-top: 55px;

  font-weight: bold;
  font-size: 16px;

  cursor: ${({ saving }) => (saving ? 'not-allowed' : 'pointer')};
`;

const ErrorContainer = styled.div`
  align-self: flex-end;
  z-index: 10;
  background: #e25017;
  color: #ffffff;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  margin: -20px;
`;
