import React, { useEffect, useState } from 'react';
import SearchFeedback from './SearchFeedback';
import Results from './Results';

function SearchDialogResults({
  term,
  results,
  loading,
  theme,
  onlyVideos,
  clickedInResult,
  showFeedback,
}) {
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [showAbove, setShowAbove] = useState(false);

  useEffect(() => {
    let showAbove = localStorage.getItem('SEARCH_FEEDBACK_SHOW_ABOVE');

    if (!showAbove) {
      showAbove = parseInt(Math.random() * 100) % 2 === 0;
      setShowAbove(showAbove);
      localStorage.setItem('SEARCH_FEEDBACK_SHOW_ABOVE', showAbove);
    }
    setShowAbove(showAbove === 'true');
  }, []);

  return (
    <div
      style={{
        height: !feedbackSent && showFeedback && !showAbove ? '90vh' : null,
      }}
    >
      {!feedbackSent && showFeedback && !showAbove && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: '#fffd',
            width: '100%',
            maxWidth: '680px',
            minHeight: '100px',
            height: 'calc(90vh - 10px)',
          }}
        >
          {!feedbackSent && showFeedback && (
            <SearchFeedback
              where={showAbove ? 'above' : 'over'}
              setFeedbackSent={setFeedbackSent}
              version={'2.0'}
            />
          )}
        </div>
      )}
      {!feedbackSent && showFeedback && showAbove && (
        <SearchFeedback
          setFeedbackSent={setFeedbackSent}
          where={showAbove ? 'above' : 'over'}
          version={'2.0'}
        />
      )}
      {results.length > 0 && (
        <Results
          results={results}
          theme={theme}
          onlyVideos={onlyVideos}
          clickedInResult={clickedInResult}
        />
      )}
      {!loading && !results.length && term && (
        <div className="no-results">Nenhum resultado encontrado</div>
      )}
      {!loading && !results.length && !term && (
        <div className="no-results">
          Digite pelo menos 3 caracteres para buscar
        </div>
      )}
    </div>
  );
}

export default SearchDialogResults;
