import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const UploadDialogContext = createContext(null);

export default function UploadDialogProvider(props) {
  const [themes, setThemes] = useState(null);
  const [professors, setProfessors] = useState(null);
  const [selectedBookEdition, setSelectedBookEdition] = useState(null);
  const [selectedBook, setSelectedBook] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [inputList, setInputList] = useState([
    { section: '', chapter: '', exercises: '' },
  ]);
  const [professor, setProfessor] = useState(null);
  const [selectedDiscipline, setSelectedDiscipline] = useState(null);
  const [file, setFile] = useState(null);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [listLink, setListLink] = useState(null);
  const [foundPercentage, setFoundPercentage] = useState(null);
  const [exam, setExam] = useState('');
  const [statementList, setStatementList] = useState(null);

  const {
    defaultThemeId,
    defaultTeachable,
    defaultProfessor,
    defaultExam,
    utms,
  } = props;

  const { data: themesResponse } = useContentQueryImmutable(
    `/mvp/themes-with-discplines-and-books`
  );

  const {
    data: professorsResponse,
    mutate: mutateProfessor,
  } = useContentQueryImmutable(`/mvp/professors-by-university`);

  const invalidateProfessorQuery = useCallback(() => {
    mutateProfessor();
    return;
  }, [mutateProfessor]);

  useEffect(() => {
    if (!themesResponse) return;
    setThemes(themesResponse.themes);
  }, [themesResponse]);

  useEffect(() => {
    if (!professorsResponse) return;
    setProfessors(professorsResponse.professors);
  }, [professorsResponse]);

  const disciplines = useMemo(() => {
    if (!selectedTheme) return [];

    return [...selectedTheme.disciplines, ...selectedTheme.mvpDisciplines];
  }, [selectedTheme]);

  useEffect(() => {
    if (defaultThemeId && themes && !selectedTheme) {
      const defautTheme = themes.find(theme => theme.id === defaultThemeId);
      defautTheme && setSelectedTheme(defautTheme);
    }

    if (defaultTeachable && disciplines && !selectedDiscipline) {
      const defaultDiscipline = disciplines.find(
        discipline =>
          discipline.id === defaultTeachable.id &&
          discipline.modelName === defaultTeachable.type
      );
      defaultDiscipline && setSelectedDiscipline(defaultDiscipline);
    }

    if (defaultProfessor && !professor) {
      setProfessor(defaultProfessor);
    }

    if (defaultExam && !exam) {
      setExam(defaultExam);
    }
  }, [
    defaultTeachable,
    defaultThemeId,
    disciplines,
    selectedDiscipline,
    selectedTheme,
    themes,
    defaultProfessor,
    professor,
    defaultExam,
    exam,
  ]);

  return (
    <UploadDialogContext.Provider
      value={{
        themes,
        professors,
        invalidateProfessorQuery,
        selectedBookEdition,
        setSelectedBookEdition,
        selectedBook,
        setSelectedBook,
        selectedTheme,
        setSelectedTheme,
        inputList,
        setInputList,
        professor,
        setProfessor,
        selectedDiscipline,
        setSelectedDiscipline,
        file,
        setFile,
        step,
        setStep,
        loading,
        setLoading,
        listLink,
        setListLink,
        foundPercentage,
        setFoundPercentage,
        exam,
        setExam,
        statementList,
        setStatementList,
        utms,
      }}
    >
      {props.children}
    </UploadDialogContext.Provider>
  );
}

export const useUploadDialogContext = () => useContext(UploadDialogContext);
