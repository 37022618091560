import React, { useCallback, useEffect, useMemo } from 'react';
import { styled, media } from 'styles';
import bookListIcon from '../../../images/book_list_icon.png';
import uploadIcon from '../../../../campaigns/mvp-books/images/upload_icon.svg';
import { useDialogContext } from 'providers/DialogProvider';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { useUserContext } from 'providers/UserProvider';
import CardsContainer from '../../CardsContainer';
import ExamToggle from './ExamToggle';
import { IoCalendarOutline } from 'react-icons/io5';
import { usePeriod } from 'modules/guides/hooks/usePeriod';

export default function ProfessorListsCards({
  professor,
  acronymDisciplineUniversity,
  mutateProfessorQuery,
  utmContent,
}) {
  const { setDialog } = useDialogContext();
  const { user } = useUserContext();
  const {
    getListsAndPeriodsGrouped,
    getPeriodsFromListsOrdered,
    getPeriodName,
    selectedPeriod,
    setSelectedPeriod,
    onPeriodClick,
    shouldShowPreviousPeriods,
  } = usePeriod();

  const exams = useMemo(() => {
    return [
      'Primeira prova',
      'Segunda prova',
      'Terceira prova',
      'Outras provas',
    ];
  }, []);

  const allLists = useMemo(() => {
    return [...professor.lists, ...professor.statementLists];
  }, [professor]);

  const getListsFromPeriod = useCallback(() => {
    if (!selectedPeriod) return [];

    const groupsOfListsAndPeriods = getListsAndPeriodsGrouped(allLists);

    return (
      groupsOfListsAndPeriods.find(gp => gp.period.id === selectedPeriod.id)
        ?.lists || []
    );
  }, [allLists, getListsAndPeriodsGrouped, selectedPeriod]);

  useEffect(() => {
    if (!professor) return;

    setSelectedPeriod(professor.currentPeriod);
  }, [professor, setSelectedPeriod]);

  return (
    <CardsContainer
      color="#70BFC7"
      title={`Listas resolvidas ${acronymDisciplineUniversity} - prof. ${professor.name}`}
      icon={bookListIcon}
      shouldHaveFreeTag={false}
      source="professorListsCards"
      styleTitle={{ margin: '0 3px 26px 3px', fontSize: '0.9em' }}
      styleIcon={{ marginTop: '23px' }}
    >
      <PeriodTitle>
        <CalenderIcon />
        <p>{`Período ${getPeriodName(professor.currentPeriod)}`}</p>
      </PeriodTitle>
      <ListContainer>
        {exams.map((exam, i) => (
          <ExamToggle
            key={i}
            exam={exam}
            lists={getListsFromPeriod()}
            professor={professor}
            mutateProfessorQuery={mutateProfessorQuery}
            utmContent={utmContent}
          />
        ))}

        {shouldShowPreviousPeriods(allLists, professor) && (
          <>
            <PeriodTitle className="bottom">
              <CalenderIcon />
              <p>Períodos</p>
            </PeriodTitle>
            <Periods>
              {getPeriodsFromListsOrdered(
                allLists,
                professor.currentPeriod
              ).map(period => {
                return (
                  <PeriodSelector
                    key={period.id}
                    className={
                      selectedPeriod?.id === period?.id ? 'selected' : ''
                    }
                    onClick={() =>
                      onPeriodClick({
                        period,
                        professor,
                        numberLists: allLists.length,
                      })
                    }
                  >
                    {period.name}
                  </PeriodSelector>
                );
              })}
            </Periods>
          </>
        )}
        {user && (
          <ButtonToUploadList
            onClick={() =>
              setDialog(
                <UploadBookListDialog
                  origin="Botão Pagina Disciplina"
                  defaultThemeId={professor.teachable.theme?.id}
                  defaultTeachable={{
                    id: professor.teachable.id,
                    type: 'Discipline',
                  }}
                  defaultProfessor={{
                    id: professor.id,
                    name: professor.name,
                  }}
                  utms={{
                    utmSource: 'site_upload',
                    utmMedium: 'botao_enviar_lista_fora_toggle',
                    utmContent,
                  }}
                />
              )
            }
          >
            <img src={uploadIcon} />
            <p>Quero enviar minha lista</p>
          </ButtonToUploadList>
        )}
      </ListContainer>
    </CardsContainer>
  );
}

const ListContainer = styled.ul`
  width: 100%;
  color: #515151;
`;

const PeriodTitle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 14px;
  margin-bottom: 5px;

  p {
    font-size: 17px;
    font-weight: bold;
    color: #515151;
  }

  &.bottom {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

const CalenderIcon = styled(IoCalendarOutline)`
  font-size: 17px;
  color: #515151;
  margin-right: 7px;

  @media ${media.mobile} {
    margin-right: 3px;
  }
`;

const Periods = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 94%;
  margin-left: 14px;
  flex-wrap: wrap;
`;

const PeriodSelector = styled.div`
  font-size: 16px;
  color: #515151;
  padding: 4px 9px;
  border: 1px solid #70bfc7;
  margin: 10px 10px 10px 0;
  border-radius: 8px;
  cursor: pointer;

  &.selected {
    background-color: #70bfc7;
    color: white;
    text-decoration: underline;
    text-underline-offset: 1px;
  }

  @media ${media.mobile} {
    padding: 5px 15px;
    font-size: 17px;
    margin-right: 8px;
  }
`;

const ButtonToUploadList = styled.div`
  width: 90%;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px auto 0 auto;
  color: #70bfc7;
  border: 1px solid #70bfc7;
  border-radius: 20px;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }

  p {
    margin-left: 8px;
    font-weight: 600;
  }
`;
