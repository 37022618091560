import React, { useCallback, useEffect } from 'react';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled, media } from 'styles';
import { IoIosStarOutline, IoIosStar } from 'react-icons/io';
import useFavoriteList from '../hooks/useFavoriteList';

export function ContainerButtons({ onShare, list, isIncompleteList }) {
  const {
    postListNewStatus,
    isFavoriteState,
    setIsFavoriteState,
    isListFavoriteFromBackend,
  } = useFavoriteList();

  const onFavoriteButtonClick = useCallback(
    async isFavorite => {
      setIsFavoriteState(isFavorite);

      await postListNewStatus({
        listId: list.id,
        listType: 'MvpListWithStatement',
        isFavorite,
        origin: 'Pagina Lista de Enunciado',
      });
    },
    [list, postListNewStatus, setIsFavoriteState]
  );

  useEffect(() => {
    setIsFavoriteState(isListFavoriteFromBackend(list));
  }, [isListFavoriteFromBackend, list, setIsFavoriteState]);

  return (
    <Container isIncompleteList={isIncompleteList}>
      <ShareButton onClick={onShare}>
        <Icon icon={faShare} /> <span>Compartilhar</span>
      </ShareButton>
      <FavoriteButton
        onClick={() => onFavoriteButtonClick(!isFavoriteState)}
        isFavorite={isFavoriteState}
      >
        {isFavoriteState ? (
          <>
            <IoIosStar className="icon" />
            <p> Lista favoritada </p>
          </>
        ) : (
          <>
            <IoIosStarOutline className="icon" />
            <p> Favoritar sua lista </p>
          </>
        )}
      </FavoriteButton>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 20px;
  margin-top: ${({ isIncompleteList }) => (isIncompleteList ? '20px' : '0')};
`;

const Button = styled.button`
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 1em;
  cursor: pointer;
  font-weight: 600;

  @media ${media.mobile} {
    width: 160px;
  }
`;

const ShareButton = styled(Button)`
  color: #858382;
  border: 1px solid #858382;
  margin-right: 10px;
`;

const FavoriteButton = styled(Button)`
  border: 1px solid #f7ac3d;
  background-color: ${({ isFavorite }) => (isFavorite ? '#F7AC3D' : 'inherit')};
  color: ${({ isFavorite }) => (isFavorite ? '#fff' : '#f7ac3d')};

  .icon {
    margin-right: 10px;
    font-size: 18px;
  }

  @media ${media.mobile} {
    width: 215px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;
