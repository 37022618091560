import React, { useEffect, useRef } from 'react';

import { LoginDialog } from 'modules/shared/dialogs';
import { useDialogContext } from 'providers/DialogProvider';

export default function useLoginDialog({ timeout, condition, origin }) {
  const timeoutRef = useRef(null);
  const { setDialog } = useDialogContext();

  useEffect(() => {
    if (condition) {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        // eslint-disable-next-line custom-rules/set-dialog
        setDialog(<LoginDialog origin={origin} />);
      }, timeout);

      return () => clearTimeout(timeoutRef.current);
    }
  }, [timeout, setDialog, timeoutRef, condition, origin]);
}
