import React from 'react';
import { styled, colors, media } from 'styles';
import BackButton from 'modules/shared/components/BackButton';

export default function SummaryHeader({ color, subjectName }) {
  return (
    <Container color={color}>
      <BackButton to="/resumoes-gratis" />
      <Title>
        Resumão de <strong>{subjectName}</strong>
      </Title>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  box-shadow: 0 3px 5px 0 rgba(176, 176, 176, 0.7);
  background-color: ${props => props.color};

  position: relative;

  padding: 20px 0;

  @media ${media.mobile} {
    padding-top: 80px;
  }
`;

const Title = styled.h1`
  font-size: 1.4em;
  text-align: center;
  color: ${colors.white};
`;
