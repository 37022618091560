import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from 'styled-components';
import { styled } from 'styles';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  margin-left: 70px;
  font-size: 17px;
  ${({ waitingApproval }) =>
    waitingApproval &&
    css`
      color: gray;
    `}
`;

export const QuestionImage = styled.img`
  max-height: 100px;
  object-fit: scale-down;
  align-self: flex-start;

  /* zoom cursor */
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
`;

export const QuestionTitle = styled.h1`
  margin: 10px 0 15px 0;
  font-weight: bold;
  color: #505050;
`;

export const AnswersTitle = styled.h1`
  margin: 20px 10px 20px 0;
  font-size: 1.1em;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: underline;
`;

export const Back = styled.div`
  margin-bottom: 20px;
  font-size: 1.1em;
  text-decoration: underline;
  cursor: pointer;
`;

export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1.1em;
`;

export const Icon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;
