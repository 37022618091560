import React, { useCallback } from 'react';
import { styled, media } from 'styles';
import { CgNotes } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { useUniversityContext } from 'providers/UniversityProvider';
import { useState } from 'react';

export default function TopicsList({ topics, guide }) {
  const [topicsIdsIncludedInGuide, setTopicsIdsIncludedInGuide] = useState([]);
  const { university } = useUniversityContext();

  const guideHasTopic = useCallback(
    topicId => {
      const guideTopicsIds = guide.guidesTopics.map(gt => gt.topicId);
      const guideHasTopic = guideTopicsIds.includes(topicId);

      return guideHasTopic;
    },
    [guide]
  );

  const shouldShowFlagInTopic = useCallback(
    topicId => {
      if (!guide) return false;
      if (topicsIdsIncludedInGuide.includes(topicId)) return false;
      if (!university) return false;

      return !guideHasTopic(topicId);
    },
    [guide, topicsIdsIncludedInGuide, guideHasTopic, university]
  );

  const getTheoryLink = useCallback(
    topic => {
      const { subjectId, id, theoryId } = topic;
      if (guide && guideHasTopic(id))
        return `/aprender/${guide.id}/topico/${subjectId}/${id}/teoria/${theoryId}`;

      return `/aprender/topico/${subjectId}/${id}/teoria/${theoryId}`;
    },
    [guide, guideHasTopic]
  );

  const getExerciseLink = useCallback(
    topic => {
      const { subjectId, id, firstExerciseId } = topic;

      if (guide && guideHasTopic(id))
        return `/aprender/${guide.id}/topico/${subjectId}/${id}/exercicio/${firstExerciseId}`;

      return `/aprender/topico/${subjectId}/${id}/exercicio/${firstExerciseId}`;
    },
    [guide, guideHasTopic]
  );

  return (
    <Container>
      {topics.map(topic => {
        return (
          <Topic key={topic.id} must>
            <TopicName>
              <Icon />
              <span>{topic.name}</span>
            </TopicName>
            <ButtonContainer>
              <Button to={getTheoryLink(topic)} colorHover="#00b8d6">
                Aprender +
              </Button>
              <Button to={getExerciseLink(topic)} colorHover="#F7AC3C">
                Praticar +
              </Button>
              {shouldShowFlagInTopic(topic.id) && (
                <FlagDoesNotDemandTopicInUniversity
                  onClick={() =>
                    setTopicsIdsIncludedInGuide([
                      ...topicsIdsIncludedInGuide,
                      topic.id,
                    ])
                  }
                >
                  {university.acronym} não costuma cobrar
                  <span>x</span>
                </FlagDoesNotDemandTopicInUniversity>
              )}
            </ButtonContainer>
          </Topic>
        );
      })}
    </Container>
  );
}

const Container = styled.ul`
  @media ${media.mobile} {
    max-width: 370px;
    margin: 0 auto;
  }
`;

const Topic = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;

  @media ${media.mobile} {
    height: auto;
    margin-bottom: 0.5rem;
  }
`;

const TopicName = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${media.mobile} {
    display: block;
    text-align: left;
    width: 250px;
  }
`;

const Icon = styled(CgNotes)`
  margin-right: 10px;

  @media ${media.mobile} {
    font-size: 17px;
  }
`;

const ButtonContainer = styled.div`
  height: 100%;
  margin-right: 10px;
  position: relative;

  @media ${media.mobile} {
    text-align: center;
    margin-right: 0px;
  }
`;

// The aim is to have only lowerCase attributes in DOM
// eslint-disable-next-line no-unused-vars
const Button = styled(({ colorHover, ...props }) => <Link {...props} />)`
  display: inline-block;
  width: 110px;
  height: 26px;
  text-align: center;
  border: 1px solid #ddd;
  color: inherit;
  text-decoration: none;
  font-size: 0.9em;
  margin: 2px;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    background: ${({ colorHover }) => colorHover};
    color: #fff;
  }
`;

const FlagDoesNotDemandTopicInUniversity = styled.div`
  position: absolute;
  top: 3px;
  width: 100%;
  background: #ff7050;
  color: #fff;
  font-size: 0.8em;
  text-align: left;
  font-weight: bold;
  height: 26px;
  line-height: 26px;
  padding-left: 10px;
  border-radius: 3px;
  cursor: pointer;

  span {
    position: absolute;
    top: 0;
    right: 14px;
    font-size: 1.07rem;
  }

  ::before {
    transition: border-width 300ms ease-in-out;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 10px 8px 0;
    border-style: solid;
    border-color: #b1533e #fff;
  }

  &:hover {
    &:before {
      border-width: 0 13px 11px 0;
    }
  }

  @media ${media.mobile} {
    width: 112px;
    left: 18.5%;
    font-size: 0.65em;
    line-height: 13px;
    word-wrap: break-word;
    padding-left: 3.5px;

    span {
      top: 9px;
      right: 5px;
      font-size: 0.75rem;
      word-wrap: break-word;
    }
  }
`;
