import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import {
  GuideHeader,
  LecturesCards,
  SubjectsCards,
  CardsContent,
} from '../components';
import { Loading, Error } from 'modules/shared/components/generic';
import { useGuide, useProfessorTracker } from '../hooks';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { ProfessorListsCards } from '../components/Professor/ListsCards';
import { media } from 'styles';

export default function SubjectProfessorPage() {
  const { subjectId, professorId } = useParams();
  const { getLecturesFromSubjects, subjectGuidableName } = useGuide();
  const { trackViewSubjectProfessorPage } = useProfessorTracker();

  const { data: subject, loading, error } = useContentQueryImmutable(
    `/subjects/${subjectId}`
  );
  const {
    data: professorWithLists,
    loading: professorWithListsLoading,
    error: professorWithListsError,
  } = useContentQueryImmutable(`/mvp/lists-by-professor/${professorId}`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (loading || error || !professorWithLists) return;

    trackViewSubjectProfessorPage({
      professorName: professorWithLists.name,
      subjectName: subject.name,
      lists: [
        ...professorWithLists.lists,
        ...professorWithLists.statementLists,
      ],
    });
  }, [
    error,
    loading,
    professorWithLists,
    subject,
    trackViewSubjectProfessorPage,
  ]);

  const showLecturesCards = useCallback(lectures => {
    return lectures.length > 0;
  }, []);

  if (error || professorWithListsError) return <Error />;
  if (loading || professorWithListsLoading) return <Loading />;

  const lectures = getLecturesFromSubjects([subject]);

  return (
    <Container>
      <GuideHeader
        name={`${subject.name} - Prof. ${professorWithLists.name}`}
        color={subject.theme.color}
        themeId={subject.theme.id}
        textAlign={media.isMobile() ? 'center' : 'left'}
        utmContent={'pag_prof_assunto'}
      />
      <CardsContent>
        {showLecturesCards(lectures) && (
          <LecturesCards lectures={lectures} color={subject.theme.color} />
        )}

        <SubjectsCards
          subjects={[subject]}
          color={subject.theme.color}
          guidableType={subjectGuidableName}
        />

        <ProfessorListsCards
          professor={professorWithLists}
          acronymDisciplineUniversity={''}
          utmContent="pag_prof_assunto"
        />
      </CardsContent>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;
