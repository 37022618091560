import { useState, useCallback, useMemo } from 'react';
import Cookies from 'js-cookie';
import { getRecaptchaToken } from 'modules/shared/utils/getRecaptchaToken';
import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_RA_CONTENT_URL}/api/v2`;

export default function useContentLazyQueryWithoutCache(url, recaptcha = null) {
  const [queryParams, setQueryParams] = useState('');
  const [response, setResponse] = useState(null);

  const updateQueryParams = useCallback(params => {
    const newQueryParams = new URLSearchParams(params).toString();
    setQueryParams(newQueryParams);
  }, []);

  const completeUrl = useMemo(() => {
    return `${baseUrl}${url}${queryParams && `?${queryParams}`}`;
  }, [queryParams, url]);

  const makeContentRequest = useCallback(async () => {
    const userJwt = Cookies.get('user_jwt');
    const headers = { 'User-JWT': userJwt };

    if (recaptcha) {
      try {
        headers['X-Recaptcha-Token'] = await getRecaptchaToken(
          recaptcha.action
        );
      } catch (error) {
        console.error(error);
      }
    }

    const response = await axios.get(completeUrl, {
      withCredentials: true,
      headers,
    });

    setResponse(response.data);
  }, [recaptcha, completeUrl]);

  const query = useCallback(
    (params = {}) => {
      updateQueryParams(params);
      makeContentRequest();
    },
    [updateQueryParams, makeContentRequest]
  );

  return useMemo(() => {
    return [query, response];
  }, [query, response]);
}
