import { useCookies, useUTM } from 'modules/shared/hooks';
import { useEffect } from 'react';

export function useIsOrganicCookie() {
  const [, setCookie] = useCookies(['is_organic']);
  const { getUTMsByLocation } = useUTM();

  useEffect(() => {
    const urlUtms = getUTMsByLocation(window.location);

    const urlHasUtm = Object.keys(urlUtms).some(key => urlUtms[key]);
    const referrerIsGoogleOrBing =
      document.referrer.includes('google') ||
      document.referrer.includes('bing');

    if (referrerIsGoogleOrBing && !urlHasUtm) {
      const twentyMinutes = 1000 * 60 * 20;
      const expires = new Date(Date.now() + twentyMinutes);
      setCookie('is_organic', 'true', { expires, path: '/' });
    }
  }, [getUTMsByLocation, setCookie]);
}
