import React, { useCallback, useState } from 'react';
import { useProfileComplete } from 'modules/profile/hooks';
import {
  Button,
  CenterContainer,
  MainContainer,
  UniversityFlag,
} from './styles';
import TermsAndContactCheckbox from './components/TermsAndContactCheckbox';
import ErrorMessage from './components/ErrorMessage';
import Header from './components/Header';

export default function IsNotOnUniversityForm({ afterSubmit, onBack }) {
  const [saving, setSaving] = useState(false);
  const [acceptedTermsAndContact, setAcceptedTermsAndContact] = useState(false);
  const [error, setError] = useState('');

  const { submitProfileComplete } = useProfileComplete();

  const canSendForm = useCallback(() => {
    let error = '';

    if (!acceptedTermsAndContact) {
      error = 'Você precisa aceitar os termos de uso para continuar';
    }

    setError(error);
    setTimeout(() => {
      setError('');
    }, 3000);

    return error === '';
  }, [acceptedTermsAndContact, setError]);

  const updateProfile = useCallback(async () => {
    if (saving) return;

    if (!canSendForm()) return;

    let params = {
      contact_preferences: acceptedTermsAndContact,
      there_is_no_university: true,
      from: 'profile complete',
    };

    const university_selected = {
      id: 2907,
      acronym: 'Não Faço Faculdade',
    };

    setSaving(true);
    const { data, error } = await submitProfileComplete(
      params,
      university_selected
    );

    if (data?.ok) afterSubmit();
    else if (error) {
      setError('Houve um erro ao salvar o perfil. Tente novamente.');
      setTimeout(() => {
        setError('');
      }, 3000);
    }
    setSaving(false);
  }, [
    acceptedTermsAndContact,
    canSendForm,
    saving,
    setSaving,
    submitProfileComplete,
    afterSubmit,
    setError,
  ]);

  return (
    <MainContainer>
      <Header copy="Complete o cadastro" />

      <CenterContainer>
        <ErrorMessage error={error} />
        <UniversityFlag>
          <span>NÃO FAÇO FACULDADE</span>
          <span onClick={onBack}>ALTERAR</span>
        </UniversityFlag>
        <TermsAndContactCheckbox onChange={setAcceptedTermsAndContact} />
        <Button onClick={updateProfile} saving={saving}>
          {saving ? 'ENVIANDO...' : 'ENVIAR'}
        </Button>
      </CenterContainer>
    </MainContainer>
  );
}
