const stars = [
  {
    id: 1,
    description: 'Tem nada a ver!',
  },
  {
    id: 2,
    description: 'Muita coisa faltando/sobrando',
  },
  {
    id: 3,
    description: 'Alguns tópicos faltando ou a mais',
  },
  {
    id: 4,
    description: 'Só uns detalhes diferentes',
  },
  {
    id: 5,
    description: 'Idêntico ao cobrado!',
  },
];

export default stars;
