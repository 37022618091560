import { colors, styled, media } from 'styles';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 60px;
  padding-bottom: 20px;
  align-items: center;
  background-color: ${({ isEditing }) => (isEditing ? '#ffffff44' : '#fafafa')};
`;

export const ErrorContainer = styled.div`
  align-self: flex-end;
  z-index: 10;
  background: red;
  color: white;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  margin: -20px;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  align-items: center;
  cursor: pointer;
`;

export const TopTitle = styled.h1`
  display: flex;
  font-size: 17px;
  align-items: center;
  text-align: center;
  padding: 0 5%;
  width: 80%;
  max-width: 600px;
  justify-content: center;
`;

export const Copy = styled.h2`
  font-size: 17px;
  color: ${colors.darkGray};
  font-weight: bold;
  margin: 10px 20px;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-top: 10px solid #f6ac3a;
  border-radius: 10px;
  padding: ${({ minSize }) => (minSize ? '10px' : '30px')};
  margin-top: 30px;
  align-items: flex-start;
  color: black;
  background-color: white;

  width: ${({ minSize }) => (minSize ? '30%' : '80%')};
  max-width: 600px;

  @media ${media.mobile} {
    padding: 30px;
    width: 90%;
  }
`;

export const UniversitySelectorContainer = styled.div`
  margin-bottom: 18px;
  width: 100%;
`;

export const Input = styled.input`
  font-family: 'Lato', sans-serif;
  width: 100%;
  border: none;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
  border-radius: 4px;
  color: hsl(0, 0%, 10%);
  font-size: 1.005em;
  padding: 0 0 3px 11px;
  &:hover {
    border-color: #aaa;
  }
  &:focus {
    &:hover {
      border-color: #2684ff;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  border: 1px solid #f6ac3a;
  border-radius: 10px;

  color: white;
  background-color: #f6ac3a;
  margin-top: 20px;
  padding: 15px;

  font-weight: bold;
  font-size: 1.1em;

  cursor: ${({ saving }) => (saving ? 'not-allowed' : 'pointer')};
`;

export const ChecksTitle = styled.span`
  margin-bottom: 12px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Lato';
`;

export const Spacer = styled.div`
  height: 20px;
`;

const option = provided => ({
  ...provided,
  textAlign: 'left',
  '&:hover': {
    backgroundColor: '#eee',
  },
  '&:selected': {
    backgroundColor: '#eee',
  },
  backgroundColor: 'transparent',
  color: 'black',
});

const control = (provided, state) => ({
  ...provided,
  border: 'none',
  borderBottom: '1px solid #e6e6e6',
  justifyContent: 'flex-start',
  textAlign: 'left',
  boxShadow: state.isFocused ? 0 : 0,
});

export const selectCustomStyles = {
  control: (provided, state) => control(provided, state),
  option: provided => option(provided),
};

export const selectCustomComponents = {
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null,
};

export const Logo = styled.img`
  width: auto;
  height: 45px;
  margin-bottom: 40px;
`;

export const UniversityFlag = styled.div`
  width: 100%;
  background-color: rgba(250, 173, 61, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px;
  margin-bottom: 20px;
  span:nth-child(2) {
    color: #616161;
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
  }
`;
