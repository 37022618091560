import {
  Button,
  CenterContainer,
  CloseButton,
  Copy,
  Logo,
  MainContainer,
} from 'modules/profile/components/profile-complete/styles';
import React, { useCallback, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { raLogo } from 'images/main';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import UniversitySelects from 'modules/profile/components/profile-complete/components/UniversitySelects';
import CourseSelect from 'modules/profile/components/profile-complete/components/CourseSelect';
import AdmissionSelects from 'modules/profile/components/profile-complete/components/AdmissionSelects';
import { useEscClick } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import ErrorMessage from 'modules/profile/components/profile-complete/components/ErrorMessage';
import { useRAPostRequest } from 'modules/shared/hooks/requests';

export default function ProfileEdit() {
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [campi, setCampi] = useState([]);
  const [lastInput, setLastInput] = useState('');

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const { unsetDialog } = useDialogContext();
  const ref = useRef(null);

  useEscClick(ref, () => unsetDialog());
  const sendProfileEdit = useRAPostRequest('profile/edit_profile');

  const canSendForm = useCallback(() => {
    let error = '';

    if (!selectedCourse) {
      error = 'Você precisa selecionar um curso';
    } else if (!selectedYear) {
      error = 'Você precisa selecionar um ano';
    } else if (!selectedPeriod) {
      error = 'Você precisa selecionar um período';
    }
    if (!selectedUniversity) {
      error = 'Você precisa selecionar uma universidade';
    }
    if (campi.length > 0 && !selectedCampus) {
      error = 'Você precisa selecionar um campus';
    }
    setError(error);

    setTimeout(() => {
      setError('');
    }, 3000);

    return error === '';
  }, [
    setError,
    selectedUniversity,
    campi,
    selectedCampus,
    selectedCourse,
    selectedYear,
    selectedPeriod,
  ]);

  const updateProfile = useCallback(async () => {
    if (saving) return;

    if (!canSendForm()) return;

    let params = {
      university_id: selectedUniversity?.value,
      university_campus_id: selectedCampus?.value,
      last_input: lastInput,
      from: 'home',
      courseId: selectedCourse?.id,
      year: selectedYear,
      period: selectedPeriod,
    };

    try {
      setSaving(true);
      await sendProfileEdit(params);
      location.reload();
    } catch (e) {
      if (e) {
        setError('Houve um erro ao salvar o perfil. Tente novamente.');
        setTimeout(() => {
          setError('');
        }, 3000);
      }
    } finally {
      setSaving(false);
    }
  }, [
    saving,
    canSendForm,
    sendProfileEdit,
    selectedUniversity,
    selectedCampus,
    selectedCourse,
    selectedYear,
    selectedPeriod,
    lastInput,
  ]);

  return (
    <MainContainer ref={ref} isEditing>
      <CloseButton onClick={() => unsetDialog()}>
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '6px' }} />
        VOLTAR
      </CloseButton>
      <>
        <Logo src={raLogo} />
        <Copy>Atualizar cadastro</Copy>
      </>

      <CenterContainer>
        {error && <ErrorMessage error={error} />}
        <UniversitySelects
          onChangeSelectedUniversity={setSelectedUniversity}
          onChangeSelectedCampus={setSelectedCampus}
          onChangeCampi={setCampi}
          setLastInput={setLastInput}
        />

        {selectedUniversity?.name?.toLowerCase() !== 'não faço faculdade' && (
          <>
            <CourseSelect setSelectedCourse={setSelectedCourse} />
            <AdmissionSelects
              onChangePeriod={setSelectedPeriod}
              onChangeYear={setSelectedYear}
            />
          </>
        )}
        <Button onClick={updateProfile} saving={saving}>
          {saving ? 'ENVIANDO...' : 'ENVIAR'}
        </Button>
      </CenterContainer>
    </MainContainer>
  );
}
