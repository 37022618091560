import { useEscClick } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import React, { useCallback, useRef } from 'react';
import { MdClose } from 'react-icons/md';
import { styled, media } from 'styles';

export default function DeletePostConfirmation({ onSubmit }) {
  const { unsetDialog } = useDialogContext();
  const ref = useRef();

  useEscClick(ref, () => {
    unsetDialog();
  });

  const handleClose = useCallback(() => {
    unsetDialog();
  }, [unsetDialog]);

  return (
    <Container ref={ref}>
      <MdClose className="close-icon" onClick={handleClose} />

      <p>Tem certeza que quer apagar seu comentário?</p>
      <span>
        Caso existam comentários relacionados, eles não serão apagados.
      </span>
      <DoubleButtonContainer>
        <Button onClick={handleClose}>Não apagar</Button>
        <Button
          onClick={() => {
            onSubmit();
            handleClose();
          }}
          escButton
        >
          Apagar
        </Button>
      </DoubleButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-top: 10px solid #e9784f;
  border-radius: 10px;
  padding-top: 35px;
  margin: 100px auto;
  justify-content: center;
  align-items: center;
  color: #7a7a7a;
  box-shadow: 0px 3.29968px 3.29968px rgba(0, 0, 0, 0.25);

  background-color: white;
  align-self: center;
  position: relative;
  z-index: 1;

  width: 100%;
  max-width: 680px;

  p {
    font-weight: bold;
    font-size: 23px;
  }

  span {
    font-size: 15px;
    padding-top: 20px;
  }

  @media ${media.mobile} {
    padding: 30px;
    width: 95%;
  }

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }
`;

const Button = styled.button`
  width: 48%;
  border: 2px solid ${({ escButton }) => (escButton ? '#E9784F' : '#36AAAD')};
  border-radius: 40px;

  color: ${({ escButton }) => (escButton ? '#E9784F' : '#36AAAD')};
  background-color: #fff;
  margin: 30px 0px;
  padding: 15px;

  font-weight: bold;
  font-size: 0.9em;

  cursor: pointer;

  @media ${media.mobile} {
    margin: 10px;
    width: 100%;
  }
`;

const DoubleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 600px;
  margin-top: 30px;
  margin: 0 auto;

  @media ${media.mobile} {
    flex-direction: column;
    width: 85%;
  }
`;
