import { useMemo } from 'react';
import useSwrQueryGenerator from './useSwrQueryGenerator';
import { convertObjectToUnderscore } from 'modules/shared/utils';

const baseUrl = `${process.env.REACT_APP_RESPONDEAI_URL}/api/v3`;

export default function useRAQueryImmutable(url, params = {}, swrConfig = {}) {
  const queryParams = useMemo(() => {
    if (Object.keys(params).length === 0) return null;

    return new URLSearchParams(convertObjectToUnderscore(params)).toString();
  }, [params]);

  const completeUrl = useMemo(() => {
    return `${baseUrl}${url}${queryParams ? `?${queryParams}` : ''}`;
  }, [url, queryParams]);

  return useSwrQueryGenerator(completeUrl, {
    ...swrConfig,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  });
}
