import React, { useEffect, useMemo, useCallback, useState } from 'react';

import { styled } from 'styles';
import { usePaymentFailedRecoverCampaign } from 'modules/campaigns/recover-subscriber/payment-failed-recover/hooks/PaymentFailedRecoverCampaign';
import { useTracker } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import {
  Container,
  Content,
  Title,
  ButtonsContainer,
  OptionButton,
  Strong,
} from 'modules/campaigns/recover-subscriber/styles';
import {
  NoAccessPaymentRecoverDialog,
  RetrySuccessDialog,
  RetryErrorDialog,
} from '.';

export default function RetryWithSameCardDialog({ source, pricing }) {
  const [loading, setLoading] = useState(false);
  const { setDialog, unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const {
    getCard,
    retryPayment,
    defaultAmplitudeEventAttributes,
  } = usePaymentFailedRecoverCampaign();

  useEffect(() => {
    trackEvent('MVP Falha de Pagamento 2.0: Visualizou Confirmacao', {
      utm_source: source,
      opcao: 'mesmo cartao',
      plan: pricing.description,
      ...defaultAmplitudeEventAttributes,
    });
  }, [source, trackEvent, pricing, defaultAmplitudeEventAttributes]);

  const card = useMemo(() => {
    return getCard();
  }, [getCard]);

  const onRetryClick = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    const success = await retryPayment(pricing, null, card.id);
    if (!success) {
      setDialog(<RetryErrorDialog source={source} pricing={pricing} />);
      setLoading(false);
      return;
    }

    setDialog(<RetrySuccessDialog source={source} pricing={pricing} />);
  }, [loading, card, retryPayment, setDialog, source, pricing]);

  const renderPaymentNewPlanInfo = useCallback(() => {
    if (!card) return null;

    if (pricing.months === '1')
      return (
        <>
          <PaymentPlanInfo>
            <Strong>Forma de Pagamento:</Strong> Plano Mensal, 1 parcela de R${' '}
            {pricing.monthlyPricing.toFixed(2)} no{' '}
            <Strong>
              cartão {card.brand} de final X-
              {card.lastFourDigits}
            </Strong>
          </PaymentPlanInfo>
          <PaymentPlanInfo>
            <Strong>Primeira Cobrança:</Strong> Imediata
          </PaymentPlanInfo>
          <PaymentPlanInfo>
            <Strong>Renovação:</Strong> Mensal
          </PaymentPlanInfo>
        </>
      );

    return (
      <>
        <PaymentPlanInfo>
          <Strong>Forma de Pagamento:</Strong> Plano {pricing.description},
          {pricing.months} parcelas de R$ {pricing.monthlyPricing.toFixed(2)} no{' '}
          <Strong>
            cartão {card.brand} de final X-
            {card.lastFourDigits}
          </Strong>
        </PaymentPlanInfo>
        <PaymentPlanInfo>
          <Strong>Cobrança da primeira parcela:</Strong> Imediata
        </PaymentPlanInfo>
        <PaymentPlanInfo>
          <Strong>Renovação automática:</Strong> {pricing.description}
        </PaymentPlanInfo>
      </>
    );
  }, [card, pricing]);

  return (
    <Container onClick={unsetDialog}>
      <Content onClick={e => e.stopPropagation()}>
        <Title color="#787878">Retentar cobrança</Title>
        {renderPaymentNewPlanInfo()}
        <ButtonsContainer>
          <OptionButton
            onClick={() =>
              setDialog(<NoAccessPaymentRecoverDialog source={source} />)
            }
          >
            Voltar
          </OptionButton>

          <OptionButton onClick={onRetryClick}>
            {loading ? 'Retentando cobrança...' : 'Retentar cobrança'}
          </OptionButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const PaymentPlanInfo = styled.div`
  margin: 10px AUTO;
  font-size: 1.1em;
  width: 400px;
  max-width: 100%;
`;
