import { useCallback } from 'react';

import { useRAPostRequest } from 'modules/shared/hooks/requests';

export default function useFlag() {
  const saveFlagReq = useRAPostRequest('flags');

  const removeFlagReq = useRAPostRequest('flags/destroy');

  const saveFlag = useCallback(
    (name, contentType, contentId, guideId) => {
      return saveFlagReq({
        guideId: parseInt(guideId),
        contentId: parseInt(contentId),
        contentType,
        name,
      });
    },
    [saveFlagReq]
  );

  const removeFlag = useCallback(
    (name, contentType, contentId, guideId) => {
      return removeFlagReq({
        guideId: parseInt(guideId),
        contentId: parseInt(contentId),
        contentType,
        name,
      });
    },
    [removeFlagReq]
  );

  return { saveFlag, removeFlag };
}
