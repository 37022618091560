import React, { useCallback, useEffect, useState } from 'react';

import { styled, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { CardTitle } from '../components';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useTracker } from 'modules/shared/hooks';

function ContactPreferenceRequestRemotion({ setPermission }) {
  const { unsetDialog } = useDialogContext();
  const [communicationRefused, setCommunicationRefused] = useState(false);
  const [communicationMaintained, setCommunicationMaintained] = useState(false);
  const [loading, setLoading] = useState(false);
  const { trackEvent } = useTracker();

  const updateContactPreference = useRAPostRequest(
    'profile/remove_contact_content_preference'
  );

  useEffect(() => {
    trackEvent('Comunicações por e-mail: Visualizou Dialog');
  }, [trackEvent]);

  const handleCommunicationRefuse = useCallback(() => {
    setCommunicationRefused(!communicationRefused);
    trackEvent('Comunicações por e-mail: Recusou Comunicações');
  }, [setCommunicationRefused, communicationRefused, trackEvent]);

  const handleKeepCommunication = useCallback(() => {
    setCommunicationMaintained(!communicationMaintained);
  }, [setCommunicationMaintained, communicationMaintained]);

  const handleConfirm = useCallback(async () => {
    if (communicationRefused) {
      setLoading(true);
      try {
        await updateContactPreference();
        setPermission(false);
      } catch (err) {
        alert('Erro ao salvar seu perfil');
      } finally {
        setLoading(false);
      }
    }
    unsetDialog();
  }, [
    communicationRefused,
    setLoading,
    updateContactPreference,
    setPermission,
    unsetDialog,
  ]);

  const handleUserVoice = useCallback(() => {
    unsetDialog();
    window.UserVoice.show();
  }, [unsetDialog]);

  return (
    <Container>
      <Content>
        <Header></Header>
        <CardTitle
          className="contact-preference-request"
          title="Permissões de comunicação"
          backgroundColor="#e25017"
        />
        <TextContext>
          {communicationMaintained ? (
            <>
              <Title green>Manter comunicações</Title>
              <p>
                Você continuará recebendo mensagens sobre novidades, conteúdos
                gratuitos e oportunidades do mercado de trabalho :)
              </p>
            </>
          ) : communicationRefused ? (
            <>
              <Title>Não Permitir Comunicações</Title>
              <p>
                Sua solicitação de recusar comunicações será realizada em até 3
                dias. Antes disso, é possível que você ainda receba nossas
                mensagens.
              </p>
              <p>
                Essa solicitação não inclui as mensagens transacionais,
                relacionadas a informações da conta, assinaturas, cancelamentos
                e termos de uso.
              </p>
            </>
          ) : (
            <>
              <Title>Não Permitir Comunicações</Title>
              <p>
                Ao recusar comunicações, estará solicitando parar de receber
                mensagens sobre novidades, conteúdos gratuitos e oportunidades
                do mercado de trabalho.
              </p>
              <p>
                ATENÇÃO: Sua solicitação será realizada em até 3 dias. Antes
                disso, é possível que você ainda receba nossas mensagens
              </p>
            </>
          )}
          <ButtonContainer>
            {communicationMaintained || communicationRefused ? (
              <Button onClick={handleConfirm} disabled={loading} single>
                ENTENDI
              </Button>
            ) : (
              <>
                <Button onClick={handleKeepCommunication}>
                  MANTER COMUNICAÇÕES
                </Button>
                <Button onClick={handleUserVoice}>FALAR COM ATENDIMENTO</Button>
                <Button onClick={handleCommunicationRefuse}>
                  RECUSAR COMUNICAÇÕES
                </Button>
              </>
            )}
          </ButtonContainer>
        </TextContext>
      </Content>
    </Container>
  );
}

export default ContactPreferenceRequestRemotion;

const Header = styled.div`
  background: #e25017;
  height: 7px;
`;

const TextContext = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    width: 70%;
    text-align: left;
    color: ${colors.darkGray};
    font-size: 1em;
    font-weight: 500;
    padding-bottom: 60px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
`;

const Content = styled.div`
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate(0, 0) scale(1);
  border-radius: 4px;
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  min-width: 240px;
  max-width: 55%;
  max-height: 80%;
  position: relative;
  overflow: auto;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

const Title = styled.h2`
  padding: 24px;
  font-size: 1.7em;
  font-weight: bold;
  color: ${({ green }) => (green ? '#5fb070' : '#e25017')};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  padding-bottom: 63px;
  width: 85%;
`;

const Button = styled.button`
  color: #5fb070;
  border: 3px solid #5fb070;
  margin: ${({ single }) => (single ? '0 auto' : '8px')};
  box-sizing: border-box;
  min-width: 100px;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  width: 90%;
  border-radius: 7px;

  &:hover {
    background-color: rgba(158, 158, 158, 0.2);
  }
`;
