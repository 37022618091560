import React from 'react';

import { styled, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';

export default function SuccessSyllabusFeedbackDialog() {
  const { unsetDialog } = useDialogContext();

  return (
    <Container onClick={() => unsetDialog()}>
      <Content>
        <Title>Sucesso!</Title>
        <Subtitle>
          Obrigado pelo seu feedback! Os responsáveis por essa matéria já foram
          avisados :)
        </Subtitle>
        <Button>OK</Button>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 650px;
  max-width: 95%;
  margin: auto;
  padding: 25px 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 10px ${colors.lightGray};
`;

const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-size: 1.2em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
`;

const Subtitle = styled.h2`
  width: 100%;
  text-align: left;
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.87);
`;

const Button = styled.div`
  margin-top: 10px;
  padding: 10px 30px;
  color: ${colors.mainOrange};
  cursor: pointer;

  &:hover {
    background-color: rgba(158, 158, 158, 0.2);
  }
`;
