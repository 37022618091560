import { styled } from 'styles';

const ContainerBanner = styled.div`
  padding: 10px 5px;
  background-color: #e2502e;

  max-height: ${props => props.toggleEffect};
  transition: max-height 0.3s ease-in-out;

  h1,
  h2 {
    color: #fff;
    text-align: center;
  }

  h1 {
    font-size: 1.2em;
    font-weight: bold;

    strong {
      padding: 2px;
      background-color: #fff;
      color: #e2502e;
    }
  }

  h2 {
    margin-top: 5px;
  }

  .tag {
    position: absolute;
    width: 100px;
    padding: 2px;
    left: -31px;
    top: 15px;
    transform: rotate(-45deg);
    background-color: #fff;
    font-size: 0.8em;
    text-align: center;
    color: #e3502e;
    box-shadow: (0 2px 1px rgba(73, 2, 2, 0.15));
  }
`;

export { ContainerBanner };
