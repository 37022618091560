import React from 'react';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export default function PhoneInput({ phone, onChangePhone }) {
  return (
    <InputMask
      mask="(99)99999-9999"
      value={phone}
      onChange={e => onChangePhone(e.target.value)}
    >
      <CustomTextField
        label="Telefone"
        variant="standard"
        size="small"
        placeholder="Preencha seu telefone"
        inputProps={{
          style: {
            fontFamily: 'Lato, sans-serif',
            fontSize: '17px',
            padding: '10px',
          },
        }}
      />
    </InputMask>
  );
}

const CustomTextField = styled(TextField)`
  & {
    margin: 5px;
    width: 100%;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    color: '#fff';
  }
  .MuiInputBase-input {
    color: #606060;
  }
  .MuiInput-underline:hover:before {
    border: none;
    border-bottom: 1px solid gray !important;
  }
`;
