import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ContentTitle from './ContentTitle';
import { styled, media } from 'styles';
import { useArrangeData } from '../hooks/useArrangeData';
import { useTracker } from 'modules/shared/hooks';

const RA_URL = process.env.REACT_APP_RESPONDEAI_URL;

export default function ExamsContainer({ selectedDiscipline }) {
  const { getSevenLastExamsFromDiscipline } = useArrangeData();
  let [exams, setExams] = useState([]);
  const { trackEvent } = useTracker();

  useEffect(() => {
    const newestExams = getSevenLastExamsFromDiscipline(selectedDiscipline);
    setExams(newestExams);
  }, [selectedDiscipline, getSevenLastExamsFromDiscipline]);

  const title = useMemo(() => {
    return `Provas Resolvidas de ${selectedDiscipline.name}`;
  }, [selectedDiscipline]);

  const getLinkFromExamList = useCallback(
    exam => {
      const guideId = selectedDiscipline.guide.id;
      const listCategoryId = exam.listCategoryId;
      const listId = exam.id;
      const exerciseId = exam.listExercises[0].id;
      return `${RA_URL}/praticar/${guideId}/lista/${listCategoryId}/${listId}/exercicio-lista/${exerciseId}?internal_source=custom-dialog`;
    },
    [selectedDiscipline]
  );

  const fireExamsClickEvent = useCallback(
    exam => {
      trackEvent('Dialog Conteúdo Personalizado: Clicou Prova', {
        Prova: exam.listCategoryName,
        Disciplina: selectedDiscipline.name,
        Matéria: selectedDiscipline?.theme.name,
      });
    },
    [selectedDiscipline, trackEvent]
  );

  const onExamClick = useCallback(
    exam => {
      fireExamsClickEvent(exam);
      const link = getLinkFromExamList(exam);
      if (link) {
        window.open(link, '_blank');
      }
    },
    [getLinkFromExamList, fireExamsClickEvent]
  );

  return (
    <Container>
      <ContentTitle
        title={title}
        color={selectedDiscipline?.theme.color}
        underline={false}
      />
      <ExamsWrapper>
        {exams.map(e => {
          return (
            <ExamCard key={e.id} onClick={() => onExamClick(e)}>
              <ListCategory>{e.listCategoryName}</ListCategory>
              <ExamName>{e.name}</ExamName>
            </ExamCard>
          );
        })}
      </ExamsWrapper>
    </Container>
  );
}

const Container = styled.div``;

const ExamsWrapper = styled.div`
  display: flex;
  margin-top: 10px;

  @media ${media.mobile} {
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scrollbar-color: red;
    scrollbar-width: none;
    margin-top: 15px;
    margin-left: 5px;

    ::-webkit-scrollbar {
      display: none !important;
    }
  }
`;

const ExamCard = styled.div`
  background: #ffffff;
  border: 3px solid #c4c4c4;
  border-radius: 3px;
  min-width: 77px;
  max-width: 100%;
  height: 84px;
  padding: 0 5px;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 6px 6px #c4c4c4;
  cursor: pointer;
`;

const ListCategory = styled.p`
  text-align: center;
  color: #979797;
  font-weight: bold;
  font-size: 18.5px;
  line-height: 21px;
`;

const ExamName = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #979797;
`;
