import React from 'react';
import { styled, colors } from 'styles';

export default function CardTitle({
  className,
  title,
  upper,
  backgroundColor = null,
}) {
  return (
    <Container
      className={className}
      upper={upper}
      backgroundColor={backgroundColor}
    >
      <h2>{title}</h2>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  padding-top: 10px;
  padding-bottom: 40px;

  h2 {
    background-color: ${props =>
      props.backgroundColor ? props.backgroundColor : colors.mainOrange};
    color: white;
    padding: 5px 10px;
    position: absolute;
    left: -5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    text-transform: ${props => (props.upper ? 'uppercase' : 'none')};
  }
`;
