import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import React, { useEffect, useState } from 'react';
import RedirectTab from './RedirectTab';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';

export default function TheoryTab() {
  const { bookExercise } = useBookEditionContext();
  const [url, setUrl] = useState('');
  const { data: discipline } = useContentQueryImmutable(
    `/disciplines/subject/${bookExercise?.topic?.subject?.id}`
  );

  useEffect(() => {
    if (!bookExercise) return;

    const commomEndPath = `/topico/${bookExercise.topic.subject.id}/${bookExercise.topic.id}/teoria/${bookExercise.topic.theory.id}`;

    const urlToredirect = discipline
      ? `/aprender/${discipline.guide.id}${commomEndPath}`
      : `/aprender${commomEndPath}`;

    setUrl(urlToredirect);

    window.open(`${process.env.REACT_APP_REACT_URL}${urlToredirect}`, '_blank');
  }, [bookExercise, discipline]);

  return <RedirectTab to={url} textCTA="Abrir teoria deste tópico" />;
}
