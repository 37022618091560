import React, { useEffect, useCallback } from 'react';

import { styled } from 'styles';
import { useFormerSubscriberRecoverCampaign } from 'modules/campaigns/recover-subscriber/former-subscriber-recover/hooks';
import { useTracker } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import {
  Container,
  Content,
  Title,
  ButtonsContainer,
  OptionButton,
} from 'modules/campaigns/recover-subscriber/styles';
import { NewCardRecoverDialog, NoAccessDialogFormerSubscriber } from '.';

export default function ErrorDialog({ source, pricing, trackViewErrorName }) {
  const { trackEvent } = useTracker();
  const { setDialog, unsetDialog } = useDialogContext();
  const {
    card,
    defaultAmplitudeEventAttributes,
  } = useFormerSubscriberRecoverCampaign();

  useEffect(() => {
    trackEvent(trackViewErrorName, {
      utm_source: source,
      opcao: 'mesmo cartao',
      plan: pricing.description,
      ...defaultAmplitudeEventAttributes,
    });
  }, [
    source,
    trackEvent,
    pricing,
    trackViewErrorName,
    defaultAmplitudeEventAttributes,
  ]);

  const onNewCardClick = useCallback(() => {
    setDialog(<NewCardRecoverDialog source={source} pricing={pricing} />);
  }, [setDialog, source, pricing]);

  const onNewPlanClick = useCallback(() => {
    setDialog(<NoAccessDialogFormerSubscriber source={source} />);
  }, [setDialog, source]);

  return (
    <Container onClick={unsetDialog}>
      <Content onClick={e => e.stopPropagation()}>
        <Title>Erro ao efetuar cobrança</Title>
        <PaymentInfo>
          Não conseguimos realizar a cobrança de{' '}
          <Strong>
            R$ {pricing.priceToShow}{' '}
            {pricing.months > 1 &&
              `(${pricing.months}x de R$${pricing.monthlyPricingToShow})`}
          </Strong>{' '}
          em seu cartão de crédito{' '}
          <Strong>
            {card.brand} de final X-{card.lastFourDigits}.
          </Strong>
        </PaymentInfo>
        <PaymentInfo>
          Com isso, seu acesso aos conteúdos continua bloqueado. Deseja tentar
          efetuar o pagamento por um novo cartão de crédito?
        </PaymentInfo>
        <ButtonsContainer>
          <OptionButton onClick={onNewPlanClick}>Trocar de Plano</OptionButton>
          <OptionButton onClick={onNewCardClick}>
            Tentar com novo cartão
          </OptionButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const PaymentInfo = styled.div`
  font-size: 1.2em;
  margin: 15px 0;
`;

const Strong = styled.span`
  font-weight: bold;
`;
