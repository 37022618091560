import React from 'react';
import { media, styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import SearchFeedbackContainer from '../components/SearchFeedbackContainer';

export default function SearchFeedbackDialog({ source, text }) {
  const { unsetDialog } = useDialogContext();

  return (
    <ContainerAllPage onClick={unsetDialog}>
      <SearchFeedbackContainer
        afterSendFeedbackCallback={unsetDialog}
        source={source}
        text={text}
      />
    </ContainerAllPage>
  );
}

const ContainerAllPage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);

  @media ${media.mobile} {
    padding-top: 50px;
  }
`;
