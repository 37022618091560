import React, { useEffect, useCallback, useState } from 'react';

import { styled } from 'styles';
import { useFormerSubscriberRecoverCampaign } from 'modules/campaigns/recover-subscriber/former-subscriber-recover/hooks';
import { useTracker } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import {
  Container,
  Content,
  Title,
  ButtonsContainer,
  OptionButton,
  Strong,
  Message,
} from 'modules/campaigns/recover-subscriber/styles';
import { NoAccessDialogFormerSubscriber, SuccessBoletoDialog } from '.';

export default function BoletoRecoverDialog({ source, pricing }) {
  const [loading, setLoading] = useState(false);
  const { setDialog, unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const {
    recoverPayment,
    defaultAmplitudeEventAttributes,
  } = useFormerSubscriberRecoverCampaign();

  useEffect(() => {
    trackEvent('Recuperacao Ex Assinantes: Visualizou Confirmacao', {
      utm_source: source,
      opcao: 'boleto',
      plan: pricing.description,
      ...defaultAmplitudeEventAttributes,
    });
  }, [source, trackEvent, pricing, defaultAmplitudeEventAttributes]);

  const onRetryClick = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    const result = await recoverPayment(
      pricing,
      'boleto',
      null,
      null,
      'former_subscriber'
    );
    if (!result.success) {
      alert('Erro ao emitir novo boleto!');
      setLoading(false);
      return;
    }

    setDialog(
      <SuccessBoletoDialog
        source={source}
        pricing={pricing}
        boletoUrl={result.boletoUrl}
      />
    );
  }, [recoverPayment, loading, setDialog, source, pricing]);

  if (!pricing) return null;
  return (
    <Container
      onClick={unsetDialog}
      data-cy="former-subscriber-emit-boleto-dialog"
    >
      <Content onClick={e => e.stopPropagation()}>
        <Title color="#787878">Informações de Pagamento</Title>
        <Message>Confirma essas informações pra gente, por favor?</Message>
        <PaymentPlanInfo>
          <Strong>Forma de Pagamento:</Strong> Plano {pricing.description}, R${' '}
          {pricing.price.toFixed(2)} à vista no <Strong>boleto</Strong>
        </PaymentPlanInfo>
        <ButtonsContainer>
          <OptionButton
            onClick={() =>
              setDialog(<NoAccessDialogFormerSubscriber source={source} />)
            }
          >
            Voltar
          </OptionButton>

          <OptionButton onClick={onRetryClick}>
            {loading ? 'Emitindo boleto...' : 'Emitir boleto'}
          </OptionButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const PaymentPlanInfo = styled.div`
  margin: 10px auto;
  font-size: 1.1em;
  width: 400px;
  max-width: 100%;
`;
