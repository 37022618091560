import React, { useCallback, useEffect, useState } from 'react';
import { styled } from 'styles';
import { HiCheck } from 'react-icons/hi';
import { spinner } from 'modules/main/images';
import { useTracker } from 'modules/shared/hooks';
import { useContentRestRequest } from 'modules/shared/hooks/requests';

export default function SaveButtonFromStudyPlan({ studyPlan, isEdit = null }) {
  const { sendPostRequest, sendPutRequest } = useContentRestRequest();
  const { trackEvent } = useTracker();
  const [loading, setLoading] = useState(false);

  const onSendStudyPlan = useCallback(async () => {
    const { themeId, subjectsIds, guideId, id } = studyPlan;

    if (loading) return;
    setLoading(true);

    if (isEdit) {
      await sendPutRequest('/study-plans', { subjectsIds, guideId });
      document.location.href = `/plano-de-estudo/${id}/guia/${guideId}`;
    } else {
      const { data: studyPlanReturn } = await sendPostRequest('/study-plans', {
        subjectsIds,
        themeId,
      });
      const { studyPlanId, guideId } = studyPlanReturn;
      trackEvent('Plano de Estudos: Criou', {
        Plataforma: 'Web',
        id: studyPlanId,
      });
      document.location.href = `/plano-de-estudo/${studyPlanId}/guia/${guideId}`;
    }
  }, [studyPlan, loading, isEdit, trackEvent, sendPutRequest, sendPostRequest]);

  useEffect(() => {
    return setLoading(false);
  }, []);

  return (
    <Container className={studyPlan.subjectsIds.length > 0 && 'visible'}>
      <Button onClick={onSendStudyPlan}>
        {loading ? (
          <>
            <img src={spinner} className="spinner" alt="spinner" />
            <span>Salvando...</span>
          </>
        ) : (
          <>
            <HiCheck className="icon" />
            <span>Concluir!</span>
          </>
        )}
      </Button>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.95);
  border-top: 1px solid #f2f2f2;
  position: fixed;
  bottom: -74px;
  left: 0;
  z-index: 15;
  transition: bottom 300ms ease-in-out;

  &.visible {
    bottom: 0;
  }
`;

const Button = styled.div`
  transition: background 200ms ease-in-out;
  display: inline-block;
  width: 250px;
  height: 46px;
  line-height: 46px;
  margin-top: 13px;
  background: #00d481;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.08em;
  font-style: italic;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #00a967;
  }

  .icon {
    font-size: 1.8rem;
    margin-right: 10px;
  }

  .spinner {
    width: 40px;
    margin-right: 5px;
  }
`;
