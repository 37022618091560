import React, { useCallback } from 'react';
import { styled, media } from 'styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import useSearchNavigation from '../hooks/useScreenNavigation';
import { useDialogContext } from 'providers/DialogProvider';
import useSearchTracker from '../hooks/useSearchTracker';
import SearchFeedbackDialog from '../dialogs/SearchFeedbackDialog';

export default function BackButton() {
  const { goPreviousScreen } = useSearchNavigation();
  const { setDialog } = useDialogContext();
  const { trackCloseSearchDialog } = useSearchTracker();

  const onCloseDialog = useCallback(() => {
    trackCloseSearchDialog();
    setDialog(
      <SearchFeedbackDialog
        source="Busca"
        text="Você está gostando da busca?"
      />
    );
  }, [trackCloseSearchDialog, setDialog]);

  return (
    <Container data-cy="nav-header">
      <Button onClick={goPreviousScreen}>
        <Icon icon={faArrowLeft} />
      </Button>
      <span onClick={onCloseDialog}>X</span>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 20px;
  left: 0px;
  width: 100%;
  height: 40px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;

  span {
    font-size: 1.5em;
    transition: opacity 150ms ease-in-out;
    color: #bbb;
    cursor: pointer;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }

  @media ${media.mobile} {
    top: 8px;
    padding: 0 20px;

    span {
      font-size: 1em;
    }
  }
`;

const Button = styled.button`
  transition: opacity 150ms ease-in-out;
  height: 100%;
  color: #bbb;
  cursor: pointer;
  opacity: 0.85;
  &:hover {
    opacity: 1;
  }
  font-size: 1em;

  @media ${media.desktop} {
    font-size: 1.3em;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.5em;
`;
