import React from 'react';
import { Route } from 'react-router-dom';
import { CreditCardTrialPage } from './pages';

export default [
  <Route
    exact
    path="/lancamento/trial-com-cartao"
    component={CreditCardTrialPage}
    key="trial-with-card-lancamento"
  />,
];
