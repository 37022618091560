import _ from 'lodash';
import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { styled, colors } from 'styles';

export default function ProgressInfo({ flags, chapters, guideId }) {
  const progressPercentage = useMemo(() => {
    if (!flags) return 0;
    const flagsFiltered = flags.filter(flag => flag.guideId == guideId);
    const flagsFromTopic = flagsFiltered.filter(
      flag => flag.contentType === 'topico'
    );
    if (flagsFromTopic.length === 0) return 0;

    const topics = _.flatten(chapters.map(chapter => chapter.topics));
    const topicsChecked = topics.filter(
      topic => !!flagsFromTopic.find(flag => flag.contentId == topic.id)
    );

    const total = topics.length;
    const checked = topicsChecked.length;

    return Math.round((checked / total) * 100);
  }, [flags, chapters, guideId]);

  return (
    <Container>
      <Line>
        <Text>Progresso</Text>
        <SolvedPercentage>
          <Check icon={faCheck} /> {progressPercentage}% feito
        </SolvedPercentage>
      </Line>
      <Line>
        <ProgressBar>
          <ProgressChecked percentage={progressPercentage} />
        </ProgressBar>
      </Line>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  font-size: 0.8em;
  font-weight: 100;
  text-transform: uppercase;
  color: ${colors.darkGray};
`;

const Line = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.span``;

const SolvedPercentage = styled.div`
  display: flex;
  color: ${colors.check};
  font-weight: bold;
  font-size: 1.1em;
`;

const Check = styled(FontAwesomeIcon)`
  margin-right: 5px;
  margin-top: 2px;
  font-size: 0.85em;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 5px;
  margin-top: 7px;
  background-color: #eee;
`;

const ProgressChecked = styled.div`
  width: ${({ percentage }) => `${percentage}%`};
  height: 100%;
  background-color: ${colors.check};
`;
