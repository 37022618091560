import {
  poo,
  partyHard,
  pokerFace,
  thinkingFace,
  confusedFace,
  sleepingFace,
  faceWithRollingEyes,
  page,
  pensiveFace,
} from 'images/content-feedback';

export const CONTENT_FEEDBACK_GRADES = [
  {
    id: 1,
    text: 'Passou longe!',
    iconSrc: poo,
  },
  {
    id: 2,
    text: 'Meh!',
    iconSrc: pokerFace,
  },
  {
    id: 3,
    text: 'Demais!',
    iconSrc: partyHard,
  },
];

export const CONTENT_FEEDBACK_FEELINGS_THEORY_TEXT = [
  {
    id: 1,
    text: 'Difícil de entender',
    iconSrc: confusedFace,
  },
  {
    id: 2,
    text: 'Chata/Cansativa',
    iconSrc: sleepingFace,
  },
  {
    id: 3,
    text: 'Muito Simples/Superficial',
    iconSrc: faceWithRollingEyes,
  },
  {
    id: 4,
    text: 'Outro',
    iconSrc: thinkingFace,
  },
];

export const CONTENT_FEEDBACK_FEELINGS_THEORY_VIDEO = [
  {
    id: 1,
    text: 'Difícil de entender',
    iconSrc: confusedFace,
  },
  {
    id: 2,
    text: 'Chata/Cansativa',
    iconSrc: sleepingFace,
  },
  {
    id: 3,
    text: 'Muito Simples/Superficial',
    iconSrc: faceWithRollingEyes,
  },
  {
    id: 4,
    text: 'Folhinhas Feias',
    iconSrc: page,
  },
  {
    id: 5,
    text: 'Outro',
    iconSrc: thinkingFace,
  },
];

export const CONTENT_FEEDBACK_FEELINGS_EXERCISE_TEXT = [
  {
    id: 1,
    text: 'Difícil de entender',
    iconSrc: confusedFace,
  },
  {
    id: 2,
    text: 'Não tá no nível da prova',
    iconSrc: pensiveFace,
  },
  {
    id: 5,
    text: 'Outro',
    iconSrc: thinkingFace,
  },
];

export const CONTENT_FEEDBACK_FEELINGS_EXERCISE_VIDEO = [
  {
    id: 1,
    text: 'Difícil de entender',
    iconSrc: confusedFace,
  },
  {
    id: 2,
    text: 'Não tá no nível da prova',
    iconSrc: pensiveFace,
  },
  {
    id: 3,
    text: 'Chata/Cansativa',
    iconSrc: sleepingFace,
  },
  {
    id: 4,
    text: 'Folhinhas Feias',
    iconSrc: page,
  },
  {
    id: 5,
    text: 'Outro',
    iconSrc: thinkingFace,
  },
];
