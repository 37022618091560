import React, { useEffect } from 'react';

import exclusiveLecture from '../../images/exclusive_lecture.png';
import useLectureTracker from 'modules/lectures/hooks/useLectureTracker';
import { styled } from 'styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCheck } from '@fortawesome/free-solid-svg-icons';
import { media } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { BookCreditCardTrialCTADialog } from 'modules/campaigns/book-trial/components';

const SubscriberOverlay = ({ isElegibleToCreditCardTrial, trialDays }) => {
  const { setDialog } = useDialogContext();

  const { trackPayWallLecture } = useLectureTracker();

  useEffect(() => {
    trackPayWallLecture();
  }, [trackPayWallLecture]);

  return (
    <Overlay>
      <Container>
        <OverlayImage src={exclusiveLecture} />
        <OverlayTitle>
          <StarIcon icon={faStar} /> CONTEÚDO PARA ASSINANTES
        </OverlayTitle>
        <Benefits>
          <Benefit>
            <CheckIcon icon={faCheck} /> Acesso completo a todos os aulões
          </Benefit>
          <Benefit>
            <CheckIcon icon={faCheck} />
            TODO o material do Responde Aí incluso
          </Benefit>
          <Benefit>
            <CheckIcon icon={faCheck} />
            Explicações em texto ou vídeo
          </Benefit>
          <Benefit>
            <CheckIcon icon={faCheck} />
            Exercícios resolvidos passo a passo
          </Benefit>
          <Benefit>
            <CheckIcon icon={faCheck} />
            Milhares de questões resolvidas de várias faculdades
          </Benefit>
        </Benefits>
        <ButtonContainer>
          {isElegibleToCreditCardTrial ? (
            <TrialButton
              onClick={() =>
                setDialog(<BookCreditCardTrialCTADialog source="Aulão" />)
              }
            >
              {`Ganhar ${trialDays} dias de Responde Aí`}
            </TrialButton>
          ) : (
            <PlansButton
              href={`${process.env.REACT_APP_RA_WEB_SSR_URL}/planos`}
            >
              Ver Planos
            </PlansButton>
          )}
        </ButtonContainer>
      </Container>
    </Overlay>
  );
};

const OverlayImage = styled.img`
  margin: 30px 0 20px 0;
  @media ${media.mobile} {
    height: 10%;
    margin: 5px 0 5px 0;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  height: 100%;
`;

const Container = styled.div`
  height: 80%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${media.mobile} {
    overflow: hidden;
    justify-content: flex-start;
  }
`;

const OverlayTitle = styled.div`
  font-size: 1.2em;
  text-align: center;
  width: 100%;
  margin: 20px 0;
  color: #666;
  @media ${media.mobile} {
    font-size: 0.8em;
  }
  svg {
    color: #f9ac3e;
    margin-right: 5px;
  }
`;

const Benefits = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @media ${media.mobile} {
    font-size: 0.5em;
  }
`;

const Benefit = styled.div`
  line-height: 25px;
  @media ${media.mobile} {
    line-height: inherit;
    display: flex;
    align-items: center;
  }
  svg {
    color: #7ed48a;
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }
`;

const PlansButton = styled.a`
  background-color: #00b9d8;
  font-size: 1.2em;
  border-radius: 5px;
  color: white;
  width: 220px;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  @media ${media.mobile} {
    width: 110px;
    height: 30px;
    line-height: 30px;
    font-size: 0.8em;
    margin: 10px 0 5px 0;
  }
  svg {
    margin-right: 5px;
    font-weight: lighter;
  }
`;

const TrialButton = styled.div`
  background-color: #00b9d8;
  font-size: 1.2em;
  border-radius: 5px;
  color: white;
  min-width: 220px;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  padding: 0 10px;
  @media ${media.mobile} {
    width: 110px;
    height: 30px;
    line-height: 30px;
    font-size: 0.8em;
    margin: 10px 0 5px 0;
  }
  svg {
    margin-right: 5px;
    font-weight: lighter;
  }

  cursor: pointer;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  justify-content: center;
`;

const StarIcon = styled(FontAwesomeIcon)``;
const CheckIcon = styled(FontAwesomeIcon)``;

export default SubscriberOverlay;
