import React, { useCallback } from 'react';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { ChangePlanOptionsDialog } from 'modules/campaigns/change-plan/components';

export default function ChangePlanButton({ subscription }) {
  const { setDialog } = useDialogContext();

  const handleChangePlanPress = useCallback(() => {
    setDialog(<ChangePlanOptionsDialog subscription={subscription} />);
  }, [subscription, setDialog]);

  return (
    <Container onClick={handleChangePlanPress}>
      <button onClick={handleChangePlanPress}>
        <Icon icon={faSyncAlt} />
        Trocar seu plano
      </button>
    </Container>
  );
}

const Container = styled.div`
  margin: 10px 0;

  button {
    background-color: #eee;
    font-size: 1em;
    padding: 5px 15px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    svg {
      margin-right: 5px;
    }
  }

  p {
    margin-top: 5px;
    font-size: 0.8em;
    font-weight: bold;
  }
`;
