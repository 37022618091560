import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components/macro';

export default function Error() {
  return (
    <Container>
      <FontAwesomeIcon
        icon={faFrown}
        style={{ fontSize: '3em', marginBottom: '10px' }}
      />
      Ops, houve algum problema
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.5;
  flex-direction: column;
`;
