import React from 'react';
import { colors, styled } from 'styles';

export default function WarningText({ type = 'question' }) {
  return (
    <WarningTextContainer>
      {type === 'answer'
        ? 'Seu comentário está aguardando aprovação para ser postado e ainda não pode ser\nvisualizado por outras pessoas ;)'
        : 'Sua questão está aguardando aprovação para ser postada e ainda não pode ser\nvisualizada por outras pessoas ;)'}
    </WarningTextContainer>
  );
}

const WarningTextContainer = styled.p`
  color: ${colors.warningRed};
  font-size: 1em;
  margin: 10px 0;
`;
