import React, { useState, useCallback } from 'react';
import { styled, colors } from 'styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '@material-ui/core';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { ReactComponent as XMarkSVG } from '../../../images/book/xmark-solid.svg';

export default function BookSuggestion({ guideName }) {
  const [openSuggestion, setOpenSuggestion] = useState(false);
  const [bookSuggestion, setBookSuggestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const createBookSuggestion = useRAPostRequest('/create_book_suggestion');

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      setLoading(true);

      if (errorMessage) setErrorMessage('');

      if (!bookSuggestion || bookSuggestion.length < 5) {
        setErrorMessage('Por favor, indique o nome, autor e edição do livro.');
        setLoading(false);
        return;
      }

      const requestOptions = {
        book_indication: bookSuggestion,
        guide_name: guideName,
      };

      try {
        await createBookSuggestion(requestOptions);

        setSuccessMessage('Booa!');
      } catch (e) {
        setErrorMessage(e.response.data.message);
      } finally {
        setLoading(false);
      }
    },
    [bookSuggestion, createBookSuggestion, errorMessage, guideName]
  );

  const handleClose = useCallback(() => {
    setOpenSuggestion(false);
    setBookSuggestion('');
    setSuccessMessage('');
    setErrorMessage('');
  }, []);

  return (
    <Content data-cy="book-suggestion-container">
      {openSuggestion ? (
        <SuggestionContent onSubmit={handleSubmit}>
          <Header>
            <Icon icon={faBook} />
            <h1>{successMessage || 'Qual livro deseja sugerir?'}</h1>
            <XMarkIcon fill={colors.darkGray} onClick={handleClose} />
          </Header>
          {successMessage ? (
            <p>
              Vamos verificar com nossa equipe a possibilidade de montar este
              solucionário.
            </p>
          ) : (
            <>
              <CustomTextField
                variant="standard"
                label="Nome, autor e edição"
                value={bookSuggestion}
                onChange={e => setBookSuggestion(e.target.value)}
                size="small"
                InputProps={{
                  style: {
                    color: colors.darkGray,
                    fontFamily: 'Lato, sans-serif',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.workspaceOrange,
                    fontFamily: 'Lato, sans-serif',
                  },
                }}
                disabled={loading ? 'disabled' : ''}
              ></CustomTextField>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <Button type="submit" disabled={loading}>
                {loading ? 'Enviando...' : 'Enviar Sugestão'}
              </Button>
            </>
          )}
        </SuggestionContent>
      ) : (
        <BookSuggestionCTA
          onClick={() => setOpenSuggestion(true)}
          data-cy="cta-book-suggestion"
        >
          Sugerir outro livro
        </BookSuggestionCTA>
      )}
    </Content>
  );
}

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 10px;
  font-family: 'Lato', sans-serif;
`;

const BookSuggestionCTA = styled.div`
  margin: 20px 0 15px 0;
  text-align: center;
  text-decoration: underline;
  font-size: 0.9em;
  color: ${colors.darkGray};
  cursor: pointer;
`;

const SuggestionContent = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 15px;
  border-radius: 10px;

  p {
    font-size: 13px;
    font-weight: bold;
    text-align: center;
  }
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  h1 {
    font-size: 13px;
    font-weight: bold;
  }

  svg {
    color: ${colors.darkGray};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 13px;
`;

const CustomTextField = styled(TextField)`
  & {
    margin: 5px;
    width: 100%;
    font-weight: bold;
    border: 2px solid red;
    font-family: 'Lato', sans-serif;
  }
`;

const Button = styled.button`
  background-color: #fff;
  color: ${colors.workspaceOrange};
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid ${colors.lightGray};
  max-width: 350px;
  width: 100%;
  margin-top: 15px;
  padding: 12px;
  box-shadow: 1px 2px 5px 1px ${colors.lightGray};
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  margin: 5px;
  text-align: center;
  color: #cc0000;
`;

const XMarkIcon = styled(XMarkSVG)`
  width: 10px;
  cursor: pointer;
`;
