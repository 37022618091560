import React, { useEffect, useCallback, useState } from 'react';

import { styled, media } from 'styles';
import { MailOutline, ErrorOutline } from '@material-ui/icons';
import { useEmailInvolvement } from 'modules/campaigns/email-involvement';
import { useTracker } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';

export default function EmailInvolvementB() {
  const [loading, setLoading] = useState(false);
  const { trackEvent } = useTracker();
  const { user } = useUserContext();
  const { clearEmailInvolvementCookies } = useEmailInvolvement();
  const { unsetDialog } = useDialogContext();

  useEffect(() => {
    trackEvent('Email Involvement: Visualizou Dialog', {
      versão: 'Barra de progresso',
    });
  }, [trackEvent]);

  useEffect(() => {
    clearEmailInvolvementCookies();
  }, [clearEmailInvolvementCookies]);

  const goGoEmail = useCallback(() => {
    if (user.provider === 'google_oauth2') {
      trackEvent('Email Involvement: Clicou CTA', {
        type: 'google',
        versão: 'Barra de progresso',
      });
      window.open('https://mail.google.com/mail/u/0/#spam', '_blank');
      unsetDialog();
      return;
    }

    const outlookDomains = ['outlook.com', 'hotmail.com', 'live.com'];
    const yahooDomains = [
      'yahoo.com',
      'yahoo.com.br',
      'ymail.com',
      'ymail.com.br',
    ];
    const domain = user.email.split('@')[1];
    if (outlookDomains.includes(domain)) {
      trackEvent('Email Involvement: Clicou CTA', {
        type: 'outlook',
        versão: 'Barra de progresso',
      });
      window.open('https://outlook.live.com/mail/junkemail', '_blank');
      unsetDialog();
    } else if (yahooDomains.includes(domain)) {
      trackEvent('Email Involvement: Clicou CTA', {
        type: 'yahoo',
        versão: 'Barra de progresso',
      });
      window.open('https://mail.yahoo.com/d/folders/6', '_blank');
      unsetDialog();
    } else {
      trackEvent('Email Involvement: Clicou CTA', {
        type: 'google',
        versão: 'Barra de progresso',
      });
      window.open('https://mail.google.com/mail/u/0/#spam', '_blank');
      unsetDialog();
    }
  }, [user, trackEvent, unsetDialog]);

  const ctaClick = useCallback(() => {
    if (loading) return;
    setLoading(true);

    setTimeout(() => goGoEmail(), 900);
  }, [loading, setLoading, goGoEmail]);

  const closeDialog = useCallback(() => {
    trackEvent('Email Involvement: Fechou Dialog', {
      versão: 'Barra de progresso',
    });
    unsetDialog();
  }, [trackEvent, unsetDialog]);

  return (
    <Dialog>
      <Container>
        <TitleSection>
          <Title>
            Você acabou de receber um 🎁
            <br /> Use o Responde Aí por <u>7 DIAS GRÁTIS</u>
          </Title>
        </TitleSection>
        <StepSection>
          <StepTitelSection>
            <StepTitle>Como ganhar seus 7 dias grátis ?</StepTitle>
            <SetpSubtitle>(é facil e não precisa preencher nada!)</SetpSubtitle>
          </StepTitelSection>
          <StepByStep>
            <Step>
              <ProgressBar className="first">
                <MailIcon />
              </ProgressBar>
              <Instruction className="first">
                <p>
                  <strong>Clica no botão aí embaixo</strong> para abrir sua
                  caixa de emails e procura pelo email de boas vindas do
                  Responde Aí
                </p>
              </Instruction>
            </Step>
            <Step>
              <ProgressBar className="last">
                <SpamIcon />
              </ProgressBar>
              <Instruction className="last">
                <p>
                  <u>Caso não encontre</u>, procure na caixa de SPAM. Nesse
                  caso, você precisa abrir o email e clicar no botão “Não é
                  SPAM”.
                </p>
              </Instruction>
            </Step>
            <Step>
              <ProgressBar className="finish"></ProgressBar>
              <Instruction className="finish">
                <p>
                  <strong>Pronto 😃!</strong> Dentro desse email tem um link
                  para receber sua recompensa.
                  <strong> Só clicar e liberar.</strong>
                </p>
              </Instruction>
            </Step>
          </StepByStep>
          <ButtonSection>
            <TrialButton onClick={ctaClick}>
              {loading ? (
                <>Estamos enviando seu presente...</>
              ) : (
                <>Quero Ganhar 7 Dias GRÁTIS</>
              )}
            </TrialButton>
            <CloseDialogText onClick={closeDialog}>
              Não quero o meu presente &#128546;
            </CloseDialogText>
          </ButtonSection>
        </StepSection>
      </Container>
    </Dialog>
  );
}

const Dialog = styled.div`
  width: 100%;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgb(2 34 66 / 34%);
  padding: 0 10px 0 10px;
`;
const Container = styled.div`
  height: 85%;
  aspect-ratio: 8 / 7;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #121116;
  overflow: hidden;
  max-height: 900px;
`;

const TitleSection = styled.div`
  background-color: #121116;
  width: 100%;
  height: 25%;
  border-radius: 0 0 35% 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: 27px;
  font-weight: 700;
  line-height: 38px;
  color: whitesmoke;
  @media ${media.mobile} {
    font-size: 19px;
    letter-spacing: inherit;
    line-height: inherit;
  }
`;

const StepSection = styled.div`
  margin: auto;
  color: #ffffff;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StepTitelSection = styled.div`
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${media.mobile} {
    height: 15%;
  }
`;

const StepTitle = styled.h2`
  color: #121116;
  font-weight: 900;
  font-size: 22px;
  letter-spacing: 0.8px;
  @media ${media.mobile} {
    font-size: 18px;
    letter-spacing: inherit;
  }
`;

const SetpSubtitle = styled.h3`
  color: #121116;
  font-weight: 600;
  font-size: 16px;
  margin-top: 4px;
  @media ${media.mobile} {
    font-size: 14px;
    letter-spacing: inherit;
  }
`;

const StepByStep = styled.div`
  width: 65%;
  background-color: white;
  border-radius: 2%;
  height: 60%;
  @media ${media.mobile} {
    width: 90%;
  }
`;

const Step = styled.div`
  height: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
`;

const ProgressBar = styled.div`
  height: 100%;
  width: 32px;
  background-color: #2c66ea;
  border: 2px solid white;
  @media ${media.mobile} {
    width: 24px;
  }
  &.first {
    border-bottom: 0;
    margin-bottom: -10px;
    border-radius: 15px 15px 0 0;
  }
  &.last {
    border-radius: 15px 15px 15px 15px;
    z-index: 2;
    height: 110%;
  }
  &.finish {
    background: rgb(126, 222, 137);
    height: 110%;
    max-width: 30px;
    background: linear-gradient(
      180deg,
      rgba(126, 222, 137, 1) 0%,
      rgba(126, 222, 137, 0.16290266106442575) 83%,
      rgba(126, 222, 137, 0) 100%
    );
  }
`;

const Instruction = styled.div`
  color: #121116;
  align-self: flex-start;
  font-size: 16px;
  text-align: left;
  margin-left: 10px;
  line-height: 22px;
  font-weight: 500;
  letter-spacing: 1.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  &.last {
    margin-top: 15px;
  }
  &.first {
    margin-top: 15px;
  }
  &.finish {
    margin-top: 10px;
  }

  @media ${media.mobile} {
    font-size: 13px;
    letter-spacing: inherit;
    line-height: inherit;
    margin-top: 10px;
    margin-left: 3px;
    &.first {
      margin-top: 20px;
    }
    &.last {
      margin-top: 10px;
    }
  }
`;

const MailIcon = styled(MailOutline)`
  margin-top: 9px;
  @media ${media.mobile} {
    font-size: 18px !important;
  }
`;

const SpamIcon = styled(ErrorOutline)`
  margin-top: 9px;
  @media ${media.mobile} {
    font-size: 18px !important;
  }
`;

const ButtonSection = styled.div`
  height: 20%;
  padding-top: 10px;
`;

const TrialButton = styled.div`
  display: flex;
  color: #121116;
  margin: auto;
  font-weight: 600;
  font-size: 19px;
  background-color: #7ede89;
  border-radius: 20px;
  padding: 15px 40px 15px 40px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  @media ${media.mobile} {
    font-size: 19px;
    padding: 7px 16px 7px 16px;
    max-width: 90%;
  }
`;

const CloseDialogText = styled.span`
  color: #121116;
  text-decoration: underline;
`;
