import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import { colors, styled } from 'styles';
import CardsContainer from './CardsContainer';
import SolvedListsIcon from '../images/solved-lists.png';
import { ToggleableComponent } from 'modules/shared/components/generic';
import { useUserContext } from 'providers/UserProvider';
import { useHistory } from 'react-router';
import UploadMaterialButton from './UploadMaterialButton';
import { useShouldShowUploadFileDialog } from 'modules/main/hooks/useShouldShowUploadFileDialog';

export default function ListsCategoriesCards({
  listCategories,
  guideId,
  theme,
  acronymDisciplineUniversity = null,
}) {
  const { user } = useUserContext();
  const [listCategoriesController, setListCategoriesController] = useState(
    listCategories.map(() => false)
  );
  const history = useHistory();
  const { isElegibleToUploadFile } = useShouldShowUploadFileDialog();

  const toggleListCategoryController = useCallback(
    index => {
      const newListCategoryController = [...listCategoriesController];
      newListCategoryController[index] = !newListCategoryController[index];
      setListCategoriesController(newListCategoryController);
    },
    [listCategoriesController, setListCategoriesController]
  );

  const calculateToggleableHeight = useCallback(listCategory => {
    const heigth = listCategory.lists.length * 45 + 40;
    return `${heigth}px`;
  }, []);

  const getListLink = useCallback(
    list => {
      const firstExercise = list.listExercises[0];
      const utmParams = history.location.search;
      return `/praticar/${guideId}/lista/${list.listCategoryId}/${list.id}/exercicio-lista/${firstExercise.id}${utmParams}`;
    },
    [guideId, history]
  );

  return (
    <CardsContainer
      color={colors.listCategory}
      title={`Exercícios Resolvidos ${acronymDisciplineUniversity ||
        user?.university.acronym}`}
      icon={SolvedListsIcon}
    >
      <Content>
        {isElegibleToUploadFile(theme?.name) && (
          <UploadMaterialButton theme={theme} />
        )}
        {listCategories.map((listCategory, index) => (
          <ListCategoryContainer
            key={listCategory.id}
            onClick={() => toggleListCategoryController(index)}
          >
            <ListCategory>
              <FileIcon icon={faFileAlt} />
              <ListCategoryName>{listCategory.name}</ListCategoryName>
              <AngleIcon
                icon={listCategoriesController[index] ? faAngleUp : faAngleDown}
              />
            </ListCategory>

            <ToggleableComponent
              open={listCategoriesController[index]}
              height={calculateToggleableHeight(listCategory)}
              animationTime="0.3s"
            >
              <Lists>
                {listCategory.lists.map(list => (
                  <ListName key={list.id} to={getListLink(list)}>
                    {list.name}
                  </ListName>
                ))}
              </Lists>
            </ToggleableComponent>
          </ListCategoryContainer>
        ))}
      </Content>
    </CardsContainer>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
`;

const ListCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0;
  color: ${colors.darkGray};
  text-transform: uppercase;
  cursor: pointer;
`;

const ListCategory = styled.div`
  position: relative;
  display: flex;
  padding: 0 10px;
`;

const FileIcon = styled(FontAwesomeIcon)`
  margin-right: 7px;
  font-size: 1.2em;
`;

const ListCategoryName = styled.div``;

const AngleIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.3em;
  opacity: 0.7;
`;

const Lists = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding: 15px 10px;
  border-radius: 10px;
  margin-top: 7px;
`;

const ListName = styled(Link)`
  transition: background-color 0.4s ease-in-out;
  display: flex;
  align-items: center;
  height: 35px;
  margin: 4px 0;
  padding: 0 20px;
  text-transform: uppercase;
  font-size: 0.9em;
  cursor: pointer;
  color: ${colors.darkGray};

  &:hover {
    background-color: rgba(158, 158, 158, 0.2);
  }
`;
