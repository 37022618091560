import { useForumContext } from 'providers/ForumProvider';
import { useCallback } from 'react';
import { keyWordsList } from './keyWordsToDetect';

export default function useForumRedirectToErrorReport() {
  const { forumContent } = useForumContext();
  const shouldConfirmRedirect = useCallback(
    (text, title = '') => {
      if (!forumContent?.chapter || !forumContent?.topic) return false;

      text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      title.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      const wordDetectedOnText = keyWordsList.find(p => text?.includes(p));
      const wordDetectedOnTitle = keyWordsList.find(p => title?.includes(p));

      return wordDetectedOnText || wordDetectedOnTitle;
    },
    [forumContent]
  );

  return { shouldConfirmRedirect };
}
