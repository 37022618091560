import React, { useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { emitter } from '@marvelapp/react-ab-test';

import { styled, colors } from 'styles';
import { useTracker, useTestAB } from 'modules/shared/hooks';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';

export default function CreditCardTrialWarningBar() {
  const history = useHistory();
  const { trackEvent } = useTracker();
  const { bookCreditCardTrialFirstCharge, trialInfo } = useBookTrialCampaign();
  const { getVariantFromTestAB } = useTestAB();

  emitter.defineVariants('creditCardTrialBar', ['on', 'off']);

  const abTestVariant = useMemo(() => {
    return getVariantFromTestAB('creditCardTrialBar');
  }, [getVariantFromTestAB]);

  useEffect(() => {
    trackEvent('Barra Notificacao Trial Cartão: Visualizou', {
      Variante: abTestVariant,
    });
  }, [trackEvent, abTestVariant]);

  const onBarClick = useCallback(() => {
    trackEvent('Barra Notificacao Trial Cartão: Clicou');
    history.push('/minha-conta');
  }, [trackEvent, history]);

  const daysUntilEnd = useMemo(() => {
    if (trialInfo?.daysUntilEnd == 1) return '1 dia';

    return `${trialInfo?.daysUntilEnd} dias`;
  }, [trialInfo]);

  if (abTestVariant === 'off' || !trialInfo) return null;
  return (
    <Container onClick={onBarClick}>
      Você tem {daysUntilEnd} de acesso grátis. Em{' '}
      {bookCreditCardTrialFirstCharge()} você será cobrado pelo plano{' '}
      {trialInfo?.plan?.toLowerCase()}
    </Container>
  );
}

const Container = styled.div`
  background: ${colors.mainOrange};
  width: 100%;
  height: 1.6rem;
  padding: 0 2px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
