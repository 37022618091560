import _ from 'lodash';
import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { styled, colors } from 'styles';
import { useWorkspaceFlags } from 'modules/workspace/hooks/shared';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';
import ExerciseFlagFeedback from './ExerciseFlagFeedback';
import dexterBigSmileImg from 'images/dexter/feedbacks/dexter-big-smile.png';
import dexterLoveImg from 'images/dexter/feedbacks/dexter-love.png';
import dexterScaredImg from 'images/dexter/feedbacks/dexter-scared.png';
import dexterNerdImg from 'images/dexter/feedbacks/dexter-nerd.png';
import dexterDismayImg from 'images/dexter/feedbacks/dexter-dismay.png';
import dexterSmileImg from 'images/dexter/feedbacks/dexter-smile.png';

export default function ExerciseFlag({ nextContentLink }) {
  const [chooseOption, setChooseOption] = useState(false);
  const { exerciseId, listExerciseId, guideId } = useParams();
  const {
    getExerciseFlag,
    removeExerciseFlag,
    saveExerciseFlag,
  } = useWorkspaceFlags();
  const location = useLocation();
  const history = useHistory();
  const exerciseFlagFeedbackComponent = useRef();

  useEffect(() => {
    setChooseOption(false);
  }, [location.pathname]);

  const exerciseFlag = useMemo(() => {
    if (listExerciseId)
      return getExerciseFlag(listExerciseId, 'exercicio-lista', guideId);

    return getExerciseFlag(exerciseId, 'exercicio', guideId);
  }, [exerciseId, listExerciseId, getExerciseFlag, guideId]);

  const isChecked = useMemo(() => {
    return exerciseFlag?.name === 'check';
  }, [exerciseFlag]);

  const isSeeLater = useMemo(() => {
    return exerciseFlag?.name === 'see_later';
  }, [exerciseFlag]);

  const onOptionClick = useCallback(
    optionName => {
      setChooseOption(true);

      if (exerciseFlag) {
        removeExerciseFlag(
          exerciseFlag.contentId,
          exerciseFlag.contentType,
          exerciseFlag.guideId,
          exerciseFlag.name
        );
      }

      const optionAlreadyChosen = exerciseFlag?.name === optionName;
      if (optionAlreadyChosen) return;

      const contentId = exerciseId || listExerciseId;
      const contentType = exerciseId ? 'exercicio' : 'exercicio-lista';
      saveExerciseFlag(contentId, contentType, guideId, optionName);

      setTimeout(
        () =>
          exerciseFlagFeedbackComponent.current?.scrollIntoView({
            behavior: 'smooth',
          }),
        700
      );

      setTimeout(() => history.push(nextContentLink), 2000);
    },
    [
      exerciseFlag,
      exerciseId,
      listExerciseId,
      guideId,
      setChooseOption,
      saveExerciseFlag,
      removeExerciseFlag,
      history,
      nextContentLink,
    ]
  );

  const getRandomFeedback = useCallback(() => {
    if (!exerciseFlag) return null;

    if (exerciseFlag.name === 'check') return _.sample(CHECK_FEEDBACKS);
    return _.sample(SEE_LATER_FEEDBACKS);
  }, [exerciseFlag]);

  const toggleableComponentHeigth = useMemo(() => {
    if (!exerciseFlagFeedbackComponent.current) return;

    return exerciseFlagFeedbackComponent.current?.clientHeight + 100;
  }, []);

  return (
    <Container data-cy="exercise-flag">
      <ToggleableComponent
        open={chooseOption}
        height={
          toggleableComponentHeigth ? `${toggleableComponentHeigth}px` : null
        }
        animationTime="0.5s"
      >
        <ExerciseFlagFeedback
          feedback={getRandomFeedback()}
          useRef={exerciseFlagFeedbackComponent}
        />
      </ToggleableComponent>

      <OptionsContainer>
        <OptionsText>Marca aí, conseguiu?</OptionsText>
        <Option>
          <CheckContainer
            className={`check ${isChecked && 'selected'}`}
            onClick={() => onOptionClick('check')}
          >
            <Icon icon={faCheck} />
          </CheckContainer>
          MATEI
        </Option>

        <Option>
          <CheckContainer
            className={`see_later ${isSeeLater && 'selected'}`}
            onClick={() => onOptionClick('see_later')}
          >
            <Icon icon={faExclamation} />
          </CheckContainer>
          VER DEPOIS
        </Option>
      </OptionsContainer>
    </Container>
  );
}

const CHECK_FEEDBACKS = [
  {
    title: 'BOOOUUAA!!',
    subtitle: 'BORA MATAR A LISTA TODA!',
    image: dexterBigSmileImg,
    type: 'check',
  },
  {
    title: 'INCRÍVEL!!',
    subtitle: 'SEGUE NESSE RITMO E<br>BORA GARANTIR O NOTÃO!',
    image: dexterLoveImg,
    type: 'check',
  },
  {
    title: 'TO BOBO!!',
    subtitle: 'TA TIRANDO ONDA, VAMO QUE VAMO!',
    image: dexterScaredImg,
    type: 'check',
  },
  {
    title: 'NERD!!',
    subtitle: 'SÉRIO',
    image: dexterNerdImg,
    type: 'check',
  },
  {
    title: 'ASTRONAUTA!!',
    subtitle: 'TU ESTUDOU NA NASA?',
    image: dexterDismayImg,
    type: 'check',
  },
];
const SEE_LATER_FEEDBACKS = [
  {
    title: 'Show, não desanima!',
    subtitle:
      'Continua tentando fazer os outros exercícios<br>e mais tarde volta nesse',
    image: dexterSmileImg,
    type: 'see_later',
  },
  {
    title: 'Tá tranquilo, tá favorável.',
    subtitle:
      'Às vezes dá um branquinho.<br>Mas não se preocupa, tenta a próxima!',
    image: dexterSmileImg,
    type: 'see_later',
  },
  {
    title: 'Quem acredita, sempre alcança!',
    subtitle: 'Não deixa uma questãozinha<br>te desanimar. Bora em frente!',
    image: dexterSmileImg,
    type: 'see_later',
  },
  {
    title: 'Vamos nessa!',
    subtitle: 'É errando que se aprende!',
    image: dexterSmileImg,
    type: 'see_later',
  },
];

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 30px;
  color: ${colors.darkGrey};
`;

const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const OptionsText = styled.div`
  margin-right: 25px;
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9em;
  margin: 0 10px;
`;

const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 2px solid ${colors.darkGray};
  border-radius: 30px;
  margin-bottom: 4px;
  cursor: pointer;

  &:hover,
  &.selected {
    color: #fff;

    &.check {
      background-color: ${colors.check};
    }

    &.see_later {
      background-color: ${colors.darkGray};
    }
  }
`;

const Icon = styled(FontAwesomeIcon)``;
