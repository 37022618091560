import { highlightObject, prepareQuery } from './utils';

export default class ElasticSearchBookExercises {
  static getSearchQuery(term, themeId) {
    const weight = term.length <= 35 ? 1 : 1.4;
    const keys = [
      { name: 'book.edition.chapter.section.exercise.lightBody', weight },
    ];

    const source = [
      'id',
      'name',
      'color',
      'book.id',
      'book.name',
      'book.edition.id',
      'book.edition.name',
      'book.edition.chapter.id',
      'book.edition.chapter.name',
      'book.edition.chapter.section.id',
      'book.edition.chapter.section.name',
      'book.edition.chapter.section.exercise.id',
      'book.edition.chapter.section.exercise.name',
      'book.edition.chapter.section.exercise.lightSolution',
      'book.edition.chapter.section.exercise.lightSolutionMaths',
      'book.edition.chapter.section.exercise.lightBodyMaths',
    ];
    const query = prepareQuery(term, keys, themeId);

    const json = {
      query,
      _source: source,
      size: 10,
      ...highlightObject(keys),
    };

    return json;
  }

  static removeDuplicates(results) {
    function ls(result) {
      return result.book.edition.chapter.section.exercise.lightSolution;
    }
    function eid(result) {
      return result.book.edition.chapter.section.exercise.id;
    }

    const toRemove = [];

    for (let i = 0; i < results.length; i++) {
      for (let j = i + 1; j < results.length; j++) {
        if (ls(results[i]).join() === ls(results[j]).join()) {
          toRemove.push(eid(results[i]) > eid(results[j]) ? j : i);
        }
      }
    }

    for (let i = toRemove.length - 1; i >= 0; i--) {
      results.splice(toRemove[i], 1);
    }

    return results;
  }

  static adjustResults(data) {
    const hits = data.hits.hits
      .filter(it => it._index === 'ra_book_exercises')
      .filter(it => it.highlight);

    data = hits.map(it => {
      it._source.score = it._score;
      it._source.max_score = data.hits.max_score;

      const highlight =
        it.highlight['book.edition.chapter.section.exercise.lightBody'];

      let lb = highlight[0];

      const mj =
        it._source.book.edition.chapter.section.exercise.lightBodyMaths;

      for (let i = 0; i < mj.length; i++) {
        lb = lb.replace(`_m${i + 1}_`, mj[i]);
        lb = lb.replace(`_m${i + 1}`, mj[i]);
      }

      it._source.book.edition.chapter.section.exercise.lightBody = lb;

      return it._source;
    });

    data = this.removeDuplicates(data);

    return data;
  }
}
