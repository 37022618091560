import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const ForumContext = createContext(null);

const ForumProvider = props => {
  const [shouldShowForum, setShouldShowForum] = useState(false);
  const [forumContent, setForumContent] = useState(null);
  const [canShowForumCTA, setCanShowForumCTA] = useState(false);
  const [isForumInView, setIsForumInView] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    if (isForumInView) setCanShowForumCTA(false);
  }, [canShowForumCTA, isForumInView]);

  const id = useMemo(() => selectedQuestion?.id || 0, [selectedQuestion]);

  return (
    <ForumContext.Provider
      value={{
        forumContent,
        setForumContent,

        canShowForumCTA,
        setCanShowForumCTA,

        isForumInView,
        setIsForumInView,

        shouldShowForum,
        setShouldShowForum,

        selectedQuestion,
        setSelectedQuestion,
        id,
      }}
      {...props}
    />
  );
};

export const useForumContext = () => useContext(ForumContext);
export default ForumProvider;
