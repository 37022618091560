import React, { useState, useEffect, useCallback } from 'react';

import { styled, colors, media } from 'styles';
import { useTracker, useCurrency, useDate, useUTM } from 'modules/shared/hooks';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useChangePlanCampaign } from 'modules/campaigns/change-plan/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import {
  ChangePlanExitDialog,
  ChangePlanSuccessDialog,
  ChangePlanOptionsDialog,
} from 'modules/campaigns/change-plan/components';

export default function ChangePlanConfirmationDialog({
  subscription,
  newPlanPricing,
}) {
  const [loading, setLoading] = useState(false);
  const {
    getCardUsedOnChangePlan,
    getInstallmentPriceFromPlanPricing,
  } = useChangePlanCampaign();
  const { setDialog } = useDialogContext();
  const { formatCurrency } = useCurrency();
  const { getHumanReadableDateFromTimestamp } = useDate();
  const changePlan = useRAPostRequest('/campaigns/change_plan');
  const { getUTMsByLocation } = useUTM();
  const { trackEvent } = useTracker();

  const getAmplitudeEventProperties = useCallback(() => {
    return {
      'Preco Plano Atual': formatCurrency(subscription.order.planPricing.price),
      Onde: media.isDesktop() ? 'Desktop' : 'Mobile',
      'Preço Plano Escolhido': formatCurrency(newPlanPricing.price),
      ...getUTMsByLocation(window.location),
    };
  }, [formatCurrency, subscription, newPlanPricing, getUTMsByLocation]);

  useEffect(() => {
    trackEvent(
      'Troca de Plano: Dialog Confirmação Visualizou',
      getAmplitudeEventProperties()
    );
  }, [trackEvent, getAmplitudeEventProperties]);

  const handleExitPress = useCallback(() => {
    trackEvent('Troca de Plano: Saiu do Fluxo', {
      ...getAmplitudeEventProperties(),
      Origem: 'Dialog Confirmacao',
    });
    setDialog(<ChangePlanExitDialog subscription={subscription} />);
  }, [trackEvent, getAmplitudeEventProperties, setDialog, subscription]);

  const handleBackPress = useCallback(() => {
    setDialog(<ChangePlanOptionsDialog subscription={subscription} />);
  }, [setDialog, subscription]);

  const handleSubmitPress = useCallback(async () => {
    if (loading) return;

    trackEvent(
      'Troca de Plano: Dialog Confirmação Clicou Avançar',
      getAmplitudeEventProperties()
    );
    setLoading(true);
    try {
      await changePlan({
        subscriptionId: subscription.id,
        newPlanPricingId: newPlanPricing.id,
      });

      setLoading(false);
      setDialog(
        <ChangePlanSuccessDialog
          subscription={subscription}
          newPlanPricing={newPlanPricing}
        />
      );
    } catch (e) {
      alert(
        'Erro ao tentar efetuar a troca de planos. Por favor, tente novamente. Se o problema persistir, entre em contato com o atendimento.'
      );
      setLoading(false);
    }
  }, [
    changePlan,
    getAmplitudeEventProperties,
    loading,
    newPlanPricing,
    setDialog,
    subscription,
    trackEvent,
  ]);

  const card = getCardUsedOnChangePlan();
  return (
    <Container>
      <Content>
        <Title>Confirme as suas informações</Title>
        <InfosContainer>
          <Info>
            <b>Seu plano atual:</b> {subscription.plan.description} (R$
            {formatCurrency(subscription.order.planPricing.price)}/mês)
          </Info>
          <Info>
            <b>Válido até:</b>{' '}
            {getHumanReadableDateFromTimestamp(subscription.expiresIn)}
          </Info>

          <InfoHighlight>
            Seu novo plano: {newPlanPricing.plan.description} (
            {newPlanPricing.plan.months} meses - R$
            {formatCurrency(
              getInstallmentPriceFromPlanPricing(
                newPlanPricing,
                newPlanPricing.plan
              )
            )}
            /mês)
          </InfoHighlight>
          <Info>
            <b>Forma de pagamento:</b> {newPlanPricing.plan.months} parcelas de
            R$
            {formatCurrency(
              getInstallmentPriceFromPlanPricing(
                newPlanPricing,
                newPlanPricing.plan
              )
            )}{' '}
            no cartão {card.brand} de final X-{card.lastFourDigits}
          </Info>

          <Info>
            <b>Cobrança da primeira parcela:</b>{' '}
            {getHumanReadableDateFromTimestamp(subscription.expiresIn)}
          </Info>
        </InfosContainer>
        <ButtonsContainer>
          <Button onClick={handleExitPress}>Sair</Button>
          <Button onClick={handleBackPress}>Voltar</Button>
          <Button className="full" onClick={handleSubmitPress}>
            {loading ? 'Carregando...' : 'Confirmar'}
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 60px;
  border-top: 10px solid ${colors.purple};
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const Title = styled.h1`
  margin-top: 25px;
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const InfosContainer = styled.div`
  width: 100%;
  padding: 25px 20px 45px 20px;
  display: flex;
  flex-direction: column;
`;

const Info = styled.span`
  margin: 3px 0;
  font-size: 0.95em;
  line-height: 1.3;

  b {
    font-weight: bold;
  }
`;

const InfoHighlight = styled.span`
  margin: 10px 0 3px 0;
  background-color: ${colors.mainOrange};
  color: #fff;
  font-weight: bold;
  font-size: 1.15em;
  padding: 3px 10px;
  line-height: 1.4;

  @media ${media.mobile} {
    margin-top: 20px;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 50px;
  margin-top: 25px;
  color: ${colors.mainOrange};
  border: 2px solid ${colors.mainOrange};
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  &.full {
    color: #fff;
    background-color: ${colors.mainOrange};
  }

  @media ${media.mobile} {
    font-size: 0.7em;
  }
`;
