import _ from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
  useWorkspaceGuide,
  useWorkspaceExercise,
} from 'modules/workspace/hooks/shared';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';

export default function useWorkspaceTopNavigation() {
  const { subjectId, guideId, topicId } = useParams();
  const {
    chapters,
    currentTopic,
    currentUniversityExercises,
    currentExtraExercises,
    filterUniversityExercises,
    filterExtraExercises,
  } = useWorkspaceContentContext();
  const { isGuideWorkspace } = useWorkspaceGuide();
  const { getListExercisesFromChapter } = useWorkspaceExercise();

  const getFixationExerciseLink = useCallback(() => {
    const firstExercise = currentTopic.exercises[0];
    if (isGuideWorkspace())
      return `/aprender/${guideId}/topico/${subjectId}/${topicId}/exercicio/${firstExercise.id}`;

    return `/aprender/topico/${subjectId}/${topicId}/exercicio/${firstExercise.id}`;
  }, [isGuideWorkspace, currentTopic, subjectId, topicId, guideId]);

  const getUniversityExerciseLink = useCallback(() => {
    const firstExercise = currentUniversityExercises[0];
    if (isGuideWorkspace())
      return `/aprender/${guideId}/topico/${subjectId}/${topicId}/exercicio-lista/${firstExercise.id}`;

    return `/aprender/topico/${subjectId}/${topicId}/exercicio-lista/${firstExercise.id}`;
  }, [
    currentUniversityExercises,
    isGuideWorkspace,
    subjectId,
    topicId,
    guideId,
  ]);

  const getTheoryLink = useCallback(() => {
    if (isGuideWorkspace())
      return `/aprender/${guideId}/topico/${subjectId}/${topicId}/teoria/${currentTopic?.theory.id}`;

    return `/aprender/topico/${subjectId}/${topicId}/teoria/${currentTopic?.theory.id}`;
  }, [isGuideWorkspace, currentTopic, subjectId, topicId, guideId]);

  const getExtraExerciseLink = useCallback(() => {
    if (isGuideWorkspace())
      return `/aprender/${guideId}/topico/${subjectId}/${topicId}/exercicio-lista/${currentExtraExercises[0]?.id}`;

    return `/aprender/topico/${subjectId}/${topicId}/exercicio-lista/${currentExtraExercises[0]?.id}`;
  }, [isGuideWorkspace, currentExtraExercises, subjectId, topicId, guideId]);

  const hasAnyUniversityExerciseOnChapters = useCallback(() => {
    const listExercises = _.flatten(
      chapters.map(chapter => getListExercisesFromChapter(chapter))
    );
    const universityExercises = filterUniversityExercises(listExercises);
    return universityExercises.length > 0;
  }, [chapters, getListExercisesFromChapter, filterUniversityExercises]);

  const showExtraExerciseTopNavigationFromChapter = useCallback(
    chapter => {
      if (hasAnyUniversityExerciseOnChapters()) return false;

      const listExercises = getListExercisesFromChapter(chapter);
      const universityExercises = filterUniversityExercises(listExercises);
      if (universityExercises.length > 0) return false;

      const extraExercises = filterExtraExercises(
        listExercises,
        universityExercises
      );
      return extraExercises.length > 0;
    },
    [
      getListExercisesFromChapter,
      hasAnyUniversityExerciseOnChapters,
      filterUniversityExercises,
      filterExtraExercises,
    ]
  );

  const showExtraExerciseTopNavigation = useCallback(() => {
    if (hasAnyUniversityExerciseOnChapters()) return false;

    if (currentUniversityExercises.length > 0) return false;
    return currentExtraExercises.length > 0;
  }, [
    hasAnyUniversityExerciseOnChapters,
    currentUniversityExercises,
    currentExtraExercises,
  ]);

  return {
    getFixationExerciseLink,
    getUniversityExerciseLink,
    getTheoryLink,
    getExtraExerciseLink,
    showExtraExerciseTopNavigation,
    showExtraExerciseTopNavigationFromChapter,
  };
}
