import { useTracker, useUTM } from 'modules/shared/hooks';
import { useCallback, useMemo } from 'react';
import { media } from 'styles';

export function useListWithStatementTracker() {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const defaultProperties = useMemo(() => {
    return {
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Versão: '1',
      ...getUTMsByLocation(window.location),
    };
  }, [getUTMsByLocation]);

  const trackClickList = useCallback(
    ({ professor, listId, origin }) => {
      trackEvent('Lista de Enunciado: Clicou Lista', {
        ...defaultProperties,
        'ID Lista': listId,
        Professor: professor,
        Origem: origin,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackViewSendListScren = useCallback(
    ({ themeName, professor, exam }) => {
      trackEvent('Lista de Enunciado: Visualizou Tela Enviar Lista', {
        ...defaultProperties,
        Matéria: themeName,
        Professor: professor,
        Prova: exam,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackUploadFile = useCallback(
    ({ fileName, fileType, professor, exam, themeName }) => {
      trackEvent('Lista de Enunciado: Fez upload de arquivo', {
        ...defaultProperties,
        'Nome Arquivo': fileName,
        'Tipo de Arquivo': fileType,
        Professor: professor.name,
        Prova: exam,
        Matéria: themeName,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackInputError = useCallback(
    error => {
      trackEvent('Lista de Enunciado: Erro Input da lista', {
        ...defaultProperties,
        Erro: error,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackClickSendListWithStatement = useCallback(
    ({ fileName, fileType, professor, exam, themeName }) => {
      trackEvent('Lista de Enunciado: Clicou Enviar Lista', {
        ...defaultProperties,
        'Nome Arquivo': fileName,
        'Tipo de Arquivo': fileType,
        Professor: professor.name,
        Prova: exam,
        Matéria: themeName,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackSendError = useCallback(
    error => {
      trackEvent('Lista de Enunciado: Erro ao Enviar a lista', {
        ...defaultProperties,
        Erro: error,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackViewThankYouScren = useCallback(
    ({ listLink, theme, professor, exam, foundPercentage }) => {
      trackEvent('Lista de Enunciado: Visualizou Tela Obrigado', {
        ...defaultProperties,
        'Gerou Link': listLink ? 'Sim' : 'Não',
        Link: listLink,
        Materia: theme,
        Professor: professor,
        Prova: exam,
        Achadas: foundPercentage,
      });
    },
    [trackEvent, defaultProperties]
  );

  return {
    trackClickList,
    trackViewSendListScren,
    trackUploadFile,
    trackInputError,
    trackClickSendListWithStatement,
    trackSendError,
    trackViewThankYouScren,
  };
}
