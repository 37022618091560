import React from 'react';
import { Route } from 'react-router-dom';

import {
  AiExerciseRedirect,
  ListWithStatementPage,
  ListWithStatementQuestionPage,
  ListWithStatementQuestionSolvingByAiPage,
  UploadStatementListPage,
} from './pages';

export default [
  <Route
    exact
    path="/lista-de-enunciado/:listId"
    component={ListWithStatementPage}
    key="lista-enunciado"
  />,
  <Route
    exact
    path="/lista-de-enunciado/:listId/questao/:questionId"
    component={ListWithStatementQuestionPage}
    key="lista-enunciado-questao"
  />,
  <Route
    exact
    path="/lista-de-enunciado/:listId/questao/:questionId/resolver-com-ia"
    component={ListWithStatementQuestionSolvingByAiPage}
    key="lista-enunciado-questao-resolucao-ia"
  />,
  <Route
    exact
    path="/resolver-lista"
    component={UploadStatementListPage}
    key="resolver-lista-de-enunciado"
  />,
  <Route
    exact
    path="/exercicios-ia/:aiExerciseId"
    component={AiExerciseRedirect}
    key="exercicios-ia"
  />,
];
