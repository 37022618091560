import { useDialogContext } from 'providers/DialogProvider';
import React from 'react';
import styled from 'styled-components';

const AdvanceStepDialog = ({ onUnLock }) => {
  const { unsetDialog } = useDialogContext();
  return (
    <Container>
      <Content>
        <Title>
          Opa! Tá com tempo e quer adiantar o conteúdo de outro dia?
        </Title>
        <ButtonContainer>
          <Button onClick={() => onUnLock()}>SIM!</Button>
          <Button onClick={unsetDialog}>Não, vou esperar</Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 700px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #00000040;
  padding: 40px 20px 75px 20px;
`;

const Title = styled.p`
  font-family: Lato;
  font-size: 28px;
  font-weight: 400;
  line-height: 33.6px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;
const Button = styled.div`
  width: 320px;
  height: 50px;
  max-width: 80%;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px #00000040;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  border: 1px solid #47d47c;
  cursor: pointer;
`;

export default AdvanceStepDialog;
