import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import React, { useEffect } from 'react';
import RedirectTab from './RedirectTab';

export default function ExerciseTab() {
  const { bookExercise } = useBookEditionContext();

  useEffect(() => {
    if (!bookExercise) return;

    window.open(
      `${process.env.REACT_APP_REACT_URL}/aprender/topico/${bookExercise.topic.subject.id}/${bookExercise.topic.id}/exercicio/${bookExercise.topic.exercises[0].id}`,
      '_blank'
    );
  }, [bookExercise]);

  if (!bookExercise) return null;

  return (
    <RedirectTab
      to={`/aprender/topico/${bookExercise.topic.subject.id}/${bookExercise.topic.id}/exercicio/${bookExercise.topic.exercises[0].id}`}
      textCTA="Abrir mais questões deste tópico"
    />
  );
}
