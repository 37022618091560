import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export default function MultilineText({ text, id }) {
  const [textToShow, setTextToShow] = useState([]);

  useEffect(() => {
    const urlRegexp = /(https?:\/\/[^\s]+)/g;
    const links = text.match(urlRegexp) || [];

    const textArr = [];

    text.split('\n').forEach(t => {
      t.split(urlRegexp).forEach(it => {
        textArr.push({ text: it, isLink: links.includes(it) });
      });
    });

    setTextToShow(textArr);
  }, [text]);

  return (
    <div id={id}>
      <Text>
        {textToShow.map(text => (
          <>
            {text.isLink ? (
              <a
                href={text.text}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#0000EE',
                }}
              >
                {text.text}
              </a>
            ) : text.text.trim() !== '' ? (
              text.text
            ) : (
              <br />
            )}
          </>
        ))}
      </Text>
    </div>
  );
}

const Text = styled.p`
  color: #505050;
`;
