import MvpGuideListsBanner from 'modules/campaigns/mvp-books/banners/MvpGuideListsBanner';
import React from 'react';
import { media, styled } from 'styles';

export default function GuideBanner({ utmContent }) {
  return (
    <Container>
      <MvpGuideListsBanner
        origin="Pagina Disciplina"
        utmContent={utmContent}
      ></MvpGuideListsBanner>
    </Container>
  );
}

const Container = styled.div`
  @media ${media.mobile} {
    background-color: #fff;
    height: 92px;
    display: flex;
    align-items: bottom;
    margin-top: 10px;
  }
`;
