import { styled } from 'styles';

export const QuestionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  padding: 30px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px 13px 0 0;
  background-color: #fff;
`;
