import { useCallback } from 'react';

import { useRAPostRequest } from 'modules/shared/hooks/requests';

export default function useChangeCreditCard() {
  const updateCardReq = useRAPostRequest('/subscriptions/update-card');

  const isEligibleToChangeCreditCard = useCallback(subscription => {
    const { order } = subscription;
    if (!order) return false;

    if (
      order.paymentMethod !== 'credit_card' ||
      order.gateway !== 'NEW_MUNDIPAGG'
    )
      return false;

    return order.state === 'opened' || order.state === 'paid';
  }, []);

  const submitUpdateCreditCard = useCallback(
    (subscription, card) => {
      return updateCardReq({
        subscriptionId: subscription.id,
        card,
      });
    },
    [updateCardReq]
  );

  return { isEligibleToChangeCreditCard, submitUpdateCreditCard };
}
