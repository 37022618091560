import React, { useState, useCallback } from 'react';
import { useLectureContext } from '../../providers/LectureProvider';
import { styled, media } from 'styles';

const speeds = [2, 1.5, 1.25, 1];

const VideoSpeedControl = () => {
  const [showSpeedOptions, setShowSpeedOptions] = useState(false);
  const { videoSpeed, setVideoSpeed } = useLectureContext();

  const onPlaybackMouseHover = useCallback(() => {
    setShowSpeedOptions(true);
  }, []);

  const onPlaybackMouseExit = useCallback(() => {
    setShowSpeedOptions(false);
  }, []);

  return (
    <VideoSpeedContainer
      onMouseOver={onPlaybackMouseHover}
      onMouseOut={onPlaybackMouseExit}
    >
      <SpeedOptions showSpeedOptions={showSpeedOptions}>
        {speeds.map((speed, i) => (
          <SmallRounded
            className={videoSpeed === speed ? 'selected' : ''}
            key={i}
            onClick={() => setVideoSpeed(speed)}
          >
            {`${speed}x`}
          </SmallRounded>
        ))}
      </SpeedOptions>
      <Rounded>{`${videoSpeed}x`}</Rounded>
    </VideoSpeedContainer>
  );
};

const VideoSpeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  user-select: none;
  @media ${media.mobile} {
    margin-bottom: 10px;
  }
`;

const SpeedOptions = styled.div`
  display: ${({ showSpeedOptions }) => (showSpeedOptions ? 'block' : 'none')};
`;

const SmallRounded = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #ddd;
  color: rgb(102, 102, 102);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0;
  font-size: 0.7em;
  &.selected {
    background-color: #f9ac3e;
    color: white;
  }
  &:hover {
    background-color: #f9ac3e;
    color: white;
  }
`;

const Rounded = styled.div`
  width: 45px;
  border-radius: 50%;
  color: white;
  height: 45px;
  cursor: pointer;
  background-color: #f9ac3e;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 7px;
  @media ${media.mobile} {
    width: 35px;
    height: 35px;
  }
`;

export default VideoSpeedControl;
