import { useCallback } from 'react';

export default function useWebView() {
  const isWebView = useCallback(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIOSWebView =
      /iPhone|iPod|iPad/i.test(userAgent) &&
      !window.MSStream &&
      (window.navigator.standalone || /WebView/.test(userAgent));
    const isAndroidWebView = /wv|Android.*Version\/[0-9]+/.test(userAgent);
    return isIOSWebView || isAndroidWebView;
  }, []);

  const isInstagramWebView = useCallback(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isInstagram = /Instagram/.test(userAgent);
    return isInstagram;
  }, []);

  const isIOSDevice = useCallback(() => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }, []);

  const openInBrowser = useCallback(
    url => {
      if (isWebView()) {
        if (/Android/i.test(navigator.userAgent)) {
          // Intent genérico para Android
          const intentUrl = `intent://${url.replace(
            /^https?:\/\//,
            ''
          )}#Intent;scheme=https;action=android.intent.action.VIEW;end`;
          window.location = intentUrl;
        }
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          // Usa o esquema "x-safari" para abrir no Safari
          const safariUrl = `x-safari-${url.replace(/^https?:\/\//, '')}`;
          window.location.href = safariUrl;
        } else {
          // Outros dispositivos (Android ou desktop)
          window.open(url, '_blank');
        }
      }
    },
    [isWebView]
  );

  return { isWebView, isInstagramWebView, openInBrowser, isIOSDevice };
}
