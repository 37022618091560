import React from 'react';
import styled from 'styled-components';

const SubjectCard = ({ first, onSelect, themeName, subjectName }) => {
  return (
    <Container
      onClick={() => onSelect(subjectName, themeName)}
      className={`${first ? 'first' : ''} ${
        themeName === 'Física' ? 'fisica' : 'calculo'
      }`}
    >
      <ThemeName>{`${themeName}`}</ThemeName>
      <SubjectName
        className={`${themeName === 'Física' ? 'fisica' : 'calculo'}`}
      >{`${subjectName}`}</SubjectName>
    </Container>
  );
};

const Container = styled.div`
  &:hover {
    & > * {
      text-decoration: underline;
    }
    cursor: pointer;
  }
  user-select: none;
  box-shadow: 0px 0px 10px 0px #00000040;
  padding: 10px 10px;
  margin-left: 35px;
  margin-bottom: 25px;
  width: 185px;
  height: 80px;
  border-radius: 10px;
  background-color: #fff8ec;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &.first {
    margin-left: 0;
  }
  &.fisica {
    background-color: #f8fffb;
  }
`;

const ThemeName = styled.div`
  font-size: 14px;
`;

const SubjectName = styled.div`
  font-size: 18px;
  color: #f7ac3b;
  &.fisica {
    color: #47d47c;
  }
`;

export default SubjectCard;
