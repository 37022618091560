class RestApiService {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;
  }

  get(path, requestOptions) {
    return this.request({ method: 'GET', path, requestOptions });
  }

  post(path, requestOptions) {
    return this.request({ method: 'POST', path, requestOptions });
  }

  put(path, requestOptions) {
    return this.request({ method: 'PUT', path, requestOptions });
  }

  delete(path, requestOptions) {
    return this.request({ method: 'DELETE', path, requestOptions });
  }

  async request({ path, method, requestOptions }) {
    const { body, headers } = requestOptions;

    const requestInit = {
      method,
      credential: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    };
    if (body) requestInit['body'] = JSON.stringify(body);

    return fetch(this.apiUrl + path, requestInit);
  }
}

export const respondeAiRestApi = new RestApiService(
  process.env.REACT_APP_RESPONDEAI_URL
);

export const raEventsRestApi = new RestApiService(
  process.env.REACT_APP_RA_EVENTS_URL
);
