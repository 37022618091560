import React from 'react';
import { styled } from 'styles';
import Breadcrumb from './Breadcrumb';
import { ResultTitle, TitleBlock } from './styles';
import { playButton } from 'images/main';

const ResultItem = ({
  resultUrl,
  breadcrumbsItems,
  title,
  content,
  clickedInResult,
  hasVideos = false,
  onlyVideos = false,
}) => {
  if (onlyVideos && !hasVideos) return null;

  return (
    <Container key={Math.random()}>
      <Breadcrumb items={breadcrumbsItems} />

      <TitleBlock>
        <ResultTitle
          href={`${resultUrl}?internal_source=search`}
          dangerouslySetInnerHTML={{ __html: title }}
          onClick={() => {
            if (clickedInResult) clickedInResult();
          }}
        />

        {hasVideos && (
          <img
            className="playButton"
            src={playButton}
            alt="Play button"
            style={{ height: '15px', width: '15px' }}
          />
        )}
      </TitleBlock>

      <a
        href={`${resultUrl}?internal_source=search`}
        style={{ fontSize: '13px' }}
        dangerouslySetInnerHTML={{ __html: content }}
        onClick={() => {
          if (clickedInResult) clickedInResult();
        }}
      />
    </Container>
  );
};

export default ResultItem;

const Container = styled.div`
  margin: 0px 2px 30px 2px;

  text-align: left;
  padding: 0 20px;
  max-height: 90px;
  overflow: hidden;
  color: #bbb;

  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .breadcrumb {
    margin: 10px 0 5px 0;
    color: #bbb;
    font-size: 0.75em;
    span {
      display: inline-block;
      margin: 0 5px;
    }
  }
`;
