import React, { useCallback } from 'react';
import { urlByResult } from './helper';
import ResultItem from './ResultItem';

const TheoryResult = ({ result, clickedInResult, onlyVideos }) => {
  const removeHighlight = useCallback(name => {
    try {
      return name?.replaceAll('<b>', '')?.replaceAll('</b>', '') || '';
    } catch (_) {
      return '';
    }
  }, []);

  const { subject } = result;
  const { topic } = subject;
  const { theory } = topic;

  const url = urlByResult(result);
  const title = `${topic?.name} | Teoria de ${result.name}`;

  let breadcrumbsItems = [
    {
      label: result?.name,
      link: `${process.env.REACT_APP_RESPONDEAI_URL}/materias/completas/${result.id}`,
      color: result.color,
    },
    { label: `${subject?.name}`, link: url },
  ];

  if (topic?.chapter)
    breadcrumbsItems.push({
      label: removeHighlight(topic?.chapter?.name),
      link: url,
    });

  // todo deal with videos directly in the result
  return (
    <ResultItem
      onlyVideos={onlyVideos}
      hasVideos={theory.videos?.length > 0}
      resultUrl={url}
      breadcrumbsItems={breadcrumbsItems}
      title={title}
      clickedInResult={clickedInResult}
      content={topic.theory.lightBody}
    />
  );
};

export default TheoryResult;
