export default async function reduceImage(file, maxSize = 2000) {
  let resizedFile = await execReductionImage(file, maxSize);

  resizedFile.lastModified = file.lastModified;
  resizedFile.lastModifiedDate = file.lastModifiedDate;
  resizedFile.name = file.name;

  return resizedFile;
}

function execReductionImage(file, maxSize) {
  let reader = new FileReader();
  let image = new Image();
  let canvas = document.createElement('canvas');
  let dataURItoBlob = function(dataURI) {
    // TODO remove deprecated method call
    let bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0
        ? window.atob(dataURI.split(',')[1])
        : window.unescape(dataURI.split(',')[1]);

    let mime = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];
    let max = bytes.length;
    let ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  };

  const resize = function() {
    let width = image.width;
    let height = image.height;

    if (width > height && width > maxSize) {
      height *= maxSize / width;
      width = maxSize;
    } else if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    let dataUrl = canvas.toDataURL('image/jpeg');

    return dataURItoBlob(dataUrl);
  };

  return new Promise(ok => {
    reader.onload = readerEvent => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
}
