import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { media, styled } from 'styles';
import {
  SolvedPercentage,
  Question,
  PageSkeleton,
  MainDialog,
} from '../components/ListWithStatementPage';
import { MathJaxWithLatexProvider } from 'providers';
import { useContentLazyQueryImmutable } from 'modules/shared/hooks/requests';
import { ShareDrawer } from '../components/ShareDrawer';
import { ContainerButtons } from '../components/ContainerButtons';
import { useTrackEvents, useQuestions } from '../hooks';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';
import { useToast } from 'modules/shared/hooks/useToast';
import { useHandleListOwner } from '../hooks/useHandleListOwner';
import ZeroPercentageDialog from '../components/ListWithStatementPage/dialogs/ZeroPercentDialog';
import Cookies from 'js-cookie';

export function ListWithStatementPage() {
  const { trackViewListPage, recordScreen } = useTrackEvents();
  const { listId } = useParams();
  const {
    questionsWithBlocked,
    solvedPercentage,
    solvedPercentageToBar,
  } = useQuestions();
  const [showShareDrawer, setShowShareDrawer] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(true);
  const { user } = useUserContext();
  const { unsetDialog, setDialog } = useDialogContext();
  const { setToastAfterReload } = useToast();
  const {
    setListIdInCookies,
    shouldSetUserIdToList,
    setUserIdToList,
  } = useHandleListOwner();
  const [hasGivenTrial, setHasGivenTrial] = useState(false);

  const [
    getList,
    { data: listData, loading: listLoading, error: listError },
  ] = useContentLazyQueryImmutable(`/mvp/lists-with-statements/${listId}`, {
    refreshInterval: shouldRefetch ? 4 * 1000 : 0, // 4s ou nunca
  });

  const isPending = useMemo(() => listData && listData.status === 'pending', [
    listData,
  ]);

  const list = useMemo(() => {
    if (!listData || isPending) return null;

    const enrichedQuestions = questionsWithBlocked(listData);
    return {
      ...listData,
      questions: enrichedQuestions,
    };
  }, [listData, isPending, questionsWithBlocked]);

  const isIncompleteList = useMemo(() => {
    return list && list.status === 'incomplete';
  }, [list]);

  const handleError = useCallback(() => {
    setToastAfterReload(
      'Ops, não conseguimos identificar nenhuma questão nessa lista. Envie outra lista!',
      'error'
    );
    unsetDialog();
    window.location.href = '/resolver-lista?list_rejected=true';
  }, [setToastAfterReload, unsetDialog]);

  const processListAtributionAndTrial = useCallback(async () => {
    const trialGiven = await setUserIdToList(listData.id);
    setHasGivenTrial(trialGiven);
  }, [listData, setUserIdToList]);

  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    if (!list || isPending) return;

    trackViewListPage(list, solvedPercentage(list));
    recordScreen();
  }, [list, trackViewListPage, solvedPercentage, recordScreen, isPending]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [list]);

  useEffect(() => {
    if (!listLoading && !isPending) setShouldRefetch(false);
  }, [isPending, listLoading]);

  useEffect(() => {
    // eslint-disable-next-line custom-rules/set-dialog
    const shouldSetLoginOrWaitDialog = !user || isPending;
    if (shouldSetLoginOrWaitDialog)
      // eslint-disable-next-line custom-rules/set-dialog
      setDialog(<MainDialog isListPending={isPending} list={listData} />);

    const shouldOpenZeroPercentageDialog =
      !shouldSetLoginOrWaitDialog &&
      isIncompleteList &&
      solvedPercentage(listData) === 0 &&
      !!Cookies.get('firstTimeInList');

    if (shouldOpenZeroPercentageDialog) {
      // eslint-disable-next-line custom-rules/set-dialog
      setDialog(<ZeroPercentageDialog list={list} />);
    }

    if (user && !isPending) {
      if (hasGivenTrial && !user.hasAccess) {
        window.location.reload();
      } else {
        if (!shouldSetLoginOrWaitDialog && !shouldOpenZeroPercentageDialog)
          unsetDialog();
      }
    }
  }, [
    hasGivenTrial,
    isIncompleteList,
    isPending,
    list,
    listData,
    listLoading,
    setDialog,
    solvedPercentage,
    unsetDialog,
    user,
  ]);

  useEffect(() => {
    if (user && listError) handleError();
  }, [
    handleError,
    isPending,
    listError,
    listLoading,
    setDialog,
    unsetDialog,
    user,
  ]);

  useEffect(() => {
    if (!listData) return;

    const shouldSetCookie = listData.userId === null && isPending;
    if (shouldSetCookie) setListIdInCookies(listData.id);
  }, [isPending, list, listData, setListIdInCookies]);

  useEffect(() => {
    if (!listData) return;

    if (shouldSetUserIdToList(listData)) {
      processListAtributionAndTrial();
    }
  }, [
    isPending,
    list,
    listData,
    processListAtributionAndTrial,
    setListIdInCookies,
    setUserIdToList,
    shouldSetUserIdToList,
  ]);

  useEffect(() => {
    if (isPending) {
      const expires = new Date(new Date().getTime() + 90000); // 150 sec from now
      Cookies.set('firstTimeInList', true, { expires });
    }
  }, [isPending]);

  if (!listData || isPending) return <PageSkeleton />;

  return (
    <MathJaxWithLatexProvider>
      <Container>
        {!isIncompleteList && (
          <Title>
            {list.exam} - Professor {list.professor.name} -{' '}
            {list.discipline.name}
          </Title>
        )}
        <ContainerButtons
          onShare={() => setShowShareDrawer(true)}
          list={list}
          isIncompleteList={isIncompleteList}
        />
        <SolvedPercentage
          percentage={isPending ? 0 : solvedPercentageToBar(list)}
        />

        <QuestionsContainer>
          {list.questions.map(question => (
            <Question question={question} list={listData} key={question.id} />
          ))}
        </QuestionsContainer>

        <ShareDrawer
          open={showShareDrawer}
          onClose={() => setShowShareDrawer(false)}
        />
      </Container>
    </MathJaxWithLatexProvider>
  );
}

const Container = styled.div`
  width: 1080px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media ${media.mobile} {
    margin-top: 80px;
  }
`;

const Title = styled.h1`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 40px 0 15px 0;
  font-size: 2em;
  font-weight: bold;
  color: #f7ac3d;

  @media ${media.mobile} {
    font-size: 1.5em;
    text-align: center;
    flex-direction: column;

    * {
      margin-top: 10px;
    }
  }
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 30px 40px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
`;
