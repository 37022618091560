import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { styled, colors, media } from 'styles';
import { useWorkspaceGuide } from '../../../hooks/shared';
import { StudyPlanEditButton } from '.';

export default function SideMenuHeader({ title, subtitle, backLink, guide }) {
  const { studyPlanGuidable } = useWorkspaceGuide();

  const showStudyPlanEdit = useMemo(() => {
    return guide?.guidable === studyPlanGuidable && media.isDesktop();
  }, [guide, studyPlanGuidable]);

  return (
    <Container>
      <BackButton to={backLink}>
        <BackIcon icon={faLongArrowAltLeft} /> Voltar
      </BackButton>
      <Divider />
      <Info>
        <Title data-cy="side-menu-title">
          {title} {showStudyPlanEdit && <StudyPlanEditButton guide={guide} />}
        </Title>
        <Subtitle>{subtitle}</Subtitle>
      </Info>
    </Container>
  );
}

const Container = styled.div``;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  min-width: 100px;
  color: #bbb;
  font-size: 1.2em;
  line-height: 55px;
  margin: 3px 0 0 18px;
  text-transform: uppercase;
  cursor: pointer;
`;

const BackIcon = styled(FontAwesomeIcon)`
  margin-right: 7px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #333;
`;

const Info = styled.div`
  margin: 40px 18px 20px 18px;
  color: ${colors.workspaceOrange};
  word-break: break-word;
`;

const Title = styled.h1`
  text-transform: uppercase;
  word-break: break-word;
  font-weight: bold;
  font-size: 1.5em;
`;

const Subtitle = styled.h2``;
