import React, { useEffect, useMemo } from 'react';
import { ContainerBanner } from './styles';
import LectureBox from './LectureBox';
import { useTracker } from 'modules/shared/hooks';
import { Loading, Error } from 'modules/shared/components/generic';
import { useRAQueryImmutable } from 'modules/shared/hooks/requests';

export default function YoutubeLiveLectureBanner() {
  const { trackEvent } = useTracker();

  const { data, loading, error } = useRAQueryImmutable(
    '/banners/banners_data/side_banner/live_lectures',
    {
      limit: 5,
    }
  );

  const lectures = useMemo(() => {
    return data?.lectures;
  }, [data]);

  useEffect(() => {
    trackEvent('Lives YT: Viu Banner Lateral');
  }, [trackEvent]);

  if (error) return <Error />;
  if (loading) return <Loading />;

  if (lectures.length === 0) return null;

  return (
    <ContainerBanner>
      <div className="header">
        <h1>Agenda de Lives:</h1>
      </div>
      <div className="lectures">
        {lectures.map((l, i) => (
          <LectureBox index={i} lecture={l} key={l.id} />
        ))}
      </div>
    </ContainerBanner>
  );
}
