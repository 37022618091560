import React, { useCallback, useRef } from 'react';
import Slick from 'react-slick';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { ThemeItem, LecturesItems } from './index';
import { useLecturesSliders } from '../hooks';

import { styled, media } from 'styles';

export default function ThemesAndLecturesSliders({ themes }) {
  const themesRef = useRef(null);
  const lecturesRef = useRef(null);

  const {
    updateSlickSlideOnNextArrowClick,
    updateSlickSlideOnPrevArrowClick,
    updateSlickSlideOnThemeClick,
    synchronizeLecturesSlider,
    selectedTheme,
  } = useLecturesSliders(themes);

  const onThemeClick = useCallback(
    clickedIndex => {
      updateSlickSlideOnThemeClick(clickedIndex, themesRef, lecturesRef);
    },
    [updateSlickSlideOnThemeClick]
  );

  const onNextArrowClick = useCallback(() => {
    updateSlickSlideOnNextArrowClick(themesRef, lecturesRef);
  }, [updateSlickSlideOnNextArrowClick]);

  const onPrevArrowClick = useCallback(() => {
    updateSlickSlideOnPrevArrowClick(themesRef, lecturesRef);
  }, [updateSlickSlideOnPrevArrowClick]);

  const configThemes = {
    slidesToShow: themes.length > 3 ? 3 : 1,
    slidesToScroll: 1,
    speed: 400,
    centerMode: true,
    centerPadding: '0',
    draggable: false,
    beforeChange: (beforeIndex, newIndex) =>
      synchronizeLecturesSlider(newIndex, lecturesRef),
    arrows: false,
    infinity: true,
  };

  const configLectures = {
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 400,
    centerMode: true,
    centerPadding: '0',
    adaptiveHeight: true,
    onSwipe: direction => {
      direction == 'left'
        ? themesRef?.current?.slickNext()
        : themesRef?.current?.slickPrev();
    },
    draggable: false,
    arrows: false,
  };

  return (
    <>
      <ThemesSliderContainer>
        <PrevArrow data-cy="prev-arrow" onClick={onPrevArrowClick} />
        <Slider ref={themesRef} {...configThemes}>
          {themes.map((t, i) => (
            <ThemeItem
              isSelected={selectedTheme.id === t.id ? true : false}
              key={t.id}
              color={t.color}
              imagePath={t.imagePath}
              name={t.name}
              onClick={() => onThemeClick(i)}
            />
          ))}
        </Slider>
        <NextArrow data-cy="next-arrow" onClick={onNextArrowClick} />
      </ThemesSliderContainer>

      <LecturesSlider ref={lecturesRef} {...configLectures}>
        {themes.map(t => (
          <LecturesItems key={t.id} themeData={t} />
        ))}
      </LecturesSlider>
    </>
  );
}

const Slider = styled(Slick)`
  width: 600px;
  max-width: 85%;
`;

const LecturesSlider = styled(Slick)`
  max-width: 100%;
`;

const NextArrow = styled(ArrowForwardIos)`
  display: block;
  font-size: 2em !important;
  text-align: center;
  z-index: 1;
  @media ${media.mobile} {
    font-size: 1.1em !important;
  }
`;

const PrevArrow = styled(ArrowBackIos)`
  display: block;
  text-align: center;
  font-size: 2em !important;
  z-index: 1;
  @media ${media.mobile} {
    font-size: 1.1em !important;
  }
`;

const ThemesSliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
`;
