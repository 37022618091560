function getURLBySocialNetwork(oauth2ProviderName, overrideConfig = {}) {
  let config = { addOrigin: true, ...overrideConfig };
  const RESPONDEAI_URL = process.env.REACT_APP_RESPONDEAI_URL;
  const authLink = `${RESPONDEAI_URL}/users/auth/${oauth2ProviderName}`;

  if (config.addParamToOrigin && config.addOrigin) {
    const currentUrl = new URL(window.location.href);
    const queryParams = new URLSearchParams(currentUrl.search);
    config.addParamToOrigin.forEach(({ param, value }) => {
      queryParams.set(param, value);
    });

    currentUrl.search = queryParams.toString();

    return `${authLink}?origin=${encodeURIComponent(currentUrl.toString())}`;
  }

  if (config.addOrigin) {
    return `${authLink}?origin=${encodeURIComponent(window.location.href)}`;
  }

  return authLink;
}

export function getFbAuthUrl(overrideConfig = {}) {
  return getURLBySocialNetwork('facebook', overrideConfig);
}

export function getGoogleAuthUrl(overrideConfig = {}) {
  return getURLBySocialNetwork('google_oauth2', overrideConfig);
}

export function getAppleAuthUrl(overrideConfig = {}) {
  return getURLBySocialNetwork('apple', overrideConfig);
}
