import React from 'react';

import { styled, media } from 'styles';
import { MathjaxContent } from 'modules/shared/components/generic';
import { useWorkspaceExercise } from 'modules/workspace/hooks/shared';

export default function ExerciseAnswer({ exercise, useRef, contentAccess }) {
  const { getExerciseColor } = useWorkspaceExercise();

  return (
    <Wrapper ref={useRef} data-cy="exercise-answer">
      <Title color={getExerciseColor()}>Resposta</Title>
      <Container color={getExerciseColor()}>
        <MathjaxContent
          html={exercise.lightAnswer}
          replaceToFakeContent={!contentAccess}
        />
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0 30px;

  @media ${media.mobile} {
    padding: 0 10px;
  }
`;

const Container = styled.div`
  width: 100%;
  border-left: 4px solid ${({ color }) => color};
  padding: 10px 20px;
  font-size: 1.2em;

  * {
    color: #888 !important;
  }
`;

const Title = styled.h1`
  font-size: 1.6em;
  color: ${({ color }) => color};
  font-family: 'Droid Serif', serif;
  padding-bottom: 30px;
`;
