import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { styled, media } from 'styles';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { Loading, Error } from 'modules/shared/components/generic';
import { LecturePlayer, Header } from 'modules/lectures/components';
import { landscape } from '../images/index';
import { useRenderLectureDialog } from 'modules/lectures/hooks';
import LectureProvider from '../providers/LectureProvider';
import PracticeContainer from '../components/PracticeContainer/PracticeContainer';
import ModuleDivider from '../components/PracticeContainer/ModuleDivider';
import useActivationCounter from 'modules/shared/hooks/useActivationCounter';

export default function LecturePage() {
  const { id: lectureId } = useParams();
  const { addActivationToUser } = useActivationCounter();

  const { data: lecture, error, loading } = useContentQueryImmutable(
    `/lectures/${lectureId}`
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useRenderLectureDialog();
  useEffect(() => {
    if (!lecture) return;
    addActivationToUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lecture]);

  if (error) return <Error />;
  if (loading) return <Loading />;

  return (
    <LectureProvider lectureData={lecture}>
      <Background>
        {!media.isMobile() && <LandscapeImg src={landscape} />}
      </Background>
      <Header />
      <Content>
        <LecturePlayer />
        <ModuleDivider />
        <PracticeContainer id="pratice" />
      </Content>
    </LectureProvider>
  );
}

const Content = styled.div`
  width: 100%;
  max-width: 960px;
  overflow: hidden;
  background-color: white;
  margin: 0 auto 200px auto;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 60vh;
  background-color: #f9ac3e;
  height: 622px;
  z-index: -1;
`;

const LandscapeImg = styled.img`
  height: 249px;
  position: absolute;
  bottom: 0;
  left: 0;
`;
