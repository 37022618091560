import React, { useMemo } from 'react';

import {
  useFormerSubscriberRecoverCampaign,
  usePaymentFailedRecoverCampaign,
  NoAccessDialogFormerSubscriber,
  NoAccessPaymentRecoverDialog,
} from 'modules/campaigns/recover-subscriber';
import { useRenderDialog } from 'modules/shared/hooks';
import NoAccessDialogFormerTrial from 'modules/campaigns/recover-subscriber/former-subscriber-recover/dialogs/NoAccessDialogFormerTrial/NoAccessDialogFormerTrial';

export default function useRenderLectureDialog() {
  const {
    isOnFormerSubscriberRecoverCampaign,
    isOnFormerTrialRecoverCampaign,
  } = useFormerSubscriberRecoverCampaign();
  const { hasPaymentFailedToRecover } = usePaymentFailedRecoverCampaign();

  const dialogs = useMemo(() => {
    return [
      {
        condition: isOnFormerSubscriberRecoverCampaign,
        render: () => <NoAccessDialogFormerSubscriber source="lecture-page" />,
      },
      {
        condition: hasPaymentFailedToRecover,
        render: () => <NoAccessPaymentRecoverDialog source="lecture-page" />,
      },
      {
        condition: isOnFormerTrialRecoverCampaign,
        render: () => <NoAccessDialogFormerTrial source="lecture-page" />,
      },
    ];
  }, [
    hasPaymentFailedToRecover,
    isOnFormerSubscriberRecoverCampaign,
    isOnFormerTrialRecoverCampaign,
  ]);

  useRenderDialog(dialogs);
}
