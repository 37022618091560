import React from 'react';
import { styled } from 'styles';
import { format, isFuture, isPast, add } from 'date-fns';
import { liveIcon } from '../images';
import { useHistory } from 'react-router-dom';

export default function LectureInfo(props) {
  const { lecture } = props;
  const history = useHistory();

  function checkIfLectureIsHapenning() {
    const startingDate = new Date(lecture.transmissionDate * 1000);
    const hasAlreadyBegan = isPast(startingDate);
    const hasNotFinished = isFuture(
      add(startingDate, { minutes: lecture.duration })
    );

    return hasAlreadyBegan && hasNotFinished;
  }

  function changeMainLecture() {
    const isClassOnlyInFuture = isFuture(
      new Date(lecture.transmissionDate * 1000)
    );

    if (!isClassOnlyInFuture)
      history.push(`/icb/aulao-intensivao/${lecture.id}`);
  }

  return (
    <Info
      subject={lecture.theme}
      notColorful={isFuture(new Date(lecture.transmissionDate * 1000))}
      onClick={changeMainLecture}
    >
      {checkIfLectureIsHapenning() && <img alt="" src={liveIcon} />}
      <p>{format(new Date(lecture.transmissionDate * 1000), 'dd/MM - HH')}h</p>
      <p>{lecture.name}</p>
    </Info>
  );
}

const Info = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  position: relative;
  cursor: ${props => (!props.notColorful ? 'pointer' : 'default')};

  &:hover {
    background-color: ${props =>
      props.notColorful
        ? '#BABABA'
        : props.subject === 'Cálculo'
        ? '#FDA243'
        : '#3EBD87'};
  }

  img {
    position: absolute;
    top: -15px;
    left: 0;
    width: 60px;
    color: red;
  }

  p {
    font-family: Lato;
    font-size: 15px;
    line-height: 18px;
    color: #000;
    text-align: center;
  }
`;
