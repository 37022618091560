import React from 'react';
import { urlByResult } from './helper';
import ResultItem from './ResultItem';

const BookResult = ({ result, clickedInResult, onlyVideos }) => {
  const exercise = result.book.edition.chapter.section.exercise;

  const url = urlByResult(result);

  let solution = result.book.edition.chapter.section.exercise.lightSolution.filter(
    it => it
  )[0];

  if (!solution) return <></>;

  solution = solution.substring(0, 100);

  const lsm = result.book.edition.chapter.section.exercise.lightSolutionMaths;

  for (let key2 in lsm[0]) {
    solution = solution.replace(`_m${key2}_`, lsm[key2 - 1]);
    solution = solution.replace(`_m${key2}`, lsm[key2 - 1]);
  }

  const lightBody =
    result?.book?.edition?.chapter?.section?.exercise?.lightBody || '';

  let title = `${lightBody} | Exercício de ${result.name}`;

  const breadcrumbsItems = [
    {
      label: result?.name,
      link: `${process.env.REACT_APP_RESPONDEAI_URL}/materias/completas/${result.id}`,
      color: result.color,
    },
    {
      label: `${result.book.name} ${result.book.edition.name}`,
      link: `${process.env.REACT_APP_RESPONDEAI_URL}/materias/solucionario/livro/${result.book.id}/edicao/${result.book.edition.id}`,
    },
    {
      label: `Cap. ${result.book.edition.chapter.name}`,
      link: url,
    },
    {
      label: `Sec. ${result.book.edition.chapter.section.name}`,
      link: url,
    },
    {
      label: `Ex. ${exercise.name}`,
      link: url,
    },
  ];

  return (
    <ResultItem
      resultUrl={url}
      breadcrumbsItems={breadcrumbsItems}
      title={title}
      onlyVideos={onlyVideos}
      clickedInResult={clickedInResult}
      content={solution}
    />
  );
};

export default BookResult;
