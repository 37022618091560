import React, { useCallback, useEffect } from 'react';
import { colors, styled } from 'styles';
import { FaRegTrashAlt, FaBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';
import CreateUserProfessorDialog from 'modules/professors/dialogs/CreateUserProfessorDialog';
import DeleteProfessorDialog from 'modules/professors/dialogs/DeleteProfessorDialog';
import { useProfessorContext } from 'modules/professors/providers/ProfessorProvider';
import { useHomeProfessorTracker } from 'modules/main/hooks/useHomeProfessorTracker';

export default function UserProfessorsListDesktop() {
  const { user } = useUserContext();
  const { setDialog } = useDialogContext();
  const { userProfessors } = useProfessorContext();
  const {
    trackAddUserProfessorClickButton,
    trackUserProfessorClick,
    trackClickOnDeleteUserProfessor,
    recordScreen,
  } = useHomeProfessorTracker();

  useEffect(() => {
    recordScreen();
  }, [recordScreen]);

  const openCreateUserProfessorDialog = useCallback(() => {
    trackAddUserProfessorClickButton();

    setDialog(<CreateUserProfessorDialog source="CTA Home" />);
  }, [setDialog, trackAddUserProfessorClickButton]);

  const getDisciplineFromProfessor = useCallback(professor => {
    return professor.discipline || professor.mvpDiscipline;
  }, []);

  return (
    <Container>
      <Title>Suas Disciplinas neste Semestre</Title>
      <Cards>
        {userProfessors.map(userProfessor => (
          <Card
            onClick={() => trackUserProfessorClick(userProfessor)}
            to={`/materias/professor/${userProfessor.professor.id}`}
            key={userProfessor.professor.id}
            color={
              getDisciplineFromProfessor(userProfessor.professor).theme.color
            }
          >
            <TrashIcon
              onClick={e => {
                trackClickOnDeleteUserProfessor(userProfessor);
                setDialog(
                  <DeleteProfessorDialog userProfessor={userProfessor} />
                );
                e.preventDefault();
              }}
            />
            <img
              src={
                getDisciplineFromProfessor(userProfessor.professor).theme
                  .imagePath
              }
              alt=""
            />
            <CardTitle>
              {getDisciplineFromProfessor(userProfessor.professor).name}
            </CardTitle>
            <ProfessorName>{userProfessor.professor.name}</ProfessorName>
            <UniversityName>{user.university.acronym}</UniversityName>
          </Card>
        ))}
        <AddCard onClick={openCreateUserProfessorDialog}>
          <div className="icon">
            <span>+</span>
            <FaBook className="rotate" />
          </div>
          <AddCardTitle>
            ADICIONAR
            <br /> NOVA DISCIPLINA
          </AddCardTitle>
        </AddCard>
      </Cards>
    </Container>
  );
}

const Container = styled.div`
  padding: 15px 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  max-width: 1220px;
  margin: 20px auto 0 auto;
`;

const Title = styled.p`
  font-size: 26px;
  margin-bottom: 25px;
  font-weight: bold;
`;

const Card = styled(Link)`
  display: flex;
  transition: opacity 100ms ease-in;
  background-color: ${props => props.color};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 184px;
  min-width: 120px;
  height: 118px;
  margin: 10px 8px 20px 8px;
  padding: 15px 0 10px 0;
  border-radius: 10px;
  /* justify-content: end; */
  color: #fff;
  img {
    width: 40px;
    height: 40px;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const CardTitle = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  text-align: center;
  width: 98%;
  margin-top: 5px;
  margin-bottom: 3px;
`;
const ProfessorName = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
  width: 98%;
`;

const TrashIcon = styled(FaRegTrashAlt)`
  font-size: 14px;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
  right: 11px;
  top: 7px;
  &:hover {
    color: white;
  }
`;

const UniversityName = styled.p`
  position: absolute;
  bottom: 4px;
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.3);
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AddCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;
  border: 1px solid ${colors.monthlyOrange};
  width: 184px;
  min-width: 120px;
  height: 118px;
  margin: 10px 8px 20px 8px;
  padding: 15px 0 10px 0;
  .icon {
    font-size: 2rem;
    color: #f7ac3b;
    margin-bottom: 10px;
    /* opacity: unset; */
  }
  &:hover {
    cursor: pointer;
    background-color: #f7ac3b32;
  }
`;

const AddCardTitle = styled.p`
  text-align: center;
  color: #6b6b6b;
  font-size: 15px;
`;
