import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_API_KEY,
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.REACT_APP_GIT_COMMIT_SHA,
        guess_uncaught_frames: true,
      },
    },
  },
  scrubFields: ['user_jwt', 'session_token', 'card', 'card_params'],
  enabled: process.env.NODE_ENV === 'production',
};

export default function RollbarProvider({ children }) {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
}
