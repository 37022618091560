import React, { useMemo } from 'react';
import BackButton from '../BackButton';
import { Header } from './styles';
import { styled, media } from 'styles';

export default function ProfessorHeader({ professor, color }) {
  const { name: professorName, teachable: discipline } = professor;

  const backLink = useMemo(() => {
    return '/materias';
  }, []);

  return (
    <>
      <Header backgroundColor={color} paddingBottom={'170px'}>
        <BackButton to={backLink} />

        <Title
          textAlign={'center'}
          fontSize={'42px'}
        >{`${discipline.name} - ${professorName}`}</Title>
      </Header>
    </>
  );
}

export const Title = styled.h1`
  position: relative;
  max-width: 960px;
  text-transform: uppercase;
  color: #fff;
  margin: 0 auto 0 auto;
  text-align: center;
  font-size: ${({ fontSize }) => fontSize || '2.2em'};
  font-weight: bold;
  text-align: ${({ textAlign }) => textAlign};

  @media ${media.mobile} {
    font-size: 22px;
    max-width: 95%;
  }
`;
