import React from 'react';
import { Route } from 'react-router-dom';

import {
  DisciplinePage,
  SubjectPage,
  StudyTrackPage,
  StudyPlanPage,
  ProfessorPage,
  SubjectProfessorPage,
} from './pages';
import ProfessorProvider from 'modules/professors/providers/ProfessorProvider';
import TooltipProvider from 'providers/TooltipProvider';

export default [
  <Route
    exact
    path="/materias/:disciplineId/guia/:guideId"
    component={DisciplinePage}
    key="guia-disciplina"
  />,
  <Route
    exact
    path="/materias/completas/:themeId/assunto/:subjectId"
    render={props => (
      <ProfessorProvider>
        <SubjectPage {...props} />
      </ProfessorProvider>
    )}
    key="guia-assunto"
  />,
  <Route
    exact
    path="/trilha-de-estudo/:studyTrackId/guia/:guideId"
    component={StudyTrackPage}
    key="guia-privadas"
  />,
  <Route
    exact
    path="/plano-de-estudo/:studyPlanId/guia/:guideId"
    component={StudyPlanPage}
    key="guia-plan-de-estudos"
  />,
  <Route
    exact
    path={'/materias/professor/:professorId'}
    component={props => (
      <TooltipProvider>
        <ProfessorPage {...props} />
      </TooltipProvider>
    )}
    key="guia-professor"
  />,
  <Route
    exact
    path={'/materias/professor/:professorId/assunto/:subjectId'}
    component={props => (
      <TooltipProvider>
        <SubjectProfessorPage {...props} />
      </TooltipProvider>
    )}
    key="assunto-professor"
  />,
];
