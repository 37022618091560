import React, { useCallback } from 'react';
import { ContentFeedback } from 'modules/feedback/components';
import {
  CONTENT_FEEDBACK_GRADES,
  CONTENT_FEEDBACK_FEELINGS,
} from 'modules/books/consts/content-feedback';
import { useBookExerciseTracker } from '../hooks';
import useBooks from '../hooks/useBooks';
import { useBookEditionContext } from '../providers/BookEditionProvider';

export default function BookContentFeedbackContainer() {
  const {
    currentSectionId,
    currentExerciseId,
    edition,
  } = useBookEditionContext();
  const { getChapterDotSectionName, getQuestionByBookExerciseId } = useBooks();
  const {
    trackContentFeedbackNote,
    trackContentFeedbackFeeling,
    trackContentFeedbackComment,
  } = useBookExerciseTracker();

  const getFeedbackBookName = useCallback(() => {
    const { amplitudeName } = edition;

    const chapterDotSectionName = getChapterDotSectionName(
      edition,
      currentSectionId
    );

    const question = getQuestionByBookExerciseId(edition, currentExerciseId);

    return `${amplitudeName} - Capítulo/Seção ${chapterDotSectionName} - Exercício ${question.name}`;
  }, [
    edition,
    getChapterDotSectionName,
    getQuestionByBookExerciseId,
    currentSectionId,
    currentExerciseId,
  ]);

  return (
    <ContentFeedback
      contentType="book-exercise"
      contentId={currentExerciseId}
      contentName={getFeedbackBookName()}
      contentMediaType="text"
      contentMediaProviderId=""
      allGrades={CONTENT_FEEDBACK_GRADES}
      allFeelings={CONTENT_FEEDBACK_FEELINGS}
      headerText="E aí, esse passo a passo te ajudou?"
      trackFeedbackNoteCallback={trackContentFeedbackNote}
      trackFeedbackFeelingCallback={trackContentFeedbackFeeling}
      trackFeedbackCommentCallback={trackContentFeedbackComment}
      dataCy="book-content-feedback"
    ></ContentFeedback>
  );
}
