import { styled } from 'styles';

const ContainerBanner = styled.div`
  height: 150px;
  background: #1e5e4e;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .banner-content {
    width: 1060px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .copy-container {
      height: 100%;
      min-width: 550px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-size: 25px;
        text-align: center;
        font-weight: bold;
        color: whitesmoke;
        width: 520px;

        &.subtitle {
          margin-top: 5px;
          font-size: 15px;
        }

        &.gray {
          color: #b9cbc7;
        }

        &.help {
          color: whitesmoke;

          button {
            color: #ff6f4e;
          }
        }
      }
    }

    @keyframes pulse {
      0% {
        transform: scale(0.95);
      }

      70% {
        transform: scale(1);
      }

      100% {
        transform: scale(0.95);
      }
    }

    .action-container {
      height: 100%;
      min-width: 280px;
      display: flex;
      justify-content: center;
      align-items: center;

      .copy-action {
        min-height: 20px;
        padding: 20px;
        min-width: 240px;
        font-size: 16px;
        font-weight: 900;
        color: whitesmoke;
        background-color: #ff6f4e;
        border-radius: 10px;
        box-shadow: 0 0 22px 3px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        word-break: break-all;
        text-align: center;

        &.pulse {
          transform: scale(1);
          animation: pulse 2s infinite;
        }
      }
    }
  }
`;

export { ContainerBanner };
