import styled from 'styled-components';
import React, { useState, useEffect } from 'react';

export default function ToggleableComponent(props) {
  const [open, setOpen] = useState(false);
  const { renderDelay } = props;

  useEffect(() => {
    if (!renderDelay) {
      setOpen(props.open);
      return;
    }

    const timeoutToOpenId = setTimeout(() => {
      setOpen(props.open);
    }, renderDelay || 0);

    return () => clearTimeout(timeoutToOpenId);
  }, [renderDelay, props.open]);

  return <Container className={open ? 'open' : ''} {...props} />;
}

const Container = styled.div`
  transition: max-height ${({ animationTime }) => animationTime || '1s'}
    ease-in-out;
  max-height: 0;
  overflow-y: hidden;
  width: ${({ width }) => (width ? `${width}` : '100%')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}` : '0'};
  ${props => props.style}

  &.open {
    max-height: ${({ height }) => height || '120px'};
  }
`;
