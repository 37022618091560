import React, { useCallback } from 'react';

import { styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker } from 'modules/shared/hooks';
import EditStudyPlanTopicsDialog from 'modules/guides/dialogs/EditStudyPlanTopicsDialog';

export default function StudyPlanEditTopicButton({ guideId, studyPlan }) {
  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();

  const onClick = useCallback(() => {
    trackEvent('Plano de Estudos: Clicou personalizar', { id: studyPlan.id });
    setDialog(
      <EditStudyPlanTopicsDialog guideId={guideId} studyPlan={studyPlan} />
    );
  }, [setDialog, trackEvent, guideId, studyPlan]);

  return <Button onClick={onClick}>Incluir/Excluir subtópicos</Button>;
}

const Button = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
  max-width: 90px;
  font-size: 0.8em;
  text-align: center;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;
