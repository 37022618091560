import React, { useCallback, useEffect, useMemo } from 'react';
import { media, styled } from 'styles';
import croppedQuestion from 'images/search/cropped-question.png';
import bluredQuestion from 'images/search/blured-question.png';
import bentedQuestion from 'images/search/bented-question.png';
import useSearchTracker from '../hooks/useSearchTracker';
import UploadImageArea from '../components/UploadImageArea';
import useSearchNavigation from '../hooks/useScreenNavigation';

export default function InputTutorialScreen({
  className,
  shouldTriggerViewEvent = true,
}) {
  const { trackViewInputTutorialPage } = useSearchTracker();
  const { goNextScreen } = useSearchNavigation();

  const tips = useMemo(() => {
    return [
      {
        text: 'Escaneie apenas uma questão por vez',
        image: croppedQuestion,
      },
      {
        text: 'O texto deve estar nítido',
        image: bluredQuestion,
      },
      {
        text: 'Mantenha nivelado',
        image: bentedQuestion,
      },
    ];
  }, []);

  const onSendQuestion = useCallback(() => {
    goNextScreen();
  }, [goNextScreen]);

  useEffect(() => {
    if (!shouldTriggerViewEvent) return;

    trackViewInputTutorialPage();
  }, [shouldTriggerViewEvent, trackViewInputTutorialPage]);

  return (
    <Container className={className}>
      <Title>Dicas para enviar sua questão</Title>
      {tips.map((tip, index) => (
        <TipBox key={`tip_${index}`}>
          <p>{tip.text}</p>
          <img src={tip.image} />
        </TipBox>
      ))}
      {media.isMobile() ? (
        <UploadImageArea />
      ) : (
        <SendQuestionButton onClick={onSendQuestion}>
          Enviar questão
        </SendQuestionButton>
      )}
      <FormatNote>Apenas jpeg, png e jpg</FormatNote>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;

  @media ${media.mobile} {
    padding-bottom: 55px;
    padding-top: 50px;
  }
`;

const Title = styled.h2`
  color: #000;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 24px;
  margin-top: 20px;

  @media ${media.mobile} {
    font-size: 21px;
    margin-bottom: 20px;
    margin-top: 0px;
  }
`;

const TipBox = styled.div`
  border-radius: 16.949px;
  background: #fff;
  box-shadow: 0px 2.6px 7.8px 0px rgba(0, 0, 0, 0.25);
  width: 428px;
  margin: 12px auto;
  padding-top: 15px;

  p {
    color: #000;
    font-size: 16px;
    font-family: Lato;
    font-weight: 700;
    margin-bottom: 10px;
  }

  img {
    height: 85px;
  }

  @media ${media.mobile} {
    width: 80%;
    margin: 12px auto;

    p {
      font-size: 14px;
    }
  }
`;

export const SendQuestionButton = styled.button`
  width: 257px;
  height: 57px;
  border-radius: 39px;
  background: #56b6a6;
  color: #fff;
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
`;
const FormatNote = styled.p`
  color: #000;
  font-size: 12px;
  margin-top: 6px;
`;
