/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled, media } from 'styles';
import { FaSearch } from 'react-icons/fa';
import { useDialogContext } from 'providers/DialogProvider';
import SearchDialog from 'modules/main/dialogs/search-dialog/SearchDialog';

import { useHistory } from 'react-router-dom';
import { useUniversityContext } from 'providers/UniversityProvider';

export default function SearchButton({
  theme = null,
  color = '#f2b138',
  where = 'home',
  inline = false,
  initialTerm = '',
  onEnter = () => {},
}) {
  const history = useHistory();
  const { setDialog } = useDialogContext();
  const { university } = useUniversityContext();

  const [term, setTerm] = useState(initialTerm);

  useEffect(() => {
    setTerm(initialTerm);
  }, [initialTerm]);

  const onSearch = useCallback(
    term => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        history.push({ search: term ? `search=${term}` : '' });
    },
    [history]
  );

  const onCloseDialog = useCallback(() => {
    history.push({ search: '' });
  }, [history]);

  const onClickResult = useCallback(
    term => {
      history.push({ search: term ? `search=${term}` : '' });
    },
    [history]
  );

  const openSearch = useCallback(
    term => {
      setDialog(
        <SearchDialog
          where={where}
          onCloseDialog={onCloseDialog}
          university={university}
          theme={theme}
          color={color}
          onSearch={onSearch}
          onClickResult={onClickResult}
          initialTerm={term}
          inline={inline}
        />
      );
    },
    [
      setDialog,
      onCloseDialog,
      onSearch,
      onClickResult,
      where,
      university,
      theme,
      color,
      inline,
    ]
  );

  useEffect(() => {
    const search = history?.location?.search;

    if (!search || !search.includes('search=')) return;

    let term = search.split('=')[1];

    if (!term) return;

    term = decodeURI(term);

    if (!inline) openSearch(term);
  }, [history, openSearch, inline]);

  const time = useRef(null);

  useEffect(() => {
    if (time.current) clearTimeout(time.current);

    time.current = setTimeout(() => {
      onEnter(term);
    }, 500);
  }, [term, onEnter]);

  return (
    <SearchContainer
      onClick={() => !inline && openSearch('')}
      isThemePage={!!theme}
      color={color}
    >
      <FaSearch
        className="icon"
        color={theme ? 'white' : `${color}`}
        size={26}
      />
      <Input
        isThemePage={!!theme}
        type="text"
        disabled={!inline}
        onClick={e => {
          if (inline) e.stopPropagation();
        }}
        placeholder={
          theme
            ? 'Busque nesta Matéria'
            : 'Buscar teorias e exercícios resolvidos'
        }
        value={term}
        onChange={e => setTerm(e.target.value)}
      />
      {/* {theme
        ? 'Busque nesta Matéria'
        : 'Buscar teorias e exercícios resolvidos'} */}
    </SearchContainer>
  );
}

const Input = styled.input`
  border: none;
  background: none;
  width: 100%;
  font-size: 1.1em;
  cursor: pointer;
  color: ${({ isThemePage }) => (isThemePage ? '#fff' : '#888')};
  font-family: 'Roboto', sans-serif;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  margin-top: 30px;
  z-index: 9;
  cursor: pointer;
  padding: 0 40px 0 13px;
  background-color: ${({ isThemePage }) =>
    isThemePage ? 'rgba(0, 0, 0, 0.05)' : '#fff'};
  border-radius: 50px;
  border: ${({ isThemePage, color }) =>
    isThemePage ? null : `2px solid ${color};`};
  text-align: center;
  color: ${({ isThemePage }) => (isThemePage ? '#fff' : '#888')};

  font-size: 1.1em;
  font-style: normal;
  text-shadow: none;

  .icon {
    font-size: 1.4rem;
    margin-right: 10px;
  }

  @media ${media.mobile} {
    width: 98%;
    margin: 30px auto;
  }
`;
