import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components/macro';

import { colors, media } from 'styles';
import dexterImg from 'images/permission/dexter-blink.png';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import { usePaymentFailedRecoverCampaign } from 'modules/campaigns/recover-subscriber/payment-failed-recover/hooks/PaymentFailedRecoverCampaign';
import { useDialogContext } from 'providers/DialogProvider';
import { PlansDialog } from 'modules/campaigns/checkout-light/dialogs';
import { NoAccessPaymentRecoverDialog } from 'modules/campaigns/recover-subscriber/payment-failed-recover/dialogs';
import {
  NoAccessDialogFormerSubscriber,
  useFormerSubscriberRecoverCampaign,
} from 'modules/campaigns/recover-subscriber';
import { BookCreditCardTrialCTADialog } from 'modules/campaigns/book-trial/components';
import NoAccessDialogFormerTrial from 'modules/campaigns/recover-subscriber/former-subscriber-recover/dialogs/NoAccessDialogFormerTrial/NoAccessDialogFormerTrial';

export default function NoAccessDisclaimer(props) {
  const {
    showBookCreditCardTrialCTA,
    trialDays,
    showCheckoutLightDialog,
  } = useBookTrialCampaign();
  const { hasPaymentFailedToRecover } = usePaymentFailedRecoverCampaign();
  const {
    isOnFormerSubscriberRecoverCampaign,
    isOnFormerTrialRecoverCampaign,
  } = useFormerSubscriberRecoverCampaign();
  const { setDialog } = useDialogContext();
  const onCTAClick = useCallback(() => {
    if (showBookCreditCardTrialCTA())
      return setDialog(<BookCreditCardTrialCTADialog source="react" />);
    if (showCheckoutLightDialog()) return setDialog(<PlansDialog />);
    if (hasPaymentFailedToRecover())
      return setDialog(
        <NoAccessPaymentRecoverDialog source="no_access_dialog_react" />
      );
    if (isOnFormerSubscriberRecoverCampaign())
      return setDialog(
        <NoAccessDialogFormerSubscriber source="no_access_dialog_react" />
      );
    if (isOnFormerTrialRecoverCampaign())
      return setDialog(
        <NoAccessDialogFormerTrial source="no_access_dialog_react" />
      );

    window.location.href = `${process.env.REACT_APP_RA_WEB_SSR_URL}/planos`;
  }, [
    showBookCreditCardTrialCTA,
    showCheckoutLightDialog,
    hasPaymentFailedToRecover,
    isOnFormerSubscriberRecoverCampaign,
    isOnFormerTrialRecoverCampaign,
    setDialog,
  ]);

  const ctaText = useMemo(() => {
    if (showBookCreditCardTrialCTA()) return 'Liberar acesso gratuito!';
    if (hasPaymentFailedToRecover()) return 'Recuperar acesso';

    return 'Quero conteúdo ilimitado!';
  }, [showBookCreditCardTrialCTA, hasPaymentFailedToRecover]);

  const subtitleText = useMemo(() => {
    if (showBookCreditCardTrialCTA())
      return `Complete seu cadastro e libere seu acesso a todo conteúdo por ${trialDays} dias grátis`;
    if (hasPaymentFailedToRecover())
      return 'Houve uma falha de pagamento da sua assinatura, e por isso seu acesso está bloqueado';

    return 'Veja nossos planos e adquira acesso a todo conteúdo!';
  }, [showBookCreditCardTrialCTA, trialDays, hasPaymentFailedToRecover]);

  return (
    <Container {...props}>
      <Message>
        <Img src={dexterImg} />
        <Text>
          <Title>Este conteúdo está bloqueado.</Title>
          <Subtitle>{subtitleText}</Subtitle>
          <Button className="desktop" onClick={onCTAClick}>
            {ctaText}
          </Button>
        </Text>
      </Message>
      <Button className="mobile" onClick={onCTAClick}>
        {ctaText}
      </Button>
    </Container>
  );
}

const Container = styled.div`
  width: 430px;
  max-width: 100%;
  margin: 0 auto;
  color: ${colors.darkGray};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Message = styled.div`
  display: flex;
  align-items: center;
`;

const Img = styled.img`
  width: 87px;
  height: 100px;
  margin-right: 20px;

  @media ${media.mobile} {
    margin-right: 10px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2em;
`;

const Subtitle = styled.div`
  font-size: 0.8em;
`;

const Button = styled.div`
  display: inline-block;
  transition: background 0.2s ease-in-out;
  border-radius: 30px;
  font-weight: bold;
  color: #fff;
  padding: 0 20px;
  background-color: ${colors.mainOrange};
  height: 40px;
  line-height: 40px;
  margin: 10px 0;
  text-decoration: none;
  font-size: 1em;
  cursor: pointer;

  @media ${media.desktop} {
    &.mobile {
      display: none;
    }
  }

  @media ${media.mobile} {
    &.desktop {
      display: none;
    }
  }
`;

const Text = styled.div`
  line-height: 1.3;
  flex: 1;
`;
