import ElasticSearchSuggestions from './elastic-search-suggestions';

export async function getSearchSuggestions(
  term,
  onlyStartsWith = true,
  time = 0
) {
  let suggestions = await ElasticSearchSuggestions.getSuggestions(
    term,
    onlyStartsWith
  );

  return {
    suggestions: suggestions.map(it => ({ term: it.term, theme: it.theme })),
    time,
  };
}
