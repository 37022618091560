import React, { useCallback, useEffect, useMemo } from 'react';

import { styled, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { PlansDialog } from 'modules/campaigns/checkout-light/dialogs';
import { dexterBlink } from 'images/permission';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import { useTracker } from '../hooks';

function NoAccessDialog() {
  const { setDialog, unsetDialog } = useDialogContext();
  const { showCheckoutLightDialog } = useBookTrialCampaign();
  const { trackEvent } = useTracker();

  const fromBook = useMemo(
    () => location.pathname.includes('/materias/solucionario/livro/'),
    []
  );

  useEffect(() => {
    if (fromBook) trackEvent('Dialog de Bloqueio: Visualizou Dialog');
  }, [fromBook, trackEvent]);

  const onCTAClick = useCallback(() => {
    if (fromBook)
      trackEvent('Dialog de Bloqueio: Clicou no CTA de Conteúdo ilimitado');
    if (showCheckoutLightDialog()) return setDialog(<PlansDialog />);

    window.location.href = `${process.env.REACT_APP_RA_WEB_SSR_URL}/planos`;
  }, [fromBook, setDialog, showCheckoutLightDialog, trackEvent]);

  return (
    <Container
      onClick={() => {
        if (fromBook) trackEvent('Dialog de Bloqueio: Fechou Dialog');
        unsetDialog();
      }}
    >
      <Content onClick={e => e.stopPropagation()}>
        <Dexter src={dexterBlink} />
        <Title>Este conteúdo é exclusivo para assinantes</Title>
        <Subtitle>
          veja nossos planos e adquira acesso a todo conteúdo!
        </Subtitle>
        <SubscribeButton onClick={onCTAClick}>
          Quero conteúdo ilimitado!
        </SubscribeButton>
      </Content>
    </Container>
  );
}

export default NoAccessDialog;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Dexter = styled.img`
  width: auto;
  height: 100px;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 1.2em;
`;

const Subtitle = styled.h3`
  font-size: 0.8em;
`;

const SubscribeButton = styled.div`
  font-family: 'Lato', sans-serif;
  min-width: 190px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: bold;
  color: white;
  text-align: center;
  padding: 0 20px;
  background-color: ${colors.exercise};
  border: none;
  height: 40px;
  line-height: 40px;
  margin: 10px 0;
  text-decoration: none;
  font-size: 1em;
  height: 40px;
`;
