import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { styled } from 'styles';
import { useRAQuery, useRAPostRequest } from 'modules/shared/hooks/requests';

import ContentFeedbackFeeling from './ContentFeedbackFeeling';
import ContentFeedbackComment from './ContentFeedbackComment';
import ContentFeedbackGrade from './ContentFeedbackGrade';

export default function ContentFeedback({
  contentType,
  contentId,
  contentName,
  contentMediaType,
  contentMediaProviderId,
  headerText,
  allGrades,
  allFeelings,
  trackFeedbackNoteCallback,
  trackFeedbackFeelingCallback,
  trackFeedbackCommentCallback,
  dataCy,
}) {
  const [grade, setGrade] = useState();
  const [feelings, setFeelings] = useState([]);
  const [comment, setComment] = useState('');
  const [isCommentOpen, setCommentOpen] = useState(false);

  const sendContentFeedback = useRAPostRequest(
    'content_feedbacks/create_or_update'
  );

  const contentFeedbackQuery = useRAQuery('/content_feedbacks/find', {
    contentType: contentType,
    contentId: contentId,
    contentMediaType: contentMediaType,
  });

  const contentFeedbackObject = useMemo(
    () => ({
      grade,
      feelings,
      comment,
      contentType,
      contentName,
      contentId,
      contentMediaType,
      contentMediaProviderId,
    }),
    [
      grade,
      feelings,
      comment,
      contentType,
      contentName,
      contentId,
      contentMediaType,
      contentMediaProviderId,
    ]
  );

  useEffect(() => {
    if (!contentFeedbackQuery.error && !contentFeedbackQuery.loading) {
      setGrade(contentFeedbackQuery.data?.contentFeedback?.grade);
      setFeelings(contentFeedbackQuery.data?.contentFeedback?.feelings || []);
      setComment(contentFeedbackQuery.data?.contentFeedback?.comment || '');
      setCommentOpen(false);
    }
  }, [
    contentFeedbackQuery.error,
    contentFeedbackQuery.loading,
    contentFeedbackQuery.data,
    setCommentOpen,
  ]);

  const saveAndTrackFeedback = useCallback(
    async (data, trackCallback) => {
      const response = await sendContentFeedback(data);
      trackCallback(response.data.contentFeedbackId);
    },

    [sendContentFeedback]
  );

  const updateNoteFeedback = useCallback(
    value => {
      let newContentFeedbackData = { ...contentFeedbackObject };

      setGrade(value);
      newContentFeedbackData = {
        ...newContentFeedbackData,
        grade: value,
      };

      saveAndTrackFeedback(newContentFeedbackData, saveFeedbackId =>
        trackFeedbackNoteCallback(value, saveFeedbackId)
      );
    },
    [
      setGrade,
      trackFeedbackNoteCallback,
      contentFeedbackObject,
      saveAndTrackFeedback,
    ]
  );

  const updateFeelingsFeedback = useCallback(
    value => {
      let newContentFeedbackData = { ...contentFeedbackObject };

      setFeelings(value);
      newContentFeedbackData = {
        ...newContentFeedbackData,
        feelings: value,
      };

      if (value.includes('Outro') && !isCommentOpen) setCommentOpen(true);

      saveAndTrackFeedback(newContentFeedbackData, saveFeedbackId =>
        trackFeedbackFeelingCallback(grade, value, saveFeedbackId)
      );
    },
    [
      grade,
      setFeelings,
      contentFeedbackObject,
      isCommentOpen,
      setCommentOpen,
      trackFeedbackFeelingCallback,
      saveAndTrackFeedback,
    ]
  );

  const updateCommentFeedback = useCallback(() => {
    saveAndTrackFeedback(contentFeedbackObject, saveFeedbackId =>
      trackFeedbackCommentCallback(grade, feelings, comment, saveFeedbackId)
    );
  }, [
    trackFeedbackCommentCallback,
    contentFeedbackObject,
    grade,
    feelings,
    comment,
    saveAndTrackFeedback,
  ]);

  return (
    <Container data-cy={dataCy}>
      <div className="grade-step-container">
        <div className="header">{headerText}</div>
        <div className="grades-container">
          {allGrades.map(gradeOption => (
            <ContentFeedbackGrade
              key={gradeOption.id}
              src={gradeOption.iconSrc}
              text={gradeOption.text}
              onClick={() => updateNoteFeedback(gradeOption.id)}
              isSelected={grade === gradeOption.id}
            />
          ))}
        </div>
        {grade && (
          <>
            <ContentFeedbackFeeling
              updateFeedback={updateFeelingsFeedback}
              feelings={feelings}
              feelingsOptions={allFeelings}
            />
            <ContentFeedbackComment
              isCommentOpen={isCommentOpen}
              setCommentOpen={setCommentOpen}
              comment={comment}
              setComment={setComment}
              updateFeedback={updateCommentFeedback}
            />
          </>
        )}
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  background: #f8f8f8;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  margin-bottom: 20px;

  .grade-step-container,
  .feeling-step-container {
    .header {
      font-size: 1.2em;
      padding: 10px;
    }
  }

  .grades-container,
  .feelings-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;
