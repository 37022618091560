import React, { useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';

import { styled, colors } from 'styles';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';

export default function NextSubjectButton({ themeId }) {
  const { subjectId } = useParams();

  const { data: nextSubject } = useContentQueryImmutable(
    `/subjects/${subjectId}/next-subject/themes/${themeId}`
  );

  const linkUrl = useMemo(() => {
    if (!nextSubject || nextSubject.error) return '#';

    const chapter = nextSubject.chapters[0];
    const topic = chapter.topics[0];
    const theory = topic.theory;

    return `/aprender/topico/${nextSubject.id}/${topic.id}/teoria/${theory.id}`;
  }, [nextSubject]);

  if (!nextSubject || nextSubject.error) return null;
  return (
    <Container to={linkUrl} data-cy="next-subject-button">
      <Bar />
      <TextContainer>
        <Text>Próximo assunto {'>>'}</Text>
        <Text className="main">{nextSubject.name}</Text>
      </TextContainer>
    </Container>
  );
}

const Container = styled(Link)`
  display: flex;
  height: 35px;
  margin: 40px 10px;
  cursor: pointer;
  color: ${colors.workspaceOrange};
`;

const Bar = styled.div`
  width: 8px;
  height: 100%;
  background-color: ${colors.workspaceOrange};
  border-radius: 50px;
  margin-left: 12.5px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const Text = styled.div`
  text-transform: uppercase;
  font-size: 0.75em;

  &.main {
    font-size: 1em;
    margin-top: 2px;
    text-decoration: underline;
  }
`;
