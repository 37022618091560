import React, { useCallback, useEffect, useState } from 'react';
import { BeatLoader } from 'modules/shared/components/generic/loaders';

import { styled } from 'styles';
import { Button } from './styles';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useTracker } from 'modules/shared/hooks';
import UniversitySelects from 'modules/profile/components/profile-complete/components/UniversitySelects';

export default function UniversitySelectForm({ setError }) {
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [campi, setCampi] = useState([]);
  const [saving, setSaving] = useState(false);

  const { trackEvent } = useTracker();

  const sendUserInfo = useRAPostRequest('profile/update_university');

  useEffect(() => {
    trackEvent('Visualizou Dialog de Confirmação de Universidade');
  }, [trackEvent]);

  const canSubmit = useCallback(() => {
    let error = '';

    if (!selectedUniversity) {
      error = 'Você precisa selecionar uma universidade';
    }

    if (campi.length > 0 && !selectedCampus) {
      error = 'Você precisa selecionar um campus';
    }

    if (error) {
      setError(error);
      setTimeout(() => {
        setError('');
      }, 3000);
    }
    return !error;
  }, [selectedUniversity, selectedCampus, campi.length, setError]);

  const handleSubmit = useCallback(async () => {
    if (!canSubmit()) return;
    setSaving(true);

    const params = {
      universityId: selectedUniversity?.value,
      universityCampusId: selectedCampus?.value,
      from: 'user_university_confirmation',
    };

    try {
      const { data } = await sendUserInfo(params);
      if (data?.user) location.reload();
    } catch (e) {
      setError('Erro ao enviar as informações. Tente novamente');
      setTimeout(() => {
        setError('');
      }, 3000);
    } finally {
      setSaving(false);
    }
  }, [
    canSubmit,
    selectedUniversity?.value,
    selectedCampus?.value,
    sendUserInfo,
    setError,
  ]);

  return (
    <>
      <UniversitySelectorContainer>
        <UniversitySelects
          onChangeSelectedUniversity={setSelectedUniversity}
          onChangeSelectedCampus={setSelectedCampus}
          onChangeCampi={setCampi}
          noUniversitySelected={false}
        />
      </UniversitySelectorContainer>
      <Button onClick={handleSubmit} canSubmit={canSubmit} saving={saving}>
        {saving ? <BeatLoader size={10} color="#fff" /> : 'ENVIAR'}
      </Button>
    </>
  );
}

const UniversitySelectorContainer = styled.div`
  width: 100%;

  input {
    padding: 7px 0px;
  }
`;
