import React, { useMemo, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';

import { styled, colors } from 'styles';
import stars from 'modules/feedback/consts/syllabus-feedback-stars';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';
import {
  FinishSyllabusFeedbackDialog,
  SuccessSylabusFeedbackDialog,
} from 'modules/feedback/dialogs';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker } from 'modules/shared/hooks';
import { useSyllabusFeedback } from 'modules/feedback/hooks';
import { useUniversityContext } from 'providers/UniversityProvider';

export default function SyllabusFeedback({ contentName, guidableId, bottom }) {
  const { universityPronoumn } = useUniversityContext();

  const [openToggle, setOpenToggle] = useState(true);
  const [starOnMouseOver, setStarOnMouseOver] = useState(null);
  const { user } = useUserContext();
  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const {
    getSyllabusFeedbackEventAttributes,
    sendSyllabusFeedback,
    saveSyllabusFeedbackFlag,
  } = useSyllabusFeedback();

  const universityAcronymWithPronoun = useMemo(() => {
    if (!user || !user.university) return;
    const pronoun = universityPronoumn;
    return `${pronoun} ${user.university.acronym}`;
  }, [user, universityPronoumn]);

  const onMouseEnterStar = useCallback(
    star => {
      setStarOnMouseOver(star);
    },
    [setStarOnMouseOver]
  );

  const onMouseLeaveStar = useCallback(
    star => {
      if (starOnMouseOver && star.id === starOnMouseOver.id)
        setStarOnMouseOver(null);
    },
    [starOnMouseOver, setStarOnMouseOver]
  );

  const isFillStar = useCallback(
    star => {
      return starOnMouseOver && star.id <= starOnMouseOver.id;
    },
    [starOnMouseOver]
  );

  const starText = useMemo(() => {
    if (!starOnMouseOver) return;

    return starOnMouseOver.description;
  }, [starOnMouseOver]);

  const onStarClick = useCallback(
    star => {
      trackEvent(
        'Feedback: Ementa - Clicou Estrela',
        getSyllabusFeedbackEventAttributes(star, contentName)
      );

      if (star.id === 5) {
        sendSyllabusFeedback(star, contentName);
        saveSyllabusFeedbackFlag(guidableId);
        setOpenToggle(false);
        setDialog(<SuccessSylabusFeedbackDialog />);
        return;
      }

      setOpenToggle(false);
      setTimeout(() => {
        setDialog(
          <FinishSyllabusFeedbackDialog
            initialSelectedStar={star}
            setOpenSyllabus={setOpenToggle}
            contentName={contentName}
            guidableId={guidableId}
          />
        );
      }, 600);
    },
    [
      setDialog,
      setOpenToggle,
      contentName,
      guidableId,
      getSyllabusFeedbackEventAttributes,
      sendSyllabusFeedback,
      saveSyllabusFeedbackFlag,
      trackEvent,
    ]
  );

  return (
    <Container className={bottom ? 'bottom' : ''}>
      <ToggleableComponent
        width="100%"
        height="120px"
        animationTime="0.6s"
        open={openToggle}
      >
        <Content>
          <Title>
            O quanto este guia está de acordo com a ementa{' '}
            <Bold>{universityAcronymWithPronoun}?</Bold>
          </Title>
          <StarsContainer>
            {stars.map(star => (
              <StarIcon
                icon={isFillStar(star) ? faStarSolid : faStarRegular}
                key={star.id}
                onMouseEnter={() => onMouseEnterStar(star)}
                onMouseLeave={() => onMouseLeaveStar(star)}
                className={isFillStar(star) ? 'filled' : ''}
                onClick={() => onStarClick(star)}
              />
            ))}
            <StarText>{starText}</StarText>
          </StarsContainer>
        </Content>
      </ToggleableComponent>
    </Container>
  );
}

const Container = styled.div`
  width: 118%;
  margin: -12px 0 30px -9%;
  background-color: #fff;
  box-shadow: 0 3px 10px ${colors.lightGray};
  border-radius: 5px;

  &.bottom {
    position: absolute;
    width: 100%;
    margin: 0;
    bottom: -130px;
  }
`;

const Content = styled.div`
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.85em;
  line-height: 1.2em;
  color: ${colors.darkGray};
  padding: 15px 15px 20px 15px;
`;

const Title = styled.div`
  max-width: 80%;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const StarsContainer = styled.div`
  position: relative;
  display: flex;
  margin-top: 10px;
`;

const StarIcon = styled(FontAwesomeIcon)`
  font-size: 1.9em;
  cursor: pointer;
  color: ${colors.mediumGray};
  margin: 0 8px;

  &.filled {
    color: #ffda1a;
  }
`;

const StarText = styled.div`
  position: absolute;
  width: 100%;
  bottom: -23px;
  text-align: center;
  font-size: 0.95em;
  color: #dcbc18;
  font-weight: bold;
`;
