import { useCallback } from 'react';

import { media } from 'styles';
import { useCurrency } from 'modules/shared/hooks';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useRAQueryImmutable } from 'modules/shared/hooks/requests';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';

export default function useChangePlanOnCancelCampaign() {
  const { hasCreditCardTrial } = useBookTrialCampaign();
  const { data: changePlanOnCancelData } = useRAQueryImmutable(
    '/campaigns/change_plan_on_cancel'
  );

  const changePlanReq = useRAPostRequest('/campaigns/change_plan_on_cancel');

  const { formatCurrency } = useCurrency();

  const isSubscriptionEligibleToChangePlanOnCancel = useCallback(() => {
    return changePlanOnCancelData?.eligible;
  }, [changePlanOnCancelData]);

  const creditCardTrialChangePlan = useCallback(() => {
    return isSubscriptionEligibleToChangePlanOnCancel() && hasCreditCardTrial();
  }, [hasCreditCardTrial, isSubscriptionEligibleToChangePlanOnCancel]);

  const getAvailablePlanPricings = useCallback(() => {
    return changePlanOnCancelData?.availablePlanPricings;
  }, [changePlanOnCancelData]);

  const getMonthlyPlanPricingPrice = useCallback(planPricing => {
    const price = (planPricing.price / planPricing.plan.months).toFixed(2);
    return price.toString().replace('.', ',');
  }, []);

  const getChangePlanCard = useCallback(() => {
    return changePlanOnCancelData?.card;
  }, [changePlanOnCancelData]);

  const changePlan = useCallback(
    async (subscription, newPlanPricing) => {
      try {
        await changePlanReq({
          subscriptionId: subscription.id,
          newPlanPricingId: newPlanPricing.id,
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    [changePlanReq]
  );

  const getAmplitudeEventProperties = useCallback(
    subscription => {
      return {
        'Preco Plano Atual': formatCurrency(
          subscription.order.planPricing.price
        ),
        Onde: media.isDesktop() ? 'Desktop' : 'Mobile',
        'Do Trial com Cartão': hasCreditCardTrial() ? 'Sim' : 'Não',
      };
    },
    [formatCurrency, hasCreditCardTrial]
  );

  return {
    isSubscriptionEligibleToChangePlanOnCancel,
    getAvailablePlanPricings,
    getMonthlyPlanPricingPrice,
    changePlan,
    getChangePlanCard,
    getAmplitudeEventProperties,
    creditCardTrialChangePlan,
  };
}
