import React from 'react';

import { styled } from 'styles';

export default function SideMenuContent({ open, children }) {
  return (
    <Container className={open ? 'open' : ''} data-cy="container-side-menu">
      {children}
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: -320px;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100vh;
  background-color: #444;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  transition: left 0.3s ease-in-out;
  z-index: 10;

  &.open {
    left: 0;
  }
`;
