import { styled } from 'styles';

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .playButton {
    height: 10px;
    margin-left: 5px;
  }
`;

const ResultTitle = styled.a`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-weight: bold; */
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '94px')};
  text-align: center;
  background-color: #f9faf9;

  .close-controls {
    height: 50px;
    display: inline-block;
    position: relative;
    width: 50px;

    @include md(mobile) {
      background-color: red;
    }
  }

  .controls {
    display: inline-block;
    position: relative;
    width: 85%;
    max-width: 700px;
    border-radius: 30px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 0.2);
    background-color: #fff;
    .controls-content {
      display: flex;
      padding-left: 10px;
    }
    .search-icon {
      align-self: center;
    }
  }
  input {
    width: 80%;
    height: 50px;
    border: none;
    background-color: #fff;
    padding: 0 50px 0 30px;
    font-size: 1.1em;
    line-height: 50px;
    @include input-placeholder {
      font-size: 0.8em;
      line-height: 20px;
    }
    @include md(mobile) {
      -webkit-appearance: none;
      line-height: initial;
    }
  }

  /* TODO entender isso aqui */
  .not-found {
    width: 1px;
  }
`;

const MdBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: ${({ height }) => (height ? height : 'auto')};
  overflow: ${({ height }) => (height ? 'hidden' : 'auto')};
  width: ${({ width }) => (width ? width : '85%')}
  max-width: ${({ width }) => (width ? 'null' : '700px')};
  margin: 10px auto;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '10px')};
  border-radius: 10px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 0.2);
  background-color: white;
`;

const MdCard = styled.div`
  /* topo | direita | inferior | esquerda */
  margin: 0px 2px 30px 2px;

  text-align: left;
  padding: 0 20px;

  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .breadcrumb {
    margin: 10px 0 5px 0;
    color: #bbb;
    font-size: 0.75em;
    span {
      display: inline-block;
      margin: 0 5px;
    }
  }
  li {
    margin: 5px 0;
  }
`;

const TypeSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 85%;
  max-width: 700px;
  margin: 10px 0 5px 0;
  /* margin: 15px auto; */
`;

const InlineBackContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 85%;
  max-width: 700px;
  margin: 10px 4px 20px 3px;
  align-items: center;
  cursor: pointer;
`;

const TypeSelectorItem = styled.p`
  color: ${({ selected, color }) =>
    selected ? (color ? color : '#f6ac3a') : '#bababa'};
  border-bottom-style: solid;
  border-bottom-width: ${({ selected }) => (selected ? '2px' : '0')};
  margin: 0 2px 10px 20px;
  cursor: pointer;
`;

const VideoSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #bababa;
  border-bottom-style: solid;
  border-bottom-width: ${({ selected }) => (selected ? '2px' : '0')};
  margin: 0 2px 0 20px;
  cursor: pointer;
`;

const SuggestionsContainer = styled.div`
  width: ${({ isMobile }) => (isMobile ? '90%' : '700px')};
  margin: ${({ isMobile }) =>
    isMobile ? '0px auto 0 auto' : '-50px auto 0 auto'};

  padding: ${({ isMobile }) => (isMobile ? '0px 0 10px 0' : '50px 0 10px 0')};

  background-color: white;
  text-align: left;

  border-top-left-radius: ${({ isMobile }) => (isMobile ? '10px' : '30px')}
  border-top-right-radius: ${({ isMobile }) => (isMobile ? '10px' : '30px')}
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  box-shadow: 0 1px 3px rgb(0 0 0 / 0.2);

  max-width: 95%;
  .md-card-content {
    padding: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h3 {
    float: right;
    color: $workspaceColor;
    text-transform: uppercase;
    margin-top: 10px;
    text-align: right;
  }
  .breadcrumb {
    margin: 10px 0 5px 0;
    color: #bbb;
    font-size: 0.75em;
    text-transform: uppercase;
    span {
      display: inline-block;
      margin: 0 5px;
    }
  }
  ul {
    margin-left: 20px;
    @include md(mobile) {
      margin-left: 10px;
    }
  }
  li {
    margin: 5px 0;
  }
`;

const SuggestionItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ isMobile }) => (isMobile ? '5px 15px' : '0 60px')};
  height: ${({ isMobile }) => (isMobile ? '' : '40px')};

  background-color: ${({ selected }) => (selected ? '#f6f6f6' : 'none')};

  .highlight {
    font-size: 1em;
  }

  .theme {
    font-size: 0.7em;
  }

  &:hover {
    background-color: #f6f6f6;
  }
`;

export {
  VideoSelectContainer,
  SuggestionsContainer,
  Container,
  MdBlock,
  MdCard,
  TypeSelectorContainer,
  TypeSelectorItem,
  ResultTitle,
  TitleBlock,
  SuggestionItem,
  InlineBackContainer,
};
