import React, { useCallback, useEffect } from 'react';
import { styled, colors } from 'styles';
import UploadArea from './UploadArea';
import { MdOutlineUploadFile } from 'react-icons/md';
import { useMvpBooksTracker } from '../hooks/useMVpBooksTracker';
import useBookFormatter from '../hooks/useBookFormatter';
import { useUploadDialogContext } from '../providers/UploadDialogProvider';
import { useToast } from 'modules/shared/hooks';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';

export function FifthStep() {
  const {
    file,
    setFile,
    setStep,
    selectedBookEdition,
    selectedTheme,
    professor,
    exam,
    setLoading,
    selectedDiscipline,
    setListLink,
    invalidateProfessorQuery,
  } = useUploadDialogContext();
  const { user } = useUserContext();
  const { sendPostFileRequest } = useContentRestRequest();
  const { errorToast } = useToast();
  const {
    trackViewSendListScren,
    trackClickSendList,
    trackListAccepted,
    trackError,
  } = useMvpBooksTracker();
  const { unsetDialog } = useDialogContext();

  const { getBookEditionType } = useBookFormatter();

  useEffect(() => {
    trackViewSendListScren({
      themeName: selectedTheme.name,
      professor: professor,
      exam: exam,
      edition: selectedBookEdition.name,
      bookType: getBookEditionType(selectedBookEdition.id),
    });
  }, [
    exam,
    getBookEditionType,
    professor,
    selectedBookEdition.id,
    selectedBookEdition.name,
    selectedTheme.name,
    trackViewSendListScren,
  ]);

  const sendListRequest = useCallback(async () => {
    if (!file) return errorToast('Selecione um arquivo');
    setLoading(true);
    trackClickSendList({
      fileName: file.name,
      fileType: file.type,
      professor: professor,
      themeName: selectedTheme.name,
      edition: selectedBookEdition.name,
      bookType: getBookEditionType(selectedBookEdition.id),
      exam,
    });

    setStep('loading');

    try {
      const response = await sendPostFileRequest('/mvp/lists', file, {
        bookEditionId: selectedBookEdition?.id,
        professor,
        userName: user.name,
        isSubscriber: user.subscriber,
        exam,
        discipline: selectedDiscipline,
      });
      setListLink(response.data.link);
      if (response.data.link) {
        trackListAccepted({
          professor: professor.name,
          exam,
          themeName: selectedTheme?.name,
          bookName: selectedBookEdition?.amplitudeName,
          link: response.data.link,
          discipline: response.data.disciplineName,
        });
        if (!professor?.id) invalidateProfessorQuery();
        setStep(7);
      } else {
        setStep(6);
      }
    } catch (e) {
      trackError('Erro ao enviar lista');
      unsetDialog();
      errorToast('Alguma coisa deu errado, tente novamente mais tarde');
    } finally {
      setLoading(false);
    }
  }, [
    file,
    errorToast,
    setLoading,
    trackClickSendList,
    professor,
    selectedTheme,
    selectedBookEdition,
    getBookEditionType,
    exam,
    setStep,
    sendPostFileRequest,
    user,
    selectedDiscipline,
    setListLink,
    trackListAccepted,
    invalidateProfessorQuery,
    trackError,
    unsetDialog,
  ]);

  const getBookEditionName = useCallback(() => {
    if (!selectedBookEdition.amplitudeName) return null;
    selectedBookEdition.amplitudeName;
  }, [selectedBookEdition.amplitudeName]);

  return (
    <Container>
      <Title>{'Agora é a hora de enviar sua lista!'}</Title>
      {file ? (
        <FileContainer>
          <MdOutlineUploadFile color={colors.darkGray} />
          <p>{file.name}</p>
        </FileContainer>
      ) : (
        <>
          <UploadArea
            exam={exam}
            professor={professor}
            selectedTheme={selectedTheme}
            selectedBookEdition={selectedBookEdition}
            setFile={setFile}
          />
          <Warn>
            <p className="book">
              Lista do livro: <b>{getBookEditionName()}</b>{' '}
            </p>
            <p className="change-book" onClick={() => setStep(3)}>
              {' '}
              ( Quero trocar o livro/edição )
            </p>
          </Warn>
        </>
      )}
      <SendListButton onClick={sendListRequest}>
        {'Enviar Lista'}
      </SendListButton>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.7rem;
  user-select: none;
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  user-select: none;
  svg {
    font-size: 25px;
  }

  p {
    margin-left: 10px;
  }
`;

const Warn = styled.div`
  .book {
    color: #666;
  }
  .change-book {
    text-decoration: underline;
    margin-top: 3px;
    cursor: pointer;
  }
`;

const SendListButton = styled.button`
  width: 90%;
  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: white;
  margin-top: 1.5rem;
  padding: 10px 0px;
  border-radius: 15px;
  font-size: 1.1em;
  font-weight: bold;
  border: 1px solid rgb(204, 204, 204);

  &:hover {
    background-color: white;
    border-color: ${colors.mainOrange};
    color: ${colors.mainOrange};
  }
`;
