import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useToast } from 'modules/shared/hooks';
import { styled, colors, media } from 'styles';
import { MdOutlineUploadFile } from 'react-icons/md';

export function UploadArea({ setFile }) {
  const { errorToast } = useToast();

  const isFileValid = useCallback(
    async acceptedFiles => {
      if (acceptedFiles.length === 0) {
        errorToast('Você só pode fazer o upload dos formatos permitidos');
        return false;
      }

      if (acceptedFiles.length > 1) {
        errorToast('Você só pode fazer o upload de um único arquivo.');
        return false;
      }

      return true;
    },
    [errorToast]
  );

  const onDrop = useCallback(
    async acceptedFiles => {
      const isValid = await isFileValid(acceptedFiles);
      if (!isValid) return;

      const file = acceptedFiles[0];

      setFile(file);
    },
    [isFileValid, setFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
      'application/pdf': [],
    },
    onDrop,
  });

  return (
    <StyledUploadArea {...getRootProps()}>
      <Container>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="release-text">Solte!</p>
        ) : (
          <UploadContainer>
            <p> Solte aqui ou faça upload da sua lista </p>
            <MdOutlineUploadFile className="icon" />
          </UploadContainer>
        )}
      </Container>
    </StyledUploadArea>
  );
}

const StyledUploadArea = styled.div`
  width: 100%;
  height: 70px;
  .release-text {
    margin-top: 50px;
    color: ${colors.mainOrange};
    font-size: 20px;
  }

  @media ${media.mobile} {
    margin-bottom: 15px;
    min-height: initial;
    width: 80%;
    background: #f9faf9;
    display: initial;
  }
`;

const Container = styled.div``;

const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  height: 100%;
  height: 70px;

  p {
    font-size: 1.2em;
    color: black;
  }

  .icon {
    font-size: 1.5rem;
    color: black;
  }
`;
