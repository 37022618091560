import { useCallback } from 'react';

import { useRAPostRequest } from 'modules/shared/hooks/requests';

export default function useReactivateSubscription() {
  const reactivateSubscriptionReq = useRAPostRequest(
    'subscriptions/reactivate'
  );

  const subscriptionEligibleToReactivate = useCallback(subscription => {
    const order = subscription.order;
    if (!order) return false;

    return order.state === 'voided' && order.paymentMethod === 'credit_card';
  }, []);

  const autoOpenReactivateDialog = useCallback(
    (subscription, location) => {
      if (!subscriptionEligibleToReactivate(subscription)) return;

      const { search } = location;
      const searchParams = new URLSearchParams(search);

      return !!searchParams.get('reativar_renovacao');
    },
    [subscriptionEligibleToReactivate]
  );

  const reactivateSubscription = useCallback(
    async subscription => {
      try {
        await reactivateSubscriptionReq({
          subscriptionId: subscription.id,
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    [reactivateSubscriptionReq]
  );

  return {
    subscriptionEligibleToReactivate,
    reactivateSubscription,
    autoOpenReactivateDialog,
  };
}
