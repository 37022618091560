import React, { useMemo } from 'react';

import { colors } from 'styles';
import { ContentHeader } from 'modules/workspace/components/content/shared';
import {
  useWorkspaceListExerciseTracker,
  useWorkspaceListExerciseErrorReport,
} from 'modules/workspace/hooks/list-exercise';
import { useWorkspaceListExerciseContext } from 'modules/workspace/providers/WorkspaceListExerciseProvider';
import { useUserContext } from 'providers/UserProvider';

export default function ListExerciseContentHeader() {
  const {
    contentTitle,
    currentListCategory,
    currentList,
  } = useWorkspaceListExerciseContext();
  const { user } = useUserContext();
  const {
    trackShareContent,
    trackErrorReportClick,
  } = useWorkspaceListExerciseTracker();
  const { getErrorReportData } = useWorkspaceListExerciseErrorReport();

  const title = useMemo(() => {
    if (!currentListCategory) return '';
    if (!currentList) return `${currentListCategory.name}`;

    return `${currentListCategory.name} ${currentList.name}`;
  }, [currentListCategory, currentList]);

  const label = useMemo(() => {
    return `${user?.university?.acronym} - ${contentTitle}`;
  }, [user, contentTitle]);

  const whatsappMessage = useMemo(() => {
    const currentUrl = window.location;

    const encodedMessage = encodeURIComponent(
      `Opa, encontrei a ${title} da ${user?.university?.acronym} resolvida!. Se liga: ${currentUrl}?utm_source=share`
    );
    return encodedMessage;
  }, [title, user]);

  const iconsData = useMemo(() => {
    return {
      whatsapp: {
        message: whatsappMessage,
        trackMethod: trackShareContent,
      },
      errorReport: {
        data: getErrorReportData(),
        trackMethod: trackErrorReportClick,
      },
    };
  }, [
    whatsappMessage,
    trackShareContent,
    getErrorReportData,
    trackErrorReportClick,
  ]);

  return (
    <ContentHeader
      color={colors.listExercise}
      title={title}
      label={label}
      iconsData={iconsData}
    />
  );
}
