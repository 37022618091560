import _ from 'lodash';
import { useState, useCallback, useMemo } from 'react';
import useSwrQueryGenerator from './useSwrQueryGenerator';
import { convertObjectToUnderscore } from 'modules/shared/utils';
import Cookies from 'js-cookie';

const baseUrl = `${process.env.REACT_APP_RESPONDEAI_URL}/api/v3`;

export default function useRALazyQueryImmutable(url, swrConfig = {}) {
  const [makeQuery, setMakeQuery] = useState(false);
  const [queryParams, setQueryParams] = useState('');

  const updateQueryParams = useCallback(params => {
    const newQueryParams = new URLSearchParams(
      convertObjectToUnderscore(_.omitBy(params, _.isNil))
    ).toString();
    setQueryParams(newQueryParams);
  }, []);

  const query = useCallback(
    (params = {}) => {
      updateQueryParams(params);
      setMakeQuery(true);
    },
    [updateQueryParams]
  );

  const completeUrl = useMemo(() => {
    return `${baseUrl}${url}${queryParams && `?${queryParams}`}`;
  }, [queryParams, url]);

  const headers = useMemo(() => {
    const sessionToken = Cookies.get('auth_session_token');
    return {
      'Session-Token': sessionToken,
    };
  }, []);

  const swrResponse = useSwrQueryGenerator(
    makeQuery ? completeUrl : null,
    {
      ...swrConfig,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    },
    null,
    headers
  );

  return useMemo(() => {
    return [query, swrResponse];
  }, [query, swrResponse]);
}
