import React, { useCallback, useMemo, useEffect } from 'react';

import { styled, media, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker } from 'modules/shared/hooks/Tracker';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import { brokenHeart } from '../../images';
import CancelReasonDialog from './CancelReasonDialog';
import { BookTrialCancelDialog } from 'modules/campaigns/book-trial/components';

export default function HeartBreakCancelDialog({ subscription }) {
  const { trackEvent } = useTracker();
  const { unsetDialog, setDialog } = useDialogContext();
  const { hasCreditCardTrial } = useBookTrialCampaign();

  useEffect(() => {
    trackEvent('Fluxo de Cancelamento: Visualizou Coracao Partido', {
      'Tem desconto': subscription.hasDiscount ? 'Sim' : 'Nao',
    });
  }, [trackEvent, subscription]);

  const handleBackButtonPress = useCallback(
    e => {
      e.stopPropagation();
      unsetDialog();
    },
    [unsetDialog]
  );

  const handleQuestionButtonPress = useCallback(() => {
    window.UserVoice.show();
  }, []);

  const handleCancelButtonPress = useCallback(() => {
    if (hasCreditCardTrial()) {
      setDialog(<BookTrialCancelDialog subscription={subscription} />);
      return null;
    }

    setDialog(<CancelReasonDialog subscription={subscription} />);
  }, [subscription, hasCreditCardTrial, setDialog]);

  const cancelText = useMemo(() => {
    if (subscription.plan.months === 1) return 'Cancelar a Assinatura';
    else return 'Desativar a Renovação';
  }, [subscription]);

  const cancelCTAText = useMemo(() => {
    if (subscription.hasDiscount) {
      if (subscription.plan.months === 1)
        return 'Vai cancelar? Você perderá seu desconto!';
      else return 'Vai desativar a renovação? Você perderá seu desconto!';
    }

    if (subscription.plan.months === 1) return 'Vai cancelar sua assinatura?';
    else return 'Vai desativar a renovação?';
  }, [subscription]);

  const formattedDiscount = useMemo(() => {
    return `R$${parseFloat(subscription.lostDiscount).toFixed(2)}`;
  }, [subscription]);

  const renderDiscountInfo = useCallback(() => {
    const dinamycInfo = subscription.plan.months === 1 ? 'cancele' : 'desative';

    return (
      <>
        <CancelInfo>
          Atualmente você possui um desconto exclusivo no seu plano. Caso{' '}
          {dinamycInfo} sua assinatura, esse desconto será perdido para sempre!
        </CancelInfo>

        <Discount>Seu desconto hoje: {formattedDiscount} / ano</Discount>
      </>
    );
  }, [formattedDiscount, subscription]);

  const renderInstallmentsInfo = useCallback(() => {
    return (
      <CancelInfo>
        Ao cancelar você está cancelando a renovação automática, ou seja, ao
        final da sua assinatura vigente não será feita a renovação do seu plano.
        Caso você tenha um plano trimestral, semestral ou anual, ele não será
        interrrompido e você não ficará isento de pagar as parcelas restantes,
        caso existam.
      </CancelInfo>
    );
  }, []);

  return (
    <Container>
      <Content>
        <CancelStrip>{cancelText}</CancelStrip>
        <Image src={brokenHeart} alt="" />
        <CancelCTA>{cancelCTAText}</CancelCTA>

        {subscription.hasDiscount && renderDiscountInfo()}

        <CancelInfo>
          Esperamos que sua experiência na nossa plataforma tenha sido ótima até
          aqui. Trabalhamos duro para tornar a experiência do Responde Aí a mais
          incrível possível!
        </CancelInfo>

        <CancelInfo>
          E se isso não tiver acontecendo, é muito importante te ouvir para que
          a gente possa melhorar :)
        </CancelInfo>

        {subscription.plan.months > 1 &&
          !hasCreditCardTrial() &&
          renderInstallmentsInfo()}

        <CancelInfo>Tem certeza que quer cancelar?</CancelInfo>

        <ButtonsContainer>
          <button onClick={handleBackButtonPress} type="button">
            Voltar
          </button>

          <button onClick={handleQuestionButtonPress}>Tenho dúvidas</button>

          <button onClick={handleCancelButtonPress}>Quero cancelar</button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${media.desktop} {
    height: 100%;
  }
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-top: 10px solid ${colors.warningRed};
  text-align: left;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const CancelStrip = styled.div`
  display: inline-block;
  padding: 3px 10px;
  position: relative;
  left: -48px;
  margin-bottom: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${colors.warningRed};
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  @media ${media.mobile} {
    left: -24px;
  }
`;

const Image = styled.img`
  display: block;
  margin: auto;
  width: 120px;

  @media ${media.mobile} {
    width: 100px;
  }
`;

const CancelCTA = styled.h1`
  margin: 8px 0%;
  font-size: 1.3em;
  font-weight: bold;
  color: ${colors.warningRed};
  text-align: center;

  @media ${media.mobile} {
    font-size: 1.4em;
  }
`;

const CancelInfo = styled.p`
  margin: 25px 0;

  @media ${media.mobile} {
    font-size: 1.1em;
  }
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  button {
    flex: 1;
    margin: 0 10px;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    border: 2px solid #929292;
    color: #929292;
    text-transform: uppercase;
  }

  @media ${media.mobile} {
    flex-direction: column-reverse;

    button {
      width: 100%;
      margin: 5px 0;
      padding: 10px 0;
    }
  }
`;

const Discount = styled.div`
  width: 100%;
  font-size: 1.2em;
  margin: 25px 0;
  color: ${colors.warningRed};
  text-align: center;
  font-weight: bold;
`;
