import React from 'react';

const Breadcrumb = ({ items }) => {
  return (
    <p className="breadcrumb">
      {items.map((it, index) => {
        return [
          <a
            key={`breadcrumb_${it}_${index}`}
            href={it.link || '#'}
            style={{ color: it.color || '#bbb' }}
          >
            {it.label}
          </a>,
          index < items.length - 1 ? (
            <span key={`breadcrumb_gt_${it}_${index}`}>&gt;</span>
          ) : null,
        ];
      })}
    </p>
  );
};

export default Breadcrumb;
