import React, { useEffect } from 'react';
import { ContainerBanner } from './styles';
import { solvedBookImage } from 'modules/banner/images/main-banner/solved-book';
import { useSolvedBooksSharedFunctions } from '../sharedFunctions';

function SolvedBooks() {
  const { viewBannerEvent, onClickBanner } = useSolvedBooksSharedFunctions();

  useEffect(() => {
    viewBannerEvent();
  }, [viewBannerEvent]);

  return (
    <ContainerBanner onClick={onClickBanner}>
      <div className="tag">novidade!</div>
      <div className="main-content">
        <div className="banner-copy">
          <h1>
            NOVOS <span>LIVROS RESOLVIDOS</span>
          </h1>
          <h2>
            Livros do ciclo básico e profissional resolvidos passo a passo{' '}
            <br /> para você matar sua lista de exercícios!
          </h2>
        </div>
        <img src={solvedBookImage} alt="livros" />
      </div>
    </ContainerBanner>
  );
}

export default SolvedBooks;
