import { styled, colors, media } from 'styles';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
`;

export const Content = styled.div`
  width: 580px;
  max-width: 95%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
  border-top: 10px solid ${colors.mainOrange};
  padding: 20px;
  margin: auto;
`;

export const Title = styled.div`
  margin-bottom: 20px;
  font-size: 1.6em;
  font-weight: bold;
  color: ${({ color }) => color || colors.mainOrange};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 40px auto 0 auto;
`;

export const OptionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0 10px;
  border: 2px solid ${colors.darkGreen};
  color: ${colors.darkGreen};
  box-shadow: none;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
`;

export const PlanInfo = styled.div`
  font-size: 1.3em;
  font-weight: bold;
  text-transform: uppercase;
  margin: 5px 0;
  color: #000;
`;

export const InstallmentsInfo = styled.div`
  font-size: 1.1em;
  color: #000;
`;

export const Strong = styled.span`
  font-weight: bold;
`;

export const Message = styled.div`
  margin: 30px auto;
  font-size: 1.15em;
  width: 400px;
  max-width: 100%;
`;

export const ChoosePlan = styled.div`
  display: flex;
  width: 400px;
  max-width: 100%;
  margin: auto;

  b {
    margin-bottom: 10px;
  }
`;

export const PlanOption = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
  cursor: pointer;

  &.selected {
    background-color: rgba(249, 172, 62, 0.1);
  }

  &:last-child {
    .plan-strip {
      display: block !important;
    }
  }
`;

export const PlanDot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50px;
  border: 2px solid #666;
  background: #fff;

  &.selected {
    background: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
  }
`;

export const PlanName = styled.span`
  margin: 5px 0;
  font-size: 1.1em;
  font-weight: bold;
`;

export const PlanDescription = styled.span`
  line-height: 1.3;
  font-size: 0.9em;

  @media ${media.mobile} {
    font-size: 0.75em;
  }
`;

export const PlanStrip = styled.div`
  display: none;
  position: absolute;
  bottom: 8px;
  font-size: 0.65em;
  text-transform: uppercase;
  background-color: green;
  color: #fff;
  padding: 2px 5px;

  @media ${media.mobile} {
    bottom: 0;
  }
`;
