import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { addMonths } from 'date-fns';

import { styled, colors, media } from 'styles';
import { useUserContext } from 'providers/UserProvider';
import { useTracker, useDate, useUTM } from 'modules/shared/hooks';
import diCaprioGif from 'images/purchase/dicaprio.gif';
import infoIcon from 'images/purchase/iconeinfo.png';
import renewIcon from 'images/purchase/iconerenova.png';
import clockIcon from 'images/purchase/iconegarantia.png';
import calendarIcon from 'images/purchase/iconedata.png';

export default function SuccessDialog({ plan }) {
  const componentRef = useRef();

  const { user } = useUserContext();
  const { getHumanReadableDate } = useDate();
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  useEffect(() => {
    componentRef.current.scrollIntoView();
  }, []);

  useEffect(() => {
    trackEvent('Checkout Light: Sucesso Visualizou', {
      ...getUTMsByLocation(window.location),
      Plano: plan.description,
      Preco: plan.pricing.price,
    });
  }, [trackEvent, getUTMsByLocation, plan]);

  const renewDate = useMemo(() => {
    const now = new Date();
    const renewDate = addMonths(now, plan.months);
    return getHumanReadableDate(renewDate);
  }, [plan, getHumanReadableDate]);

  const profileUrl = useMemo(() => {
    return '/minha-conta';
  }, []);

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Container ref={componentRef}>
      <Content>
        <Gif src={diCaprioGif} />
        <Title>YEAH! Um brinde ao seu sucesso nas provas!</Title>
        <Text>Bem vindo ao Responde Aí!</Text>
        <Text>
          Seu email de acesso à plataforma é <b>{user.email}</b>e enviaremos uma
          mensagem nesse email cadastrado a sua confirmação de pagamento.
        </Text>
        <Text>Bons estudos :)</Text>

        <InfoContainer>
          <InfoIcon src={infoIcon} />
          <InfoContent>
            <InfoTitle>Informações importantes</InfoTitle>
            <InfoText>
              Todas as informações de plano também estão disponíveis em "
              <a href={profileUrl} target="_blank" rel="noopener noreferrer">
                Minha Conta
              </a>
              ", mas caso você tenha alguma outra dúvida, pode acessar o nosso
              FAQ ou enviar uma mensagem para o email: faleai@respondeai.com.br
            </InfoText>
          </InfoContent>
        </InfoContainer>

        <InfoContainer>
          <InfoIcon src={renewIcon} />
          <InfoContent>
            <InfoTitle>Renovação automática</InfoTitle>
            <InfoText>
              Gostou? Então nem precisa se preocupar! A renovação do seu plano é
              automática. :) <br />
              Você pode desativar sua renovação indo em "
              <a href={profileUrl} target="_blank" rel="noopener noreferrer">
                Minha Conta
              </a>
              " e seu plano fica liberado até o fim do plano contratado.
            </InfoText>
          </InfoContent>
        </InfoContainer>

        <InfoContainer>
          <InfoIcon src={clockIcon} />
          <InfoContent>
            <InfoTitle>Garantia de 7 dias</InfoTitle>
            <InfoText>
              Não gostou? Você tem 7 dias para testar e se for o caso, pedir o
              seu reembolso pra gente pelo faleai@respondeai.com.br!
            </InfoText>
          </InfoContent>
        </InfoContainer>

        <InfoContainer>
          <InfoIcon src={calendarIcon} />
          <InfoContent>
            <InfoTitle>Anota aí!</InfoTitle>
            <InfoText>
              Na data {renewDate} seu plano renovará automaticamente fazendo uma
              nova cobrança. Para mais informações, só{' '}
              <a href={profileUrl} target="_blank" rel="noopener noreferrer">
                clicar aqui
              </a>
              .
            </InfoText>
          </InfoContent>
        </InfoContainer>

        <FirstStepsTitle>Primeiros passos</FirstStepsTitle>
        <Video
          width="100%"
          height="360"
          src="http://www.youtube.com/embed/kFF3M8HYMBY"
          frameborder="0"
          allowfullscreen
        />
        <BeginToStudyButton onClick={refreshPage}>
          Começar a estudar
        </BeginToStudyButton>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  padding: 30px 30px 20px;
  background-color: #fff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const Gif = styled.img`
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.5em;
  margin: 10px 10px 20px 0;
  text-align: left;
`;

const Text = styled.div`
  margin-bottom: 20px;
  text-align: left;
`;

const InfoContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 20px 10px;
  font-size: 0.9em;

  @media ${media.mobile} {
    flex-direction: column;
  }
`;

const InfoIcon = styled.img`
  width: 50px;
  margin-right: 25px;

  @media ${media.mobile} {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoTitle = styled.h1`
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 7px;
  text-align: left;

  @media ${media.mobile} {
    text-align: center;
  }
`;

const InfoText = styled.div`
  text-align: left;
  line-height: 1.2;

  a {
    text-decoration: underline;
  }

  @media ${media.mobile} {
    text-align: center;
  }
`;

const FirstStepsTitle = styled.h1`
  font-size: 1.5em;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin: 10px 0 20px;
`;

const Video = styled.iframe`
  border: none;
`;

const BeginToStudyButton = styled.div`
  width: 100%;
  padding: 20px 0;
  margin: 20px 0;
  background-color: ${colors.mainOrange};
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
`;
