import React from 'react';
import { styled, colors } from 'styles';

export default function BookNewEditionsStrip() {
  return (
    <Container>
      <Triangle />
      <ContentText>Novas Edições!</ContentText>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  right: -10px;
  bottom: 60px;
`;

const Triangle = styled.span`
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-left: 10px solid ${colors.darkGray};
  border-bottom: 6px solid transparent;
  position: absolute;
  right: 0;
  top: -7px;
`;

const ContentText = styled.span`
  position: relative;
  z-index: 2;
  background-color: ${colors.lightPurple};
  padding: 3px 10px;
  font-size: 0.75em;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
`;
