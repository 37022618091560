import { useCallback } from 'react';
import { useCookies as useReactCookies } from 'react-cookie';

const DEFAULT_COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

function useCookies(cookiesToMonitore = []) {
  const [cookies, setCookie, removeCookie] = useReactCookies(cookiesToMonitore);

  const overwritedSetCookie = useCallback(
    (name, value, options = {}) => {
      const overwriteOptions = {
        ...options,
        path: options.path || '/',
        domain: options.domain || DEFAULT_COOKIE_DOMAIN,
      };
      setCookie(name, value, overwriteOptions);
    },
    [setCookie]
  );

  const overwritedRemoveCookie = useCallback(
    (name, options = {}) => {
      const overwriteOptions = {
        ...options,
        path: options.path || '/',
        domain: options.domain || DEFAULT_COOKIE_DOMAIN,
      };
      removeCookie(name, overwriteOptions);
    },
    [removeCookie]
  );

  return [cookies, overwritedSetCookie, overwritedRemoveCookie];
}

export { useCookies };
