import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import Header from './components/Header';
import { CenterContainer, MainContainer } from './styles';

function SelectIsOnUniversity({ onChange, isNFFOnLeft }) {
  const [isOnUniversity, setIsOnUniversity] = useState(null);

  const handleSetIsOnUniversity = useCallback(() => {
    if (isOnUniversity) setIsOnUniversity(null);
    else {
      setIsOnUniversity(true);
    }
  }, [isOnUniversity, setIsOnUniversity]);

  const handleSetIsNotOnUniversity = useCallback(() => {
    if (isOnUniversity || isOnUniversity === null) {
      setIsOnUniversity(false);
    } else setIsOnUniversity(null);
  }, [isOnUniversity, setIsOnUniversity]);

  useEffect(() => {
    if (isOnUniversity === null) return;
    onChange(isOnUniversity);
  }, [isOnUniversity, onChange]);

  const options = useMemo(() => {
    const optionsToShuffle = [
      {
        text: 'FAÇO FACULDADE',
        onClick: handleSetIsOnUniversity,
        selected: isOnUniversity,
      },
      {
        text: 'NÃO FAÇO FACULDADE',
        onClick: handleSetIsNotOnUniversity,
        selected: isOnUniversity === false,
      },
    ];

    if (isNFFOnLeft) {
      const isOnUniversityOption = optionsToShuffle.shift();
      optionsToShuffle.push(isOnUniversityOption);
    }

    return optionsToShuffle;
  }, [
    handleSetIsOnUniversity,
    handleSetIsNotOnUniversity,
    isOnUniversity,
    isNFFOnLeft,
  ]);

  return (
    <MainContainer>
      <Header copy="" />

      <CenterContainer>
        <FormContainer>
          <h2>Você está fazendo faculdade?</h2>
          <ButtonContainer>
            {options?.map(op => {
              return (
                <Button
                  onClick={op.onClick}
                  selected={op.selected}
                  key={op.text}
                >
                  {op.text}
                </Button>
              );
            })}
          </ButtonContainer>
        </FormContainer>
      </CenterContainer>
    </MainContainer>
  );
}

const FormContainer = styled.div`
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media ${media.mobile} {
    flex-direction: column;
  }
  h2 {
    font-weight: bold;
    font-size: 16px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  width: 40%;
  padding: 15px;
  background: ${({ selected }) => (selected ? '#eee' : '#fff')};
  border: 1px solid #f6ac3a;
  border-radius: 10px;

  font-weight: bold;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
`;

export default SelectIsOnUniversity;
