import { useCallback } from 'react';
import { isFuture } from 'date-fns';

function useFormValidate() {
  const validPhone = useCallback(phone => {
    const phoneRegex = /(\([0-9]{2}\) [0-9]{5}-[0-9]{4})|([0-9]{11})/;
    return phone && phone.match(phoneRegex);
  }, []);

  const validName = useCallback(name => {
    return name && name.length > 2;
  }, []);

  const validCpf = useCallback(cpf => {
    if (!cpf) return false;

    let sum = 0;
    let rest;
    cpf = cpf.replaceAll(/(\.)|-/gi, '');

    if (cpf === '00000000000') return false;
    for (let i = 1; i <= 9; i++)
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++)
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpf.substring(10, 11))) return false;
    return true;
  }, []);

  const validPostalCode = useCallback(postalCode => {
    const postalCodeRegex = /[0-9]{5}-[0-9]{3}/;
    return postalCode && postalCode.match(postalCodeRegex);
  }, []);

  const validCreditCardHolderName = useCallback(holderName => {
    return holderName && holderName.length > 2 && holderName.length < 65;
  }, []);

  const validCreditCardNumber = useCallback(number => {
    if (!number) return false;

    const numberWithouSpace = number.replaceAll(/(\.)|-/gi, '');
    return numberWithouSpace.length >= 13 && numberWithouSpace.length <= 19;
  }, []);

  const validCreditCardValidateDate = useCallback(dateString => {
    if (!dateString || dateString === '') return false;

    const validateMonth = parseInt(dateString.split('/')[0]);
    if (!validateMonth) return false;

    let validateYear = dateString.split('/')[1];
    if (!validateYear) return false;

    if (validateYear.length === 2) {
      validateYear = parseInt(`20${validateYear}`);
    } else {
      validateYear = parseInt(validateYear);
    }
    const date = new Date(validateYear, validateMonth, 0);

    return validateMonth >= 1 && validateMonth <= 12 && isFuture(date);
  }, []);

  const validCreditCardCvv = useCallback(cvv => {
    return cvv.length >= 3 && cvv.length <= 4;
  }, []);

  const getCreditCardErrors = useCallback(
    creditCard => {
      const number = validCreditCardNumber(creditCard.number)
        ? ''
        : 'Número de cartão inválido.';
      const holderName = validCreditCardHolderName(creditCard.holderName)
        ? ''
        : 'Nome inválido.';
      const expiry = validCreditCardValidateDate(creditCard.expiry)
        ? ''
        : 'Data inválida.';
      const cvv = validCreditCardCvv(creditCard.cvv)
        ? ''
        : 'Código de segurança inválido.';

      return { number, holderName, expiry, cvv };
    },
    [
      validCreditCardNumber,
      validCreditCardHolderName,
      validCreditCardValidateDate,
      validCreditCardCvv,
    ]
  );

  return {
    validPhone,
    validName,
    validCpf,
    validPostalCode,
    validCreditCardHolderName,
    validCreditCardNumber,
    validCreditCardValidateDate,
    validCreditCardCvv,
    getCreditCardErrors,
  };
}

export { useFormValidate };
