import { useCallback } from 'react';
import { useRAQueryImmutable } from 'modules/shared/hooks/requests';

export default function useFreezeSubscriptionCampaign() {
  const { data: freezeSubscriptionData } = useRAQueryImmutable(
    '/campaigns/freeze_subscription'
  );

  const isSubscriptionFreezed = useCallback(() => {
    return freezeSubscriptionData?.freezed;
  }, [freezeSubscriptionData]);

  const isSubscriptionReactivated = useCallback(() => {
    return freezeSubscriptionData?.reactivated;
  }, [freezeSubscriptionData]);

  const getFreezedUntilDateInTimestamp = useCallback(() => {
    return freezeSubscriptionData?.freezedUntil;
  }, [freezeSubscriptionData]);

  const getPriceAfterFreeze = useCallback(
    subscription => {
      if (!freezeSubscriptionData) return '';

      const planMonths = subscription.plan.months;
      const newPrice = freezeSubscriptionData.priceAfterFreeze;
      if (planMonths === 1) {
        return `R$${newPrice}/mes`;
      }

      const pricePerMonth = (newPrice / planMonths).toFixed(2);
      return `${planMonths}xR$${pricePerMonth}`;
    },
    [freezeSubscriptionData]
  );

  const isSubscriptionEligibleToFreeze = useCallback(() => {
    return freezeSubscriptionData?.eligibleToFreeze;
  }, [freezeSubscriptionData]);

  return {
    isSubscriptionFreezed,
    isSubscriptionReactivated,
    getFreezedUntilDateInTimestamp,
    getPriceAfterFreeze,
    isSubscriptionEligibleToFreeze,
  };
}
