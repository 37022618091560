import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { SubjectCard } from 'modules/learning-path/components';
import { Loading } from 'modules/shared/components/generic';
import { useLearningPathTracker } from 'modules/learning-path/hooks';

const SubjectStep = ({ goFoward, learningPaths }) => {
  const { trackSubjectDialogView } = useLearningPathTracker();
  useEffect(() => {
    trackSubjectDialogView();
  }, [trackSubjectDialogView]);

  const getUniqueSubjectNames = useCallback(() => {
    const order = [
      'Limites',
      'Derivadas',
      'Aplicação de Derivadas',
      'Integrais',
      'Aplicação de Integrais',
      'Funções de Várias Variáveis',
      'Integrais Duplas',
      'Intregrais Triplas',
      'Integrais de Linha',
      'Integrais de Superfície',
      'Eletricidade',
      'Magnetismo',
    ];

    const uniqueNames = learningPaths.filter(lp => lp.days === 1);

    const sortedUniqueNames = uniqueNames.sort((a, b) => {
      return order.indexOf(a.name) - order.indexOf(b.name);
    });

    return sortedUniqueNames;
  }, [learningPaths]);

  if (!learningPaths) return <Loading />;

  return (
    <Content onClick={event => event.stopPropagation()}>
      <Header>
        <StepIndicator>Passo 1 de 3</StepIndicator>
      </Header>
      <Title>Qual matéria você quer estudar?</Title>
      <CardsContainer>
        {getUniqueSubjectNames().map((lp, i) => {
          return (
            <SubjectCard
              first={i % 3 === 0 ? true : false}
              key={i}
              onSelect={goFoward}
              themeName={lp.theme}
              subjectName={lp.name}
            />
          );
        })}
      </CardsContainer>
    </Content>
  );
};

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Content = styled.div`
  z-index: 9999;
  width: 95%;
  max-width: 700px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #00000040;
  padding: 40px 20px 75px 20px;
`;

const Title = styled.p`
  font-size: 30px;
  margin: 35px 0;
  font-weight: 700;
  color: #f7ac3b;
`;

const StepIndicator = styled.div`
  color: #888;
  font-size: 20px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export default SubjectStep;
