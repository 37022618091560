import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { colors } from 'styles';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';

export default function useWorkspace() {
  const {
    theoryId,
    exerciseId,
    listExerciseId,
    summaryId,
    chapterId,
  } = useParams();
  const {
    chapters,
    currentUniversityExercises,
    currentExtraExercises,
  } = useWorkspaceContentContext();

  const isTheoryWorkspace = useCallback(() => {
    return !!theoryId;
  }, [theoryId]);

  const isExtraTimePageWorkspace = useCallback(() => {
    return !!chapterId && !!listExerciseId;
  }, [chapterId, listExerciseId]);

  const isFixationExerciseWorkspace = useCallback(() => {
    return !!exerciseId;
  }, [exerciseId]);

  const isUniversityExerciseWorkspace = useCallback(() => {
    if (!listExerciseId) return false;

    return !!currentUniversityExercises.find(
      exercise => parseInt(exercise.id) === parseInt(listExerciseId)
    );
  }, [listExerciseId, currentUniversityExercises]);

  const isExtraExerciseWorkspace = useCallback(() => {
    if (!listExerciseId) return false;

    return !!currentExtraExercises.find(
      exercise => parseInt(exercise.id) === parseInt(listExerciseId)
    );
  }, [listExerciseId, currentExtraExercises]);

  const isSummaryWorkspace = useCallback(() => {
    return !!summaryId;
  }, [summaryId]);

  const isFirstTopic = useCallback((chapter, topic) => {
    if (!chapter || !topic) return false;

    const firstTopic = chapter.topics[0];
    return firstTopic.id === topic.id;
  }, []);

  const isLastTopic = useCallback((chapter, topic) => {
    const lastTopicIndex = chapter.topics.length - 1;
    const lastTopic = chapter.topics[lastTopicIndex];
    return lastTopic.id === topic.id;
  }, []);

  const chapterHasSummary = useCallback(chapter => {
    const summaries = chapter.topics
      .map(topic => topic.summary)
      .filter(summary => !!summary);
    return summaries.length > 0;
  }, []);

  const getSummariesFromChapter = useCallback(chapter => {
    const summaries = chapter.topics
      .map(topic => topic.summary)
      .filter(summary => summary?.id);

    return summaries;
  }, []);

  const getChapterIndex = useCallback(
    chapter => {
      const chaptersIds = chapters.map(c => c.id);
      return chaptersIds.indexOf(chapter.id);
    },
    [chapters]
  );

  const currentcontentData = useMemo(() => {
    return {
      theory: {
        amplitudeContentType: 'Teoria',
        viewEventContentType: 'teoria',
        color: colors.theory,
      },
      fixationExercise: {
        amplitudeContentType: 'Exercício Fixação',
        viewEventContentType: 'exercicio',
        color: colors.exercise,
      },
      univeristyExercise: {
        amplitudeContentType: 'Exercício Lista',
        viewEventContentType: 'exercicio-lista',
        color: colors.listExercise,
      },
      extraExercise: {
        amplitudeContentType: 'Exercício Lista',
        viewEventContentType: 'exercicio-lista',
        color: colors.extraExercise,
      },
      summary: {
        color: colors.theory,
        amplitudeContentType: 'Resuminho',
        viewEventContentType: 'resuminho',
      },
    };
  }, []);

  const getCurrentContentData = useCallback(
    key => {
      if (isTheoryWorkspace()) return currentcontentData.theory[key];
      if (isFixationExerciseWorkspace())
        return currentcontentData.fixationExercise[key];
      if (isUniversityExerciseWorkspace())
        return currentcontentData.univeristyExercise[key];
      if (isExtraExerciseWorkspace() || isExtraTimePageWorkspace())
        return currentcontentData.extraExercise[key];
      if (isSummaryWorkspace()) return currentcontentData.summary[key];
    },
    [
      isTheoryWorkspace,
      isFixationExerciseWorkspace,
      isUniversityExerciseWorkspace,
      isExtraExerciseWorkspace,
      currentcontentData,
      isExtraTimePageWorkspace,
      isSummaryWorkspace,
    ]
  );

  return {
    isFirstTopic,
    isLastTopic,
    chapterHasSummary,
    getChapterIndex,
    isTheoryWorkspace,
    isExtraTimePageWorkspace,
    isFixationExerciseWorkspace,
    isUniversityExerciseWorkspace,
    isExtraExerciseWorkspace,
    isSummaryWorkspace,
    getCurrentContentData,
    getSummariesFromChapter,
  };
}
