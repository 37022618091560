/* eslint-disable camelcase */
// TODO verificar a busca com "typos"
export function extractAllTextBetweenQuotes(str) {
  const re = /"(.*?)"/g;
  const result = [];
  let current;

  while ((current = re.exec(str))) {
    result.push(`"${current.pop()}"`);
  }

  result.map(it => (str = str.replace(`${it}`, '')));
  result.push(str.trim());

  return result.length > 0 ? result : [str];
}

export function sortByScore(data) {
  return data.sort((a, b) => {
    if (a.score === b.score) return 0;
    return a.score > b.score ? -1 : 1;
  });
}

export function removePrepositions(term) {
  // remove prepositions from term
  term.replace(/\b(of|the|and|or|in|to|for|with|on|at|from|by|as|a|an)\b/g, '');
  // remove brazilian prepositions from term
  term.replace(
    /\b(de|da|do|das|dos|em|no|na|nos|para|pelo|pela|pelos|pelas|com|per|por)\b/g,
    ''
  );

  return term;
}

export function highlightObject(keys, tags = ['<b>', '</b>']) {
  const highlightFields = {};
  keys.map(it => {
    highlightFields[it.name] = {};
  });

  return {
    highlight: {
      number_of_fragments: 1,
      order: 'score',
      fragment_size: 63,
      pre_tags: [tags[0]],
      post_tags: [tags[1]],
      fields: highlightFields,
    },
  };
}

export function prepareQuery(originalterm, keys, themeId) {
  const terms = extractAllTextBetweenQuotes(originalterm);

  const mustTerms = terms.filter(
    it => it[0] === '"' && it[it.length - 1] === '"'
  );

  const shouldTerms = terms.filter(it => mustTerms.indexOf(it) === -1);
  // join should terms in a string with space
  const shouldTerm = shouldTerms.join(' ');

  const must = [];

  if (themeId) {
    must.push({
      match: { id: themeId },
    });
  }

  if (mustTerms.length > 0) {
    const mustShould = [];

    mustTerms.map(term => {
      term = term.replace(/"/g, '');

      keys.map(it => {
        const key = it.name;

        const match_phrase = {};
        match_phrase[key] = term;

        mustShould.push({
          match_phrase: match_phrase,
        });
      });
    });

    must.push({ bool: { should: mustShould } });
  }
  const should = [
    {
      bool: {
        must: [
          {
            multi_match: {
              query: shouldTerm,
              fields: keys.map(it => `${it.name}^${it.weight}`),
            },
          },
        ],
      },
    },
  ];

  if (themeId) {
    should[0].bool.must.push({
      match: { id: themeId },
    });
  }

  const query = {
    bool: {
      should,
    },
  };

  if (must.length > 0) query.bool.must = must;

  keys.map(it => {
    const item = {
      match_phrase: {},
    };

    item.match_phrase[it.name] = {
      query: originalterm.replace(/"/g, ''),
      slop: 10,
      boost: 10,
    };

    query.bool.should.push(item);
  });

  keys.map(it => {
    const item = {
      match_phrase_prefix: {},
    };

    item.match_phrase_prefix[it.name] = {
      query: originalterm.replace(/"/g, ''),
      slop: 10,
    };

    query.bool.should.push(item);
  });

  console.log(query);
  return query;
}
