import React, { createContext, useContext, useState, useEffect } from 'react';

import { useRAQuery } from 'modules/shared/hooks/requests';

export const WorkspaceFlagsContext = createContext(null);

const WorkspaceFlagsProvider = props => {
  const [flags, setFlags] = useState([]);
  const flagsQuery = useRAQuery('/flags');

  useEffect(() => {
    if (flagsQuery.loading || flagsQuery.error) return;

    const currentFlags = flagsQuery.data?.flags;
    currentFlags && setFlags(currentFlags);
  }, [flagsQuery]);

  return (
    <WorkspaceFlagsContext.Provider value={{ flags, setFlags }} {...props} />
  );
};

export const useWorkspaceFlagsContext = () => useContext(WorkspaceFlagsContext);

export default WorkspaceFlagsProvider;
