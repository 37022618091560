import { useCallback, useMemo } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { isStar, isTop50 } from 'providers/UniversityProvider';

import {
  useRAPostRequest,
  useRAQueryImmutable,
} from 'modules/shared/hooks/requests';
import { useCookies, useGTM } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';

export default function useProfileComplete() {
  const { addVariableToGTMDataLayer } = useGTM();
  const [cookies, setCookie] = useCookies(['justSignUp']);
  const sendCompleteProfile = useRAPostRequest('profile/complete_profile');
  const { data: userTerms, loading: userTermsLoading } = useRAQueryImmutable(
    '/profile/accepted_terms'
  );
  const { user } = useUserContext();
  const location = useLocation();

  const isAlreadyOnProfileCompletePage = useCallback(() => {
    return location.pathname.indexOf('profile-complete') !== -1;
  }, [location.pathname]);

  const userDontNeedSendCourseInfo = useMemo(() => true, []);

  const userCameFromPlaybook = useCallback(() => {
    return Cookies.get('came_from_pb') == 'true';
  }, []);

  const shouldRedirectToProfileComplete = useCallback(() => {
    if (userTermsLoading || !userTerms) return false;

    let shouldRedirectToProfileComplete =
      user && (!user.university || !userTerms?.acceptedTerms);

    if (location.pathname.includes('/lista-de-enunciado')) {
      shouldRedirectToProfileComplete = false;
    }
    const shouldRedirectToCourseInfoDialog =
      !shouldRedirectToProfileComplete &&
      user &&
      (!user?.course || !user?.yearAdmission || !user?.periodAdmission) &&
      user?.university?.name !== 'Não faço faculdade' &&
      !userDontNeedSendCourseInfo &&
      !userCameFromPlaybook();
    return (
      (shouldRedirectToProfileComplete || shouldRedirectToCourseInfoDialog) &&
      !isAlreadyOnProfileCompletePage()
    );
  }, [
    userTermsLoading,
    userTerms,
    user,
    location,
    userDontNeedSendCourseInfo,
    userCameFromPlaybook,
    isAlreadyOnProfileCompletePage,
  ]);

  const updateGtm = useCallback(
    async (university, campusId = '') => {
      addVariableToGTMDataLayer({
        iesAcronym: university?.acronym,
        iesId: university?.id,
        isStar: isStar(university?.id),
        isTop50: isTop50(university?.id),
      });

      const event = {
        event: 'completou_cadastro',
        iesID: university?.id,
        iesCampusID: campusId,
      };

      addVariableToGTMDataLayer(event);
    },
    [addVariableToGTMDataLayer]
  );

  const submitProfileComplete = useCallback(
    async (params, selectedUniversity) => {
      const res = { data: null, error: null };
      try {
        const { data } = await sendCompleteProfile(params);
        res.data = data;
        setCookie('justSignUp', cookies.justSignUp + 2);

        const universityCampusId = params?.university_campus_id;

        if (data.signup) updateGtm(selectedUniversity, universityCampusId);
      } catch (error) {
        res.error = error;
      }

      return res;
    },
    [updateGtm, setCookie, sendCompleteProfile, cookies]
  );

  const shouldUserAcceptTerms = useMemo(() => {
    if (userTermsLoading || !userTerms) return false;
    return !userTerms?.acceptedTerms;
  }, [userTermsLoading, userTerms]);

  const isUserOnUniversity = useMemo(() => {
    return (
      user?.university?.name && user?.university?.name !== 'Não faço faculdade'
    );
  }, [user]);

  const isUserNotOnUniversity = useMemo(() => {
    return user?.university?.name === 'Não faço faculdade';
  }, [user]);

  const getRedirectUrl = useCallback(() => {
    const query = new URLSearchParams(location.search);

    let data = {};

    for (let params of query.entries()) {
      data[params[0]] = params[1];
    }

    return data.redirect ? data.redirect : '/';
  }, [location.search]);

  const sendToCorrectUrl = useCallback(() => {
    window.location.href = getRedirectUrl();
  }, [getRedirectUrl]);

  return {
    shouldRedirectToProfileComplete,
    isAlreadyOnProfileCompletePage,
    submitProfileComplete,
    shouldUserAcceptTerms,
    isUserOnUniversity,
    isUserNotOnUniversity,
    sendToCorrectUrl,
    getRedirectUrl,
    userDontNeedSendCourseInfo,
  };
}
