import { useCallback } from 'react';
import { useUserContext } from 'providers/UserProvider';
import _ from 'lodash';
import { useBookPermission } from './useBookPermission';

const useBookExercisePermission = edition => {
  const { user } = useUserContext();
  const {
    shouldBooksFirstChaptersBeFree,
    isBookAccessible,
  } = useBookPermission();

  const getExercisesFromFirstChapter = useCallback(() => {
    if (!edition) return [];

    const sections = edition.chapters[0].sections;
    const questions = _.flatten(sections.map(section => section.questions));
    const exercises = questions.map(q => q.exercise?.id);
    return exercises;
  }, [edition]);

  const isExerciseFromFirstChapter = useCallback(
    exerciseId => {
      const exercisesFromFirstChapter = getExercisesFromFirstChapter();
      return exercisesFromFirstChapter.includes(exerciseId);
    },
    [getExercisesFromFirstChapter]
  );

  const isFreemiumSection = useCallback(
    sectionId => {
      if (!shouldBooksFirstChaptersBeFree) return false;

      const firstChapterSections = edition.chapters[0].sections;
      const firstChapterSectionsIds = firstChapterSections.map(
        section => section.id
      );

      return firstChapterSectionsIds.includes(sectionId);
    },
    [edition, shouldBooksFirstChaptersBeFree]
  );

  const canViewBookExercise = useCallback(
    exerciseId => {
      const chapterPermission =
        shouldBooksFirstChaptersBeFree &&
        isExerciseFromFirstChapter(exerciseId);
      const bookPermision = isBookAccessible(edition);
      const userPermission = user?.hasAccess;

      return chapterPermission || bookPermision || userPermission;
    },
    [
      user,
      isBookAccessible,
      shouldBooksFirstChaptersBeFree,
      isExerciseFromFirstChapter,
      edition,
    ]
  );

  return {
    canViewBookExercise,
    isBookAccessible,
    isExerciseFromFirstChapter,
    isFreemiumSection,
  };
};

export { useBookExercisePermission };
