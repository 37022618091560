import React, { useCallback, useEffect, useState } from 'react';
import { styled, media } from 'styles';
import { MdClose } from 'react-icons/md';
import { useDialogContext } from 'providers/DialogProvider';
import ThemesMenuFromStudyPlanDialog from '../components/themes-page/theme/ThemesMenuFromStudyPlanDialog';
import { landscape } from '../images';
import { SubjectCardFromStudyPlan } from '../components/themes-page/subject';
import SaveButtonFromStudyPlan from '../components/themes-page/buttons/SaveButtonFromStudyPlan';

export default function BuildStudyPlanDialog({ theme, studyPlanToEdit }) {
  const { unsetDialog } = useDialogContext();
  const [isThemesMenuOpen, setIsThemesMenuOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const emptyStudyPlan = {
    themeId: selectedTheme.id,
    subjectsIds: [],
  };

  const [studyPlan, setStudyPlan] = useState(studyPlanToEdit || emptyStudyPlan);

  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) unsetDialog();
    };
    window.addEventListener('keyup', close);

    return () => window.removeEventListener('keyup', close);
  }, [unsetDialog]);

  const getSubjectPosition = useCallback(
    subject => {
      return studyPlan.subjectsIds.indexOf(subject.id) + 1;
    },
    [studyPlan]
  );

  return (
    <Container>
      <img src={landscape} className="background" alt="paisagem" />
      <MdClose className="close-icon" onClick={unsetDialog} />
      <Title>
        <h1> Que assuntos devem cair na sua prova? </h1>
        <h3> Relaxa, você pode alterar isso depois</h3>
      </Title>
      <StepsContainer>
        <span
          className={isThemesMenuOpen ? 'fill' : ''}
          onClick={() => {
            setIsThemesMenuOpen(true);
            setStudyPlan(emptyStudyPlan);
          }}
        ></span>
        <span className={!isThemesMenuOpen ? 'fill' : ''}></span>
      </StepsContainer>
      <ThemesMenuFromStudyPlanDialog
        selectedTheme={selectedTheme}
        setSelectedTheme={setSelectedTheme}
        isThemesMenuOpen={isThemesMenuOpen}
        setIsThemesMenuOpen={setIsThemesMenuOpen}
        resetStudyPlan={setStudyPlan}
      />
      {!isThemesMenuOpen && (
        <SubjectCardContainer>
          {selectedTheme.subjects.map(s => (
            <SubjectCardFromStudyPlan
              key={s.id}
              subject={s}
              color={selectedTheme.color}
              studyPlan={studyPlan}
              setStudyPlan={setStudyPlan}
              position={getSubjectPosition(s)}
            />
          ))}
        </SubjectCardContainer>
      )}
      <SaveButtonFromStudyPlan
        studyPlan={studyPlan}
        isEdit={!!studyPlanToEdit}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 50px;
  background-color: #fff;

  .background {
    position: fixed;
    left: -100px;
    bottom: 0;
    opacity: 0.8;
    z-index: 0;
  }

  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.7rem;
    color: #888;
    cursor: pointer;
    z-index: 3;

    &:hover {
      transform: scale(1.3);
      color: #555;
      transition: color 180ms ease-in-out;
      transition: transform 180ms ease-in-out;
    }
  }
`;

const Title = styled.div`
  color: #888;
  font-weight: 100;
  text-align: center;

  h1 {
    font-size: 2.5em;

    @media ${media.mobile} {
      font-size: 2em;
    }
  }

  h3 {
    font-size: 1.1em;
    margin-top: 10px;
    line-height: 20px;

    @media ${media.mobile} {
      font-size: 1em;
    }
  }
`;

const StepsContainer = styled.div`
  text-align: center;
  margin: 40px 0;

  span {
    width: 30px;
    height: 10px;
    display: inline-block;
    background: #fff;
    transition: background 300ms ease-in-out;
    width: 30px;
    height: 10px;
    border: 1px solid #f9ac3e;
    border-radius: 5px;
    margin: 0 3px;
  }

  .fill {
    background: #f9ac3e;
  }
`;

const SubjectCardContainer = styled.div`
  max-width: 960px;
  margin: 40px auto 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 100px;

  @media ${media.mobile} {
    width: 300px;
    justify-content: space-between;
  }
`;
