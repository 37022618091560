import React, { useCallback, useEffect, useState } from 'react';
import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';

import { styled } from 'styles';
import { useQuestions, useTrackEvents } from 'modules/mvp/hooks';
import { ExerciseFeedback } from '../shared';
import { useUserContext } from 'providers/UserProvider';

export function QuestionWithResponse({
  question,
  setCheckSearchResult,
  refetchList,
  list,
}) {
  const [showResolution, setShowResolution] = useState(false);
  const { trackViewQuestion, trackClickToSeeSolutionButton } = useTrackEvents();
  const { user } = useUserContext();
  const { incrementFreeViewQuestion } = useQuestions();

  useEffect(() => {
    if (!question) return;

    trackViewQuestion(question, question.mvpListWithStatement);
  }, [question, trackViewQuestion]);

  const toggleShowResolution = useCallback(async () => {
    const shouldSentViewFreeResolution = !showResolution && !user?.hasAccess;
    if (shouldSentViewFreeResolution) {
      await incrementFreeViewQuestion(question.id, refetchList);
    }

    if (!showResolution) trackClickToSeeSolutionButton(list, question);

    setShowResolution(!showResolution);
  }, [
    incrementFreeViewQuestion,
    list,
    question,
    refetchList,
    showResolution,
    trackClickToSeeSolutionButton,
    user,
  ]);

  const showAnswerButtonCopy = useCallback(() => {
    return showResolution ? 'Esconder resolução' : 'Ver resolução';
  }, [showResolution]);

  return (
    <Container>
      <StatementTitle>Questão {question.label} da Lista</StatementTitle>
      <Statement text={question.exercise.lightBody} />
      <ShowAnserButton onClick={toggleShowResolution}>
        {showAnswerButtonCopy()}
      </ShowAnserButton>
      {!showResolution && (
        <NotMyQuestionButton onClick={() => setCheckSearchResult(true)}>
          Não parece a questão da minha lista
        </NotMyQuestionButton>
      )}

      <SolutionContainer className={showResolution ? 'show' : 'hide'}>
        <SolutionContent>
          {question.exercise.lightSolution.map((solution, index) => (
            <Step key={index}>
              <StepTitle>Passo {index + 1}</StepTitle>
              <Solution text={solution} />
            </Step>
          ))}

          {showResolution && (
            <ExerciseFeedback question={question} origin="fim_da_resolucao" />
          )}

          {question.exercise.lightAnswer && (
            <>
              <AnswerTitle>Resposta</AnswerTitle>
              <AnswerContent>
                <Answer text={question.exercise.lightAnswer} />
              </AnswerContent>
            </>
          )}
        </SolutionContent>
      </SolutionContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  max-width: 100%;
  margin: auto;
  padding-top: 20px;
`;

const StatementTitle = styled.div`
  width: 100%;
  color: #f7ac3d;
  font-weight: bold;
  font-size: 1.6em;
  text-decoration: underline;
`;

const Statement = styled(MathjaxContentWithLatex)`
  color: #5d5d5d;
  margin-top: 15px;
`;

const ShowAnserButton = styled.button`
  width: 280px;
  max-width: 95%;
  padding: 15px 0;
  margin: 10px auto 0 auto;
  background-color: #f7ac3d;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 40px;
`;

const SolutionContainer = styled.div`
  max-width: 100%;

  &.show {
    display: flex;
  }

  &.hide {
    display: none;
  }
`;

const SolutionContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  font-size: 1.1em;
`;

const Step = styled.div`
  margin-bottom: 30px;
`;

const StepTitle = styled.h1`
  width: 100%;
  color: #f7ac3d;
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 20px;

  &:first-child {
    margin-top: 15px;
  }
`;

const Solution = styled(MathjaxContentWithLatex)`
  color: #5d5d5d;
  margin-top: 10px;
`;

const AnswerTitle = styled.h1`
  width: 100%;
  color: #f7ac3d;
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 5px;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
`;

const AnswerContent = styled.div`
  border-left: 4px solid #f7ac3d;
  padding: 20px;
  font-size: 1.2em;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
`;

const Answer = styled(MathjaxContentWithLatex)`
  margin: 30px 0px;
`;

const NotMyQuestionButton = styled.div`
  text-decoration: underline;
  margin: auto;
  margin-top: 15px;
  color: #555;
  cursor: pointer;
`;
