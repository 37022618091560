import { useTracker, useUTM } from 'modules/shared/hooks';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useCallback, useMemo } from 'react';
import { media } from 'styles';

export function useMvpBooksTracker() {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();
  const sendListSent = useRAPostRequest(`campaigns/crm_journey/book_list`);

  const defaultProperties = useMemo(() => {
    return {
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Versão: '2.3',
      ...getUTMsByLocation(window.location),
    };
  }, [getUTMsByLocation]);

  const trackViewFormScreen = useCallback(
    (origin, type) => {
      trackEvent('MVP Lista: Visualizou Tela Formulário', {
        ...defaultProperties,
        Origem: origin,
        Tipo: type,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackViewEditionFormScreen = useCallback(
    ({ theme, professor, exam }) => {
      trackEvent('MVP Lista: Visualizou Tela Escolher Livro/Edição', {
        ...defaultProperties,
        Professor: professor?.name,
        Matéria: theme,
        Prova: exam,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackViewFormatScren = useCallback(
    ({ themeName, professor, exam, edition, bookType }) => {
      trackEvent('MVP Lista: Visualizou Tela Formato Lista', {
        ...defaultProperties,
        Matéria: themeName,
        Professor: professor?.name,
        Prova: exam,
        Edição: edition,
        'Tipo de livro': bookType,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackViewSendListScren = useCallback(
    ({ themeName, professor, exam, edition, bookType }) => {
      trackEvent('MVP Lista: Visualizou Tela Enviar Lista', {
        ...defaultProperties,
        Matéria: themeName,
        Professor: professor?.name,
        Prova: exam,
        Edição: edition,
        'Tipo de livro': bookType,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackUploadFile = useCallback(
    ({ fileName, fileType, professor, exam, bookType, edition, themeName }) => {
      trackEvent('MVP Lista: Fez upload de arquivo', {
        ...defaultProperties,
        'Nome Arquivo': fileName,
        'Tipo de Arquivo': fileType,
        Professor: professor?.name,
        Prova: exam,
        'Tipo de livro': bookType,
        Edição: edition,
        Matéria: themeName,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackClickSendList = useCallback(
    ({ fileName, fileType, professor, exam, bookType, edition, themeName }) => {
      trackEvent('MVP Lista: Clicou Enviar Lista', {
        ...defaultProperties,
        'Nome Arquivo': fileName,
        'Tipo de Arquivo': fileType,
        'Tipo de livro': bookType,
        Professor: professor?.name,
        Prova: exam,
        Edição: edition,
        Matéria: themeName,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackViewThankYouScren = useCallback(
    ({ listLink, theme, professor, exam, book, edition, bookType }) => {
      trackEvent('MVP Lista: Visualizou Tela Obrigado', {
        ...defaultProperties,
        'Gerou Link': listLink ? 'Sim' : 'Não',
        Link: listLink,
        Materia: theme,
        Professor: professor?.name,
        Prova: exam,
        Livro: book,
        Edição: edition,
        'Tipo de livro': bookType,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackCloseDialog = useCallback(
    origin => {
      trackEvent('MVP Lista: Clicou Fechar Dialog', {
        ...defaultProperties,
        Tela: origin,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackError = useCallback(
    error => {
      trackEvent('MVP Lista: Erro Input da lista', {
        ...defaultProperties,
        Erro: error,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackClickList = useCallback(
    ({ listId, professor, origin }) => {
      trackEvent('MVP Lista: Clicou Lista', {
        ...defaultProperties,
        'ID Lista': listId,
        Professor: professor?.name,
        Origem: origin,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackViewListTypeScreen = useCallback(
    origin => {
      trackEvent('MVP Lista: Visualizou Tela Tipo de Lista', {
        ...defaultProperties,
        Origem: origin,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackClickListType = useCallback(
    ({ type }) => {
      trackEvent('MVP Lista: Clicou Tipo de Lista', {
        Tipo: type,
        ...defaultProperties,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackViewManualInputListScreen = useCallback(
    ({ theme, professor, exam, book, edition, statement }) => {
      const event = statement
        ? 'MVP Lista: Visualizou Tela Não Resolvemos Enunciado'
        : 'MVP Lista: Visualizou tela de upload de texto pós lista recusada';

      trackEvent(event, {
        ...defaultProperties,
        Materia: theme,
        Professor: professor?.name,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackClickChangeBook = useCallback(
    ({ theme, professor, exam, book, edition, statement }) => {
      trackEvent('MVP Lista: Clicou alterar Livro', {
        Tela: `${statement ? 'Lista Enunciado' : 'Lista de seção'}`,
        ...defaultProperties,
        Materia: theme,
        Professor: professor?.name,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackClickAddSection = useCallback(
    ({ theme, professor, exam, book, edition, statement }) => {
      trackEvent('MVP Lista: Clicou Adicionar Seção', {
        Tela: `${statement ? 'Lista Enunciado' : 'Lista de seção'}`,
        ...defaultProperties,
        Materia: theme,
        Professor: professor?.name,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackClickRemoveSection = useCallback(
    ({ theme, professor, exam, book, edition, statement }) => {
      trackEvent('MVP Lista: Clicou Excluir Seção', {
        Tela: `${statement ? 'Lista Enunciado' : 'Lista de seção'}`,
        ...defaultProperties,
        Materia: theme,
        Professor: professor?.name,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackUploadListViaTextInput = useCallback(
    ({ theme, professor, exam, book, edition, statement }) => {
      trackEvent('MVP Lista: Fez upload de Lista via Texto', {
        Tela: `${statement ? 'Lista Enunciado' : 'Lista de seção'}`,
        ...defaultProperties,
        Materia: theme,
        Professor: professor?.name,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackClickConfirmLink = useCallback(
    ({ theme, professor, exam, book, edition }) => {
      trackEvent('MVP Lista: Clicou em Confirmar Livro', {
        ...defaultProperties,
        Materia: theme,
        Professor: professor?.name,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackListAccepted = useCallback(
    async ({ professor, exam, themeName, bookName, link, discipline }) => {
      try {
        await sendListSent({
          professor,
          exam,
          themeName,
          bookName,
          link,
          discipline,
        });
        // eslint-disable-next-line no-empty
      } catch {}
    },
    [sendListSent]
  );

  const trackThemeSelected = useCallback(
    ({ theme }) => {
      trackEvent('MVP Lista: Selecionou Matéria', {
        ...defaultProperties,
        Materia: theme,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackDisciplineSelected = useCallback(
    ({ theme, isDefault, disciplineName, disciplineId }) => {
      trackEvent('MVP Lista: Selecionou Disciplina', {
        ...defaultProperties,
        Materia: theme,
        'Disciplina Default?': isDefault,
        Disciplina: disciplineName,
        'ID Disciplina': disciplineId,
      });
    },
    [defaultProperties, trackEvent]
  );
  const trackProfessorSelected = useCallback(
    ({
      theme,
      disciplineName,
      disciplineId,
      newProfessor,
      professorName,
      professorId,
    }) => {
      trackEvent('MVP Lista: Selecionou Professor', {
        ...defaultProperties,
        Materia: theme,
        Disciplina: disciplineName,
        'ID Disciplina': disciplineId,
        'Criou Professor?': newProfessor,
        'Nome Professor': professorName,
        'ID Professor': professorId,
      });
    },
    [defaultProperties, trackEvent]
  );
  const trackExamSelected = useCallback(
    ({
      theme,
      disciplineName,
      disciplineId,
      professorName,
      professorId,
      exam,
    }) => {
      trackEvent('MVP Lista: Selecionou Prova', {
        ...defaultProperties,
        Materia: theme,
        Disciplina: disciplineName,
        'ID Disciplina': disciplineId,
        Professor: professorName,
        'ID Professor': professorId,
        Prova: exam,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackClickFavoriteBookList = useCallback(
    ({ isFavorite, listId, origin }) => {
      trackEvent('MVP Lista: Clicou Favoritar', {
        'ID da Lista': listId,
        ...defaultProperties,
        Favorito: isFavorite ? 'Sim' : 'Nao',
        Local: origin,
      });
    },
    [trackEvent, defaultProperties]
  );

  return {
    trackViewFormScreen,
    trackViewFormatScren,
    trackViewSendListScren,
    trackUploadFile,
    trackClickSendList,
    trackViewThankYouScren,
    trackCloseDialog,
    trackError,
    trackClickList,
    trackViewEditionFormScreen,
    trackListAccepted,
    trackViewListTypeScreen,
    trackClickListType,
    trackViewManualInputListScreen,
    trackClickChangeBook,
    trackClickAddSection,
    trackClickRemoveSection,
    trackUploadListViaTextInput,
    trackClickConfirmLink,
    trackExamSelected,
    trackDisciplineSelected,
    trackThemeSelected,
    trackProfessorSelected,
    trackClickFavoriteBookList,
  };
}
