import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { useRAQuery } from 'modules/shared/hooks/requests';
import {
  UniversitySelectorContainer,
  selectCustomStyles,
  selectCustomComponents,
} from '../styles';
import { useUserContext } from 'providers/UserProvider';
import useUniversitySelectSearch from 'modules/profile/hooks/useUniversitySearch';

export default function UniversitySelects({
  onChangeSelectedUniversity,
  onChangeSelectedCampus,
  onChangeCampi,
  setLastInput = () => {
    return;
  },
  noUniversitySelected,
  updatedUniversity = null,
}) {
  const userContext = useUserContext();
  const universitiesReq = useRAQuery('/universities/get_universities_filtered');
  const [loadingUniversities, setLoadingUniversities] = useState(false);

  const [universitiesFiltered, setUniversitiesFiltered] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState(null);

  const [campi, setCampi] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const { getFirst100UniversitiesInSearch } = useUniversitySelectSearch();

  useEffect(() => {
    if (noUniversitySelected) {
      setSelectedUniversity(null);
      setSelectedCampus(null);
    }
  }, [noUniversitySelected]);

  useEffect(() => {
    onChangeSelectedUniversity(selectedUniversity);
  }, [onChangeSelectedUniversity, selectedUniversity]);

  useEffect(() => {
    onChangeSelectedCampus(selectedCampus);
  }, [onChangeSelectedCampus, selectedCampus]);

  useEffect(() => {
    onChangeCampi(campi);
  }, [onChangeCampi, campi]);

  const onChangeUniversity = useCallback(
    e => {
      if (!e) {
        setSelectedUniversity(null);
        setSelectedCampus(null);
        setCampi([]);
        return;
      }

      const university = universitiesFiltered.find(
        it => parseInt(it?.id) === parseInt(e.value)
      );

      if (!university) return;

      setSelectedUniversity(university);
      setSelectedCampus(null);

      setCampi(
        (university?.campi || []).map(it => {
          return { value: it.id, label: it.name, ...it };
        })
      );
    },
    [universitiesFiltered]
  );

  useEffect(() => {
    if (!universities.length) return;

    if (updatedUniversity) {
      const userUniversity = universities.find(
        it => it?.id.toString() === updatedUniversity.id?.toString()
      );
      setSelectedUniversity(userUniversity);
    } else {
      const userUniversity = universities.find(
        it => it.id.toString() === userContext?.user?.university?.id?.toString()
      );

      let tempArray = universities.slice(0, 10);

      if (userUniversity) tempArray = [userUniversity, ...tempArray];

      setUniversitiesFiltered(tempArray);

      if (userUniversity?.name === 'Não faço faculdade') return;
      setSelectedUniversity(userUniversity);
    }
  }, [universities, userContext, updatedUniversity]);

  useEffect(() => {
    if (!selectedUniversity) return;
    onChangeUniversity(selectedUniversity);
  }, [selectedUniversity, onChangeUniversity]);

  useEffect(() => {
    if (!campi.length) return;

    const userCampus = campi.find(
      it =>
        it.id.toString() === userContext?.user?.universityCampus?.id?.toString()
    );

    if (userCampus) setSelectedCampus(userCampus);
  }, [campi, userContext]);

  const onChangeCampus = useCallback(
    e => {
      if (!e) {
        setSelectedCampus(null);
        return;
      }

      const campus = campi.find(it => parseInt(it.id) === parseInt(e.value));

      setSelectedCampus(campus);
    },
    [campi]
  );

  useEffect(() => {
    const { data, loading } = universitiesReq;

    setLoadingUniversities(loading);
    if (!data) return;

    let { universities } = data;
    let arrUniversities = universities.map(it => {
      return { value: it.id, label: it.name, ...it };
    });

    arrUniversities = arrUniversities.filter(
      (university, index, self) =>
        index ===
        self.findIndex(
          t => t.label.toLowerCase() === university.label.toLowerCase()
        )
    );

    setUniversities(arrUniversities);
  }, [universitiesReq]);

  const onInputChangeUniversity = useCallback(
    input => {
      if (input !== '') {
        setLastInput(input);
      }
      setInputValue(input);

      const universitiesFiltered = getFirst100UniversitiesInSearch(
        universities,
        input
      );
      setUniversitiesFiltered(universitiesFiltered);
    },
    [universities, setLastInput, getFirst100UniversitiesInSearch]
  );

  return (
    <>
      <UniversitySelectorContainer data-cy="universities-select">
        <Select
          placeholder={
            loadingUniversities
              ? 'Carregando lista de faculdades...'
              : 'Digite o nome ou a sigla da sua faculdade...'
          }
          options={universitiesFiltered}
          isClearable={true}
          onInputChange={onInputChangeUniversity}
          onChange={onChangeUniversity}
          value={selectedUniversity}
          components={selectCustomComponents}
          styles={selectCustomStyles}
          menuIsOpen={inputValue.length >= 1}
          filterOption={() => true}
          isDisabled={universitiesFiltered.length === 0}
        />
      </UniversitySelectorContainer>

      <UniversitySelectorContainer data-cy="universities-campus-select">
        {campi.length > 0 && (
          <Select
            placeholder="Selecione o campus..."
            options={campi}
            isClearable={true}
            onChange={onChangeCampus}
            value={selectedCampus}
            components={selectCustomComponents}
            styles={selectCustomStyles}
          />
        )}
      </UniversitySelectorContainer>
    </>
  );
}
