import React, { memo, useCallback, useEffect, useState } from 'react';
import { media, fonts, styled } from 'styles';
import { Loading } from 'modules/shared/components/generic';
import useHtmlReplacement from 'modules/shared/hooks/useHtmlReplacement';
import { useMathJax } from 'modules/shared/hooks/useMathJax';

function MathjaxContent({
  html,
  dataCy,
  replaceToFakeContent = false,
  forceBlur = false,
}) {
  const [rendered, setRendered] = useState(false);
  const { replaceToFakeHtml } = useHtmlReplacement();

  const getHtmlToRender = useCallback(() => {
    if (replaceToFakeContent) return replaceToFakeHtml(html);

    return html;
  }, [replaceToFakeContent, replaceToFakeHtml, html]);

  return (
    <Container data-cy={dataCy}>
      {!rendered && <CustomLoading />}
      <MathJaxContainer className={rendered && !forceBlur ? 'rendered' : ''}>
        <Html
          html={getHtmlToRender()}
          onFinishRender={() => setRendered(true)}
        />
      </MathJaxContainer>
    </Container>
  );
}

function Html({ html, onFinishRender }) {
  const [MathJax, updateMathContent] = useMathJax();

  useEffect(() => {
    updateMathContent(onFinishRender);
  }, [MathJax, updateMathContent, onFinishRender]);

  if (!MathJax) return null;

  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
}

export default memo(MathjaxContent);

const Container = styled.div`
  position: relative;
  margin: 30px 0;

  h1,
  h2,
  p {
    margin: 0;
    padding: 0;
  }

  * {
    user-select: none !important;
    -webkit-user-select: none !important;
    /* Disable selection/copy in UIWebView */

    font-style: normal;
  }

  h2:empty {
    display: none;
  }

  img {
    max-width: 804px;
    margin: 0 auto;
    display: block;
  }

  p,
  h1,
  h3,
  li {
    font-family: ${fonts.serif};
    font-weight: 400;
  }

  ul,
  ol {
    margin-left: 15px;
    padding-left: 26px;
  }

  h1,
  b {
    color: #444;
  }

  b {
    font-weight: 700;
  }

  h1 {
    font-size: 1.7em;
    margin-top: 35px;
    line-height: 33px;
  }

  h2 {
    font-size: 1.55em;
    margin-top: 10px;
    line-height: 31px;
    padding-bottom: 30px;
  }

  p,
  li {
    font-size: 1.25em;
    line-height: 26px;
    color: #444;
    padding-bottom: 15px;

    @media ${media.mobile} {
      font-size: 1.1em;
    }
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 1.5em;
    margin-left: 1.3em;
  }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
  }

  ul li {
    list-style-type: disc;
  }

  p {
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

const CustomLoading = styled(Loading)`
  position: absolute;
  min-height: unset;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const MathJaxContainer = styled.div`
  filter: blur(4px);

  &.rendered {
    filter: none;
  }
`;
