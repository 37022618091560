import { styled, colors, media } from 'styles';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 50px;
  margin-top: 25px;
  color: ${colors.successGreen};
  border: 2px solid ${colors.successGreen};
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  @media ${media.mobile} {
    font-size: 0.7em;
  }
`;
