import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';

export const LectureContext = createContext(null);

const LectureProvider = ({ lectureData, children }) => {
  const [playing, setPlaying] = useState(false);
  const [neverPlayedLecture, setNeverPlayedLecture] = useState(true);
  const [blocked, setBlocked] = useState(false);
  const [videoSpeed, setVideoSpeed] = useState(1);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const [bulletHoverPosition, setBulletHoverPosition] = useState(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [currentVideoHoverIndex, setCurrentVideoHoverIndex] = useState(null);
  const [modulesAlreadyPlayed, setModulesAlreadyPlayed] = useState([]);
  const [errorReportInputIsOpen, setErrorReportInputIsOpen] = useState(false);

  const currentModuleIndex = useMemo(() => {
    if (currentVideoHoverIndex !== null) return currentVideoHoverIndex;
    return currentVideoIndex;
  }, [currentVideoHoverIndex, currentVideoIndex]);

  const currentModule = useMemo(() => {
    return lectureData?.lectureModules[currentModuleIndex];
  }, [currentModuleIndex, lectureData]);

  const totalDuration = useMemo(() => {
    return lectureData?.duration;
  }, [lectureData]);

  const lecturesList = useMemo(() => {
    const lectures = lectureData?.lectureModules;
    const enrichedLectureList = lectures.map(lecture => {
      return {
        ...lecture,
        proportionalSize: parseFloat(
          lecture?.video?.duration / totalDuration
        ).toFixed(2),
      };
    });
    return enrichedLectureList;
  }, [lectureData, totalDuration]);

  const getFullProgressSeconds = useCallback(() => {
    const previousVideosDuration = lecturesList.reduce((acc, module, idx) => {
      if (idx < currentModuleIndex) {
        return acc + module.video.duration;
      }
      return acc;
    }, 0);
    return previousVideosDuration + progressSeconds;
  }, [currentModuleIndex, lecturesList, progressSeconds]);

  const getHoverProgressSeconds = useCallback(() => {
    if (currentVideoHoverIndex === null) return 0;
    const previousVideosDuration = lecturesList.reduce((acc, module, idx) => {
      if (idx < currentVideoHoverIndex) {
        return acc + module.video.duration;
      }
      return acc;
    }, 0);
    return (
      previousVideosDuration +
      lecturesList[currentVideoHoverIndex].video.duration * bulletHoverPosition
    );
  }, [currentVideoHoverIndex, lecturesList, bulletHoverPosition]);

  const onVolumeBarClick = useCallback(
    (e, volumeBarRef) => {
      const rect = volumeBarRef.current.getBoundingClientRect();
      const newHeight = parseInt(rect.bottom) - parseInt(e.clientY);
      const maxHeight = parseInt(volumeBarRef.current.offsetHeight);
      let newVolume = parseFloat((newHeight / maxHeight).toFixed(2));
      if (newVolume > 1) newVolume = 1;
      if (newVolume < 0) newVolume = 0;
      if (muted) setMuted(false);
      setVolume(newVolume);
    },
    [setVolume, muted]
  );

  const onProgressBarHover = useCallback(
    (e, progressBarRef) => {
      const rect = progressBarRef.getBoundingClientRect();
      const maxWidth = parseInt(progressBarRef.offsetWidth);
      const newWidth = parseInt(e.clientX) - parseInt(rect.left);
      let mousePosition = parseFloat((newWidth / maxWidth).toFixed(2));
      if (mousePosition > 1) mousePosition = 1;
      if (mousePosition < 0) mousePosition = 0;
      setBulletHoverPosition(mousePosition);
    },
    [setBulletHoverPosition]
  );

  const onVideoProgressClick = useCallback(
    (e, clickedVideoIndex, progressBarRef, seekTo) => {
      const rect = progressBarRef.getBoundingClientRect();
      const maxWidth = parseInt(progressBarRef.offsetWidth);
      const newWidth = parseInt(e.clientX) - parseInt(rect.left);
      let mousePosition = parseFloat((newWidth / maxWidth).toFixed(2));
      if (mousePosition > 1) mousePosition = 1;
      if (mousePosition < 0) mousePosition = 0;
      setProgressPercentage(mousePosition);
      seekTo(mousePosition, clickedVideoIndex);
      setCurrentVideoIndex(clickedVideoIndex);
    },
    []
  );

  const onMouseEnterModule = useCallback(index => {
    setCurrentVideoHoverIndex(index);
  }, []);
  const restartCurrentHoverModule = useCallback(() => {
    setCurrentVideoHoverIndex(null);
  }, []);

  const getPlayingByIndex = useCallback(
    index => {
      if (index !== currentVideoIndex) return false;
      return playing;
    },
    [playing, currentVideoIndex]
  );

  const getVideoUrlByIndex = useCallback(
    index => {
      const providerId = lecturesList[index].video.providerId;
      return `https://www.youtube.com/embed/${providerId}?modestbranding=0&rel=0`;
    },
    [lecturesList]
  );

  const toggleMuted = useCallback(() => {
    setMuted(!muted);
  }, [muted]);

  const getWidthFromIndex = useCallback(
    videoIndex => {
      if (videoIndex < currentVideoIndex) return 1;
      if (videoIndex === currentVideoIndex) return progressPercentage;
      return 0;
    },
    [currentVideoIndex, progressPercentage]
  );

  const lectureName = useMemo(() => {
    return `AULÃO DE ${lectureData?.subject?.name}`;
  }, [lectureData]);

  return (
    <LectureContext.Provider
      value={{
        playing,
        getPlayingByIndex,
        setPlaying,
        videoSpeed,
        setVideoSpeed,
        progressPercentage,
        setProgressPercentage,
        progressSeconds,
        setProgressSeconds,
        volume,
        setVolume,
        muted,
        setMuted,
        currentVideoIndex,
        bulletHoverPosition,
        setBulletHoverPosition,
        onVolumeBarClick,
        onProgressBarHover,
        getVideoUrlByIndex,
        toggleMuted,
        lectureName,
        lecturesList,
        setCurrentVideoIndex,
        getWidthFromIndex,
        onVideoProgressClick,
        currentVideoHoverIndex,
        onMouseEnterModule,
        restartCurrentHoverModule,
        currentModuleIndex,
        currentModule,
        lectureData,
        getFullProgressSeconds,
        getHoverProgressSeconds,
        blocked,
        setBlocked,
        neverPlayedLecture,
        setNeverPlayedLecture,
        modulesAlreadyPlayed,
        setModulesAlreadyPlayed,
        errorReportInputIsOpen,
        setErrorReportInputIsOpen,
      }}
    >
      {children}
    </LectureContext.Provider>
  );
};

export const useLectureContext = () => useContext(LectureContext);

export default LectureProvider;
