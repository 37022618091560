/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { styled } from 'styles';
import reduceImage from '../shared/reduceImage';

export default function PastableContainer({ children, setFile, setDropError }) {
  // without it the onDrop function will not work
  useDropzone();

  const validateFiles = useCallback(
    files => {
      if (files.length > 1) {
        setDropError && setDropError('Você só pode enviar um arquivo.');
        return false;
      }

      const file = files[0];
      if (file.size > 10000000) {
        setDropError && setDropError('O arquivo deve ter no máximo 10MB.');
        return false;
      }

      if (!file.type.startsWith('image/')) {
        setDropError && setDropError('O arquivo deve ser uma imagem.');
        return false;
      }

      return true;
    },
    [setDropError]
  );

  const resizeAndSetFile = useCallback(
    file => {
      reduceImage(file).then(setFile);
    },
    [setFile]
  );

  const onDrop = useCallback(
    e => {
      const files = e.dataTransfer.files;
      if (!validateFiles(files)) return;
      e.preventDefault();

      resizeAndSetFile(files[0]);
    },
    [resizeAndSetFile, validateFiles]
  );

  const onPaste = useCallback(
    e => {
      const files = e.clipboardData.files;
      if (!files.length) return;
      if (!validateFiles(files)) return;

      e.preventDefault();
      e.stopPropagation();

      resizeAndSetFile(files[0]);
    },
    [resizeAndSetFile, validateFiles]
  );

  return (
    <Container onDrop={onDrop} onPaste={onPaste}>
      {children}
    </Container>
  );
}

// try to avoid any kind of styling here
const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;
