import { useCallback } from 'react';
import { useCookies, useUTM } from 'modules/shared/hooks';

export default function useFreeMonth() {
  const [cookies, setCookie] = useCookies(['free_month_elegible']);
  const { getUTMsByLocation } = useUTM();

  const onEligibleFreeMonthCampaginPageEnter = useCallback(() => {
    const utms = getUTMsByLocation(window.location);
    if (
      utms['MKT: UTM Source'] === 'facebook-ads' ||
      utms['MKT: UTM Campaign'] === 'listas-faculdades'
    ) {
      setCookie('free_month_elegible', 'true');
    }
  }, [setCookie, getUTMsByLocation]);

  const isUserElegibleForFreeMonth = useCallback(
    () => !!cookies.free_month_elegible,
    [cookies]
  );

  return { onEligibleFreeMonthCampaginPageEnter, isUserElegibleForFreeMonth };
}
