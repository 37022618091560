import ElasticSearchBookExercises from './elastic-search-book-exercises';
import ElasticSearchExercises from './elastic-search-exercises';
import ElasticSearchTopics from './elastic-search-topics';
import { execAPICall } from './requests';
import { highlightObject } from './utils';

export default class ElasticSearchAll {
  static async searchTheories(term, themeId, universityId) {
    const tQuery = ElasticSearchTopics.getSearchQuery(term, themeId);

    const highlightTheory = [
      ...Object.keys(tQuery.highlight.fields),
    ].map(it => ({ name: it }));

    const theoryQuery = {
      _source: [...new Set([...tQuery._source])],

      query: {
        bool: {
          should: [tQuery.query],
        },
      },
      size: 20,
      ...highlightObject(highlightTheory),
      indices_boost: [{ ra_theories: term.length > 35 ? 1.0 : 1.2 }],
    };

    const data = await execAPICall(theoryQuery, 'ra_theories');
    const theories = await ElasticSearchTopics.adjustResults(
      data,
      universityId
    );

    return theories;
  }

  static async searchExercises(term, themeId, universityId) {
    const eQuery = ElasticSearchExercises.getSearchQuery(term, themeId);
    const beQuery = ElasticSearchBookExercises.getSearchQuery(term, themeId);

    const highlightExercises = [
      ...Object.keys(eQuery.highlight.fields),
      ...Object.keys(beQuery.highlight.fields),
    ].map(it => ({ name: it }));

    const exercisesQuery = {
      _source: [...new Set([...eQuery._source, ...beQuery._source])],

      query: {
        bool: {
          should: [eQuery.query, beQuery.query],
        },
      },
      size: 20,
      ...highlightObject(highlightExercises),
      indices_boost: [
        { ra_book_exercises: term.length > 35 ? 1.2 : 1.0 },
        { ra_exercises: term.length > 35 ? 1.2 : 1.0 },
      ],
    };

    const data = await execAPICall(
      exercisesQuery,
      'ra_book_exercises,ra_exercises'
    );

    const exercises = await ElasticSearchExercises.adjustResults(
      data,
      universityId
    );

    const bookExercises = await ElasticSearchBookExercises.adjustResults(
      data,
      universityId
    );

    return { exercises, bookExercises };
  }

  static async searchAll(term, themeId, universityId) {
    const theories = await ElasticSearchAll.searchTheories(
      term,
      themeId,
      universityId
    );

    const { exercises, bookExercises } = await ElasticSearchAll.searchExercises(
      term,
      themeId,
      universityId
    );

    return { topics: theories, exercises, bookExercises };
  }
}
