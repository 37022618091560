import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { SideMenuItems } from '../shared';
import ChapterTitle from './ChapterTitle';
import TopicLink from './TopicLink';
import ChapterExtraButtons from './ChapterExtraButtons';
import { useWorkspaceSideMenuChapters } from 'modules/workspace/hooks/main';

export default function Chapter({ chapter, open, position, onChapterClick }) {
  const { topicId, guideId } = useParams();
  const {
    showExtraExerciseButton,
    showExtraSummaryButton,
  } = useWorkspaceSideMenuChapters();

  const hasExtraContent = useCallback(
    chapter => {
      return (
        showExtraExerciseButton(chapter) || showExtraSummaryButton(chapter)
      );
    },
    [showExtraExerciseButton, showExtraSummaryButton]
  );

  const renderTitle = useCallback(() => {
    return (
      <ChapterTitle
        title={`${position + 1} ${chapter.name}`}
        chapter={chapter}
        open={open}
      />
    );
  }, [chapter, position, open]);

  const renderItems = useCallback(() => {
    return (
      <>
        {chapter.topics.map(topic => (
          <div key={topic.id}>
            <TopicLink
              current={
                parseInt(topic.id) === parseInt(topicId) ? 'current' : ''
              }
              open={open}
              topic={topic}
              guideId={guideId}
              chapter={chapter}
            />
          </div>
        ))}

        {open && hasExtraContent(chapter) && (
          <ChapterExtraButtons chapter={chapter} />
        )}
      </>
    );
  }, [chapter, guideId, topicId, open, hasExtraContent]);

  return (
    <SideMenuItems
      open={open}
      tooltip={true}
      onClick={() => onChapterClick(chapter)}
      spaceOnBottom={hasExtraContent(chapter)}
      renderTitleComponent={renderTitle}
      renderItems={renderItems}
    />
  );
}
