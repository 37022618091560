import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Loading, MathjaxContent } from 'modules/shared/components/generic';
import { Container, InlineBackContainer, MdBlock } from './styles';
import { useTracker, useEscClick } from 'modules/shared/hooks';
import { media } from 'styles';
import SearchFieldDesktop from './SearchFieldDesktop';
import SearchFieldMobile from './SearchFieldMobile';
import Cookies from 'js-cookie';
import { prepareResults } from './helper';
import TypeSelector from './TypeSelector';
import SearchDialogResults from './SearchDialogResults';
import { ExecSearch } from 'apis/search/Search';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchEvents from './SearchEvents';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useDialogContext } from 'providers/DialogProvider';
import MathJaxProvider from 'providers/MathJaxProvider';

const resultsType = {
  ALL: 'all',
  THEORIES: 'theories',
  EXERCISES: 'exercises',
};

export default function SearchDialog({
  where,
  theme,
  onSearch,
  onClickResult,
  onCloseDialog,
  initialTerm = '',
  color = '#f2b138',
  university,
  inline = false,
}) {
  const ref = useRef(null);
  const { trackEvent } = useTracker();

  const [showFeedback, setShowFeedback] = useState(false);
  const { unsetDialog } = useDialogContext();
  const [results, setResults] = useState([]);
  const [term, setTerm] = useState(initialTerm);
  const [resultType, setResultType] = useState(resultsType.ALL);
  const [onlyVideos, setOnlyVideos] = useState(false);
  const [loading, setLoading] = useState(false);

  const logSearchDataReq = useRAPostRequest('search/log_search_data');
  const logSearchResultsReq = useRAPostRequest('search/log_search_results');

  const closeDialog = useCallback(() => {
    new SearchEvents({
      elasticTracker: logSearchDataReq,
    }).closeDialog(term, theme);
    unsetDialog();
    if (onCloseDialog) onCloseDialog();
  }, [logSearchDataReq, onCloseDialog, term, theme, unsetDialog]);

  useEscClick(ref, closeDialog);

  useEffect(() => {
    if (initialTerm) {
      setTerm(initialTerm);
    }
  }, [initialTerm]);

  useEffect(() => {
    const open = localStorage.getItem('search_open_quantity') || '0';
    const newOpen = parseInt(open) + 1;
    localStorage.setItem('search_open_quantity', newOpen.toString());

    const alreadyVoted = localStorage.getItem('search_already_voted');

    setShowFeedback(alreadyVoted !== 'true' && newOpen % 3 === 0);
  }, []);

  // useEffect(() => {
  //   if ((result?.variables?.data || '').indexOf('closed_search') > -1) {
  //     onSearch('');
  //     unsetDialog();
  //   }
  // }, [onSearch, result?.variables?.data, unsetDialog]);

  const timer = useRef(null);

  const setupCookieForBookExercise = useCallback(
    result => {
      let in30seconds = new Date(new Date().getTime() + 30 * 1000);
      Cookies.set(
        'books_result',
        JSON.stringify({
          bookExerciseId: result?.book?.edition?.chapter?.section?.exercise?.id,
          searchTerm: term,
        }),
        { expires: in30seconds }
      );
    },
    [term]
  );

  const clickedInResult = useCallback(
    (it, index) => {
      if (it.type.toLowerCase() === 'bookexercise')
        setupCookieForBookExercise(it);

      onClickResult(term);
      new SearchEvents({
        amplitudeTracker: trackEvent,
        elasticTracker: logSearchDataReq,
      }).clickInResult(term, index, resultType, theme, where, it, inline);
    },
    [
      setupCookieForBookExercise,
      onClickResult,
      term,
      trackEvent,
      logSearchDataReq,
      resultType,
      theme,
      where,
      inline,
    ]
  );

  useEffect(() => {
    new SearchEvents({
      amplitudeTracker: trackEvent,
    }).openDialog(where, inline);
  }, [where, trackEvent, inline]);

  useEffect(() => {
    if (!term || !term.trim()) return;
    clearTimeout(timer.current);

    timer.current = setTimeout(async () => {
      setLoading(true);
      const trimmed = term.trim().replace(/ +(?= )/g, '');
      onSearch(trimmed);

      new SearchEvents({
        amplitudeTracker: trackEvent,
      }).typeOnSearch(trimmed, where, inline);

      const data = await ExecSearch(trimmed, theme?.id, university?.id || '');

      const parsed = JSON.parse(data);
      const time = parsed.time;

      let all = prepareResults(parsed, term);

      setResults(all);

      setLoading(false);

      new SearchEvents({
        amplitudeTracker: trackEvent,
        elasticTracker: logSearchDataReq,
        elasticResultsTracker: logSearchResultsReq,
      }).search(term, theme, where, all, parsed, time, inline);
    }, 500);
  }, [
    term,
    onSearch,
    trackEvent,
    logSearchDataReq,
    theme,
    where,
    university,
    inline,
    logSearchResultsReq,
  ]);

  const resultsToShow = useCallback(
    results => {
      if (resultType === resultsType.ALL) return results;

      if (resultType === resultsType.THEORIES)
        return results.filter(it => it.type === 'theory');

      return results.filter(it => it.type !== 'theory');
    },
    [resultType]
  );

  return (
    <MathJaxProvider>
      <Container
        ref={ref}
        paddingTop={inline ? '0' : '94px'}
        isThemePage={!!theme}
        isMobile={media.isMobile()}
      >
        <div style={{ display: 'none' }}>
          <MathjaxContent html={''} />
        </div>
        {media.isDesktop() && !inline && (
          <SearchFieldDesktop
            closeDialog={closeDialog}
            term={term}
            color={color}
            setTerm={setTerm}
            execSearch={ExecSearch}
          />
        )}

        {media.isMobile() && (
          <SearchFieldMobile
            closeDialog={closeDialog}
            term={term}
            color={color}
            setTerm={setTerm}
          />
        )}

        {loading && term ? (
          <Loading />
        ) : (
          <>
            <MdBlock
              width={inline ? '100%' : null}
              marginTop={inline ? '0' : '10px'}
            >
              {inline && (
                <InlineBackContainer onClick={closeDialog}>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ marginRight: '2px' }}
                  />
                  Voltar
                </InlineBackContainer>
              )}

              {!!results.length && (
                <TypeSelector
                  color={color}
                  setResultType={setResultType}
                  resultType={resultType}
                  resultsType={resultsType}
                  onlyVideos={onlyVideos}
                  setOnlyVideos={setOnlyVideos}
                />
              )}

              <SearchDialogResults
                term={term}
                results={resultsToShow(results)}
                loading={loading}
                theme={theme}
                onlyVideos={onlyVideos}
                clickedInResult={clickedInResult}
                showFeedback={showFeedback}
              />
            </MdBlock>
          </>
        )}
      </Container>
    </MathJaxProvider>
  );
}
