import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { styled } from 'styles';
import { BookIconsContainer, BookContentFeedbackContainer } from '..';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useCookies } from 'modules/shared/hooks';
import ExerciseStatement from '../ExerciseStatement';
import Solution from './Solution';
import Answer from './Answer';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import { useBookExercisePermission } from 'modules/books/hooks';
import useSearchElegebility from 'modules/search/hooks/useSearchElegibility';
import { useBookExercisePageAbTest } from 'modules/books/hooks/useBookExercisePageAbTest';

function BookSolution() {
  const {
    bookExercise,
    currentExerciseId,
    edition,
    numViewsExerciseStatement,
  } = useBookEditionContext();
  const { canViewBookExercise } = useBookExercisePermission(edition);
  const { shouldShowExerciseStatement } = useSearchElegebility();
  const { shouldShowStatementFromAbTest } = useBookExercisePageAbTest();

  const [cookies] = useCookies(['books_result']);
  const cookieFromSearch = cookies.books_result;

  const logSearchTrackingTimeReq = useRAPostRequest(
    'search/log_search_tracking_time'
  );

  const blur = useMemo(() => {
    return !canViewBookExercise(currentExerciseId);
  }, [canViewBookExercise, currentExerciseId]);

  const shouldShowContentFeedback = useCallback(() => {
    return canViewBookExercise(currentExerciseId);
  }, [canViewBookExercise, currentExerciseId]);

  const shouldShowAnswer = useCallback(() => {
    return bookExercise?.lightAnswer !== '';
  }, [bookExercise]);

  const mustShowExerciseStatement = useCallback(() => {
    if (shouldShowExerciseStatement()) return true;
    if (shouldShowStatementFromAbTest(numViewsExerciseStatement)) return true;

    if (!cookieFromSearch) return false;

    if (parseInt(cookieFromSearch.bookExerciseId) === bookExercise?.id) {
      return true;
    }

    return false;
  }, [
    shouldShowExerciseStatement,
    shouldShowStatementFromAbTest,
    numViewsExerciseStatement,
    cookieFromSearch,
    bookExercise?.id,
  ]);

  const termSearched = useMemo(() => {
    if (!cookieFromSearch) return '';

    return cookieFromSearch.searchTerm;
  }, [cookieFromSearch]);

  useEffect(() => {
    if (!termSearched) return;

    // esse é um log em duas etapas.
    // a ideia é ter nocao do tempo que o usuario fica no resultado da busca
    // primeiros 2s trackeamos a cada 200ms
    // depois disso trackeamos a cada 5s

    const start = new Date();

    function sendLog() {
      const time = +new Date() - start;

      const json = {
        url: location.href.split('?')[0],
        term: termSearched,
        time: time,
      };

      logSearchTrackingTimeReq(json);
    }

    let interval;
    let counter = 0;
    const firstInterval = setInterval(() => {
      counter++;

      sendLog();

      if (counter === 10) {
        interval = setInterval(() => {
          sendLog();
        }, 5000);

        clearInterval(firstInterval);
      }
    }, 200);

    return () => {
      clearInterval(firstInterval);
      clearInterval(interval);
    };
  }, [bookExercise, termSearched, logSearchTrackingTimeReq]);

  return (
    <ContentContainer>
      <BookIconsContainer />
      {mustShowExerciseStatement() && (
        <ExerciseStatement termSearched={termSearched} />
      )}
      <Solution blur={blur} />
      {shouldShowAnswer() && <Answer blur={blur} />}
      {shouldShowContentFeedback() && <BookContentFeedbackContainer />}
    </ContentContainer>
  );
}

export default memo(BookSolution);

const ContentContainer = styled.div`
  position: relative;
  flex: 1;
  align-self: center;
  background-color: white;
  width: 100%;
  padding: 0 20px;
  .blurs p {
    filter: blur(5px);
  }
`;
