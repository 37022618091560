import { useCallback } from 'react';
import { useUserContext } from 'providers/UserProvider';

const usePermission = () => {
  const { user } = useUserContext();

  const UserInPlaybookFreeUniversity = useCallback(() => {
    return (
      user?.hasAccess &&
      !user?.hasValidFreePass &&
      !user?.subscriber &&
      user?.isFromPlaybookFreeUniversityCampaign
    );
  }, [user]);

  return {
    UserInPlaybookFreeUniversity,
  };
};

export { usePermission };
