import { useCallback } from 'react';

export default function useForumPermission() {
  const shouldShowForumForUserInContent = useCallback(content => {
    if (content?.type === 'BookExercise') {
      return true;
    }

    if (
      content?.type === 'FixationExercise' ||
      content?.type === 'ListExercise' ||
      content?.type === 'ExtraTimeExercise'
    ) {
      return true;
    }

    return false;
  }, []);

  const contentHasForum = useCallback(content => {
    return (
      content?.type === 'BookExercise' ||
      content?.type === 'FixationExercise' ||
      content?.type === 'ListExercise' ||
      content?.type === 'ExtraTimeExercise'
    );
  }, []);

  return {
    shouldShowForumForUserInContent,
    contentHasForum,
  };
}
