import React from 'react';
import { styled } from 'styles';
import DexterNoAccessCTA from './DexterNoAccessCTA';

export default function WrapperContent({ color, contentAccess, children }) {
  return (
    <Wrapper color={color}>
      <WrapperBlur
        className={contentAccess ? '' : 'blurs'}
        data-cy="blur-wrapper"
      >
        {children}
      </WrapperBlur>
      {!contentAccess && <DexterNoAccessCTA origin="workspace" />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  border-radius: 5px;
  border-top: 10px solid ${({ color }) => color};
  position: relative;
  overflow: hidden;
  margin-top: 30px;
`;

const WrapperBlur = styled.div`
  &.blurs {
    filter: blur(5px);
  }
`;
