import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { styled, colors } from 'styles';

import CardTitle from './CardTitle';

export default function UserPendingBoleto({ pendingBoleto }) {
  return (
    <Container>
      <CardTitle title="Boleto Pendente" upper />
      <Content>
        <h3>Você tem um boleto pendente</h3>

        {pendingBoleto.line && (
          <div className="boleto-code-container">
            <p>{pendingBoleto.line}</p>
            <FontAwesomeIcon
              onClick={() => navigator.clipboard.writeText(pendingBoleto.line)}
              icon={faCopy}
            />
          </div>
        )}

        <a
          rel="noopener noreferrer"
          target="_blank"
          href={pendingBoleto.paymentsUrl}
        >
          Imprimir
        </a>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 90%;
  max-width: 720px;
  margin-bottom: 50px;

  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 15px -2px rgba(51, 51, 51, 0.5);
`;

const Content = styled.div`
  padding: 0 40px 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .boleto-code-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    p {
      background-color: #efefef;
      padding: 10px 20px;
      border-radius: 10px;
      box-shadow: inset 0px 0px 3px -1px rgba(0, 0, 0, 0.75);
      word-break: break-all;
      flex: 1;
    }

    svg {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  a {
    background-color: ${colors.mainOrange};
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
  }
`;
