import React, { useEffect } from 'react';
import { styled, media } from 'styles';
import { useParams, useLocation } from 'react-router-dom';
import { MainContentContainer } from '../components/content';
import { SideMenuChapters } from 'modules/workspace/components/side-menu';
import { Loading, Error } from 'modules/shared/components/generic';
import WorkspaceFlagsProvider from '../providers/WorkspaceFlagsProvider';
import WorkspaceContentProvider from '../providers/WorkspaceContentProvider';
import ScrollToTopButton from 'modules/shared/components/ScrollToTopButton';
import UnloggedHeader from '../components/UnloggedHeader';
import { useUserContext } from 'providers/UserProvider';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import ForumProvider from 'providers/ForumProvider';
import MathJaxProvider from 'providers/MathJaxProvider';

export default function WorkspaceSubjectPage() {
  const location = useLocation();
  const { subjectId } = useParams();
  const { user } = useUserContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const { data: subject, loading, error } = useContentQueryImmutable(
    `/subjects/${subjectId}`
  );

  if (error) return <Error />;
  if (loading) return <Loading />;

  return (
    <MathJaxProvider>
      <ForumProvider>
        <WorkspaceFlagsProvider>
          <WorkspaceContentProvider
            chapters={subject.chapters}
            lecture={subject.lecture}
            contentTitle={subject.theme?.name}
            contentSubtitle={subject.name}
            subjects={[subject]}
            theme={subject.theme}
          >
            <Container>
              {!user && media.isDesktop() && <UnloggedHeader />}
              <SideMenuChapters themeId={subject.theme?.id} />
              <MainContentContainer />
            </Container>
            <ScrollToTopButton />
          </WorkspaceContentProvider>
        </WorkspaceFlagsProvider>
      </ForumProvider>
    </MathJaxProvider>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #f6f6f6;
`;
