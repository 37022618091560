import { useCallback } from 'react';

export default function useHtmlReplacement() {
  const replaceToFakeHtml = useCallback(html => {
    let newHTml = '';
    const replaceDefaultText = `<p style="text-align:center;">Acesso bloqueado desse conteúdo ----- Acesso bloqueado desse conteúdo. </p>`;

    let numberIterations = Math.floor(html.length / replaceDefaultText.length);
    numberIterations = Math.min(numberIterations, 20);
    for (let i = 0; i < numberIterations; i++) {
      newHTml += replaceDefaultText;
    }

    return newHTml;
  }, []);

  return {
    replaceToFakeHtml,
  };
}
