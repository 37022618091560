import { styled, colors } from 'styles';

const PathLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  background-color: #ccc;

  opacity: 0;

  &.first {
    top: 50%;
  }

  &.last {
    top: -50%;
  }

  &.open {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
  }

  &.flagged {
    background-color: ${colors.check};
  }
`;

export default PathLine;
