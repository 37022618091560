import { useLiveLectures } from 'modules/lectures/hooks';
import React from 'react';
import { styled, media } from 'styles';
import LiveLectureItem from './LiveLectureItem';
import LiveLecturesHeader from './LiveLecturesHeader';

export default function LiveLecturesCard({ liveLectures, themes }) {
  const { listOfThemesWithLiveLectures, getLecturesByTheme } = useLiveLectures(
    liveLectures,
    themes
  );

  return (
    <Container data-cy="live-lectures-card">
      <LiveLecturesHeader />
      <Text>
        Pratique para sua prova com aulões gravados para provas da sua faculdade
      </Text>
      {listOfThemesWithLiveLectures.map((theme, index) => (
        <LiveLectureItem
          dataCy={`live-lecture-item-${theme.id}`}
          key={index}
          theme={theme}
          lectures={getLecturesByTheme(theme.id)}
        />
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;

  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  padding: 35px 20px;
  margin-top: 60px;
  height: fit-content;
  @media ${media.mobile} {
    transform: scale(0.8);
  }
`;

const Text = styled.p`
  color: rgba(255, 255, 255, 0.8);
  width: 240px;
  line-height: 1.2;
  font-size: 0.9em;
  text-align: center;
  margin: auto;
  margin-bottom: 25px;
  margin-top: 25px;
`;
