import useSwrQueryGenerator from './useSwrQueryGenerator';

const baseUrl = `${process.env.REACT_APP_RA_CONTENT_URL}/api/v2`;

export default function useContentQuery(url, params = {}, swrConfig = {}) {
  const queryParams = new URLSearchParams(params).toString();
  const completeUrl = `${baseUrl}${url}${queryParams && `?${queryParams}`}`;

  return useSwrQueryGenerator(completeUrl, {
    ...swrConfig,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });
}
