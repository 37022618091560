import React, { useMemo } from 'react';
import { styled } from 'styles';
import ShareLinkContainer from 'modules/shared/components/ShareLinkContainer';
import { ErrorReportFloatButton } from 'modules/feedback/components';
import { useBooks, useBookExerciseTracker } from '../hooks';
import { useBookEditionContext } from '../providers/BookEditionProvider';

export default function BookIconsContainer() {
  const {
    currentSectionId,
    currentExerciseId,
    edition,
  } = useBookEditionContext();
  const {
    trackErrorReportClick,
    trackShareBookExercise,
  } = useBookExerciseTracker();
  const { getChapterDotSectionName, getQuestionByBookExerciseId } = useBooks();

  const whatsappMessage = useMemo(() => {
    const currentUrl = window.location;
    const encodedMessage = encodeURIComponent(
      `Opa, encontrei o que a gente precisava sobre ${edition.book.name}. Se liga: ${currentUrl}?utm_source=share`
    );
    return encodedMessage;
  }, [edition]);

  const errorReportLocation = useMemo(() => {
    const { theme } = edition.book;
    const chapterDotSectionName = getChapterDotSectionName(
      edition,
      currentSectionId
    );
    const question = getQuestionByBookExerciseId(edition, currentExerciseId);

    return `Matéria: ${theme.name} - ${theme.id}
            Livro: ${edition.amplitudeName}
            Edição: ${edition.name}ª
            Capítulo/Seção: ${chapterDotSectionName}
            Exercício: ${question.name}
            Url do conteúdo: ${location.href}
          `;
  }, [
    edition,
    getChapterDotSectionName,
    getQuestionByBookExerciseId,
    currentExerciseId,
    currentSectionId,
  ]);

  return (
    <IconsContainer data-cy="book-icons-container">
      <ShareLinkContainer
        wppMessageEncoded={whatsappMessage}
        trackContentCallback={trackShareBookExercise}
      />
      <ErrorReportFloatButton
        data={{
          type: 'exercício de livro',
          location: errorReportLocation,
        }}
        trackFeedbackCallback={trackErrorReportClick}
      />
    </IconsContainer>
  );
}

const IconsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
