import React from 'react';
import { ClipLoader } from 'modules/shared/components/generic/loaders';
import styled from 'styled-components/macro';

export default function Loading({ className }) {
  return (
    <Container className={className}>
      <ClipLoader color="#f7ac3b" />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
