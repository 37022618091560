import { useCallback } from 'react';

export default function useDate() {
  const format = useCallback(date => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }, []);

  const getHumanReadableDate = useCallback(
    date => {
      if (typeof date === 'string') date = new Date(date);

      return format(date);
    },
    [format]
  );

  const getHumanReadableDateFromTimestamp = useCallback(
    timestamp => {
      const date = new Date(timestamp * 1000);

      return format(date);
    },
    [format]
  );

  return { getHumanReadableDate, getHumanReadableDateFromTimestamp };
}
