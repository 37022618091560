import { useCallback } from 'react';
import { useUserContext } from 'providers/UserProvider';

export default function useDoubleAccess() {
  const { userJwtQuery, setLoading, getUserJwt, onLogout } = useUserContext();

  const showUserDoubleAccessDialog = useCallback(() => {
    return userJwtQuery?.error?.status === 403;
  }, [userJwtQuery?.error?.status]);

  const clearUserSession = useCallback(() => {
    onLogout();
    setLoading(true);
    getUserJwt({});
  }, [getUserJwt, onLogout, setLoading]);

  return { showUserDoubleAccessDialog, clearUserSession };
}
