import { styled, colors, media } from 'styles';

export default styled.div`
  position: relative;
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 80px;
  border-top: 10px solid
    ${({ borderColor }) => borderColor || colors.warningRed};
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;
