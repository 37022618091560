import { styled, colors, media } from 'styles';

export default styled.button`
  width: 100%;
  border: none;
  border: 2px solid #000;
  font-weight: bold;
  font-size: 1.5em;
  margin: 5px 0;
  padding: 10px 0;
  border-radius: 5px;

  &.highlight {
    background-color: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
    color: #fff;
  }

  &.light {
    color: #a2a2a2;
    border-color: #a2a2a2;
  }

  @media ${media.mobile} {
    font-size: 1.4em;
  }
`;
