import React from 'react';

import { styled } from 'styles';
import Covid19CancellationRequestMessage from './campaigns/Covid19CancellationRequestMessage';
import CancelSubscriptionButton from './CancelSubscriptionButton';
import VoidedSubscriptionCopy from './VoidedSubscriptionCopy';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import { CancelBookTrialButton } from 'modules/campaigns/book-trial/components';

export default function UserSubscriptionCancellation({ subscription, user }) {
  const { hasCreditCardTrial } = useBookTrialCampaign();

  if (hasCreditCardTrial()) {
    return <CancelBookTrialButton subscription={subscription} />;
  }

  if (user && user.scheduledOrder) {
    return <CancelSubscriptionButton user={user} subscription={subscription} />;
  }

  if (
    subscription.order.state === 'voided' ||
    subscription.order.state === 'refunded'
  ) {
    return <VoidedSubscriptionCopy subscription={subscription} />;
  }

  if (subscription.updatedToday) {
    return (
      <CancelTomorrow>
        Você poderá cancelar a assinatura a partir de amanhã.
      </CancelTomorrow>
    );
  }

  if (user.covid19CancellationRequest) {
    return (
      <Covid19CancellationRequestMessage
        user={user}
        subscription={subscription}
      />
    );
  }

  return <CancelSubscriptionButton user={user} subscription={subscription} />;
}

const CancelTomorrow = styled.p`
  margin-top: 5px;
  font-size: 0.8em;
  font-weight: bold;
`;
