import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { colors } from 'styles';
import { useUserContext } from 'providers/UserProvider';
import { useWorkspaceFlags } from 'modules/workspace/hooks/shared';
import { useContentLazyQueryWithoutCache } from 'modules/shared/hooks/requests';

export default function useWorkspaceExercise() {
  const [exercise, setExercise] = useState(null);
  const { exerciseId, listExerciseId, guideId, chapterId } = useParams();
  const { user } = useUserContext();
  const { getExerciseFlag } = useWorkspaceFlags();

  const recaptchaConfig = useMemo(() => {
    if (user) return null;

    return { action: 'view_exercise' };
  }, [user]);

  const [getExercise, exerciseResponse] = useContentLazyQueryWithoutCache(
    `/exercises/${exerciseId}`,
    recaptchaConfig
  );

  const [
    getListExercise,
    listExerciseResponse,
  ] = useContentLazyQueryWithoutCache(
    `/list-exercises/${listExerciseId}`,
    recaptchaConfig
  );

  useEffect(() => {
    if (!exerciseResponse) return;

    setExercise(exerciseResponse);
  }, [exerciseResponse]);

  useEffect(() => {
    if (!listExerciseResponse) return;

    setExercise(listExerciseResponse);
  }, [listExerciseResponse]);

  const isFixationExerciseWorkspace = useCallback(() => {
    return !!exerciseId;
  }, [exerciseId]);

  const isCurrentExercise = useCallback(
    exercise => {
      if (isFixationExerciseWorkspace())
        return parseInt(exercise.id) === parseInt(exerciseId);
      return parseInt(exercise.id) === parseInt(listExerciseId);
    },
    [isFixationExerciseWorkspace, exerciseId, listExerciseId]
  );

  const loadExercise = useCallback(() => {
    setExercise(null);

    if (exerciseId) getExercise();
    if (listExerciseId) getListExercise();
  }, [exerciseId, listExerciseId, getExercise, getListExercise]);

  const getListExercisesFromChapter = useCallback(chapter => {
    if (!chapter) return [];
    const topics = chapter.topics;
    if (!topics) return [];
    return _.flatten(topics.map(topic => topic.listExercises));
  }, []);

  const getExercisesFromChapter = useCallback(chapter => {
    const topics = chapter.topics;
    if (!topics) return [];
    return _.flatten(topics.map(topic => topic.exercises));
  }, []);

  const filterListExercisesFromUniversity = useCallback(
    listExercises => {
      if (!user || !user.university) return [];

      if (user.universityCampus) {
        const listExercisesFromUniversityCampus = listExercises.filter(
          exercise =>
            parseInt(exercise.universityCampusId) === user.universityCampus.id
        );
        if (listExercisesFromUniversityCampus.length > 0)
          return listExercisesFromUniversityCampus;
      }

      const listExercisesFromUniversity = listExercises.filter(
        exercise => parseInt(exercise.universityId) === user.university.id
      );
      return listExercisesFromUniversity;
    },
    [user]
  );

  const getExtraExercisesFromChapter = useCallback(
    chapter => {
      const allListExercises = getListExercisesFromChapter(chapter);
      const universityExercises = filterListExercisesFromUniversity(
        allListExercises
      );
      const universityExercisesIds = universityExercises.map(e => e.id);

      return allListExercises.filter(
        exercise => !universityExercisesIds.includes(exercise.id)
      );
    },
    [getListExercisesFromChapter, filterListExercisesFromUniversity]
  );

  const getFlagFromExercise = useCallback(
    exercise => {
      if (listExerciseId)
        return getExerciseFlag(exercise.id, 'exercicio-lista', guideId);

      return getExerciseFlag(exercise.id, 'exercicio', guideId);
    },
    [listExerciseId, guideId, getExerciseFlag]
  );

  const getExerciseIndexFromExercises = useCallback((exercise, exercises) => {
    const exercisesIds = exercises?.map(exercise => exercise.id);
    return exercisesIds?.indexOf(exercise?.id);
  }, []);

  const getExerciseColor = useCallback(() => {
    if (exerciseId) return colors.exercise;
    if (chapterId && listExerciseId) return colors.extraExercise;
    if (listExerciseId) return colors.listExercise;
  }, [exerciseId, listExerciseId, chapterId]);

  return {
    isCurrentExercise,
    getExerciseIndexFromExercises,
    loadExercise,
    getListExercisesFromChapter,
    getExercisesFromChapter,
    filterListExercisesFromUniversity,
    getFlagFromExercise,
    exercise,
    getExtraExercisesFromChapter,
    getExerciseColor,
  };
}
