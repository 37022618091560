import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FaBook } from 'react-icons/fa';
import { GoPencil } from 'react-icons/go';
import { BiTrash } from 'react-icons/bi';

import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';
import BuildStudyPlanDialog from 'modules/main/dialogs/BuildStudyPlanDialog';
import { Container } from './styles.js';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useSWRConfig } from 'swr';

export default function StudyPlanCard({ theme, studyPlan, privateThemeColor }) {
  const { user } = useUserContext();
  const { setDialog } = useDialogContext();
  const { sendDeleteRequest } = useContentRestRequest();
  const { mutate } = useSWRConfig();

  const onEditStudyPlan = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();
      setDialog(
        <BuildStudyPlanDialog theme={theme} studyPlanToEdit={studyPlan} />
      );
    },
    [theme, studyPlan, setDialog]
  );

  const onDeletedStudyPlan = useCallback(
    async shouldUpdateQuery => {
      if (shouldUpdateQuery) {
        await mutate(
          `${process.env.REACT_APP_RA_CONTENT_URL}/api/v2/study-plans`
        );
      } else {
        alert(
          'Ocorreu algum problema ao apagar seu Plano de Estudo.Tente mais tarde novamente'
        );
      }
    },
    [mutate]
  );

  const onDestroyStudyPlan = useCallback(
    async event => {
      event.stopPropagation();
      event.preventDefault();
      const shouldDelete = window.confirm(
        'Tem certeza que deseja deletar este Plano de Estudo? Essa ação é irreversível.'
      );
      if (shouldDelete) {
        const { data } = await sendDeleteRequest(
          `/study-plans/${studyPlan.id}`
        );

        await onDeletedStudyPlan(data.success);
      }
    },
    [studyPlan, sendDeleteRequest, onDeletedStudyPlan]
  );

  const studyPlanUrl = useMemo(() => {
    return `/plano-de-estudo/${studyPlan.id}/guia/${studyPlan.guideId}`;
  }, [studyPlan]);

  return (
    <Link to={studyPlanUrl}>
      <Container theme={theme} privateThemeColor={privateThemeColor}>
        <div className="edit-delete-icons">
          <GoPencil
            className="edit"
            onClick={event => onEditStudyPlan(event)}
          />
          <BiTrash
            className="delete"
            onClick={event => onDestroyStudyPlan(event)}
          />
        </div>
        <img src={user.pictureUrl} alt="" />
        <FaBook className="icon" />
        <span className="name">{studyPlan.name || 'Plano de Estudos'}</span>
      </Container>
    </Link>
  );
}
