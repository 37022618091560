import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import LiveLectures from './LiveLectures';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';

export default function LiveLectureItem({ theme, lectures, dataCy }) {
  const [open, setOpen] = useState(false);

  const calculateToggleableHeight = useCallback(lectures => {
    const heigth = lectures.length * 55 + 5;
    return `${heigth}px`;
  }, []);

  return (
    <Container data-cy={dataCy}>
      <ThemeItem onClick={() => setOpen(!open)} color={theme.color}>
        <ThemeDropDown>
          <p>{theme.name}</p>
        </ThemeDropDown>
        <ArrowIcon
          icon={open ? faChevronUp : faChevronDown}
          className={open ? 'arrowUp' : 'arrowDown'}
        />
      </ThemeItem>
      <ToggleableComponent
        height={calculateToggleableHeight(lectures)}
        animationTime="0.3s"
        open={open}
      >
        <LiveLectures lectures={lectures} color={theme.color} />
      </ToggleableComponent>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 28px;
`;

const ThemeDropDown = styled.div`
  overflow: hidden;
  width: 100%;
  font-size: 1.15em;
  line-height: 55px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const ThemeItem = styled.div`
  border-radius: 8px;
  margin-bottom: -8px;
  background-color: ${({ color }) => color};
  width: 310px;
  height: 55px;
  padding: 0 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1px;
  user-select: none;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  font-size: 1.4em;
  font-weight: bold;
  position: relative;
  left: 27px;
  margin-left: -19.5px;
  color: #fff;
`;
