import React, { createContext, useContext, useState } from 'react';

const SearchContext = createContext(null);

export default function SearchProvider({ children }) {
  const [searchType, setSearchType] = useState(null);
  const [screenId, setScreenId] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [file, setFile] = useState(null);
  const [imgUploadedSrc, setImgUploadedSrc] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);

  return (
    <SearchContext.Provider
      value={{
        screenId,
        setScreenId,
        searchType,
        setSearchType,
        searchTerm,
        setSearchTerm,
        file,
        setFile,
        imgUploadedSrc,
        setImgUploadedSrc,
        results,
        setResults,
        loading,
        setLoading,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export const useSearchContext = () => useContext(SearchContext);
