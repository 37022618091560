import { useEffect, useState } from 'react';

export function useComponentFirstTimeVisible({
  ref, // React component ref
  onVisible, // Function callback
  threshold = 0.5, // Eefault: Trigger when 50% of the component is visible
}) {
  const [firstTimeVisible, setFirstTimeVisible] = useState(false);

  useEffect(() => {
    if (!ref || !ref.current) return;

    const element = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (!firstTimeVisible) onVisible();
          setFirstTimeVisible(true);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold,
      }
    );

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [firstTimeVisible, onVisible, ref, threshold]);
}
