import { useCallback, useMemo } from 'react';

export default function useUTM() {
  const getUTMsByLocation = useCallback(location => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    return {
      'MKT: UTM Source':
        searchParams.get('utm_source') || searchParams.get('amp;utm_source'),
      'MKT: UTM Medium':
        searchParams.get('utm_medium') || searchParams.get('amp;utm_medium'),
      'MKT: UTM Campaign':
        searchParams.get('utm_campaign') ||
        searchParams.get('amp;utm_campaign'),
      'MKT: UTM Term':
        searchParams.get('utm_term') || searchParams.get('amp;utm_term'),
      'MKT: UTM Content':
        searchParams.get('utm_content') || searchParams.get('amp;utm_content'),
    };
  }, []);

  const utmParamsWithoutInternalSource = useMemo(() => {
    let utmParams = new URLSearchParams(window.location.search);
    utmParams.delete('internal_source');

    return utmParams ? `?${utmParams.toString()}` : '';
  }, []);

  return { getUTMsByLocation, utmParamsWithoutInternalSource };
}
