import { useToast } from 'modules/shared/hooks';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useCallback, useState } from 'react';
import { useTrackEvents } from './useTrackEvents';
import { useMvpBooksTracker } from 'modules/campaigns/mvp-books/hooks/useMVpBooksTracker';

export default function useFavoriteList() {
  const { sendPostRequest } = useContentRestRequest();
  const [isFavoriteState, setIsFavoriteState] = useState();
  const { successToast } = useToast();
  const { trackClickFavoriteFeature } = useTrackEvents();
  const { trackClickFavoriteBookList } = useMvpBooksTracker();

  const isListFavoriteFromBackend = useCallback(list => {
    if (!list?.userPreferences || !list.userPreferences[0]) return false;

    return list.userPreferences[0].isFavorite;
  }, []);

  const postListNewStatus = useCallback(
    async ({ listId, listType, isFavorite, origin }, mutate = () => {}) => {
      setIsFavoriteState(isFavorite);
      const success = await sendPostRequest(`mvp/list-user-preferences`, {
        listId,
        listType,
        isFavorite,
      });

      if (success) {
        successToast(
          isFavorite
            ? 'Sua lista foi favoritada ⭐'
            : 'Sua lista foi desfavoritada :)'
        );

        if (listType === 'MvpBookExerciseList') {
          trackClickFavoriteBookList({ listId, isFavorite, origin });
        } else {
          trackClickFavoriteFeature({ listId, isFavorite, origin });
        }

        mutate();
      }
    },
    [
      sendPostRequest,
      successToast,
      trackClickFavoriteBookList,
      trackClickFavoriteFeature,
    ]
  );

  return {
    postListNewStatus,
    isFavoriteState,
    setIsFavoriteState,
    isListFavoriteFromBackend,
  };
}
