import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useTracker } from 'modules/shared/hooks';
import { media } from 'styles';
import PersonalizedContentDialog from 'modules/shared/dialogs/personalized-content-dialog/PersonalizedContentDialog';
import { useDialogContext } from 'providers/DialogProvider.js';

function usePersonalizedContentSharedFunctions() {
  const history = useHistory();
  const { trackEvent } = useTracker();
  const { setDialog } = useDialogContext();
  const eventProperties = useMemo(() => {
    return {
      Onde: 'Home',
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
    };
  }, []);

  const viewBannerEvent = useCallback(() => {
    trackEvent('Visualizou Banner de Conteúdo Personalizado', eventProperties);
  }, [trackEvent, eventProperties]);

  const onClickBanner = useCallback(() => {
    trackEvent('Clicou Banner de Conteúdo Personalizado', eventProperties);
    setDialog(
      <PersonalizedContentDialog history={history} source="banner-home" />
    );
  }, [trackEvent, eventProperties, history, setDialog]);

  return {
    viewBannerEvent,
    onClickBanner,
  };
}

export { usePersonalizedContentSharedFunctions };
