import React, { useCallback, useEffect, useMemo } from 'react';
import { Loading, Error } from 'modules/shared/components/generic';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import ProfessorWithListsPage from './ProfessorWithListsPage';
import ProfessorWithoutListsPage from './ProfessorWithoutListsPage';
import { useParams } from 'react-router-dom';
import { useTracker } from 'modules/shared/hooks';

export default function ProfessorPage() {
  const { professorId } = useParams();
  const { amplitudeStartSessionReply } = useTracker();

  const {
    data: professorWithLists,
    loading: professorWithListsLoading,
    error: professorWithListsError,
    mutate: mutateProfessorQuery,
  } = useContentQueryImmutable(`/mvp/lists-by-professor/${professorId}`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    amplitudeStartSessionReply(0.1); // 10%
  }, [amplitudeStartSessionReply]);

  const totalListsQuestions = useMemo(() => {
    if (!professorWithLists) return 0;
    const bookListsQuestionsCount = professorWithLists.lists.reduce(
      (sum, list) => sum + parseInt(list.solvedQuestions || 0),
      0
    );

    const statementListsQuestionsCount = professorWithLists.statementLists.reduce(
      (sum, list) => sum + parseInt(list.solvedQuestions || 0),
      0
    );

    return bookListsQuestionsCount + statementListsQuestionsCount;
  }, [professorWithLists]);

  const isProfessorEligibleToListsPage = useCallback(() => {
    return totalListsQuestions >= 10;
  }, [totalListsQuestions]);

  const getGuideIdFromProfessor = useCallback(() => {
    if (professorWithLists.teachableType === 'MvpDiscipline') {
      return professorWithLists?.teachable?.guideRelation?.guide?.id;
    }

    if (professorWithLists.teachableType === 'Discipline') {
      return professorWithLists?.teachable?.guide?.id;
    }
  }, [professorWithLists]);

  if (professorWithListsError) return <Error />;
  if (professorWithListsLoading) return <Loading />;

  return isProfessorEligibleToListsPage() ? (
    <ProfessorWithListsPage
      professorWithLists={professorWithLists}
      mutateProfessorQuery={mutateProfessorQuery}
    />
  ) : (
    <ProfessorWithoutListsPage
      discipline={professorWithLists.teachable}
      guideId={getGuideIdFromProfessor()}
      professor={professorWithLists}
      mutateProfessorQuery={mutateProfessorQuery}
    />
  );
}
