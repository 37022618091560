import { useTracker, useUTM } from 'modules/shared/hooks';
import { useCallback, useMemo } from 'react';
import { media } from 'styles';
import useMonitorSearchUsage from './useMonitorSearchUsage';
import useSearchExerciseType from './useSearchExerciseType';

export default function useSearchTracker() {
  const { trackEvent } = useTracker();
  const { getSearchViewCounter } = useMonitorSearchUsage();
  const { getUTMsByLocation } = useUTM();
  const {
    getExerciseIdByResult,
    getExerciseTypeByResult,
  } = useSearchExerciseType();

  const defaultProperties = useMemo(() => {
    return {
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Onde: 'Livros',
      Versão: '2',
      ...getUTMsByLocation(window.location),
    };
  }, [getUTMsByLocation]);

  const trackViewSearchDialog = useCallback(() => {
    const properties = {
      ...defaultProperties,
      'Quantas vezes entrou na busca?': getSearchViewCounter() + 1,
    };
    trackEvent('Busca por Imagem: Abriu Dialog', properties);
  }, [trackEvent, defaultProperties, getSearchViewCounter]);

  const trackClickSearchByText = useCallback(() => {
    trackEvent('Busca por Imagem: Clicou Busca por texto', defaultProperties);
  }, [trackEvent, defaultProperties]);

  const trackClickSearchByImage = useCallback(() => {
    trackEvent('Busca por Imagem: Clicou Busca por imagem', defaultProperties);
  }, [trackEvent, defaultProperties]);

  const trackViewInputTutorialPage = useCallback(() => {
    trackEvent('Busca por Imagem: Visualizou Tela Tutorial', defaultProperties);
  }, [trackEvent, defaultProperties]);

  const trackViewUploadPage = useCallback(() => {
    trackEvent('Busca por Imagem: Visualizou Tela Upload', defaultProperties);
  }, [trackEvent, defaultProperties]);

  const trackClickSendImage = useCallback(() => {
    trackEvent('Busca por Imagem: Clicou Enviar Imagem', defaultProperties);
  }, [trackEvent, defaultProperties]);

  const trackCameraAccessDenied = useCallback(() => {
    trackEvent(
      'Busca por Imagem: Não permitiu acesso à camera',
      defaultProperties
    );
  }, [trackEvent, defaultProperties]);

  const trackViewCroppedPage = useCallback(() => {
    trackEvent('Busca por Imagem: Visualizou Tela Corte', defaultProperties);
  }, [trackEvent, defaultProperties]);

  const trackClickImageToCrop = useCallback(
    wasCropped => {
      const properties = {
        ...defaultProperties,
        'Alterou a foto incial?': wasCropped ? 'Sim' : 'Não',
      };
      trackEvent('Busca por Imagem: Cortou a imagem', properties);
    },
    [trackEvent, defaultProperties]
  );

  const trackViewSerpResults = useCallback(
    (results, fileName) => {
      if (!results) return;

      const properties = {
        ...defaultProperties,
        'Número de resultados exibidos': results.length,
        'ID conteúdo posição 1': getExerciseIdByResult(results[0]),
        'Score Posição 1': results[0]?._score,
        'Tipo Posição 1': getExerciseTypeByResult(results[0]),
        'ID conteúdo posição 2': getExerciseIdByResult(results[1]),
        'Score Posição 2': results[1]?._score,
        'Tipo Posição 2': getExerciseTypeByResult(results[1]),
        'ID conteúdo posição 3': getExerciseIdByResult(results[2]),
        'Score Posição 3': results[2]?._score,
        'Tipo Posição 3': getExerciseTypeByResult(results[2]),
        'ID conteúdo posição 4': getExerciseIdByResult(results[3]),
        'Score Posição 4': results[3]?._score,
        'Tipo Posição 4': getExerciseTypeByResult(results[3]),
        'ID conteúdo posição 5': getExerciseIdByResult(results[4]),
        'Score Posição 5': results[4]?._score,
        'Tipo Posição 5': getExerciseTypeByResult(results[4]),
        'ID conteúdo posição 6': getExerciseIdByResult(results[5]),
        'Score Posição 6': results[5]?._score,
        'Tipo Posição 6': getExerciseTypeByResult(results[5]),
        'ID conteúdo posição 7': getExerciseIdByResult(results[6]),
        'Score Posição 7': results[6]?._score,
        'Tipo Posição 7': getExerciseTypeByResult(results[6]),
        'ID conteúdo posição 8': getExerciseIdByResult(results[7]),
        'Score Posição 8': results[7]?._score,
        'Tipo Posição 8': getExerciseTypeByResult(results[7]),
        'ID conteúdo posição 9': getExerciseIdByResult(results[8]),
        'Score Posição 9': results[8]?._score,
        'Tipo Posição 9': getExerciseTypeByResult(results[8]),
        'ID conteúdo posição 10': getExerciseIdByResult(results[9]),
        'Score Posição 10': results[9]?._score,
        'Tipo Posição 10': getExerciseTypeByResult(results[9]),
        'Nome do arquivo': fileName,
      };
      trackEvent('Busca por Imagem: Visualizou Resultados', properties);
    },
    [
      trackEvent,
      defaultProperties,
      getExerciseIdByResult,
      getExerciseTypeByResult,
    ]
  );

  const trackClickSerpResult = useCallback(
    (result, resultPosition, fileName) => {
      if (!result) return;

      const properties = {
        ...defaultProperties,
        'Posição clicada': resultPosition + 1,
        'ID do conteúdo': getExerciseIdByResult(result),
        'Tipo do conteúdo': getExerciseTypeByResult(result),
        'Score do conteúdo': result._score,
        'Nome do arquivo': fileName,
      };
      trackEvent('Busca por Imagem: Clicou no Resultado da SERP', properties);
    },
    [
      trackEvent,
      defaultProperties,
      getExerciseIdByResult,
      getExerciseTypeByResult,
    ]
  );

  const trackViewFeedbackFromSearch = useCallback(
    source => {
      const properties = {
        ...defaultProperties,
        Onde: source,
      };
      trackEvent('Busca por Imagem: Visualizou Feedback', properties);
    },
    [trackEvent, defaultProperties]
  );

  const trackSendFeedbackFromSearch = useCallback(
    (feedback, source) => {
      const properties = {
        ...defaultProperties,
        Onde: source,
        'Gostou?': feedback === 'up' ? 'Sim' : 'Não',
      };
      trackEvent('Busca por Imagem: Enviou Feedback', properties);
    },
    [trackEvent, defaultProperties]
  );

  const trackErrorOnUploadImage = useCallback(
    message => {
      const properties = {
        ...defaultProperties,
        Descrição: message,
      };
      trackEvent('Busca por Imagem: Erro no upload', properties);
    },
    [trackEvent, defaultProperties]
  );

  const trackCloseSearchDialog = useCallback(() => {
    trackEvent('Busca por Imagem: Clicou Sair dialog', defaultProperties);
  }, [trackEvent, defaultProperties]);

  // Abaixo são eventos da busca antiga

  const trackTypeInSearchInput = useCallback(
    termSearched => {
      const properties = {
        Versão: 'Com imagem',
        Onde: 'books',
        'Termo Buscado': termSearched,
        'Busca direto no matérias': false,
      };
      trackEvent('Busca: Digitou na Busca', properties);
    },
    [trackEvent]
  );

  const trackViewResultsByText = useCallback(
    termSearched => {
      const properties = {
        Versão: 'Com imagem',
        Onde: 'books',
        'Termo Buscado': termSearched,
        'Busca direto no matérias': false,
      };
      trackEvent('Busca: Visualizou Resultados', properties);
    },
    [trackEvent]
  );

  const trackClickInResultByText = useCallback(
    (resultPosition, termSearched) => {
      const properties = {
        'Posição da Sugestão': resultPosition,
        Versão: 'Com imagem',
        'Termo Buscado': termSearched,
      };
      trackEvent('Busca: Resultado Clicado', properties);
    },
    [trackEvent]
  );

  return {
    trackViewSearchDialog,
    trackClickSearchByText,
    trackClickSearchByImage,
    trackViewInputTutorialPage,
    trackViewUploadPage,
    trackClickSendImage,
    trackCameraAccessDenied,
    trackViewCroppedPage,
    trackClickImageToCrop,

    trackViewSerpResults,
    trackClickSerpResult,
    trackViewFeedbackFromSearch,
    trackSendFeedbackFromSearch,
    trackErrorOnUploadImage,
    trackCloseSearchDialog,
    trackTypeInSearchInput,
    trackViewResultsByText,
    trackClickInResultByText,
  };
}
