import React, { useCallback, useRef } from 'react';
import { styled, media } from 'styles';
import { FaSearch } from 'react-icons/fa';
import { debounce } from 'lodash';
import { useRaSearchPostRequest } from 'modules/shared/hooks/requests';
import useSearchTracker from '../hooks/useSearchTracker';
import { useSearchContext } from '../providers/SearchProvider';

export default function SearchInput() {
  const {
    setResults,
    setLoading,
    setSearchTerm,
    searchTerm,
  } = useSearchContext();
  const { sendPostRequest } = useRaSearchPostRequest('/search/with-text');
  const { trackTypeInSearchInput } = useSearchTracker();

  const sendSearchRequest = useCallback(
    async input => {
      if (input.length < 3) return;

      setLoading(true);
      trackTypeInSearchInput(input);
      const { data: results } = await sendPostRequest({ textToSearch: input });
      setResults(results);
      setLoading(false);
    },
    [setResults, setLoading, sendPostRequest, trackTypeInSearchInput]
  );

  // useRef is used to prevent create a function each re-render
  const debouncedSearch = useRef(
    debounce(async input => {
      await sendSearchRequest(input);
    }, 500)
  ).current;

  const handleSearchInputChange = useCallback(
    event => {
      const input = event.target.value;
      setSearchTerm(input);
      debouncedSearch(input);
    },
    [debouncedSearch, setSearchTerm]
  );

  return (
    <SearchContainer data-cy="text-search-input">
      <FaSearch className="search-icon" color="#149f8c" size={26} />
      <Input
        type="text"
        placeholder="Busque por um exercicio de livro"
        value={searchTerm}
        onChange={event => handleSearchInputChange(event)}
      />
    </SearchContainer>
  );
}

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  margin: 0 0 15px 0;
  z-index: 9;
  cursor: pointer;
  padding: 0 20px 0 13px;
  background-color: #fff;
  border-radius: 50px;
  border: 2px solid #149f8c;
  text-align: center;
  color: #888;
  width: 600px;

  font-size: 1.1em;
  font-style: normal;
  text-shadow: none;

  .search-icon {
    font-size: 1.4rem;
    margin-right: 10px;
  }

  @media ${media.mobile} {
    width: 90%;
  }
`;

const Input = styled.input`
  border: none;
  background: none;
  width: 100%;
  font-size: 1.1em;
  cursor: pointer;
  color: #888;
  font-family: 'Roboto', sans-serif;
`;
