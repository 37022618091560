import { styled, media } from 'styles';

const Container = styled.div`
  position: relative;
  width: 960px;
  max-width: 95%;
  margin: 0 auto 0 auto;
  padding: 0px 24px 40px 24px;

  @media ${media.mobile} {
    padding: 60px 5px 40px 5px;
    max-width: 100%;
    margin: auto;
  }
`;

export default Container;
