import styled from 'styled-components';

const ContainerBanner = styled.div`
  background: #a74ca3;
  height: 100%;
  color: #fff;

  .banner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 960px;
    height: 100%;
    margin: auto;

    .message {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 1.25em;
        font-weight: bold;

        &.highlight {
          color: #a74ca3;
          background: #fff;
          padding: 3px 5px;
          margin: 6px 0;
          font-size: 1.35em;
        }
      }
    }

    button {
      background: #f7ac3b;
      width: 220px;
      padding: 5px 0;
      text-align: center;
      border: none;
      color: #fff;
      font-size: 1.3em;
      font-weight: bold;
      border-radius: 5px;
    }
  }

  .pig-container {
    margin-left: 30px;

    img {
      width: 140px;
    }

    .layer {
      display: flex;
      justify-content: center;
      align-items: center;

      background: #ffcbfd;
      width: 140px;
      height: 140px;
      border-radius: 90px;
    }

    @keyframes animation01 {
      0% {
        border-color: rgba(255, 203, 253, 0);
      }
      50% {
        border-color: rgba(255, 203, 253, 0.6);
      }
      100% {
        border-color: rgba(255, 203, 253, 0);
      }
    }

    @keyframes animation02 {
      0% {
        border-color: rgba(255, 203, 253, 0);
      }
      50% {
        border-color: rgba(255, 203, 253, 0.3);
      }
      100% {
        border-color: rgba(255, 203, 253, 0);
      }
    }

    @keyframes animation03 {
      0% {
        border-color: rgba(255, 203, 253, 0);
      }
      50% {
        border-color: rgba(255, 203, 253, 0.2);
      }
      100% {
        border-color: rgba(255, 203, 253, 0);
      }
    }

    .layer-border-1 {
      border: 15px solid transparent;
      border-radius: 100px;
      animation: animation01 4s infinite;
    }

    .layer-border-2 {
      border: 15px solid transparent;
      border-radius: 110px;
      animation: animation02 4s infinite;
    }

    .layer-border-3 {
      border: 15px solid transparent;
      border-radius: 120px;
      animation: animation03 4s infinite;
    }
  }
`;

export { ContainerBanner };
