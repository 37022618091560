import React from 'react';
import { styled, colors } from 'styles';
import { MathjaxContent } from 'modules/shared/components/generic';

export default function SummaryContent({ summary, title, color }) {
  return (
    <Container>
      <Title color={color}>{title}</Title>
      <MathJaxContainer>
        <MathjaxContent html={summary.lightBody} />
      </MathJaxContainer>
    </Container>
  );
}

const Container = styled.div`
  display: block;
`;

const Title = styled.h3`
  background-color: ${props => props.color};
  display: inline-block;
  padding: 10px 20px;
  margin: 30px 0 30px -15px;
  border-radius: 5px;
  font-size: 0.9em;
  color: ${colors.white};
`;

const MathJaxContainer = styled.div``;
