import { useTracker, useUTM } from 'modules/shared/hooks';
import { useCallback, useMemo } from 'react';
import { media } from 'styles';
import { useProfileComplete } from 'modules/profile/hooks';

export default function useProfileCompleteTracker() {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();
  const { getRedirectUrl } = useProfileComplete();

  const redirectLocation = useMemo(() => {
    const link = decodeURI(getRedirectUrl());
    if (!link) return;

    try {
      return new URL(decodeURI(link));
    } catch {
      return;
    }
  }, [getRedirectUrl]);

  const trackViewProfileComplete = useCallback(
    (isNFFOnLeft, test13Version) => {
      const eventName = 'Visualizou Completar Cadastro';

      const utms = redirectLocation ? getUTMsByLocation(redirectLocation) : {};

      const data = {
        Plataforma: media.platformName(),
        'Versão Teste AB': isNFFOnLeft ? 'NFF na esquerda' : 'NFF na direita',
        'Versão Teste 13': test13Version ? 'Teste' : 'Controle',
        ...utms,
      };

      trackEvent(eventName, data);
    },
    [trackEvent, getUTMsByLocation, redirectLocation]
  );

  const trackViewAcademicEducationDialog = useCallback(() => {
    trackEvent('Visualizou Dialog de Formação Acadêmica');
  }, [trackEvent]);

  const trackSubmitAcademicEducation = useCallback(
    eventData => {
      trackEvent('Informou Formação Acadêmica', eventData);
    },
    [trackEvent]
  );
  return {
    trackViewProfileComplete,
    trackViewAcademicEducationDialog,
    trackSubmitAcademicEducation,
  };
}
