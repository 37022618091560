import React from 'react';
import { styled } from 'styles';
import { useBookEditionContext } from '../providers/BookEditionProvider';
import BookExercise from './BookExercise';
import BookExerciseSelector from './BookExerciseSelector';
import DesktopBookBanner from 'modules/banner/book-banner/DesktopBookBanner';
import MobileBookBanner from 'modules/banner/book-banner/MobileBookBanner';
import BookNavigationButtons from './BookNavigationButtons';
import { media } from 'styles';
import { useRenderBookEditionPageDialog } from 'modules/books/hooks';
import BookInfoInline from './lists/BookInfoInline';
import { useBookExercisePageAbTest } from '../hooks/useBookExercisePageAbTest';

export default function ContentContainer({ shouldShowBookInfoInline }) {
  const { currentExerciseId } = useBookEditionContext();
  const { shouldChangeLayout } = useBookExercisePageAbTest();

  useRenderBookEditionPageDialog();

  return (
    <Container>
      {!media.isMobile() && !shouldChangeLayout && <DesktopBookBanner />}
      {media.isMobile() && !shouldChangeLayout && <MobileBookBanner />}
      {shouldShowBookInfoInline && <BookInfoInline />}
      <BookExerciseSelector />
      {currentExerciseId !== -1 && (
        <>
          {!shouldChangeLayout && <BookNavigationButtons />}
          <BookExercise />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 960px;
  max-width: 95%;
  padding-top: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0px auto 30px auto;
`;
