import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { colors, styled } from 'styles';
import { useDate } from 'modules/shared/hooks';
import { useTracker } from 'modules/shared/hooks';
import {
  Container,
  Content,
  ButtonsContainer,
  Button,
} from 'modules/campaigns/change-plan-on-cancel/components';
import {
  ChangePlanOnCancelCTADialog,
  ChangePlanOnCancelSuccessDialog,
} from '.';
import { useDialogContext } from 'providers/DialogProvider';
import { useChangePlanOnCancelCampaign } from 'modules/campaigns/change-plan-on-cancel/hooks';
import MoneyIcon from 'images/cancellation/money.png';

export default function ChangePlanOnCancelConfirmationDialog({
  subscription,
  selectedReason,
  selectedDetailedReason,
  otherReason,
  newPlanPricing,
}) {
  const [loading, setLoading] = useState(false);
  const { setDialog } = useDialogContext();
  const { getHumanReadableDateFromTimestamp } = useDate();
  const { trackEvent } = useTracker();
  const {
    getMonthlyPlanPricingPrice,
    changePlan,
    getChangePlanCard,
    getAmplitudeEventProperties,
  } = useChangePlanOnCancelCampaign();

  useEffect(() => {
    trackEvent('Troca de Plano no Cancel: Confirmacao Visualizou', {
      ...getAmplitudeEventProperties(subscription),
      'Novo Plano': newPlanPricing.plan.description,
    });
  }, [trackEvent, getAmplitudeEventProperties, newPlanPricing, subscription]);

  const onBackClick = useCallback(() => {
    trackEvent('Troca de Plano no Cancel: Confirmacao Voltou', {
      ...getAmplitudeEventProperties(subscription),
      'Novo Plano': newPlanPricing.plan.description,
    });
    setDialog(
      <ChangePlanOnCancelCTADialog
        subscription={subscription}
        selectedReason={selectedReason}
        selectedDetailedReason={selectedDetailedReason}
        otherReason={otherReason}
      />
    );
  }, [
    subscription,
    selectedReason,
    selectedDetailedReason,
    otherReason,
    newPlanPricing,
    setDialog,
    trackEvent,
    getAmplitudeEventProperties,
  ]);

  const onChangePlanClick = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    const success = await changePlan(subscription, newPlanPricing);
    if (!success) {
      setLoading(false);
      alert(
        'Erro ao trocar de plano. Por favor, entre em contato com o atendimento'
      );
      return;
    }

    trackEvent('Troca de Plano no Cancel: Confirmacao Clicou Trocar', {
      ...getAmplitudeEventProperties(subscription),
      'Novo Plano': newPlanPricing.plan.description,
    });
    setDialog(
      <ChangePlanOnCancelSuccessDialog
        subscription={subscription}
        newPlanPricing={newPlanPricing}
      />
    );
  }, [
    subscription,
    newPlanPricing,
    trackEvent,
    getAmplitudeEventProperties,
    changePlan,
    loading,
    setDialog,
  ]);

  const planDescriptionText = useMemo(() => {
    const price = subscription.order.planPricing.price;
    const installments = subscription.order.installments || 1;
    const monthlyPrice = (price / installments).toFixed(2);

    return `${subscription.plan.description}, ${installments}x R$${monthlyPrice}`;
  }, [subscription]);

  const newPlanDescriptionText = useMemo(() => {
    const monthlyPrice = getMonthlyPlanPricingPrice(newPlanPricing);

    return `${newPlanPricing.plan.description} (${newPlanPricing.plan.months}x R$${monthlyPrice}/mes)`;
  }, [newPlanPricing, getMonthlyPlanPricingPrice]);

  const card = useMemo(() => {
    return getChangePlanCard();
  }, [getChangePlanCard]);

  const renderPaymentNewPlanInfo = useCallback(() => {
    if (newPlanPricing.plan.months === 1)
      return (
        <>
          <PaymentPlanInfo>
            <Strong>Forma de Pagamento:</Strong> R$
            {getMonthlyPlanPricingPrice(newPlanPricing)} no cartão {card.brand}{' '}
            de final X-{card.lastFourDigits}
          </PaymentPlanInfo>
          <PaymentPlanInfo>
            <Strong>Primeira Cobrança:</Strong>{' '}
            {getHumanReadableDateFromTimestamp(subscription.expiresIn)}
          </PaymentPlanInfo>
          <PaymentPlanInfo>
            <Strong>Renovação:</Strong> Mensal
          </PaymentPlanInfo>
        </>
      );

    return (
      <>
        <PaymentPlanInfo>
          <Strong>Forma de Pagamento:</Strong> {newPlanPricing.plan.months}{' '}
          parcelas de R${getMonthlyPlanPricingPrice(newPlanPricing)} no cartão{' '}
          {card.brand} de final X-{card.lastFourDigits}
        </PaymentPlanInfo>
        <PaymentPlanInfo>
          <Strong>Cobrança da primeira parcela:</Strong>{' '}
          {getHumanReadableDateFromTimestamp(subscription.expiresIn)}
        </PaymentPlanInfo>
      </>
    );
  }, [
    newPlanPricing,
    subscription,
    card,
    getMonthlyPlanPricingPrice,
    getHumanReadableDateFromTimestamp,
  ]);

  return (
    <Container>
      <Content borderColor={colors.successGreen}>
        <Icon src={MoneyIcon} />
        <Title>Trocar para o plano {newPlanPricing.plan.description}</Title>
        <CurrentPlanDescription>
          Seu plano atual: {planDescriptionText}
        </CurrentPlanDescription>
        <CurrentPlanInfo>
          Válido até {getHumanReadableDateFromTimestamp(subscription.expiresIn)}
        </CurrentPlanInfo>
        <NewPlanCTA>Seu novo plano: {newPlanDescriptionText}</NewPlanCTA>
        {renderPaymentNewPlanInfo()}
        {subscription.plan.months > 1 && (
          <WarningInfo>
            <Strong>Atenção: </Strong> Caso ainda tenha parcelas restantes a
            pagar,{' '}
            <Strong>a troca de planos não abona o pagamento delas.</Strong> E
            assim como o plano semestral, o pagamento das parcelas do plano
            anual não podem ser interrompidas.
          </WarningInfo>
        )}
        <ButtonsContainer>
          <Button onClick={onBackClick}>Voltar</Button>
          <Button onClick={onChangePlanClick}>
            {loading ? 'Trocando plano...' : 'Trocar de plano'}
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Icon = styled.img`
  display: block;
  margin: 20px auto 5px auto;
  width: 65px;
`;

const Title = styled.h1`
  font-weight: bold;
  color: ${colors.successGreen};
  font-size: 1.4em;
  text-align: center;
`;

const CurrentPlanDescription = styled.div`
  font-weight: bold;
  font-size: 1.1em;
  margin-top: 20px;
`;

const CurrentPlanInfo = styled.div`
  margin-top: 5px;
  font-weight: bold;
  font-size: 0.8em;
`;

const NewPlanCTA = styled.div`
  width: fit-content;
  margin: 20px 0 15px;
  font-size: 1.1em;
  font-weight: bold;
  background-color: ${colors.mainOrange};
  color: #fff;
  padding: 5px;
`;

const PaymentPlanInfo = styled.div`
  margin: 2px 0;
  font-size: 0.9em;
`;

const Strong = styled.span`
  font-weight: bold;
`;

const WarningInfo = styled.div`
  margin: 20px 0 10px;
  font-size: 0.9em;
`;
