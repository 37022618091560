import React, { useMemo, useCallback } from 'react';
import Cookies from 'js-cookie';

import { styled, media, colors } from 'styles';
import { logoWithoutText } from 'images/main';

import CardTitle from './CardTitle';
import UserSubscriptionCancellation from './UserSubscriptionCancellation';
import {
  SubscriptionInfoChangePlan,
  ChangePlanButton,
} from 'modules/campaigns/change-plan/components';
import { PaymentFailedRecoverButton } from 'modules/campaigns/recover-subscriber/payment-failed-recover/components';
import FreezeSubscriptionOptionsButton from 'modules/campaigns/freeze-subscription/components/FreezeSubscriptionOptionsButton';
import SubscriptionInfoDefault from './SubscriptionInfoDefault';
import { useFreezeSubscriptionCampaign } from 'modules/campaigns/freeze-subscription/hooks';
import { useChangePlanCampaign } from 'modules/campaigns/change-plan/hooks';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import { usePaymentFailedRecoverCampaign } from 'modules/campaigns/recover-subscriber/payment-failed-recover/hooks/PaymentFailedRecoverCampaign';
import {
  useChangeCreditCard,
  ChangeCreditCardButton,
} from 'modules/campaigns/change-credit-card';
import { useChangePlanOnCancelCampaign } from 'modules/campaigns/change-plan-on-cancel/hooks';
import { ChangePlanOnCancelButton } from 'modules/campaigns/change-plan-on-cancel/components';
import { useDate } from 'modules/shared/hooks';
import { SubscriptionInfoBookTrial } from 'modules/campaigns/book-trial/components';

export default function UserSubscription({ subscription, user }) {
  const { getHumanReadableDateFromTimestamp } = useDate();
  const {
    isSubscriptionFreezed,
    isSubscriptionReactivated,
    getFreezedUntilDateInTimestamp,
  } = useFreezeSubscriptionCampaign();
  const {
    isSubscriptionEligibleToChangePlan,
    isOnChangePlanCampaign,
  } = useChangePlanCampaign();
  const { hasCreditCardTrial } = useBookTrialCampaign();
  const { hasPaymentFailedToRecover } = usePaymentFailedRecoverCampaign();
  const { isEligibleToChangeCreditCard } = useChangeCreditCard();
  const { creditCardTrialChangePlan } = useChangePlanOnCancelCampaign();

  const isWaitingCancellationConfirmation = useCallback(() => {
    return !!Cookies.get('cancel_subscription_processing');
  }, []);

  const isScheduledSubscription = useCallback(() => {
    return (
      !user.subscriber &&
      subscription.expiresIn &&
      subscription.order?.state !== 'payment_failed'
    );
  }, [user, subscription]);

  const subscriptionState = useMemo(() => {
    if (isSubscriptionFreezed()) {
      const state = subscriptionStates['freezed'];

      const freezedUntil = getHumanReadableDateFromTimestamp(
        getFreezedUntilDateInTimestamp()
      );
      state.message = `Pausada até ${freezedUntil}`;
      return state;
    }

    if (isSubscriptionReactivated()) {
      return subscriptionStates['reactivated'];
    }

    if (isWaitingCancellationConfirmation()) {
      return subscriptionStates['waiting_void'];
    }

    if (isScheduledSubscription()) {
      return subscriptionStates['scheduled'];
    }

    return subscriptionStates[subscription.order.state];
  }, [
    isSubscriptionFreezed,
    isSubscriptionReactivated,
    isWaitingCancellationConfirmation,
    isScheduledSubscription,
    subscription.order.state,
    getHumanReadableDateFromTimestamp,
    getFreezedUntilDateInTimestamp,
  ]);

  const renderSubscriptionInfo = () => {
    if (isOnChangePlanCampaign())
      return <SubscriptionInfoChangePlan subscription={subscription} />;
    if (hasCreditCardTrial())
      return <SubscriptionInfoBookTrial subscription={subscription} />;

    return <SubscriptionInfoDefault subscription={subscription} />;
  };

  return (
    <Container>
      <CardTitle title="Assinatura" upper />

      <ContentContainer>
        <img className="desktop" src={logoWithoutText} alt="" />

        <Content>
          <SubscriptionTitle color={subscriptionState.color}>
            <img className="mobile" src={logoWithoutText} alt="" />
            <div>
              <h3>Acesso Completo ao Responde Aí</h3>
              <span>{subscriptionState.message}</span>
            </div>
          </SubscriptionTitle>

          {renderSubscriptionInfo()}

          <ButtonsContainer>
            {hasPaymentFailedToRecover() && <PaymentFailedRecoverButton />}

            {isSubscriptionEligibleToChangePlan() && (
              <ChangePlanButton subscription={subscription} />
            )}
            {!isSubscriptionEligibleToChangePlan() &&
              creditCardTrialChangePlan() && (
                <ChangePlanOnCancelButton subscription={subscription} />
              )}

            {isSubscriptionFreezed() && (
              <FreezeSubscriptionOptionsButton subscription={subscription} />
            )}

            {(subscription.order.recurring || user.scheduledOrder) &&
              !isSubscriptionFreezed() &&
              subscription.order.paymentMethod !== 'pix' && (
                <UserSubscriptionCancellation
                  user={user}
                  subscription={subscription}
                />
              )}

            {isEligibleToChangeCreditCard(subscription) && (
              <ChangeCreditCardButton subscription={subscription} />
            )}
          </ButtonsContainer>
        </Content>
      </ContentContainer>
    </Container>
  );
}

const subscriptionStates = {
  voided: {
    message: 'Cancelada',
    color: colors.warningRed,
  },
  waiting_void: {
    message: 'Cancelando...',
    color: colors.warningRed,
  },
  paid: {
    message: 'Ativa',
    color: colors.successGreen,
  },
  opened: {
    message: 'Ativa',
    color: colors.successGreen,
  },
  reactivated: {
    message: 'Reativada',
    color: colors.successGreen,
  },
  freezed: {
    message: '',
    color: '#8DADEC',
  },
  refunded: {
    message: 'Estornada',
    color: '#8DADEC',
  },
  scheduled: {
    message: 'Agendada',
    color: '#8DADEC',
  },
  payment_failed: {
    message: 'Falha no pagamento',
    color: colors.warningRed,
  },
};

const Container = styled.div`
  width: 90%;

  max-width: 720px;
  margin-bottom: 50px;

  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 15px -2px rgba(51, 51, 51, 0.5);
`;

const ContentContainer = styled.div`
  padding: 0 40px 20px 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  img {
    width: 135px;
    height: auto;
    margin-right: 35px;
  }

  img.mobile {
    display: none;
  }

  img.desktop {
    display: block;
  }

  @media ${media.mobile} {
    width: 95%;
    justify-content: center;
    padding: 0 10px 20px 10px;

    img {
      width: 35px;
      margin-right: 15px;
    }

    img.mobile {
      display: block;
    }

    img.desktop {
      display: none;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubscriptionTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;

  div {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 1.1em;
      font-weight: bold;
      margin-bottom: 5px;
    }

    span {
      background-color: ${({ color }) => color};
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      display: flex;
      width: max-content;
      padding: 2px 5px;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
