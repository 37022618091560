import { useTracker } from 'modules/shared/hooks';
import { useCallback } from 'react';
import { useSupportMaterialBanner } from './useSupportMaterialBanner';

export default function useProfessorTracker() {
  const { trackEvent } = useTracker();
  const { bannerWillAppearInThePage } = useSupportMaterialBanner();

  const getSolvedQuestionsFromLists = useCallback(lists => {
    return lists.reduce((acc, list) => acc + (list.solvedQuestions || 0), 0);
  }, []);

  const trackViewProfessorPage = useCallback(
    ({ professorName, disciplineName, lists }) => {
      trackEvent('Página do Professor: Visualizou Página do Professor', {
        Professor: professorName,
        Disciplina: disciplineName,
        'Número de Listas': lists.length,
        'Número de Questões': getSolvedQuestionsFromLists(lists),
        'Tem Banner Material de Apoio': bannerWillAppearInThePage()
          ? 'Sim'
          : 'Não',
      });
    },
    [trackEvent, getSolvedQuestionsFromLists, bannerWillAppearInThePage]
  );

  const trackViewSubjectProfessorPage = useCallback(
    ({ professorName, subjectName, lists }) => {
      trackEvent(
        'Página do Professor: Visualizou Página do Professor (Assunto)',
        {
          Professor: professorName,
          Assunto: subjectName,
          'Número de Listas': lists.length,
          'Número de Questões': getSolvedQuestionsFromLists(lists),
        }
      );
    },
    [trackEvent, getSolvedQuestionsFromLists]
  );

  const trackViewProfessorNotEligiblePage = useCallback(
    ({ professorName, disciplineName, lists }) => {
      trackEvent(
        'Página do Professor: Visualizou Página do Professor Não Elegível',
        {
          Professor: professorName,
          Disciplina: disciplineName,
          'Número de Listas': lists.length,
          'Número de Questões': getSolvedQuestionsFromLists(lists),
        }
      );
    },
    [trackEvent, getSolvedQuestionsFromLists]
  );

  const trackClickPrevPeriod = useCallback(
    ({ period, professorName, disciplineName, numberOfLists }) => {
      trackEvent('Página do Professor: Clicou Período Anterior', {
        Período: period,
        Professor: professorName,
        Disciplina: disciplineName,
        'Número de Listas': numberOfLists,
        //TODO Número de Questões
      });
    },
    [trackEvent]
  );

  const trackClickList = useCallback(
    ({ professorName, disciplineName, listType, list }) => {
      trackEvent('Página do Professor: Clicou Lista', {
        'ID Lista': list.id,
        Professor: professorName,
        Disciplina: disciplineName,
        'Tipo de Lista': listType,
        'Número de Questões': list.solvedQuestions || 0,
      });
    },
    [trackEvent]
  );

  const trackClickBook = useCallback(
    ({ professorName, disciplineName, numberOfLists, bookName, lists }) => {
      trackEvent('Página do Professor: Clicou Livro', {
        Professor: professorName,
        Disciplina: disciplineName,
        'Número de Listas': numberOfLists,
        'Número de Questões': getSolvedQuestionsFromLists(lists),
        Livro: bookName,
      });
    },
    [trackEvent, getSolvedQuestionsFromLists]
  );

  const trackClickLecture = useCallback(
    ({ professorName, disciplineName, lectureSubject, lists }) => {
      trackEvent('Página do Professor: Clicou Aulão', {
        Professor: professorName,
        Disciplina: disciplineName,
        'Número de Listas': lists.length,
        'Número de Questões': getSolvedQuestionsFromLists(lists),
        Assunto: lectureSubject,
      });
    },
    [trackEvent, getSolvedQuestionsFromLists]
  );

  const trackClickSubject = useCallback(
    ({ professorName, disciplineName, subjectName, lists }) => {
      trackEvent('Página do Professor: Clicou Assunto', {
        Professor: professorName,
        Disciplina: disciplineName,
        'Número de Listas': lists.length,
        'Número de Questões': getSolvedQuestionsFromLists(lists),
        Assunto: subjectName,
      });
    },
    [trackEvent, getSolvedQuestionsFromLists]
  );

  const trackClickSendList = useCallback(
    ({ professorName, disciplineName, source, lists }) => {
      trackEvent('Página do Professor: Clicou Enviar Lista', {
        Professor: professorName,
        Disciplina: disciplineName,
        'Número de Listas': lists.length,
        'Número de Questões': getSolvedQuestionsFromLists(lists),
        Local: source,
      });
    },
    [trackEvent, getSolvedQuestionsFromLists]
  );

  const trackClickStudySupport = useCallback(
    ({ professorName, disciplineName, lists }) => {
      trackEvent('Página do Professor: Clicou Ver Materiais de Apoio', {
        Professor: professorName,
        Disciplina: disciplineName,
        'Número de Listas': lists.length,
        'Número de Questões': getSolvedQuestionsFromLists(lists),
      });
    },
    [trackEvent, getSolvedQuestionsFromLists]
  );

  const trackClickSupportBanner = useCallback(
    professor => {
      trackEvent(
        'Página do Professor: Clicou Entendi Banner Material de apoio',
        {
          Professor: professor?.name,
          'ID Professor': professor?.id,
        }
      );
    },
    [trackEvent]
  );

  return {
    trackViewProfessorPage,
    trackViewProfessorNotEligiblePage,
    trackClickPrevPeriod,
    trackClickList,
    trackClickBook,
    trackClickSubject,
    trackClickSendList,
    trackClickLecture,
    trackClickStudySupport,
    trackViewSubjectProfessorPage,
    trackClickSupportBanner,
  };
}
