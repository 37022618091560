import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useForumTracker from 'modules/forum/hooks/useForumTracker';
import { useForumContext } from 'providers/ForumProvider';
import { useUserContext } from 'providers/UserProvider';
import React, { useCallback } from 'react';
import { colors, styled, media } from 'styles';

const isOnBooksPage = () => window.location.pathname.includes('/solucionario/');
const isOnWorkspace = () =>
  window.location.pathname.includes('/aprender/') ||
  window.location.pathname.includes('/praticar/');

function ForumCTA() {
  const { user } = useUserContext();
  const { canShowForumCTA } = useForumContext();
  const { trackClickedInForumCTA } = useForumTracker();

  const scrollToForum = useCallback(() => {
    const elementToScrollIn = document.getElementById('forumContainer');
    if (!elementToScrollIn) return;

    elementToScrollIn.scrollIntoView({
      behavior: 'smooth',
    });

    trackClickedInForumCTA();
  }, [trackClickedInForumCTA]);

  if (isOnWorkspace()) return null;
  if (isOnBooksPage() && !canShowForumCTA) return null;
  if (!user) return null;

  return (
    <>
      {isOnBooksPage() && !media.isMobile() ? (
        <ContainerBooksPage
          onClick={scrollToForum}
          hidden={window.innerWidth < 1170}
        >
          <strong>Ficou com alguma dúvida?</strong>
          <Icon icon={faAngleDoubleDown} color={colors.mainOrange} />
        </ContainerBooksPage>
      ) : (
        <Container isWorkspaceContent={isOnWorkspace()} onClick={scrollToForum}>
          <strong>
            {media.isMobile() ? 'Dúvidas?' : 'Ficou com alguma dúvida?'}
          </strong>
          <Icon icon={faAngleDoubleDown} color={colors.mainOrange} />
        </Container>
      )}
    </>
  );
}

export default ForumCTA;

const ContainerBooksPage = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  position: fixed;

  bottom: 0;
  align-items: center;
  justify-content: center;
  width: calc((100% - 960px) / 2);
  height: '60px';
  background-color: #393939;
  padding: 20px 20px;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  color: white;
  font-size: 1rem;

  z-index: 1000;
`;

const Container = styled.div`
  display: flex;

  position: ${({ isWorkspaceContent }) =>
    isWorkspaceContent ? 'relative' : 'fixed'};

  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ isWorkspaceContent }) => (isWorkspaceContent ? '93px' : '75px')};
  background-color: #393939;
  padding: 20px 20px;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  color: white;
  font-size: 1.15rem;

  z-index: 1000;

  @media ${media.mobile} {
    height: 52px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;
