import React from 'react';

import { styled } from 'styles';
import { ExerciseFeedback } from '../shared';
import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';

export function StepsAndAnswer({ question }) {
  const steps = question.exercise.lightSolution;
  const answer = question.exercise.lightAnswer;

  return (
    <>
      {steps.map((step, index) => (
        <>
          <StepTitle>Passo {index + 1}:</StepTitle>
          {step && <LatexContent text={step} isLatex={true} />}
        </>
      ))}

      <ExerciseFeedback
        question={{ ...question, exerciseType: 'MvpExercise' }}
        origin="fim_da_resolucao"
      />

      {answer && (
        <>
          <StepTitle>Resposta:</StepTitle>
          <LatexContent text={answer} isLatex={true} />
        </>
      )}
    </>
  );
}

const StepTitle = styled.h1`
  color: #6a0d91;
  font-weight: bold;
  font-size: 1.4em;
  text-decoration: underline;
  text-align: left;
`;

const LatexContent = styled(MathjaxContentWithLatex)`
  color: #5d5d5d;
  margin-top: 15px;
  max-width: 100%;
`;
