import React, { useCallback, useEffect } from 'react';
import {
  useRAQuery,
  useContentQueryImmutable,
} from 'modules/shared/hooks/requests';
import styled from 'styled-components';
import { LiveLecturesCard } from '../components/';
import { useTracker, useUTM } from 'modules/shared/hooks';
import {
  TitleWithImage,
  LecturesHeader,
  ThemesAndLecturesSliders,
} from '../components/';
import { Loading, Error } from 'modules/shared/components/generic';

const LecturesPage = () => {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();
  const { data, error, loading } = useRAQuery('/live_lectures');
  const {
    data: themesData,
    error: themesError,
    loading: themesLoading,
  } = useContentQueryImmutable('/themes/with-lectures');

  const getThemes = useCallback(themes => {
    return themes.map(theme => {
      return {
        id: theme.id,
        color: theme.color,
        name: theme.name,
      };
    });
  }, []);

  useEffect(() => {
    trackEvent('Aulão: Visualizou todos os aulões', {
      ...getUTMsByLocation(window.location),
      Plataforma: 'Web',
    });
  }, [trackEvent, getUTMsByLocation]);

  if (error || themesError) return <Error />;
  if (loading || themesLoading) return <Loading />;

  return (
    <>
      {!data.liveLectures?.length && <LecturesHeader />}
      <Container>
        {!!data.liveLectures?.length && (
          <LiveLecturesCard
            liveLectures={data.liveLectures}
            themes={getThemes(themesData)}
          />
        )}
        <ContentContainer haveLiveLectures={!!data.liveLectures?.length}>
          {data.liveLectures?.length ? (
            <TitleWithImage />
          ) : (
            <Title>MATÉRIAS DISPONÍVEIS</Title>
          )}
          <ThemesAndLecturesSliders themes={themesData} />
        </ContentContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${({ haveLiveLectures }) =>
    haveLiveLectures ? '800px' : '960px'};
`;

const Title = styled.div`
  width: 100%;
  color: #666;
  font-weight: 600;
  text-align: center;
  margin: 30px 0 30px 0;
`;

export default LecturesPage;
