import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';
import { useMvpBooksTracker } from 'modules/campaigns/mvp-books/hooks/useMVpBooksTracker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BookListExamToggle = ({ examName, lists }) => {
  const [open, setOpen] = useState(false);

  const { trackClickList } = useMvpBooksTracker();

  const calculateToggleHeight = useCallback(() => {
    const height = lists.length * 45 + 40;

    return `${height}px`;
  }, [lists.length]);

  const orderedLists = useMemo(() => {
    return lists.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  }, [lists]);

  const openMvpListTab = useCallback(
    list => {
      trackClickList({
        listId: list.id,
        professor: list.listProfessor?.name || list.professor,
        origin: 'Pagina Disciplina',
        tipo: list.type,
      });

      const utmSource = 'site_navegacao';
      const utmMedium = 'botao_lista';
      const utmContent = 'pag_disciplina';

      if (list.type === 'Seção') {
        window.open(
          `${process.env.REACT_APP_MVP_URL}/listas/${list.id}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}`,
          '_blank'
        );
      } else {
        window.open(
          `${process.env.REACT_APP_REACT_URL}/lista-de-enunciado/${list.id}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}`,
          '_blank'
        );
      }
    },
    [trackClickList]
  );

  return (
    <ExamToggle>
      <ExamName onClick={() => setOpen(!open)}>
        <AngleIcon icon={open ? faAngleUp : faAngleDown} />
        <ExamTitle>{`${examName}`}</ExamTitle>
      </ExamName>
      <ToggleableComponent
        open={open}
        animationTime="0.3s"
        height={calculateToggleHeight()}
      >
        <Lists>
          {orderedLists.map((list, i) => (
            <BookListItem key={list.id} onClick={() => openMvpListTab(list)}>
              <p>{`Lista ${i + 1}`}</p>
            </BookListItem>
          ))}
        </Lists>
      </ToggleableComponent>
    </ExamToggle>
  );
};

const ExamToggle = styled.div`
  padding: 5px 0 5px 15px;
`;

const AngleIcon = styled(FontAwesomeIcon)`
  right: 9px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.1em;
  color: #a4a4a4;
`;

const ExamName = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const ExamTitle = styled.p`
  margin-left: 4px;
`;

const Lists = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(112, 192, 199, 0.08);

  padding: 15px 10px;
  border-radius: 10px;
  margin-top: 7px;
`;

const BookListItem = styled.li`
  max-width: 94%;
  display: flex;
  text-align: left;
  padding: 8px 10px;
  font-size: 0.9em;
  color: #666;
  list-style: none;
  transition: background-color 0.4s ease-in-out;
  cursor: pointer;

  p {
    margin-left: 10px;
  }

  &:hover {
    background-color: rgba(158, 158, 158, 0.2);
    border-radius: 6px;
  }
`;

export default BookListExamToggle;
