import React, { useEffect, useState, useCallback } from 'react';
import { Checkbox } from 'modules/shared/components/inputs';
import { useMemo } from 'react';
import { useRAQuery } from 'modules/shared/hooks/requests';

function TermsAndContactCheckbox({ onChange }) {
  const [terms, setTerms] = useState(false);
  const { data: userTerms } = useRAQuery('/profile/accepted_terms');

  useEffect(() => {
    onChange(terms);
  }, [onChange, terms]);

  useEffect(() => {
    if (userTerms) {
      setTerms(userTerms.acceptedTerms);
    }
  }, [userTerms]);

  const handleCheck = useCallback(
    terms => {
      setTerms(terms);
    },
    [setTerms]
  );

  const altText = useMemo(() => {
    return 'Ao concordar, você aceita receber comunicações sobre novidades, conteúdos gratuitos e oportunidades do mercado de trabalho';
  }, []);
  return (
    <>
      <Checkbox
        name="profile-completer-select-accept-terms"
        label="Concordo com os "
        checked={terms}
        setChecked={handleCheck}
        linkLabel="Termos de Uso"
        linkUrl="https://www.respondeai.com.br/termos-de-uso"
        altText={altText}
      />
    </>
  );
}

export default TermsAndContactCheckbox;
