import React, { useMemo } from 'react';
import { styled, colors } from 'styles';
import BookSection from './BookSection';
import LectureSection from './LectureSection';
import GuideSection from './GuideSection';

export default function StudySupportSection({
  professor,
  studySupportMaterial,
}) {
  const lectures = useMemo(() => {
    const subjectsWithLectures = studySupportMaterial.subjects.filter(
      subject => !!subject.lecture
    );
    return subjectsWithLectures.map(subject => ({
      ...subject.lecture,
      imagePath: subject.imagePath,
      subjectName: subject.name,
    }));
  }, [studySupportMaterial]);

  return (
    <Container>
      <Title>MATERIAIS DE APOIO</Title>

      {studySupportMaterial.books.length > 0 && (
        <BookSection professor={professor} books={studySupportMaterial.books} />
      )}

      {lectures.length > 0 && (
        <LectureSection professor={professor} lectures={lectures} />
      )}

      <GuideSection
        professor={professor}
        subjects={studySupportMaterial.subjects}
        guideId={studySupportMaterial.id}
      />
    </Container>
  );
}

const Container = styled.div`
  padding: 25px 20px;
  width: 1400px;
  max-width: 95%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  margin-top: -110px;
`;

const Title = styled.p`
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  color: ${colors.mainOrange};
  margin-bottom: 30px;
`;
