import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled, media } from 'styles';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { MdUploadFile } from 'react-icons/md';
import { useProfessorTracker } from 'modules/guides/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import ListLink from './ListLink';

export default function ListToggle({
  professor,
  lists,
  examName,
  examGrouped,
  opened = false,
  mutateProfessorQuery,
}) {
  const [isOpen, setIsOpen] = useState(opened);
  const [maxHeight, setMaxHeight] = useState('0px');
  const contentRef = useRef(null);

  const { trackClickSendList } = useProfessorTracker();
  const { setDialog } = useDialogContext();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setMaxHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [isOpen]);

  const handleSentListClick = useCallback(() => {
    trackClickSendList({
      professorName: professor.name,
      disciplineName: professor.teachable.name,
      lists: [...professor.lists, ...professor.statementLists],
      source: 'Botão dentro da prova',
    });

    setDialog(
      <UploadBookListDialog
        defaultThemeId={professor.teachable.theme?.id}
        defaultTeachable={{ id: professor.teachable.id, type: 'Discipline' }}
        defaultProfessor={{
          id: professor.id,
          name: professor.name,
        }}
        defaultExam={examGrouped}
        utms={{
          utmSource: 'site_upload',
          utmMedium: ' botao_enviar_lista_dentro_toggle',
          utmContent: 'pag_prof_elegivel',
        }}
      />
    );
  }, [professor, setDialog, examGrouped, trackClickSendList]);

  return (
    <Container>
      <ToggleContainer onClick={toggle}>
        <ToggleTitle>{`${examName} (${lists.length} ${
          lists.length === 1 ? 'lista resolvida' : 'listas resolvidas'
        })`}</ToggleTitle>
        {isOpen ? <AngleUpIcon /> : <AngleDownIcon />}
      </ToggleContainer>

      <ToggleContent ref={contentRef} style={{ maxHeight }}>
        <MarginContainer style={{ marginBottom: '10px' }}>
          <SendListButton onClick={handleSentListClick}>
            <UploadIcon />
            Enviar Lista
          </SendListButton>
          {lists?.map((list, i) => (
            <ListLink
              professor={professor}
              list={list}
              name={`Lista ${i + 1}`}
              key={list.id}
              mutateProfessorQuery={mutateProfessorQuery}
              utmContent="pag_prof_elegivel"
            />
          ))}
        </MarginContainer>
      </ToggleContent>
    </Container>
  );
}

const MarginContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 14px;
`;

const ToggleContent = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease;
  padding: 0 14px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #70bfc7;
  border-radius: 10px;
  margin-bottom: 10px;
  user-select: none;
`;

const ToggleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 14px;
  @media ${media.mobile} {
    padding: 10px 10px;
  }

  cursor: pointer;
`;

const ToggleTitle = styled.span`
  font-size: 18px;
  color: #515151;
`;

const AngleUpIcon = styled(FaAngleUp)`
  font-size: 30px;
  color: #515151;
`;

const AngleDownIcon = styled(FaAngleDown)`
  font-size: 30px;
  color: #515151;
`;

//TODO unificar esse component
const SendListButton = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #70bfc7;
  color: #70bfc7;
  margin: 5px 10px 5px 0;
  padding: 10px 15px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    background-color: #70bfc732;
  }
`;

const UploadIcon = styled(MdUploadFile)`
  font-size: 17px;
  font-weight: lighter;
  color: #70bfc7;
`;
