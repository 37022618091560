import React from 'react';
import { Route } from 'react-router-dom';
import ThemePage from './pages/ThemePage';
import ThemesPage from './pages/ThemesPage';

export default [
  <Route exact path="/materias" component={ThemesPage} key="materias" />,
  <Route
    exact
    path="/materias/completas/:id"
    component={ThemePage}
    key="materia-completa"
  />,
];
