import React, { useEffect } from 'react';
import { useUserContext } from 'providers/UserProvider';
import { useGTM } from 'modules/shared/hooks';
import ProfileComplete from '../components/profile-complete/ProfileComplete';

export default function ProfileCompletePage() {
  const { user } = useUserContext();
  const { addVariableToGTMDataLayer } = useGTM();

  useEffect(() => {
    addVariableToGTMDataLayer({ event: 'iniciou_cadastro' });
  }, [addVariableToGTMDataLayer]);

  if (!user) location.href = '/materias';

  return <ProfileComplete from="profile complete" />;
}
