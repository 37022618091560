import { useEffect, useCallback } from 'react';

function useOutsideClick(ref, handler) {
  const executeHandler = useCallback(
    e => {
      if (ref.current && !ref.current.contains(e.target)) {
        handler();
      }
    },
    [ref, handler]
  );

  useEffect(() => {
    document.addEventListener('mousedown', executeHandler);

    return () => {
      document.removeEventListener('mousedown', executeHandler);
    };
  }, [executeHandler]);
}

export { useOutsideClick };
