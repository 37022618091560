import React from 'react';
import { styled } from 'styles';
import { useLectureContext } from '../../providers/LectureProvider';

const ModuleDivider = () => {
  const { currentModuleIndex: moduleIndex } = useLectureContext();
  return (
    <Divider>
      <span>pratique o módulo {moduleIndex + 1}</span>
    </Divider>
  );
};

const Divider = styled.div`
  margin: 10px 0;
  border-top: 1px solid #ddd;
  width: 100%;
  font-size: 0.7em;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;

  span {
    position: relative;
    background: #fff;
    top: -7px;
    padding: 0 10px;
    color: #888;
  }
`;

export default ModuleDivider;
