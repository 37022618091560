import React, { useEffect, useMemo, useCallback } from 'react';
import { addMonths, format } from 'date-fns';

import { styled } from 'styles';
import { useTracker } from 'modules/shared/hooks';
import {
  Container,
  Content,
  Title,
  ButtonsContainer,
  OptionButton,
} from 'modules/campaigns/recover-subscriber/styles';
import { usePaymentFailedRecoverCampaign } from 'modules/campaigns/recover-subscriber/payment-failed-recover/hooks';

export default function RetrySuccessDialog({ source, pricing }) {
  const { trackEvent } = useTracker();
  const { defaultAmplitudeEventAttributes } = usePaymentFailedRecoverCampaign();

  useEffect(() => {
    trackEvent('MVP Falha de Pagamento 2.0: Visualizou Sucesso', {
      utm_source: source,
      opcao: 'mesmo cartao',
      plan: pricing.description,
      ...defaultAmplitudeEventAttributes,
    });
  }, [source, trackEvent, pricing, defaultAmplitudeEventAttributes]);

  const nextRenovationDate = useMemo(() => {
    const newRenovationDate = addMonths(new Date(), pricing.months);

    return format(newRenovationDate, 'dd/MM/yyyy');
  }, [pricing]);

  const bonusLink = useMemo(() => {
    return 'https://storage.googleapis.com/responde-ai-static/planners/PLANNEREDITAVEL_2023.pdf';
  }, []);

  const trackBonusClick = useCallback(() => {
    trackEvent('MVP Falha de Pagamento 2.0: Clicou Bonus', {
      utm_source: source,
      opcao: 'mesmo cartao',
      plan: pricing.description,
      ...defaultAmplitudeEventAttributes,
    });
  }, [
    pricing.description,
    source,
    trackEvent,
    defaultAmplitudeEventAttributes,
  ]);

  const onConfirmClick = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectUrl =
      searchParams.get('redirect_url') || searchParams.get('amp;redirect_url');
    if (redirectUrl) {
      window.location.href = decodeURI(redirectUrl);
      return;
    }

    const url = window.location.href;
    if (url.includes('minha-conta')) {
      window.location.href = `${process.env.REACT_APP_RESPONDEAI_URL}/materias`;
      return;
    }

    window.location.reload();
  }, []);

  return (
    <Container>
      <Content>
        <Title>Cobrança realizada com sucesso</Title>
        <PaymentInfo>
          Parabéns, seu acesso ao Responde Aí foi liberado! Conseguimos realizar
          a cobrança de{' '}
          <Strong>
            R$ {pricing.price.toFixed(2)}{' '}
            {pricing.months > 1 &&
              `(${pricing.months}x de R$${pricing.monthlyPricing.toFixed(2)})`}
          </Strong>{' '}
          em seu cartão de crédito.
        </PaymentInfo>
        <PaymentInfo>
          Agora, seu plano passa a ter renovação automática com a próxima data
          de renovação sendo <Strong>{nextRenovationDate}</Strong>. Caso queira
          cancelar a renovação automática, basta acessar o menu de Minha Conta e
          efetuar o cancelamento.
        </PaymentInfo>
        <BonusLink href={bonusLink} onClick={trackBonusClick} target="_blank">
          Bonus: clique aqui e baixe o planner de estudos
        </BonusLink>
        <ButtonsContainer>
          <OptionButton onClick={onConfirmClick}>
            Começar a estudar
          </OptionButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const PaymentInfo = styled.div`
  font-size: 1.2em;
  margin: 15px 0;
`;

const Strong = styled.span`
  font-weight: bold;
`;

const BonusLink = styled.a`
  font-size: 1.2em;
  text-decoration: underline;
  cursor: pointer;
`;
