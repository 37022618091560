import React, { useMemo } from 'react';

import CardInfoField from 'modules/profile/components/CardInfoField';
import { useCurrency } from 'modules/shared/hooks';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';

export default function SubscriptionInfoBookTrial({ subscription }) {
  const {
    bookCreditCardTrialFreeAccessEnd,
    bookCreditCardTrialFirstCharge,
  } = useBookTrialCampaign();
  const { formatCurrency } = useCurrency();

  const formattedPrice = useMemo(() => {
    const { order, plan } = subscription;

    if (order.installments && order.installments > 1) {
      const monthlyPrice = order.planPricing.price / plan.months;
      return `${plan.description} ${order.installments}x R$${formatCurrency(
        monthlyPrice
      )}`;
    }

    return `${plan.description} R$${formatCurrency(order.planPricing.price)}`;
  }, [subscription, formatCurrency]);

  return (
    <>
      <CardInfoField
        fieldId="plan"
        label={`Plano até dia ${bookCreditCardTrialFreeAccessEnd()}`}
      >
        Gratuito
      </CardInfoField>

      <CardInfoField
        fieldId="plan"
        label={`Plano a partir de ${bookCreditCardTrialFirstCharge()}`}
      >
        {formattedPrice}
      </CardInfoField>

      <CardInfoField fieldId="payment" label="Meio de Pagamento">
        Cartão
      </CardInfoField>
    </>
  );
}
