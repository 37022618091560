import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import CompleteGuideIcon from '../images/complete-guide.png';
import CardsContainer from './CardsContainer';
import ProgressInfo from './ProgressInfo';
import StudyPlanEditTopicButton from './StudyPlanEditTopicButton';
import { SyllabusFeedback } from 'modules/feedback/components';
import { useSyllabusFeedback } from 'modules/feedback/hooks';
import { useGuide } from 'modules/guides/hooks';
import { useRAQuery } from 'modules/shared/hooks/requests';
import { colors, styled, media } from 'styles';

export default function SubjectsCards({
  subjects,
  color,
  guideId,
  guidableType,
  studyPlan,
  name,
  isFree = false,
  shouldHaveFreeTag = false,
}) {
  const history = useHistory();
  const { isStudyPlan } = useGuide();
  const {
    showTopSyllabusFeedback,
    showBottomSyllabusFeedback,
  } = useSyllabusFeedback();

  const flagsQuery = useRAQuery('/flags');

  const flags = useMemo(() => {
    if (flagsQuery.loading || flagsQuery.error) return null;

    return flagsQuery.data?.flags;
  }, [flagsQuery]);

  const getChapters = useCallback(() => {
    return _.flatten(subjects.map(subject => subject.chapters));
  }, [subjects]);

  const getChapterIndex = useCallback(
    chapter => {
      const chapters = getChapters();
      return chapters.findIndex(c => c.id === chapter.id) + 1;
    },
    [getChapters]
  );

  const getChapterLinkUrl = useCallback(
    chapter => {
      const firstTopic = chapter.topics[0];
      const utmParams = history.location.search;
      if (guideId)
        return `/aprender/${guideId}/topico/${chapter.subjectId}/${firstTopic.id}/teoria/${firstTopic.theory.id}${utmParams}`;

      return `/aprender/topico/${chapter.subjectId}/${firstTopic.id}/teoria/${firstTopic.theory.id}${utmParams}`;
    },
    [guideId, history]
  );

  const chapterIsChecked = useCallback(
    chapter => {
      if (!flags) return false;

      const flagsFiltered = guideId
        ? flags.filter(flag => parseInt(flag.guideId) === guideId)
        : flags;

      return !!flagsFiltered.find(
        flag =>
          flag.contentType === 'capitulo' &&
          parseInt(flag.contentId) == chapter.id
      );
    },
    [flags, guideId]
  );

  return (
    <CardsContainer
      color={colors.mainOrange}
      title="Guia completão"
      icon={CompleteGuideIcon}
      width="300px"
      shouldHaveFreeTag={shouldHaveFreeTag}
      source={
        isFree
          ? 'subjectsCardsFromFreeDiscipline'
          : 'subjectsCardsFromNotFreeDiscipline'
      }
    >
      <Content>
        {showTopSyllabusFeedback(guidableType, guideId, flags) && (
          <SyllabusFeedback contentName={name} guidableId={guideId} />
        )}
        <ProgressInfo
          flags={flags}
          chapters={getChapters()}
          guideId={guideId}
        />
        {subjects.map((subject, index) => {
          return (
            <Item key={subject.id}>
              <SubjectItem color={color} className={index === 0 ? 'first' : ''}>
                <SubjectItemImg src={subject.imagePath} />
                <SubjectItemName>{subject.name}</SubjectItemName>
              </SubjectItem>
              {subject.chapters.map(chapter => (
                <ChapterItem
                  key={chapter.id}
                  to={{
                    pathname: getChapterLinkUrl(chapter),
                    state: { previousUrl: location.pathname },
                  }}
                >
                  <CheckIcon
                    icon={faCheck}
                    className={chapterIsChecked(chapter) ? 'checked' : ''}
                  />
                  <ChapterItemName>
                    {getChapterIndex(chapter)}. {chapter.name}
                  </ChapterItemName>
                </ChapterItem>
              ))}
            </Item>
          );
        })}
      </Content>
      {isStudyPlan(guidableType) && media.isDesktop() && (
        <StudyPlanEditTopicButton guideId={guideId} studyPlan={studyPlan} />
      )}
      {showBottomSyllabusFeedback(guidableType, guideId, flags) &&
        media.isDesktop() && (
          <SyllabusFeedback
            contentName={name}
            bottom={true}
            guidableId={guideId}
          />
        )}
    </CardsContainer>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px 10px 15px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubjectItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({ color }) => color};
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 0.9em;
  margin: 10px 0 5px 0;
  cursor: pointer;

  &.first {
    margin-top: 0;
  }
`;

const SubjectItemImg = styled.img`
  width: 50px;
  border-radius: 5px;
`;

const SubjectItemName = styled.div`
  color: #fff;
  font-size: 0.8em;
  margin-left: 12px;
`;

const ChapterItem = styled(Link)`
  display: flex;
  align-items: center;
  transition: background 100ms ease-in-out;
  cursor: pointer;
  border-radius: 5px;
  padding: 8px 0;
  color: ${colors.darkGray};

  &:hover {
    background-color: #f9f9f9;
  }
`;

const ChapterItemName = styled.div`
  font-size: 0.75em;

  @media ${media.mobile} {
    font-size: 0.8em;
  }
`;

const CheckIcon = styled(FontAwesomeIcon)`
  float: left;
  margin: 0 10px;
  color: #ddd;
  padding-top: 3px;
  font-size: 1.1em;

  &.checked {
    color: ${colors.check};
  }
`;
