import React, { useCallback } from 'react';

import { SideMenuItems } from '../shared';
import ListCategoryTitle from './ListCategoryTitle';
import ListLink from './ListLink';

export default function ListCategory({
  listCategory,
  open,
  onListCategoryClick,
}) {
  const renderTitle = useCallback(() => {
    return <ListCategoryTitle listCategory={listCategory} open={open} />;
  }, [listCategory, open]);

  const renderItems = useCallback(() => {
    return listCategory.lists.map(list => (
      <ListLink key={list.id} list={list} />
    ));
  }, [listCategory]);

  return (
    <SideMenuItems
      open={open}
      onClick={() => onListCategoryClick(listCategory)}
      renderTitleComponent={renderTitle}
      renderItems={renderItems}
      spaceOnBottom={true}
    />
  );
}
