import { useDialogContext } from 'providers/DialogProvider';
import React from 'react';

import { styled, colors } from 'styles';
import { DeleteAccountDialog } from '../dialogs/DeleteAccountDialog';

export function DeleteButton() {
  const { setDialog } = useDialogContext();

  return (
    <Button onClick={() => setDialog(<DeleteAccountDialog />)}>
      Deletar conta
    </Button>
  );
}

const Button = styled.button`
  padding: 10px 30px;
  background-color: ${colors.lightGray};
  color: #fff;
  font-size: 1.2em;
  border-radius: 5px;

  &:hover {
    background-color: ${colors.warningRed};
  }
`;
