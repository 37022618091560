import { urlByResult } from './helper';

function pad(num) {
  return (num < 10 ? '0' : '') + num;
}

function dateIsoString() {
  const date = new Date();

  let tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
    date.getSeconds()
  )}${dif}${pad(Math.floor(Math.abs(tzo) / 60))}:${pad(Math.abs(tzo) % 60)}`;
}

function dateOnly() {
  const date = new Date();

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}`;
}

export default class SearchEvents {
  constructor(trackers) {
    this.amplitudeTracker = trackers.amplitudeTracker;
    this.elasticTracker = trackers.elasticTracker;
    this.elasticResultsTracker = trackers.elasticResultsTracker;
  }

  closeDialog(term, theme) {
    this.elasticTracker({
      search_action: 'closed_search',
      date: dateIsoString(),
      dateOnly: dateOnly(),
      term: term || '',
      theme: theme ? { id: theme.id, name: theme.name } : {},
    });
  }

  clickInResultAmplitudeTracker(term, index, resultType, where, inline) {
    this.amplitudeTracker('Busca: Resultado Clicado', {
      Versão: '2.0',
      Onde: where,
      'Termo Buscado': term,
      'Posicao do resultado': index + 1,
      'Busca direto no matérias': inline,
      Filtro: resultType,
    });
  }

  clickInResultElasticTracker(term, index, resultType, theme, result) {
    const logData = {
      search_action: 'clicked_in_result',
      date: dateIsoString(),
      dateOnly: dateOnly(),
      term,
      result: {
        url: urlByResult(result),
        position: index + 1,
        type: result.type,
        filter: resultType,
      },
    };

    if (theme) logData.theme = { id: theme.id, name: theme.name };

    this.elasticTracker(logData);
  }

  clickInResult(term, index, resultType, theme, where, result, inline) {
    this.clickInResultElasticTracker(term, index, resultType, theme, result);
    this.clickInResultAmplitudeTracker(term, index, resultType, where, inline);
  }

  openDialog(where, inline) {
    this.amplitudeTracker('Busca: Abriu Dialog', {
      Versão: '2.0',
      Onde: where,
      'Busca direto no matérias': inline,
    });
  }

  typeOnSearch(term, where, inline) {
    this.amplitudeTracker('Busca: Digitou na Busca', {
      Versão: '2.0',
      Onde: where,
      'Termo Buscado': term,
      'Busca direto no matérias': inline,
    });
  }

  search(term, theme, where, allResults, parsedResults, time, inline) {
    const logData = {
      search_action: 'search',
      date: dateIsoString(),
      dateOnly: dateOnly(),
      term,
      timems: time,
      results: {
        topics: parsedResults.topics.length,
        bookExercises: parsedResults.bookExercises.length,
        exercises: parsedResults.exercises.length,
      },
    };

    if (theme) logData.theme = { id: theme.id, name: theme.name };

    this.elasticTracker(logData);

    this.amplitudeTracker('Busca: Visualizou Resultados', {
      Versão: '2.0',
      Onde: where,
      'Tempo que demorou para buscar (ms)': time,
      'Termo Buscado': term,
      'Busca direto no matérias': inline,
    });

    const resultLog = [];
    let i = 0;
    allResults.map(it => {
      const url = urlByResult(it);

      if (url) {
        resultLog.push({
          date: dateIsoString(),
          dateOnly: dateOnly(),
          url: url,
          type: it.type,
          position: i + 1,
        });

        i++;
      }
    });

    this.elasticResultsTracker({ resultLog });
  }

  gotSuggestions(term, time) {
    this.amplitudeTracker('Busca: Visualizou Sugestões', {
      Versão: '2.0',
      'Tempo que demorou para buscar (ms)': time,
      'Termo Buscado': term,
    });
  }

  clickInSuggestion(term, index) {
    this.amplitudeTracker('Busca: Clicou em Sugestão', {
      Versão: '2.0',
      'Termo Buscado': term,
      'Posição da Sugestão': index,
    });
  }

  bounceTheSearch(term, timeKeptOnSearch, url) {
    this.amplitudeTracker('Busca: rejeitou resultado da Busca', {
      Versão: '2.0',
      'Termo Buscado': term,
      'Visualizou o resultado por (ms)': timeKeptOnSearch,
      URL: url,
    });
  }
}
