import React from 'react';
import { styled, media } from 'styles';
import { BiNotepad } from 'react-icons/bi';
import { useUserContext } from 'providers/UserProvider';

export default function ContentTitle({
  title,
  color,
  underline = true,
  onClick = null,
}) {
  const { user } = useUserContext();
  return (
    <ContainerTitle underline={underline} onClick={onClick}>
      <Icon fill={color} />
      <Title underline={underline}>{`${title} ${
        user.university.gender === 'F' ? 'da' : 'do'
      } ${user.university.acronym}`}</Title>
    </ContainerTitle>
  );
}

const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: ${({ underline }) => (underline ? 'pointer' : 'auto')};

  @media ${media.mobile} {
    justify-content: start;
  }
`;

const Icon = styled(BiNotepad)`
  font-size: 32px;
  flex-shrink: 0;
`;

const Title = styled.h3`
  font-weight: 900;
  font-size: 20px;
  line-height: 16px;
  color: #858585;
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  text-decoration-color: #aaa;
  margin-left: 15px;

  @media ${media.mobile} {
    font-size: 15px;
    text-align: left;
    line-height: 19px;
  }
`;
