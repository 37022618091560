import React, { useState } from 'react';

import { colors, styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { GenericDialog } from 'modules/shared/dialogs';
import { useRAPostRequest } from 'modules/shared/hooks/requests';

export default function ErrorReportInput({ setShow, data }) {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { setDialog } = useDialogContext();

  const sendErrorReport = useRAPostRequest('error_reports/send_report');

  async function submit() {
    setLoading(true);
    try {
      await sendErrorReport({
        data: JSON.stringify({
          ...data,
          message,
        }),
      });
      setDialog(
        <GenericDialog
          title="Muito obrigado pelo seu feedback!"
          message="Pode ficar tranquilo que nosso expert responsável já foi avisado e vai resolver o mais rápido possível."
        />
      );
      setShow(false);
    } catch (_) {
      setDialog(
        <GenericDialog
          title="Erro"
          message="Houve um erro na comunicação com o servidor. Por favor, tente novamente mais tarde."
        />
      );
    }

    setLoading(false);
  }

  return (
    <Container>
      <p>Achou algum erro?</p>
      <FeedbackContent
        value={message}
        onChange={e => setMessage(e.target.value)}
        rows={5}
      />

      <SendFeedbackButton onClick={submit}>
        {loading ? 'ENVIANDO...' : 'ENVIAR'}
      </SendFeedbackButton>
    </Container>
  );
}

const Container = styled.div`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: rgb(255, 255, 255);
  font-family: 'Lato', sans-serif;
  position: absolute;
  right: 50px;
  top: 0;
  width: 300px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px;
  cursor: default;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  border-top: 10px solid ${colors.ErrorReportRed};
  margin: 0;
  z-index: 10;

  p {
    color: ${colors.ErrorReportRed};
  }
`;

const FeedbackContent = styled.textarea`
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  width: 100%;
  background: #eee;
  border-radius: 8px;
  resize: vertical;
  padding: 8px;
  color: #888;
  margin-top: 3px;
  margin-bottom: 0px;
  height: 110px;
  border: 0;
  resize: vertical;
`;

const SendFeedbackButton = styled.button`
  font-family: 'Lato', sans-serif;
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  height: 46px;
  line-height: 46px;
  border: 1px solid ${colors.ErrorReportRed};
  border-radius: 30px;
  font-size: 0.9em;
  font-weight: bold;
  color: ${colors.ErrorReportRed};
  text-align: center;
  padding: 0 20px;
  margin: 10px 0 2px 0;
  min-width: 150px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.ErrorReportRed};
    color: white;
  }
`;
