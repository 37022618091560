import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format as formatDate, isToday, addDays } from 'date-fns';

import { styled, media, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker, useDate } from 'modules/shared/hooks';
import { useFreezeSubscriptionCampaign } from 'modules/campaigns/freeze-subscription/hooks';
import { useRAPostRequest } from 'modules/shared/hooks/requests';

export default function FreezeSubscriptionReactivate({ subscription }) {
  const [loading, setLoading] = useState(false);
  const { unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const { getHumanReadableDateFromTimestamp } = useDate();
  const { getFreezedUntilDateInTimestamp } = useFreezeSubscriptionCampaign();
  const freezeSubscriptionReactivateReq = useRAPostRequest(
    'campaigns/freeze_subscription/reactivate'
  );

  useEffect(() => {
    trackEvent('Congelamento Assinatura: REATIVAR Visualizou');
  }, [trackEvent]);

  const formattedFreezedUntil = useMemo(() => {
    return getHumanReadableDateFromTimestamp(getFreezedUntilDateInTimestamp());
  }, [getFreezedUntilDateInTimestamp, getHumanReadableDateFromTimestamp]);

  const reactiveSubscriptionFormattedDate = useMemo(() => {
    const currentDate = new Date();
    const expiresInDate = new Date(subscription.expiresIn * 1000);
    if (
      isToday(expiresInDate) ||
      expiresInDate.getTime() < currentDate.getTime()
    ) {
      const result = addDays(currentDate, 1);
      return formatDate(result, 'dd/MM/yyyy');
    }

    return formatDate(expiresInDate, 'dd/MM/yyyy');
  }, [subscription]);

  const handleReactiveSubscriptionPress = useCallback(async () => {
    if (loading) return;

    trackEvent('Congelamento Assinatura: REATIVAR Clicou Reativar');
    setLoading(true);

    try {
      await freezeSubscriptionReactivateReq();
      window.location.reload(false);
    } catch (e) {
      alert(
        'Tivemos um problema ao reativar sua assinatura. Por favor, tente novamente ou entre em contato com o atendimento.'
      );
      setLoading(false);
      return;
    }
  }, [freezeSubscriptionReactivateReq, loading, trackEvent]);

  const handleBackPress = useCallback(() => {
    trackEvent('Congelamento Assinatura: REATIVAR Clicou Voltar');
    unsetDialog();
  }, [unsetDialog, trackEvent]);

  return (
    <Container>
      <Content>
        <CancelStrip>Reativar assinatura</CancelStrip>
        <Title>
          Deseja reativar sua assinatura e recuperar seu acesso ao Responde Aí?
        </Title>
        <Paragraph>
          Atualmente, você já está com sua assinatura pausada até o dia{' '}
          {formattedFreezedUntil}. Após essa data, sua assinatura voltará a
          ficar ativa automaticamente.
          <br />
          <br />
          Caso opte por reativar sua assinatura agora, você receberá uma
          cobrança no dia {reactiveSubscriptionFormattedDate} e assim que o
          pagamento for confirmado pelo nosso sistema, seu acesso será
          normalizado.
        </Paragraph>

        <ButtonsContainer>
          <button onClick={handleBackPress} type="button">
            Voltar
          </button>

          <button
            onClick={handleReactiveSubscriptionPress}
            className="positive"
          >
            {loading ? 'Carregando... aguarde' : 'Reativar Assinatura'}
          </button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-top: 10px solid ${colors.darkGreen};
  text-align: left;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const CancelStrip = styled.div`
  display: inline-block;
  padding: 3px 10px;
  position: relative;
  left: -48px;
  margin-bottom: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${colors.darkGreen};
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  @media ${media.mobile} {
    left: -24px;
  }
`;

const Title = styled.h1`
  margin-bottom: 15px;
  font-size: 1.6em;
  font-weight: bold;
  color: ${colors.darkGreen};
`;

const Paragraph = styled.p`
  font-size: 1em;
  margin: 20px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 500px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 60px;

  button {
    width: 45%;
    font-size: 0.95em;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 20px 20px 0;
    height: 50px;
    border: 2px solid #989898;
    color: #989898;
    text-transform: uppercase;
    font-weight: bold;

    &.positive {
      border-color: ${colors.darkGreen};
      color: ${colors.darkGreen};
    }
  }

  @media ${media.mobile} {
    flex-direction: column-reverse;

    button {
      width: 95%;
    }
  }
`;
