import { useCallback } from 'react';

import { useTracker } from 'modules/shared/hooks';

export function useProfessorTracker() {
  const { trackEvent } = useTracker();

  const trackOpenUserProfessorCreateDialog = useCallback(
    (theme, discipline, source) => {
      trackEvent(
        'Home com Professor: Visualizou Dialog de Criar Guia do Professor',
        {
          Matéria: theme?.name,
          Disciplina: discipline?.name,
          Origem: source,
        }
      );
    },
    [trackEvent]
  );

  const trackRejectInformProfessor = useCallback(
    (theme, discipline, source) => {
      trackEvent('Home com Professor: Clicou Para Não Informar Professor', {
        Matéria: theme?.name,
        Disciplina: discipline?.name,
        Origem: source,
      });
    },
    [trackEvent]
  );

  const trackCloseUserProfessorCreateDialog = useCallback(
    (theme, discipline, source) => {
      trackEvent(
        'Home com Professor: Fechou Dialog de Criar Guia do Professor',
        {
          Matéria: theme?.name,
          Disciplina: discipline?.name,
          Origem: source,
        }
      );
    },
    [trackEvent]
  );

  const trackUserProfessorCreated = useCallback(
    (theme, discipline, professor) => {
      trackEvent('Home com Professor: Adicionou Guia do Professor', {
        Matéria: theme?.name,
        Disciplina: discipline?.name,
        Professor: professor?.name,
        'Criou novo?': professor?.id ? 'Não' : 'Sim',
      });
    },
    [trackEvent]
  );

  return {
    trackUserProfessorCreated,
    trackOpenUserProfessorCreateDialog,
    trackCloseUserProfessorCreateDialog,
    trackRejectInformProfessor,
  };
}
