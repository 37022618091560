import React, { useCallback, useMemo, useEffect } from 'react';
import { styled, media, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker } from 'modules/shared/hooks/Tracker';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import dexterWarning from 'images/cancellation/dexter_warning.png';
import CancelReasonDialog from 'modules/profile/dialogs/cancellation/CancelReasonDialog';

export default function BookTrialCancelDialog({ subscription }) {
  const { trackEvent } = useTracker();
  const { unsetDialog, setDialog } = useDialogContext();
  const {
    bookCreditCardTrialFreeAccessEnd,
    bookCreditCardTrialFirstCharge,
  } = useBookTrialCampaign();

  useEffect(() => {
    trackEvent('Trial com Cartão: Visualizou cancelamento', {});
  }, [trackEvent]);

  const handleBackButtonPress = useCallback(
    e => {
      e.stopPropagation();
      unsetDialog();
    },
    [unsetDialog]
  );

  const handleCancelButtonPress = useCallback(() => {
    setDialog(<CancelReasonDialog subscription={subscription} />);
  }, [subscription, setDialog]);

  const cancelText = useMemo(() => {
    if (subscription.plan.months === 1) return 'Cancelar a Assinatura';
    else return 'Desativar a Renovação';
  }, [subscription]);

  return (
    <Container>
      <Content>
        <CancelStrip>{cancelText}</CancelStrip>
        <Image src={dexterWarning} alt="" />
        <CancelCTA>Certeza que quer perder o acesso a plataforma?</CancelCTA>

        <CancelInfo>
          Atualmente você possui acesso grátis a plataforma até{' '}
          {bookCreditCardTrialFreeAccessEnd()}. Caso cancele agora, seu acesso
          ao conteúdo será bloqueado.
        </CancelInfo>

        <CancelInfo>
          Você ainda pode aproveitar nosso conteúdo de forma gratuita e cancelar
          apenas ao final de seu período grátis, caso esse seja seu desejo.
          Basta efetuar o cancelamento antes do dia{' '}
          {bookCreditCardTrialFirstCharge()}.
        </CancelInfo>

        <ButtonsContainer>
          <button onClick={handleBackButtonPress} type="button">
            Voltar
          </button>

          <button onClick={handleCancelButtonPress}>
            Cancelar e perder acesso
          </button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${media.desktop} {
    height: 100%;
  }
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-top: 10px solid ${colors.warningRed};
  text-align: left;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const CancelStrip = styled.div`
  display: inline-block;
  padding: 3px 10px;
  position: relative;
  left: -48px;
  margin-bottom: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${colors.warningRed};
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  @media ${media.mobile} {
    left: -24px;
  }
`;

const Image = styled.img`
  display: block;
  margin: auto;
  width: 160px;

  @media ${media.mobile} {
    width: 100px;
  }
`;

const CancelCTA = styled.h1`
  margin: 8px 0%;
  font-size: 1.3em;
  font-weight: bold;
  color: ${colors.warningRed};
  text-align: center;

  @media ${media.mobile} {
    font-size: 1.4em;
  }
`;

const CancelInfo = styled.p`
  margin: 25px 0;

  @media ${media.mobile} {
    font-size: 1.1em;
  }
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  button {
    flex: 1;
    margin: 0 10px;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    border: 2px solid #929292;
    color: #929292;
    text-transform: uppercase;
  }

  @media ${media.mobile} {
    flex-direction: column-reverse;

    button {
      width: 100%;
      margin: 5px 0;
      padding: 10px 0;
    }
  }
`;
