import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';

import { raLogo } from 'images/main';
import { styled, media } from 'styles';
import { useEscClick, useTracker } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import StudyInfoForm from './StudyInfoForm';
import UniversitySelectForm from './UniversitySelectForm';

export default function UserUniversityConfirmationDialog() {
  const [isOnUniversity, setIsOnUniversity] = useState(null);
  const [canNext, setCanNext] = useState(false);
  const [error, setError] = useState(false);
  const [canCloseDialog, setCanCloseDialog] = useState(true);
  const { unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const ref = useRef();

  useEscClick(ref, () => {
    handleClose();
  });

  useEffect(() => {
    trackEvent('Visualizou Dialog de Confirmação Acadêmica');
  }, [trackEvent]);

  const handleClose = useCallback(() => {
    if (!canCloseDialog) return;
    trackEvent('Fechou Dialog de Confirmação Acadêmica', {
      Etapa: canNext ? 'Segunda etapa' : 'Primeira etapa',
    });
    unsetDialog();
  }, [unsetDialog, trackEvent, canNext, canCloseDialog]);

  const handleSelect = useCallback(
    value => {
      trackEvent('Preencheu Dialog de Confirmação Acadêmica', {
        'Faz faculdade': value ? 'Sim' : 'Não',
      });

      setIsOnUniversity(value);

      setTimeout(() => {
        setCanNext(true);
        setCanCloseDialog(false);
      }, 500);
    },
    [setCanNext, setIsOnUniversity, trackEvent, setCanCloseDialog]
  );

  return (
    <Container ref={ref}>
      <Logo src={raLogo}></Logo>
      {canCloseDialog && (
        <MdClose className="close-icon" onClick={handleClose} />
      )}

      <StepsContainer>
        <span className={!canNext ? 'fill' : ''}></span>
        <span className={canNext ? 'fill' : ''}></span>
      </StepsContainer>
      <FormContainer>
        {error && <ErrorContainer>{error}</ErrorContainer>}{' '}
        {!canNext ? (
          <>
            <h2>Você está fazendo faculdade?</h2>
            <ButtonContainer>
              <Button
                onClick={() => handleSelect(false)}
                selected={isOnUniversity === false}
              >
                NÃO
              </Button>
              <Button
                onClick={() => handleSelect(true)}
                selected={isOnUniversity}
              >
                SIM
              </Button>
            </ButtonContainer>
          </>
        ) : isOnUniversity ? (
          <UniversitySelectForm setError={setError} />
        ) : (
          <StudyInfoForm
            setError={setError}
            setIsOnUniversity={setIsOnUniversity}
          />
        )}
      </FormContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 60px;
  padding-bottom: 20px;
  align-items: center;

  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.7rem;
    color: #888;
    cursor: pointer;
    z-index: 3;

    &:hover {
      transform: scale(1.3);
      color: #555;
      transition: color 180ms ease-in-out;
      transition: transform 180ms ease-in-out;
    }
  }

  @media ${media.mobile} {
    padding: 30px;
  }
`;

const Logo = styled.img`
  width: auto;
  height: 45px;
`;

export const StepsContainer = styled.div`
  text-align: center;
  margin: 40px 0;

  span {
    width: 30px;
    height: 10px;
    display: inline-block;
    background: #fff;
    transition: background 300ms ease-in-out;
    width: 30px;
    height: 10px;
    border: 1px solid #f9ac3e;
    border-radius: 5px;
    margin: 0 3px;
  }

  .fill {
    background: #f9ac3e;
  }

  @media ${media.mobile} {
    span {
      width: 44px;
      height: 10px;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-top: 10px solid #f6ac3a;
  border-radius: 10px;
  padding: 35px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  color: #7a7a7a;
  background-color: white;
  align-self: center;
  position: sticky;
  z-index: 1;

  width: 80%;
  max-width: 600px;

  h2 {
    font-weight: bold;
    font-size: 16px;
  }

  @media ${media.mobile} {
    padding: 30px;
    width: 95%;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  width: 40%;
  padding: 15px;
  background: ${({ selected }) => (selected ? '#eee' : '#fff')};
  border: 1px solid #f6ac3a;
  border-radius: 10px;

  font-weight: bold;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
`;

const ErrorContainer = styled.div`
  align-self: flex-end;
  z-index: 10;
  background: #e25017;
  color: #ffffff;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  margin: -20px;
`;
