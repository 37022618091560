import _ from 'lodash';
import { useState, useCallback, useMemo } from 'react';
import useSwrQueryGenerator from './useSwrQueryGenerator';
import { convertObjectToUnderscore } from 'modules/shared/utils';

const baseUrl = `${process.env.REACT_APP_RESPONDEAI_URL}/api/v3`;

export default function useRALazyQuery(url, swrConfig = {}) {
  const [makeQuery, setMakeQuery] = useState(false);
  const [queryParams, setQueryParams] = useState('');

  const updateQueryParams = useCallback(params => {
    const newQueryParams = new URLSearchParams(
      convertObjectToUnderscore(_.omitBy(params, _.isNil))
    ).toString();
    setQueryParams(newQueryParams);
  }, []);

  const query = useCallback(
    (params = {}) => {
      updateQueryParams(params);
      setMakeQuery(true);
    },
    [updateQueryParams]
  );

  const completeUrl = useMemo(() => {
    return `${baseUrl}${url}${queryParams && `?${queryParams}`}`;
  }, [queryParams, url]);

  const swrResponse = useSwrQueryGenerator(makeQuery ? completeUrl : null, {
    ...swrConfig,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });

  return useMemo(() => {
    return [query, swrResponse];
  }, [query, swrResponse]);
}
