import { useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

export default function useRaSearchPostFileRequest() {
  const sendPostFileRequest = useCallback(async (file = {}, options = {}) => {
    const userJwt = Cookies.get('user_jwt');

    options.headers = {
      ...(options.headers || {}),
      'User-JWT': userJwt,
      'Content-Type': 'multipart/form-data',
    };

    const formData = new FormData();
    if (file) formData.append('image', file);

    const url = `${process.env.REACT_APP_RA_SEARCH_URL}/search/with-image`;

    let response;
    try {
      response = await axios.post(url, formData, options);
      return { data: response.data };
    } catch (e) {
      return { error: e.response.data };
    }
  }, []);

  return { sendPostFileRequest };
}
