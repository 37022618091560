import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { styled } from 'styles';

export default function ContentFeedbackComment({
  isCommentOpen,
  setCommentOpen,
  comment,
  setComment,
  updateFeedback,
}) {
  function onSubmit() {
    const text = comment;
    if (!text || text === '') return;

    updateFeedback();
    setCommentOpen(false);
  }

  return (
    <Container>
      {!isCommentOpen && (
        <div className="comment-header" onClick={() => setCommentOpen(true)}>
          <Icon className="comment-open" icon={faComment} />
          Deixe seu comentário
        </div>
      )}
      {isCommentOpen && (
        <div className="comment-area-container">
          <Icon className="comment-area-icon" icon={faComment} />
          <textarea
            value={comment}
            onChange={event => setComment(event.target.value)}
            className="comment-area"
            placeholder="Quanto mais detalhes, melhor ;)"
          ></textarea>
          <SubmitIcon icon={faPaperPlane} onClick={onSubmit} />
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 15px;

  .comment-header {
    text-decoration: underline;
    cursor: pointer;
  }

  .comment-area-container {
    margin: auto;
    width: 33%;
    position: relative;
    min-width: 250px;

    .comment-area {
      border: #bcbcbc solid 1px;
      overflow: auto;
      outline: none;

      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      resize: none;

      padding: 10px 40px 10px 10px;
      width: 100%;
      min-height: 100px;
      border-radius: 10px;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.2em;
  margin-right: 5px;

  &.comment-area-icon {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  }
`;

const SubmitIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;
