import {
  poo,
  partyHard,
  pokerFace,
  rabbit,
  thinkingFace,
  confusedFace,
} from 'images/content-feedback';

export const CONTENT_FEEDBACK_GRADES = [
  {
    id: 1,
    text: 'Passou longe!',
    iconSrc: poo,
  },
  {
    id: 2,
    text: 'Meh!',
    iconSrc: pokerFace,
  },
  {
    id: 3,
    text: 'Demais!',
    iconSrc: partyHard,
  },
];

export const CONTENT_FEEDBACK_FEELINGS = [
  {
    id: 1,
    text: 'Difícil de entender',
    iconSrc: confusedFace,
  },
  {
    id: 2,
    text: 'Pulou algum passo',
    iconSrc: rabbit,
  },
  {
    id: 3,
    text: 'Outro',
    iconSrc: thinkingFace,
  },
];
