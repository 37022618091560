import React, { useCallback } from 'react';
import { media, styled } from 'styles';
import { GoPencil } from 'react-icons/go';
import { Loading, Error } from 'modules/shared/components/generic';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';

export default function ThemesMenuFromStudyPlanDialog({
  selectedTheme,
  setSelectedTheme,
  isThemesMenuOpen,
  setIsThemesMenuOpen,
  resetStudyPlan,
}) {
  const { data: themes, loading, error } = useContentQueryImmutable(
    `/themes/with-subjects`
  );

  const onChooseTheme = useCallback(
    chosenTheme => {
      setIsThemesMenuOpen(false);
      setSelectedTheme(chosenTheme);

      resetStudyPlan({
        themeId: chosenTheme.id,
        subjectsIds: [],
      });
    },
    [resetStudyPlan, setIsThemesMenuOpen, setSelectedTheme]
  );

  if (error) return <Error />;
  if (loading) return <Loading />;

  return (
    <Container>
      {isThemesMenuOpen ? (
        <ul>
          {themes.map(theme => (
            <li key={theme.id} onClick={() => onChooseTheme(theme)}>
              {' '}
              {theme.name}{' '}
            </li>
          ))}
        </ul>
      ) : (
        <div
          onClick={() => {
            setIsThemesMenuOpen(true);
            resetStudyPlan({
              themeId: selectedTheme.id,
              subjectsIds: [],
            });
          }}
        >
          <span>{selectedTheme.name}</span>
          <GoPencil className="icon" />
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  box-shadow: 0 1px 10px 2px rgb(0 0 0 / 30%);
  width: 320px;
  min-height: 80px;
  margin: 0 auto;
  padding: 15px 0;
  border-radius: 10px;
  border-top: 10px solid #f9ac3e;
  background: #fff;
  padding: 15px 0;
  font-size: 0.9em;
  color: #888;
  text-align: left;
  position: relative;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 15px;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }

    span {
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 30px;
      flex-grow: 1;
    }

    .icon {
      font-size: 1.1rem;
    }
  }

  ul {
    cursor: pointer;

    li {
      width: 100%;
      min-height: 40px;
      padding: 0 15px 0 45px;
      display: flex;
      align-items: center;
      justify-content: start;

      &:hover {
        background-color: #eee;
      }
    }
  }

  @media ${media.mobile} {
    width: 300px;
    margin: 0 auto;
  }
`;
