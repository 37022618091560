import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useCallback } from 'react';
import { useUserContext } from 'providers/UserProvider';
import Cookies from 'js-cookie';

export function useHandleListOwner() {
  const { sendPutRequest } = useContentRestRequest();
  const { user } = useUserContext();

  const shouldSetUserIdToList = useCallback(
    list => {
      if (!user || list.userId) return false;

      const listsThatUserIsOwner = Cookies.get('lists_that_user_is_owner');
      let listsThatUserIsOwnerArray = listsThatUserIsOwner
        ? JSON.parse(listsThatUserIsOwner)
        : [];

      const hasCurrentList = listsThatUserIsOwnerArray.includes(list.id);

      return hasCurrentList;
    },
    [user]
  );

  const setUserIdToList = useCallback(
    async listId => {
      const res = await sendPutRequest(`/mvp/lists-with-statements/${listId}`);

      return res.data.success;
    },
    [sendPutRequest]
  );

  const setListIdInCookies = useCallback(listId => {
    const listsThatUserIsOwner = Cookies.get('lists_that_user_is_owner');

    let listsThatUserIsOwnerArray = listsThatUserIsOwner
      ? JSON.parse(listsThatUserIsOwner)
      : [];

    listsThatUserIsOwnerArray.push(listId);

    Cookies.set(
      'lists_that_user_is_owner',
      JSON.stringify(listsThatUserIsOwnerArray)
    );
  }, []);

  return {
    setUserIdToList,
    setListIdInCookies,
    shouldSetUserIdToList,
  };
}
