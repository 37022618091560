import CourseSelect from './CourseSelect';
import {
  Container,
  ErrorContainer,
  CloseButton,
  Title,
  CenterContainer,
  Button,
  Logo,
} from './styles';
import { raLogo } from 'images/main';
import { useEscClick, useTracker } from 'modules/shared/hooks';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useDialogContext } from 'providers/DialogProvider';
import { useUserContext } from 'providers/UserProvider';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { media } from 'styles';

export default function SendCourseDialog({ onChange }) {
  const ref = useRef(null);
  const { unsetDialog } = useDialogContext();
  const { user, setUser } = useUserContext();
  const [formError, setFormError] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const [saving, setSaving] = useState(false);
  const sendCourse = useRAPostRequest('users/update_basic_infos');

  const { trackEvent } = useTracker();

  useEscClick(ref, unsetDialog);

  useEffect(() => {
    trackEvent('Visualizou Dialog de Qualificação', {
      Categoria: media.platformName(),
      Local: 'Fórum RA',
    });
  }, [trackEvent]);

  const canSendForm = useCallback(() => {
    let error = '';

    if (!selectedCourse) {
      error = 'Você precisa selecionar um curso';
    }

    setFormError(error);

    setTimeout(() => {
      setFormError('');
    }, 3000);
    return error === '';
  }, [selectedCourse]);

  const updateUserCourse = useCallback(async () => {
    if (saving) return;

    if (!canSendForm()) return;

    const params = {
      university_course_id: selectedCourse.id,
    };

    setSaving(true);

    try {
      await sendCourse(params);
      setUser({ ...user, course: selectedCourse });

      if (onChange) onChange();

      trackEvent('Informou Dados de Qualificação', {
        Categoria: media.platformName(),
        Local: 'Fórum RA',
        Curso: selectedCourse.name,
        Universidade: user?.university?.name,
      });
    } catch (err) {
      alert('Erro ao salvar seu perfil');
    } finally {
      setSaving(false);
    }

    unsetDialog();
  }, [
    canSendForm,
    onChange,
    saving,
    selectedCourse,
    sendCourse,
    setUser,
    trackEvent,
    unsetDialog,
    user,
  ]);

  return (
    <Container ref={ref}>
      <CloseButton onClick={unsetDialog}>X</CloseButton>
      <Logo src={raLogo} />
      <Title>Informe o seu curso para continuar</Title>
      <CenterContainer>
        {formError && (
          <ErrorContainer>
            <span>{formError}</span>
          </ErrorContainer>
        )}

        <CourseSelect setSelectedCourse={setSelectedCourse} />

        <Button onClick={updateUserCourse} saving={saving}>
          {saving ? 'ENVIANDO...' : 'ENVIAR'}
        </Button>
      </CenterContainer>
    </Container>
  );
}
