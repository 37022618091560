import React, { useState, useRef, useCallback, useEffect } from 'react';
import { media, styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useUserContext } from 'providers/UserProvider';
import { useEscClick, useTracker, useUTM } from 'modules/shared/hooks';
import { Loading } from 'modules/shared/components/generic';
import { useArrangeData } from './hooks/useArrangeData.js';
import ThemesSlider from './components/ThemesSlider.js';
import DisciplineContent from './components/DisciplineContent.js';
import useContentQueryImmutable from 'modules/shared/hooks/requests/useContentQueryImmutable.js';

export default function PersonalizedContentDialog({ history, source }) {
  const { unsetDialog } = useDialogContext();
  const { user } = useUserContext();
  const { getThemes } = useArrangeData();
  const [selectedTheme, setSelectedTheme] = useState(null);
  const ref = useRef(null);
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const { data: disciplines, loading, error } = useContentQueryImmutable(
    `/disciplines/with-list-exercises`
  );

  const closeDialog = useCallback(() => {
    trackEvent('Dialog Conteúdo Personalizado: Fechou Dialog');
    unsetDialog();
  }, [unsetDialog, trackEvent]);

  useEscClick(ref, closeDialog);

  const universityPronoumn = useCallback(() => {
    return user?.university?.gender === 'M' ? 'do' : 'da';
  }, [user?.university?.gender]);

  useEffect(() => {
    trackEvent('Dialog Conteúdo Personalizado: Visualizou Dialog', {
      ...getUTMsByLocation(window.location),
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Onde: source,
    });
  }, [getUTMsByLocation, trackEvent, source]);

  if (error) return unsetDialog();
  if (loading) return <Loading />;

  const themes = getThemes(disciplines);

  return (
    <Container ref={ref}>
      <Content>
        <CloseButton onClick={closeDialog}>X</CloseButton>
        <Title>{`Tudo ${universityPronoumn()} ${
          user.university.acronym
        } em um só lugar!`}</Title>
        <ThemesSlider
          themes={themes}
          selectedTheme={selectedTheme || themes[0]}
          setSelectedTheme={setSelectedTheme}
        />
        <DisciplineContent
          disciplines={disciplines}
          selectedTheme={selectedTheme || themes[0]}
          history={history}
        />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${media.mobile} {
    background-color: #fff;
  }
`;

const Content = styled.div`
  position: relative;
  width: 90%;
  max-width: 982px;
  background: #fff;
  padding: 30px 45px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 50px 0;

  @media ${media.mobile} {
    width: 100%;
    margin-top: 75px;
    height: 100%;
    background-color: #fff;
    padding: 30px 0 60px 0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 23px;

  @media ${media.mobile} {
    top: 10px;
    right: 7px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 27px;
  color: #8c8c8c;
  text-transform: uppercase;
  text-align: center;

  @media ${media.mobile} {
    font-size: 20px;
    margin: 5px;
  }
`;
