import { useUserContext } from 'providers/UserProvider';
import React, { useEffect } from 'react';
import { styled } from 'styles';
import { usePersonalizedContentSharedFunctions } from '../sharedFunctions.js';

export default function UniversityPersonalizedContentBannerMobile() {
  const { user } = useUserContext();
  const {
    viewBannerEvent,
    onClickBanner,
  } = usePersonalizedContentSharedFunctions();

  useEffect(() => {
    viewBannerEvent();
  }, [viewBannerEvent]);

  return (
    <ContainerBanner onClick={onClickBanner}>
      <h2>
        Conteúdos feitos para você <br />
        {` mandar bem ${user?.university?.gender === 'F' ? 'na' : 'no'} ${
          user?.university?.acronym
        }`}
      </h2>
      <CTAButton>clique e confira</CTAButton>
    </ContainerBanner>
  );
}

const ContainerBanner = styled.div`
  width: 100%;
  height: 60px;
  background: linear-gradient(180deg, #7000ff 0%, #92227a 59.37%, #ff9d2d 100%),
    #bb8ab9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  padding: 0 2.5%;

  h2 {
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 0.9rem;
    text-align: center;
    color: #ffffff;
    max-width: 53%;
  }
`;

const CTAButton = styled.button`
  width: 43%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 30px #ffffff;
  border-radius: 10px;
  outline: 0;
  border: 0;
  text-transform: uppercase;
  color: #410094;
  font-weight: bold;
  font-size: 11px;
`;
