import React, { useCallback, useState } from 'react';
import { defaultSubjectImage } from 'images/main';
import { styled, media } from 'styles';
import { BiCheck } from 'react-icons/bi';

export default function SubjectCardFromStudyPlan({
  subject,
  color,
  studyPlan,
  setStudyPlan,
  position,
}) {
  if (!subject.imagePath) subject.imagePath = defaultSubjectImage;

  const isCardSelect = useCallback(() => {
    return studyPlan.subjectsIds.includes(subject.id);
  }, [studyPlan, subject]);

  const [isSelected, setIsSelected] = useState(isCardSelect());

  const onSubjectClick = useCallback(() => {
    let newStudyPlan = {};

    if (!isSelected) {
      newStudyPlan = { ...studyPlan };
      newStudyPlan.subjectsIds.push(subject.id);
    } else {
      const newSubjectsChosen = studyPlan.subjectsIds.filter(
        s => s !== subject.id
      );
      newStudyPlan = { ...studyPlan, subjectsIds: newSubjectsChosen };
    }
    setStudyPlan(newStudyPlan);
    setIsSelected(!isSelected);
  }, [isSelected, studyPlan, subject, setStudyPlan]);

  return (
    <Container isSelected={isSelected} color={color} onClick={onSubjectClick}>
      <div className="tick-circle">
        {isSelected ? <span>{position}</span> : <BiCheck className="icon" />}
      </div>
      <img src={subject.imagePath} alt="imagem-materia" />
      <p>{subject.name}</p>
    </Container>
  );
}

const Container = styled.div`
  width: 150px;
  height: 160px;
  margin: 10px 5px;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 2px 1px -1px rgb(0 0 0 / 12%);
  border-radius: 8px;
  z-index: 10;
  cursor: pointer;
  background: #fff;
  position: relative;

  .tick-circle {
    position: absolute;
    top: -10px;
    right: -8px;
    width: 30px;
    height: 30px;
    background: ${({ isSelected }) => (isSelected ? '#7ED48A' : '#888')};
    color: #fff;
    border: 3px solid #fff;
    border-radius: 30px;
    font-size: 1.1em;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-weight: bold;
    }

    .icon {
      font-size: 1.7rem !important;
    }
  }

  img {
    max-width: 100%;
    background-color: ${props => props.color};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  p {
    padding: 5px;
    text-transform: uppercase;
    font-size: 0.78em;
    color: #888;
    line-height: 1.1;
    text-align: left;
  }

  @media ${media.mobile} {
    width: 130px;
    height: 140px;
  }
`;
