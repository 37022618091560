import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { styled, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { Loading, Error } from 'modules/shared/components/generic';
import { useStudyPlan } from '../hooks';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';

export default function EditStudyPlanTopicsDialog({ guideId, studyPlan }) {
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [removedTopics, setRemovedTopics] = useState(
    studyPlan.topicsIds.map(id => parseInt(id))
  );
  const [changedRemovedTopics, setChangedRemovedTopics] = useState(false);
  const { unsetDialog } = useDialogContext();

  const {
    data: guide,
    loading: loadingGuide,
    error: errorGuide,
  } = useContentQueryImmutable(`/guides/${guideId}`);

  const { data: theme, loading, error } = useContentQueryImmutable(
    `/themes/${studyPlan.theme.id}/with-subjects`
  );

  const { saveStudyPlanTopics } = useStudyPlan();

  useEffect(() => {
    if (!theme || !guide) return;

    const initialSubjectId = guide.subjects[0].id;
    const initialSubject = theme.subjects.find(
      subject => subject.id === initialSubjectId
    );

    setSelectedSubject({ ...initialSubject });
  }, [guide, theme]);

  const toggleTopic = useCallback(
    topicId => {
      if (!changedRemovedTopics) setChangedRemovedTopics(true);
      let newRemovedTopics;
      if (removedTopics.includes(topicId))
        newRemovedTopics = removedTopics.filter(
          removedTopic => removedTopic !== topicId
        );
      else newRemovedTopics = [...removedTopics, topicId];

      setRemovedTopics(newRemovedTopics);
    },
    [
      removedTopics,
      setRemovedTopics,
      changedRemovedTopics,
      setChangedRemovedTopics,
    ]
  );

  const topicIsRemoved = useCallback(
    topicId => {
      return removedTopics.includes(topicId);
    },
    [removedTopics]
  );

  const onSaveClick = useCallback(async () => {
    if (!changedRemovedTopics) return;

    const success = await saveStudyPlanTopics(guideId, removedTopics);
    if (success) {
      alert('Plano de estudos salvo com sucesso!');
      window.location.reload();
    } else {
      alert('Erro ao salvar plano de estudos. Por favor, tente novamente.');
    }
  }, [guideId, removedTopics, changedRemovedTopics, saveStudyPlanTopics]);

  const getThemeSubjectFromId = useCallback((theme, subjectId) => {
    return theme.subjects.find(subject => subject.id === subjectId);
  }, []);

  if (errorGuide || error) return <Error />;
  if (loadingGuide || loading) return <Loading />;

  return (
    <Container data-cy="study-plan-edit-topic-dialog">
      <SideMenu>
        <SideMenuTitle>Selecione o módulo que deseja editar:</SideMenuTitle>
        {guide.subjects.map(subject => (
          <SideMenuOption
            key={subject.id}
            selected={selectedSubject && selectedSubject.id === subject.id}
            onClick={() =>
              setSelectedSubject(getThemeSubjectFromId(theme, subject.id))
            }
          >
            <SideMenuOptionImg src={subject.imagePath} />
            {subject.name}
          </SideMenuOption>
        ))}
        <SaveButton
          className={changedRemovedTopics ? 'active' : ''}
          onClick={onSaveClick}
        >
          Salvar <SaveIcon icon={faSave} />
        </SaveButton>
      </SideMenu>
      <EditionContent>
        <EditionContentTitle>
          ÁREA DE EDIÇÃO DO SEU PLANO DE ESTUDOS
        </EditionContentTitle>
        <EditionContentCard>
          {selectedSubject && (
            <>
              <SideMenuOption selected={true}>
                <SideMenuOptionImg src={selectedSubject.imagePath} />
                {selectedSubject.name}
              </SideMenuOption>

              {selectedSubject.chapters.map((chapter, index) => (
                <Chapter key={chapter.id}>
                  <ChapterTitle>
                    {index + 1}. {chapter.name}
                  </ChapterTitle>
                  <ChapterTopics>
                    {chapter.topics.map(topic => (
                      <Topic
                        key={topic.id}
                        className={topicIsRemoved(topic.id) ? 'removed' : ''}
                      >
                        {topic.name}{' '}
                        <TopicToggle onClick={() => toggleTopic(topic.id)}>
                          {topicIsRemoved(topic.id) ? '+' : 'x'}
                        </TopicToggle>
                      </Topic>
                    ))}
                  </ChapterTopics>
                </Chapter>
              ))}
            </>
          )}
        </EditionContentCard>
      </EditionContent>
      <CloseButton onClick={() => unsetDialog()}>X</CloseButton>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgba(255, 255, 255, 0.9);
`;

const CloseButton = styled.div`
  position: absolute;
  font-size: 1.4em;
  font-weight: bold;
  top: 30px;
  right: 30px;
  cursor: pointer;
`;

const SideMenu = styled.div`
  position: relative;
  width: 300px;
  height: 100%;
  box-shadow: 0 0 21px 0 #ddd;
  background-color: #fff;
  padding: 70px 10px;
`;

const SideMenuTitle = styled.div`
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
  color: ${colors.mediumGray};
  margin-bottom: 20px;
`;

const SideMenuOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  text-align: left;
  color: #665;
  line-height: 1;
  font-size: 0.95em;
  cursor: pointer;
  opacity: ${({ selected }) => (selected ? '1' : '0.4')};
`;

const SideMenuOptionImg = styled.img`
  background-color: ${colors.mainOrange};
  margin-right: 10px;
  border-radius: 7px;
  height: 40px;
`;

const EditionContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: scroll;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 70px 10px;
`;

const EditionContentTitle = styled.h1`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 50px;
  color: #665;
`;

const EditionContentCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
`;

const Chapter = styled.div`
  width: 100%;
  text-align: left;
`;

const ChapterTitle = styled.h2`
  text-transform: uppercase;
  color: ${colors.darkGray};
  font-size: 0.75em;
  padding: 20px 0 5px 0;
`;

const ChapterTopics = styled.div`
  background-color: #f3f3f3;
  border-radius: 10px;
  padding: 5px;
`;

const Topic = styled.div`
  position: relative;
  color: ${colors.mediumGray};
  padding: 10px;
  font-size: 0.85em;

  &.removed {
    text-decoration: line-through;
    color: ${colors.lightGray};
  }
`;

const TopicToggle = styled.div`
  position: absolute;
  top: -1px;
  right: 10px;
  font-weight: bold;
  font-size: 1.15em;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SaveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 35px;
  width: 180px;
  color: ${colors.lightGray};
  background-color: #eee;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  border-radius: 20px;
  padding: 10px 15px;

  &.active {
    background-color: ${colors.mainOrange};
    color: #fff;
  }
`;

const SaveIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`;
