import React, { useEffect } from 'react';
import { useSolvedBooksSharedFunctions } from '../sharedFunctions';
import { ContainerBanner } from './styles';

function SolvedBooksMobile() {
  const { viewBannerEvent, onClickBanner } = useSolvedBooksSharedFunctions();

  useEffect(() => {
    viewBannerEvent();
  }, [viewBannerEvent]);

  return (
    <ContainerBanner onClick={onClickBanner}>
      <span className="tag">NOVO</span>
      <h1>
        LIVROS <strong>RESOLVIDOS</strong>
      </h1>
      <h2>Clique aqui e confira!</h2>
    </ContainerBanner>
  );
}

export default SolvedBooksMobile;
