import React, { useMemo } from 'react';
import { media, styled } from 'styles';
import { PlanCard } from './PlanCard';
import { pricings } from './data/pricings';

export function PlanChoiceStep({
  setPricingId,
  pricingId,
  setStep,
  eventProps,
}) {
  const benefits = useMemo(() => {
    return [
      '📄 Acesso completo a sua lista e qualquer outra lista enviada',
      '📝 Mais de 150 mil questões resolvidas de 100 Livros',
      '🎥 Videoaulas de mais de 30 disciplinas de Exatas',
    ];
  }, []);

  return (
    <>
      <ReasonSubtitle></ReasonSubtitle>
      <Title>
        {' '}
        Você completou as suas <span>2</span> questões grátis! <br />{' '}
        Desbloqueie sua lista completa e mande bem nas provas!
      </Title>
      <Subtitle>A assinatura do Responde Aí garante: </Subtitle>
      {benefits.map(benefit => (
        <Benefit key={benefit}>{benefit}</Benefit>
      ))}
      <PlansContainer>
        {pricings.map(pricing => (
          <PlanCard
            key={pricing.id}
            pricing={pricing}
            setPricingId={setPricingId}
            pricingId={pricingId}
            eventProps={eventProps}
          />
        ))}
      </PlansContainer>

      <AdvanceButton onClick={() => setStep(2)}>Avançar</AdvanceButton>
    </>
  );
}

export const Title = styled.h1`
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #222;

  span {
    color: #f7ac3d;
  }
`;

const ReasonSubtitle = styled.h3`
  text-align: center;
  font-size: 1.1rem;
  color: #000;
  margin-bottom: 30px;
`;

const Subtitle = styled.h1`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #222;
`;

const Benefit = styled.p`
  margin: 3px 0;
  color: #222;
`;

const PlansContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  color: #222;
`;

const AdvanceButton = styled.div`
  width: 84%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
  color: #f2f2f2;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #f7ac3d;

  @media ${media.mobile} {
    width: 96%;
  }
`;
