import { styled } from 'styles';
import { media } from 'styles';

export const Container = styled.div`
  background-color: ${props => props.color};
  height: 306px;
  padding: 60px 0 15px 0;
  position: relative;
  width: 100%;

  img {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 300px;
    opacity: 0.8;
    z-index: 1;
  }

  @media ${media.mobile} {
    text-align: center;
    margin: 0;
    height: 413px;
    /* padding-top: 60px; */

    img {
      height: 230px;
      left: -30%;
    }
  }
`;

export const MainContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
  color: #fff;
  margin-top: 10px;

  h1 {
    font-size: 2.2em;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.1rem;
  }

  @media ${media.mobile} {
    flex-direction: column;

    img {
      height: 230px;
    }

    h1 {
      font-size: 1.6rem;
    }

    h2 {
      font-size: 1rem;
    }

    .search {
      margin-top: 20px;
    }
  }
`;
