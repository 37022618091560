import { styled } from 'styles';

const ContainerBanner = styled.div`
  background: #000;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 5px 20px 5px;

  .header {
    text-align: center;

    h1 {
      font-weight: bold;
      color: white;
      font-size: 1.3em;
      margin-bottom: 25px;
      margin-top: 5px;
    }
  }

  .lectures {
    width: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
  }
`;

const LecturePlayBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-size: 0.9rem;
  color: #fff;
  cursor: pointer;
  text-align: center;
  margin: 10px 5px;
  width: 45%;
  min-height: 100px;
  img {
    width: 38px;
    margin-bottom: 8px;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin: 5px 10px 10px 10px;
  }
`;

export { ContainerBanner, LecturePlayBox };
