import React from 'react';

import LecturesClockIcon from '../images/lecture-clock.png';
import CardsContainer from './CardsContainer';
import LectureCard from './LectureCard';
import { colors } from 'styles';

export default function LecturesCards({
  lectures,
  color,
  guideId,
  shouldHaveFreeTag = false,
}) {
  return (
    <CardsContainer
      color={colors.lecture}
      title="Aulões salva-vidas"
      icon={LecturesClockIcon}
      shouldHaveFreeTag={shouldHaveFreeTag}
      source="lecturesCards"
    >
      {lectures.map(lecture => (
        <LectureCard
          key={lecture.id}
          lecture={lecture}
          color={color}
          guideId={guideId}
        />
      ))}
    </CardsContainer>
  );
}
