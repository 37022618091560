import { useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function useSearchAutomaticallyOpen() {
  const history = useHistory();
  const shouldShowOpenSearchDialogAutomaticallyByUtm = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const hasParam = searchParams.get('open_search');

    searchParams.delete('open_search');

    const newSearch = searchParams.toString();

    history.push({
      pathname: window.location.pathname,
      search: newSearch ? `?${newSearch}` : '',
    });

    return hasParam === 'true';
  }, [history]);

  return { shouldShowOpenSearchDialogAutomaticallyByUtm };
}
