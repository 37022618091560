import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';

export const BookEditionContext = createContext(null);

const BookEditionProvider = props => {
  const { edition } = props;
  const { exerciseId, bookId } = useParams();
  const history = useHistory();
  const [currentExerciseId, setCurrentExerciseId] = useState(-1);
  const [currentSectionId, setCurrentSecttionId] = useState(-1);
  const [bookExercise, setBookExercise] = useState();
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedProfessor, setSelectedProfessor] = useState(null);
  const [numViewsExerciseStatement, setNumViewsExerciseStatement] = useState(0);

  const {
    data: professors,
    loading: loadingProfessors,
  } = useContentQueryImmutable(`/mvp/professors-by-book-edition`, {
    bookId,
    exerciseId,
  });

  const { data: userProfessors } = useContentQueryImmutable(
    `/mvp/user-professors`
  );

  const onChangeExerciseSelected = useCallback(
    exerciseId => {
      setCurrentTab(0);
      const bookId = edition.book.id;

      const newURI = `/materias/solucionario/livro/${bookId}/edicao/${edition.id}/exercicio/${exerciseId}`;
      let utmParams = new URLSearchParams(window.location.search);
      utmParams.delete('internal_source');
      history.push({
        pathname: newURI,
        search: utmParams.toString(),
      });
    },

    [edition, history]
  );

  const findQuestionFromUrlAndSetTheStates = useCallback(() => {
    edition.chapters.forEach(chapter => {
      chapter.sections.forEach(section => {
        const foundQuestion = section.questions.find(q => {
          return q.exercise.id === parseInt(exerciseId);
        });

        if (foundQuestion) {
          setCurrentSecttionId(section.id);
          setCurrentExerciseId(parseInt(exerciseId));
        }
      });
    });
  }, [edition, exerciseId]);

  useEffect(() => {
    if (parseInt(exerciseId) !== currentExerciseId) {
      findQuestionFromUrlAndSetTheStates();
    }
  }, [exerciseId, currentExerciseId, findQuestionFromUrlAndSetTheStates]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentExerciseId]);

  return (
    <BookEditionContext.Provider
      value={{
        edition,
        currentExerciseId,
        setCurrentExerciseId,
        currentSectionId,
        setCurrentSecttionId,
        onChangeExerciseSelected,
        currentTab,
        setCurrentTab,
        bookExercise,
        setBookExercise,
        professors,
        loadingProfessors,
        userProfessors,
        selectedProfessor,
        setSelectedProfessor,
        numViewsExerciseStatement,
        setNumViewsExerciseStatement,
      }}
      {...props}
    />
  );
};

export const useBookEditionContext = () => useContext(BookEditionContext);

export default BookEditionProvider;
