import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

export default function useWorkspaceGuide() {
  const { guideId } = useParams();

  const studyPlanGuidable = useMemo(() => {
    return 'StudyPlans';
  }, []);

  const disciplineGuidable = useMemo(() => {
    return 'Disciplines';
  }, []);

  const studyTrackGuidable = useMemo(() => {
    return 'StudyTracks';
  }, []);

  const isGuideWorkspace = useCallback(() => {
    return !!guideId;
  }, [guideId]);

  const getChaptersFromGuide = useCallback(guide => {
    const subjects = guide.subjects;
    return _.flatten(subjects.map(subject => subject.chapters));
  }, []);

  const getGuidableNameFromGuide = useCallback(
    guide => {
      if (guide.guidableName) return guide.guidableName;

      if (guide.guidable === studyPlanGuidable) return 'PLANO DE ESTUDO';
    },
    [studyPlanGuidable]
  );

  const getThemeFromGuide = useCallback(guide => {
    const firstSubject = guide.subjects[0];
    return firstSubject?.theme;
  }, []);

  const getGuideTypeToRaEvents = useCallback(guide => {
    if (!guide) return 'Assunto Default';
    if (guide.guidable === 'Disciplines') return 'Disciplina';
    if (guide.guidable === 'StudyPlans') return 'Plano de estudo';
    if (guide.guidable === 'StudyTracks') return 'Trilha de estudo';
  }, []);

  return {
    getChaptersFromGuide,
    getGuidableNameFromGuide,
    studyPlanGuidable,
    disciplineGuidable,
    studyTrackGuidable,
    isGuideWorkspace,
    getThemeFromGuide,
    getGuideTypeToRaEvents,
  };
}
