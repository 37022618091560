import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export default function useEmailReminder() {
  const location = useLocation();

  const shouldRenderEmailReminderDialog = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const utm_campaign = searchParams.get('utm_campaign');
    if (
      utm_campaign === 'reminder' ||
      utm_campaign === 'no_reminder' ||
      utm_campaign === 'savethedate' ||
      utm_campaign === 'bff'
    ) {
      return true;
    }
    return false;
  }, [location]);

  return { shouldRenderEmailReminderDialog };
}
