import reduceImage from '../shared/reduceImage';
import { faTimes as removeIcon } from '@fortawesome/free-solid-svg-icons';
import { uploadIcon, uploadIconHover, uploadIconFilled } from 'images/forum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useRef } from 'react';
import { colors, styled } from 'styles';
import useForumTracker from 'modules/forum/hooks/useForumTracker';

function imageSizeInKB(file) {
  return (file.size / 1000).toFixed(2);
}

export default function ForumInput({
  setText,
  placeholder,
  file,
  setFile,
  text = '',
  error = '',
  onShiftEnter,
  id,
}) {
  const { trackClickToSendImage } = useForumTracker();
  const inputFile = useRef(null);

  const adjustFileName = fileName => {
    if (fileName.length > 20) {
      return `${fileName.slice(0, 9)}...${fileName.slice(
        fileName.length - 9,
        fileName.length
      )}`;
    }
    return fileName;
  };

  const handleImageUploadIconClick = useCallback(() => {
    trackClickToSendImage();
    inputFile.current.click();
  }, [trackClickToSendImage, inputFile]);

  return (
    <Container>
      <input
        ref={inputFile}
        type="file"
        id="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={e => {
          let file = e.target.files[0];
          reduceImage(file).then(setFile);
        }}
      />

      <CustomTextField
        id={id}
        placeholder={placeholder}
        onChange={e => setText(e.target.value)}
        multiline={true}
        value={text}
        onKeyPress={e => {
          if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            if (onShiftEnter) onShiftEnter();
          }
        }}
      />

      <Divider />

      <FilesContainer>
        {!file && (
          <IconAndTextUploadButton onClick={handleImageUploadIconClick}>
            <Icon
              title={`Upload de imagem`}
              src={uploadIcon}
              hoverImage={uploadIconHover}
            />
            <FileUploadText>Adicionar imagem</FileUploadText>
          </IconAndTextUploadButton>
        )}

        {error && (
          <ErrorContainer>
            <span>{error}</span>
          </ErrorContainer>
        )}

        {file && (
          <QuestionImage
            file={file}
            className="pastedImage"
            title={`${file.name} - ${imageSizeInKB(file)}KB`}
          >
            <Icon title={`Upload de imagem`} src={uploadIconFilled} />
            <span>{adjustFileName(file.name)}</span>
            <RemoveIcon
              onClick={() => {
                setFile(null);
              }}
              icon={removeIcon}
            />
          </QuestionImage>
        )}
      </FilesContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 5px;
`;

const CustomTextField = styled.textarea`
  font-family: 'Lato', sans-serif;
  width: 100%;
  min-height: 150px;
  border: none;
  padding: 10px;
  resize: none;
  color: ${colors.darkGray};
  font-size: 16px;
`;

const Divider = styled.div`
  width: 98%;
  align-self: center;
  height: 1px;
  background-color: #e0e0e0;
`;

const FilesContainer = styled.div`
  height: 40px;
  padding: 5px;
  font-family: 'Lato', sans-serif;
  font-size: 0.8em;
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  font-size: 2em;
  &:hover {
    ${({ hoverImage }) => hoverImage && `content: url(${hoverImage});`}
  }
  cursor: ${({ hoverImage }) => hoverImage && 'pointer'};
`;

const RemoveIcon = styled(FontAwesomeIcon)`
  font-size: 1em;
  color: ${colors.mediumGray};
  cursor: pointer;
  margin-left: 10px;
`;

const QuestionImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  overflow: auto;

  padding: 3px 8px;

  border: 1px solid ${colors.lightGray};
  color: ${colors.darkGray};
  text-decoration: underline;
  border-radius: 5px;

  span {
    margin-left: 5px;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 10px;

  span {
    color: ${colors.ErrorReportRed};
    font-size: 12px;
  }
`;

const FileUploadText = styled.span`
  color: ${colors.mediumGray};
  &:hover {
    color: ${colors.darkGray};
  }
  cursor: pointer;
  padding-left: 8px;
  text-decoration: underline;
`;

const IconAndTextUploadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
