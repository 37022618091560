import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { TextField, TextareaAutosize } from '@material-ui/core';

import { styled, media, colors } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import CancelReasonDialog from './CancelReasonDialog';
import CancelConfirmation from './CancelConfirmation';
import { FreezeSubscriptionCTA } from 'modules/campaigns/freeze-subscription/dialogs';
import { ChangePlanOnCancelCTADialog } from 'modules/campaigns/change-plan-on-cancel/dialogs';
import { useFreezeSubscriptionCampaign } from 'modules/campaigns/freeze-subscription/hooks';
import { useChangePlanOnCancelCampaign } from 'modules/campaigns/change-plan-on-cancel/hooks';
import { useTracker } from 'modules/shared/hooks/Tracker';

export default function CancelDetailedReasonDialog({
  subscription,
  selectedReason,
}) {
  const { trackEvent } = useTracker();
  const { setDialog } = useDialogContext();
  const [selectedOption, setSelectedOption] = useState(null);
  const [otherInput, setOtherInput] = useState('');
  const { isSubscriptionEligibleToFreeze } = useFreezeSubscriptionCampaign();
  const {
    isSubscriptionEligibleToChangePlanOnCancel,
  } = useChangePlanOnCancelCampaign();

  useEffect(() => {
    trackEvent('Fluxo de Cancelamento: Visualizou Motivos Detalhados', {
      'Motivo Generico': selectedReason.text,
    });
  }, [trackEvent, selectedReason]);

  const handleBackButtonPress = useCallback(() => {
    setDialog(<CancelReasonDialog subscription={subscription} />);
  }, [setDialog, subscription]);

  const enableContinueButton = useCallback(() => {
    return otherInput || selectedOption;
  }, [otherInput, selectedOption]);

  const handleContinuePress = useCallback(() => {
    if (!enableContinueButton()) {
      alert('Informe um motivo para o cancelamento, por favor.');
      return;
    }
    if (selectedReason.id === 2 && isSubscriptionEligibleToFreeze()) {
      setDialog(
        <FreezeSubscriptionCTA
          subscription={subscription}
          selectedReason={selectedReason}
          selectedDetailedReason={selectedOption}
          otherReason={otherInput}
        />
      );
      return;
    }

    if (
      selectedReason.id === 1 &&
      isSubscriptionEligibleToChangePlanOnCancel()
    ) {
      setDialog(
        <ChangePlanOnCancelCTADialog
          subscription={subscription}
          selectedReason={selectedReason}
          selectedDetailedReason={selectedOption}
          otherReason={otherInput}
        />
      );
      return;
    }

    setDialog(
      <CancelConfirmation
        subscription={subscription}
        selectedReason={selectedReason}
        selectedDetailedReason={selectedOption}
        otherReason={otherInput}
      />
    );
  }, [
    enableContinueButton,
    isSubscriptionEligibleToFreeze,
    isSubscriptionEligibleToChangePlanOnCancel,
    setDialog,
    subscription,
    selectedReason,
    selectedOption,
    otherInput,
  ]);

  const cancelText = useMemo(() => {
    if (subscription.plan.months === 1) return 'Cancelar a Assinatura';
    else return 'Desativar a Renovação';
  }, [subscription]);

  const onReasonDetailedClick = useCallback(
    reason => {
      setSelectedOption(reason);
    },
    [setSelectedOption]
  );

  const hasSelectReasonDetailed = useCallback(
    reason => {
      return selectedOption?.id === reason.id;
    },
    [selectedOption]
  );

  function renderOtherReason() {
    if (selectedReason.detailedReasons.length > 0)
      return (
        <Option>
          Outros:
          <CustomTextField
            value={otherInput}
            onChange={e => {
              const newOtherInput = e.target.value;
              setOtherInput(newOtherInput);
            }}
          />
        </Option>
      );

    return (
      <CustomTextareaAutosize
        aria-label="minimum height"
        rowsMin={5}
        placeholder="Digite aqui"
        value={otherInput}
        onChange={e => {
          const newOtherInput = e.target.value;
          setOtherInput(newOtherInput);
        }}
      />
    );
  }

  return (
    <Container>
      <Content>
        <CancelStrip>{cancelText}</CancelStrip>

        <ReasonIcon src={selectedReason.iconSelected} />
        <ReasonAsk>Conte mais pra gente?</ReasonAsk>

        <OptionsContainer>
          {selectedReason.detailedReasons &&
            selectedReason.detailedReasons.map(reason => (
              <Option
                key={reason.id}
                onClick={() => onReasonDetailedClick(reason)}
              >
                <OptionDot
                  className={hasSelectReasonDetailed(reason) && 'selected'}
                />
                {reason.text}
              </Option>
            ))}
          {renderOtherReason()}
        </OptionsContainer>

        <ButtonsContainer>
          <button onClick={handleBackButtonPress} type="button">
            Voltar
          </button>

          <button
            onClick={handleContinuePress}
            className={enableContinueButton() && 'enable'}
          >
            Continuar
          </button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-top: 10px solid ${colors.warningRed};
  text-align: left;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const CancelStrip = styled.div`
  display: inline-block;
  padding: 3px 10px;
  position: relative;
  left: -48px;
  margin-bottom: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${colors.warningRed};
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  @media ${media.mobile} {
    left: -24px;
  }
`;

const ReasonIcon = styled.img`
  display: block;
  margin: 20px auto 5px auto;
  width: 65px;
`;

const ReasonAsk = styled.h1`
  font-weight: bold;
  color: ${colors.warningRed};
  font-size: 1.4em;
  text-align: center;
`;

const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin: 20px 0;
`;

const Option = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
`;

const OptionDot = styled.div`
  width: 13px;
  min-width: 13px;
  height: 13px;
  border-radius: 50px;
  margin-right: 5px;
  border: 1px solid ${colors.lightGray};

  &.selected {
    background-color: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
  }
`;

const CustomTextField = styled(TextField)`
  width: 100%;

  .MuiInput-root {
    margin-left: 5px;
  }
`;

const CustomTextareaAutosize = styled(TextareaAutosize)`
  border-color: ${colors.lightGray};
  border-radius: 8px;
  padding: 8px;
  margin: 20px 0;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  button {
    flex: 1;
    margin: 0 10px;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    border: 2px solid #929292;
    color: #929292;
    text-transform: uppercase;

    &.enable {
      border-color: ${colors.warningRed};
      color: ${colors.warningRed};
    }
  }

  @media ${media.mobile} {
    flex-direction: column-reverse;

    button {
      width: 100%;
      margin: 5px 0;
      padding: 10px 0;
    }
  }
`;
