import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router';

import { styled, media } from 'styles';
import { BookNewEditionsStrip } from 'modules/books/components';

export default function BookCard({ book }) {
  const { search } = useLocation();

  const getBookPageLink = useCallback(() => {
    return book.editions.length > 1
      ? `/livros-resolvidos/livro/${book.id}/edicoes`
      : `/materias/solucionario/livro/${book.id}/edicao/${book.editions[0].id}`;
  }, [book.id, book.editions]);

  const percentageColor = book.solvedPercentage === 100 ? '#00BC40' : '#CCD400';
  const bookPageLink = getBookPageLink();

  return (
    <Container>
      <Link
        key={`book_${book.id}`}
        to={{ pathname: bookPageLink, search: search }}
      >
        <Image src={`${book.imagePath}`} loading="lazy" />
        <SolvedBookPercentage percentageColor={percentageColor}>
          {book.solvedPercentage}% RESOLVIDO
        </SolvedBookPercentage>
        {book.hasNewEditions && <BookNewEditionsStrip />}
      </Link>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  cursor: pointer;
  width: 18%;
  margin-bottom: 25px;
  margin-right: 2%;

  @media ${media.mobile} {
    width: 37vw;
    margin-right: 3vw;

    &:last-child {
      img {
        margin-right: 30px;
      }
    }
  }
`;

const Image = styled.img`
  width: 100%;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
`;

const SolvedBookPercentage = styled.div`
  border-color: ${props => props.percentageColor};
  color: ${props => props.percentageColor};
  margin-top: 2px;
  border: 1px solid;
  padding: 4px 0;
  font-size: 0.9em;
  border-radius: 5px;

  @media ${media.mobile} {
    padding: 2px 0;
    font-size: 0.8em;
  }
`;

const Link = styled(RouterLink)`
  @media ${media.mobile} {
    width: 37vw;
  }
`;
