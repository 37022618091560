import React, { useCallback, useMemo, useEffect } from 'react';

import { styled, media, colors } from 'styles';
import { useTracker, useDate } from 'modules/shared/hooks';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';

export default function CancelSuccess({
  subscription,
  selectedReason,
  selectedDetailedReason,
  otherReason,
}) {
  const { trackEvent } = useTracker();
  const { getHumanReadableDateFromTimestamp } = useDate();
  const { hasCreditCardTrial } = useBookTrialCampaign();

  useEffect(() => {
    trackEvent('Fluxo de Cancelamento: Visualizou Sucesso', {
      'Motivo Generico': selectedReason?.text,
      'Motivo Detalhado': selectedDetailedReason?.text,
      'Outro Motivo': otherReason,
    });
  }, [trackEvent, selectedReason, selectedDetailedReason, otherReason]);

  const handleConfirmationPress = useCallback(() => {
    window.location.reload();
  }, []);

  const cancelText = useMemo(() => {
    if (subscription.plan.months === 1) return 'Cancelar a Assinatura';
    else return 'Desativar a Renovação';
  }, [subscription]);

  const comfirmationCTAText = useMemo(() => {
    if (subscription.plan.months === 1) return 'Assinatura cancelada';
    else return 'Renovação desativada';
  }, [subscription]);

  const confirmationInfoText = useMemo(() => {
    if (subscription.plan.months === 1) return 'Sua assinatura foi cancelada';
    else return 'Sua renovação de assinatura foi desativada';
  }, [subscription]);

  const subscriptionExpiresIn = useMemo(() => {
    return getHumanReadableDateFromTimestamp(subscription.expiresIn);
  }, [subscription, getHumanReadableDateFromTimestamp]);

  return (
    <Container>
      <Content>
        <CancelStrip>{cancelText}</CancelStrip>

        <Confirmation>{comfirmationCTAText}</Confirmation>

        <>
          <ConfirmationInfo>Nossa parceria chegou ao fim :(</ConfirmationInfo>

          {hasCreditCardTrial() ? (
            <ConfirmationInfo>
              {confirmationInfoText} e seu acesso à plataforma foi cortado.
              Vamos te enviar um email pra confirmar e garantir que está tudo
              certinho.
            </ConfirmationInfo>
          ) : (
            <>
              <ConfirmationInfo>
                {confirmationInfoText} e seu acesso à plataforma vai até o fim
                do seu plano, em {subscriptionExpiresIn}. Vamos te enviar um
                email pra confirmar e garantir que está tudo certinho.
              </ConfirmationInfo>

              <ConfirmationInfo>
                Lembrando que o cancelamento da assinatura não interrompe o
                pagamento de parcelas restantes, caso existam, e também não
                garante o estorno. Caso tenha dúvidas se é elegível para estorno
                ou em como fazer o pedido dele, confira nossa{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="https://www.respondeai.com.br/faq"
                  target="_blank"
                >
                  política de estorno
                </a>
                .
              </ConfirmationInfo>
            </>
          )}

          <ConfirmationInfo>
            {' '}
            Esperamos que você tenha tido bons momentos estudando com a gente e
            obrigada pela confiança no nosso trabalho! Te desejamos todo sucesso
            nos estudos :)
          </ConfirmationInfo>

          <ButtonsContainer>
            <button onClick={handleConfirmationPress} className="enable">
              Ok
            </button>
          </ButtonsContainer>
        </>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-top: 10px solid ${colors.warningRed};
  text-align: left;

  @media ${media.mobile} {
    padding: 20px;
    font-size: 0.9em;
  }
`;

const CancelStrip = styled.div`
  display: inline-block;
  padding: 3px 10px;
  position: relative;
  left: -48px;
  margin-bottom: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${colors.warningRed};
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  @media ${media.mobile} {
    left: -24px;
  }
`;

const Confirmation = styled.h1`
  font-weight: bold;
  color: ${colors.warningRed};
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 30px;
`;

const ConfirmationInfo = styled.p`
  width: 100%;
  line-height: 1.2;
  margin: 25px 0;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;

  button,
  a {
    flex: 1;
    margin: 0 10px;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    border: 2px solid #929292;
    color: #929292;
    text-transform: uppercase;
    font-size: 0.9em;

    &.enable {
      border-color: ${colors.warningRed};
      color: ${colors.warningRed};
    }

    &.full {
      border-color: ${colors.darkGreen};
      background-color: ${colors.darkGreen};
      color: #fff;
    }
  }

  @media ${media.mobile} {
    flex-direction: column-reverse;

    button {
      width: 100%;
      margin: 5px 0;
      padding: 10px 0;
    }
  }
`;
