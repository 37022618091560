import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { useEffect, useState } from 'react';

const useUserLearningPath = () => {
  const [userLearningPath, setUserLearningPath] = useState(null);
  const { data, loading, error } = useContentQueryImmutable(
    `/learning-path/get-user-learning-paths`
  );

  useEffect(() => {
    if (!data) return;
    // TODO pegar o certo e nao o primeiro
    setUserLearningPath(data[0]);
  }, [data]);

  return {
    userLearningPath,
  };
};

export default useUserLearningPath;
