import React from 'react';
import { styled } from 'styles';
import { IoMdStar } from 'react-icons/io';

export default function TagAsFreeContent({
  className,
  text,
  stylesOptions = {},
}) {
  return (
    <Container className={className}>
      <Icon styles={stylesOptions} className="star-icon" />
      <p>{text}</p>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4.43994px;
  padding: 0px 10px 1.5px 5px;

  p {
    font-family: Lato;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    margin-top: 2.5px;
  }
`;

const Icon = styled(({ ...props }) => <IoMdStar {...props} />)`
  margin-right: 4px;
`;
