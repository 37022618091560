import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { styled, media } from 'styles';

export default function HamburgerTrigger({ onClick, open }) {
  return (
    <IconContainer
      onClick={onClick}
      className={open ? 'open' : ''}
      data-cy="side-menu-hamburguer-trigger"
    >
      <Icon icon={faBars} />
    </IconContainer>
  );
}

const IconContainer = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 62px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 11;

  &.open {
    left: 238px;
    color: #fff;
  }

  @media ${media.mobile} {
    &:not(.open) {
      position: absolute;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.25em;
`;
