import React, { useState, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { styled, colors } from 'styles';
import { useUserContext } from 'providers/UserProvider';
import { useWorkspaceExercise } from 'modules/workspace/hooks/shared';

export default function Tooltip({ className, chapter }) {
  const [showMessage, setShowMessage] = useState(false);
  const [customStyles, setCustomStyles] = useState({});
  const { user } = useUserContext();
  const {
    getExercisesFromChapter,
    getListExercisesFromChapter,
    filterListExercisesFromUniversity,
  } = useWorkspaceExercise();

  const onIconHover = useCallback(
    event => {
      setShowMessage(true);
      setCustomStyles({
        top: `${event.target.getBoundingClientRect().top - 20}px`,
        left: `${event.target.getBoundingClientRect().left + 25}px`,
      });
    },
    [setShowMessage, setCustomStyles]
  );

  const topicsCount = useMemo(() => chapter.topics && chapter.topics.length, [
    chapter,
  ]);
  const topicsText = useMemo(() => (topicsCount === 1 ? 'Tópico' : 'Tópicos'), [
    topicsCount,
  ]);

  const exercisesCount = useMemo(() => {
    const exercises = getExercisesFromChapter(chapter);
    return exercises.length;
  }, [chapter, getExercisesFromChapter]);
  const exercisesText = useMemo(
    () =>
      exercisesCount === 1 ? 'Exercício de Fixação' : 'Exercícios de Fixação',
    [exercisesCount]
  );

  const listExercisesCount = useMemo(() => {
    const allListExercises = getListExercisesFromChapter(chapter);
    const listExercisesFromUniversity = filterListExercisesFromUniversity(
      allListExercises
    );
    return listExercisesFromUniversity.length;
  }, [chapter, getListExercisesFromChapter, filterListExercisesFromUniversity]);
  const listExercisesText = useMemo(
    () =>
      exercisesCount === 1
        ? `Exercício ${user?.university?.acronym}`
        : `Exercícios ${user?.university?.acronym}`,
    [exercisesCount, user]
  );

  return (
    <Container className={className} id="tooltip">
      <FontAwesomeIcon
        icon={faInfoCircle}
        onMouseEnter={onIconHover}
        onMouseLeave={() => setShowMessage(false)}
      />
      {showMessage && (
        <MessageContainer style={customStyles}>
          <Triangle />
          <Messages>
            <Message className="topics">
              <Count>{topicsCount}</Count>
              <Text>{topicsText}</Text>
            </Message>
            {exercisesCount > 0 && (
              <Message className="exercises">
                <Count>{exercisesCount}</Count>
                <Text>{exercisesText}</Text>
              </Message>
            )}
            {listExercisesCount > 0 && (
              <Message className="list-exercises">
                <Count>{listExercisesCount}</Count>
                <Text>{listExercisesText}</Text>
              </Message>
            )}
          </Messages>
        </MessageContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: none;
  position: absolute;
  height: 100%;
  align-items: center;
  left: calc(100% - 25px);
  color: ${colors.mediumGray};
  font-size: 1.05em;

  svg {
    opacity: 0.9;
  }
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 25px 20px 0;
  border-color: transparent #3a3a3a transparent transparent;
`;

const MessageContainer = styled.div`
  position: fixed;
  display: flex;
  height: 50px;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 1;
`;

const Messages = styled.div`
  position: relative;
  top: -15px;
  display: flex;
  align-items: flex-start;
  background-color: #3a3a3a;
  height: 70px;
  border-radius: 10px;
  padding: 12px 0;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 0 5px;
  text-align: center;
  font-size: 0.8em;

  &.topics {
    color: ${colors.theory};
  }

  &.exercises {
    color: ${colors.exercise};
  }

  &.list-exercises {
    color: ${colors.listExercise};
  }
`;

const Count = styled.div`
  font-weight: bold;
  margin-bottom: 2px;
`;

const Text = styled.div`
  text-transform: uppercase;
`;
