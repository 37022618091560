import NewToggleableComponent from 'modules/shared/components/NewToggleableComponent';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { faComment, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useFeedback } from 'modules/mvp/hooks/useFeedback';
import { FEEDBACK_OPTIONS } from './options';
import {
  Container,
  Title,
  CommentContainer,
  FeedbackGrade,
  FeedbackIconContainer,
  FeedbackIcon,
  FeedbackLabel,
  Heart,
  Icon,
  SubmitIcon,
} from './styles';
import { useComponentFirstTimeVisible } from 'modules/shared/hooks/useComponentVisible';

export function ExerciseFeedback({ question, style = {}, origin }) {
  const [open, setOpen] = useState(false);
  const [score, setScore] = useState(null);
  const [comment, setComment] = useState('');
  const [commentSent, setCommentSent] = useState(false);
  const ref = useRef(null);
  const {
    setExerciseFeedbackSent,
    trackViewFeedback,
    trackSendScore,
    trackSendComment,
  } = useFeedback();

  const exerciseType = useMemo(() => {
    return question?.exerciseType;
  }, [question]);

  const message = useMemo(() => {
    if (exerciseType === 'MvpExercise')
      return 'Ajuda a gente? O que você achou dessa resolução por IA?';

    return 'Me conta, até aqui o que você tem achado dessa resolução?';
  }, [exerciseType]);

  const containerClass = useMemo(() => {
    if (exerciseType === 'MvpExercise') return 'ai';

    return '';
  }, [exerciseType]);

  const onSetScore = useCallback(
    newScore => {
      trackSendScore({
        exerciseId: question?.exerciseId,
        questionId: question?.id,
        listId: question?.mvpListWithStatementId,
        exerciseType: exerciseType,
        score: newScore,
        origin: origin,
        disciplineName: question?.mvpListWithStatement?.discipline?.name,
      });
      setExerciseFeedbackSent(exerciseType);

      if (newScore === 3) {
        // better score
        setScore(newScore);
        setTimeout(() => {
          setOpen(false);
        }, 2000);
        return;
      }

      // other scores
      setOpen(false);
      setTimeout(() => {
        setScore(newScore);
        setOpen(true);
      }, 600);
    },
    [exerciseType, origin, question, setExerciseFeedbackSent, trackSendScore]
  );

  const onSendComment = useCallback(() => {
    trackSendComment({
      exerciseId: question?.exerciseId,
      questionId: question?.id,
      listId: question?.mvpListWithStatementId,
      exerciseType: exerciseType,
      score: score,
      comment: comment,
      origin: origin,
      disciplineName: question?.mvpListWithStatement?.discipline?.name,
    });

    setCommentSent(true);
    setTimeout(() => setOpen(false), 2000);
  }, [comment, exerciseType, origin, question, score, trackSendComment]);

  const onComponentVisible = useCallback(() => {
    trackViewFeedback({
      exerciseId: question?.exerciseId,
      questionId: question?.id,
      listId: question?.mvpListWithStatementId,
      exerciseType: exerciseType,
      origin: origin,
      disciplineName: question?.mvpListWithStatement?.discipline?.name,
    });
  }, [exerciseType, origin, question, trackViewFeedback]);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 2000);
  }, [exerciseType, question, trackViewFeedback, origin]);

  useComponentFirstTimeVisible({ ref, onVisible: onComponentVisible });

  return (
    <NewToggleableComponent open={open}>
      {!score && (
        <Container style={style} className={containerClass} ref={ref}>
          <Title
            style={{ marginBottom: '20px', fontWeight: 'bold' }}
            className="title"
          >
            {message}
          </Title>
          <FeedbackGrade>
            {FEEDBACK_OPTIONS.map(option => (
              <FeedbackIconContainer key={option.id}>
                <FeedbackIcon
                  src={option.iconSrc}
                  alt={option.text}
                  onClick={() => onSetScore(option.id)}
                />
                <FeedbackLabel>{option.text}</FeedbackLabel>
              </FeedbackIconContainer>
            ))}
          </FeedbackGrade>
        </Container>
      )}

      {(score === 3 || commentSent) && (
        <Container style={style} className={containerClass}>
          <Title className="title">
            Obrigado pelo feedback <Heart>&#9829;</Heart>
          </Title>
        </Container>
      )}

      {(score === 1 || score === 2) && !commentSent && (
        <Container style={style} className={containerClass}>
          <Title style={{ marginBottom: '20px' }} className="title">
            Pode deixar seu comentário para entendermos melhor?{' '}
            <Heart>&#9829;</Heart>
          </Title>

          <CommentContainer>
            <Icon icon={faComment} />
            <textarea
              value={comment}
              onChange={event => setComment(event.target.value)}
              className="comment-area"
              placeholder="Quanto mais detalhes, melhor ;)"
            ></textarea>

            <SubmitIcon icon={faPaperPlane} onClick={onSendComment} />
          </CommentContainer>
        </Container>
      )}
    </NewToggleableComponent>
  );
}
