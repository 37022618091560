import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import {
  GuideHeader,
  LecturesCards,
  SubjectsCards,
  BooksCards,
  CardsContent,
} from '../components';
import { Loading, Error } from 'modules/shared/components/generic';
import { useGuide } from '../hooks';
import { useTracker } from 'modules/shared/hooks';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { useProfessor } from 'modules/professors/hooks/useProfessor';
import { useDialogContext } from 'providers/DialogProvider';
import CreateUserProfessorDialog from 'modules/professors/dialogs/CreateUserProfessorDialog';
import { FaPen } from 'react-icons/fa';

export default function SubjectPage() {
  const { subjectId } = useParams();
  const { getLecturesFromSubjects, subjectGuidableName } = useGuide();
  const { trackEvent } = useTracker();
  const { showUserProfessors: isUserElgibleToSeeProfessor } = useProfessor();
  const { setDialog } = useDialogContext();
  const { getDisciplinesFromSubject } = useProfessor();

  const { data: subject, loading, error } = useContentQueryImmutable(
    `/subjects/${subjectId}`
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (loading || error) return;

    trackEvent('Visualizou Assunto Default', {
      Matéria: subject.theme.name,
      Assunto: subject.name,
    });
  }, [subject, trackEvent, loading, error]);

  const showLecturesCards = useCallback(lectures => {
    return lectures.length > 0;
  }, []);

  const showBooksCards = useCallback(books => {
    return books.length > 0;
  }, []);

  const openInformProfessorDialog = useCallback(() => {
    const disciplinesFromSubject = getDisciplinesFromSubject(subject.id);
    const defaultDiscipline =
      disciplinesFromSubject.length === 1 ? disciplinesFromSubject[0] : null;

    setDialog(
      <CreateUserProfessorDialog
        defaultThemeId={subject?.theme?.id}
        defaultSubject={subject}
        color={subject?.theme?.color}
        defaultDisciplineId={defaultDiscipline?.id}
        defaultDisciplineType={defaultDiscipline?.modelName}
        availableDisciplines={disciplinesFromSubject}
        source="Página Assunto Professor Não Informado"
      />
    );
  }, [getDisciplinesFromSubject, setDialog, subject]);

  const getGuideHeaderTitle = useCallback(() => {
    if (isUserElgibleToSeeProfessor())
      return (
        <>
          {`${subject?.name} - `}
          <EditableProfessorName onClick={openInformProfessorDialog}>
            Professor não Informado
            <EditIcon />
          </EditableProfessorName>
        </>
      );
    return `${subject?.name}`;
  }, [isUserElgibleToSeeProfessor, openInformProfessorDialog, subject]);

  if (error) return <Error />;
  if (loading) return <Loading />;

  const lectures = getLecturesFromSubjects([subject]);

  return (
    <Container>
      <GuideHeader
        name={getGuideHeaderTitle()}
        color={subject.theme.color}
        themeId={subject.theme.id}
        textAlign="left"
        utmContent={'pag_assunto'}
      />
      <CardsContent>
        {showLecturesCards(lectures) && (
          <LecturesCards lectures={lectures} color={subject.theme.color} />
        )}

        <SubjectsCards
          subjects={[subject]}
          color={subject.theme.color}
          guidableType={subjectGuidableName}
        />

        {showBooksCards(subject.books) && (
          <BooksCards books={subject.books} subject={subject} />
        )}
      </CardsContent>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;

const EditableProfessorName = styled.span`
  text-decoration: underline;
  text-underline-offset: 5px;
  position: relative;
  cursor: pointer;
  &:hover {
    color: #e6e6e6;
    svg {
      color: #e6e6e6;
    }
  }
`;

const EditIcon = styled(FaPen)`
  font-size: 0.8em;
  position: absolute;
  bottom: 4px;
  left: calc(100% + 5px);
  font-weight: lighter;
  color: white;
`;
