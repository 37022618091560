import React, { useCallback, useEffect, useState } from 'react';
import {
  PhoneInput,
  DownloadableHeader,
  DownloadableItem,
  DownloadableSendButton,
} from '../index';
import { styled, colors, media } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useUserContext } from 'providers/UserProvider';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useTracker, useUTM } from 'modules/shared/hooks';
import useDownloadContentCampaign from '../hooks/useDonwalodContentCampaign';

export default function DonwloadContentPhoneRequestDialog() {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [updated, setUpdated] = useState(false);

  const { user, setUser } = useUserContext();
  const { unsetDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();
  const { getContentByTerm, getUtmTerm } = useDownloadContentCampaign();

  const updateUser = useRAPostRequest('users/update_basic_infos');

  useEffect(() => {
    trackEvent('Pedir Telefone: Visualizou Dialog', {
      ...getUTMsByLocation(window.location),
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Conteudo: getUtmTerm(),
    });
  }, [trackEvent, getUTMsByLocation, getUtmTerm]);

  const formattedPhone = useCallback(phone => {
    return `55${phone.replace(/\D/g, '')}`;
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      if (formattedPhone(phone).length < 12) {
        setLoading(false);
        alert('Numero de telefone inválido');
        return;
      }
      const response = await updateUser({
        phone: formattedPhone(phone),
        from: getUtmTerm(),
      });
      setUser({ ...user, ...response.data.user });
      setLoading(false);
      setUpdated(true);
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  }, [updateUser, setUser, user, phone, formattedPhone, getUtmTerm]);

  const handleClose = useCallback(() => {
    unsetDialog();
    trackEvent('Pedir Telefone: Fechou Dialog', {
      ...getUTMsByLocation(window.location),
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Conteudo: getUtmTerm(),
    });
  }, [trackEvent, getUTMsByLocation, unsetDialog, getUtmTerm]);

  return (
    <Container>
      <Content>
        <Title>
          Atualize seu cadastro para receber mais conteúdos como esse
        </Title>

        <CloseButton onClick={handleClose} className="close">
          X
        </CloseButton>

        <PhoneInputContainer>
          <PhoneInput phone={phone} onChangePhone={setPhone} />
        </PhoneInputContainer>

        <DownloadableSendButton
          handleSubmit={handleSubmit}
          loading={loading}
          updated={updated}
        />

        <DownloadableContent>
          <DownloadableHeader headerName="Garanta seu conteúdo abaixo" />
          <DownalodableItemList>
            {getContentByTerm(getUtmTerm())?.map(
              ({
                id,
                name,
                downloadLink,
                blocked,
                color,
                lightColor,
                displayName,
              }) => (
                <DownloadableItem
                  key={id}
                  name={name}
                  displayName={displayName}
                  link={downloadLink}
                  blocked={blocked}
                  color={color}
                  lightColor={lightColor}
                  updated={updated}
                />
              )
            )}
          </DownalodableItemList>
        </DownloadableContent>
      </Content>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 750px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  padding: 30px 15px 20px;
  border-top: 10px solid ${colors.mainOrange};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Title = styled.div`
  font-size: 1.4em;
  margin-bottom: 10px;
  @media ${media.mobile} {
    font-size: 1.1em;
  }
`;

const PhoneInputContainer = styled.div`
  width: 85%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const DownloadableContent = styled.div`
  margin-top: 20px;
  background-color: #f5f5f5;
  width: 95%;
  padding-bottom: 20px;
`;

const DownalodableItemList = styled.div`
  display: flex;
  width: 85%;
  margin: auto;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const CloseButton = styled.span`
  font-size: 1.3em;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
  position: absolute;
  top: 10px;
  right: 20px;
  @media ${media.mobile} {
    font-size: 1.2em;
    right: 10px;
    top: 5px;
  }
`;
