import React, { useCallback } from 'react';
import { media, styled } from 'styles';
import useSearchTracker from '../hooks/useSearchTracker';
import { useSearchContext } from '../providers/SearchProvider';
import useSearchExerciseType from '../hooks/useSearchExerciseType';

export default function ResultItem({ result, resultPosition }) {
  const { _source: content } = result;
  const { searchType, searchTerm, file } = useSearchContext();
  const { trackClickSerpResult, trackClickInResultByText } = useSearchTracker();
  const {
    getExerciseLink,
    getHtmlStatement,
    geTitleName,
  } = useSearchExerciseType();

  const onResultClick = useCallback(() => {
    if (searchType === 'image')
      trackClickSerpResult(result, resultPosition, file.name);
    if (searchType === 'text')
      trackClickInResultByText(resultPosition, searchTerm);
  }, [
    searchType,
    trackClickSerpResult,
    result,
    resultPosition,
    trackClickInResultByText,
    searchTerm,
    file,
  ]);

  return (
    <Container onClick={onResultClick}>
      <Title>
        <ThemePart color={content.themeColor}>
          {`${content.themeName}`}
        </ThemePart>
        <span> {` > `} </span>
        <span>{geTitleName(result)}</span>
      </Title>
      <Statement
        data-cy="search-result-statement"
        href={getExerciseLink(result, searchType)}
        target="_blank"
        dangerouslySetInnerHTML={{ __html: getHtmlStatement(result) }}
      ></Statement>
    </Container>
  );
}

const Container = styled.div`
  margin: 10px 2px;
  text-align: left;
  padding: 0px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666;

  @media ${media.mobile} {
    padding: 0px;
    margin: 6px 2px;
  }
`;

const Title = styled.p`
  font-size: 0.8em;
  margin-bottom: 5px;
`;

const ThemePart = styled.span`
  color: ${({ color }) => color};
`;

const Statement = styled.a`
  margin-left: 3px;

  :hover {
    text-decoration: underline;
  }
`;
