import React from 'react';
import ContentTitle from './ContentTitle';
import DisciplineCard from './DisciplineCard.js';
import { styled, media } from 'styles';

export default function ContainerWithOnlyDisciplines({
  selectedDiscipline,
  disciplines,
  history,
}) {
  return (
    <>
      <ContentTitle
        title={`${selectedDiscipline?.theme.name}`}
        color={selectedDiscipline?.theme.color}
        underline={false}
      />
      <DisciplinesCardsWrapper>
        {disciplines.map(d => {
          return (
            <DisciplineCard
              key={d.id}
              theme={d.theme}
              discipline={d}
              history={history}
            />
          );
        })}
      </DisciplinesCardsWrapper>
    </>
  );
}

const DisciplinesCardsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  @media ${media.mobile} {
    flex-wrap: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scrollbar-color: red;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none !important;
    }
  }
`;
