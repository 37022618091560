import React, { useEffect } from 'react';
import { media, styled } from 'styles';
import SearchProvider from '../providers/SearchProvider';
import useSearchNavigation from '../hooks/useScreenNavigation';
import BackButton from '../components/BackButton';
import useSearchTracker from '../hooks/useSearchTracker';
import useMonitorSearchUsage from '../hooks/useMonitorSearchUsage';

export default function SearchDialog() {
  const { trackViewSearchDialog } = useSearchTracker();
  const { increaseViewCounter } = useMonitorSearchUsage();

  useEffect(() => {
    increaseViewCounter();
    trackViewSearchDialog();
    // Important to let empty array. Only call in first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchProvider>
      <DialogContainer data-cy="search-dialog">
        <BackButton />
        <SearchContainerScreen />
      </DialogContainer>
    </SearchProvider>
  );
}

function SearchContainerScreen() {
  const { getScreenToRender } = useSearchNavigation();

  return <SearchContainer>{getScreenToRender()}</SearchContainer>;
}

const DialogContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  background: rgb(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;

  @media ${media.mobile} {
    position: relative;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
